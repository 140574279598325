import {UserChat} from "./user-chat";
import {MessageChat} from "./message-chat";

export class GroupRoomChat {
  id: string;
  roomTypeId: number;
  date: any;
  users: UserChat[];
  group_users: any [];
  messages: MessageChat[];
  newmessages;
  auxMessages: MessageChat[];
  usersMap: Map<string, UserChat>;
  messagesMap: Map<string, MessageChat>;
  infoCargada: boolean;
  snapshot: number;
  nonReadMessages: number;
  allMessagesLoaded: boolean;
  description: string;
  groupPic: string;
  groupName: string;
  groupOpened: number;
  onlyAdminCanAdd: boolean;
  onlyAdminCanChat: boolean;
  onlyAdminCanEditGroupInfo: boolean;
  myInfo: UserChat;
  startPagination: string;
  lastReadMessage: string;
  lastMessage: any
  isTyping: boolean;
  isPublic: Number;
  left: boolean;
  belongs: boolean;
  constructor(id?: string, roomTypeId?: number, date?: any, users?: UserChat[],
              messages?: MessageChat[], usersMap?: Map<string, UserChat>,messagesMap?: Map<string, MessageChat>,
              description?: string, groupPic?: string, groupName?: string, groupOpened?: number, onlyAdminCanAdd?: boolean,
              onlyAdminCanChat?: boolean, onlyAdminCanEditGroupInfo?: boolean, lastMessage?: any, groupUsers?, isPublic?:Number, left?, belongs?) {
    this.id = id || '';
    this.roomTypeId = roomTypeId || -1;
    this.date = date || null;
    this.users = users || [];
    this.messages = messages || [];
    this.newmessages =  [];
    this.usersMap = usersMap || new Map<string, UserChat>();
    this.messagesMap = messagesMap || new Map<string, MessageChat>();
    this.infoCargada = false;
    this.snapshot = 0;
    this.nonReadMessages = 0;
    this.allMessagesLoaded = false;
    this.description = description || '';
    this.groupPic =groupPic || '';
    this.groupName = groupName || '';
    this.groupOpened = groupOpened || null;
    this.onlyAdminCanAdd = onlyAdminCanAdd || false;
    this.onlyAdminCanChat = onlyAdminCanChat || false;
    this.onlyAdminCanEditGroupInfo = onlyAdminCanEditGroupInfo || false;
    this.startPagination = undefined;
    this.lastReadMessage = undefined;
    this.lastMessage = lastMessage;
    this.isTyping = false;
    this.group_users = groupUsers;
    this.isPublic = isPublic;
    this.left = left;
    this.belongs = belongs;
  }
}
