import { Component, OnInit, Input, NgModule  } from '@angular/core';
import { FunctionsService } from "app/services/functions/functions.service";
import { EndpointService } from 'app/services/endpoint/endpoint.service';
import { UntypedFormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { isSetAccessor } from 'typescript';
import { UntypedFormGroup, NgModel, ValidatorFn, Validators } from '@angular/forms';

// Joan: Donava error després de la migració de 9 -> 14
/*
@NgModule ({
  imports: [ MatFormFieldModule, MatInputModule ],
  exports: [ MatFormFieldModule, MatInputModule ]
})
*/

@Component({
  selector: 'app-events-conditions-custom-element',
  templateUrl: './events-conditions-custom-element.component.html',
  styleUrls: ['./events-conditions-custom-element.component.css']
})
export class EventsConditionsCustomElementComponent implements OnInit {

  @Input() childs: []
  @Input() values: []
  @Input() fieldNames: []
  @Input() conditionsArray

  constructor(
    public functionsService: FunctionsService,
    public endpointService: EndpointService
    ) { }
    
  myControl = new UntypedFormControl('');
  myControl2 = new UntypedFormControl('');
  filteredOptions: Observable<string[]>;
  filteredOptions2: Observable<string[]>;
  actualTable: string;

  public showField = false;
  public showOperator = false;

  public actualField = [];
  public actualField2 = [];

  public fieldNamesFilter = [];
  public fieldNamesFilter2 = [];
  
  tableInit: string = "";

  addConditionForm = new UntypedFormGroup({
    conditionTable: new UntypedFormControl({disabled: false }),
    conditionCampo: new UntypedFormControl({ disabled: false }),
    conditionOperador: new UntypedFormControl({ disabled: false }),
    conditionTipo: new UntypedFormControl({ disabled: false }),
    conditionValor: new UntypedFormControl({ disabled: false }),
    conditionTable2: new UntypedFormControl({ disabled: false }),
    conditionCampo2: new UntypedFormControl({ disabled: false }),
  });

  ngOnInit() {
    console.log(this.addConditionForm.valid, "valid form")
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.fieldNamesFilter.filter(field => field.toLowerCase().includes(filterValue));
  }

  private _filter2(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.fieldNamesFilter2.filter(field => field.toLowerCase().includes(filterValue));
  }

  selectTable(value, bool) {
    if(value) {
      for(let i in this.functionsService.fieldNames) {
        if(i == value) {
          if (bool) {
            this.actualField = this.functionsService.fieldNames[i];
            this.fieldNamesFilter = this.actualField.map(function(item) {
              return item['COLUMN_NAME'];
            });
            this.filteredOptions = this.myControl.valueChanges.pipe(
              startWith(''),
              map(value => this._filter(value || '')),
            );

          } else {
            this.actualField2 = this.functionsService.fieldNames[i];
            this.fieldNamesFilter2 = this.actualField2.map(function(item) {
              return item['COLUMN_NAME'];
            });
            this.filteredOptions2 = this.myControl2.valueChanges.pipe(
              startWith(''),
              map(value => this._filter2(value || '')),
            );

          }
        }
      }
    } else {
      if (bool) this.actualField = [];
      else this.actualField2 = [];
    }
    this.showField = true;
  }
  
  updateCondition(condition, id_subtipo?, bd_table?, bd_field?, id_operador?, bd_table2?, bd_field2?, value?) {
    if(id_subtipo !== undefined) {
      condition['id_subtipo'] = id_subtipo

      if(id_subtipo == 1) {
        condition['bd_table2'] = "0"
        condition['bd_field2'] = "0"
      }
    }
    if(value !== undefined) condition['values'][0]['valor'] = value
    if(bd_table2 !== undefined) condition['bd_table2'] = bd_table2
    if(bd_field2 !== undefined) condition['bd_field2'] = bd_field2
    if(bd_table !== undefined) condition['bd_table'] = bd_table
    if(bd_field !== undefined) condition['bd_field'] = bd_field
    if(id_operador !== undefined) condition['id_operador'] = id_operador
    
    if(condition['children'] !== undefined) {
      for (let i = 0; i < condition['children'].length; i++) {
        return this.updateCondition(condition['children'][i], id_subtipo, bd_table, bd_field, id_operador, bd_table2, bd_field2, value)
      }
    }
  }

  deleteCondition(condition, idParent) {
    condition['borrado'] = 1;
  }

  checkIfTransform(idParent) {
    console.log(this.functionsService.arrayConditions)
    this.searchTree(this.functionsService.arrayConditions, idParent);
  }

  searchTree(arrayConditions, idParent){
    console.log(idParent, "idParent", arrayConditions)
    for(let i in arrayConditions) {
      console.log(arrayConditions[i], "arrayCondition index")
      if(idParent == arrayConditions[i]['id']){
        if(arrayConditions[i]['children'].filter(function(element) {return element.borrado != 1;})[0] && 
          arrayConditions[i]['children'].filter(function(element) {return element.borrado != 1;}).length < 2 && arrayConditions[i]['id_parent'] != 0) {
            arrayConditions[i]['borrado'] = 1;
            arrayConditions.splice(i, 0, arrayConditions[i]['children'][0]);
            return
        } else {
          return
        }
      } else if (arrayConditions[i].children != null) {
        this.searchTree(arrayConditions[i].children, idParent);
        return arrayConditions[i];
      }
    }
    return null;
  }

  extendSimpleCondition(condition, arrayConditions, idCondition, idSubtipo, idParent, idEvento, idReporte, bdTable, bdField, idOperador, bdTable2, bdField2, values) {
    condition['borrado'] = 1;
    let currentIndex = this.conditionsArray.indexOf(condition);

    let push = ({id_subtipo: 3, id_parent: idParent, id_evento: idEvento, id_reporte: idReporte, bd_table: 0, bd_field: 0, id_operador: 0, bd_table2: 0, bd_field2: 0, 
    children: [{id_subtipo: idSubtipo, id_parent: null, id_evento: idEvento, id_reporte: idReporte, bd_table: bdTable, bd_field: bdField, id_operador: idOperador, bd_table2: bdTable2, bd_field2: bdField2, values: values, borrado: 0}, 
              {id_subtipo: null, id_parent: null, id_evento: idEvento, id_reporte: idReporte, bd_table: undefined, bd_field: undefined, id_operador: undefined, bd_table2: undefined, bd_field2: undefined, values: [{valor: ''}], borrado: 0}], 
    values: [{valor: ''}],
    borrado: 0});

    this.conditionsArray.splice(currentIndex, 0, push);
  }

}
