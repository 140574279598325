import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ChatService} from "../../../services/chat.service";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {BottomSheetEditUserGroupComponent} from "../bottom-sheet-edit-user-group/bottom-sheet-edit-user-group.component";
import {AuthService} from "../../../services/auth/auth.service";
import {BottomSheetAddUserToGroupComponent} from "../bottom-sheet-add-user-to-group/bottom-sheet-add-user-to-group.component";

@Component({
  selector: 'app-chat-group-info',
  templateUrl: './chat-group-info.component.html',
  styleUrls: ['./chat-group-info.component.scss']
})
export class ChatGroupInfoComponent implements OnInit {
  // public group: any;
  @Input() group;
  @Input() contacts;
  public firstFormGroup: UntypedFormGroup;
  editName: boolean = false;
  editDescription: boolean = false;
  onlyAdminCanChat: boolean = false;
  onlyAdminCanEditGroupInfo: boolean = false;
  onlyAdminCanAdd: boolean = false;
  editConfig: boolean = false;
  groupType: number;
  @ViewChild("fileUpload", { static: false }) fileUpload: ElementRef;
  files  = [];

  usuario_search: any;

  constructor(public chatService: ChatService,
              public formBuilder: UntypedFormBuilder,
              private _bottomSheet: MatBottomSheet,
              public authService: AuthService,
              /*@Inject(MAT_DIALOG_DATA) public data: any,*/) {
    console.log(this.group);
    console.log(this.contacts);
    // this.group = data.group;
  }

  ngOnInit(): void {
    this.onlyAdminCanChat = this.group.onlyAdminCanChat;
    this.onlyAdminCanEditGroupInfo = this.group.onlyAdminCanEditGroupInfo;
    this.onlyAdminCanAdd = this.group.onlyAdminCanAdd;
    this.groupType = this.group.groupOpened;
    this.firstFormGroup = this.formBuilder.group({
      firstCtrlGroupName: [this.group.groupName, Validators.required],
      firstCtrlGroupDescription: [this.group.description, Validators.required],
    });
  }

  public openBottomSheetEditUserGroup(groupid, userId, isAdmin, selfName, otherName): void {
    console.log('GRUPO --> ', groupid);
    console.log('USUARIO --> ', userId);
    const bottomSheet = this._bottomSheet.open(BottomSheetEditUserGroupComponent, {
      data : {groupId: groupid, userId: userId, isAdmin: isAdmin, selfName: selfName, otherName: otherName}
    });

    bottomSheet.afterDismissed().subscribe( data => {

    });
  }

  saveName(name: any) {
    console.log(name);
    this.editName = false;
    this.chatService.updateGroupName(this.group.id, name);
  }

  saveDescription(description: any) {
    console.log(description);
    this.editDescription = false;
    this.chatService.updateGroupDescription(this.group.id, description);
  }

  cancelEditGroupName() {
    this.firstFormGroup.get('firstCtrlGroupName').setValue(this.group.groupName);
    this.editName = false;
  }

  cancelEditDescription() {
    this.firstFormGroup.get('firstCtrlGroupDescription').setValue(this.group.description);
    this.editDescription = false;
  }

  addUserToGroup(groupid) {
    console.log('GRUPO --> ', groupid);
    const bottomSheet = this._bottomSheet.open(BottomSheetAddUserToGroupComponent, {
      data : {groupId: groupid, contacts: this.contacts, name: this.authService.userFullName}
    });
    bottomSheet.afterDismissed().subscribe( data => { });
  }

  saveConfiguration() {
    this.chatService.updateGroupConfiguration(this.onlyAdminCanChat, this.onlyAdminCanEditGroupInfo,
      this.onlyAdminCanAdd, this.groupType, this.group.id);
    this.group.onlyAdminCanChat = this.onlyAdminCanChat;
    this.group.onlyAdminCanEditGroupInfo = this.onlyAdminCanEditGroupInfo;
    this.group.onlyAdminCanAdd = this.onlyAdminCanAdd;
    this.group.groupOpened = this.groupType;
    this.editConfig = false;
  }

  cancelEditConfiguration() {
    this.onlyAdminCanChat = this.group.onlyAdminCanChat;
    this.onlyAdminCanEditGroupInfo = this.group.onlyAdminCanEditGroupInfo;
    this.onlyAdminCanAdd = this.group.onlyAdminCanAdd;
    this.groupType = this.group.groupOpened;
    this.editConfig = false;
  }

  addImage() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++)
      {
        const file = fileUpload.files[index];
        this.files.push({ data: file, inProgress: false, progress: 0});
      }
      this.uploadFiles(this.fileUpload);
    };
    fileUpload.click();
/*    fileUpload.onchange = () => {
      const file = fileUpload.files[0];
      this.files.push({ data: file, inProgress: false, progress: 0});
      console.log(this.files[0]);
      this.uploadFiles(this.fileUpload, this.files[0]);
    };
    fileUpload.click();*/
    // console.log(this.files);
  }


  private uploadFiles(picture: ElementRef) {
    picture.nativeElement.value = '';
    this.chatService.uploadFile(this.files[0], this.group.id);
    this.files = [];
    // this.uploadFile(this.files[0], groupId);
    /*this.files.forEach(file => {
      this.uploadFile(file);
    });*/
  }
}
