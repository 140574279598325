import {Values} from "../../../values/values";

export class ColaborationBody {

  // CAMPOS COMUNES ENTRE INMO Y GRUPO
  public tipoPerfil: number;
  public basic: boolean;
  public silver: boolean;
  public golden: boolean;
  public mensajes: number;

  // CAMPOS ÚNICOS PARA INMOS
  public colaborarConAgrupaciones: number;
  public flatSharing: number;
  public flatLending: number;
  //public listaPersonalizadaFS: SimpleProperty[];
  //public listaPersonalizadaFL: SimpleProperty[];
  public grupos: [];

  // CAMPOS ÚNICOS PARA GRUPOS
  public verViviendas: number;
  public verInmos: number;

  constructor() {
    this.clearBody();
  }

  public clearBody() {
  this.tipoPerfil = 1;
  this.basic = true;
  this.silver = true;
  this.golden = true;
  this.mensajes = 1;
  // Inmo
  this.colaborarConAgrupaciones = 1;
  this.flatSharing = 1;
  this.flatLending = 0;
  // Grupo
  this.verInmos = 1;
  this.verViviendas = 1;
  }

  public handleResponse(data: any, isGrupo = false) {

    this.tipoPerfil = data[0]?.tipo_perfil;
    this.basic = data[0]?.colaborar_con_basic === 1 ? true : false;
    this.silver = data[0]?.colaborar_con_silver === 1 ? true : false;
    this.golden = data[0]?.colaborar_con_golden === 1 ? true : false;
    this.mensajes = data[0]?.mensajes;
  }
}
