import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Values} from "../../values/values";
import {map} from "rxjs/internal/operators";
import { environment } from 'environments/environment';
@Injectable()
export class PushNotificationsService {

    constructor(private http: HttpClient) { }  
    
    private URL = environment.SOCKET_ENDPOINT + '/api/';
  
    public addPushSubscriber(userId: number, sub){
        let requestURL = this.URL + 'push/addPushSubscription';
        const data = {
          userId: userId,
          sub: sub
        }
        return this.http.post<any[]>(requestURL, data);
    }

    public notifyAllUsers(noti){
        console.log("noti-socket" , noti);
        let requestURL = this.URL + 'push/sendPushNotification';
        return this.http.post<any[]>(requestURL,{notis: noti});
    }

    public notifyAllUsersChat(chat){
        console.log("noti-socket" , chat);
        let requestURL = this.URL + 'push/sendPushNotificationChat';
        return this.http.post<any[]>(requestURL,{notis: chat});
    }

    /*addPushSubscriber(sub:any) {
        return this.http.post('/api/notifications', sub);
    }*/



}