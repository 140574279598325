import {Component, OnInit, Input, ViewChild, AfterViewInit, OnDestroy} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import {SelectionModel} from '@angular/cdk/collections';

//SERVICES
import { FunctionsService } from '../../../services/functions/functions.service';
import {RoutingService} from "../../../services/routing/routing.service";
import {GenericService} from "../../../services/generic/generic.service";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {AuthService} from "../../../services/auth/auth.service";
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { BottomSheetFilterTableComponent } from './bottom-sheet-filter-table/bottom-sheet-filter-table.component';
import { RETURN } from 'mat-table-exporter';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class TableComponent implements OnInit, AfterViewInit, OnDestroy {
  // @Input() functionId: number;
  @Input() structure = null;
  @Input() index: number;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public idQuery: number;
  public dataSource: MatTableDataSource<any>;
  public displayedColumns = [];
  public sticky;
  public search;
  public paginatorOptions;
  public tableData;
  public arrayFiltersFields = [];
  public arrayFiltersFieldsInit = [];
  public filterSearch = null;
  public filtersCount = 0;
  public loadedFilter = true;
  public aux = [];
  public oldValue = -1;
  public actualRow = null;
  public oldBdField = '';
  public expandedCol = null;
  public expandedElement;
  public pageIndex : number = 0;
  public loaded = false;
  public loadedAfterView = false;
  public clickedRow = null;
  public bottomSheetRef = null;
  public lastSort: Sort = { active: '', direction: '' };

  public getHeightV;
  public getHeightTable2V;
  public getHeightTableV;
  public caluclated = false;
  public expandedElementLast = null;
  public selection = new SelectionModel<any>(true, []);

  constructor(public functionsService: FunctionsService,
              public routingService: RoutingService,
              public genericService: GenericService,
              public authService: AuthService,
              public _bottomSheet: MatBottomSheet,
              public datepipe: DatePipe) {}

  ngOnInit(): void {
    this.sticky = this.structure[this.index]['sticky'] == 1;
    this.search = this.structure[this.index]['search'] == 1;
    this.paginatorOptions = this.structure[this.index]['paginator'] === null ? null : this.structure[this.index]['paginator'] != 0 ? this.structure[this.index]['paginator'].split(',') : null
    this.idQuery = this.structure[this.index]['id_query'];
    this.tableData = this.genericService.tableData[this.structure[this.index]['id_functional_area']]['data'];
    this.arrayFiltersFields = this.genericService.tableData[this.structure[this.index]['id_functional_area']]['tableFilters'];
    this.displayedColumns = this.genericService.tableData[this.structure[this.index]['id_functional_area']]['columns'];
    if(this.tableData.length == 0 && this.structure[this.index]['form_field_empty'] == 1) this.structure[this.index]['id_functional_status_general'] = 2;
    for (let e in this.structure[this.index]['child']) {
      if (this.structure[this.index]['child'][e]['type'] == 'expansion') this.expandedCol = this.structure[this.index]['child'][e];
      this.genericService.initElementInFrontend(this.structure[this.index]['child'][e], e, this.structure[this.index]);
    }

    this.dataSource = new MatTableDataSource<any>([]);

    // Sirve para filtrar sin tener en cuenta los acentos
    this.dataSource.filterPredicate = (data: any, filter: string): boolean => {
      let dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
        const keysToIgnore = ['lastTablesActualRows', 'param_intern', 'tmp_param']; // Agrega aquí las claves que quieres ignorar
        // Verificar si la clave actual no está en la lista de claves a ignorar
        if (!keysToIgnore.includes(key)) {
            return currentTerm + (data as { [key: string]: any })[key] + '◬';
        }
        // Si es una clave a ignorar, simplemente retornar el término actual sin agregar el valor de la clave
        return currentTerm;
      }, '').normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/\s/g, '');
      let transformedFilter = filter.replace(/\s/g, '').normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    }

    if(!this.structure[this.index]['wasInitialized'] || this.genericService.staticHTML || this.structure[this.index]['id_functional_parent_initial_dsb'] > 0) {
      if(!this.genericService.staticHTML) {
        let actual_id_functional_area = this.getActualInitialFA();
        if (this.genericService.paramControlVariables[actual_id_functional_area] && this.genericService.paramControlVariables[actual_id_functional_area]['indexParam'] && this.genericService.paramControlVariables[actual_id_functional_area]['params'][this.genericService.paramControlVariables[actual_id_functional_area]['indexParam']]) {
          let param = this.genericService.paramControlVariables[actual_id_functional_area];
          if (param['params'][this.genericService.paramControlVariables[actual_id_functional_area]['indexParam']]['output']) {
            if (param['params'][this.genericService.paramControlVariables[actual_id_functional_area]['indexParam']]['output'][0]) {
              this.oldValue = param['params'][this.genericService.paramControlVariables[actual_id_functional_area]['indexParam']]['output'][0]['value']
              this.oldBdField = param['params'][this.genericService.paramControlVariables[actual_id_functional_area]['indexParam']]['output'][0]['bd_field'];
            }
          }
        }
      }
      
      this.aux = [];
      if(this.structure[this.index]['type'] == "checkbox") {
        this.aux.push({"Name":"0000-selectorCheckbox", "Order": -999999999999});
      }
      
      let delCol: string[] = [];
      for (let col in this.displayedColumns) {
        if(this.displayedColumns[col] == "0000-selectorCheckbox") continue;
        let found = false;
        if (!Array.isArray(this.displayedColumns[col]) && this.displayedColumns[col].split('-')[1] == this.oldBdField) this.oldBdField = this.displayedColumns[col]
        let i = 0;
        for (let ch in this.structure[this.index]['child']) {
          let bd_field
          this.structure[this.index]['child'][ch]['bd_field'] = this.structure[this.index]['child'][ch]['bd_field'] !== null ? this.structure[this.index]['child'][ch]['bd_field'] : ""
          if (!this.structure[this.index]['child'][ch]['bd_field'].includes(',')) bd_field = this.structure[this.index]['child'][ch]['id_functional_area'] + '-' + this.structure[this.index]['child'][ch]['bd_field'].toLowerCase()
          else bd_field = this.structure[this.index]['child'][ch]['id_functional_area'] + '-' + this.structure[this.index]['child'][ch]['bd_field'].split(',')[0].toLowerCase()
          if (this.structure[this.index]['child'][ch]['id_functional_type'] == 9 && bd_field == this.displayedColumns[col] && this.checkIfHide(ch)) {
            found = true;
            this.aux.push({
              Name: bd_field,
              Order: this.structure[this.index]['child'][ch]['order_auto_cron'],
              Sort: !(this.structure[this.index]['child'][ch]['sort'] == 0),
              Id: i,
              icon: this.structure[this.index]['child'][ch]['icon'],
              class: this.structure[this.index]['child'][ch]['icon_type'] == 'Outlined' ? "material-icons-outlined" :
                this.structure[this.index]['child'][ch]['icon_type'] == 'Filled' ? "material-icons" :
                  this.structure[this.index]['child'][ch]['icon_type'] == 'Round' ? "material-icons-round" :
                    this.structure[this.index]['child'][ch]['icon_type'] == 'Two-tone' ? "material-icons-two-tone" :
                      this.structure[this.index]['child'][ch]['icon_type'] == 'Sharp' ? "material-icons-sharp" : "material-icons",
              Tooltipp: this.structure[this.index]['child'][ch]['tooltip']
            })
            break;
          } else if (!this.structure[this.index]['child'][ch]['bd_table'] && !this.structure[this.index]['child'][ch]['bd_field'] && !this.aux.some(e => e.Name === bd_field)) {
            found = true;
            this.aux.push({
              Name: bd_field,
              Order: this.structure[this.index]['child'][ch]['order_auto_cron'],
              Sort: !(this.structure[this.index]['child'][ch]['sort'] == 1),
              Id: i,
              icon: this.structure[this.index]['child'][ch]['icon'],
              class: this.structure[this.index]['child'][ch]['icon_type'] == 'Outlined' ? "material-icons-outlined" :
                this.structure[this.index]['child'][ch]['icon_type'] == 'Filled' ? "material-icons" :
                  this.structure[this.index]['child'][ch]['icon_type'] == 'Round' ? "material-icons-round" :
                    this.structure[this.index]['child'][ch]['icon_type'] == 'Two-tone' ? "material-icons-two-tone" :
                      this.structure[this.index]['child'][ch]['icon_type'] == 'Sharp' ? "material-icons-sharp" : "material-icons",
              Tooltipp: this.structure[this.index]['child'][ch]['tooltip']
            })
          }
          i++
        }
        if (!found) {
          delCol.push(col)
        }
      }
  
      for (let col of delCol) {
        this.displayedColumns.splice(this.displayedColumns.indexOf(delCol[col]), 1);
      }
  
      this.aux.sort((a, b) => {
        if (a.Order === null || b.order === null) return 1;
        return (a.Order > b.Order) ? 1 : -1;
      })
  
      for (let e in this.aux) {
        this.displayedColumns[e] = this.aux[e].Name
      }
    } else {
      let actual_id_functional_area = this.getActualInitialFA();
      this.aux = this.genericService.paramControlVariablesFAs[actual_id_functional_area][this.genericService.paramControlVariables[actual_id_functional_area]['indexParam']][this.structure[this.index]['id_functional_area']]['wasInitializedAUX'];
      this.displayedColumns = this.genericService.paramControlVariablesFAs[actual_id_functional_area][this.genericService.paramControlVariables[actual_id_functional_area]['indexParam']][this.structure[this.index]['id_functional_area']]['wasInitializedDisplayedColumns'];
    }
    let actual_id_functional_area = this.getActualInitialFA();
    if(!this.genericService.staticHTML && !(this.structure[this.index]['id_functional_parent_initial_dsb'] > 0)) {
      if(!this.checkIfParamExists()) {
        this.genericService.paramControlVariablesFAs[actual_id_functional_area][this.genericService.paramControlVariables[actual_id_functional_area]['indexParam']][this.structure[this.index]['id_functional_area']] = {};
      }
      this.genericService.paramControlVariablesFAs[actual_id_functional_area][this.genericService.paramControlVariables[actual_id_functional_area]['indexParam']][this.structure[this.index]['id_functional_area']]['wasInitializedAUX'] = this.aux;
      this.genericService.paramControlVariablesFAs[actual_id_functional_area][this.genericService.paramControlVariables[actual_id_functional_area]['indexParam']][this.structure[this.index]['id_functional_area']]['wasInitializedDisplayedColumns'] = this.displayedColumns;
    }
    this.loaded = true;
  }

  doFilter(value : string) {
    value = value.trim().toLocaleLowerCase();
    this.dataSource.filter = value;
    this.filterSearch = value;
    this.saveFilter();
    this.calculateHeights();
  }

  saveFilter() {
    if(!this.genericService.staticHTML && !(this.structure[this.index]['id_functional_parent_initial_dsb'] > 0)) {
      let actual_id_functional_area = this.getActualInitialFA();
      if(!this.checkIfParamExists()) {
        this.genericService.paramControlVariablesFAs[actual_id_functional_area][this.genericService.paramControlVariables[actual_id_functional_area]['indexParam']][this.structure[this.index]['id_functional_area']] = {};
      }
      this.pageIndex = this.getCurrentPage();
      let par = {tableFilter: this.filterSearch, tableMultipleFilters: this.arrayFiltersFields, pageIndex: this.pageIndex, lastSort: this.lastSort};
      let idFa = this.genericService.cloneVariable(this.structure[this.index]['id_functional_area']).toString();
      this.genericService.updateParamControlFA(idFa, actual_id_functional_area, this.genericService.paramControlVariables[actual_id_functional_area]['indexParam'], par);
      this.genericService.paramControlVariablesFAs[actual_id_functional_area][this.genericService.paramControlVariables[actual_id_functional_area]['indexParam']][idFa]['tableFilter'] = this.filterSearch;
      this.genericService.paramControlVariablesFAs[actual_id_functional_area][this.genericService.paramControlVariables[actual_id_functional_area]['indexParam']][idFa]['tableMultipleFilters'] = this.arrayFiltersFields;
      this.genericService.paramControlVariablesFAs[actual_id_functional_area][this.genericService.paramControlVariables[actual_id_functional_area]['indexParam']][idFa]['pageIndex'] = this.pageIndex;
      this.genericService.paramControlVariablesFAs[actual_id_functional_area][this.genericService.paramControlVariables[actual_id_functional_area]['indexParam']][idFa]['lastSort'] = this.lastSort;
    }
  }
  
  getRowId(rowID: any, originalRowID: any) {
    if(originalRowID !== undefined) rowID = originalRowID;
    return rowID;
  }

  rowClick(event : any, row : any, rowID: any) : void {
    if(this.structure[this.index]['type'] != "checkbox") {
      if(this.oldBdField !== undefined && this.oldBdField !== null && this.oldBdField !== '') {
        this.oldValue = row[this.oldBdField]
      }
      this.pushActualRow(rowID);
      this.functionsService.getFunction(this.structure[this.index], this.genericService.tableData[this.structure[this.index]['id_functional_area']]['data'][rowID]);
    }
  }

  massiveAction() {
    let selected = this.selection.selected;
    let selectedRows = [];
    let isFirst = true;
    for(let i in selected) {
      selectedRows.push(selected[i]);
      if(!this.isAllSelected()) {
        this.pushActualRow(selected[i]['rowID'], isFirst);
        if(isFirst) isFirst = false;
      }
    }
    let ele = this.genericService.cloneVariable(this.structure[this.index]);
    ele['massive_action'] = true; // Lo ponemos porque sino los params interns no actuan como si fuese un boton sino como una table y solo coje esos params y no los de toda la pantalla
    this.functionsService.getFunction(ele, selectedRows);
  }

  rowClickImage(event : any, row : any, rowID: any, id: any) : void {
    if(this.oldBdField !== undefined && this.oldBdField !== null && this.oldBdField !== '') {
      this.oldValue = row[this.oldBdField]
    }
    this.pushActualRow(rowID);
    this.functionsService.getFunction(this.structure[this.index]['child'][id], this.genericService.tableData[this.structure[this.index]['id_functional_area']]['data'][rowID]);
  }

  pushActualRow(rowID, isFirst = true) {
    if(isFirst) {
      this.genericService.tablesActualRows = [];
      this.oldValue = -1;
     }
    this.genericService.tablesActualRows.push({"id_functional_area": this.structure[this.index]['id_functional_area'], "rowID": rowID})
    this.actualRow = null;
  }

  public isHighlightRow(oldBdField, rowID, row) {
    if(this.genericService.tablesActualRows && this.genericService.tablesActualRows.length && this.genericService.tablesActualRows.length > 0 && (row['isHighlightRow'] === undefined || row['lastTablesActualRows'] === undefined || this.genericService.tablesActualRows != row['lastTablesActualRows'])) {
      row['lastTablesActualRows'] = this.genericService.tablesActualRows;
      for(let x in this.genericService.tablesActualRows) {
        if(this.genericService.tablesActualRows[x]['id_functional_area'] == this.structure[this.index]['id_functional_area'] && rowID == this.genericService.tablesActualRows[x]['rowID']) {
          row['isHighlightRow'] = true;
          return true;
        }
      }
      row['isHighlightRow'] = oldBdField == this.oldValue;
      return oldBdField == this.oldValue;
    } else {
      return row['isHighlightRow'];
    }
  }

  checkArray(element: any) {
    return Array.isArray(element) && element.length > 1;
  }

  private isValidDate(str: string) {
    // Regular expression to check if string is valid date
    const regexExp = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/gi;
    return regexExp.test(str);
  }

  ngAfterViewInit() {
    if(this.sort) this.dataSource.sort = this.sort;

    // Joan: Codi per ordenar nombres i dates - Caldrà revisar quan es localitzi per altres països
    this.dataSource.sortingDataAccessor = (item, property) => {
      let filter = this.genericService.cloneVariable(item[property]);
      let idFaSplited = property.split('-');
      let idFA = null;
      if(idFaSplited[0]) idFA = this.genericService.findElementWithId(idFaSplited[0], false, false, true);
      if(idFA && filter && typeof filter == "string") {
        if(idFA['hint']) filter = filter.replace(idFA['hint'], "");
        if(idFA['text']) filter = filter.replace(idFA['text'], "");
      }
      if (typeof filter == "string" && this.isValidDate(filter.substring(0, 10))) {
        return filter.substring(6, 10) + filter.substring(3, 5) + filter.substring(0, 2) + filter.substr(10);
      } else if (typeof filter == "string" && !isNaN(parseFloat(filter.replace(/\./g, "").replace(",", ".")))) { // ...and ensure strings of whitespace fail
        return parseFloat(filter.replace(/\./g, "").replace(",", "."));
      } else {
        return filter;
      }
    }
    
    if(this.paginatorOptions !== null) this.dataSource.paginator = this.paginator;
    this.dataSource.data = this.tableData;
    
    if(!this.genericService.staticHTML && !(this.structure[this.index]['id_functional_parent_initial_dsb'] > 0)) {
      let actual_id_functional_area = this.getActualInitialFA();
      if (this.checkIfParamExists()) {
        let param = this.genericService.paramControlVariablesFAs[actual_id_functional_area][this.genericService.paramControlVariables[actual_id_functional_area]['indexParam']][this.structure[this.index]['id_functional_area']];
        if(param && param !== undefined && param !== null) {     
          
          if (param['tableFilter']) { // Aplicar el último Search
            this.dataSource.filter = param['tableFilter'];
            this.doFilter(param['tableFilter']);
          }

          if(param['paginator']) { // Aplicar el último Paginator
            this.paginator = param['paginator'];
          }

          if(param['tableMultipleFilters']) { // Aplicar los últimos Filtros
            let paramTableFilters = param['tableMultipleFilters'];
            for(let i in paramTableFilters) {
              let valueOldFilter = paramTableFilters[i]['filter'];
              if(this.arrayFiltersFields[i]) {
                if(this.arrayFiltersFields[i]['type'] == 1999999) {
                  if(this.arrayFiltersFields[i]['filter'] === undefined) this.arrayFiltersFields[i]['filter'] = {fechaInicio: null, fechaFin: null};
                  this.arrayFiltersFields[i]['filter'] = valueOldFilter;
                } else if(this.arrayFiltersFields[i]['type'] == 191) {
                  if(this.arrayFiltersFields[i]['filter'] === undefined) this.arrayFiltersFields[i]['filter'] = {min: null, max: null};
                  this.arrayFiltersFields[i]['filter'] = valueOldFilter;
                } else if(this.arrayFiltersFields[i]['type'] == 124) {
                  if(this.arrayFiltersFields[i]['filter'] === undefined) this.arrayFiltersFields[i]['filter'] = [];
                  this.arrayFiltersFields[i]['filter'] = valueOldFilter;
                } else {
                  let actualValues = this.arrayFiltersFields[i]['value'];
                  if(!Array.isArray(valueOldFilter)) {
                    for(let j in actualValues) {
                      if(actualValues[j] == valueOldFilter) {
                        if(this.arrayFiltersFields[i]['filter'] === undefined) this.arrayFiltersFields[i]['filter'] = [];
                        this.arrayFiltersFields[i]['filter'] = valueOldFilter;
                        break;
                      }
                    }
                  } else {
                    for(let j in valueOldFilter) {
                      let founded = false;
                      for(let k in actualValues) {
                        if(actualValues[k] == valueOldFilter[j]) {
                          founded = true;
                          break;
                        }
                      }
                      if(founded) {
                        if(this.arrayFiltersFields[i]['filter'] === undefined) this.arrayFiltersFields[i]['filter'] = [];
                        let found = false;
                        for(let l in this.arrayFiltersFields[i]['filter']) {
                          if(this.arrayFiltersFields[i]['filter'][l] == valueOldFilter[j]) {
                            found = true;
                            break;
                          }
                        }
                        if(!found) {
                          this.arrayFiltersFields[i]['filter'].push(valueOldFilter[j]);
                        }
                      }
                    }
                  }
                }
              }
            }
            this.applyFilters();
          }

          if(param['pageIndex'] && param['pageIndex'] < this.getNumPages()) { // Ir a la página que estábamos
            this.pageIndex = param['pageIndex'];
          }

          if(param['lastSort']) { // Aplicar el ordenar
            this.lastSort = param['lastSort'];
          }
          
          this.applySort(); // Hay que hacerlo siempre pues si no hay lastSort pero si pageIndex, el pageIndex falla
        }  
      }
    }

    this.structure[this.index]['expandedElement'] = this.tableData.find(row => row[this.oldBdField] === this.oldValue)
    if(this.expandedCol !== null && this.oldValue !== null && this.oldValue !== undefined && this.oldValue != -1 && this.oldBdField !== null && this.oldBdField !== undefined && this.oldBdField != '') {
      let from = 0;
      if(this.structure[this.index]['id_functional_parent_initial'] == this.genericService.headerId || this.structure[this.index]['id_functional_parent_initial'] == this.genericService.headerNoLoguedId) from = 1;
      if(this.structure[this.index]['id_functional_parent_initial'] == this.genericService.footerId || this.structure[this.index]['id_functional_parent_initial'] == this.genericService.footerNoLoguedId) from = 2;
      this.genericService.loadExpansion(this.tableData.find(row => row[this.oldBdField] === this.oldValue), from)
    }   
    this.loadedAfterView = true;
    this.calculateHeights();
    let actual_id_functional_area = this.getActualInitialFA();
    this.structure[this.index]['wasInitialized'] = true;
  }
 
  applySort() {
    setTimeout(() => { // Need to put timeout because I need to wait to the table to be loaded, at least de paginator if not will initialize the table with the first page always (and will say the correct page in the paginator but will show the first page)
      this.dataSource.sort.active = this.lastSort.active;
      this.dataSource.sort.direction = this.lastSort.direction;
      this.dataSource.sort.sortChange.emit(this.lastSort);
    });
  }

  ngOnDestroy() {
    this.saveFilter();
  }

  calculateHeights() {
    this.genericService.heightTableChange = false;
    setTimeout(() => {
      this.getHeightV = this.getHeight();
      this.getHeightTable2V = this.getHeightTable2();
      this.getHeightTableV = this.getHeightTable();
    }, 0);
  }

  checkIfHide(ch) {
    if(this.structure[this.index]['child'][ch]['id_functional_status_general'] === 2 || this.structure[this.index]['child'][ch]['hide']  == true || this.structure[this.index]['child'][ch]['hide']  == 'true' || this.structure[this.index]['child'][ch]['hide'] == 1) return false;
    else return true
  }

  iconClick(id: any) {
    this.functionsService.getFunction(this.structure[this.index]['child'][id]);
  }

  arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
      if (JSON.stringify(a[i]) !== JSON.stringify(b[i])) return false;
    }
    return true;
  }

  public expansionChange(row) {
    this.functionsService.getFunction(this.structure[this.index], row, 47)
  }

  public getHeight() {
    let boxTable0 = document.getElementsByClassName('mat-elevation-z3-' + this.structure[this.index]['id_functional_area'])[0];
    let boxTableFinal = 0;
    if(boxTable0 !== undefined) {
      let boxTable = boxTable0.getBoundingClientRect();
      boxTableFinal = boxTable.height;
    }

    let boxNoRows0 = document.getElementsByClassName('noRowsGeneric-' + this.structure[this.index]['id_functional_area'])[0];
    let boxNoRowsFinal = 0;
    if(boxNoRows0 !== undefined) {
      let boxNoRows = boxNoRows0.getBoundingClientRect();
      boxNoRowsFinal = boxNoRows.height;
    }
    
    boxTableFinal = boxTableFinal + boxNoRowsFinal;
    return boxTableFinal + 18;
  }

  public getHeightTable() {
    return this.getHeightFilter() + this.getHeightBarTools();
  }

  public getHeightTable2() {
    let box0 = document.getElementsByClassName('mat-dialog-content')[0];
    let boxFinal = 0;
    if(box0 !== undefined) {
      let box = box0.getBoundingClientRect();
      boxFinal = box.height;
    }
    boxFinal = boxFinal - this.getHeightFilter();
    let fullHeight = +this.getHeight() - this.getHeightFilter();

    if(fullHeight > boxFinal) boxFinal = fullHeight;
    return boxFinal;
  }

  public getHeightBarTools() {
    let barTools0 = document.getElementsByClassName('bar-tools')[0];
    let barToolsFinal = 0;
    if(barTools0 !== undefined) {
      let barTools = barTools0.getBoundingClientRect();
      barToolsFinal = barTools.height;
    }
    return barToolsFinal;
  }

  public getHeightFilter() {
    let boxFilter0 = document.getElementsByClassName('bottom-table-generic-' + this.structure[this.index]['id_functional_area'])[0];
    let boxFilterFinal = 0;
    if(boxFilter0 !== undefined) {
      let boxFilter = boxFilter0.getBoundingClientRect();
      boxFilterFinal = boxFilter.height;
    }
    return boxFilterFinal;
  }

  public appearProfilePermission(idFa, rowKey) {
    let e = "profile-permissions-appear-" + idFa;
    if(rowKey[e] !== undefined && rowKey[e] == 0) return false;
    return true;
  }

  public openFilterTable() {
    this.arrayFiltersFieldsInit = this.genericService.cloneVariable(this.arrayFiltersFields);
    this.bottomSheetRef = this._bottomSheet.open(BottomSheetFilterTableComponent, {
      panelClass: "filter-table-generic-bottomsheet",
      data: this.arrayFiltersFields,
    });
    this.bottomSheetRef.afterDismissed().subscribe(data => {
      if(data) {
        switch(data) {
          case 1:
            this.filtersCount = 0;
            this.dataSource.data = this.tableData;
            this.saveFilter();
            break;
          case 2:
            this.filtersCount = 0;
            this.applyFilters();
            this.saveFilter();
            break;
          case 3:
            this.arrayFiltersFields = this.genericService.cloneVariable(this.arrayFiltersFieldsInit);
            break;
        }
        this.calculateHeights();
      }
    });
  }

  private applyFilters() {
    this.filtersCount = 0;
    this.loadedFilter = false;
    let newData = this.tableData;
    let filters = this.arrayFiltersFields;
    for(let i in filters) {
      if(filters[i]['type'] == 124) {
        if(filters[i]['filter'] === 0 || filters[i]['filter'] === 1) {
          ++this.filtersCount;
          let newData2 = [];
          for(let j in newData) {
            if(newData[j]['originalRowID'] === undefined) newData[j]['originalRowID'] = j;
            let originValue = newData[j][filters[i]['nameOriginal']];
            let filterValue = filters[i]['filter'];
            if(filters[i]['filter'] === 0) {
              if(!Array.isArray(originValue)) {
                if(filterValue == originValue) newData2.push(newData[j]);
              } else {
                for(let l in originValue) {
                  if(filterValue == originValue[l]) {
                    newData2.push(newData[j]);
                    break;
                  }
                }
              }
            } else {
              if(!Array.isArray(originValue)) {
                if(0 < originValue) newData2.push(newData[j]);
              } else {
                for(let l in originValue) {
                  if(0 < originValue[l]) {
                    newData2.push(newData[j]);
                    break;
                  }
                }
              }
            }
          }
          newData = newData2;
        }
      } else if(filters[i]['type'] == 191) {
        let max = null;
        if(filters[i]['filter'].max) max = filters[i]['filter'].max;
        let min = null;
        if(filters[i]['filter'].min) min = filters[i]['filter'].min;
        if(min > 0 || max > 0) {
          ++this.filtersCount;
          let newData2 = [];
          for(let j in newData) {
            if(newData[j]['originalRowID'] === undefined) newData[j]['originalRowID'] = j;
            let originValue = this.prepareAsNumber(newData[j][filters[i]['nameOriginal']]);
            if((min == 0 || originValue >= min) && (max == 0 || originValue <= max)) {
              newData2.push(newData[j]);
            }
          }
          newData = newData2;
        }
      } else if(filters[i]['type'] == 1999999) {
        let fechaInicio = null;
        if(filters[i]['filter'].fechaInicio) {
          fechaInicio = new Date(filters[i]['filter'].fechaInicio).setHours(2,0,0,0);
        }
        let fechaFin = null;
        if(filters[i]['filter'].fechaFin) {
          fechaFin = new Date(filters[i]['filter'].fechaFin).setHours(2,0,0,0);
        }
        if(fechaInicio != null || fechaFin != null) {
          let newData2 = [];
          ++this.filtersCount
          for(let j in newData) {
            if(newData[j]['originalRowID'] === undefined) newData[j]['originalRowID'] = j;
            let originValue = newData[j][filters[i]['nameOriginal']];
            if(!Array.isArray(originValue)) {
              originValue = this.tratarDate(originValue, fechaInicio);
              if((fechaInicio == null || fechaInicio <= originValue) && (fechaFin == null || originValue <= fechaFin)) newData2.push(newData[j]);
            } else {
              for(let l in originValue) {
                originValue = this.tratarDate(originValue, fechaInicio);
                if((fechaInicio == null || fechaInicio <= originValue) && (fechaFin == null || originValue <= fechaFin)) {
                  newData2.push(newData[j]);
                  break;
                }
              }
            }
          }
          newData = newData2;
        }
      } else {
        if(filters[i]['filter'].length > 0) {
          ++this.filtersCount;
          let newData2 = [];
          for(let j in newData) {
            if(newData[j]['originalRowID'] === undefined) newData[j]['originalRowID'] = j;
            let originValue = newData[j][filters[i]['nameOriginal']];
            let filterValue = filters[i]['filter'];
            nextRowToFilter:
            if(!Array.isArray(filterValue)) {
              if(!Array.isArray(originValue)) {
                if(originValue && filterValue && filterValue.trim() == originValue.trim()) {
                  newData2.push(newData[j]);
                  break nextRowToFilter;
                }
              } else {
                for(let l in originValue) {
                  if(originValue[l] && filterValue && filterValue.trim() == originValue[l].trim()) {
                    newData2.push(newData[j]);
                    break nextRowToFilter;
                  }
                }
              }
            } else {
              for(let k in filterValue) {
                if(!Array.isArray(originValue)) {
                  if(originValue && filterValue[k] && filterValue[k].trim() == originValue.trim()) {
                    newData2.push(newData[j]);
                    break nextRowToFilter;
                  }
                } else {
                  for(let l in originValue) {
                    if(originValue[l] && filterValue[k] && filterValue[k].trim() == originValue[l].trim()) {
                      newData2.push(newData[j]);
                      break nextRowToFilter;
                    }
                  }
                }
              }
            }
          }
          newData = newData2;
        }
      }
    }
    newData.sort(function (a, b) {
      if (a.originalRowID > b.originalRowID) return 1;
      if (a.originalRowID < b.originalRowID) return -1;
      return 0;
    });
    this.dataSource.data = newData;
    this.loadedFilter = true;
  }

  private tratarDate(originValue, fechaInicio) {
    if(originValue == null || originValue === undefined) {
      if(fechaInicio != null) originValue = 0;
      else originValue = 9999999999999999999999999999999;
    } else {
      originValue = originValue.split(" ")[0];
      originValue = originValue.split(",")[0];
      let values = originValue.split("-");
      if(values[2] && values[2].length == 4) originValue = values[2] + "-" + values[1] + "-" + values[0];
      originValue = new Date(originValue);
      originValue = originValue.getTime();
    }
    return originValue;
  }

  private prepareAsNumber(value) {
    if(isNaN(value)) {
      value = value.split(',')[0];
      value = value.replaceAll('.', '');
      value = value.replaceAll(' ', '');
      value = value.replaceAll('€', '');
      value = value.replaceAll('$', '');
    }
    return value;
  }

  public expandedColumn() {
    if(this.genericService.heightTableChange) this.calculateHeights();
    return this.expandedCol !== null;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.filteredData);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  checkSelectionMultiple(i, row) {
    row['rowID'] = i;
    this.selection.toggle(row);
    return true;
  }

  onPageChange(event) {
    this.pageIndex = event.pageIndex;
    this.saveFilter();
  }

  getActualInitialFA() {
    let actual_id_functional_area = this.structure[this.index]['id_functional_parent_initial'];
    if(this.structure[this.index]['id_functional_parent_initial_dsb'] > 0) {
      actual_id_functional_area = this.structure[this.index]['id_functional_parent_initial_dsb'];
    }
    return actual_id_functional_area;
  }

  onColumnSelectSort(column: string) {
    const currentSort = this.dataSource.sort;
    let sortState: Sort = { active: '', direction: '' };
    if(currentSort && currentSort.active) {
      const isAsc = currentSort.active === column && currentSort.direction === 'asc';
      const direction = isAsc ? 'asc' : 'desc';
      let newSortState: Sort = { active: column, direction: direction };
      if(JSON.stringify(this.lastSort) != JSON.stringify(newSortState)) sortState = newSortState;
    }
    this.lastSort = sortState;
    this.saveFilter();
  }
  
  checkIfParamExists() {
    let actual_id_functional_area = this.getActualInitialFA();
    let respInit = this.genericService.paramControlVariablesFAs[actual_id_functional_area] !== undefined &&
      this.genericService.paramControlVariables[actual_id_functional_area]['indexParam'] !== undefined &&
      this.genericService.paramControlVariablesFAs[actual_id_functional_area][this.genericService.paramControlVariables[actual_id_functional_area]['indexParam']] !== undefined;
    if(!respInit) this.genericService.paramControlVariablesFAs[actual_id_functional_area][this.genericService.paramControlVariables[actual_id_functional_area]['indexParam']] = new Map();
    let resp = this.genericService.paramControlVariablesFAs[actual_id_functional_area][this.genericService.paramControlVariables[actual_id_functional_area]['indexParam']][this.structure[this.index]['id_functional_area']] !== undefined;
    return resp;
  }

  getNumPages() {
      // Obtén el número total de elementos después de aplicar cualquier filtro
      const elementosFiltrados = this.dataSource.filteredData.length;

      // Obtén el tamaño de la página de la configuración del paginador
      const elementosPorPagina = this.dataSource.paginator?.pageSize || 1;

      // Calcula el número total de páginas
      return Math.ceil(elementosFiltrados / elementosPorPagina);
  }

  // Método para obtener el número de la página actual
  getCurrentPage(): number {
    // Asegúrate de que el paginador esté disponible
    if (this.paginator) {
      // La propiedad 'pageIndex' del paginador te da el número de la página actual
      return this.paginator.pageIndex;
    }
    return 0; // Valor predeterminado si el paginador no está disponible
  }
}