<div class="notis0" [ngStyle]="{'background': background}">
  <div class="each-noti">
    <span style="display: margin: 0.8em;" fxLayout="row wrap">
      <span fxFlex="100" class="tittle-noti" *ngIf="!personalizado" [ngStyle]="{'color': colorTitle }"><i *ngIf="iconActive" id="iconing" [className]="'material-icons'" matTooltip="{{iconTooltipe}}" [ngStyle]="{'color': colorIcon}">{{icon}}</i>{{title}}</span>
      <span fxFlex="100" class="subtittle-noti" *ngIf="!personalizado" [ngStyle]="{'color': colorSubtitle }">{{subtitle}}</span>
      <span class="text-noti" *ngIf="personalizado" [innerHTML]="mensaje | safeHtml"></span>
      <span fxFlex="100" class="container-buttons">
        <button *ngIf="button1" (click)="buttonAction(0)"  matTooltip="{{button1Tooltipe}}" [ngStyle]="{'background': button1Background, 'color': button1Color, 'border': '1px solid ' + button1Border}"><i *ngIf="button1ActiveIcon" [className]="'material-icons'" [ngStyle]="{'color': button1IconColor}">{{button1Icon}}</i>{{button1Text}}</button>
        <button *ngIf="button2" (click)="buttonAction(1)"  matTooltip="{{button2Tooltipe}}" [ngStyle]="{'background': button2Background, 'color': button2Color, 'border': '1px solid ' + button2Border}"><i *ngIf="button2ActiveIcon" [className]="'material-icons'" [ngStyle]="{'color': button2IconColor}">{{button2Icon}}</i>{{button2Text}}</button>
        <button *ngIf="button3" (click)="buttonAction(2)" matTooltip="{{button3Tooltipe}}" [ngStyle]="{'background': button3Background, 'color': button3Color, 'border': '1px solid ' + button3Border}"><i *ngIf="button3ActiveIcon" [className]="'material-icons'" [ngStyle]="{'color': button3IconColor}">{{button3Icon}}</i>{{button3Text}}</button>
      </span>
    </span>
    <mat-icon *ngIf="close" class="removeit" (click)="closeSnackBar()" [ngStyle]="{'color': colorClose}">cancel</mat-icon>
  </div>
</div>
