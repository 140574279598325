import {Component, Input, OnInit} from '@angular/core';
import { MonitoringActions, MonitoringDecorator } from "../../services/track-decorators";
import { Strings } from "../../../strings/strings";
import { EndpointService } from "../../services/endpoint/endpoint.service";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth/auth.service";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { CommunityFunctionsService } from 'app/services/functions/community-functions.service';

@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.scss']
})
export class CommunityComponent implements OnInit {

  @Input() structure;

  // Structure
  public showColaboration: boolean;

  // Variables para mostrar el perfil de una inmo
  public showPerfilInmo = false;
  public inmoURLperfil: string;
  public inmoURLperfilId: number;
  public isInmoPerfil: boolean;

  // Variables para filtrar adecuadamente las inmos/grupos
  public inmoId: number;
  public groupId: number;
  public puede_colaborar: number;
  public responsable_community: number;

  public type: number; // indica si somos una inmo (type === 1) o de un grupo (type === 2)
  public id: number; // id de la inmo o grupo
  public permisos: boolean; // indica si el usuario es responsable de la community

  // Variables para mostrar las inmos/grupos
  public inmos: any;
  public inmosToShow: any;
  public inmosLoaded: boolean;
  public originalInmos: any;

  public buscar: string;
  public filterSelected: number;

  public hideCommunity: boolean;

  constructor(
    private endpointService: EndpointService,
    private router: Router,
    private authService: AuthService,
    private communityFunctions: CommunityFunctionsService
  ) { }

  ngOnInit(): void {

    this.checkStructure();

    this.voidMethodForMonitoring(null);
    this.inmosLoaded = false;
    this.buscar = '';
    this.filterSelected = 0;

    this.endpointService.checkIfVisible(1).subscribe(data => {
      if (data['response'] == 1) {
        this.hideCommunity = false;
      } else {
        this.hideCommunity = true;
      }
      let p = new Promise<void>((resolve, reject) => {
        setTimeout(async () => {
          this.inmoId = this.authService.inmoId;
          this.groupId = this.authService.groupId;
          this.puede_colaborar = this.authService.puede_colaborar;
          this.responsable_community = this.authService.responsable_community;
          resolve();
        }, 300);
      });

      p.then(res => {
        if (this.inmoId === null && this.groupId !== null) {
          this.type = 2;
          this.id = this.groupId;
        } else {
          this.type = 1;
          this.id = this.inmoId;
        }

        if (this.showColaboration) {
          this.permisos = true;
          this.getTodo(0);
        } else {
          this.permisos = false;
          // this.getTodo(1);
          this.getAll();
        }


        let i = localStorage.getItem("communityInmo");
        localStorage.removeItem("communityInmo");

        if(i){

          //TODO: QUERY PARA RECOGER INMOINFO (Igual que lista inmosCommunity)

          this.mostrarPerfilInmo(i);
        }

      });
    });

    /* OLD
    this.endpointService.checkIfVisible(1).subscribe(data => {
      if (data['response'] == 1) {
        this.hideCommunity = false;
      } else {
        this.hideCommunity = true;
      }
      let p = new Promise((resolve, reject) => {
        setTimeout(async () => {
          this.inmoId = this.authService.inmoId;
          this.groupId = this.authService.groupId;
          this.puede_colaborar = this.authService.puede_colaborar;
          this.responsable_community = this.authService.responsable_community;
          resolve();
        }, 300);
      });

      p.then(res => {
        if (this.inmoId === null && this.groupId !== null) {
          this.type = 2;
          this.id = this.groupId;
        } else {
          this.type = 1;
          this.id = this.inmoId;
        }
        if (this.puede_colaborar === 1) {
          // tabs
          if (this.responsable_community === 1) {
            this.permisos = true;
            this.getTodo(0);
          } else {
            this.permisos = false;
            this.getTodo(1);
          }
        } else {
          // No tabs
          this.getAll();
        }

        let i = localStorage.getItem("communityInmo");
        localStorage.removeItem("communityInmo");

        if(i){

          //TODO: QUERY PARA RECOGER INMOINFO (Igual que lista inmosCommunity)

          this.mostrarPerfilInmo(i);
        }

      });
    });
    */
  }

  @MonitoringDecorator(MonitoringActions.VIEW, Strings.USERTRACKVIEWCODE.COMMUNITY_SEARCH)
  private voidMethodForMonitoring(id) {
    return id;
  }

  public onPageChange($event): void {
    this.inmosToShow = this.inmos.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
  }

  /* POL:
  Existen 4 llamdas para obtener las inmos/grupos que se mostraran en la community:
  --> getTodo: Obtiene todas las inmos/grupos (ordenadas por: pendientes/buscar colb./colaborando). Se le pasa por parametro el
  estado. Si estado === 0, el usuario es responsable de community por lo que puede ver las inmos/grupos pendientes, si estado === 1,
  el usuario NO puede ver las inmos/grupos pendientes y estas apareceran directamente en buscar colab.
  --> getColaboradores: Obtiene las inmos/grupos con los que no se esta colaborando ni esta pendiente. Se le pasa por parametro el
  el estado (misma lógica que getTodo)
  --> getMisColaboradores: Obtiene las inmos/grupos con los que se esta colaborando.
  --> getSolicitudesPendientes: Obtiene las inmos/grupos con los que se tiene estado de colaboración pendiente.

  En todas estas llamadas se les pasa el type (que indica si somos una inmo (type === 1) o de un grupo (type === 2)), ya que
  segun somos inmo o grupo, las querys para obtener comprovaciones son diferentes.

  Valores que me tiene que devolver cualquier query:
  --> colaborationId: id de la colaboracion (si es que hay)
  --> esMLS: 0--> es inmo || 1--> es grupo
  --> tipo_perfil: 1--> publico || 2--> privado
  --> (respecto a mi inmo/grupo) tipo: 1--> sin colab. || 2--> mi colaborador || 3--> colaboración pendiente
  --> (si tipo === 3) esSolicitada: 0--> no ha sido invitada  || 1--> ha sido invitada
  --> mensajes: 1-->cualquiera || 2-->solo colaboradores

  Comprovaciones:
  [ OK ] - Inmo --> getTodo: type = 1, estado = 0
  [ OK ] - Inmo --> getTodo: type = 1, estado = 1
  [ OK ] - Grupo --> getTodo: type = 2, estado = 0
  [ OK ] - Grupo --> getTodo: type = 2, estado = 1
  ------------------------------------------------
  [ OK ] - Inmo --> getColaboradores: type = 1, estado = 0
  [ OK ] - Inmo --> getColaboradores: type = 1, estado = 1
  [ OK ] - Grupo --> getColaboradores: type = 2, estado = 0
  [ OK ] - Grupo --> getColaboradores: type = 2, estado = 1
  ------------------------------------------------
  [ OK ] - Inmo --> getMisColaboradores: type = 1
  [ OK ] - Grupo --> getMisColaboradores: type = 2
  ------------------------------------------------
  [ OK ] - Inmo --> getSolicitudesPendientes: type = 1
  [ OK ] - Grupo --> getSolicitudesPendientes: type = 2
  ------------------------------------------------
  */

  public getTodo(estado: number): void {
    let x = {id_function: 500};
    let params = {that: this, estado: estado};
    this.communityFunctions.getFunction(x, params);
    /*
    this.inmosLoaded = false;
    this.endpointService.getTodo(this.id, this.type, estado, this.authService.userId).subscribe(data => {
      if (data['errorCode'] === 0) {
        this.inmos = data['response'];
        this.inmosToShow = this.inmos.slice(0, 40);
        this.inmosLoaded = true;
        this.originalInmos = this.inmos;
      }
    });*/
  }

  public getColaboradores(estado: number): void {
    let x = {id_function: 501};
    let params = {that: this, estado: estado};
    this.communityFunctions.getFunction(x, params);
    /* 
    this.inmosLoaded = false;
    this.endpointService.getBuscarColaboradores(this.id, this.type, estado, this.authService.userId).subscribe(data => {
      if (data['errorCode'] === 0) {
        this.inmos = data['response'];
        this.inmosToShow = this.inmos.slice(0, 40);
        this.inmosLoaded = true;
        this.originalInmos = this.inmos;
      }
    });*/
  }

  public getMisColaboradores(): void {
    let x = {id_function: 502};
    let params = {that: this};
    this.communityFunctions.getFunction(x, params);
    /*this.inmosLoaded = false;
    this.endpointService.getMisColaboradores(this.id, this.type, this.authService.userId).subscribe(data => {
      if (data['errorCode'] === 0) {
        this.inmos = data['response'];
        this.inmosToShow = this.inmos.slice(0, 40);
        this.inmosLoaded = true;
        this.originalInmos = this.inmos;
      }
    });*/
  }

  public getSolicitudesPendientes(): void {
    let x = {id_function: 503};
    let params = {that: this};
    this.communityFunctions.getFunction(x, params);
    /*
    this.inmosLoaded = false;
    this.endpointService.getSolicitudesPendientes(this.id, this.type, this.authService.userId).subscribe(data => {
      if (data['errorCode'] === 0) {
        this.inmos = data['response'];
        this.inmosToShow = this.inmos.slice(0, 40);
        this.inmosLoaded = true;
        this.originalInmos = this.inmos;
      }
    });*/
  }

  public getAll(): void {
    let x = {id_function: 504};
    let params = {that: this};
    this.communityFunctions.getFunction(x, params);
    /*this.inmosLoaded = false;
    this.endpointService.getAllInmosAndMLS(this.id, this.type, this.authService.userId).subscribe(data => {
      if (data['errorCode'] === 0) {
        this.inmos = data['response'];
        this.inmosToShow = this.inmos.slice(0, 40);
        this.inmosLoaded = true;
        this.originalInmos = this.inmos;
      }
    });*/
  }

  public changeBuscar(newValue): void {
    let x = {id_function: 505};
    let params = {that: this,newValue:newValue};
    this.communityFunctions.getFunction(x, params);
    /*
    newValue = newValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    this.inmosLoaded = false;
    this.inmos = this.originalInmos.filter(inmo =>
      (inmo.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()).includes(newValue)
      //|| (inmo.slogan.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()).includes(newValue)
    );
    this.inmosToShow = this.inmos.slice(0, 40);
    this.inmosLoaded = true;*/
  }

  public mostrarPerfilInmo(inmo: any) {
    let x = {id_function: 506};
    let params = {that: this,inmo:inmo};
    this.communityFunctions.getFunction(x, params);
    /*
    let elmnt = document.getElementById("containerbuscar");
    elmnt.scrollTop = 0;
    this.showPerfilInmo = true;
    this.inmoURLperfil = inmo.url_canonica;
    this.inmoURLperfilId = inmo.id;
    this.isInmoPerfil = (inmo.esMLS === 0) ? true : false;*/
  }

  public mostrarBuscar() {
    this.showPerfilInmo = false;
    this.inmoURLperfil = null;
    this.isInmoPerfil = null;

    this.changeFilter(this.filterSelected,true);
  }

  public changeFilter(n: number, igual: boolean): void {
    if (igual === true) {
      this.filterSelected = n;
      switch (n) {
        case 0: { // All
          if (this.permisos === true) this.getTodo(0);
          else this.getTodo(1);
          break;
        }
        case 1: { // Buscar colaboradores
          if (this.permisos === true) this.getColaboradores(0);
          else this.getColaboradores(1);
          break;
        }
        case 2: { // Mis colaboradores
          this.getMisColaboradores();
          break;
        }
        case 3: { // Solicitudes pendientes
          this.getSolicitudesPendientes();
          break;
        }
      }
    } else {
      if (n !== this.filterSelected) {
        this.filterSelected = n;
        switch (n) {
          case 0: { // All
            if (this.permisos === true) this.getTodo(0);
            else this.getTodo(1);
            break;
          }
          case 1: { // Buscar colaboradores
            if (this.permisos === true) this.getColaboradores(0);
            else this.getColaboradores(1);
            break;
          }
          case 2: { // Mis colaboradores
            this.getMisColaboradores();
            break;
          }
          case 3: { // Solicitudes pendientes
            this.getSolicitudesPendientes();
            break;
          }
        }
      }
    }
  }

  tabChanged($event: MatTabChangeEvent) {
    if($event.index === 0) {
      this.changeFilter(this.filterSelected, true);
    }
  }
  
  checkStructure() {
    if(this.structure != undefined){
      for(let i in this.structure){
        if(this.structure[i]['id_functional_type'] == 1){
          this.showColaboration = (this.structure[i]['id_functional_type'] === 5 && this.structure[i]['id_functional_status_mkt'] === 1) ? true : false;
        }
      }
    }
  }

}
