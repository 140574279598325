<form>
  <div>
    <mat-checkbox [(ngModel)]="active" [ngModelOptions]="{standalone: true}">
      Activar
    </mat-checkbox>
  </div>
</form>

<form>

  <mat-form-field>
    <input matInput type="text" placeholder="Icono" [(ngModel)]="icon" [ngModelOptions]="{standalone: true}" size="50"
           autocomplete="off">
  </mat-form-field>

  <mat-form-field>
    <mat-label>Tipo</mat-label>
    <mat-select [(value)]="tipo">
      <mat-option *ngFor="let t of arrayTipo" [value]="t.id">{{t.nombre}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <input matInput type="color" placeholder="Color" [(ngModel)]="iconColor" [ngModelOptions]="{standalone: true}"
           autocomplete="off">
  </mat-form-field>

  <mat-form-field>
    <input matInput type="text" placeholder="Tooltipe" [(ngModel)]="tooltipe" [ngModelOptions]="{standalone: true}" size="50"
           autocomplete="off">
  </mat-form-field>

</form>

<div>
  <p>Ver material icons
  <a href="https://material.io/resources/icons/?icon=add_shopping_cart&style=baseline" target="_blank" >aquí</a>.</p>
</div>

<div class="dialog-footer-2">
  <button (click)="onOk()">{{"Aceptar"}}</button>
  <button (click)="onCancel()">{{"Cancelar"}}</button>
</div>
