<div class="buttons-dreta">

  <button *ngIf="selectedTab.value == 2 && editReport" (click)="DynamicReportComponent.showEventQuery(); showOtherButtons = false;" class="btn btnjan erp-status-btn"><i matTooltip="Ver consulta sql" class="material-icons">visibility</i></button>
  <button *ngIf="selectedTab.value == 2 && (showOtherButtons || editReport)" (click)="DynamicReportComponent.preview();" class="btn btnjan erp-status-btn"><i matTooltip="Demo" class="material-icons">emoji_objects</i></button>
  <button *ngIf="selectedTab.value == 2 && showOtherButtons" (click)="DynamicReportComponent.saveReportTemplate(); showOtherButtons = false;" class="btn btnjan erp-status-btn"><i matTooltip="Guardar" class="material-icons">save</i></button>
  <button *ngIf="selectedTab.value == 2 && editReport" (click)="DynamicReportComponent.saveEditReportTemplate(); showOtherButtons = false; editReport = false;" class="btn btnjan erp-status-btn"><i matTooltip="Guardar" class="material-icons">save</i></button>
  <button *ngIf="selectedTab.value == 2 && (showOtherButtons || editReport)" (click)="DynamicReportComponent.onClickReturn(); showOtherButtons = false; editReport = false;" class="btn btnjan erp-status-btn"><i matTooltip="Cancelar" class="material-icons">cancel</i></button>

</div>

<mat-tab-group [selectedIndex]="selectedTab.value" (selectedIndexChange)="selectedTab.setValue($event); setCurrentTab($event)">
  <mat-tab id="campaña">
    <ng-template mat-tab-label>
      <span class="displayNone">Campañas </span>
      <span *ngIf="nCamp > 0" >Seleccionados {{nCamp}} <i class="material-icons" click-stop-propagation (click)="quitFilterJourney()">close</i></span>
    </ng-template>
    <campanya-ego #CampanyaEgoComponent
                  (updateCampana)="updateCampana()"
                  (filterJourney)="filterJourney()"
                  (iniCampana)="iniCampana()"
                  (quitFilterJourney)="quitFilterJourney()"
                  [dataSourceCampana]="dataSourceCampana"
                  [campanaArray]="campanaArray"
                  [selectedTab]="selectedTab">
    </campanya-ego>
  </mat-tab>
  <mat-tab id="journey">
    <ng-template mat-tab-label>
      <span class="displayNone">Journeys</span>
      <span *ngIf="nJour > 0" >Seleccionados {{nJour}} <i class="material-icons" click-stop-propagation (click)="quitFilterPlantilla()">close</i></span>
    </ng-template>
    <journey-ego #JourneyEgoComponent
                 (updateJourney)="updateJourney()"
                 (filterPlantilla)="filterPlantilla()"
                 (iniJourney)="iniJourney()"
                 (quitFilterPlantilla)="quitFilterPlantilla()"
                 [dataSourceJourney]="dataSourceJourney"
                 [journeyArray]="journeyArray"
                 [selectedTab]="selectedTab">

    </journey-ego>
  </mat-tab>
  <mat-tab id="dynamicreport">
    <ng-template mat-tab-label>
      <span class="displayNone"> {{strings.DYNAMICREPORT}}</span>
    </ng-template>
    <dynamic-report #DynamicReportComponent
                    (sendEditReport)="sendEditReport()"
                    (sendShowOtherButtons)="sendShowOtherButtons()"
                    (updatePlantillas)="updatePlantillas()"
                    (iniPlantillas)="iniPlantillas()"
                    [plantillasArray]="plantillasArray"
                    [dataSourcePlantilla]="dataSourcePlantilla"
                    [notification]="notification"
                    [notificationSuperior]="notificationSuperior"
                    [notificationSuperiorHome]="notificationSuperiorHome"
                    [inputFilter]="inputFilter"
                    [journeyArray]="journeyArray">
    </dynamic-report>
  </mat-tab>
</mat-tab-group>
