import { Component, Input, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import SignaturePad from 'signature_pad';
@Component({
  selector: 'app-sign',
  templateUrl: './sign.component.html',
  styleUrls: ['./sign.component.sass']
})
export class SignComponent implements OnInit, AfterViewInit {

  @Input() index: number;
  @Input() structure = null;
  @ViewChild('sPad', { static: false }) signaturePadElement: ElementRef;
  signaturePad: SignaturePad;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.structure[this.index]['tmp_value_signature'] = new SignaturePad(this.signaturePadElement.nativeElement);
  }

  onCleanSign() {
    this.structure[this.index]['tmp_value_signature'].clear();
  }
}
