//https://stackoverflow.com/questions/29775830/how-to-implement-a-typescript-decorator

function thirdDecorator(msg: string) {
  return (target: Object, propertyKey: string, descriptor: TypedPropertyDescriptor<any>) => {
    const f = descriptor.value;
    descriptor.value = function (...args: any[]) {
      const result = f.apply(this, args);
      return result;
    };
    return descriptor;
  };
}

function monitoringDecorator(action: MonitoringActions, msg: string, id?: number) {
  //console.log("ENTRO");
  return (target: Object, propertyKey: string, descriptor: TypedPropertyDescriptor<any>) => {

    //console.log("ENTRO100");
    //console.log(this.authService);


    const f = descriptor.value;
    descriptor.value = function (...args: any[]) {
      let act = '';
      switch (action) {
        case MonitoringActions.VIEW:
          act += 'visited';
          break;
        case MonitoringActions.CLICK:
          act += 'clicked';
          // Id pasado al botón al hacer click
          break;
      }
      let idd = null;
      if (id) {
        idd = id;
      }
      if (args.length > 0) {
        idd = args[0];
      }
      if (this.authService === undefined) {
      } else {

        //console.log("MONITOREO!!!!!");
        //console.log(idd);

        /*this.endpointService.insertMonitoringInformation(
          this.authService.getLoggedInUserId(),
          this.authService.userFullName,
          act,
          msg,
          idd).subscribe(
          data => {
          }
        );*/
      }
      const result = f.apply(this, args);
      return result;
    };
    return descriptor;
  };
}

export enum MonitoringActions {
  VIEW,
  CLICK
}

export {thirdDecorator as CustomDecorator};
export {monitoringDecorator as MonitoringDecorator};
