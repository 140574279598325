<button *ngIf="showMenuNotisBool || showMenuVisitasBool || showMenuGestionarActivBool" mat-button class="yellow icons-header icons-header-custom" matTooltip="Notificaciones" fxLayout="row" fxLayoutAlign="end center"
    [matMenuTriggerFor]="notificaciones" [matBadge]="'!'" [matBadgeHidden]="(ego_not_num + visita_not_num + gestor_actividades_num)<1">
    <i class="material-icons outlined">notifications</i>
</button>

<mat-menu #notificaciones="matMenu" class="notis-menu">
    <button mat-menu-item matTooltip="Centro de notificaciones" [matMenuTriggerFor]="notificaciones_ego" *ngIf="showMenuNotisBool">
      <span>
        <i class="material-icons">campaign</i>
        <span [matBadge]="ego_not_num" [matBadgeHidden]="ego_not_num<1"></span>
      </span>
      <span>Notificaciones</span>
    </button>
    <button mat-menu-item matTooltip="Visitas portal 4dmovin" [matMenuTriggerFor]="visitas_portal"
            *ngIf="showMenuVisitasBool">
      <span>
        <i class="material-icons">calendar_today</i>
        <span [matBadge]="visita_not_num" [matBadgeHidden]="visita_not_num<1"></span>
      </span>
      <span>Visitas 4DMOVIN</span>
    </button>
    <button mat-menu-item matTooltip="Gestor de actividades" [matMenuTriggerFor]="gestor_actividades"
            *ngIf="showMenuGestionarActivBool">
      <span>
        <i class="material-icons">alarm</i>
        <span [matBadge]="gestor_actividades_num" [matBadgeHidden]="gestor_actividades_num<1"></span>
      </span>
      <span>Avisos agenda</span>
    </button>
</mat-menu>

<mat-menu #gestor_actividades="matMenu">
    <div style="padding: 0.8em;" (click)="$event.stopPropagation()">
        <app-notifications #notis3 [not_type]="3" [userLogged]="authService.isUserSessionActive()" (gestor_actividades_num)="getGestorActividadesCount($event)"></app-notifications>
    </div>
</mat-menu>

<mat-menu #visitas_portal="matMenu">
    <div style="padding: 0.8em;" (click)="$event.stopPropagation()">
        <app-notifications #notis1 [not_type]="1" [userLogged]="authService.isUserSessionActive()" (visita_not_num)="getVisitNotificationsCount($event)"></app-notifications>
    </div>
</mat-menu>

<mat-menu #notificaciones_ego="matMenu">
    <div style="padding: 0.8em;" (click)="$event.stopPropagation()">
      <app-notifications #notis2 [not_type]="2" [userLogged]="authService.isUserSessionActive()" (ego_not_num)="getEgoNotificationsCount($event)"></app-notifications>
    </div>
</mat-menu>