<span *ngIf="loaded && loadedMicrosoft && loadedGoogle" fxLayout="row wrap">
  <div class="dialog-container" fxFlex="100">
    <label class="title-input">Configuración de la agenda</label>
    <button class="close-button" mat-button (click)="onNoClick()"><i class="material-icons">close</i></button>
  </div>
  <mat-accordion fxFlex="100" fxLayout="row wrap">
    <mat-expansion-panel expanded fxFlex="100" >
      <mat-expansion-panel-header>
        <mat-panel-title>
          CONFIGURACIÓN FECHA CALENDARIO
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
  
      <div class="row-expansion3">
        <mat-form-field appearance="outline">
          <mat-label>Hora inicio de la agenda</mat-label>
          <mat-select [(value)]="userData.hora_inicio_cal">
            <ng-container *ngFor="let option of bodyTime.tiempo; let i = index">
              <div *ngIf="i < bodyTime.tiempo.length-2">
                <mat-option [value]="option.time">
                  {{option.time}}
                </mat-option>
              </div>
            </ng-container>
          </mat-select>
        </mat-form-field>
  
        <mat-form-field appearance="outline">
          <mat-label>Hora fin de la agenda</mat-label>
          <mat-select [(value)]="userData.hora_fin_cal">
            <ng-container *ngFor="let option of bodyTime.tiempo; let i = index;">
              <div *ngIf="i > bodyTime.ini1.idTime+1">
                <mat-option [value]="option.time">
                  {{option.time}}
                </mat-option>
              </div>
            </ng-container>
          </mat-select>
        </mat-form-field>
  
        <mat-form-field appearance="outline">
          <mat-label>La agenda empieza en...</mat-label>
          <mat-select [(value)]="calendarFirstDay">
            <mat-option [value]="1">Lunes</mat-option>
            <mat-option [value]="2">Martes</mat-option>
            <mat-option [value]="3">Miércoles</mat-option>
            <mat-option [value]="4">Jueves</mat-option>
            <mat-option [value]="5">Viernes</mat-option>
            <mat-option [value]="6">Sábado</mat-option>
            <mat-option [value]="0">Domingo</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
  
      <div class="row-expansion3" *ngIf="showRememberConfig">
        <mat-form-field appearance="outline">
          <mat-label>Primer aviso</mat-label>
          <mat-select [(value)]="userData.id_aviso1">
            <!--<mat-option [value]="null">Desactivado</mat-option>-->
            <mat-option *ngFor="let time of ActivitiesReReminderArray" [value]="time.id">{{time.tiempo}}</mat-option>
          </mat-select>
        </mat-form-field>
  
        <mat-form-field appearance="outline">
          <mat-label>Segundo aviso</mat-label>
          <mat-select [(value)]="userData.id_aviso2">
            <!--<mat-option [value]="null">Desactivado</mat-option>-->
            <mat-option *ngFor="let time of ActivitiesReReminderArray" [value]="time.id">{{time.tiempo}}</mat-option>
          </mat-select>
        </mat-form-field>
  
        <mat-form-field appearance="outline">
          <mat-label>Tercer aviso</mat-label>
          <mat-select [(value)]="userData.id_aviso3">
            <!--<mat-option [value]="null">Desactivado</mat-option>-->
            <mat-option *ngFor="let time of ActivitiesReReminderArray" [value]="time.id">{{time.tiempo}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
  
      <div class="generic-buttons-blue-revert">
        <button mat-button (click)="updateInfo()">Guardar cambios</button>
      </div>
  
    </mat-expansion-panel>

    <mat-expansion-panel fxFlex="100"  >
      <mat-expansion-panel-header>
        <mat-panel-title>
          CONFIGURACIÓN GOOGLE CALENDAR
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
  
      <div *ngIf="authService.googleToken != null">
        <div class="row-expansion2" style="margin-bottom: 20px;">
          <mat-form-field appearance="outline" style="margin-bottom: 0.5em;">
            <mat-label>Calendarios</mat-label>
            <mat-select [(ngModel)]="calendarsSelectedGoogle" multiple>
              <mat-option *ngFor="let s of calendarsGoogle" [value]="s.id">{{s.nombre}}</mat-option>
            </mat-select>
            <mat-hint>Elige que calendarios quieres sincronizar</mat-hint>
          </mat-form-field>
          <mat-form-field appearance="outline" *ngIf="calendarsSelectedGoogle.length > 0">
            <mat-label>Empresa por defecto</mat-label>
            <mat-select [(ngModel)]="calendarEmpresaGoogle" (ngModelChange)="calendarEmpresaGoogleChange()">
              <mat-option *ngFor="let s of this.authService.accountsGeneric" [value]="s.id">{{s.nombre}}</mat-option>
            </mat-select>
            <mat-hint>Empresa donde se asignan las actividades provenientes de Google Calendar</mat-hint>
          </mat-form-field>
        </div>
    
        <div fxLayout="row wrap" class="buttons-sincro-calendar">
          <div class="generic-buttons-blue-revert" *ngIf="!areArrayEquals(calendarsSelectedGoogleInit, calendarsSelectedGoogle)">
            <button mat-button (click)="guardarGoogle()">Guardar calendarios y sincronizar</button>
          </div>
          <div class="generic-buttons-blue" style="margin-left: 7px;" *ngIf="calendarsSelectedGoogleInit.length > 0">
            <button mat-button (click)="borrarGoogle()">Desincronizar todos los calendarios</button>
          </div>
          <div class="generic-buttons-blue" style="margin-left: 7px;">
            <button mat-button (click)="desvincularGoogle()">Desvincular Google Calendar</button>
          </div>
        </div>
      </div>
  
      <div *ngIf="authService.googleToken == null">
        Debes vincular Google Calendar.
        <div class="generic-buttons-blue-revert" style="margin-top: 0.5em;">
          <button mat-button (click)="vincularGoogle()">Vincular</button>
        </div>
      </div>
      
    </mat-expansion-panel>
  
    <mat-expansion-panel fxFlex="100"  >
      <mat-expansion-panel-header>
        <mat-panel-title>
          CONFIGURACIÓN CALENDARIO MICROSOFT (OUTLOOK)
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
  
      <div *ngIf="authService.microsoftToken != null">
        <div class="row-expansion2" style="margin-bottom: 30px;">
          <mat-form-field appearance="outline" style="margin-bottom: 0.5em;">
            <mat-label>Calendarios</mat-label>
            <mat-select [(ngModel)]="calendarsSelectedMicrosoft" multiple>
              <mat-option *ngFor="let s of calendarsMicrosoft" [value]="s.id">{{s.nombre}}</mat-option>
            </mat-select>
            <mat-hint>Elige que calendarios quieres sincronizar</mat-hint>
          </mat-form-field>
          <mat-form-field appearance="outline" *ngIf="calendarsSelectedMicrosoft.length > 0">
            <mat-label>Empresa por defecto</mat-label>
            <mat-select [(ngModel)]="calendarEmpresaMicrosoft" (ngModelChange)="calendarEmpresaMicrosoftChange()">
              <mat-option *ngFor="let s of this.authService.accountsGeneric" [value]="s.id">{{s.nombre}}</mat-option>
            </mat-select>
            <mat-hint>Empresa donde se asignan las actividades provenientes de Microsoft Calendar (Outlook)</mat-hint>
          </mat-form-field>
        </div>
    
        <div fxLayout="row wrap" class="buttons-sincro-calendar">
          <div class="generic-buttons-blue-revert" *ngIf="!areArrayEquals(calendarsSelectedMicrosoftInit, calendarsSelectedMicrosoft)">
            <button mat-button (click)="guardarMicrosoft()">Guardar calendarios y sincronizar</button>
          </div>
          <div class="generic-buttons-blue" style="margin-left: 7px;" *ngIf="calendarsSelectedMicrosoftInit.length > 0">
            <button mat-button (click)="borrarMicrosoft()">Desincronizar todos los calendarios</button>
          </div>
          <div class="generic-buttons-blue" style="margin-left: 7px;">
            <button mat-button (click)="desvincularMicrosoft()">Desvincular Microsoft Calendar</button>
          </div>
        </div>
  
      </div>
  
      <div *ngIf="authService.microsoftToken == null">
        Debes vincular Microsoft Calendar.
        <div class="generic-buttons-blue-revert" style="margin-top: 0.5em;">
          <button mat-button (click)="vincularMicrosoft()">Vincular</button>
        </div>
      </div>
  
    </mat-expansion-panel>
  </mat-accordion>
</span>

<div *ngIf="!loaded || !loadedMicrosoft || !loadedGoogle" fxLayoutAlign="center center" fxFlex="100">
  <mat-spinner></mat-spinner>
</div>
