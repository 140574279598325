import { LOCALE_ID, Inject, Injectable } from '@angular/core';
import { CalendarEventTitleFormatter, CalendarEvent } from 'angular-calendar';
import { DatePipe } from '@angular/common';
import {CustomEvent, CustomEventAction} from '../../../activities/objects/custom-event.component';

@Injectable()
export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {
  constructor(@Inject(LOCALE_ID) private locale: string) {
    super();
  }

  // you can override any of the methods defined in the parent class

  month(event: CustomEvent): string {
    let title = event.title;
    if (!event.allDay) {
      title = `<b>${new DatePipe(this.locale).transform(
        event.start,
        'HH:mm',
        this.locale
      )}</b> ` + title;
    }
    title = this.addZone(title, event);
    return title;
  }

  week(event: CustomEvent): string {
    let title = event.title;
    title = this.addZone(title, event);
    return title;
  }

  day(event: CustomEvent): string {
    let title = event.title;
    title = this.addZone(title, event);
    return title;
  }

  weekTooltip(event: CustomEvent) {
    let title = event.title;
    title = this.addZoneBr(title, event);
    title = this.addCreatorBr(title, event);
    title = this.addParticipantsBr(title, event);
    title = this.addAccountBr(title, event);
    return title;
  }

  dayTooltip(event: CustomEvent): string {
    let title = event.title;
    title = this.addZoneBr(title, event);
    title = this.addCreatorBr(title, event);
    title = this.addParticipantsBr(title, event);
    title = this.addAccountBr(title, event);
    return title;
  }

  addZone(title, event) {
    let hasTown = false;
    if(event.town){
      title += `, ${event.town}`;
      hasTown = true;
    }
    if(event.zone) {
      if(hasTown) title += ' (';
      else title += ', ';
      title += `${event.zone}`;
      if(hasTown) title += ')';
    }
    return title;
  }

  addZoneBr(title, event) {
    let hasTown = false;
    if(event.town){
      title += `<br> ${event.town}`;
      hasTown = true;
    }
    if(event.zone) {
      if(hasTown) title += ' (';
      else title += '<br> ';
      title += `${event.zone}`;
      if(hasTown) title += ')';
    }
    return title;
  }

  addCreatorBr(title, event) {
    if(event.account != undefined){
      title += '<br> Organizador: ';
      title += `${event.creatorName}`;
    }
    return title;
  }

  addParticipantsBr(title, event) {
    if (event.users?.length > 0) {
      title += '<br> Participantes: ';
      event.users.forEach((e, i, arr) => {
        if (i === arr.length - 1) {
          title += `${e.user}`;
        }
        else {
          title += `${e.user}, `;
        }
      });
    }
    return title;
  }

  addAccountBr(title, event) {
    if (event.account) {
      title += `<br> ${event.account.nombre} ${event.account.tipoNombre}`;
    }
    return title;
  }
}
