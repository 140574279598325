import { AngularEditorConfig } from "@kolkov/angular-editor";
import { environment } from "../environments/environment";

export class Values {
  static Version = "7.2.85";
  static VersionDate = "30/06/2024";

  // TODO: Crear api para prod y poner restricción http con movin.cloud y http://movin.cloud con www también? probarlo. La de demo pues no poner restricción
  // Prod:
  // static GOOGLE_API_KEY = 'AIzaSyDDD_E7LB2dKMu2qJniasF0fMatAyfmaXE';
  // Demo:
  static GOOGLE_API_KEY = "AIzaSyDDD_E7LB2dKMu2qJniasF0fMatAyfmaXE";

  // Stripe Public Key
  // Prod:
  static STRIPE_KEY = "pk_live_ppTZuY5XIGbYVQvW2a0hzHB300Xbci1Lne";
  // Dev:
  // static STRIPE_KEY = 'pk_test_8bH0KG7sCqjbbHXYm64AjJ1000XM7OHbzl';

  // LINK 4DMOVIN
  // Prod:
  static ROOT_URL = 'https://movin.cloud';
  // Dev:
  // static ROOT_URL = "https://test-erp.movin.cloud";
  // NICO:
  // static ROOT_URL = "http://10.105.0.1:8080";

  // LINK GROWTHTOOL360
  // Prod:
  // static ROOT_URL = 'https://app.growthtool360.com';
  // Dev:
  // static ROOT_URL = 'https://app.growthtool360.com';

  static ERP_URL = "/";

  static BASE_URL = Values.ROOT_URL + Values.ERP_URL;

  static MARKETING_PICS_DIR_URL = Values.BASE_URL + "marketing-images/";

  static SERVER_URL = Values.BASE_URL + "backend/index.php?resource=";

  // static SERVER_URL = Values.BASE_URL + "backendTestA/index.php?resource=" ;

  // static SERVER_URL = Values.BASE_URL + "backendTestB/index.php?resource=";

  // static SERVER_URL = Values.BASE_URL + "backendTestC/index.php?resource=";

  static NO_IMAGE_URL = "assets/img/no-image.png";

  static CHAT_GROUP = "assets/img/default/logo-group.png";

  static CHAT_SERVER_URL = environment.SOCKET_ENDPOINT;

  static ROUTES = {
    CLIENTS_GENERIC: "listado-clientes",
    PROPERTIES_GENERIC: "listado-inmuebles",
    LOGIN: "login",
    INMO: "inmo",
    REGISTRY_INMO_GROUP: "registry-inmo-group",
    COMMUNITY: "community",
    CHAT: "chat",
  };

  static API_REQ_PATTERN = "&req=";

  static API_RESOURCES = {
    PROPERTIES: "properties",
    CLIENTS: "clients",
    SEARCH: "search",
    LOGIN: "login",
    VALIDTE_USER: "validate-user",
    USER: "user",
    RECOVER_PASSWORD: "recover-password",
    CHECK_CODE: "check-code",
    CHANGE_PASSWORD: "change-password",
    PRIVILEGIES: "privilegies",
    LOCATIONS: "locations",
    ACTIVITIES: "activities",
    EVENTS: "events",
    EMAILTEMPLATES: "emailtemplates",
    TARGETLIST: "target-list",
    USERMONITORING: "user-monitoring",
    SETTINGS: "settings",
    FILES: "files",
    INMO: "inmo",
    COMMON: "common",
    REPORT: "report",
    BILL: "bill",
    EGO_NOTIFICATIONS: "ego-notifications",
    MARKETPLACE: "marketplace",
    ORPHAN: "orphan",
    LANDING: "landing",
    GROUP: "group",
    COMMUNITY: "community",
    PORTAL: "portal",
    GENERIC: "generic",
    RESTAURANT: "restaurant",
    EMAILINMO: "emailInmo",
    EXPORTERS: "exporters",
    GOOGLE: "google",
    MICROSOFT: "microsoft",
    SOCIAL_MEDIA_TWITTER: "twitter",
    SOCIAL_MEDIA_INSTAGRAM: "instagram",
    SOCIAL_MEDIA_FACEBOOK: "facebook",
    SOCIAL_MEDIA_LINKEDIN: "linkedin",
    SOCIAL_MEDIA: "socialmedia",
    LOAD_DATA_FROM_FILE: "load-data-from-file",
    PAYMENT: "payment",
    HOTELS: "hotels",
    ACT_LUDIC: "act-ludic"
  };

  static FILES_REQS = {
    UPLOAD_FILE: "upload-file",
    GET_ALL_FILES: "get-all-files",
  };

  static LOAD_DATA_FROM_FILE = {
    LOAD_CLIENTS_FROM_FILE: "insert-load-data-from-file",
  };

  static INMO_REQS = {
    GET_ALL_USERS: "get-all-users",
    DELETE_USER: "delete-user-inmo",
    CREATE_INMO_DEMO: "create-inmo-demo",
    GET_PORTAL: "get-portal",
    GET_GROUP_TYPE: "get-group-type",
    GET_MODULE: "get-module",
    POST_INMO_IMAGE_URL: Values.SERVER_URL + "inmo&req=set-inmo-image",
    POST_AVATAR_IMAGE_URL: Values.SERVER_URL + "inmo&req=set-avatar-image",
    POST_GROUP_IMAGE_URL: Values.SERVER_URL + "inmo&req=set-group-image",
    GET_ALL_FROM_INMO_BY_ID: "get-all-from-inmo-by-id",
    DELETE_INMO: "delete-inmo",
    DELETE_INMO_IMAGE: "delete-inmo-image",
    DELETE_IMAGE_INMO: "delete-image-inmo",
    CHANGE_INMO_IMAGE_URL: Values.SERVER_URL + "inmo&req=change-inmo-image",
    CHANGE_GROUP_IMAGE_URL: Values.SERVER_URL + "inmo&req=change-group-image",
    GET_PROFILE: "get-profile",
    INSERT_REGISTRY: "insert-registry",
    GET_SOFTWARE: "get-software",
    NEW_ORPHAN: "orphan-user",
    ASSIGN_INMO_ORPHAN_USER: "new-inmo-orphan-user",
    LOOKING_USER: "lookingUser",
    GET_ALL_INMO: "get-all-inmo",
    SIGN_UP_INMO_USER: "sign-up-inmo",
    ACCEPT_USER: "accept-user",
    REJECT_CANCEL_USER: "reject-cancel-user",
    GET_NAME_INMO_BY_USER_ID: "get-inmo-name-by-user-id",
    LINK_INMO_GROUP: "link-inmo-group",
    GET_ALL_INMO_WITHOUT_YOUR_GROUP: "get-all-inmo-without-your-group",
    CANCEL_INMO_ACCOUNT: "cancel-inmo-account",
    COUNT_ADMIN_INMO: "admin-count-inmo",
    GET_USER_MOVIN_AFILIATE: "get-user-movin-afiliate",
  };

  static GENERIC_REQUEST = {
    GET_MODULE_ID_BY_ROUTE: 'get-module-id-by-route',
    GET_ROUTE_BY_ID: 'get-route-by-id',
    RETRIEVE_STRUCTURE: 'retrieveStructure',
    REFRESH_STRUCTURE: 'refreshStructure',
    GET_ACTIVE_COMPANY: 'get-active-company',
    SET_ACTIVE_COMPANY: 'set-active-company',
    GET_DATA_BY_ID: 'get-data-by-id-query',
    UPDATE_RESULTS: 'update-results',
    INSERT_FORM_DATA: 'insert-form-data',
    PARSE_LANGUAGE_TAG: 'translate-tag',
    DELETE_DATA: 'delete-data',
    UPDATE_VIVIENDA_CONFIG_PORTALES_MASSIVE: 'update-vivienda-config-portales-massive',
    SEND_EMAIL_INMUEBLE: 'send-email-inmueble',
    GET_TEXT_INMUEBLE: 'get-text-inmueble',
    SAVE_TEXT_INMUEBLE: 'save-text-inmueble',
    GET_IMAGES_PROPERTY: 'get-images-property',
    UPDATE_IMAGES_PROPERTY: 'update-images-property',
    GET_CONDITIONS_FROM_EVENTO: 'get_conditions_from_evento',
    GET_EVENTOS_TABLES: 'get_eventos_tables',
    GET_FIELDS_NAME_TABLE: 'get_fields_name_table',
    GET_OPERATORS_CONDITIONS: 'get_operators_conditions',
    UPDATE_CONDITIONS: 'update-conditions',
    DELETE_CONDITION: 'delete-condition',
    GET_ALL_PLANTILLAS_IDIOMA: 'get-all-plantillas-idioma',
    GET_ELEMENTS_PLANTILLA: 'get-elements-plantilla',
    UPDATE_ELEMENTS_PLANTILLA: 'update-elements-plantilla',
    GET_ELEMENTS_TIPOS: 'get_elements_tipos',
    RELOAD_QUERIES: 'reload_queries',
    GET_ELEMENTS_GENERIC_CREATOR: 'get-elements-generic-creator',
    UPDATE_FA_CUSTOM: 'update-fa-custom',
    GET_CHIPS_INFO: "get-chips-plantillas-custom",
    INSERT_EMAIL_ELEMENTS_REUSE: "insert-email-elements-reuse",
    DUPLICATE_PLANTILLAS_EMAIL: 'duplicate-plantillas-email',
    GET_JOURNEYS_CREATOR: "get-journeys-creator",
    UPDATE_JOURNEY_CREATOR: "update-journeys-creator",
    ARCHIVE_ALL_DEMANDA_FROM_VIVIENDA_CUSTOM: "archive-all-demandas-from-vivienda-custom",
    LOAD_DEFER_TABLE_DATA: "load-defer-table-data",
    RELOAD_TABLE: 'reload-table',
    CHANGE_COMPANY: 'change-company',
    DELETE_ALL_DOCS: 'delete-all-docs',
    SAVE_USER_PARAMS: 'save-user-params',
    SAVE_USER_PARAMS_FA: 'save-user-params-fa',
    GET_TABLES_FROM_DATABASE: "get-tables-from-database",
    GET_FIELDS_FROM_TABLE: "get-fields-from-table",
    SEARCH_GENERIC: "search-generic",
    GET_DB_SCHEMA: "get-db-schema",
  };

  static RESTAURANT_REQS = {
    INSERT_PEDIDO_STATIC_RESTAURANT: "insert-pedido-static-restaurant",
    MERGE_PEDIDOS_RESTAURANT: "merge-pedidos-restaurant",
    MOVE_PEDIDO_RESTAURANT: "move-pedido-restaurant",
    MARCHAR_PRODUCTOS_PEDIDO_RESTAURANT: "marchar-productos-pedido-restaurant",
    ANADIR_PRODUCTO_DIRECTO_RESTAURANT: "anadir-producto-directo-restaurant",
    PRODUCTOS_ESTADO_CINCO_DIRECTO: "productos-estado-cinco-directo",
    ADD_TO_CHECKOUT_RESTAURANT: "add-to-checkout-restaurant",
    GET_REST_MESAS: "get-rest-mesas",
    GET_REST_TICKET: "get-rest-ticket",
    GET_REST_COMANDA: "get-rest-comanda",
    GET_REST_COMANDA_CANCELADOS: "get-rest-comanda-cancelados",
    GET_REST_MOVIMIENTO: "get-rest-movimiento",
    GET_REST_CIERRE_CAJA: "get-rest-cierre-caja",
    GET_REST_CIERRE_CAJA_SNAPSHOT: "get-rest-cierre-caja-snapshot",
    GET_REST_PEDIDO: "get-rest-pedido",
    GET_REST_CASH_PRINTER: "get-rest-cash-printer",
    INSERT_TICKET_PEDIDO: "insert-ticket-pedido",
    CHECK_OCUPACION_MESA_RESERVAS: "check-ocupacion-mesa",
    CHECK_OCUPACION_UBI_RESERVAS: "check-ocupacion-ubi",
  };

  static ACT_LUDIC_REQS = {
    GET_ACT_LUDIC_TICKET: "get-act-ludic-ticket",
  }

  static REPORT_REQS = {
    GET_REPORT_TYPES: "report-types",
    GET_ALL_PLANTILLAS: "get-all-plantillas",
    SET_DYNAMIC_REPORT: "set-dynamic-report",
    GET_PLANTILLA: "get-plantilla",
    DROP_PLANTILLA: "drop-plantilla",
    UPDATE_PLANTILLA: "update-plantilla",
    GET_TYPE_ICON: "get-type-icon",
    GET_ACTION_BUTTON: "get-button-action",
    GET_ACTION_BUTTON_ACTIVE: "get-button-action-active",
    GET_ALL_ACTION_BUTTON_ACTIVE: "get-all-button-action-active",
    UPDATE_ID_EVENT: "update-event",
    GET_QUESTIONS: "get-preguntas",
    ACCEPT_CONTRACT: "accept-contract",
    ACCEPT_PRIVACITY: "accept-privacity",
    EXECUTE_EGO_BUTTON_ACTION: "execute-ego-button-action",
    GET_MODULOS: "get-modulos",
    GET_JOURNEY_POPUP_INGO: "get-journey-popup-info",
    EDIT_JOURNEY: "edit-journey",
    GET_JOURNEY: "get-journey",
    GET_ALL_JOURNEY: "get-all-journey",
    GET_ALL_CAMPANA: "get-all-campana",
    GET_CAMPANA_POPUP_INFO: "get-campana-popup-info",
    GET_CAMPANA: "get-campana",
    EDIT_CAMPANA: "edit-campana",
    MAILCHIMP: "mailchimp",
    NEW_NOTI_EGO: "new-not-ego",
  };

  static PAYMENT_REQS = {
    PAY: "pay",
    UPDATE_PAYMENT: "upate-payment",
    MAKE_PAYMENT: "make-payment",
    INSERT_TICKET_PAYMENT_METHOD: "insert-ticket-payment-method"
  };

  static ORPHAN_REQS = {
    LOAD_OPHANS: "load-orphan",
  };

  static COMMUNITY_REQS = {
    GET_URL_BY_ID_INMO: "get-url-inmo",
    GET_INMO_BY_NAME: "get-inmo-by-name",
    GET_INMO_BY_NAME_GROUP: "get-inmo-by-name-group",
    GET_MLS_BY_NAME: "get-mls-by-name",
    GET_MLS_BY_NAME_GROUP: "get-mls-by-name-group",
    GET_VIVIENDAS: "get-viviendas-by-inmo-name",
    GET_VIVIENDAS_GROUP: "get-viviendas-of-group",
    GET_INMOS: "get-inmos-by-mls-name",
    GET_INMOS_GROUP: "get-inmos-by-mls-name-group",
    GET_ALL: "get-all-inmos-mls",
    GET_TODO: "get-todo",
    GET_BUSCAR_COLABORADORES: "get-colaboradores",
    GET_MIS_COLABORADORES: "get-mis-colaboradores",
    GET_SOLICITUDES_PENDIENTES: "get-solicitudes-pendientes-colaboracion",
    GET_OPINIONS: "get-opiniones",
    SOLICITAR_COLABORACION: "solicitar-colaboracion",
    ACTUALIZAR_COLABORACION: "actualizar-colaboracion",
    ACCEPT_COLLABORATION: "accept-collaboration",
    REJECT_COLLABORATION: "reject-collaboration",
    UPDATE_PROFILE_TYPE: "update-profile-type",
    UPDATE_PROFILE_TYPE_GROUP: "update-profile-type-group",
    UPDATE_COLABORAR_SEGUN_MIS_AGRUPACIONES:
      "update-colaborar-segun-mis-agrupaciones",
    UPDATE_COLABORAR_SEGUN_MIS_AGRUPACIONES_PERSON:
      "update-colaborar-segun-mis-agrupaciones-person",
    COLABORAR_CON_BASIC: "colaborar_con_basic",
    COLABORAR_CON_SILVER: "colaborar_con_silver",
    COLABORAR_CON_GOLDEN: "colaborar_con_golden",
    COLABORAR_CON_BASIC_GRUPO: "colaborar_con_basic_grupo",
    COLABORAR_CON_SILVER_GRUPO: "colaborar_con_silver_grupo",
    COLABORAR_CON_GOLDEN_GRUPO: "colaborar_con_golden_grupo",
    UPDATE_FLATSHARING: "update-flatsharing",
    UPDATE_FLATLENDING: "update-flatlending",
    UPDATE_RECIBIR_MENSAJES: "update-recibir-mensajes",
    UPDATE_RECIBIR_MENSAJES_GROUP: "update-recibir-mensajes-group",
    UPDATE_VER_INMOS_GROUP: "update-ver-inmos",
    UPDATE_VER_VIVIENDAS_GROUP: "update-ver-viviendas",
    GET_COMMUNITY_INFO: "get-community-info",
    GET_COMMUNITY_INFO_GROUP: "get-community-info-group",
    UPDATE_GRUPO: "update-grupo",
    SOLICITAR_GRUPO: "solicitar-union-grupo",
    GET_CHAT_CONTACTS: "get-chat-contacts",
    UPLOAD_CHAT_GROUP_PICTURE: "upload-chat-group-picture",
    UPDATE_CHAT_GROUP_PICTURE: "update-chat-group-picture",
    INSERT_NEW_ROOM_PRIVATE: "insert-new-room-private",
    INSERT_NEW_ROOM_GROUP: "insert-new-room-group",
    INSERT_NEW_ROOM_INMO_GROUP: "insert-new-room-inmo-group",
    INSERT_NEW_USER_IN_ROOM: "insert-new-user-in-room",
    INSERT_NEW_MESSAGE_CHAT: "insert-new-message-chat",
    INSERT_MESSAGES_CHAT: "insert-messages-chat",
    UPDATE_COLABORACION: "update-colaboracion",
    INSERT_NEW_FOLLOW: "insert-new-follow",
    DELETE_FOLLOW: "delete-follow",
    GET_NEXT_ROOM_MESSAGES: "get-next-room-messages",
    GET_GRUPOS_INMO: "get-grupos-inmo",
    ACCEPT_INMO_GROUP_REQUEST: "accept-inmo-group-request",
    REJECT_INMO_GROUP_REQUEST: "reject-inmo-group-request",
    ACCEPT_INMO_GROUP_INVITATION: "accept-inmo-group-invitation",
    REJECT_INMO_GROUP_INVITATION: "reject-inmo-group-invitation",
    ACCEPT_USER_GROUP_REQUEST: "accept-user-group-request",
    REJECT_USER_GROUP_REQUEST: "reject-user-group-request",
    ACCEPT_USER_GROUP_INVITATION: "accept-user-group-invitation",
    REJECT_USER_GROUP_INVITATION: "reject-user-group-invitation",
    INMO_LEAVE_GROUP: "inmo-leave-group",
    USER_LEAVE_GROUP: "user-leave-group",
    INSERT_USER_CHAT: "insert-user-chat",
    GET_USER_CHAT_INFO: "get-user-chat-info",
    MESSAGE_READ_BY_ALL_GROUP_MEMBERS: "message-read-by-all-group-members",
    INSERT_MESSAGE_CHAT_GROUP_USERS: "insert-message-chat-group-users",
    UPDATE_MESSAGE_CHAT_GROUP_USER: "update-message-chat-group-user",
    GET_USER_ROOMS: "get-user-rooms",
    GET_ROOM_INFO: "get-room-info",
    GET_COMMUNITY_ADMINS_FOR_CHAT_INMO_GROUP:
      "get-community-admins-for-chat-inmo-group",
    CHECK_IF_ROOM_INMO_GROUP_EXISTS: "check-if-room-inmo-group-exists",
    CHECK_IF_USER_CHAT_EXISTS: "check-if-user-chat-exists",
    LOAD_USER_ROOMS: "load-user-rooms",
  };

  static BILL_REQS = {
    GET_PLANES_MOVIN: "get-planes-movin",
    GET_PLANES_COMPRADOS: "get-planes-comprados",
    GET_PLANES_USADOS: "get-planes-usados",
    SET_PLANES_MOVIN: "set-planes-movin",
    UNSUBSCRIBE: "unsubscribe",
    TOTAL: "total",
    RESTAR_MOVINS: "restar-movins",
  };

  static USER_MONITORING_REQS = {
    INSERT: "insert-data",
    UPDATE: "update-data",
    INSERT_FUNCTIONS: "insert-functions-data",
  };

  static USER_REQS = {
    GET_USER_INFO: "get-user-info",
    UPLOAD_USER_PROFILE_PICTURE: "upload-user-profile-picture",
    GET_USERS: "get-users",
    GET_USER: "get-user",
    GET_ALL_FROM_USER: "get-all-from-user",
    INSERT_USER: "insert-update-user",
    UPDATE_PROFILE_USER: "update-profile-user",
    UPDATE_AGENDA_SETTINGS: "update-agenda-settings",
    UPDATE_MATCHING_PASSWORD: "update-matching-password",
    REMOVE_USER: "remove-user",
    CHECK_USER_EXISTS: "check-user-exists",
    INSERT_USER_REPORT: "add-user-report",
    DELETE_USER_REPORT: "delete-user-report",
    UPDATE_USER_REPORT_VALUES: "update-user-report-values",
    UPDATE_USER_REPORT_COMISSION: "update-user-report-comission",
    GET_USER_REPORTS: "get-user-reports",
    MIGRATE_CLIENTS: "migrate-clients",
    GET_ALL_FULL_NAMES_AND_IDS_BY_EMPRESA:
      "get-all-full-names-and-ids-by-empresa",
    GET_ALL_MY_ACCOUNTS_USERS_EXCEPT_ME: "get-all-my-accounts-users-except-me",
    GET_ALL_MY_ACCOUNTS_USERS: "get-all-my-accounts-users",
    INSERT_SCHEDULE_VIEW: "insert-schedule-view",
    DELETE_IMAGE_PROFILE: "delete-image-profile",
    USER_IS_IN_GROUP: "user-is-in-group",
    USER_GENERATE_TOKEN: "generate-user-token",
    UPDATE_DEFAULT_EMPRESA_CALENDARIO_USUARIO: "update-default-empresa-calendario-usuario",
    GET_PIN_WAITER: "get-pin-waiter"
  };

  static LOCATION_REQS = {
    GET_LOCATIONS: "get-locations",
  };

  static PROPERTIES_REQS = {
    GET_PREDEF_INFO_FOR_SELECTORS: "get-for-selectors-params",
    DUPLICATE_PROPERTY: "duplicate-property",
    GET_ALL_PROPERTIES_REFERENCES_AND_IDS:
      "get-all-properties-renferences-and-ids",
    GET_ACTIVITIESPREDEFINEDVALUES: "get-values",
    DUPLICATE: "duplicate",
    GET_ALL_COUNTRY: "get-all-country",
    GET_PROVINCE: "get-province",
    UPDATE_SERIE: "update-serie",
    INSERT_SERIE: "insert-serie",
    DUPLICATE_VIVIENDA: "duplicate-vivienda",
    DELETE_ALL_PICTURES: "delete-all-pictures",
    DELETE_UNPUBLISHED_PICTURES: "delete-unpublished-pictures",
  };

  static ACTIVITIES_REQS = {
    GET_ACTIVITIESPREDEFINEDVALUES: "get-values",
    CREATE_ACTIVITY: "create-activity",
    EDIT_ACTIVITY: "edit-activity",
    GET_ACTIVITIES: "get-activities",
    GET_ACTIVITY: "get-activity",
    DELETE_ACTIVITY: "delete-activity",
    DELETE_ACTIVITY_ADMIN: "delete-activity-admin",
    SIGN_ACTIVITY: "sign-activity",
    GET_REFERENCE_ACTIVITIES: "get-reference-activities",
    GET_CATEGORIES: "get-categories",
    GET_OFFERS_BY_ID_EMPRESA: "get-offers-by-id-empresa",
    ADD_NEW_CATEGORY_TYPE: "add-new-category-type",
    UPDATE_CATEGORY_TYPE: "update-category-type",
    DELETE_CATEGORY_TYPE: "delete-category-type",
    GET_CALENDAR_ACTIVITIES: "get-calendar-activities",
    GET_ONEACTIVITIY: "get-one-activity",
    GET_CALENDAR_ACTIVITIES_BY_CATEGORY: "get-calendar-activities-by-category",
    GET_CALENDAR_ACTIVITY: "get-calendar-activity",
    ADD_NEW_CALENDAR_ACTIVITY: "add-new-calendar-activity",
    ADD_NEW_CALENDAR_PERIOD_ACTIVITIES: "add-new-calendar-period-activities",
    DELETE_CALENDAR_ACTIVITY: "delete-calendar-activity",
    DELETE_CALENDAR_PERIOD_ACTIVITY: "delete-calendar-period-activity",
    UPDATE_CALENDAR_ACTIVITY: "update-calendar-activity",
    UPDATE_PERIODIC_CALENDAR_ACTIVITY: "update-periodic-calendar-activity",
    UPDATE_CALENDAR_ACTIVITY_CATEGORY: "update-calendar-activity-category",
    GET_ACTIVITIES_SERIES_MAX_ID: "get-activities-series-max-id",
    GET_ACTIVITIES_NOTIFICATION_TIMING: "get-activities-notification-timing",
    DELETE_CALENDAR_SERIE_ACTIVITIES: "delete-calendar-serie-activities",
    UPDATE_ACTIVITY_STATE: "update-activity-state",
    POSTPONE_ACTIVITY: "postpone-activity",
    GET_ALL_GROUPS: "get-all-groups",
    GET_ALL_GROUP_USERS_BY_ID: "get-all-group-users-by-id",
    INSERT_GROUP_USERS: "insert-group-users",
    DELETE_GROUP_USERS: "delete-group-users",
    CANCEL_ACTIVITY_STATE: "cancel_activity_state",
    CHECK_USER_GROUP_NAME_EXISTS: "check-user-group-name-exists",
    UPDATE_USER_GROUP: "update-user-group",
    GET_PENDING_ACTIVITIES: "get-pending-activities",
  };

  static CLIENTS_REQS = {
    GET_PREDEF_INFO_FOR_SELECTORS: "get-selectors-params",
    GET_ALL_FULL_NAMES_AND_IDS: "get-all-full-names-and-ids",
    DUPLICATE_MATCHING: "duplicate-matching",
  };

  static PRIVILEGIES_REQS = {
    GET_ROLES: "get-roles",
    GET_USER_PERMISSIONS: "get-user-permissions",
  };

  static SEARCH_REQS = {
    FILTER_CLIENTS: "filter-clients",
    FILTER_CLIENTS_FROM_ENCARGO: "filter-clients-from-encargo",
    FILTER_FLATS: "filter-flats",
    FILTER_FLATS_COLABORATION: "filter-flats-colaboration",
    FILTER_INMO: "filter-inmo",
    FILTER_INMO_GROUP: "filter-inmo-group",
    FILTER_ENCARGOS: "filter-encargos",
  };

  static MARKETPLACE_REQS = {
    TEST: "test-marketplace",
    GET_MODULOS_OFERTAS: "get-modulos-ofertas",
    GET_PACKS: "get-packs",
    GET_MODULOS_PACKS: "get-modulos-packs",
    GET_MODULOS_INMO: "get-modulos-inmo",
    TIENE_MODULO_DEPENDIENTE: "tiene-modulo-dependiente",
    GET_MOVINS: "get-movins",
    INSERT_MODULO_INMO: "insert-modulo-inmo",
    DESACTIVAR_MODULO_INMO: "desactivar-modulo-inmo",
    GET_MODULOS_DEPENDIENTES: "get-modulos-dependientes",
    INSERT_CONFIG_PRODUCT: "insert-config-product",
    GET_PLANTILLA_EGO_BUTTON_APP: "get-plantilla-ego-button-app",
    INSERT_PLANTILLA_EGO_BUTTON_APP_GENERIC_PRODUCT:
      "insert-plantilla-ego-button-app-generic-product",
    GET_ID_PLANTILLA_EGO_BUTTON_APP_GENERIC_PRODUCT:
      "get-id-plantilla-ego-button-app-generic-product",
    SET_DEMO_REALIZADA: "set-demo-realizada",
    GET_DEMOS_INFO: "get-demos-info",
  };

  static EVENTS_REQS = {
    CONDITION_SELECTORS: "get-conditions-selectors-values",
    GET_ALL_EVENTS: "get-all-events",
    GET_ALL_REPORTS: "get-all-reports",
    GET_REPORTS_LIST: "get-reports-list",
    GET_WORKFLOW_REPORT: "get-report",
    GET_REPORT_USER_PERMISSION: "get-report-user-permission-list",
    INSERT_REPORT_USER_PERMISSION: "insert-report-user-permission-list",
    UPDATE_REPORT: "update-report",
    DELETE_REPORT: "delete-report",
    DUPLICATE_REPORT: "duplicate-report",
    GET_EVENT: "get-event",
    GET_EVENT_CONDITIONS: "get-event-conditions",
    INSERT_EVENT: "insert-event",
    UPDATE_EVENT: "update-event",
    DELETE_EVENT: "delete-event",
    INSERT_EMAIL_FROM: "insert-email-from",
    DELETE_EMAIL_FROM: "delete-email-from",
    GET_CAMPAIGN: "get-campaign",
    GET_ALL_CAMPAIGNS: "get-all-campaigns",
    INSERT_CAMPAIGN: "insert-campaign",
    UPDATE_CAMPAIGN: "update-campaign",
    DELETE_CAMPAIGN: "delete-campaign",
    SEARCH_CLIENTS: "search-clients",
    GET_USERS: "get-users",
    GET_CLIENTS_GROUPS: "get-clients-groups",
    GET_LIST_EXTERN: "get-list-extern",
    GET_ENUM_LIST: "get-enum-list-for-module-field",
    LOAD_PREDEFINED_VALUES: "load-predefined-values",
    LOAD_EXTERN_VALUES: "load-extern-values",
    GET_VALUES_FOR_MANAGEMENT: "get-workflow-manager-values",
    EDIT_WORKFLOW_MANAGEMENT: "edit-workflow-manager-item",
    CREATE_WORKFLOW_MANAGEMENT: "create-workflow-manager-item",
    GET_FIELDS_FRONTEND_NAMES: "get-fields-frontend-names",
    UPDATE_MRM_FIELDS: "update-MRM-fields",
    GET_FIELDS_MRM: "get-fieldsMRM",
    GET_CAMPAIGNS_FIELDS_MRM: "get-campaigns-fieldsMRM",
    SEND_EMAILS: "send-emails",
    GET_REPORT: "load-report-values",
    GENERATE_REPORT: "generate-report",
    GENERATE_REPORT_AS_PDF: "generate-report-as-pdf",
    INSERT_REPORT: "insert-report",
    GET_EMAILS: "get-emails",
    GET_EVENTS_MODULOS: "get-events-modulos",
    INSERT_TRACKING_LINK: "insert-tracking-link",
    GET_FUNCTIONS_TO_EXECUTE_BY_MODULE_ID:
      "get-functions-to-execute-by-module-id",
    QUEUE_EMAIL: "queue-email",
    CREATE_DYNAMIC_EGO_NOTIFICATION: "create-dynamic-ego-notification",
  };

  static EMAILTEMPLATES_REQS = {
    TEMPLATE_SELECTORS: "get-selectors-values",
    GET_ALL_TEMPLATES: "get-all-templates",
    GET_ALL_TEMPLATES_PDF: "get-all-templates_pdf",
    GET_ALL_TEMPLATES_NAMES: "get-all-templates-names",
    GET_ALL_TEMPLATES_NAMES_BY_MODULE: "get-all-templates-names-by-module",
    GET_TEMPLATE: "get-template",
    GET_TEMPLATE_PDF: "get-template-pdf",
    INSERT_TEMPLATE: "insert-template",
    UPDATE_TEMPLATE: "update-template",
    INSERT_TEMPLATE_PDF: "insert-template-pdf",
    UPDATE_TEMPLATE_PDF: "update-template-pdf",
    DELETE_TEMPLATE: "delete-template",
    DELETE_TEMPLATE_PDF: "delete-template-pdf",
    INSERT_TEMPLATE_TYPE: "insert-template-type",
    DELETE_TEMPLATE_TYPE: "delete-template-type",
    GET_ALL_EMAIL_IMAGES: "get-all-email-images",
    INSERT_EMAIL_IMAGE: "insert-email-image",
    GET_FILES_FOR_ATTACHMENT: "get-files-for-attachment",
    SEND_CONTACT_MAIL: "send-contact-mail",
    INSERT_CONTACT_INFO: "insert-contact-info",
    GET_TEMPLETE_BY_ID_MODULE: "get-templete-by-id-module",
    GET_TEMPLETE_VIEW: "get-templete-view",
    UPLOAD_TEMPLETE_IMAGE: "upload-templete-image",
    DELETE_TEMPLETE_IMAGE: "delete-templete-image",
    GET_ALL_TEMPLATES_BY_TYPE: "get-all-templates-by-type",
  };

  static TARGETLIST_REQS = {
    GET_ALL_TARGET_LISTS: "get-all-target-lists",
    INSERT_TARGET_LIST: "insert-target-list",
    DELETE_TARGET_LIST: "delete-target-list",
    GET_TARGET_LIST: "get-target-list",
    UPDATE_TARGET_LIST: "update-target-list",
  };

  static COMMON_REQS = {
    GET_PORTAL_ACTIVITIES_AVAILABILITY: "get-portal-activities-availability",
    GET_ALL_COMMERCIAL_AVAILABLE: "get-all-commercial-available",
    COMMERCIAL_ACCEPTED_VISIT: "commercial-accepted-visit",
    COMMERCIAL_REJECTED_VISIT: "commercial-rejected-visit",
    GET_COMMERCIAL_NOTIFICATIONS: "get-commercial-notifications",
    GET_ACTIVITIES_NOTIFICATIONS: "get-activities-notifications",
    DELETE_NOTIFICATION: "delete-notification",
    COMMERCIAL_ACCEPTED_STREAMING: "commercial-accepted-streaming",
    COMMERCIAL_REJECTED_STREAMING: "commercial-rejected-streaming",
    COMMERCIAL_ACCEPTED_LIVE_STREAMING: "commercial-accepted-live-streaming",
    COMMERCIAL_REJECTED_LIVE_STREAMING: "commercial-rejected-live-streaming",
    GET_PROPERTY_INFO: "get-property-info",
    GET_LOGROS_DATA: "get-logros-data",
    GET_ADMIN_AREA_BY_INMO: "get-admin-area-by-inmo",
  };

  static EGONOTIFICATIONS_REQS = {
    GET_ALL_EGO_NOTIFICATIONS: "get-all-ego-notifications",
    GET_EGO_NOTIFICATION: "get-ego-notification",
    GET_EGO_HOME_NOTIFICATION: "get-ego-home-notification",
    GET_EGO_SUPERIOR_NOTIFICATION: "get-ego-superior-notification",
    GET_QUESTIONS: "get-questions",
    INSERT_EGO_ACTION: "insert-ego-action",
    DELETE_EGO_NOTIFICATION: "delete-ego-notification",
    GET_ALL_EGO_IMAGES: "get-all-ego-images",
    INSERT_EGO_IMAGE: "insert-ego-image",
    DELETE_IMAGE_EGO: "delete-image-ego",
    GET_EGO_NOTIFICATION_BY_ID: "get-ego-notification-by-id",
    UPDATE_EGO_NOTIFICATION_FINAL_HOUR: "update_ego_notification_final_hour",
    INSERT_EGO_MONITOREO: "insert_ego_monitoreo",
    CLICK_EGO_MONITOREO: "click_ego_monitoreo",
  };

  static LANDING_REQS = {
    GET_CATEGORIAS: "get-categorias",
    GET_INFO_INMO: "get-info-inmo",
    GET_INMOS: "get-inmos",
    GET_PUNTUACION_INMO: "get-puntuacion-inmo",
    GET_CAMPOS_MODULOS: "get-campos-modulo",
    GET_KNOWLEDGE_INFO: "get-knowledge-info",
    GET_HOME_INFO: "get-home-info",
    GET_TEXTOS_HOME: "get-textos-home",
    UPDATE_VALUE: "update-element",
    GET_SCREEN_INFO: "get-screen-info",
    GET_OPTIONS: "get-options",
    GET_NAME_INMO: "get-inmo-name-by-id",
    GET_NEWS_AREA: "get-news-area",
    GET_CINEMA: "get-cinema",
    GET_LIBRARY: "get-library",
    GET_REPORT_SPACE: "get-report-space",
    GET_ACADEMY: "get-academy",
    GET_PLAY_SPACE: "get-play-space",
    GET_TRANSFORMACION: "get-transformacion",
  };

  static GROUP_REQS = {
    CANCEL_GROUP_ACCOUNT: "cancel-group-account",
  };

  static PORTAL_REQS = {
    GET_PORTALS: "get-portals",
    GET_PORTALS_BY_INMO_ID: "get-portals-by-inmo-id",
    GET_PORTALS_FOR_NEW_PROPERTY: "get-portals-for-new-property",
    GET_VIVIENDAS_INMO_BY_PORTAL_ID: "get-viviendas-inmo-by-portal-id",
    GET_INMO_PORTAL: "get-inmo-portal",
    ADD_INMO_PORTAL: "add-inmo-portal",
    UPDATE_RELACION_VIVIENDA_PORTALES: "update-relacion-vivienda-portales",
    UPDATE_MASSIVE_RELACION_VIVIENDA_PORTALES:
      "update-massive-relacion-vivienda-portales",
  };

  static EMAIL_INMO_REQS = {
    UPDATE_EMAILS: "update-emails",
    CAPTURE_EMAIL: "capture-email",
    VALIDATE_EMAIL: "validate-email",
    SELECT_FOLDER: "select-folder",
    TEST_CRON: "execute-cron-lead",
    DESVINCULAR_GOOGLE_GMAIL: "desvincular-google-email",
  };

  static EXPORTERS = {
    PUBLISH_INMOFACTORY: "publish-inmofactory",
    UPDATE_INMOFACTORY: "update-inmofactory",
    UNPUBLISH_INMOFACTORY: "unpublish-inmofactory",
    UNPUBLISH_INMOFACTORY_PORTAL: "unpublish-inmofactory-portal",
    PUBLICAR_PORTALES_VIVIENDA: "publicar-portales-vivienda",
  };

  static GOOGLE_REQS = {
    VERIFY_ACCOUNT: "verifyAccount",
    NEW_EVENT_CALENDAR_GOOGLE: "new-calendar-event",
    SAVE_TOKEN: "save-token",
    GET_CALENDARS: "get-calendars",
    VINCULAR_GOOGLE_CALENDAR: "vincular-google-calendar",
    SET_CALENDARS_GOOGLE: "set-calendars",
    UNSET_ALL_CALENDARS: "unset-all-calendars-google",
    DESVINCULAR_CALENDAR_GOOGLE: "desvincular-google-calendar",
    VINCULAR_GOOGLE_GMAIL: "vincular-google-gmail",
    DESVINCULAR_GOOGLE_GMAIL: "desvincular-google-gmail",
  };
  static MICROSOFT_REQS = {
    NEW_EVENT_CALENDAR_MICROSOFT: "new-calendar-event",
    GET_CALENDARS: "get-calendars",
    VINCULAR_MICROSOFT_CALENDAR: "vincular-microsoft-calendar",
    DESVINCULAR_MICROSOFT_CALENDAR: "desvincular-microsoft-calendar",
    SET_MICROSOFT_CALENDARS: "set-calendars",
    UNSET_ALL_CALENDARS: "unset-all-calendars-microsoft",
    DESVINCULAR_MICROSOFT_MAIL: "desvincular-microsoft-mail",
  };

  static SOCIAL_MEDIA_REQS = {
    VINCULAR_TWITTER: "vincular-twitter",
    DESVINCULAR_TWITTER: "desvincular-twitter",
    VINCULAR_INSTAGRAM: "vincular-instagram",
    DESVINCULAR_INSTAGRAM: "desvincular-instagram",
    VINCULAR_FACEBOOK: "vincular-facebook",
    DESVINCULAR_FACEBOOK: "desvincular-facebook",
    VINCULAR_LINKEDIN: "vincular-linkedin",
    DESVINCULAR_LINKEDIN: "desvincular-linkedin",
    GET_SOCIAL_MEDIA_ACCOUNTS: "get-social-media-accounts",
  };

  static HOTELS_REQS = {
    DUPLICATE_ACCOMMODATION: "duplicate-accommodation",
  };

  static KNOWLEDGE_TYPES: { id: number; name: string }[] = [
    { id: 1, name: "NewsArea" },
    { id: 2, name: "Cinema" },
    { id: 3, name: "Library" },
    { id: 4, name: "ReportSpace" },
    { id: 5, name: "Academy" },
    { id: 6, name: "PlaySpace" },
    { id: 7, name: "Transformación" },
  ];

  static MARKETPLACE_TYPES: { name: string; type: number; main: number }[] = [
    { name: "Mis módulos", type: 1, main: 1 },
    { name: "Todos los módulos", type: 2, main: 1 },
    { name: "Próximamente", type: 12, main: 1 },
    // {name: 'Packs', type: 3, main: 1}
  ];

  static KNOWLEDGE_TYPES2: { name: string; type: number; main: number }[] = [
    { name: "Proceso de transformación digital", type: 11, main: 1 },
    { name: "NewsArea", type: 4, main: 1 },
    { name: "Cinema", type: 5, main: 1 },
    { name: "Library", type: 6, main: 1 },
    { name: "ReportSpace", type: 7, main: 1 },
    { name: "Academy", type: 8, main: 1 },
    { name: "PlaySpace", type: 9, main: 1 },
    //{name: 'landing', type: 10, main: 2}
  ];

  static KNOWLEDGE_TYPES_LANDING: {
    name: string;
    type: number;
    main: number;
  }[] = [{ name: "landing", type: 10, main: 2 }];

  static RESPONSIVE_OPTIONS = [
    {
      breakpoint: "1250px",
      numVisible: 5,
      numScroll: 1,
    },
    {
      breakpoint: "1024px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "960px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "600px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  static EDITOR_CONFIG: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "auto",
    minHeight: "0",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    defaultParagraphSeparator: "",
    defaultFontName: "Arial",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" },
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    uploadUrl: "v1/image",
    sanitize: true,
    toolbarPosition: "top",
  };

  static COLABORATION_REQUEST: {
    inmoSolicitante: number;
    inmoSolicitada: number;
    idUsuario: number;
    estado: number;
    esPrivada: number;
  } = null;
}
