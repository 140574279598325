<h2 *ngIf="title !== null" mat-dialog-title>{{title}}</h2>

<div mat-dialog-content>{{message}}</div>
<div class="dialog-footer-2">

  <mat-form-field>
    <input matInput type="text" placeholder="Escribe aquí" maxlength="100"
           [(ngModel)]="input" autocomplete="off" required>
  </mat-form-field>

  <button mat-primary class="mat-raised-button"(click)="clickButton(1)">Aceptar</button>
  <button class="mat-raised-button" (click)="clickButton(2)">Cancelar</button>

</div>
