<h1 mat-dialog-title>CONFIRMA LA BAJA</h1>
<div mat-dialog-content>
  <p>¿Desea cancelar la renovacion del módulo <b>{{data.modulo['nombre']}}</b>?</p>
  <p *ngIf="data.productos.length">Esto afectará a los siguientes productos:</p>
  <ul style="margin-left: 30px;" *ngFor="let producto of data.productos">
    <li>{{producto.nombre}}</li>
  </ul>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancelar</button>
  <button mat-button (click)="onAceptar()">Aceptar</button>
</div>
