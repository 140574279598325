import { Injectable } from "@angular/core";

//SERVICES
import {GenericService} from "../generic.service";
import {EndpointService} from "../../endpoint/endpoint.service";
import {PrinterService} from "app/services/printer/printer.service";

@Injectable({
  providedIn: "root",
})
export class PrintTicketService {
  private productType = {
    TARIFAS: "tarifas",
    SERVICIOS: "servicios",
    RESTAURANT: "restaurant",
  }
  
  constructor(public genericService: GenericService, public endpointService: EndpointService, public printerService: PrinterService) {}

  private printTicket(ticketInfo, printerInfo, from) {
    // from == 0 és ticket de restaurant, from == 1 és ticket chiquipark

    interface Producto {
      name: string;
      ppu: number;
      units: number;
      total: number;
    }
  
    let productos: Producto[] = [];

    interface Tarifa {
      name?: string;
      ppu?: string;
      units?: number;
      total?: number;
    }
  
    let tarifas: Tarifa[] = [];

    interface Servicio {
      name: string;
      ppu: number;
      units: number;
      total: number;
    }
  
    let servicios: Servicio[] = [];

    interface printTicket {
      ip?: string,
      port?: number,
      imagepath?: string,
      invoice?: Invoice
    }

    interface Header {
      name?: String,
      f_s_t?: String,
      camarero_o_influencer?: String,
      impresora?: String;
    }

    interface Header2 {
      num_ticket?: String,
      table?: String,
      camarero?: String,
      fecha_ticket_generado?: String,
      fecha_factura_generada?: String,
      hora?: String,
      comment?: String,
    }

    interface TotalImport {
      importe_ticket?: String,
      importe_ticket_final?: String,
      descuento_ticket?: String
    }

    interface Total2 {
      comensales?: String,
      precioComensal?: String
    }

    interface Footer {
      cif?: String,
      address1?: String,
      telefono?: String
    }

    interface Footer2 {
      thanks?: String,
      wifi?: String
    }

    interface FooterCliente {
      nombre?: String,
      nif_cif?: String,
      telefono?: String,
      direccion?: String,
      geo_fullname?: String,
      cp?: String  
    }

    interface Invoice {
      header?: Header,
      header2?: Header2,
      plus?: Producto[],
      plusTarifas?: Tarifa[],
      plusServicios?: Servicio[],
      totalImport?: TotalImport,
      total2?: Total2,
      footer?: Footer,
      footerCliente?: FooterCliente,
      footer2?: Footer2
    }
    
    if(!printerInfo || printerInfo === undefined) {
      this.genericService.openSnackBar("No se ha encontrado ninguna impresora para imprimir", 4000, ["lightred-snackbar"]);
      return;
    }
    if(!printerInfo['logo'] || printerInfo['logo'] === undefined) {
      this.genericService.openSnackBar("No se ha añadido el logo de la empresa", 4000, ["lightblue-snackbar"]);
    }
    
    let ticketInfoPrint = {} as printTicket;
    if(from == 0) {
      for(let i in ticketInfo) {
        productos.push({"name": ticketInfo[i]['nombre_producto'], "ppu": ticketInfo[i]['importe_unitario_producto'], "units": ticketInfo[i]['cantidad'], "total": ticketInfo[i]['importe_total_producto']})
      }
      if(!productos || productos === undefined || productos.length == 0) {
        this.genericService.openSnackBar("No hay productos para imprimir en el ticket", 4000, ["lightred-snackbar"]);
        return;
      }
      if (ticketInfo[0]['factura_solicitada'] == 1) {
        // Aquí va la IP de la impresora
        ticketInfoPrint = {
          "ip":printerInfo['ip_printer'],
          "port":printerInfo['port_printer'],
          "imagepath":printerInfo['logo'],
          "invoice":{
            "header":{
              "name":ticketInfo[0]['nombre_empresa'],
              "f_s_t":"Factura: " + ticketInfo[0]['num_factura']
            },
            "header2":{
              "fecha_factura_generada":"Fecha factura: " + ticketInfo[0]['fecha_factura_generada'],
              "fecha_ticket_generado":"Fecha ticket: " + ticketInfo[0]['fecha_ticket_generado'],
              "num_ticket":"Num. ticket: " + ticketInfo[0]['num_ticket'],
              "table":"Mesa: " + ticketInfo[0]['nombre_mesa']
            },
            "plus":productos,
            "totalImport":{
              "importe_ticket":ticketInfo[0]['importe_ticket'],
              "importe_ticket_final":ticketInfo[0]['importe_ticket_final'],
              "descuento_ticket":ticketInfo[0]['descuento_ticket']
            },
            "total2":{
              "comensales":"Comensales: " + ticketInfo[0]['num_comensales'],
              "precioComensal":"Precio por Comensal: " + ticketInfo[0]['precio_comensal'],
            },
            "footer":{
              "cif":"CIF: " + ticketInfo[0]['cif_empresa'],
              "address1":ticketInfo[0]['direccion_empresa'] + ", " + ticketInfo[0]['poblacion_empresa'] + " (" + ticketInfo[0]['codigo_postal_empresa'] + ")",
              "telefono":"Teléfono: " + ticketInfo[0]['telefono_empresa']
            },
            "footerCliente":{
              "nombre":"Nombre: " + ticketInfo[0]['nombre'] + " " + ticketInfo[0]['apellidos'],
              "nif_cif":"NIF/CIF: " + ticketInfo[0]['nif_cif'],
              "telefono":"Tlf: " + ticketInfo[0]['telefono'],
              "direccion":"Dirección: " + ticketInfo[0]['direccion_habitual'],
              "geo_fullname":ticketInfo[0]['geo_fullname'],
              "cp":"CP: " + ticketInfo[0]['codigo_postal']              
            },
            "footer2":{
              "thanks":printerInfo['impresora_texto_1'],
              "wifi":printerInfo['impresora_texto_2']
            },
          },
        }
      }
      else {
        // Aquí va la IP de la impresora
        ticketInfoPrint = {
          "ip":printerInfo['ip_printer'],
          "port":printerInfo['port_printer'],
          "imagepath":printerInfo['logo'],
          "invoice":{
            "header":{
              "name":ticketInfo[0]['nombre_empresa'],
              "f_s_t":"Factura simplificada"
            },
            "header2":{
              "num_ticket":"Num. ticket: " + ticketInfo[0]['num_ticket'],
              "table":"Mesa: " + ticketInfo[0]['nombre_mesa'],
              "camarero":"Camarero: " + ticketInfo[0]['camarero'],
              "fecha_ticket_generado":ticketInfo[0]['fecha_ticket_generado']
            },
            "plus":productos,
            "totalImport":{
              "importe_ticket":ticketInfo[0]['importe_ticket'],
              "importe_ticket_final":ticketInfo[0]['importe_ticket_final'],
              "descuento_ticket":ticketInfo[0]['descuento_ticket']
            },
            "total2":{
              "comensales":"Comensales: " + ticketInfo[0]['num_comensales'],
              "precioComensal":"Precio por Comensal: " + ticketInfo[0]['precio_comensal'],
            },
            "footer":{
              "cif":"CIF: " + ticketInfo[0]['cif_empresa'],
              "address1":ticketInfo[0]['direccion_empresa'] + ", " + ticketInfo[0]['poblacion_empresa'] + " (" + ticketInfo[0]['codigo_postal_empresa'] + ")",
              "telefono":"Teléfono: " + ticketInfo[0]['telefono_empresa']
            },
            "footer2":{
              "thanks":printerInfo['impresora_texto_1'],
              "wifi":printerInfo['impresora_texto_2']
            },
          },
        }
      }
    }
    else if(from == 1) {
      for(let i in ticketInfo) {
        productos.push({"name": ticketInfo[i]['nombre_producto'], "ppu": ticketInfo[i]['importe_unitario_producto'], "units": ticketInfo[i]['cantidad_producto'], "total": ticketInfo[i]['importe_total_producto']})
      }
      for(let i in ticketInfo) {
        servicios.push({"name": ticketInfo[i]['nombre_servicio'], "ppu": ticketInfo[i]['importe_unitario_servicio'], "units": ticketInfo[i]['cantidad_servicio'], "total": ticketInfo[i]['importe_total_servicio']})
      }
      for(let i in ticketInfo) {
        tarifas.push({"ppu": ticketInfo[i]['nombre_tarifa'], "total": ticketInfo[i]['importe_total_tarifa']})
      }
      let invoice = {} as Invoice;
      let header = {} as Header;
      let header2 = {} as Header2;
      let totalImport = {} as TotalImport;
      let footer = {} as Footer;
      let footer2 = {} as Footer2;

      // Dades impressora
      ticketInfoPrint.ip = printerInfo['ip_printer'];
      ticketInfoPrint.port = printerInfo['port_printer'];
      ticketInfoPrint.imagepath = printerInfo['logo'];

      // Dades header
      header.name = ticketInfo[0]['nombre_empresa'];
      header.f_s_t = "Factura: " + ticketInfo[0]['num_factura'];
      invoice.header = header;

      // Dades header2
      header2.fecha_factura_generada = "Fecha factura: " + ticketInfo[0]['fecha_factura_generada']
      header2.fecha_ticket_generado = "Fecha ticket: " + ticketInfo[0]['fecha_ticket_generado']
      header2.num_ticket = "Num. ticket: " + ticketInfo[0]['num_ticket']
      invoice.header2 = header2;

      // Dades productes, serveis i tarifes
      invoice.plusTarifas = tarifas;
      invoice.plusServicios = servicios;
      invoice.plus = productos;

      // Dades totalImport
      totalImport.importe_ticket = ticketInfo[0]['importe_ticket'];
      totalImport.importe_ticket_final = ticketInfo[0]['importe_ticket_final'];
      totalImport.descuento_ticket = ticketInfo[0]['descuento_ticket']; 
      invoice.totalImport = totalImport;

      // Dades footer
      footer.cif = "CIF: " + ticketInfo[0]['cif_empresa'];
      footer.address1 = ticketInfo[0]['direccion_empresa'] + ", " + ticketInfo[0]['poblacion_empresa'] + " (" + ticketInfo[0]['codigo_postal_empresa'] + ")";
      footer.telefono = "Teléfono: " + ticketInfo[0]['telefono_empresa'];
      invoice.footer = footer;

      // Dades footer2
      footer2.thanks = printerInfo['impresora_texto_1'];
      footer2.wifi = printerInfo['impresora_texto_2']
      invoice.footer2 = footer2;

      ticketInfoPrint.invoice = invoice;
      console.log("EEEE: ", ticketInfoPrint);
    }
    this.printerService.printInvoice(ticketInfoPrint)
    // this.endpointService.printTicketOut(ticketInfoPrint, printerInfo['ip_network']).subscribe(respImpresora => {
    //   console.log(respImpresora);
    // });
  }

  public printTicketRestaurant(idTicket) {
    // Símobolo € cal?
    // Imagen: debe ser monocromo, png, de 200x200px máximo
    this.endpointService.printRestTicket(idTicket).subscribe(data => {
      let ticketInfo = data['response']['ticket'];
      let printerInfo = data['response']['printer'];
      this.printTicket(ticketInfo, printerInfo, 0);
    });
  }

  public printTicketActLudic(idTicket) {
    this.endpointService.printActLudicTicket(idTicket).subscribe(data => {
      let ticketInfo = data['response']['ticket'];
      let printerInfo = data['response']['printer'];
      console.log('TICKET INFO FROM API:', ticketInfo);
      console.log('PRINTER INFO FROM API:', printerInfo);
      // this.printTicket(ticketInfo, printerInfo, 1); // TODO => revisar al desarrollar funcionalidad de imprimir Factura
      let tarifas = [];
      let servicios = [];
      let productos = [];

      for (let i in ticketInfo['tarifas']) {
        tarifas.push({ "name": ticketInfo['tarifas'][i]['nombre_asistente'], "ppu": ticketInfo['tarifas'][i]['nombre_tarifa'], "units": ticketInfo['tarifas'][i]['duracion_minutos'], "total": ticketInfo['tarifas'][i]['importe_total'], "iva": ticketInfo['tarifas'][i]['iva_tarifa'] })
      }
      for (let i in ticketInfo['servicios']) {
        servicios.push({ "name": ticketInfo['servicios'][i]['nombre'], "ppu": ticketInfo['servicios'][i]['ppu'], "units": ticketInfo['servicios'][i]['cantidad'], "total": ticketInfo['servicios'][i]['importe_total'], "iva": ticketInfo['servicios'][i]['iva_servicio'] })
      }
      for (let i in ticketInfo['productos']) {
        productos.push({ "name": ticketInfo['productos'][i]['nombre_producto'], "ppu": ticketInfo['productos'][i]['ppu'], "units": ticketInfo['productos'][i]['cantidad'], "total": ticketInfo['productos'][i]['importe_total'], "iva": ticketInfo['productos'][i]['iva'] })
      }

      const iva = this.setupIVA(tarifas, servicios, productos);

      let ticketInfoPrint = {
        "ip": printerInfo['ip_printer'],
        "port": printerInfo['port_printer'],
        "imagepath": printerInfo['logo'],
        "invoice": {
          "header": {
            "name": ticketInfo[0]['nombre_empresa'],
            "f_s_t": "Factura simplificada"
          },
          "header2": {
            "num_ticket": "Num. ticket: " + ticketInfo[0]['num_ticket'],
            "fecha_ticket_generado": "Fecha ticket: " + ticketInfo[0]['fecha_ticket_generado'],
            "user_checkout": "Usuario: " + ticketInfo[0]['nombre_usuario_checkout']
          },
          "tarifas": tarifas,
          "servicios": servicios,
          "plus": productos,
          "iva": iva,
          "totalImport": {
            "importe_ticket": ticketInfo[0]['importe_ticket'],
            "importe_ticket_final": ticketInfo[0]['importe_ticket_final'],
            "descuento_ticket": ticketInfo[0]['descuento_ticket']
          },
          "footer": {
            "cif": "CIF: " + ticketInfo[0]['cif_empresa'],
            "address1": ticketInfo[0]['direccion_empresa'] + ", " + ticketInfo[0]['poblacion_empresa'] + " (" + ticketInfo[0]['codigo_postal_empresa'] + ")",
            "telefono": "Teléfono: " + ticketInfo[0]['telefono_empresa']
          },
          "footerCliente": {
            "nombre": "Nombre: " + ticketInfo[0]['nombre_cliente'] + " " + ticketInfo[0]['apellidos_cliente'],
            "nif_cif": "NIF/CIF: " + ticketInfo[0]['nif_cif_cliente'],
            "telefono": "Tlf: " + ticketInfo[0]['telefono_cliente'],
            "direccion": "Dirección: " + ticketInfo[0]['direccion_cliente'],
            "cp": "CP: " + ticketInfo[0]['cp_cliente']
          },
          "footer2": {
            "thanks": printerInfo['impresora_texto_1'],
            "wifi": printerInfo['impresora_texto_2']
          },
        },
      }
      this.printerService.printActLudic(ticketInfoPrint);

    });
  }

  public printCommandRestaurant(idPedido) {
    this.endpointService.printComanda(idPedido).subscribe(data => {
      let commandInfo = data['response']['command'];
      let printersInfo = data['response']['printer'];
      let id_category0 = 0;
      for(let i in printersInfo) {
        let comanda = [];
        let printerInfo = printersInfo[i];
        if(!printerInfo || printerInfo === undefined) {
          this.genericService.openSnackBar("No se ha encontrado ninguna impresora para imprimir la comanda", 4000, ["lightred-snackbar"]);
          continue;
        }
        
        for(let j in commandInfo) {
          if(id_category0 == 0) id_category0 = commandInfo[j]['id_categoria'];
          for(let i in commandInfo[j]['productos']) {
            if(commandInfo[j]['productos'][i]['id_impresora'] == printerInfo['id_impresora']) {
              let product = {"name": commandInfo[j]['productos'][i]['nombre_producto'], "units": commandInfo[j]['productos'][i]['cantidad']}
              if(commandInfo[j]['productos'][i]['suplementos'] && commandInfo[j]['productos'][i]['suplementos'].length > 0) {
                let suplementos = [];
                console.log(commandInfo[j]['productos'][i]['suplementos']);
                for(let k in commandInfo[j]['productos'][i]['suplementos']) {
                  suplementos.push({"name": commandInfo[j]['productos'][i]['suplementos'][k]['nombre']});
                }
                product['suplementos'] = suplementos;
              }
              if(commandInfo[j]['productos'][i]['comentario'] && commandInfo[j]['productos'][i]['comentario'].length > 0) {
                product['description'] = commandInfo[j]['productos'][i]['comentario'];
              }
              let foundKey = -1;
              for(let m in comanda) {
                if(comanda[m]['category'] == commandInfo[j]['categoria']) {
                  foundKey = +m;
                  break;
                }
              }
              if(foundKey == -1) {
                comanda.push({'category': commandInfo[j]['categoria'], 'products': [product]});
              } else {
                comanda[foundKey]['products'].push(product);
              }
            }
          }
        }

        if(!comanda || comanda === undefined || comanda.length == 0) {
          this.genericService.openSnackBar("No hay productos para imprimir en la comanda", 4000, ["lightred-snackbar"]);
          continue;
        }

        let beep = false;
        if(printerInfo['cajon'] != 1) beep = true;

        console.log("commandInfo", commandInfo);
        if(id_category0 != 0) {
          // Aquí va la IP de la impresora
          let commandInfoPrint = {
            "ip":printerInfo['ip_printer'],
            "port":printerInfo['port_printer'],
            "beep":beep,
            "invoice":{
              "header":{
                "camarero_o_influencer": commandInfo[id_category0]['productos'][0]['camarero_o_influencer'],
                "impresora":printerInfo['nombre'],
              },
              "header2":{
                "num_ticket":"Num. pedido: " + commandInfo[id_category0]['productos'][0]['num_pedido'],
                "camarero":"Camarero: " + commandInfo[id_category0]['productos'][0]['camarero'],
                "hora":commandInfo[id_category0]['productos'][0]['hora'],
                "num_comensales":"Comensales: " + commandInfo[id_category0]['productos'][0]['num_comensales'],
                "table":"Mesa: " + commandInfo[id_category0]['productos'][0]['nombre_mesa'],
                "sala":"Sala: " + commandInfo[id_category0]['productos'][0]['nombre_sala'],
                "comment": "Comentario: " + commandInfo[id_category0]['productos'][0]['comentario_pedido']
              },
              "plus":comanda
            },
          }
          this.printerService.printCommand(commandInfoPrint['ip'], commandInfoPrint['port'], commandInfoPrint['invoice'], commandInfoPrint['beep']);

          // this.endpointService.printCommandOut(commandInfoPrint, printerInfo['ip_network']).subscribe(respImpresora => {
          //   console.log(respImpresora);
          // });
        }
      }
    });
  }

  public printCommandCanceladosRestaurant(idPedido) {
    this.endpointService.printComandaCancelados(idPedido).subscribe(data => {
      let commandInfo = data['response']['command'];
      let printersInfo = data['response']['printer'];
      let id_category0 = 0;
      for(let i in printersInfo) {
        let comanda = [];
        let printerInfo = printersInfo[i];
        if(!printerInfo || printerInfo === undefined) {
          this.genericService.openSnackBar("No se ha encontrado ninguna impresora para imprimir la comanda", 4000, ["lightred-snackbar"]);
          continue;
        }
        
        for(let j in commandInfo) {
          if(id_category0 == 0) id_category0 = commandInfo[j]['id_categoria'];
          for(let i in commandInfo[j]['productos']) {
            if(commandInfo[j]['productos'][i]['id_impresora'] == printerInfo['id_impresora']) {
              let product = {"name": commandInfo[j]['productos'][i]['nombre_producto'], "units": commandInfo[j]['productos'][i]['cantidad']}
              if(commandInfo[j]['productos'][i]['suplementos'] && commandInfo[j]['productos'][i]['suplementos'].length > 0) {
                let suplementos = [];
                console.log(commandInfo[j]['productos'][i]['suplementos']);
                for(let k in commandInfo[j]['productos'][i]['suplementos']) {
                  suplementos.push({"name": commandInfo[j]['productos'][i]['suplementos'][k]['nombre']});
                }
                product['suplementos'] = suplementos;
              }
              if(commandInfo[j]['productos'][i]['comentario'] && commandInfo[j]['productos'][i]['comentario'].length > 0) {
                product['description'] = commandInfo[j]['productos'][i]['comentario'];
              }
              let foundKey = -1;
              for(let m in comanda) {
                if(comanda[m]['category'] == commandInfo[j]['categoria']) {
                  foundKey = +m;
                  break;
                }
              }
              if(foundKey == -1) {
                comanda.push({'category': commandInfo[j]['categoria'], 'products': [product]});
              } else {
                comanda[foundKey]['products'].push(product);
              }
            }
          }
        }

        if(!comanda || comanda === undefined || comanda.length == 0) {
          this.genericService.openSnackBar("No hay productos para imprimir en la comanda", 4000, ["lightred-snackbar"]);
          continue;
        }

        let beep = false;
        if(printerInfo['cajon'] != 1) beep = true;

        // Aquí va la IP de la impresora
        let commandInfoPrint = {
          "ip":printerInfo['ip_printer'],
          "port":printerInfo['port_printer'],
          "beep":beep,
          "invoice":{
            "header":{
              "cancelado":"**CANCELADO**",
              "camarero_o_influencer": commandInfo[id_category0]['productos'][0]['camarero_o_influencer'],
              "impresora":printerInfo['nombre'],
            },
            "header2":{
              "num_ticket":"Num. pedido: " + commandInfo[id_category0]['productos'][0]['num_pedido'],
              "camarero":"Camarero: " + commandInfo[id_category0]['productos'][0]['camarero'],
              "hora":commandInfo[id_category0]['productos'][0]['hora'],
              "num_comensales":"Comensales: " + commandInfo[id_category0]['productos'][0]['num_comensales'],
              "table":"Mesa: " + commandInfo[id_category0]['productos'][0]['nombre_mesa'],
              "comment": "Comentario: " + commandInfo[id_category0]['productos'][0]['comentario_pedido']
            },
            "plus":comanda,
            "footer":{
              "cancelado":"**CANCELADO**",
            },
          },
        }
        this.printerService.printCommand(commandInfoPrint['ip'], commandInfoPrint['port'], commandInfoPrint['invoice'], commandInfoPrint['beep']);
        // this.endpointService.printCommandOut(commandInfoPrint, printerInfo['ip_network']).subscribe(respImpresora => {
        //   console.log(respImpresora);
        // });
      }
    });
  }

  public openCashRestaurant(idEmpresa) {
    // "192.168.1.15", "9100", "192.168.1.66" => Lior (delete this line after push)
    this.endpointService.openCash(idEmpresa).subscribe(data => {
      let printerInfo = data['response']['printer'];
      if(!printerInfo || printerInfo === undefined) {
        this.genericService.openSnackBar("No se ha encontrado ninguna impresora con cajón asignado", 4000, ["lightred-snackbar"]);
        return;
      }
      
      let printerInfoData = {"ip": printerInfo['ip_printer'], "port": printerInfo['port_printer']};
      this.printerService.openCashDrawer(printerInfoData['ip'], printerInfoData['port']);
      // this.endpointService.openCashRest(printerInfoData, printerInfo['ip_network']).subscribe(respImpresora => {
      //   console.log(respImpresora);
      // });
    });
  }

  public printMovementRestaurant(idMovimiento) {
    this.endpointService.printMovimiento(idMovimiento).subscribe(data => {
      console.log("Data: ", data);
      let movementInfo = data['response']['movement'];
      let printerInfo = data['response']['printer'];
      if(!printerInfo || printerInfo === undefined) {
        this.genericService.openSnackBar("No se ha encontrado ninguna impresora para imprimir", 4000, ["lightred-snackbar"]);
        return;
      }
      if(!movementInfo || movementInfo === undefined) {
        this.genericService.openSnackBar("No hay movimiento para imprimir", 4000, ["lightred-snackbar"]);
        return;
      }
      // Aquí va la IP de la impresora
      let movementInfoPrint = {
        "ip":printerInfo['ip_printer'],
        "port":printerInfo['port_printer'],
        "movement":{
          "header":{
            "text":movementInfo[0]['nombre_ticket'],
          },
          "header2":{
            "fecha":"\x1B\x21\x08" + "Fecha: " + "\x1B\x21\x00" + movementInfo[0]['fecha_insert'],
            "usuario":"\x1B\x21\x08" + "Usuario: " + "\x1B\x21\x00" + movementInfo[0]['nombre'],
            "concepto":"\x1B\x21\x08" + "Concepto: " + "\x1B\x21\x00" + movementInfo[0]['descripcion']
          },
          "footer":{
            "total":"Total: " + movementInfo[0]['importe'].replace('.', ',')
          },
        },
      }
      this.printerService.printMovement(movementInfoPrint['ip'], movementInfoPrint['port'], movementInfoPrint['movement'])
      // this.endpointService.printMovementOut(movementInfoPrint, printerInfo['ip_network']).subscribe(respImpresora => {
      //   console.log(respImpresora);
      // });
    });
  }

  public printCierreCajaRestaurant(idCierre) {
    this.endpointService.printCierreCaja(idCierre).subscribe(data => {
      console.log("Data: ", data);
      let cierreInfo = data['response']['cierre'];
      let cobrosInfo = data['response']['cobros'];
      let movimientosInfo = data['response']['movimientos'];
      let totalesInfo = data['response']['totales'];
      let printerInfo = data['response']['printer'];
      if(!printerInfo || printerInfo === undefined) {
        this.genericService.openSnackBar("No se ha encontrado ninguna impresora para imprimir", 4000, ["lightred-snackbar"]);
        return;
      }

      if(!cierreInfo || cierreInfo === undefined) {
        this.genericService.openSnackBar("No hay cierres de caja para imprimir", 4000, ["lightred-snackbar"]);
        return;
      }
      if(!cierreInfo[0]['fecha_cierre_caja'] || cierreInfo[0]['fecha_cierre_caja'] == null) cierreInfo[0]['fecha_cierre_caja'] = "";
      // Aquí va la IP de la impresora
      let cierreInfoPrint = {
        "ip":printerInfo['ip_printer'],
        "port":printerInfo['port_printer'],
        "cierre":{
          "referencia":"Núm. Cierre: " + cierreInfo[0]['referencia'],
          "header":{
            "caja":"Caja: " + cierreInfo[0]['caja'],
            "camarero":"Camarero: " + cierreInfo[0]['camarero'],
            "inicio_jornada":"Inicio de Jornada: " + cierreInfo[0]['fecha_insert'],
            "hasta":"Hasta: " + cierreInfo[0]['fecha_cierre_caja'],
          },
          "saldo_inicial":cierreInfo[0]['saldo_inicial'],
          "importe_ingresos":cierreInfo[0]['importe_ingresos'],
          "cobros":cobrosInfo,
          "totales":totalesInfo,
          "importe_movimientos":cierreInfo[0]['importe_movimientos'],
          "movimientos":movimientosInfo,
          "saldo_final_calculado":cierreInfo[0]['saldo_final_calculado'], 
          "saldo_final_manual":cierreInfo[0]['saldo_final_manual'], 
          "importe_propinas":cierreInfo[0]['importe_propinas'],
        },
      }
      console.log(cierreInfoPrint);
      
      this.printerService.printCierreOrSnapshotCaja(cierreInfoPrint['ip'], cierreInfoPrint['port'], cierreInfoPrint['cierre'], false);

      // this.endpointService.printCierreOut(cierreInfoPrint, printerInfo['ip_network']).subscribe(respImpresora => {
      //   console.log(respImpresora);
      // });
    });
  }

  public printCierreCajaSnapshotRestaurant(idCierreCajaSnapshot) {
    this.endpointService.printCierreCajaSnapshot(idCierreCajaSnapshot).subscribe(data => {
      console.log("Data: ", data);
      let snapshotInfo = data['response']['snapshot'];
      let cobrosInfo = data['response']['cobros'];
      let movimientosInfo = data['response']['movimientos'];
      let totalesInfo = data['response']['totales'];
      let printerInfo = data['response']['printer'];
      if(!printerInfo || printerInfo === undefined) {
        this.genericService.openSnackBar("No se ha encontrado ninguna impresora para imprimir", 4000, ["lightred-snackbar"]);
        return;
      }

      if(!snapshotInfo || snapshotInfo === undefined) {
        this.genericService.openSnackBar("No hay snapshots de caja para imprimir", 4000, ["lightred-snackbar"]);
        return;
      }
      // Aquí va la IP de la impresora
      let snapshotInfoPrint = {
        "ip":printerInfo['ip_printer'],
        "port":printerInfo['port_printer'],
        "snapshot":{
          "referencia":"Núm. Snapshot: " + snapshotInfo[0]['referencia'],
          "header":{
            "caja":"Caja: " + snapshotInfo[0]['caja'],
            "camarero":"Camarero: " + snapshotInfo[0]['camarero'],
            "fecha_snapshot":"Fecha snapshot: " + snapshotInfo[0]['fecha_insert'],
            "inicio_jornada":"Inicio de Jornada: " + snapshotInfo[0]['fecha_cierre_caja'],
            "referencia_cierre_caja":"Cierre Vinculado: " + snapshotInfo[0]['referencia_cierre_caja'],
          },
          "saldo_inicial":snapshotInfo[0]['saldo_inicial'],
          "importe_ingresos":snapshotInfo[0]['importe_ingresos'],
          "cobros":cobrosInfo,
          "totales":totalesInfo,
          "importe_movimientos":snapshotInfo[0]['importe_movimientos'],
          "movimientos":movimientosInfo,
          "saldo_final_calculado":snapshotInfo[0]['saldo_final_calculado'], 
          "saldo_final_manual":snapshotInfo[0]['saldo_final_manual'], 
          "importe_propinas":snapshotInfo[0]['importe_propinas'],
        },
      }
      console.log(snapshotInfoPrint);

      this.printerService.printCierreOrSnapshotCaja(snapshotInfoPrint['ip'], snapshotInfoPrint['port'], snapshotInfoPrint['snapshot'], true);
      // this.endpointService.printSnapshotOut(snapshotInfoPrint, printerInfo['ip_network']).subscribe(respImpresora => {
      //   console.log(respImpresora);
      // });
    });
  }

  public printPedidoRestaurant(idPedido) {
    this.endpointService.printPedido(idPedido).subscribe(data => {
      let ticketInfo = data['response']['ticket'];
      let printerInfo = data['response']['printer'];
      let productos = [];
      let importeTotal = 0;
      let importePorComensal = 0;
      if(!printerInfo || printerInfo === undefined) {
        this.genericService.openSnackBar("No se ha encontrado ninguna impresora para imprimir", 4000, ["lightred-snackbar"]);
        return;
      }
      if(!printerInfo['logo'] || printerInfo['logo'] === undefined) {
        this.genericService.openSnackBar("No se ha añadido el logo de la empresa", 4000, ["lightblue-snackbar"]);
      }
      for(let i in ticketInfo) {
        productos.push({"name": ticketInfo[i]['nombre_producto'], "ppu": ticketInfo[i]['importe_unitario_producto'].replace(".", ","), "units": ticketInfo[i]['cantidad'], "total": ticketInfo[i]['importe_total_producto']})
        let precio  = ticketInfo[i]['importe_total_producto'];
        importeTotal += +precio;
      }
      // importeTotal = Math.round(importeTotal * 100) / 100; // Redondea a dos decimales
      // importePorComensal = Math.round(importeTotal/+ticketInfo[0]['num_comensales'] * 100) / 100;
      importePorComensal = (importeTotal / +ticketInfo[0]['num_comensales']);

      let importeTotalText = importeTotal.toFixed(2).toString().replace(".", ",");
      let importePorComensalText = importePorComensal.toFixed(2).toString().replace(".", ",");

      if(!productos || productos === undefined || productos.length == 0) {
        this.genericService.openSnackBar("No hay productos para imprimir en el ticket", 4000, ["lightred-snackbar"]);
        return;
      }
      let ticketInfoPedido = {
        "ip":printerInfo['ip_printer'],
        "port":printerInfo['port_printer'],
        "imagepath":printerInfo['logo'],
        "pedido":{
          "header":{
            "name":ticketInfo[0]['nombre_empresa'],
            "f_s_t":"Factura simplificada"
          },
          "header2":{
            "num_pedido":"Num. pedido: " + ticketInfo[0]['num_pedido'],
            "table":"Mesa: " + ticketInfo[0]['nombre_mesa'],
            "camarero":"Camarero: " + ticketInfo[0]['camarero'],
            "fecha_ticket_generado":ticketInfo[0]['fecha_ticket_generado']
          },
          "plus":productos,
          "totalImport":{
            "importe_ticket":importeTotalText
          },
          "total2":{
            "comensales":"Comensales: " + ticketInfo[0]['num_comensales'],
            "precioComensal":"Precio por Comensal: " + importePorComensalText,
          },
          "footer":{
            "cif":"CIF: " + ticketInfo[0]['cif'],
            "address1":ticketInfo[0]['direccion'] + ", " + ticketInfo[0]['geo_name'] + " (" + ticketInfo[0]['codigo_postal'] + ")",
            "telefono":"Teléfono: " + ticketInfo[0]['telefono']
          },
          "footer2":{
            "thanks":printerInfo['impresora_texto_1'],
            "wifi":printerInfo['impresora_texto_2']
          },
        },
      }

      console.log('PRINTER INFO', printerInfo);
      console.log('TICKET INFO PEDIDO', ticketInfoPedido);
      this.printerService.printPedido(ticketInfoPedido);
      // this.printerService.printPedidoNormalized(ticketInfoPedido);

      // this.endpointService.printPedidoOut(ticketInfoPedido, printerInfo['ip_network']).subscribe(respImpresora => {
      //   console.log(respImpresora);
      // });
    });

  }

  private groupAndSumByIVA(array, groups, groupName) {
  array.forEach(item => {
    if (groupName === this.productType.RESTAURANT && item.iva === null) {
      item.iva = '10.00';
    }

    if (Object.values(item).some(value => value === null)) {
      return;
    }

    const ivaKey = item.iva || 'sin_iva';
    if (!groups[ivaKey]) {
      groups[ivaKey] = {
        items: [],
        accumulated_without_iva: 0
      };
    }

    groups[ivaKey].items.push(item);
    groups[ivaKey].accumulated_without_iva += parseFloat(item.total);
  });

    Object.keys(groups).forEach(key => {
      groups[key].accumulated_without_iva = parseFloat(groups[key].accumulated_without_iva.toFixed(2));
    });
  }

  private setupIVA(tarifas, servicios, productos) {
    const gruposPorIVA = {};
    this.groupAndSumByIVA(tarifas, gruposPorIVA, this.productType.TARIFAS);
    this.groupAndSumByIVA(servicios, gruposPorIVA, this.productType.SERVICIOS);
    this.groupAndSumByIVA(productos, gruposPorIVA, this.productType.RESTAURANT);

    if (gruposPorIVA['sin_iva']) delete gruposPorIVA['sin_iva'];

    return gruposPorIVA;
  }

 }
