import { DatePipe } from "@angular/common";
import { HttpErrorResponse, HttpEventType } from "@angular/common/http";
import { EventEmitter, Injectable, OnInit } from '@angular/core';
import { forkJoin, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ChatGroupUserInfo } from "../header/chat/bottom-sheet-add-user-to-group/bottom-sheet-add-user-to-group.component";
import { SelectedChatInfo } from "../header/chat/chat.component";
import { GroupRoomChat } from "../header/chat/models/group-room-chat";
import { InmoGroupRoom } from "../header/chat/models/inmo-group-room";
import { MessageChat } from "../header/chat/models/message-chat";
import { RoomChat } from "../header/chat/models/room-chat";
import { UserChat } from "../header/chat/models/user-chat";
import { UserRoom } from "../header/chat/models/user-room";
import { ApiChatService } from "./api-chat/api-chat.service";
import { AuthService } from "./auth/auth.service";
import { EndpointService } from "./endpoint/endpoint.service";
import { SocketioService } from "./socketio.service";

@Injectable({
  providedIn: 'root'
})
export class ChatService implements OnInit {
  public Record = [];
  public PrivateChatRooms: RoomChat[] = [];
  public PrivateChatRoomsMap = new Map();
  public GroupChatRooms: GroupRoomChat[] = [];
  public AllUserPrivateChatRooms: UserRoom[] = [];
  public myInfo: UserChat;
  public limitResults = 15;
  public profilePicture = null;
  public unread_conversations : { [key:string]:[]; } = {};
  public non_read = 0;
  private PRIVATE_CONVERSATION_TYPE = 1;
  private GROUP_TYPE = 2;
  private INMO_TYPE = 3;
  private non_read_conversations : any[];
  private users_online = new Map();

  public pendingChats: number;
  public userGroup = {};
  files = [];
  selectedChat: SelectedChatInfo = {id: '-1', chatType: -1};
  title = '';
  ioConnection: any;
  chatScroll: EventEmitter<number> = new EventEmitter();


  constructor(private endpointService: EndpointService,
              private apiChatService: ApiChatService,
              public datepipe: DatePipe,
              public authService: AuthService,
              public socketService: SocketioService) {
  }

  emitChatScrollEvent(number) {
    this.chatScroll.emit(number);
  }

  ngOnInit() {
     this.initIoConnection();
  }

  public getPublicGroups() {
    return this.apiChatService.getPublicGroups(this.authService.user_token).subscribe(data => {
      console.log('publicGroups', data, this.authService.user_token);
      data.forEach(group => {
        this.handleGroup(group, this.authService.user_token);
      });
    });
  }

  public getNonReadMessagesCount() {
    return this.apiChatService.getNonReadMessages(this.authService.user_token).subscribe(data => {
      this.non_read = data.length;

      for (let conversation of data){
        let key = conversation['_id']['conversation_id'];
        this.PrivateChatRoomsMap.get(key).nonReadMessages = conversation['count'];
      }
      this.non_read_conversations = data;
      return data;
    });
  }

  public onEnteredChat(selectedChat: any){
    this.socketService.sendEnteredToChat(selectedChat, this.authService.user_token);
  }
  
  public onTyping(selectedChat: any){
    this.socketService.sendTyping(selectedChat, this.authService.user_token);
  }

  public onNoLongerTyping(selectedChat: any){
    this.socketService.sendNoLongerTyping(selectedChat, this.authService.user_token);
  }

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}
 

  public initIoConnection(): void {
    console.log('ENTRO?');
    /*this.apiChatService.getLastMessages(this.authService.userId+"", groupId, 0 , 1).subscribe(data => {
      if(data){
        console.log('Cuidado crack');
        //this.PrivateChatRoomsMap.get(groupId).test = data['messages'][0].msg;
        console.log(data);
        //console.log(this.PrivateChatRoomsMap.get(groupId));
      }
    });*/
    console.log('chat', this.authService.user_token);
    this.socketService.initSocket(this.authService.user_token);
    this.ioConnection = this.socketService.onTyping()
    .subscribe((data) => {
      // console.log('Has sido añadido a un nuevo grupo!!!');
      // console.log(groupObj);
      this.PrivateChatRoomsMap.get(data.selectedchat).isTyping = true;
    });

    this.ioConnection = this.socketService.onEnteredChat()
    .subscribe((data) => {
      // console.log('Has sido añadido a un nuevo grupo!!!');
      // console.log(groupObj);

      for(let i = this.PrivateChatRoomsMap.get(data.selectedchat).newmessages.length - 1; i>=0; --i){
        /*this.PrivateChatRoomsMap.get(data.selectedchat).newmessages[i].isRead = true;
          this.PrivateChatRoomsMap.get(data.selectedchat).newmessages[i].lastSeen = new Date() + ""
        this.PrivateChatRoomsMap.get(data.selectedchat).messages.unshift(this.PrivateChatRoomsMap.get(data.selectedchat).newmessages[i]);*/
        if(this.PrivateChatRoomsMap.get(data.selectedchat).newmessages[i].isRead) break;
        else {
          this.PrivateChatRoomsMap.get(data.selectedchat).newmessages[i].isRead = true;
          this.PrivateChatRoomsMap.get(data.selectedchat).newmessages[i].lastSeen = new Date() + "";
        }
      }

      //this.PrivateChatRoomsMap.get(data.selectedchat).newmessages = [];
      
    });

    this.ioConnection = this.socketService.onNoLongerTyping()
    .subscribe((data) => {
      // console.log('Has sido añadido a un nuevo grupo!!!');
      // console.log(groupObj);
      this.PrivateChatRoomsMap.get(data.selectedchat).isTyping = false;
    });

    this.ioConnection = this.socketService.onMessage()
      .subscribe((message) => {
        // SEND PUSH NOTIFIACTION HERE
        if(!this.PrivateChatRoomsMap.get(message['groupId']).left){
        if(this.selectedChat.id == message['groupId']){
          this.onEnteredChat(this.selectedChat.id);
        }
        this.PrivateChatRoomsMap.get(message['groupId']).lastMessage = message.date;
        this.PrivateChatRoomsMap.get(message['groupId']).message = message.msg;
        if(this.PrivateChatRoomsMap.get(message['groupId']).infoCargada === true 
        || this.PrivateChatRoomsMap.get(message['groupId']).messages.length == 0) {
          // 606d6c989c7e1c0cdc84865d 606d6c989c7e1c0cdc84865d
          let msg = new MessageChat(message._id, message.userId, message.msg, message.date, message.msgType, '', true,message.read);
          let index = this.PrivateChatRoomsMap.get(message['groupId']).messages.length;
          if(index > 0){
            let previousMsg = this.PrivateChatRoomsMap.get(message['groupId']).messages[index-1];
            let diff = (new Date(msg.date).getTime() - new Date(previousMsg.date).getTime());
            let days = Math.round(diff / (1000 * 3600 * 24));
            console.log(days, previousMsg, msg );

            if(days >= 1){
              this.PrivateChatRoomsMap.get(message['groupId']).newmessages.push(new MessageChat("-1", "-1", this.formatDate(msg.date) , new Date(), 5, '', true, true, new Date() + ""));
            }    
          }
          this.PrivateChatRoomsMap.get(message['groupId']).newmessages.push(msg);

        }
        if (this.selectedChat.id !== message['groupId']) {
          this.PrivateChatRoomsMap.get(message['groupId']).nonReadMessages += 1;
          this.non_read = 0;
          this.PrivateChatRoomsMap.forEach((key, value) => {
            if(key.nonReadMessages > 0){
              this.non_read += 1;
            }
          });
  
        }
        else {
          this.emitChatScrollEvent(0);
        }
        this.sortChat();
      }
      });

      this.ioConnection = this.socketService.onGroup()
      .subscribe((groupObj) => {
        // console.log('Has sido añadido a un nuevo grupo!!!');
        // console.log(groupObj);
        this.handleGroup(groupObj, this.authService.user_token);
        // this.PrivateChatRoomsMap.get(groupObj['groupId']).messages.push(groupObj);
      });

    this.ioConnection = this.socketService.onUpdateGroup()
      .subscribe((groupObj) => {
        // console.log('se ha actualizado el grupo!!');
        // console.log(groupObj);
        this.handleGroup(groupObj, this.authService.user_token);
        // this.PrivateChatRoomsMap.get(groupObj['groupId']).messages.push(groupObj);
      });

     this.ioConnection = this.socketService.onUserOnline()
      .subscribe((userObj) => {
        console.log(userObj, 'user online');
        if(userObj.userId !== this.authService.user_token) {
          if(userObj.isFirstRun === true) {
            setTimeout(() => {
              // console.log('Alerta, info importante del usuario!!');
              // console.log(userObj);
              this.setUserStatus(userObj.userId, userObj.isOnline);
            }, 0); //NOTA IBRA: Buscar la forma de evitar este timeout cuando carga la primera vez
          }
          else {
            // console.log('Alerta, info importante del usuario!!');
            // console.log(userObj);
            this.setUserStatus(userObj.userId, userObj.isOnline);
          }

        }
      });

     this.ioConnection = this.socketService.onUpdateUser()
      .subscribe((groupObj) => {
        // console.log('USER ADDED OR REMOVED FROM GROUP!!');
        // console.log(groupObj);
        if(groupObj.action === "remove") {
          console.log('USER REMOVED FROM GROUP!!');
          this.PrivateChatRoomsMap.get(groupObj.groupId).usersMap.delete(groupObj.userId);
        }
        else if(groupObj.action === "add") {
          console.log('USER ADDED TO GROUP!!');
          // [{ type: 'ping', data: 'probe' }]
          this.userAddedToGroup(groupObj.groupId, groupObj.userId).then(res => {});
        }
        // console.log(groupObj);
        // this.handleGroup(groupObj, this.authService.user_token);
        // this.PrivateChatRoomsMap.get(groupObj['groupId']).messages.push(groupObj);
      });

    this.socketService.onEvent(Event.CONNECT)
      .subscribe(() => {
        console.log('CONNECT',);
        /*this.updateGroup('5f71bb65de0b0c63c62fc06e', undefined, undefined, undefined, undefined,
          false, undefined, undefined, undefined);*/
        this.getAllGroups(this.authService.user_token);
      });

    this.socketService.onEvent(Event.DISCONNECT)
      .subscribe(() => {
      });

  }

  acceptGroupInvitation(groupId, userId){
    return this.apiChatService.acceptGroupInvitation(groupId, userId);
  }

  rejectGroupInvitation(groupId, userId){
    return this.apiChatService.rejectGroupInvitation(groupId, userId);

  }

  getGroupInfo(groupId) {
    return this.apiChatService.getGroupInfo(groupId, this.authService.user_token);
  }

  userBelongsToGroup(groupId){
    return this.apiChatService.userBelongsToGroup(groupId, this.authService.user_token);
  }

  joinPublicGroup(groupId){
    return this.apiChatService.joinPublicGroup(groupId, this.authService.user_token);
  }

  leaveGroup(groupId){
    return this.apiChatService.leaveGroup(groupId, this.authService.user_token);
  }

  generateLink(groupId){
    return this.apiChatService.generateInvitationLink(groupId, this.authService.user_token);
  }

  getInvitationLink(groupId){
    return this.apiChatService.getInvitationLink(groupId, this.authService.user_token);
  }

  setUserStatus(userId, isOnline) {
    this.users_online.set(userId, isOnline);
    Array.from(this.PrivateChatRoomsMap.values()).filter(item => {
      if(item?.usersMap.has(userId)) {
        item.usersMap.get(userId).online = isOnline;
      }
    });
  }

  getMessages(userId, groupId) {
    this.apiChatService.getMessages(userId, groupId, this.PrivateChatRoomsMap.get(groupId).snapshot, this.limitResults).subscribe(data => {
      if (data) {
        console.log('getMessages', data);
        //console.log(conversation_id, index, this.unread_conversations, non_read);
        if(this.PrivateChatRoomsMap.get(groupId).nonReadMessages > 0) {
          if(this.non_read > 0){
            this.non_read -= 1;
          }
          this.PrivateChatRoomsMap.get(groupId).nonReadMessages = 0;
        }
        // console.log('El mensaje mas reciente es este --> ' + data['messages'][0]['_id']);
        this.PrivateChatRoomsMap.get(data['_id']).startPagination = this.PrivateChatRoomsMap.get(data['_id']).lastReadMessage = data['messages'][0]['_id'];
        let msgs = [];
        data['messages'].forEach((m: any) => {
          let message
          if(!m.read){ // nuevo
            message = new MessageChat(m._id, m.userId, m.msg, m.date, m.msgType, '', true, m.read,m.lastseen);
            //this.PrivateChatRoomsMap.get(data['_id']).newmessages.unshift(message);
            msgs.unshift(message);
          }else{
            message = new MessageChat(m._id, m.userId, m.msg, m.date, m.msgType, '', false, m.read,m.lastseen);
            msgs.unshift(message);
          }
          
        });
        this.PrivateChatRoomsMap.get(data['_id']).messages.push(msgs[0]);

        for(let i=1;i<msgs.length;++i){
          if(msgs[i]){
            let diff = (new Date(msgs[i].date).getTime() - new Date(msgs[i-1].date).getTime());
            let days = Math.round(diff / (1000 * 3600 * 24));
            console.log(days, msgs[i-1], msgs[i] );

            if(days >= 1){
              this.PrivateChatRoomsMap.get(data['_id']).messages.push(new MessageChat("-1", "-1", this.formatDate(msgs[i].date) , new Date(), 5, '', true, true, new Date() + ""));
            }
            this.PrivateChatRoomsMap.get(data['_id']).messages.push(msgs[i]);
        }

        }
        if (data['messages'].length === this.limitResults) this.PrivateChatRoomsMap.get(groupId).snapshot += 1;
        this.PrivateChatRoomsMap.get(groupId).allMessagesLoaded = (data['messages'].length < this.limitResults);
      }
      else {
        this.PrivateChatRoomsMap.get(groupId).allMessagesLoaded = true;
      }
      this.PrivateChatRoomsMap.get(groupId).infoCargada = true;
    });
  }


  getAllGroups(userId) {
    this.apiChatService.getAllGroups(userId).subscribe(async data => {
      console.log('getAllGroups', userId, data);
      data.forEach(group => {
        this.handleGroup(group, userId);
      });
    })
  }

  handleGroup(group, userId) {
    let room: any = null;
    if(!group['lastMessage']){
      group['lastMessage'] = group['creationDate'];
    }
    if (group['groupType'] === this.PRIVATE_CONVERSATION_TYPE) { // conversacion
      this.apiChatService.getLastMessages(userId, group['_id']).subscribe(data => {

        let lastmessage = "";
        if(data){
          lastmessage = data['messages'][0].msg
        }
        room = new RoomChat(group['_id'], group['groupType'], group['creationDate'], [], [], null, null, group['lastMessage'], lastmessage);

        this.PrivateChatRoomsMap.set(group['_id'], room);
        this.handleUserInfo(group['_id'], group['users'], userId).then(res => {});
      });
      
    } else if (group['groupType'] === this.GROUP_TYPE) { // grupo
      let isPublic = 2;
      if(group['isPublic']){
        isPublic = group['isPublic'];
      } 
      
      let user_left = false;
      let user_belongs = false;
      for(let user of group['users']){

        if(!this.userGroup[group['_id']]){
          this.userGroup[group['_id']] = {}
        }

        this.userGroup[group['_id']][user.userId] = {
          left: user.left, accepted: user.accepted, rejected:user.rejected, 
          borrado: user.borrado, isAdmin: user.isAdmin, canTalk: user.canTalk}

        if(!user.left && user.userId == this.authService.user_token) {
          user_belongs = true;
          break;
        }
        if(user.left && user.userId == this.authService.user_token){
          user_left = true;
          break;
        }
      }
      room = new GroupRoomChat(group['_id'], group['groupType'], group['creationDate'], [], [], null, null,
        group['groupDesc'], group['groupImg'], group['groupName'], 1, group['adminAdd'], group['adminTalk'],
        true, group['lastMessage'], group['users'],isPublic,user_left, user_belongs);
      this.PrivateChatRoomsMap.set(group['_id'], room);
      this.handleUserInfo(group['_id'], group['users'], userId).then(res => {});
    } else if (group['groupType'] === this.INMO_TYPE) {
      // let inmoId = parseInt(group['agency'], 10) !== this.authService.inmoId ? parseInt(group['agency'], 10) : parseInt(group['agency'], 10);
      if(parseInt(group['agency'], 10) === this.authService.inmoId) {
        this.endpointService.getInmoNameByUserId(group['creatorId']).subscribe(res => {
          this.getInfoInmo(group, room, userId, res['response'][0].id);
        });
      }
      else {
        this.getInfoInmo(group, room, userId, parseInt(group['agency'], 10));
      }

      // console.log('HANDLE IN PROCESS');
    }
    this.sortChat();
  }


  getInfoInmo(group, room, userId, inmoId) {
    this.endpointService.getInfoInmo(inmoId).subscribe(data => {
      let groupName = data['response'][0].nombre;
      let groupPic = data['response'][0].logo_cuadrado;
      room = new InmoGroupRoom(group['_id'], group['groupType'], inmoId, group['creationDate'], groupName, groupPic, [], [],
        undefined, undefined, group['lastMessage']);
      this.PrivateChatRoomsMap.set(group['_id'], room);
      this.handleUserInfo(group['_id'], group['users'], userId).then(res => {});
    });
  }

  createGroup(groupType, userId, users, groupName?, groupDesc?, groupImg?, adminTalk?, adminAdd? ) {
    this.apiChatService.createGroup(groupType, groupName, groupDesc, groupImg, adminTalk, adminAdd, userId, users).subscribe(res => {

      this.selectedChat.chatType = res['groupType'];
      this.selectedChat.id = res['_id'];

      let checkExist = setInterval(async () => {
        //await console.log('waiting...');
        if (this.PrivateChatRoomsMap.has(res['_id'])) {
          this.selectedChat = {id: res['_id'], chatType: res['groupType']};
          this.PrivateChatRoomsMap.get(res['_id']).infoCargada = true;
          this.PrivateChatRoomsMap.get(res['_id']).allMessagesLoaded = true;
          await clearInterval(checkExist);
        }
      }, 200);

    });
  }

  updateGroup(groupId: string, groupType?: number, groupName?: string, groupDesc?: string, groupImg?: string, adminTalk?: boolean,
              adminAdd?: boolean, userId?: string, creationDate?: Date, isPublic?) {
    this.apiChatService.updateGroup(groupId, groupType, groupName, groupDesc, groupImg, adminTalk, adminAdd, userId, creationDate, isPublic).subscribe(res => {
    })
  }

  sendMessage(userId, msgType, msg, groupId) {
    this.apiChatService.sendMessage(userId, msgType, msg, groupId).subscribe(res => {
      //this.PrivateChatRoomsMap.get(groupId).push(new MessageChat('0', userId, msg, new Date(), msgType, '', true, true));
      this.sortChat();
    });
  }

  private handleUserInfo(roomId: string, users: any[], userId: string) {
    return new Promise(async (resolve, reject) => {
      const observersArray = [];
      users.forEach((us: any) => {
        let p = new Promise(resolve => {
          this.endpointService.getUserChatInfo(us.userId).subscribe(data => {
            let user = new UserChat(us.userId, data['response'].nombre, data['response'].foto, data['response'].nombreInmo, false, us.isAdmin);
            resolve({user: user, usToken: us.userId});
          });
        });
        observersArray.push(p);
        
      });
      forkJoin(observersArray).subscribe(d => {
        d.forEach((us: any) => {

          if (us.usToken === userId) {
            this.PrivateChatRoomsMap.get(roomId).myInfo = us.user;
          }
          if ((us.usToken !== userId && this.PrivateChatRoomsMap.get(roomId).roomTypeId === 1) || this.PrivateChatRoomsMap.get(roomId).roomTypeId !== 1) {
            this.PrivateChatRoomsMap.get(roomId).usersMap.set(us.usToken, us.user);
          }
        })
        resolve(roomId);
      })
    });
  }

  private userAddedToGroup(roomId: string, userId: string) {
    return new Promise(async (resolve, reject) => {
      let p1 = new Promise(resolve => {
        this.endpointService.getUserChatInfo(userId).subscribe(data => {
          let user = new UserChat(userId, data['response'].nombre, data['response'].foto, data['response'].nombreInmo, false, false);
          resolve({user: user, usToken: userId});
        });
      });
      p1.then((res: any) => {
        this.PrivateChatRoomsMap.get(roomId).usersMap.set(res.usToken, res.user);
        resolve(res);
      })
    });
  }
  async paginate(groupId, userId) {
    this.apiChatService.getMessages(userId, groupId, this.PrivateChatRoomsMap.get(groupId).snapshot, this.limitResults,
      this.PrivateChatRoomsMap.get(groupId).startPagination).subscribe(data => {
      if (data) {
        let msgs = [];
        data['messages'].forEach((m: any) => {
          let message = new MessageChat(m._id, m.userId, m.msg, m.date, m.msgType, '', true, m.read);
          msgs.push(message);
        });
        this.PrivateChatRoomsMap.get(groupId).infoCargada = true;
        if (data['messages'].length === this.limitResults) this.PrivateChatRoomsMap.get(groupId).snapshot += 1;
        this.PrivateChatRoomsMap.get(groupId).allMessagesLoaded = data['messages'].length < this.limitResults;
      
        for(let i =1;i<msgs.length;++i){
          
          if(msgs[i]){
            
            let diff = (new Date(msgs[i-1].date).getTime() - new Date(msgs[i].date).getTime());
            let days = Math.round(diff / (1000 * 3600 * 24));
            console.log(days, msgs[i-1], msgs[i] );
            if(days >= 1){
              this.PrivateChatRoomsMap.get(data['_id']).messages.unshift(new MessageChat("-1", "-1", this.formatDate(msgs[i-1].date) , new Date(), 5, '', true, true, new Date() + ""));
            }    
           this.PrivateChatRoomsMap.get(data['_id']).messages.unshift(msgs[i]);
            
          }

        }

      /*for (let i =0;i<msgs.length-1;++i) {
        if(msgs[i].date )
      }*/
    }
    });
  }

  async createChatGroup(groupName: string, groupDescription: string, selectedUsers: any,
                        onlyAdminCanChat: boolean, onlyAdminCanAdd: boolean, onlyAdminCanEditGroupInfo: boolean,
                        groupOpened: number, picture: any, myToken: string) {
    this.apiChatService.createGroup(2, groupName, groupDescription, undefined,
      onlyAdminCanChat, onlyAdminCanAdd, myToken, selectedUsers, undefined, groupOpened).subscribe(res =>{

      let checkExist = setInterval(async () => {
        console.log('waiting...');
        if (this.PrivateChatRoomsMap.has(res['_id'])) {
          this.PrivateChatRoomsMap.get(res['_id']).allMessagesLoaded = true;
          this.selectedChat = {id: res['_id'], chatType: res['groupType']};
          await clearInterval(checkExist);
        }
      }, 200);

      this.uploadFile(picture, res['_id']).then((result) => {
        let checkExist = setInterval(async () => {
          //await console.log('waiting...');
          if (this.PrivateChatRoomsMap.has(res['_id'])) {
            this.PrivateChatRoomsMap.get(res['_id']).groupPic = result;
            await clearInterval(checkExist);
          }
        }, 200);
      });
    })
  }

  uploadFile(file, groupId) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      let imageName = null;
      if (file) {
        formData.append('file', file?.data);
        file.inProgress = true;
        imageName = file.name;
      
      this.endpointService.uploadChatGroupPicture(formData, imageName, groupId).pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round(event.loaded + 0.000000001 / event.total);
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          file.inProgress = false;
          return of(`${file.data.name} upload failed.`);
        })).subscribe((event: any) => {
        if (typeof (event) === 'object') {
          this.profilePicture = event.body.response;
          this.apiChatService.updateGroup(groupId, undefined, undefined, undefined, event.body.response,
            undefined, undefined, undefined, undefined).subscribe(res =>{
            resolve(event.body.response,);
          });
          /*this.endpointService.insertNewRoomGroup(groupId, groupRoom['fecha'], groupRoom['id_tipo_sala'], groupRoom['nombre'],
            groupRoom['descripcion'], groupRoom['groupOpened'], groupRoom['onlyAdminCanChat'], groupRoom['onlyAdminCanAdd'],
            groupRoom['onlyAdminCanEditGroupInfo'], event.body.response).subscribe(data => {
            resolve();
          });*/
        }
      });
    }
    });
  }

  addUsersToGroup(users: Map<string, ChatGroupUserInfo>, groupId, name) {
    users.forEach((value, key) => {
      this.apiChatService.addUser(groupId, this.authService.user_token, {userId: key}).subscribe(res => {
        let mensaje = name.concat(' ha añadido a ').concat(value.name).concat(' al grupo');
        this.apiChatService.sendMessage(null, 5, mensaje, groupId).subscribe(res => {
        });
      });
    });
  }

  setUserAdmin(groupId, userId) {
    // Query para poner al usuario como admin
    this.apiChatService.setPrivilegies(groupId, this.authService.user_token, {userId: userId, isAdmin: true}).subscribe(res => {
    });
  }

  unsetUserAdmin(groupId, userId) {
    // Query para quitar el usuario como admin
    this.apiChatService.setPrivilegies(groupId, this.authService.user_token, {userId: userId, isAdmin: false}).subscribe(res => {
    });
  }

  updateGroupName(groupId, newName) {
    // Query para actuaizar el nombre del grupon
    this.updateGroup(groupId, undefined, newName, undefined, undefined,
          undefined, undefined, undefined, undefined);
  }

  updateGroupDescription(groupId, newDescription) {
    // Query para actualizar la descripción del grupo
    this.updateGroup(groupId, undefined, undefined, newDescription, undefined,
      undefined, undefined, undefined, undefined);
  }

  updateGroupConfiguration(onlyAdminCanChat, onlyAdminCanEditGroupInfo, onlyAdminCanAdd, groupType, groupId) {
    // Query para actualizar la configuración de un grupo
    this.updateGroup(groupId, 2, undefined, undefined, undefined,
      onlyAdminCanChat, onlyAdminCanAdd, undefined, undefined, groupType);
  }

  deleteGroupUser(groupId, userId, selfName, otherName) {
    //TODO: Llamar a la API para eliminar a un usuario de un grupo y dentro del subscribe poner todo esto;
    this.apiChatService.removeUser(groupId, this.authService.user_token, {userId: userId}).subscribe(res => {
      let mensaje = selfName.concat(' ha eliminado a ').concat(otherName).concat(' del grupo');
      this.apiChatService.sendMessage(userId, 5, mensaje, groupId).subscribe(res => {
      });
    });
  }

  scroll() {
    return this.chatScroll;
  }

  async createInmoGroup(inmoId, myToken) {
    return new Promise(async (resolve, reject) => {
      let users = [];

      await this.endpointService.getCommunityAdminsForChatInmoGroup(inmoId, myToken).subscribe(async res => {
        await res['response'].forEach(elem => {
          users.push({userId: elem.usuario_token, isAdmin: elem.responsable_chat === 1, canTalk: elem.responsable_chat === 1});
        });
        this.apiChatService.createGroup(3, undefined, undefined, undefined, true,
          true, myToken, users, inmoId).subscribe(data => {
          resolve({roomId: data['_id'], roomType: 3});
        });
      });
    });
  }

  private sortChat() {
    // Convert the map to array first and sort it by "lastMessage"
    const privateChatRooms = Array.from(this.PrivateChatRoomsMap).map(([key, value]) => ({key, ...value})).sort((a, b) => {
      let aDate = new Date(a.lastMessage);
      let bDate = new Date(b.lastMessage);
      if (aDate === bDate)
        return 0;
      else
        return aDate > bDate ? -1 : 1;
      }
    );

    // Rebuild the map after sorting it.
    const privateChatRoomsMap = new Map();
    privateChatRooms.forEach((chat) => privateChatRoomsMap.set(chat.key, chat));

    this.PrivateChatRoomsMap = privateChatRoomsMap;
  }
}

export enum Event {
  CONNECT = 'connect',
  DISCONNECT = 'disconnect'
}
