<div *ngIf="not_type === 1">
  <div *ngFor="let not of notifications" class="notis0">
    <div class="each-noti">
      <i *ngIf="not.estado === 1 && not.tipo === 0" class="material-icons color_yellow carteta" matTooltip="Pendiente de confirmar">person_add</i>
      <i *ngIf="not.estado === 2 && not.tipo === 0" class="material-icons color_green carteta" matTooltip="Visita aceptada">person_add</i>
      <i *ngIf="not.estado === 3 && not.tipo === 0" class="material-icons color_red carteta" matTooltip="Visita no disponible">person_add</i>
      <i *ngIf="not.estado === 1 && not.tipo === 1" class="material-icons color_yellow carteta" matTooltip="Pendiente de confirmar">live_tv</i>
      <i *ngIf="not.estado === 2 && not.tipo === 1" class="material-icons color_green carteta" matTooltip="Visita aceptada">live_tv</i>
      <i *ngIf="not.estado === 3 && not.tipo === 1" class="material-icons color_red carteta" matTooltip="Visita no disponible">live_tv</i>
      <p class="no-margin" style="display: inline-grid; padding-left: 7px !important; color: #fff;">
        <a style="font-size: 11px; text-transform: uppercase; font-weight: bold; padding: 0.5em 0; color: #fff !important;"> {{ not.titulo ? not.titulo : ''}}</a>
        <i *ngIf="not.estado === 1" style="font-size: 11px; color: #fff; padding-bottom: 0.3em;">
          <button style="margin-right: 1em; text-transform: uppercase; font-size: 11px; font-weight: bold;" class="btn-success" (click)="aceptarVisita(not); $event.stopPropagation()">Aceptar</button>
          <button style="margin-right: 1em; text-transform: uppercase; font-size: 11px; font-weight: bold;" class="btn-danger" (click)="rechazarVisita(not); $event.stopPropagation()">Rechazar</button>
        </i>
        <i class="text-info-noti" *ngIf="not.fecha_inicio"> <span class="material-icons option-mats-ics">query_builder</span> {{ not.fecha_inicio ? not.fecha_inicio + ' a ' + not.fecha_fin : ''}}</i>
        <i class="text-info-noti" *ngIf="not.desc_cancelacion"> <span class="material-icons color_red option-mats-ics">report_problem</span> {{ not.desc_cancelacion }}</i>
        <i class="text-info-noti" *ngIf="not.viviendaNombre" (click)="openPropertyInfo(not.id_vivienda); $event.stopPropagation()"> <span class="material-icons option-mats-ics">home</span>  {{not.viviendaNombre}}</i>
        <i class="text-info-noti" *ngIf="not.nombre_usuario" (click)="openClientInteressesInfo(not.id_usuario); $event.stopPropagation()"> <span class="material-icons option-mats-ics">person_pin</span>  {{not.nombre_usuario}}</i>
      </p>
      <mat-icon *ngIf="not.estado !== 1" class="removeit" (click)="deleteNotification(not.id); $event.stopPropagation()">cancel</mat-icon>
    </div>
    <mat-divider style="margin: 5px 15px;"></mat-divider>
  </div>
  <span *ngIf="notifications.length === 0"><i style="font-size: 11px; color: #fff !important;">No hay notificaciones</i></span>
</div>

<div *ngIf="not_type === 2">
  <div *ngFor="let data of egoNotifications; let i = index" class="notis0">
    <auto-advice-home [data]="data" [demo]="false" [index]="i"></auto-advice-home>
  </div>
    <span *ngIf="egoNotifications.length === 0"><i style="font-size: 11px; color: #fff !important;">No hay notificaciones</i></span>
</div>

<div *ngIf="not_type === 3">
  <div *ngFor="let event of activitiesNotifications" class="notis0">
    <div [ngClass]="event.lastActivatedUser ? 'each-noti each-noti-highlight' : 'each-noti'" *ngIf="event.estado_actividad === 0">
      <div class="no-margin" style="display: inline-grid; padding: 7px !important;">
        <!--<a style="font-size: 12px; text-transform: uppercase;">Actividad finalizada: <b>{{event.actividad[0].title}}</b></a>-->
        <a style="font-size: 11px; color: #fff; padding: 0.5em 0; text-transform: uppercase; font-weight: bold;">¿Se ha realizado la actividad <span class="yellow">{{event.titulo}}</span>?</a>
        <i style="font-size: 11px; color: #fff;" *ngIf="event.actividad[0]?.start_date"> <span class="material-icons option-mats-ics">query_builder</span> Inicio: {{ event.actividad[0]?.start_date | date:'dd/MM/yy, HH:mm' }}</i>
        <i style="font-size: 11px; color: #fff;" *ngIf="event.actividad[0]?.end_date"> <span class="material-icons option-mats-ics">query_builder</span> Fin: {{ event.actividad[0]?.end_date | date:'dd/MM/yy, HH:mm' }}</i>
        <span style="font-size: 11px; color: #fff; padding: 0.3em; 0">
          <button style="margin-right: 1em; text-transform: uppercase; font-size: 11px; font-weight: bold;" class="btn-success" (click)="setEventFinished(event); $event.stopPropagation()">Si</button>
          <button style="margin-right: 1em; text-transform: uppercase; font-size: 11px; font-weight: bold;" class="btn-danger" click-stop-propagation (click)="setEventCancelled(event); $event.stopPropagation()">No</button>
          <button style="margin-right: 1em; text-transform: uppercase; font-size: 11px; font-weight: bold;" class="btn-info" click-stop-propagation (click)="postponeEvent(event); $event.stopPropagation()">Posponer</button>
        </span>
        <div *ngIf="event.mostrarCancelacion" click-stop-propagation style="margin-top: 1em;">
          <div class="row-expansion1">
            <mat-form-field class="matFormFieldWhite" appearance="outline">
              <mat-label>Motivo de cancelación</mat-label>
              <textarea required matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" [(ngModel)]="tempComment" (ngModelChange)="tempComment = $event"></textarea>
            </mat-form-field>
          </div>
          <div class="generic-buttons-blue-revert">
            <button cdkFocusInitial style="margin: 0 0.5em 0.5em;" (click)="setMotivoCancelacion(event); $event.stopPropagation()" *ngIf="!isInvalid()">
              <i style="margin-right: 0.3em;" class="material-icons">check</i>
              ACEPTAR
            </button>
          </div>
        </div>
        <div *ngIf="event.mostrarPosponer" click-stop-propagation style="margin-top: 1em;">
          <div style="display: flex; flex-direction: column;">
            <mat-form-field class="matFormFieldWhite formFieldNoPadding" style="cursor: pointer" appearance="outline">
              <mat-label>Fecha de inicio</mat-label>
              <input matInput class="form-control" type="text" mwlFlatpickr [altInput]="true"
                     [ngModel]="event.newDateStart" (ngModelChange)="event.newDateStart = $event" (ngModelChange)="refresh.next()"
                     [convertModelValue]="true" [enableTime]="true" [time24hr]="true" dateFormat="Y-m-dTH:i"
                     altFormat="F j, Y H:i" [required]="true" [noCalendar]="false" [options]="datePickerOptions"/>
              <mat-icon matSuffix *ngIf="event.newDateStart" (click)="event.newDateStart = null; $event.stopPropagation()">close</mat-icon>
              <mat-error *ngIf="startDateIsInvalid(event.newDateStart)">{{getStartDateErrorMessage(event.newDateStart)}}</mat-error>
            </mat-form-field>
          </div>
          <div class="generic-buttons-blue-revert">
            <button cdkFocusInitial style="margin: 0 0.5em 0.5em;" *ngIf="event.newDateStart" (click)="postponeActivity(event.newDateStart, event); $event.stopPropagation()">
              <i style="margin-right: 0.3em;" class="material-icons">check</i>
              POSPONER
            </button>
          </div>
        </div>
        <span class="removeit more-info" style="top: inherit; bottom: 14px;" (click)="openActivityInfo(event); $event.stopPropagation()" matTooltip="Información de la actividad"> + Info
          <mat-icon style="font-size: 13px; width: auto; height: auto;">info</mat-icon>
        </span>
      </div>
    </div>
    <div class="each-noti" *ngIf="event.estado_actividad === 1">
      <p class="no-margin" style="display: inline-grid; padding-left: 7px !important; color: #fff;">
        <a style="font-size: 11px; color: #fff; padding: 0.5em 0; text-transform: uppercase; font-weight: bold;">{{event.mensaje_recordatorio}}</a>
        <i style="font-size: 11px; color: #fff;" *ngIf="event.actividad[0]?.start_date"> <span class="material-icons option-mats-ics">query_builder</span> Inicio: {{ event.actividad[0]?.start_date | date:'short' }}</i>
        <i style="font-size: 11px; color: #fff;" *ngIf="event.actividad[0]?.end_date"> <span class="material-icons option-mats-ics">query_builder</span> Fin: {{ event.actividad[0]?.end_date | date:'short' }}</i>
      </p>
      <mat-icon class="removeit" (click)="deleteNotification(event.id); $event.stopPropagation(); activitiesNotifications.pop(event)">cancel</mat-icon>
    </div>
    <mat-divider style="margin: 5px 15px;"></mat-divider>
  </div>
  <span *ngIf="activitiesNotifications.length === 0"><i style="font-size: 11px; color: #fff !important;">No hay notificaciones</i></span>
</div>
<!--    <auto-advice-home></auto-advice-home>-->
