
<span *ngIf="structure != undefined && structure != null">
    <ng-container *ngIf="filterArrayCondition() == 0; else printCondition">
    <div style="max-width: calc(100vw - 48px - 2vw); overflow: auto hidden; padding: 15px 0;">
        <app-events-conditions-custom-loop class="component-class" [conditionsArray]=[] [idEvento]="newIdEvento" [idReporte]="newIdReporte" [structure]="structure" [index]="this.index" [param]="param" [firstElement]="true"></app-events-conditions-custom-loop>
    </div>
    </ng-container>
    <ng-template #printCondition>
        <div style="max-width: calc(100vw - 48px - 2vw); overflow: auto hidden; padding: 15px 0;" *ngIf="filterArrayCondition()[0].children !== undefined; else button">
            <app-events-conditions-custom-loop class="component-class" [conditionsArray]="filterArrayCondition()[0].children" [conditionType]="functionsService.arrayConditions[0].id_subtipo" [structure]="structure" [index]="this.index" [conditionId]="functionsService.arrayConditions[0].id" [firstElement]="true" [param]="param"></app-events-conditions-custom-loop>
        </div>
    </ng-template>
</span>