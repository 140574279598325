import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.css']
})
export class MessageDialogComponent implements OnInit {

  // form: FormGroup;
  title: string = null;
  message: string = null;
  labelButton1: string = null;
  labelButton2: string = null;
  labelButton3: string = null;

  constructor(
    // private fb: FormBuilder,
    private dialogRef: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {

    if (data.title) {
      this.title = data.title;
    }
    if (data.message) {
      this.message = data.message;
    }
    if (data.labelButton1) {
      this.labelButton1 = data.labelButton1;
    }
    if (data.labelButton2) {
      this.labelButton2 = data.labelButton2;
    }
    if (data.labelButton3) {
      this.labelButton3 = data.labelButton3;
    }
  }

  ngOnInit() {
/*
    this.form = fb.group({
      message: [message, []],
    });
*/
  }

  clickButton(buttonOption: number) {
    this.dialogRef.close(buttonOption);
  }
}
