<!-- TOTAL PRECIO MÓDULOS COMPRADOS -->
<div fxFlex="100" fxLayout="row wrap" class="sms-total-movs" fxLayoutAlign="end" fxLayoutAlign.sm="center" fxLayoutAlign.xs="center" *ngIf="type === 1 && (this.data && this.data.length)">Tus productos actuales suponen un coste de <span class="yellow">{{this.totalModulos}} MOVS</span></div>

<!-- TOTAL MÓDULOS PARA COMPRAR -->
<div *ngIf="type === 2 && (this.data && this.data.length)" fxLayout="row wrap" fxFlex="100">
  <div fxFlex="100" fxLayout="row wrap" class="sms-total-movs" fxLayoutAlign="end" fxLayoutAlign.sm="center" fxLayoutAlign.xs="center">Si adquieres todos los productos el precio será de <span class="yellow">{{this.totalModulos}} MOVS</span></div>
  <div *ngFor="let card of data" fxFlex="20" fxFlex.md="33.33" fxFlex.sm="50" fxFlex.xs="100" fxLayout="row wrap">
    <div class="container-card modulos" *ngIf="card.id !== -1" fxLayout="row wrap" fxFlex="100">
      <div class="main-container" fxLayout="row wrap" fxFlex="100">
        <div class="left-part" fxFlex="50" fxLayout="row wrap" fxLayoutAlign=" start">
          <span class="titulo-modulo" fxFlex="100">{{card.nombre}}</span>
          <div class="price" fxFlex="100" fxLayout="row wrap">

            <!-- Movs -->
            <span fxFlex="100" *ngIf="card.precio < card.price_base" style="text-decoration: line-through; color: red;">{{card.price_base}} MOVS</span>
            <span fxFlex="100" *ngIf="card.precio > 0">{{card.precio}} MOVS</span>

            <!-- Euros -->
            <span fxFlex="100" *ngIf="card.price_final_euros < card.price_base_euros && card.precio > 0" style="text-decoration: line-through; color: red;">{{card.price_base_euros}} €</span>
            <span fxFlex="100" *ngIf="this.movinsComprados && card.precio > 0">{{card.price_final_euros}} €</span>
            <span fxFlex="100" *ngIf="!this.movinsComprados && card.precio > 0">Desde {{card.price_final_euros}}€</span>

            <!-- Descuento -->
            <span fxFlex="100" *ngIf="card.price_discount && card.price_discount != 0 && card.precio > 0" style="color: grey">-{{card.price_discount}} MOVS</span>
            <span fxFlex="100" *ngIf="card.price_discount_perc && card.price_discount_perc != 0 && card.precio > 0" style="color: grey">-{{card.price_discount_perc}}%</span>

            <!-- Free -->
            <span fxFlex="100" *ngIf="card.precio <= 0" >Gratuito</span>

          </div>
        </div>
        <div class="right-part" fxFlex="50">
          <div class="category-product-0">
            <span class="category-product" fxFlex="100" *ngIf="card.id_product_type == 1">Producto</span>
            <span class="category-product" fxFlex="100" *ngIf="card.id_product_type == 2">Widget</span>
          </div>
          <img *ngIf="card.imagen" [src]="sanitizer.bypassSecurityTrustResourceUrl(card.imagen)">
        </div>
      </div>
      <div class="text-modulo" fxFlex="100" fxLayout="row wrap">
        <span fxFlex="100" class="description-modulo">{{card.descripcion}}</span>
        <span style="text-align: right; text-transform: uppercase; font-weight: 600; font-size: 11px; position: absolute; right: 0; bottom: 0;" fxFlex="50" (click)="openVideoDialog(card, videoDialog)" *ngIf="card.video">Ver vídeo <span style="position: relative !important;" class="material-icons">play_circle_outline</span></span>
      </div>
      <button class="button-registry" fxFlex="100" (click)="openCheckout(card)">Añadir módulo</button>
    </div>
    <div class="container-card modulos" *ngIf="card.id === -1" fxLayout="row wrap" fxFlex="100">
      <img *ngIf="card.imagen" [src]="sanitizer.bypassSecurityTrustResourceUrl(card.imagen)">
    </div>
  </div>
</div>

<span *ngIf="dataLoaded && showMisModulos && type !== 2 && (this.data && this.data.length)" [ngClass]="{'library': this.type === 6, 'knowledge': (this.type >= 4 && this.type <= 9) || this.type === 11}" style="max-width: 100%; width: 100%;">
  <p-carousel [value]="data" [numVisible]="numVisible" [numScroll]="numScroll" [circular]="circular" [page]="page" [responsiveOptions]="responsiveOptions">
    <!-- MIS MODULOS -->
    <ng-template *ngIf="type === 1" let-card pTemplate="item">
      <div class="container-card modulos" *ngIf="card.id !== -1" fxLayout="row wrap" fxFlex="100">
        <div class="main-container" fxFlex="100" fxLayout="row wrap">
          <div class="left-part" fxFlex="50" fxLayout="row wrap" fxLayoutAlign=" start">
            <span class="titulo-modulo" fxFlex="100">{{card.nombre}}</span>
            <div class="price" fxFlex="100" fxLayout="row wrap">
              <span fxFlex="100" *ngIf="card.precio_compra > 0">{{card.precio_compra}} MOVS</span>
              <span fxFlex="100" *ngIf="card.precio_compra_euros > 0">{{card.precio_compra_euros}} €</span>
              <span fxFlex="100" *ngIf="card.precio_compra == 0">Gratuito</span>
              <span fxFlex="100" *ngIf="card.estate" style="margin-top: 1em; text-transform: uppercase; color: rgba(var(--primary-color), 0.9);">Renovación {{ card.fecha_desactivacion }}</span>
              <span fxFlex="100" *ngIf="!card.estate" style="margin-top: 1em; text-transform: uppercase; color: rgb(var(--tertiary-color));">Caducidad {{ card.fecha_desactivacion }}</span>
            </div>
          </div>
          <div fxFlex="50" class="right-part">
            <div class="category-product-0">
              <span class="category-product" fxFlex="100" *ngIf="card.id_product_type == 1">Producto</span>
              <span class="category-product" fxFlex="100" *ngIf="card.id_product_type == 2">Widget</span>
            </div>
            <img *ngIf="card.imagen" [src]="sanitizer.bypassSecurityTrustResourceUrl(card.imagen)">
            <iframe *ngIf="!card.imagen && card.video" width="420" height="315" [src]="sanitizer.bypassSecurityTrustResourceUrl(card.video)"></iframe>
          </div>
        </div>

        <!-- Hover -->
        <div class="text-modulo" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center">
          <span fxFlex="100" class="description-modulo">{{card.descripcion}}</span>
          <span style="border-top: 1px solid #383838; padding: 2px 0;" fxFlex="100" fxLayout="row wrap">
            <button style="text-align: left; text-transform: uppercase; font-weight: 600; font-size: 11px; padding: 0.5em 0; margin-top: -2px;" fxFlex="50" *ngIf="card.estate" (click)="updateProductSubscription(card,false)">Dar de baja</button>
            <span style="text-align: right; text-transform: uppercase; font-weight: 600; font-size: 11px; position: absolute; right: 0; bottom: 0;" fxFlex="50" (click)="openVideoDialog(card, videoDialog)" *ngIf="card.video">Ver vídeo <span style="position: relative !important;" class="material-icons">play_circle_outline</span></span>
          </span>
        </div>

        <!--<button class="button-registry" fxFlex="100" *ngIf="card.estate && card.numWidgets" (click)="openWidgets(card)">Ver widgets</button>-->
        <button class="button-registry" fxFlex="100" *ngIf="!card.estate" (click)="updateProductSubscription(card,true)">Renovar</button>
      </div>

      <div class="container-card modulos" *ngIf="card.id === -1" fxLayout="row wrap" fxFlex="100">
        <img *ngIf="card.imagen" [src]="sanitizer.bypassSecurityTrustResourceUrl(card.imagen)">
      </div>
    </ng-template>

    <!-- PROXIMAMENTE -->
    <ng-template *ngIf="type === 12" let-card pTemplate="item">
      <div class="container-card modulos" *ngIf="card.id !== -1" fxLayout="row wrap" fxFlex="100">
        <div class="main-container" fxLayout="row wrap" fxFlex="100">
          <div class="left-part" fxFlex="50" fxLayout="row wrap" fxLayoutAlign=" start" >
            <span class="titulo-modulo" fxFlex="100">{{card.nombre}}</span>
          </div>
          <div class="right-part" fxFlex="50">
            <div class="category-product-0">
              <span class="category-product" fxFlex="100" *ngIf="card.id_product_type == 1">Producto</span>
              <span class="category-product" fxFlex="100" *ngIf="card.id_product_type == 2">Widget</span>
            </div>
            <img *ngIf="card.imagen" [src]="sanitizer.bypassSecurityTrustResourceUrl(card.imagen)">
          </div>
        </div>
        <div class="text-modulo" fxFlex="100" fxLayout="row wrap">
          <span fxFlex="100" class="description-modulo">{{card.descripcion}}</span>
          <span style="text-align: right; text-transform: uppercase; font-weight: 600; font-size: 11px; position: absolute; right: 0; bottom: 0;" fxFlex="50" (click)="openVideoDialog(card, videoDialog)" *ngIf="card.video">Ver vídeo <span style="position: relative !important;" class="material-icons">play_circle_outline</span></span>
        </div>
      </div>
    </ng-template>

    <!-- PACKS -->
    <ng-template *ngIf="type === 3 && showPack" let-card pTemplate="item">
      <div class="container-card modulos" *ngIf="card.id !== -1" fxLayout="row wrap" fxFlex="100">
        <div class="main-container" fxFlex="100">
            <img *ngIf="card.imagen" [src]="sanitizer.bypassSecurityTrustResourceUrl(card.imagen)">
        </div>

        <button class="button-registry" fxFlex="100" (click)="displayPack(card.id)">Ver Pack</button>
      </div>

      <div class="container-card modulos" *ngIf="card.id === -1" fxLayout="row wrap" fxFlex="100">
        <img *ngIf="card.imagen" [src]="sanitizer.bypassSecurityTrustResourceUrl(card.imagen)">
      </div>
    </ng-template>

    <!-- TRANSFORMACION -->
    <ng-template *ngIf="type === 11" let-card pTemplate="item">
      <div *ngIf="card.id !== -1" class="item">
        <img [src]="card.imagen" class="img-carroussel">
        <div class="img-carroussel text-hov text-hov2-buttons">
          <span style="height: calc(100% - 3.5em); overflow: auto;" class="thedescription">{{card.descripcion}}</span>
          <a style="left: 0;" (click)="sidenavService.toggleDrawer(1, card.subtitulo, card.url_item_intern)">Ver contenidos</a>
          <a style="right: 0;" *ngIf="card.id_plantilla_ego != 0 && card.id_plantilla_ego != null" (click)="adviceReportService.knowledge(card.id_plantilla_ego)">Empezar guía</a>
        </div>
      </div>

      <div *ngIf="card.id === -1" class="item">
        <img src="{{card.src}}" class="img-carroussel">
      </div>
    </ng-template>

    <!-- NOTAS DE PRENSA -->
    <ng-template *ngIf="type === 4" let-card pTemplate="item">
      <div *ngIf="card.id !== -1" class="item" (click)="sidenavService.toggleDrawer(1, card.subtitulo, card.url_item_intern)">
        <img [src]="card.imagen" class="img-carroussel">
        <div class="img-carroussel text-hov">
          <span class="thedescription">{{card.descripcion}}</span>
        </div>
      </div>

      <div *ngIf="card.id === -1" class="item">
        <img src="{{card.src}}" class="img-carroussel">
      </div>
    </ng-template>

    <!-- VIDEOTECA -->
    <ng-template *ngIf="type === 5" let-card pTemplate="item">
      <app-knowledge-video class="item" *ngIf="card.id !== -1" [card]="card"></app-knowledge-video>

      <div *ngIf="card.id === -1" class="item">
        <img src="{{card.src}}" class="img-carroussel">
      </div>
    </ng-template>

    <!-- LIBROS -->
    <ng-template *ngIf="type === 6" let-card pTemplate="item">
      <div *ngIf="card.id !== -1" class="item">
        <a href="{{card.url_item_extern}}" target="_blank">
          <img [src]="card.imagen" class="img-carroussel">
        </a>
      </div>
      <div *ngIf="card.id === -1" class="item">
        <img src="{{card.src}}" class="img-carroussel">
      </div>
    </ng-template>

    <!-- INFORMES -->
    <ng-template *ngIf="type === 7" let-card pTemplate="item">
      <div *ngIf="card.id !== -1" class="item">
        <img [src]="card.imagen" class="img-carroussel">
      </div>

      <div *ngIf="card.id === -1" class="item">
        <img src="{{card.src}}" class="img-carroussel">
      </div>
    </ng-template>

    <!-- CURSOS -->
    <ng-template *ngIf="type === 8" let-card pTemplate="item">
      <div *ngIf="card.id !== -1" class="item">
        <img [src]="card.imagen" class="img-carroussel">
      </div>

      <div *ngIf="card.id === -1" class="item">
        <img src="{{card.src}}" class="img-carroussel">
      </div>
    </ng-template>

    <!-- PLAY SPACE -->
    <ng-template *ngIf="type === 9" let-card pTemplate="item">
      <div *ngIf="card.id !== -1" class="item">
        <img [src]="card.imagen" class="img-carroussel">
      </div>

      <div *ngIf="card.id === -1" class="item">
        <img src="{{card.src}}" class="img-carroussel">
      </div>
    </ng-template>

    <!-- LANDING KNOWLEDGE PLACE -->
    <ng-template *ngIf="type === 10" let-card pTemplate="item">
      <div *ngIf="tipos[card.index].id === 1 && card.last[0].id !== -1" class="item" (click)="sidenavService.toggleDrawer(1, card.last[0].subtitulo, card.last[0].url_item_intern)">
        <img class="img-carroussel" src="{{card.last[0].imagen}}">
        <div class="img-carroussel text-hov">
          <span class="thedescription">{{card.descripcion}}</span>
        </div>
      </div>

      <div *ngIf="tipos[card.index].id === 2 && card.last[0].id !== -1" class="item">
        <app-knowledge-video class="item" *ngIf="card.last[0].id !== -1" [card]="card.last[0]"></app-knowledge-video>
        <div *ngIf="card.last[0].id === -1" class="item">
          <img src="{{card.last[0].src}}" class="img-carroussel">
        </div>
      </div>

      <div *ngIf="tipos[card.index].id === 3 && card.last[0].id !== -1" class="item">
        <a href="{{card.last[0].url_item_extern}}" target="_blank">
          <img *ngIf="card.last[0].imagen" src="{{card.last[0].imagen}}" class="img-carroussel">
        </a>
      </div>

      <div *ngIf="tipos[card.index].id === 4 && card.last[0].id !== -1" class="item">
        <img *ngIf="card.last[0].imagen" src="{{card.last[0].imagen}}" class="img-carroussel">
      </div>

      <div *ngIf="tipos[card.index].id === 5 && card.last[0].id !== -1" class="item">
        <img *ngIf="card.last[0].imagen" src="{{card.last[0].imagen}}" class="img-carroussel">
      </div>

      <div *ngIf="tipos[card.index].id === 6 && card.last[0].id !== -1" class="item">
        <img *ngIf="card.last[0].imagen" src="{{card.last[0].imagen}}" class="img-carroussel">
      </div>

      <div *ngIf="card.last[0].id === -1" class="item">
        <img src="{{card.last[0].src}}" class="img-carroussel">
      </div>

      <a (click)="showCarousel(card.index)" class="open-carousel">{{tipos[card.index].name}}</a>

    </ng-template>
  </p-carousel>
</span>

<!-- SI NO HAY MODULOS COMPRADOS -->
<span *ngIf="type === 1 && (!this.data || !this.data.length)">
  <h4 style="margin-left: 5rem; margin-top: 1rem;">Aquí aparecerán los productos que adquieras.</h4>
</span>

<!-- SI NO HAY MODULOS PARA COMPRAR -->
<span *ngIf="type === 2 && (!this.data || !this.data.length)">
  <h4 style="margin-left: 5rem; margin-top: 1rem;">No hay módulos disponibles.</h4>
</span>

<div class="knowledge-carousel-container knowledge" style="position: relative;" *ngIf="(knowledge_index !== -1) && (knowledge_index !== 2) && (data[knowledge_index].all.length > 0)">
  <span (click)="knowledge_index = -1;" class="material-icons" style="position: absolute; color: #fff; right: 0; font-size: 25px; top: 24px;">close</span>
  <p-carousel [value]="data[knowledge_index].all" [numVisible]="5" [numScroll]="1" [circular]="false" [page]="0" [responsiveOptions]="this.responsiveOptions">
    <ng-template let-card pTemplate="item">
      <div *ngIf="tipos[knowledge_index].id === 1 && card.id !== -1" class="item" (click)="sidenavService.toggleDrawer(1, card.subtitulo, card.url_item_intern)">
        <img [src]="card.imagen" class="img-carroussel">
        <div class="img-carroussel no-text-hov">
          <h1 class="thetitles">{{card.name}}</h1>
          <span class="thesubtitles">{{card.subtitulo}}</span>
        </div>
        <div class="img-carroussel text-hov">
          <span class="thedescription">{{card.descripcion}}</span>
        </div>
      </div>

      <app-knowledge-video *ngIf="tipos[knowledge_index].id === 2" [card]="card"></app-knowledge-video>

      <span *ngIf="tipos[knowledge_index].id === 3 && card.id !== -1" class="library">
      <div class="item">
        <a href="{{card.url_item_extern}}" target="_blank">
          <img *ngIf="card.imagen" src="{{card.imagen}}" class="img-carroussel">
        </a>
      </div>

      </span>

      <div *ngIf="tipos[knowledge_index].id === 4 && card.id !== -1" class="item">
        <img *ngIf="card.imagen" [src]="card.imagen" class="img-carroussel">
      </div>

      <div *ngIf="tipos[knowledge_index].id === 5 && card.id !== -1" class="item">
        <img *ngIf="card.imagen" [src]="card.imagen" class="img-carroussel">
      </div>

      <div *ngIf="tipos[knowledge_index].id === 6 && card.id !== -1" class="item">
        <img *ngIf="card.imagen" [src]="card.imagen" class="img-carroussel">
      </div>

      <div *ngIf="card.id === -1">
        <img src="{{card.src}}" class="img-carroussel">
      </div>

    </ng-template>
  </p-carousel>
</div>

<div class="knowledge-carousel-container knowledge" style="position: relative;" *ngIf="(knowledge_index === 2) && (data[knowledge_index].all.length > 0)">
  <span (click)="knowledge_index = -1;" class="material-icons" style="position: absolute; color: #fff; right: 0; font-size: 25px; top: 24px;">close</span>
  <p-carousel [value]="data[knowledge_index].all" [numVisible]="8" [numScroll]="1" [circular]="false" [page]="0" [responsiveOptions]="this.responsiveOptions2">
    <ng-template let-card pTemplate="item">
      <span class="library">
        <div class="item">
          <a href="{{card.url_item_extern}}" target="_blank">
            <img *ngIf="card.imagen" src="{{card.imagen}}" class="img-carroussel">
          </a>
        </div>
        <div *ngIf="card.id === -1" class="item">
            <img src="{{card.src}}" class="img-carroussel">
        </div>
      </span>
    </ng-template>
  </p-carousel>
</div>

<div *ngIf="showPack" id="selected-pack">
  <p-carousel [value]="dataPack" [numVisible]="numVisible" [numScroll]="numScroll" [circular]="circular" [page]="page" [responsiveOptions]="responsiveOptions">
    <ng-template let-card pTemplate="item">
      <div class="container-card" *ngIf="card.id !== -1" fxLayout="row wrap" fxFlex="100">
        <div class="main-container" fxFlex="100">
          <div fxFlex="50">
            {{card.nombre}}
            {{card.subtitulo}}
            {{card.descripcion}}
            {{card.precio}}
          </div>
          <div fxFlex="50">
            <img style="width: 200px; height: 200px;" *ngIf="card.imagen" [src]="sanitizer.bypassSecurityTrustResourceUrl(card.imagen)">
            <iframe *ngIf="!card.imagen && card.video" width="420" height="315" [src]="sanitizer.bypassSecurityTrustResourceUrl(card.video)"></iframe>
          </div>
        </div>
      </div>

      <div class="container-card" *ngIf="card.id === -1" fxLayout="row wrap" fxFlex="100">
        <img style="width: 100%; height: 100%;" *ngIf="card.imagen" [src]="sanitizer.bypassSecurityTrustResourceUrl(card.imagen)">
      </div>
    </ng-template>
  </p-carousel>
</div>

<!-- Video dialog -->
<ng-template #videoDialog>
  <div class="notis0">
    <mat-dialog-content>
      <iframe width="560" height="315" [src]="videoUrl" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </mat-dialog-content>
    <mat-dialog-actions align="end" style="justify-content: space-between;">
      <button mat-button matDialogClose color="primary">Cerrar</button>
    </mat-dialog-actions>
  </div>
</ng-template>
