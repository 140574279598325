<div *ngIf="hideCommunity === false" id="containerbuscar" fxLayout="row wrap" fxFlex="100" style="height: calc(100vh - 5.2em); overflow: scroll;">
  <div class="padre-tabs" style="width: 100%" *ngIf="showColaboration">
    <mat-tab-group mat-stretch-tabs (selectedTabChange)="tabChanged($event)">
      <mat-tab label="Buscar">
        <ng-template mat-tab-label>
          <i fxShow="false" fxShow.sm="true" fxShow.xs="true" class="material-icons rounded" matTooltip="Buscar">person_search</i>
          <span class="options-view" matTooltip="Buscar" fxShow="true" fxShow.sm="false" fxShow.xs="false">Buscar</span>
        </ng-template>
        <ng-template matTabContent>
          <div *ngIf="showPerfilInmo === false">
            <form class="search-colaborators" style="margin: 1vw 1.4vw 0 1.4vw; align-items: center;">
              <mat-form-field ngStyle.sm="padding: 0.5em 0;" ngStyle.xs="padding: 0.5em 0;" appearance="outline" fxFlex="100">
                <input matInput placeholder="Buscar ..." [ngModel]="buscar" [ngModelOptions]="{standalone: true}" (ngModelChange)="changeBuscar($event)">
                <div class="mat-icon-button" matSuffix>
                  <mat-icon fxShow="true" style="font-size: 21px;" matTooltip="Filtrar" [matMenuTriggerFor]="filtrar">filter_alt</mat-icon>
                </div>
              </mat-form-field>
            </form>
            <span class="lista-inmos-0" fxLayout="row wrap" *ngIf="inmosLoaded">
            <span fxFlex="100" fxLayout="row wrap" style="margin: 1vw;" *ngIf="inmos.length > 0">
              <div *ngFor="let inmo of inmosToShow" fxFlex="25" fxFlex.md="33.33" fxFlex.sm="50" fxFlex.xs="100">
                <app-inmo-card
                  (event)="mostrarPerfilInmo($event)"
                  [inmo]="inmo"
                  [tipo]="inmo.tipo"
                  [esMLS]="inmo.esMLS"
                  [type]="type"
                  [permisos]="permisos"
                  [structure]="structure"
                >
                </app-inmo-card>
              </div>
            </span>
            <div *ngIf="inmos.length === 0" class="message-no-results" fxFlex="100">
                <span class="material-icons outlined" style="font-size: 18px; vertical-align: text-bottom; padding-right: 4px;">sentiment_very_dissatisfied</span>Vaya, parece que no hay ningún resultado.
            </div>
            <mat-paginator *ngIf="inmos.length >= 41" [length]="inmos.length" (page)="onPageChange($event)" [pageSize]="40" [pageSizeOptions]="[40]"></mat-paginator>
          </span>
          </div>
          <div *ngIf="showPerfilInmo === true">
            <app-perfil-inmo
              (event)="mostrarBuscar()"
              [inmoIdClick]="inmoURLperfilId"
              [inmoURL]="inmoURLperfil"
              [isInmo]="isInmoPerfil"
              [myProfile]="false"
              [structure]="structure"
            ></app-perfil-inmo>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="Mi perfil">
        <ng-template mat-tab-label>
          <i fxShow="false" fxShow.sm="true" fxShow.xs="true" class="material-icons rounded" matTooltip="Mi perfil">person</i>
          <span class="options-view" matTooltip="Mi perfil" fxShow="true" fxShow.sm="false" fxShow.xs="false">Mi perfil</span>
        </ng-template>
        <ng-template matTabContent>
          <app-perfil-inmo [inmoURL]="(type === 1) ? this.authService.inmoURL : this.authService.groupURL" [isInmo]="(type === 1) ? true : false" [myProfile]="true"></app-perfil-inmo>
        </ng-template>
      </mat-tab>
      <mat-tab label="Ajustes">
        <ng-template mat-tab-label>
          <i fxShow="false" fxShow.sm="true" fxShow.xs="true" class="material-icons rounded" matTooltip="Ajustes">build_circle</i>
          <span class="options-view" matTooltip="Ajustes" fxShow="true" fxShow.sm="false" fxShow.xs="false">Ajustes</span>
        </ng-template>
        <ng-template matTabContent>
          <app-community-administrator [isInmo]="(type === 1) ? true : false"></app-community-administrator>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="padre-tabs" style="width: 100%; z-index: 999;" *ngIf="!showColaboration">
    <form class="search-colaborators" style="margin: 1vw 1.4vw 0 1.4vw;">
      <mat-form-field ngStyle.sm="padding: 0.5em 0;" ngStyle.xs="padding: 0.5em 0;" appearance="outline" fxFlex="100">
        <input matInput placeholder="Buscar colaboradores" [ngModel]="buscar" [ngModelOptions]="{standalone: true}" (ngModelChange)="changeBuscar($event)">
        <div class="mat-icon-button" matSuffix>
          <mat-icon fxShow="true" style="font-size: 21px;" matTooltip="Buscar colaboradores">search</mat-icon>
        </div>
      </mat-form-field>
    </form>
    <span class="lista-inmos-0" fxLayout="row wrap" *ngIf="inmosLoaded">
      <span fxFlex="100" fxLayout="row wrap" style="margin: 1vw;">
        <div *ngFor="let inmo of inmosToShow" fxFlex="25" fxFlex.md="33.33" fxFlex.sm="50" fxFlex.xs="100">
          <app-inmo-card [inmo]="inmo" [tipo]="4" [esMLS]="inmo.esMLS" [type]="type" [permisos]="permisos"></app-inmo-card>
        </div>
      </span>
      <mat-paginator *ngIf="inmos.length >= 41" [length]="inmos.length" (page)="onPageChange($event)" [pageSize]="40" [pageSizeOptions]="[40]"></mat-paginator>
    </span>
  </div>
</div>
<div *ngIf="hideCommunity === false" class="img-back0"><div class="img-back"></div></div>

<mat-menu #filtrar="matMenu">
<button mat-menu-item (click)="changeFilter(0, false)">
  <span class="material-icons" *ngIf="filterSelected === 0">check_box</span>
  <span class="material-icons" *ngIf="filterSelected !== 0">check_box_outline_blank</span>
  <span>Todos</span>
</button>

<button mat-menu-item (click)="changeFilter(1, false)">
  <span class="material-icons" *ngIf="filterSelected === 1">check_box</span>
  <span class="material-icons" *ngIf="filterSelected !== 1">check_box_outline_blank</span>  <span>Buscar colaboradores</span>
</button>

<button mat-menu-item (click)="changeFilter(2, false)">
  <span class="material-icons" *ngIf="filterSelected === 2">check_box</span>
  <span class="material-icons" *ngIf="filterSelected !== 2">check_box_outline_blank</span>
  <span>Mis colaboradores</span>
</button>

<button *ngIf="permisos === true" mat-menu-item (click)="changeFilter(3, false)">
  <span class="material-icons" *ngIf="filterSelected === 3">check_box</span>
  <span class="material-icons" *ngIf="filterSelected !== 3">check_box_outline_blank</span>
  <span>Solicitudes pendientes</span>
</button>
</mat-menu>

<span *ngIf="hideCommunity">
  <div style="height: calc(100vh - 5.2em); margin-bottom: -5.2em; display: flex; text-align: center; align-items: center; color: rgb(var(--secondary-color)); width: 100%; text-transform: uppercase; background: #000;">
  <p style="text-align: center; width: 100%; margin: 0; font-weight: bold; font-size: 18px;">Coming soon . . .</p>
</div>
</span>
