import { Injectable } from '@angular/core';
import { EndpointService } from './endpoint/endpoint.service';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class StaticTablesService {
  public languagesListArray: any = [];


  public agentsArray: any = [];

  constructor(public endpointService: EndpointService, private authService: AuthService) {}

  public initialize(): void {
    this.endpointService.getPropertiesPredefinedSelectorsValues(this.authService.inmoId).subscribe(data => {
      if (data['errorCode'] == 0) {
        this.getPropertyDefaultValuesArrays(data['response']);
      }
    });
  }

  private getPropertyDefaultValuesArrays(response: any) {
    this.languagesListArray = response.languagesList;

    //this.managersArray = response.managers;
    // this.loadpredefinedCaptator(response.captors);
    // this.loadVisibleOwners(response.owners);
  }

}
