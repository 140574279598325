export class UpdateRowAction {

  public id: number;
  public modulo: number;
  public field: number;
  public value: any;
  public query: string;

  constructor() {
    this.clearBody();
  }

  public clearBody() {
    this.id = null;
    this.modulo = 1;
    this.field = 1;
    this.value = '0';
    this.query = null;
  }

  public handleResponse(data: any) {
    this.id = data.core.id;
    this.modulo = data.core.id_modulo;
    this.field = data.core.id_campo;
    this.query = data.core.consulta_sql;
    try {
      this.value = parseInt(data.core.valor, 10);
      if (isNaN(this.value)) {
        this.value = data.core.valor;
      }
    } catch (err) {
      this.value = data.core.valor;
    }
  }
}
