import {EventConditionBody} from './workflow-event-condition-body';
import {EventActionBody} from './workflow-event-action-body';
import {ModulConditionContainer} from './workflow-event-condition-modul-container';

export class WorkflowEventBody {

  public name: string;
  public status: number;
  public frontendStatus: number;
  public creator: number;
  public creationDate: string;
  public modificationDate: string;
  public executionTime: number;
  public executionTarget: number;
  public id: number;
  public modulo: number;
  public executionDate: any;
  public repeat: boolean;
  public repeat_offset: number;
  public repeat_magnitude: number;
  public days_of_repetition: Array<number>;
  public hour: any;
  public final_hour: any;
  public options = { year: 'numeric', month: 'numeric', day: 'numeric' };
  public conditions: Array<ModulConditionContainer>;
  public actions: any;
  public id_inmobiliaria: number;
  public query: string;
  public tipo_evento: number;
  public id_plantilla: number;
  public fecha_final_date: any;
  public fecha_final: string;
  public consulta_estatica: number;
  //public hora_final: any;

  constructor() {
    this.clearBody();
  }

  public clearBody() {
    this.name = null;
    this.status = null;
    this.frontendStatus = null;
    this.creator = null;
    this.creationDate = null;
    this.modificationDate = null;
    this.executionTime = null;
    this.executionTarget = null;
    this.id = null;
    this.modulo = null;
    this.executionDate = null;
    this.actions = [];
    this.repeat = false;
    this.repeat_offset = null;
    this.repeat_magnitude = null;
    this.days_of_repetition = [];
    const date = new Date();
    this.executionDate = {
      year: date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate()
    };
    this.hour = {
      hour: 0,
      minute: 0,
      second: 0
    };
    this.final_hour = {
      hour: 0,
      minute: 0,
      second: 0
    };
    this.id_inmobiliaria = null;
    this.query = null;
    this.tipo_evento = null;
    this.id_plantilla = null;
    this.fecha_final_date = null;
    this.fecha_final = null;
    this.consulta_estatica = null;
    //this.hora_final = null;
  }

  public handleDaysOfRepetitionResponse(list: Array<any>) {
    this.days_of_repetition = [];
    if (list != null && list !== undefined) {
      for (let i = 0; i < list.length; i++) {
        this.days_of_repetition.push(list[i].id_dia);
      }
    }
  }

  public handleResponse(data: any) {
    this.name = data.nombre;
    this.status = data.estado;
    this.creator = data.id_creador;
    this.creationDate = data.fecha_alta;
    this.modificationDate = data.fecha_modificacion;
    this.executionTime = data.cuando_ejecutar;
    this.executionTarget = data.ejecutar_sobre;
    this.id = data.id;
    this.modulo = data.id_modulo;
    if (data.fecha_ejecucion != null) {
      const splitedDateTime = data.fecha_ejecucion.split(' ');
      const splitedDate = splitedDateTime[0].split('-');

      this.executionDate = {
        year: parseInt(splitedDate[0], 10),
        month: (parseInt(splitedDate[1], 10) - 1),
        day: parseInt(splitedDate[2], 10)
      };
      /*if (data.id === 3627 || data.id === 3595) { // For tests
      }*/

      const hourr = splitedDateTime[1].split(':');
      this.hour = {
        hour: parseInt(hourr[0], 10),
        minute: parseInt(hourr[1], 10),
        second: parseInt(hourr[2], 10)
      };

      // let d1 = new Date();
      // d1.setFullYear(this.executionDate.year);
      // d1.setMonth(this.executionDate.month);
      // d1.setDate(this.executionDate.day);
      // this.executionDate = d1;
    } else {
      const date = new Date();
      this.executionDate = {
        year: date.getFullYear(),
        month: date.getMonth(),
        day: date.getDate()
      };
      this.hour = {
        hour: 0,
        minute: 0,
        second: 0
      };
    }

    if (data.fecha_fin != null && data.cuando_ejecutar !== 1) {
      const splitedEndDateTime = data.fecha_fin.split(' ');
      const final_hourr = splitedEndDateTime[1].split(':');
      this.final_hour = {
        hour: parseInt(final_hourr[0], 10),
        minute: parseInt(final_hourr[1], 10),
        second: parseInt(final_hourr[2], 10)
      };

    } else {
    this.final_hour = {
        hour: 23,
        minute: 59,
        second: 59
      };
    }

    this.repeat = data.repetir;
    this.repeat_offset = data.repetir_offset;
    this.repeat_magnitude = data.repetir_magnitud;
    this.id_inmobiliaria = data.id_inmobiliaria;
    this.query = data.consulta_sql;
    this.tipo_evento = data.tipo_evento;
    this.id_plantilla = data.id_plantilla_ego;
    if (data.cuando_ejecutar !== 1) {
      let d1 = new Date(data.fecha_fin);
      d1.setHours(this.final_hour.hour);
      d1.setMinutes(this.final_hour.minute);
      d1.setSeconds(this.final_hour.second);
      this.fecha_final_date = d1;
      this.fecha_final = data.fecha_fin;
    } else {
      let d1 = new Date();
      d1.setHours(23);
      d1.setMinutes(59);
      d1.setSeconds(59);
      this.fecha_final_date = d1;
      this.fecha_final = null;
    }
    this.consulta_estatica = data.consulta_estatica;
    this.frontendStatus = data.activado_frontend;
    //this.fecha_final_date =  ? new Date(data.fecha_fin) : null;
    /*
    const date = new Date(data.fecha_final);
    this.fecha_final_date = {
      year: date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate()
    };
    */
    //this.fecha_final = data.cuando_ejecutar !== 1 ? data.fecha_fin : null;
    // this.hora_final = data.hora_final;
  }

  public handleResponseConditions(array: any) {
    const arrayAux = [];
    for (let i = 0; i < array.length; i++) {
      const elem = array[i];
      const valuesList = [Number(elem.valor)];
      // const valuesList = [];
      let j;
      for (j = i + 1; j < array.length; j++) {
        if (elem.id_tipo === 3 && elem.id_mrm === array[j].id_mrm && elem.id_campo === array[j].id_campo) {
          valuesList.push(Number(array[j].valor));
        }
        else {
          break;
        }
      }
      if (valuesList.length > 1 && elem.id_tipo === 3) {
        elem.valor = valuesList.slice();
        i = j - 1;
      }
      arrayAux.push(elem);
    }

    this.conditions = [];
    const addedElementsId = [];
    for (let i = 0; i < arrayAux.length; i++) {
      const elem = arrayAux[i];
      let flag = false;
      for (let j = 0; j < this.conditions.length && !flag; j++) {
        if (elem.id_mrm === this.conditions[j].conditionModulo && addedElementsId.indexOf(elem.id) === -1) {
          const eventConditionBody = new EventConditionBody();
          if (elem.id_tipo == 3) {
            eventConditionBody.handleResponseMultipleValues(arrayAux[i]);
          }
          else {
            eventConditionBody.handleResponse(arrayAux[i]);
          }
          this.conditions[j].containerConditions.push(eventConditionBody);
          addedElementsId.push(elem.id);
          flag = true;
        }
      }
      if (!flag) {
        this.conditions.push(new ModulConditionContainer(elem.id_mrm));
        const eventConditionBody = new EventConditionBody();
        if (elem.id_tipo == 3) {
          eventConditionBody.handleResponseMultipleValues(arrayAux[i]);
        }
        else {
          eventConditionBody.handleResponse(arrayAux[i]);
        }
        this.conditions[this.conditions.length - 1].containerConditions.push(eventConditionBody);
      }
    }
  }

  public handleResponseActions(array: any) {
    this.actions = [];
    for (let i = 0; i < array.length; i++) {
      const eventActionBody = new EventActionBody();
      eventActionBody.handleResponse(array[i]);
      this.actions.push(eventActionBody);
    }
  }

  public init() {
    this.name = '';
    this.status = 0;
    this.creator = null;
    this.creationDate = (new Date().getDate()) + '-' +
      (new Date().getMonth() + 1) + '-' +
      (new Date().getFullYear());

    this.modificationDate = (new Date().getDate()) + '-' +
      (new Date().getMonth() + 1) + '-' +
      (new Date().getFullYear());
    // this.creationDate = new Date().toJSON().slice(0,10);
    // this.modificationDate = new Date().toJSON().slice(0,10);
    this.executionTarget = 0;
    this.executionTime = 0;
    this.id = null;
    this.conditions = new Array();
    this.actions = new Array();
    this.modulo = null;
    this.repeat = false;
    this.repeat_offset = 0;
    this.repeat_magnitude = null;
    this.id_inmobiliaria = null;
    this.query = null;
    this.tipo_evento = null;
    this.id_plantilla = null;
    this.fecha_final_date = null;
    this.fecha_final = null;
  }

  public insertEgo(id, status, name){
    this.id_plantilla = id;
    this.status = status;
    this.name = name;
  }

  public updateEgo(status, name){
    this.status = status;
    this.name = name;
  }

}
