<div class="generic-buttons-blue-revert" style="position: relative;" *ngIf="!minimized && !firstElement">
    <button style="position: absolute; left: -6px; top: -6px;" mat-raised-button (click)="minimize()"><mat-icon class="material-icons">close_fullscreen</mat-icon></button>
</div>
<div (click)="minimize()" class="generic-buttons-blue-revert" style="display: flex; margin-bottom: 6px;" *ngIf="minimized && !firstElement">
    <button mat-raised-button><mat-icon class="material-icons">open_in_full</mat-icon></button>
    <p style="margin: 3px 6px;">Condición ocultada</p>
</div>
<div class="generic-buttons-blue-revert" *ngIf="filterArrayCondition().length == 0">
    <button mat-raised-button (click)="newGroupConditionUnique(functionsService.arrayConditions, 0)"><mat-icon class="material-icons">add</mat-icon></button>
</div>
<div *ngIf="conditionsArray.length > 0 && !minimized" fxLayout="row" [ngClass]="'condition-' + idFirstCondition">
    <span *ngFor="let condition of filterArrayCondition(); last as isLast" fxLayout="row" fxLayoutAlign="center center">
        <ng-container *ngIf="condition.borrado != 1">
            <div style="padding: 5px;">
                <app-events-conditions-custom-element class="component-class new-condition-group" [childs]="condition" [conditionsArray]="conditionsArray"></app-events-conditions-custom-element>
            </div>
            <div style="padding: 5px;" *ngIf="!isLast">
                <mat-form-field appearance="outline" style="width: 100px;">
                    <mat-select [(ngModel)]="conditionType" (selectionChange)="updateConditionType(conditionType)">
                        <mat-option [value]="option.value" *ngFor="let option of operators">{{ option.viewValue }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </ng-container>
        <div style="padding: 5px;" [ngClass]="'condition-button-' + idFirstCondition" *ngIf="isLast">
            <div class="generic-buttons-blue-revert">
                <button mat-raised-button [matMenuTriggerFor]="menu"><mat-icon class="material-icons">add</mat-icon></button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="newSimpleCondition(conditionsArray, condition.id_parent, condition.id_evento, condition.id_reporte)">Condición</button>
                    <button mat-menu-item (click)="newGroupCondition(conditionsArray, condition.id_parent, condition.id_evento, condition.id_reporte)">Grupo condiciones</button>
                </mat-menu>
            </div>
        </div>
    </span>
</div>