import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-canva',
  templateUrl: './canva.component.html',
  styleUrls: ['./canva.component.css']
})
export class CanvaComponent implements OnInit {

  @Input() structure: any;
  @Input() index: number;

  public value: string;
  public safeValue;
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }
}
