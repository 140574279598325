<div class="example-container mat-elevation-z3 taulesgrans" style="overflow: auto; height: calc(100vh - 5.2em - 2em - 0.6vh - 15px);">
  <table mat-table [dataSource]="dataSourceJourney" class="mat-elevation-z8" matSort>

    <ng-container matColumnDef="operations">
      <th mat-header-cell *matHeaderCellDef class="buttons-head">
        <button style="width: 100%;" (click)="journeyPopup(null)" matTooltip="Nueva journey">
          <i class="material-icons">add</i>
          <i class="material-icons">feedback</i>
        </button>
      </th>
      <td mat-cell *matCellDef="let element; let index = index">
        <mat-checkbox click-stop-propagation style="border: 0; background: inherit;" [(ngModel)]="element.checkbox" [checked]="element.estado" (change)="filterPlantilla.emit()"></mat-checkbox>
        <button click-stop-propagation (click)="journeyPopup(element.id)" class="btn btn-warning erp-status-btn"><i class="material-icons">create</i></button>
        <button click-stop-propagation class="btn-danger" style="border: 0; background: inherit;" matTooltip="Eliminar Email">
          <i class="material-icons">delete_forever</i>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
      <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
      <td mat-cell *matCellDef="let element"> {{eventStatusList[element.activo]}} </td>
    </ng-container>

    <ng-container matColumnDef="creator">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Creador</th>
      <td mat-cell *matCellDef="let element"> {{element.creador}} </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha de creación</th>
      <td mat-cell *matCellDef="let element"> {{element.fecha_creacion}} </td>
    </ng-container>

    <!--<ng-container matColumnDef="campaign">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Campaña vinculada</th>
      <td mat-cell *matCellDef="let element"> {{element.id_campanya}} </td>
    </ng-container>-->


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row (click)="selectedTab.setValue(2); selectCheckBox(row.id)" *matRowDef="let row; let index = index; columns: displayedColumns;"></tr>

  </table>
</div>

<div class="bottom-table">
  <div class="bottom-table2">
    <mat-form-field class="input-filter">
      <input matInput [(ngModel)]="inputFilter" (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
    </mat-form-field>
  </div>
  <mat-paginator style="width: 50%;" [pageSizeOptions]="[50,100,200]"></mat-paginator>
</div>
