import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import { Router } from "@angular/router";
import { EndpointService } from "../../services/endpoint/endpoint.service";
import { AuthService } from "../../services/auth/auth.service";
import { Values } from "../../../values/values";
import { forkJoin } from "rxjs";
import { GenericService } from "../../services/generic/generic.service";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { LocalStorageService } from "../../services/local-storage.service";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import {AutoPlantillaComponent} from "../../auto-plantilla/auto-plantilla.component";
import {ChatService} from "../../services/chat.service";

@Component({
  selector: 'app-perfil-inmo',
  templateUrl: './perfil-inmo.component.html',
  styleUrls: ['./perfil-inmo.component.scss']
})
export class PerfilInmoComponent implements OnInit {

  @Input() isInmo: boolean; // indica si estoy visitando un grupo o una inmo
  @Input() comingFromInmos: boolean; // indica si estoy visitando un grupo o una inmo
  @Input() inmoURL: string; // alias de la inmo o grupo visitado (si myProfile === true, ignorar)
  @Input() inmoIdClick: number; // Id de la inmo
  @Input() myProfile: boolean; // indica si visito mi perfil o no
  @Input() structure;
  @ViewChild('AutoPlantillaComponent') public AutoPlantillaComponent: AutoPlantillaComponent;
  @Output() event = new EventEmitter();

  // Variables para las viviendas de una inmobiliaria
  public searchResultRowIndex = [];
  public totalElements: number;
  public mostrarMensajeHTML: boolean;
  public pageSize = 24;
  public vsOffset = 0;
  public vsSize = 0;
  public showCarousel = [];
  public searchResult = [];

  public puede_colaborar: number;
  public responsable_community: number;
  public permisos: boolean;
  public inmoId: number;
  public groupId: number;

  public infoInmo: any;
  public inmosMLS: any;
  public mlsInmo: any;
  public loadedInfo: boolean;

  public type: number;

  // Opiniones
  public tooltipInfo: string;
  public opinionCriterio1 = [];
  public opinionCriterio2 = [];
  public opiniones: any;
  public responsiveOptions = [
    { breakpoint: '1024px', numVisible: 3, numScroll: 1 },
    { breakpoint: '768px', numVisible: 2, numScroll: 1 },
    { breakpoint: '560px', numVisible: 1, numScroll: 1 }
  ];

  public responsiveOptionsCarousel = [
    { breakpoint: '1500px', numVisible: 13, numScroll: 5 },
    { breakpoint: '1250px', numVisible: 11, numScroll: 6 },
    { breakpoint: '960px', numVisible: 8, numScroll: 4 },
    { breakpoint: '680px', numVisible: 5, numScroll: 5 },
    { breakpoint: '540px', numVisible: 4, numScroll: 4 },
    { breakpoint: '450px', numVisible: 3, numScroll: 3 }
  ];
  public colabRequest = Values.COLABORATION_REQUEST;


  // Contacto
  public mensajeForm: UntypedFormGroup;
  public enviarFormClicked = false;

  // Variables para los botones: etiquetaX (nombre del botón), accionX (id de la acción al pulsar), showEtiquetaX (para mostrar o no el boton)
  public etiqueta1: string;
  public accion1: number;
  public showEtiqueta1 = true;

  public etiqueta2: string;
  public accion2: number;
  public showEtiqueta2 = true;
  public enviarMensajeBloqueado = false;
  public routes = Values.ROUTES;

  //Variables para mostrar/esconder Viviendas/inmos dependiendo de configuracion del grupo
  public showViviendas = true;
  public showInmos = true;

  // Logros
  public logrosBody;
  public isBasic = false;
  public isSilver = false;
  public isGolden = false;

  public showMessageButton: boolean;
  public showFollowButton: boolean;
  public showInmoCollaborateButton: boolean;
  public showGroupCollaborateButton: boolean;

  constructor(
    private router: Router,
    private endpointService: EndpointService,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId,
    public genericService: GenericService,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private changeDetector: ChangeDetectorRef,
    public dialog: MatDialog,
    public chatService: ChatService,
  ) {
    this.loadedInfo = false;
  }

  ngOnInit(): void {
    this.checkStructure();
    this.initialize();
  }

  public initialize(): void {
    let p = new Promise<void>((resolve, reject) => {
      setTimeout(async () => {
        this.inmoId = this.authService.inmoId;
        this.groupId = this.authService.groupId;
        this.puede_colaborar = this.authService.puede_colaborar;
        this.responsable_community = this.authService.responsable_community;
        resolve();
      }, 300);
    });

    p.then(res => {
      /*if (this.myProfile === true) {
        if (this.isInmo === false) {
          // Cuando es 'Mi Perfil', y soy un grupo, no tengo guardado en el authService el url del grupo, por lo que
          // tenemos que hacer una query previa para recuperarlo --> en el futuro en vez de usar las url_canonicas usar los id
          this.endpointService.getMlsNameById(this.authService.groupId).subscribe(data => {
            if (data['errorCode'] === 0) {
              this.inmoURL = data['response'][0]['url_canonica'];
              this.initialize2();
            }
          });
        } else {
          this.initialize2();
        }
      } else {
        this.initialize2();
      }*/
      this.initialize2();
    });
  }

  public initialize2(): void {
    const inmo = this.inmoURL;

    this.permisos = (this.responsable_community === 1) ? true : false;
    this.type = (this.isInmo) ? 1 : 2;
    const observerArray = [];

    if (this.isInmo) {
      // ENTREM PERFIL INMOBILIARIA
      if (this.type === 1) { // Sóc inmo
        observerArray.push(this.endpointService.getInmoByName(this.authService.inmoId,inmo,this.authService.userId));
      } else { // Sóc grup
        observerArray.push(this.endpointService.getInmoByName2(this.authService.groupId,inmo,this.authService.userId));
      }
      observerArray.push(this.endpointService.getViviendasByInmoName(inmo));
      if (this.type === 1) {
        if (this.myProfile) {
          observerArray.push(this.endpointService.getGruposOrderByPuntuation(this.authService.inmoId,this.authService.inmoId));
        } else {
          observerArray.push(this.endpointService.getGruposOrderByPuntuation(this.authService.inmoId,this.inmoIdClick));
        }
      }
      forkJoin(observerArray).subscribe(data => {
        if (data[0]['errorCode'] === 0) {
          this.infoInmo = data[0]['response'][0];
          this.tooltipInfo = (this.infoInmo.media === 0) ? 'No hay opiniones' : this.infoInmo.media.toString();
          this.recuperarOpiniones();
          if (this.infoInmo.otros_criterios[0] === undefined) {
            this.opinionCriterio1.push('Rapidez');
            this.opinionCriterio1.push(0);
            this.opinionCriterio1.push(0);
          } else {
            this.opinionCriterio1.push(this.infoInmo.otros_criterios[0].valor);
            this.opinionCriterio1.push(this.infoInmo.otros_criterios[0].puntuacion);
            this.opinionCriterio1.push(this.infoInmo.otros_criterios[0].num_opiniones);
          }
          if (this.infoInmo.otros_criterios[1] === undefined) {
            this.opinionCriterio2.push('Profesionalidad');
            this.opinionCriterio2.push(0);
            this.opinionCriterio2.push(0);
          } else {
            this.opinionCriterio2.push(this.infoInmo.otros_criterios[1].valor);
            this.opinionCriterio2.push(this.infoInmo.otros_criterios[1].puntuacion);
            this.opinionCriterio2.push(this.infoInmo.otros_criterios[1].num_opiniones);
          }
        }

        if (data[1]['errorCode'] === 0) {
          this.searchResult = data[1]['response'];
          this.totalElements = this.searchResult.length;
          this.processThumbnailList(this.searchResult);
          this.initShowCarousel();
          this.buscar();
          this.setPageWindow(this.vsOffset, this.vsSize);
        }
        if (this.type === 1) {
          if (data[2]['errorCode'] === 0) {
            this.mlsInmo = data[2]['response'];
          }
        }
        this.establecerEtiquetas();
        this.loadedInfo = true;
      });
    } else {
      // ENTREM PERFIL MLS
      if (this.type === 1) { // Sóc inmo
        observerArray.push(this.endpointService.getMLSByName(this.authService.inmoId, inmo, this.authService.userId));
        observerArray.push(this.endpointService.geInmosByMlsName(inmo, this.authService.inmoId));
      } else { // Sóc grup
        observerArray.push(this.endpointService.getMLSByName2(this.authService.groupId, inmo, this.authService.userId));
        observerArray.push(this.endpointService.geInmosByMlsName2(inmo, this.authService.groupId));
      }
      forkJoin(observerArray).subscribe(data => {
        if (data[0]['errorCode'] === 0) {
          this.infoInmo = data[0]['response'][0];
        }

        if (data[1]['errorCode'] === 0) {
          this.inmosMLS = data[1]['response'];
          // Cojemos viviendas de las inmos del grupo.
          this.endpointService.getViviendasOfGroup(this.inmosMLS).subscribe(data2 => {
            this.searchResult = data2['response'];
            this.totalElements = this.searchResult.length;
            this.processThumbnailList(this.searchResult);
            this.initShowCarousel();
            this.buscar();
            this.setPageWindow(this.vsOffset, this.vsSize);
          });
        } else {
          this.inmosMLS = [];
        }
        this.showHideInmosViviendasGroup();
        this.establecerEtiquetas();
        this.loadedInfo = true;
      });
    }

    this.mensajeForm = this.formBuilder.group({
      mensaje: ['', Validators.compose([Validators.required])],
    });

    // Cargar Datos de Logros.
    let promise = new Promise<void>((resolve) => {
      if (this.myProfile) {
        if (this.isInmo) { // Soc una Inmo
          this.endpointService.getLogrosData(this.authService.inmoId, true).subscribe(data=>{
            this.logrosBody = data['response'][0];
            resolve();
          });
        } else {
          this.endpointService.getLogrosData(this.authService.groupId, false).subscribe(data=>{
            this.logrosBody = data['response'][0];
            resolve();
          });
        }
      } else {
        if (this.isInmo) { // Soc una Inmo
          this.endpointService.getLogrosData(this.inmoIdClick, true).subscribe(data=>{
            this.logrosBody = data['response'][0];
            resolve();
          });
        } else {
          this.endpointService.getLogrosData(this.inmoIdClick, false).subscribe(data=>{
            this.logrosBody = data['response'][0];
            resolve();
          });
        }
      }
    });
    promise.then(e => {
      if (this.logrosBody['basic'] == 1) {
        this.isBasic = true;
      } else if (this.logrosBody['silver'] == 1) {
        this.isSilver = true;
      } else if (this.logrosBody['golden'] == 1) {
        this.isGolden = true;
      }
    });
  }

  private establecerEtiquetas(): void {

    // ETIQUETA 1
      if (this.showInmoCollaborateButton) { // JO sóc una inmo
        if (this.isInmo === true) { // la carta es una inmo
          switch (this.infoInmo.colabEstado) {
            case 2: { this.etiqueta1 = 'Colaborar'; this.accion1 = 1; break; }
            case 1: { this.etiqueta1 = 'Colaborando'; this.accion1 = 2; break; }
            case 0: {
              if (this.infoInmo.esSolicitada === 1) { this.etiqueta1 = 'Acceptar'; this.accion1 = 3; break; }
              else if (this.infoInmo.esSolicitada === 0) { this.etiqueta1 = 'Pendiente'; this.accion1 = 4; break; }
            }}
        } else if (this.isInmo === false) { // la carta es una agrupacio
          switch (this.infoInmo.colabEstado) {
            case 2: { this.etiqueta1 = 'Unirme'; this.accion1 = 5; break; }
            case 1: { this.etiqueta1 = 'Miembro'; this.accion1 = 6; break; }
            case 0: {
              if (this.infoInmo.esSolicitada === 1) { this.etiqueta1 = 'Acceptar'; this.accion1 = 7; break; }
              else if (this.infoInmo.esSolicitada === 0) { this.etiqueta1 = 'Pendiente'; this.accion1 = 8; break; }
            }}
        }
      } else if (this.showGroupCollaborateButton) { // JO sóc una agrupació
        if (this.isInmo === true) { // indica si la carta és inmo --> else: és una agrupació
          switch (this.infoInmo.colabEstado) {
            case 2: { this.etiqueta1 = 'Invitar'; this.accion1 = 9; break; }
            case 1: { this.etiqueta1 = 'Miembro'; this.accion1 = 10; break; }
            case 0: {
              if (this.infoInmo.esSolicitada === 0) { this.etiqueta1 = 'Acceptar'; this.accion1 = 11; break; }
              else if (this.infoInmo.esSolicitada === 1) { this.etiqueta1 = 'Pendiente'; this.accion1 = 12; break; }
            }}
        } else if (this.isInmo === false) {
          this.showEtiqueta1 = false;
        }
      }


    if (this.permisos === false) {
      // Si usuario no tiene permitido colaborar (usuarios.puede_colaborar === 0) --> no mostrar botón 1
      this.showEtiqueta1 = false;
    }

    // ETIQUETA 2 -> mensajes
    if (this.infoInmo.mensajes === 1 || (this.infoInmo.mensajes === 2 && this.infoInmo.colabEstado === 1)) {
      /*this.etiqueta2 = 'Mensaje';*/ this.accion2 = 1; this.enviarMensajeBloqueado = false;
    } else if (this.infoInmo.mensajes === 2 && this.infoInmo.colabEstado !== 1) {
      /*this.etiqueta2 = 'No puedes enviar mensajes';*/ this.accion2 = 1; this.enviarMensajeBloqueado = true;
    }

    if (this.infoInmo.colabEstado === 0) {
      if (this.type === 1 && this.infoInmo.esSolicitada === 1) { this.etiqueta2 = 'Rechazar'; this.accion2 = 13; this.enviarMensajeBloqueado = false; }
      else if (this.type === 2 && this.infoInmo.esSolicitada === 0) { this.etiqueta2 = 'Rechazar'; this.accion2 = 13; this.enviarMensajeBloqueado = false; }
    }

    // Soy propietario:
    if (this.myProfile === true) {
      this.showEtiqueta1 = true;
      this.etiqueta1 = 'Editar Perfil';
      this.accion1 = 15;
      this.showEtiqueta2 = false;
    }

  }

  private establecerEtiquetasOld(): void {

    // ETIQUETA 1
    if (this.permisos) { // Comprueva si el usuario tiene permisos para invitar/acceptar/etc
      if (this.type === 1) { // JO sóc una inmo
        if (this.isInmo === true) { // la carta es una inmo
          switch (this.infoInmo.colabEstado) {
            case 2: { this.etiqueta1 = 'Colaborar'; this.accion1 = 1; break; }
            case 1: { this.etiqueta1 = 'Colaborando'; this.accion1 = 2; break; }
            case 0: {
              if (this.infoInmo.esSolicitada === 1) { this.etiqueta1 = 'Acceptar'; this.accion1 = 3; break; }
              else if (this.infoInmo.esSolicitada === 0) { this.etiqueta1 = 'Pendiente'; this.accion1 = 4; break; }
            }}
        } else if (this.isInmo === false) { // la carta es una agrupacio
          switch (this.infoInmo.colabEstado) {
            case 2: { this.etiqueta1 = 'Unirme'; this.accion1 = 5; break; }
            case 1: { this.etiqueta1 = 'Miembro'; this.accion1 = 6; break; }
            case 0: {
              if (this.infoInmo.esSolicitada === 1) { this.etiqueta1 = 'Acceptar'; this.accion1 = 7; break; }
              else if (this.infoInmo.esSolicitada === 0) { this.etiqueta1 = 'Pendiente'; this.accion1 = 8; break; }
            }}
        }
      } else if (this.type === 2) { // JO sóc una agrupació
        if (this.isInmo === true) { // indica si la carta és inmo --> else: és una agrupació
          switch (this.infoInmo.colabEstado) {
            case 2: { this.etiqueta1 = 'Invitar'; this.accion1 = 9; break; }
            case 1: { this.etiqueta1 = 'Miembro'; this.accion1 = 10; break; }
            case 0: {
              if (this.infoInmo.esSolicitada === 0) { this.etiqueta1 = 'Acceptar'; this.accion1 = 11; break; }
              else if (this.infoInmo.esSolicitada === 1) { this.etiqueta1 = 'Pendiente'; this.accion1 = 12; break; }
            }}
        } else if (this.isInmo === false) {
          this.showEtiqueta1 = false;
        }
      }
    } else {
      // Usuario no es responsable de la community --> boton Recomendar
      this.etiqueta1 = 'Recomendar'; this.accion1 = 14;
    }
    if (this.permisos === false) {
      // Si usuario no tiene permitido colaborar (usuarios.puede_colaborar === 0) --> no mostrar botón 1
      this.showEtiqueta1 = false;
    }

    // ETIQUETA 2 -> mensajes
    if (this.infoInmo.mensajes === 1 || (this.infoInmo.mensajes === 2 && this.infoInmo.colabEstado === 1)) {
      /*this.etiqueta2 = 'Mensaje';*/ this.accion2 = 1; this.enviarMensajeBloqueado = false;
    } else if (this.infoInmo.mensajes === 2 && this.infoInmo.colabEstado !== 1) {
      /*this.etiqueta2 = 'No puedes enviar mensajes';*/ this.accion2 = 1; this.enviarMensajeBloqueado = true;
    }

    if (this.infoInmo.colabEstado === 0) {
      if (this.type === 1 && this.infoInmo.esSolicitada === 1) { this.etiqueta2 = 'Rechazar'; this.accion2 = 13; this.enviarMensajeBloqueado = false; }
      else if (this.type === 2 && this.infoInmo.esSolicitada === 0) { this.etiqueta2 = 'Rechazar'; this.accion2 = 13; this.enviarMensajeBloqueado = false; }
    }

    // Soy propietario:
    if (this.myProfile === true) {
      this.showEtiqueta1 = true;
      this.etiqueta1 = 'Editar Perfil';
      this.accion1 = 15;
      this.showEtiqueta2 = false;
    }
  }

  public showHideInmosViviendasGroup() {
    //Cojemos los datos de la configuración del grupo.
    let group;
    if(this.myProfile) {
      this.showInmos = true;
      this.showViviendas = true;
    } else {
      this.endpointService.getCommunityInfoGroup(this.inmoIdClick).subscribe(async data => {
        await data['response'];
        if (data['response'][0]['ver_mis_inmos'] == 1) {
          this.showInmos = true;
        } else if (data['response'][0]['ver_mis_inmos'] == 2) {
          this.showInmos = false;
        } else if (data['response'][0]['ver_mis_inmos'] == 3) {
          // Nomes poden veure el colaboradores. Nomes poden veure usuaris que colaboren directament.
          this.showInmos = false;
          this.endpointService.getMisColaboradores(this.inmoIdClick, this.type, this.authService.userId).subscribe(data => {
            data['response'].forEach(colab => {
              if (this.authService.groupId == null) {
                if (colab['id'] == this.authService.inmoId) {
                  this.showInmos = true;
                }
              } else {
                if (colab['id'] == this.authService.groupId) {
                  this.showInmos = true;
                }
              }
            });
          });
        }
        if (data['response'][0]['ver_mis_viviendas'] == 1) {
          this.showViviendas = true;
        } else if (data['response'][0]['ver_mis_viviendas'] == 2) {
          this.showViviendas = false;
        } else if (data['response'][0]['ver_mis_viviendas'] == 3) {
          this.showViviendas = false;
          this.endpointService.getMisColaboradores(this.inmoIdClick, this.type, this.authService.userId).subscribe(data => {
            data['response'].forEach(colab => {
              if (this.authService.groupId == null) {
                if (colab['id'] == this.authService.inmoId) {
                  this.showViviendas = true;
                }
              } else {
                if (colab['id'] == this.authService.groupId) {
                  this.showViviendas = true;
                }
              }
            });
          });
        }
      });
    }
  }

  public tratarPrecio(n: number): string {
    if (n < 10) {
      return 'A consultar'
    } else {
      return this.numberWithPoints(n) + '€';
    }
  }

  public numberWithPoints(n: number) {
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  public processThumbnailList(data: any) {
    for (let i = 0; i < data.length; i++) {
      data[i].thumbnails = this.genericService.processThumbnails(data[i]);
    }
  }

  public setPageWindow(offset, size) {
    for (let i = 0; i < this.totalElements; i++) {
      if (this.searchResult[i] == null) {
        this.searchResult[i] = {};
        this.showCarousel[i] = { show: false, page: 0, size: 0 };
      }
      if (i < offset) {
        this.searchResult[i].preload = true;
        this.searchResult[i].ssrload = false;
        this.searchResult[i].postload = false;
      }
      if (i >= offset && i < offset + size) {
        this.searchResult[i].preload = false;
        this.searchResult[i].ssrload = true;
        this.searchResult[i].postload = false;
      }
      if (i >= offset + size) {
        this.searchResult[i].preload = false;
        this.searchResult[i].ssrload = false;
        this.searchResult[i].postload = true;
      }
    }
    this.searchResultRowIndex.length = 0;
    const ixStart = offset - this.pageSize * 2 < 0 ? 0 : offset - this.pageSize * 2;
    const ixEnd = offset + size + this.pageSize > this.totalElements ? this.totalElements : offset + size + this.pageSize;
    for (let i = ixStart; i < ixEnd; i += 3) {
      this.searchResultRowIndex.push(i);
    }
  }

  public initShowCarousel() {
    for (let i = 0; i < this.totalElements; i++) {
      this.showCarousel[i] = { show: false, page: 0, size: 0 };
    }
  }

  public buscar(): void {
    if (this.totalElements === 0) {
      this.mostrarMensajeHTML = true;
    } else {
      this.mostrarMensajeHTML = false;
    }
  }

  public goBack(): void {
    //this.router.navigate([Values.ROUTES.COMMUNITY]);
    this.event.emit();
  }

  public goToPortal(vivienda: any): void {
    // A partir de la inmo y de la vivienda, construimos el URL al portal:
    let url = 'https://4dmovin.com/es/inmobiliaria/' + this.inmoURL + '/';

    const elements = [];
    if (typeof vivienda.tipo_operacion !== 'undefined' && typeof vivienda.idOperacion !== 'undefined') {
      if (vivienda.idOperacion === 1) elements.push('comprar');
      else elements.push('alquilar');
    }
    if (typeof vivienda.subtipo_propiedad !== 'undefined') elements.push(vivienda.subtipo_propiedad);
    if (typeof vivienda.poblacion !== 'undefined') elements.push(vivienda.poblacion);
    if (vivienda.id) elements.push(vivienda.id);

    let res = elements.join('-').toLowerCase();
    res = res.replace(/ /gi, '-');
    res = res.replace(/,/gi, '-');
    res = res.replace(/--/gi, '-');

    url += res;

    const win = window.open(url, '_blank');
    win.focus();
  }

  public enviarMensajeFormulario(): void {
    this.enviarFormClicked = true;
    if (this.mensajeForm.valid) {
      this.enviarFormClicked = false;
      const mf = this.mensajeForm.value;

    }
  }

  public recuperarOpiniones(): void {
    this.endpointService.getOpinions(this.infoInmo.id).subscribe(data => {
      if (data['errorCode'] === 0) {
        this.opiniones = data['response'];
      }
    });
  }

  public showAgency(): void {
    //this.router.navigate([this.routes.SETTINGS]);
    this.genericService.openInEditMode = false;
    localStorage.setItem("movin", this.authService.inmoId.toString());

    this.localStorageService.agency();

    //localStorage.setItem("seeMovin", "agency");
    this.router.navigate([Values.ROUTES.INMO]);
  }

  public photoClicked(i: number): void {
    this.showCarousel[i].show = true;

    setTimeout(() => {
      if (this.searchResult[i].thumbnails.length > 1) {
        this.showCarousel[i].page = 1;
        this.changeDetector.detectChanges();
      }
    }, 100);
  }

  public ejecutarAccion(accion: number): void {
    // Nomes s'ha d'obir dialog quan es cancela colaboració (ja sigui que la colaboracio ja existeix o be quan esta pendiente (la has enviat tu))
    // id accion --> 2, 4, 6, 8, 10, 12
    if (accion === 2 || accion === 4 || accion === 6 || accion === 8 || accion === 10 || accion === 12) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { tipo: accion }
      });

      dialogRef.afterClosed().subscribe(confirmar => {
        if (confirmar === true) {
          switch (accion) {
            case 2: { this.updateColaboracion(this.infoInmo.colaborationId, 2); break; } // Inmo --> cancelo colaboracion con inmo
            case 4: { this.updateColaboracion(this.infoInmo.colaborationId, 2); break; } // Inmo --> cancelo solicitud que estaba pendiente
            case 6: { this.updateGrupo(this.infoInmo.colaborationId, 2); break; } // Inmo --> cancelo (dejo) un grupo
            case 8: { this.updateGrupo(this.infoInmo.colaborationId, 2); break; } // Inmo --> cancelo solicitud que estaba pendiente
            case 10: { this.updateGrupo(this.infoInmo.colaborationId, 2); break; } // Grupo --> expulso a una inmo
            case 12: { this.updateGrupo(this.infoInmo.colaborationId, 2); break; } // Grupo --> cancelo solicitud (de invitación a una inmo) que estaba pendiente

          }
        }
      });
    } else {
      switch (accion) {
        case 1: { this.solicitarColaboracion(this.infoInmo.id); break; } // Inmo --> solicito colaborar a inmo
        // case 1: { this.colabRequest = {, , , , esPrivada};
        // this.autoPlantilla.egoButton(2, {inmoSolicitante: this.authService.inmoId, inmoSolicitada: idInmoSolicitada, idUsuario: this.authService.userId, estado, esPrivada: 3}); break; } // Inmo --> solicito colaborar a inmo
        case 3: { this.updateColaboracion(this.infoInmo.colaborationId, 1); break; } // Inmo --> acepto solicitud de colaboración
        case 5: { this.invitarInmo(this.infoInmo.id, this.authService.inmoId, 0); break; } // Inmo --> solicito unirme grupo
        case 7: { this.updateGrupo(this.infoInmo.colaborationId, 1); break; } // Inmo --> acepto la invitación de un grupo
        case 9: { this.invitarInmo(this.authService.groupId, this.infoInmo.id, 1); break; } // Grupo --> invito a una inmo
        case 11: { this.updateGrupo(this.infoInmo.colaborationId, 1); break; } // Grupo --> acepto una inmo al grupo
        case 13: { this.rechazarSolicitud(); break; } // Inmo, Grupo --> rechaza solicitud que estaba pendiente
        case 14: { break; } // Recomendar
        case 15: { break; } // Eres el propietario --> Editar perfil
      }
    }
  }

  public rechazarSolicitud(): void {
    if (this.type === 1) { // Soy una inmo
      if (this.infoInmo.esMLS === 0) { // rechazo colaboracion de una inmo
        this.updateColaboracion(this.infoInmo.colaborationId, 2);
      } else { // rechazo colaboracion de un grupo
        this.updateGrupo(this.infoInmo.colaborationId, 2);
      }
    } else { // Soy un grupo
      this.updateGrupo(this.infoInmo.colaborationId, 2);
    }
  }

  // EGO
  public solicitarColaboracion(idInmoSolicitada: number): void {
    const estado = (this.authService.inmoTipoPerfil === 1 && this.infoInmo.tipo_perfil === 1) ? 1 : 0;
    const esPrivada = (this.authService.inmoTipoPerfil === 1 && this.infoInmo.tipo_perfil === 1) ? 0 : 1;
    const body = { inmoSolicitante: this.authService.inmoId, inmoSolicitada: idInmoSolicitada,
                    idUsuario: this.authService.userId, estado, esPrivada}
    this.AutoPlantillaComponent.egoButton(2, body).then(res => {
      if (res['errorCode'] === 0) {
        this.infoInmo.colaborationId = res['response'];
        if (estado === 1) {
          this.infoInmo.colabEstado = 1;
        } else {
          this.infoInmo.esSolicitada = 0;
          this.infoInmo.colabEstado = 0;
        }
        this.establecerEtiquetas();
      }
    });
  }

  /*
  // No EGO
  public solicitarColaboracion(idInmoSolicitada: number): void {
    const estado = (this.authService.inmoTipoPerfil === 1 && this.infoInmo.tipo_perfil === 1) ? 1 : 0;
    const esPrivada = (this.authService.inmoTipoPerfil === 1 && this.infoInmo.tipo_perfil === 1) ? 0 : 1;

    this.endpointService.solicitarColaboracion(this.authService.inmoId, idInmoSolicitada, this.authService.userId, estado, esPrivada).subscribe(data => {
      if (data['errorCode'] === 0) {
        this.infoInmo.colaborationId = data['response'];
        if (estado === 1) {
          this.infoInmo.colabEstado = 1;
        } else {
          this.infoInmo.esSolicitada = 0;
          this.infoInmo.colabEstado = 0;
        }
        this.establecerEtiquetas();
      }
    });
  }
  */

  public updateColaboracion(colaborationId: number, estado: number): void {
    this.endpointService.actualizarColaboracion(colaborationId, estado).subscribe(data => {
      if (data['errorCode'] === 0) {
        if (estado === 2) this.infoInmo.colabEstado = 2;
        else if (estado === 1) this.infoInmo.colabEstado = 1;
        this.establecerEtiquetas();
      }
    });
  }

  public invitarInmo(idGrupo: number, idInmo: number, invitada: number): void {
    let estado = 0;
    if (this.type === 1) { // Somos inmo
      estado = (this.authService.inmoTipoPerfil === 1 && this.infoInmo.tipo_perfil === 1) ? 1 : 0;
    } else { // Somos grupo
      estado = (this.authService.groupTipoPerfil === 1 && this.infoInmo.tipo_perfil === 1) ? 1 : 0;
    }

    this.endpointService.solicitarGrupo(idGrupo, idInmo, invitada, this.authService.userId, estado).subscribe(data => {
      if (data['errorCode'] === 0) {
        this.infoInmo.colaborationId = data['response'];
        if (estado === 1) {
          this.infoInmo.colabEstado = 1;
        } else {
          this.infoInmo.colabEstado = 0;
          this.infoInmo.esSolicitada = invitada;
        }
        this.establecerEtiquetas();
      }
    });
  }

  public updateGrupo(colaborationId: number, estado: number): void {
    this.endpointService.actualizarGrupo(colaborationId, estado).subscribe(data => {
      if (data['errorCode'] === 0) {
        switch (estado) {
          case 1: {
            this.infoInmo.colabEstado = 1;
            break;
          }
          case 2: {
            this.infoInmo.colabEstado = 2;
            break;
          }
        }
        this.establecerEtiquetas();
      }
    });
  }

  public enviarMensaje(accion: number): void {
    this.chatService.createInmoGroup(this.infoInmo.id, this.authService.user_token).then(res => {
      let checkExist = setInterval(async () => {
        if (this.chatService.PrivateChatRoomsMap.has(res['roomId'])) {
          this.chatService.selectedChat = {id: res['roomId'], chatType: res['roomType']};
          await this.router.navigate([this.routes.CHAT]).then(() => {
          });
          await clearInterval(checkExist);
        }
      }, 200);
    });
  }
  public follow(): void {
    const tipo = (this.isInmo === true) ? 1 : 2; // tipo = 1 --> inmos, tipo = 2 --> grupos
    const body = { idUsuario: this.authService.userId, tipo: tipo, id: this.infoInmo.id };
    this.AutoPlantillaComponent.egoButton(tipo === 1 ? 9 : 25, body).then(res => {
      if (res['errorCode'] === 0) {
        this.infoInmo.followId = res['response'];
      }
    });

    /*this.endpointService.insertNewFollow(this.authService.userId, tipo, this.infoInmo.id).subscribe(data => {
      if (data['errorCode'] === 0) {
        this.infoInmo.followId = data['response'];
      }
    });*/
  }

  public unfollow(): void {
    this.endpointService.deleteFollow(this.infoInmo.followId).subscribe(data => {
      if (data['errorCode'] === 0) {
        this.infoInmo.followId = null;
      }
    });
  }

  checkStructure() {
    this.showMessageButton = this.structure != undefined && this.structure['130'] != undefined && this.structure['130']['id_functional_type'] == 5 ? true : false;
    this.showFollowButton = this.structure != undefined && this.structure['131'] != undefined && this.structure['131']['id_functional_type'] == 5 ? true : false;
    this.showInmoCollaborateButton = this.structure != undefined && this.structure['132'] != undefined && this.structure['132']['id_functional_type'] == 5 ? true : false;
    this.showGroupCollaborateButton = this.structure != undefined && this.structure['133'] != undefined && this.structure['133']['id_functional_type'] == 5 ? true : false;
  }
}
