<div *ngIf="!opened" (click)="toggleIcon();" 
class="icons-header" fxLayout="row" fxLayoutAlign="end center">
    <p class="tittled yellow margining" matTooltip="Utilidades"
         [matBadge]="5" 
        [matBadgeHidden]="false">
        <i class="material-icons outlined">expand_more</i>
    </p>
</div>

<div *ngIf="opened" (click)="toggleIcon()" class="icons-header" fxLayout="row" fxLayoutAlign="end center">
    <p class="tittled yellow margining" matTooltip="Utilidades">
        <i class="material-icons outlined">expand_less</i>
    </p>
</div>

