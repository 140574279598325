import {EmailAction} from './workflow-event-email-action-body';
import { UpdateRowAction } from 'app/workflow/objects/workflow-event-update-row-action-body';
import {ExecuteFunctionAction} from "./workflow-event-execute-function-action-body";

export class EventActionBody{

    public id: number;
    public actionType: number;
    public detailedAction: any;

    constructor() {
        this.clearBody();
    }

    public clearBody() {
        this.id = null;
        this.actionType = null;
        this.detailedAction = null;
    }

    public handleResponse(data: any) {
        this.id = data.id;
        this.actionType = data.id_tipo_accion;
        console.log('ACTIONS');
        console.log(data);
        switch (this.actionType) {
            case 1:
              this.detailedAction = new EmailAction();
              this.detailedAction.handleResponse(data.accion_detallada);
              break;
            case 2:
              break;
            case 3:
              break;
            case 4:
              this.detailedAction = new UpdateRowAction();
              this.detailedAction.handleResponse(data.accion_detallada);
              break;
            case 5:
              break;
            case 6:
              console.log(data);
              this.detailedAction = new ExecuteFunctionAction();
              this.detailedAction.handleResponse(data.accion_detallada);
              break;
        }
    }

}
