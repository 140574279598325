import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterUsers'
})
export class FilterUsersPipe implements PipeTransform {

  transform(items: any[], array2: any[], opType: number): any[] {
    if(!items) return [];
    if(!array2) return items;
    switch (opType) {
      case 1:
        return items.filter(item =>
          array2.some(f => (f.value === true)) === true
        );
    }
  }

}
