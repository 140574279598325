<div *ngIf="showNewVersionBar() && genericService.finishedLoadStructure && this.authService.checkUserIsDeveloping == 0"
  class="bar-new-version generic-buttons-yellow-revert">
  <p class="bar-new-version-tittle">¡Hay una nueva versión disponible!</p>
  <p class="bar-new-version-text">Recarga la página para actualizar la aplicación.</p>
  <button mat-raised-button (click)="this.genericService.refreshStructure(2)" class="bar-new-version-button"><span
      class="material-icons">update</span>Recargar</button>
    </div>
    
    
    <ng-container *ngIf="!genericService.staticHTML">
      <ng-container *ngIf="!authService.loadingInit || !genericService.finishedLoadStructureHeader">
        <mat-progress-bar style="top: 0 !important;" class="loading-window" mode="indeterminate"></mat-progress-bar>
      </ng-container>
      
      <ng-container *ngIf="!authService.finishToLogued">
        <!-- i'm in loggin process -->
      </ng-container>
      
      <ng-container *ngIf="authService.loadingInit && authService.finishToLogued">
        <ng-container *ngIf="authService.isUserSessionActive()">
          <ng-container *ngIf="genericService.finishedLoadStructureHeader">
            <app-generic #generic [structure]="authService.headerStructure"
            *ngIf="authService.headerStructure != undefined && authService.headerStructure != null"></app-generic>
            <div class="bar-tools" fxFlex="100" fxLayout="row wrap"
            *ngIf="this.routingService.loadedBreadcrumbs && !routingService.shouldOpenExternalWindow">
          <div fxFlex="100" fxLayout="row" class="tabs-generic">
            <div fxLayout="row" class="tabs-generic-group">
              <ng-container
                *ngFor="let p of genericService.paramControlVariables | keyvalue : genericService.returnZero">
                <span [ngStyle]="{'order': genericService.paramControlVariables[p.key].orden}" class="tab-generic"
                  [ngClass]="p.key != routingService.moduleId ? 'tab-generic' : 'tab-generic tab-generic-active'"
                  *ngIf="genericService.paramControlVariables[p.key]['active'] == 1"
                  matTooltip="{{genericService.paramControlVariables[p.key].route.replaceAll('-', ' ')}}">
                  <span class="material-icons sub-tab-generic" [matMenuTriggerFor]="sub_tab_generic"
                    *ngIf="genericService.paramControlVariables[p.key]['params'].length > 1 && !this.genericService.tabChanging">expand_more</span>
                  <mat-menu #sub_tab_generic="matMenu" [hasBackdrop]="false">
                    <span
                      [ngClass]="clearAllActivated && clearAllActivated[p.key] ? 'clearAllActivated' : 'clearAllActivated-no'">
                      <span class="clearAllTabs" (click)="clearAllTabs(p.key); $event.stopPropagation()">
                        <span class="material-icons clearAllTabsIcon1">cancel</span>
                        <span class="material-icons clearAllTabsIcon2">check_circle</span>
                        <span class="clearAllTabsText clearAllTabsText1">Clear all</span>
                        <span class="clearAllTabsText clearAllTabsText2">Confirm</span>
                      </span>
                    </span>
                    <ng-container
                      *ngFor="let par of genericService.paramControlVariables[p.key]['params'] | keyvalue : genericService.returnZero">
                      <button
                        [ngClass]="genericService.paramControlVariables[p.key]['indexParam'] == par.key ? 'highlight' : ''"
                        mat-menu-item *ngIf="par.key && par.key != undefined && par.key != 'undefined'"
                        (click)="swipeTabIndex(par.key, p.key, genericService.paramControlVariables[p.key].route_internal)">
                        <span *ngIf="genericService.paramControlVariables[p.key]['indexParam'] != par.key"
                          class="material-icons" style="margin: 0 !important; font-size: 16px;"
                          (click)="this.deleteIndexTabGeneric(p.key, par.key); $event.stopPropagation()">cancel</span>
                        <span style="padding-left: 5px;">
                          <span
                            *ngIf="!genericService.paramControlVariables[p.key]['params'][par.key]['labelWindowParam']">#{{+par.key
                            + 1}}</span>
                          <span
                            *ngIf="genericService.paramControlVariables[p.key]['params'][par.key]['labelWindowParam']"
                            matTooltip="{{genericService.paramControlVariables[p.key]['params'][par.key]['labelWindowParam']}}">{{genericService.paramControlVariables[p.key]['params'][par.key]['labelWindowParam']}}</span>
                        </span>
                      </button>
                    </ng-container>
                  </mat-menu>
                  <span class="text-tab-generic"
                    (click)="openTabGeneric(p.key, genericService.paramControlVariables[p.key].route_internal)">
                    <span *ngIf="genericService.paramControlVariables[p.key].icon"
                      class="material-icons icon-tab-generic">{{ genericService.paramControlVariables[p.key].icon
                      }}</span>
                    {{genericService.paramControlVariables[p.key].route.replaceAll('-', ' ')}}
                  </span>
                  <span class="material-icons close-tab-generic"
                    (click)="this.closeTabGeneric(p.key, p.key == routingService.moduleId)">close</span>
                </span>
              </ng-container>
            </div>
            <span class="material-icons add-tab-generic" (click)="newTabGeneric()">add</span>
          </div>
          <div class="bar-tools-0" fxFlex="100" *ngIf="!this.genericService.tabChanging">
            <div class="first-bar-tools" fxFlex="70" fxFlex.sm="50" fxFlex.xs="30" fxLayoutAlign="start center">
              <span class="material-icons" (click)="this.functionsService.goPrev(true)"
                [ngClass]="!this.genericService.hasPrev() ? 'arrow-opacity' : ''"
                matTooltip="Ir a la pantalla anterior">arrow_back</span>
              <!--<span class="material-icons" [matMenuTriggerFor]="historial" *ngIf="this.getHistory().length > 1 || genericService.getCurrentIndex() > 0" matTooltip="Historial de navegación">history</span>
              <span class="material-icons arrow-opacity" *ngIf="this.getHistory().length <= 1 && genericService.getCurrentIndex() <= 0" matTooltip="Historial de navegación">history</span>-->
              <span class="material-icons" (click)="this.functionsService.goNext()"
                [ngClass]="!this.genericService.hasNext() ? 'arrow-opacity' : ''"
                matTooltip="Ir a la siguiente pantalla">arrow_forward</span>
              <span *ngIf="this.genericService.refreshStructureFinished" class="material-icons"
                matTooltip="Recargar la pantalla" (click)="this.genericService.refresh()">refresh</span>
              <ng-container *ngIf="this.genericService.moduleId != 43">
                <p fxShow="true" fxShow.sm="false" fxShow.xs="false" class="breadcrumbs">
                  <span class="material-icons" (click)="go('inicio')"> home </span>
                  <ng-container *ngFor="let b of genericService.breadcrumbs">
                    <span class="material-icons">navigate_next </span>
                    <span class="link" (click)="navigateBreadcrumbs(b)"> {{b['name'].replaceAll('-', ' ')}} </span>
                  </ng-container>
                </p>
              </ng-container>
            </div>
            <div class="second-bar-tools icons-header-custom" style="padding-right: 0.3em;" fxFlex="30" fxFlex.sm="50"
              fxFlex.xs="70" fxLayoutAlign="end center" *ngIf="!genericService.readMode">
              <span class="dateLastCharge"
                *ngIf="genericService.paramControlVariables[routingService.moduleId] && genericService.paramControlVariables[routingService.moduleId]['indexParam'] && genericService.paramControlVariables[routingService.moduleId]['params'] && genericService.paramControlVariables[routingService.moduleId]['params'][genericService.paramControlVariables[routingService.moduleId]['indexParam']] && genericService.paramControlVariables[routingService.moduleId]['params'][genericService.paramControlVariables[routingService.moduleId]['indexParam']]['lastLoad'] && this.isOldLoaded(genericService.paramControlVariables[routingService.moduleId]['params'][genericService.paramControlVariables[routingService.moduleId]['indexParam']]['lastLoadValue'])"
                matTooltip="Última vez en recargar la pantalla. Los datos pueden ser antiguos. Recarga para actualizarlos.">{{genericService.paramControlVariables[routingService.moduleId]['params'][genericService.paramControlVariables[routingService.moduleId]['indexParam']]['lastLoad']}}</span>
              <span class="previewModeGenericSlider"
                matTooltip="Modo previsualizar: no aparecerán los campos vacíos"><span
                  *ngIf="!this.genericService.previewModeUser" class="material-icons previewModeGenericSliderIcon2"
                  (click)="previewModeUserChange()">visibility_off</span><mat-slide-toggle
                  [(ngModel)]="this.genericService.previewModeUser"
                  (change)="this.previewModeGeneric()"></mat-slide-toggle><span
                  *ngIf="this.genericService.previewModeUser" (click)="previewModeUserChange()"
                  class="material-icons previewModeGenericSliderIcon">visibility</span></span>
              <mat-checkbox *ngIf="this.authService.checkUserIsDeveloping == 1"
                [(ngModel)]="this.genericService.devModeGeneric"
                (change)="this.genericService.devModeGenericFunction()">Dev</mat-checkbox>
              <span *ngIf="this.authService.checkUserIsDeveloping == 1" style="margin-left: 5px;" class="material-icons"
                (click)="this.genericService.consoleLogFA(); $event.stopPropagation()">terminal</span>
              <span [matBadge]="this.genericService.getNumChanges()"
                [matBadgeHidden]="this.genericService.getNumChanges()<1" class="badge-form-change">
                <span class="material-icons" *ngIf="this.genericService.getNumChanges() > 0"
                  [matMenuTriggerFor]="form_changes"
                  matTooltip="Cambios realizados en la pantalla">published_with_changes</span>
                <span class="material-icons arrow-opacity" *ngIf="this.genericService.getNumChanges() < 1"
                  matTooltip="Cambios realizados en la pantalla">published_with_changes</span>
              </span>
            </div>
          </div>
        </div>
        <div fxFlex="100" fxLayout="row wrap"
          [ngClass]="{'isOpenExternalWindow': routingService.shouldOpenExternalWindow, 'container-logged-0': true, 'session-active' : true}">
          <notificacion-superior *ngIf="genericService.finishedLoadStructure" class="notificacion-superior-class"
            id="notificacion-superior-class" #NotificacionSuperiorComponent
            style="width: 100%; position: fixed; z-index: 999;"></notificacion-superior>
          <span class="container-logged" [style.margin-top.px]="this.getHeight()">
            <router-outlet></router-outlet>
            <!--<div style="height: 9em;" fxHide="true" fxHide.sm="false" fxHide.xs="false"></div>-->
            <span
              *ngIf="routingService.shouldOpenHelp || routingService.shouldOpenChat || routingService.shouldOpenExternalWindow || routingService.shouldOpenSearch"
              fxLayout="row wrap" fxFlex="100"
              [ngClass]="{'generic-tab-full-width-2': routingService.shouldOpenHelp, 'generic-tab-group-guias': routingService.shouldOpenHelp, 'side-bar-header': true}">
              <div fxFlex="100" class="yellow go-back-close-sidenav" (click)="closeSidenav()"><span
                  class="material-icons" matTooltip="Cerrar y volver a la pantalla anterior">arrow_back</span> Cerrar y
                volver</div>
              <div fxLayout="row wrap" fxFlex="100" class="side-bar-header-sub">
                <div *ngIf="routingService.shouldOpenHelp" ngClass.sm="mat-sidenav-bis-faqs"
                  ngClass.xs="mat-sidenav-bis-faqs" fxFlex="100">
                  <mat-tab-group>
                    <mat-tab>
                      <ng-template mat-tab-label>
                        <mat-icon class="example-tab-icon">live_help</mat-icon>
                        <span fxHide.sm="true" fxHide.xs="true" style="padding-right: 0px;">PREGUNTAS FRECUENTES DE ESTA
                        </span><span style="padding-left: 3px;">PANTALLA</span>
                      </ng-template>
                      <span fxFlex="100" fxLayout="row wrap" class="tabla-puntuaciones">
                        <mat-accordion fxLayout="row wrap" fxFlex="100">
                          <mat-expansion-panel *ngFor="let c of this.adviceReportService.preguntasYrespuestas;"
                            fxFlex="100">
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                <span [innerHTML]="c.pregunta | safeHtml"></span>
                              </mat-panel-title>
                            </mat-expansion-panel-header>
                            <span [innerHTML]="c.respuesta | safeHtml"></span>
                          </mat-expansion-panel>
                          <span *ngIf="this.adviceReportService.preguntasYrespuestas.length <= 0" fxFlex="100"
                            class="no-asks">Parece que todaví­a no hay "Preguntas frecuentes" para esta pantalla</span>
                        </mat-accordion>
                      </span>
                    </mat-tab>
                    <mat-tab>
                      <ng-template mat-tab-label>
                        <mat-icon class="example-tab-icon">help_center</mat-icon>
                        <span fxHide.sm="true" fxHide.xs="true" style="padding-right: 0px;">PREGUNTAS FRECUENTES
                        </span><span style="padding-left: 3px;">GENERALES</span>
                      </ng-template>
                      <span fxFlex="100" fxLayout="row wrap" class="tabla-puntuaciones">
                        <mat-accordion fxLayout="row wrap" fxFlex="100">
                          <mat-expansion-panel *ngFor="let c of this.adviceReportService.preguntasYrespuestasGeneral;"
                            fxFlex="100">
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                <span [innerHTML]="c.pregunta | safeHtml"></span>
                              </mat-panel-title>
                            </mat-expansion-panel-header>
                            <span [innerHTML]="c.respuesta | safeHtml"></span>
                          </mat-expansion-panel>
                          <span *ngIf="this.adviceReportService.preguntasYrespuestasGeneral.length <= 0" fxFlex="100"
                            class="no-asks">Parece que todaví­a no hay "Preguntas frecuentes generales"</span>
                        </mat-accordion>
                      </span>
                    </mat-tab>
                    <!--<mat-tab>
                      <ng-template mat-tab-label>
                        <mat-icon class="example-tab-icon">support_agent</mat-icon>
                        SOLICITAR CITA
                      </ng-template>
                      <div fxFlex="100" fxLayout="row wrap" class="tabla-puntuaciones" style="padding: 1vw 2vw; border-left: 1px solid #222223;">
                        <iframe src="https://calendly.com/d/cfrc-vw8x/4dmovin?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=882334" style="width: 100%; height: 100%;"></iframe>
                      </div>
                    </mat-tab>-->
                  </mat-tab-group>
                </div>
                <div *ngIf="routingService.shouldOpenChat" fxFlex="100" style="min-width: 100vw; width: 100vw;">
                  <app-chat [name]="name"></app-chat>
                </div>
                <div *ngIf="routingService.shouldOpenExternalWindow" fxFlex="100"
                  style="min-width: 100vw; width: 100vw; background: #fff; height: 100%;" id="externalURL">
                  <app-generic
                    *ngIf="authService.externalWindowStructure != undefined && authService.externalWindowStructure != null; else progressBarExternal"
                    [structure]="authService.externalWindowStructure"></app-generic>
                  <ng-template #progressBarExternal>
                    <mat-progress-bar class="loading-window"
                      style="top: 5.3em !important; z-index: 99999999999999999 !important; margin-top: 3px;"
                      mode="indeterminate"></mat-progress-bar>
                  </ng-template>
                </div>
                <div *ngIf="routingService.shouldOpenSearch" fxFlex="100"
                  style="min-width: 100vw; width: 100vw; background: #fff; height: 100%;"
                  [ngClass]="{'hasresults': searchResults.length > 0, 'searching': isSearcing, 'search-container': true}">
                  <mat-form-field appearance="outline">
                    <input #filterInput placeholder="Buscar..." matInput type="text" [(ngModel)]="value"
                      (keyup.enter)="searchValue($event.target.value); $event.stopPropagation()">
                    <span class="generic-buttons-blue-revert button-filter-table" matPrefix
                      *ngIf="value != '' && value != lastValue">
                      <button mat-raised-button aria-label="Search"
                        (click)="searchValue(value); $event.stopPropagation()">
                        Filtrar
                      </button>
                    </span>
                    <mat-icon *ngIf="value" matSuffix mat-icon-button aria-label="Clear"
                      (click)="cleanFilter(); $event.stopPropagation()" matTooltip="Limpiar">close</mat-icon>
                    <mat-icon *ngIf="value || searchResults.length > 0" matSuffix mat-icon-button aria-label="Clear"
                      (click)="deleteFilter(); $event.stopPropagation()" matTooltip="Resetear">refresh</mat-icon>
                  </mat-form-field>
                  <p *ngIf="searchResults.length === 0" class="search-num-results">Puedes hacer varias búsquedas
                    diferentes separando por <strong class="blue" style="font-size: 15px;">;</strong></p>
                  <p *ngIf="searchResults.length === 0" class="search-num-results">Hay un máximo de 300 resultados por
                    búsqueda.</p>
                  <div class="search-results">
                    <ng-container *ngFor="let results of searchResults">
                      <div *ngIf="results['result'].length > 0" class="blue search-num-results">
                        Se han encontrado <strong>{{ results['result'].length }} resultado<span
                            *ngIf="results['result'].length > 1">s</span></strong> de la búsqueda "<strong>{{
                          results['search'] }}</strong>"
                      </div>
                      <div *ngIf="results['result'].length == 0 && lastValue != '' && !isSearcing"
                        class="red search-num-results">
                        <strong>No se han encontrado resultados para "{{ results['search'] }}"</strong>
                      </div>
                      <div *ngFor="let result of results['result']" class="search-result search-result-result"
                        (click)="navigateSearch(result)">
                        <h6 style="margin: 0;"><span [innerHTML]="result.title | safeHtml"></span></h6>
                        <span style="font-size: 13px; line-height: 1;"><span
                            [innerHTML]="result.text | safeHtml"></span></span>
                        <div class="bottom-search-result"><i [innerHTML]="result.module | safeHtml"></i></div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="searchResults.length === 0">
                      <div class="search-result search-result-no1"></div>
                      <div class="search-result search-result-no2"></div>
                      <div class="search-result search-result-no3"></div>
                      <div class="search-result search-result-no4"></div>
                      <div class="search-result search-result-no5"></div>
                      <div class="search-result search-result-no6"></div>
                      <div class="search-result search-result-no7"></div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </span>
          </span>
        </div>
        <app-generic #footer (openSidebar)="onOpenSidebar($event)" [structure]="authService.footerStructure"
          *ngIf="authService.footerStructure != undefined && authService.footerStructure != null"></app-generic>
      </ng-container>

      <mat-menu #historial="matMenu">
        <span
          *ngFor="let e of this.genericService.history.slice(this.genericService.getCurrentIndex() - 10 >= 0 ? this.genericService.getCurrentIndex() - 10 : 0).reverse(); let i = index">
          <button
            *ngIf="this.authService.labelLanguage && e['url'].split(this.authService.labelLanguage + '/')[1] !== undefined"
            mat-menu-item (click)="navigateHistory(e, i)" style="text-transform: capitalize"
            [ngClass]="i == genericService.history.length - 1 - genericService.getCurrentIndex() ? 'highlight' : ''">{{
            e['url'].split(this.authService.labelLanguage + "/")[1].replaceAll('/', '').replaceAll('-', '
            ').split('#')[0] }}</button>
        </span>
      </mat-menu>

      <mat-menu #form_changes="matMenu">
        <span mat-menu-item class="form-change-window" fxLayout="row wrap">
          <p class="form-change-window-label">{{ getInternalName(this.genericService.currentInitialArea['key']) }} <span
              *ngIf="this.genericService.refreshStructureFinished" class="material-icons"
              style="color: rgb(var(--secondary-color)) !important" matTooltip="Deshacer todos los cambios"
              (click)="this.genericService.refresh()">restart_alt</span></p>
          <span *ngFor="let elem of functionsService.getFormsCurrentPage() | keyvalue" class="form-change" fxFlex="100">

            <!--  CABECERA  -->
            <!--- ETIQUETA QUAN ES UN DUPLICATE I AVISA QUE S'HA CREAT EL DUPLICATE -> ES DE LA FORMA "NEW (X)"-->
            <p *ngIf="elem.value.labelNewDuplicated" class="form-change-label" fxLayoutAlign="space-between start">
              <span style="text-transform: uppercase;"> NEW (
                <span class="material-icons">segment</span> {{ elem.value.id_duplicated }})
              </span>
              <span class="material-icons" style="margin-left: 10px !important;"
                (click)="revertDuplicateFromValue(this.genericService.currentInitialArea['key'], elem)"
                matTooltip="Deshacer cambio">restart_alt</span>
            </p>

            <!--- ETIQUETA QUAN ES UN UNA MODIFICACIÓ D'UN ELEMENT QUE NO S'ACABA DE DUPLICAR I NO TE MÉS D'UNA OPCIÓ -->
            <p *ngIf="!elem.value.labelNewDuplicated && !elem.value.isDuplicated && !elem.value.hasNgForOptions"
              class="form-change-label" fxLayoutAlign="space-between start">
              <span style="text-transform: uppercase;"> {{ getLabel(elem.value.name) }}
                <span class="form-change-type">({{ getType(elem.value.name) }})</span></span>
              <span class="material-icons" style="margin-left: 10px !important;"
                (click)="revertFormValue(this.genericService.currentInitialArea['key'],elem)"
                matTooltip="Deshacer cambio">restart_alt</span>
            </p>

            <!--- ETIQUETA QUAN ES UN UNA MODIFICACIÓ D'UN ELEMENT QUE NO S'ACABA DE DUPLICAR I TE MÉS D'UNA OPCIÓ -->
            <p *ngIf="!elem.value.labelNewDuplicated && (elem.value.isDuplicated || elem.value.hasNgForOptions)"
              class="form-change-label" fxLayoutAlign="space-between start">
              <span style="text-transform: uppercase;"
                matTooltip="{{ this.genericService.formsChanged[this.genericService.currentInitialArea['key']][this.genericService.paramControlVariables[this.genericService.currentInitialArea['key']]['indexParam']][elem.value.name].tooltip }}">
                {{ getLabel(elem.value.name) }} (<span class="material-icons">segment</span>
                {{elem.value.id_duplicated}})
                <span class="form-change-type">({{ getType(elem.value.name) }})</span></span>
              <span class="material-icons" style="margin-left: 10px !important;"
                (click)="revertFormValue(this.genericService.currentInitialArea['key'],elem)"
                matTooltip="Deshacer cambio">restart_alt</span>
            </p>

            <!-- CONTINGUT DEL CANVI -->
            <p class="form-changedd" fxLayoutAlign="start center" fxLayout="row wrap">
              <span class="form-change-old">
                <ng-container
                  *ngIf="elem.value.type !== 'checkbox_' && elem.value.type !== 'slide_' && elem.value.type !== 'password_' && elem.value.type !== '5-stars_' && elem.value.type !== '10-stars_' && elem.value.type !== '3-faces_' && elem.value.type !== '5-faces_'">
                  {{
                  this.genericService.formsChanged[this.genericService.currentInitialArea['key']][this.genericService.paramControlVariables[this.genericService.currentInitialArea['key']]['indexParam']][elem.value.name].old
                  }}
                </ng-container>
                <ng-container *ngIf="elem.value.type === 'checkbox_'">
                  <i style="color: green !important;"
                    *ngIf="elem.value.oldValue == true || elem.value.internal_value.old == true" class="material-icons">
                    check_box
                  </i>
                  <i style="color: red !important;"
                    *ngIf="elem.value.oldValue == false || elem.value.internal_value.old == false"
                    class="material-icons">
                    check_box_outline_blank
                  </i>
                </ng-container>
                <ng-container *ngIf="elem.value.type === 'slide_'">
                  <i style="color: green !important;"
                    *ngIf="elem.value.oldValue == true || elem.value.internal_value.old == true" class="material-icons">
                    toggle_on
                  </i>
                  <i style="color: red !important;"
                    *ngIf="elem.value.oldValue == false || elem.value.internal_value.old == false"
                    class="material-icons">
                    toggle_off
                  </i>
                </ng-container>
                <ng-container *ngIf="elem.value.type == 'password_'">
                  <i class="material-icons" style="color: #FFFFFF !important;">
                    password
                  </i>
                </ng-container>
                <ng-container *ngIf="elem.value.type == '5-stars_' || elem.value.type == '10-stars_'">
                  {{
                  this.genericService.formsChanged[this.genericService.currentInitialArea['key']][this.genericService.paramControlVariables[this.genericService.currentInitialArea['key']]['indexParam']][elem.value.name].old
                  }}
                  <i class="material-icons" style="color: #FFFFFF !important;">
                    star
                  </i>
                </ng-container>
                <ng-container *ngIf="elem.value.type == '3-faces_'">
                  {{
                  this.genericService.formsChanged[this.genericService.currentInitialArea['key']][this.genericService.paramControlVariables[this.genericService.currentInitialArea['key']]['indexParam']][elem.value.name].old
                  }}
                  <ng-container *ngFor="let face of genericService.iconFacesArray3; let i = index">
                    <i [ngStyle]="{'color': face['color']}"
                      *ngIf="i+1 === genericService.formsChanged[genericService.currentInitialArea['key']][elem.value.name].old"
                      class="material-icons">
                      {{ face.icon }}
                    </i>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="elem.value.type == '5-faces_'">
                  {{
                  this.genericService.formsChanged[this.genericService.currentInitialArea['key']][this.genericService.paramControlVariables[this.genericService.currentInitialArea['key']]['indexParam']][elem.value.name].old
                  }}
                  <ng-container *ngFor="let face of genericService.iconFacesArray5; let i = index">
                    <i [ngStyle]="{'color': face['color']}"
                      *ngIf="i+1 === genericService.formsChanged[genericService.currentInitialArea['key']][elem.value.name].old"
                      class="material-icons">
                      {{ face.icon }}
                    </i>
                  </ng-container>
                </ng-container>
              </span>
              <span *ngIf="!elem.value.labelNewDuplicated && !elem.value.is_from_no_params"
                class="material-icons form-changed-icon">trending_flat</span>
              <span class="form-change-new">
                <ng-container
                  *ngIf="elem.value.type !== 'checkbox_' && elem.value.type !== 'slide_' && elem.value.type !== '5-stars_' && elem.value.type !== '10-stars_' && elem.value.type !== '3-faces_' && elem.value.type !== '5-faces_'">
                  {{
                  this.genericService.formsChanged[this.genericService.currentInitialArea['key']][this.genericService.paramControlVariables[this.genericService.currentInitialArea['key']]['indexParam']][elem.value.name].new
                  }}
                </ng-container>
                <ng-container *ngIf="elem.value.type === 'checkbox_'">
                  <i style="color: green !important;"
                    *ngIf="elem.value.newValue == true || elem.value.internal_value.new == true" class="material-icons">
                    check_box
                  </i>
                  <i style="color: red !important;"
                    *ngIf="elem.value.newValue == false || elem.value.internal_value.new == false"
                    class="material-icons">
                    check_box_outline_blank
                  </i>
                </ng-container>
                <ng-container *ngIf="elem.value.type === 'slide_'">
                  <i style="color: green !important;"
                    *ngIf="elem.value.newValue == true || elem.value.internal_value.new == true" class="material-icons">
                    toggle_on
                  </i>
                  <i style="color: red !important;"
                    *ngIf="elem.value.newValue == false || elem.value.internal_value.new == false"
                    class="material-icons">
                    toggle_off
                  </i>
                </ng-container>
                <ng-container *ngIf="elem.value.type == '5-stars_' || elem.value.type == '10-stars_'">
                  {{
                  this.genericService.formsChanged[this.genericService.currentInitialArea['key']][this.genericService.paramControlVariables[this.genericService.currentInitialArea['key']]['indexParam']][elem.value.name].new
                  }}
                  <i class="material-icons" style="color: #FFFFFF !important;">
                    star
                  </i>
                </ng-container>
                <ng-container *ngIf="elem.value.type == '3-faces_'">
                  {{
                  this.genericService.formsChanged[this.genericService.currentInitialArea['key']][this.genericService.paramControlVariables[this.genericService.currentInitialArea['key']]['indexParam']][elem.value.name].new
                  }}
                  <ng-container *ngFor="let face of genericService.iconFacesArray3; let i = index">
                    <i [ngStyle]="{'color': face['color']}"
                      *ngIf="i+1 === genericService.formsChanged[genericService.currentInitialArea['key']][elem.value.name].new"
                      class="material-icons">
                      {{ face.icon }}
                    </i>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="elem.value.type == '5-faces_'">
                  {{
                  this.genericService.formsChanged[this.genericService.currentInitialArea['key']][this.genericService.paramControlVariables[this.genericService.currentInitialArea['key']]['indexParam']][elem.value.name].new
                  }}
                  <ng-container *ngFor="let face of genericService.iconFacesArray5; let i = index">
                    <i [ngStyle]="{'color': face['color']}"
                      *ngIf="i+1 === genericService.formsChanged[genericService.currentInitialArea['key']][elem.value.name].new"
                      class="material-icons">
                      {{ face.icon }}
                    </i>
                  </ng-container>
                </ng-container>
              </span>
            </p>
          </span>
        </span>
      </mat-menu>
    </ng-container>

    <ng-container *ngIf="!authService.isUserSessionActive() && genericService.finishedLoadStructureHeader">
      <span *ngIf="authService.headerStructure != undefined && authService.headerStructure != null"
        [ngClass]="authService.headerStructure['id_functional_status_mkt'] != 1 ? authService.headerStructure['class_custom']+ ' ' + 'mkt-no-available' : authService.headerStructure['class_custom']"
        style="position: fixed;width: 100vw; z-index: 99999;">
        <app-generic #generic [structure]="authService.headerStructure"></app-generic>
      </span>
      <span style="display: block; padding-top: 2.6em; width: 100%;"></span>
      <app-rrss-home-custom *ngIf="genericService.projectID == 0"
        #SociaSocialMediaCustomComponent></app-rrss-home-custom>
      <router-outlet></router-outlet>
      <app-generic #footerNoLogued (openSidebar)="onOpenSidebar($event)" [structure]="authService.footerStructure"
        *ngIf="authService.footerStructure != undefined && authService.footerStructure != null && authService.footerStructure[0] != undefined && authService.footerStructure[0]['id_functional_parent'] != undefined"></app-generic>
    </ng-container>
  </ng-container>

  <auto-plantilla #AutoPlantillaComponent></auto-plantilla>

  <div *ngIf="showInstallAppIos">
    Install this webapp on your iphone tap (icon)
  </div>

  <ng-template #warningDialog>
    <div mat-dialog-title>
      <p>{{ this.genericService.warningTitle }}</p>
    </div>
    <div mat-dialog-content>
      <p>{{ this.genericService.warningMessage }}</p>
    </div>
    <div mat-dialog-actions style="float: right;">
      <div class="generic-buttons-blue">
        <button mat-button [mat-dialog-close]="false">{{ this.genericService.warningButton1 }}</button>
      </div>
      <div class="generic-buttons-blue-revert" style="margin-left: 7px;">
        <button mat-button [mat-dialog-close]="true"> {{ this.genericService.warningButton2 }} </button>
      </div>
    </div>
  </ng-template>

</ng-container>

<ng-container *ngIf="genericService.staticHTML">
  <div *ngIf="genericService.urlFullInfo.can_download == 1 || genericService.urlFullInfo.can_print == 1"
    class="buttons-static-html">

    <div *ngIf="functionsService.downloadingStaticPage" class="generic-buttons-red-revert generic-buttons-big"
      style="margin-right: 7px;">
      <button mat-button (click)="printFunction()"><mat-spinner style="margin:0 auto;"
          diameter="20"></mat-spinner></button>
    </div>
    <ng-container *ngIf="!functionsService.downloadingStaticPage">
      <div *ngIf="genericService.urlFullInfo.can_print == 1" class="generic-buttons-red-revert generic-buttons-big"
        style="margin-right: 7px;">
        <button mat-button (click)="printFunction()"> {{ 'general.print' | translate}} </button>
      </div>
      <div *ngIf="genericService.urlFullInfo.can_download == 1" class="generic-buttons-red-revert generic-buttons-big"
        style="margin-left: 7px;">
        <button mat-button (click)="downloadFunction()"> {{ 'general.download' | translate}} </button>
      </div>
    </ng-container>
  </div>
  <div style="height: calc(2.6em + 15px);"
    *ngIf="!functionsService.downloadingStaticPage && genericService.staticHTML && (genericService.urlFullInfo.can_download == 1 || genericService.urlFullInfo.can_print == 1)">
  </div>
  <router-outlet></router-outlet>
</ng-container>

<span class="notificacion-superior-class" *ngIf="!notificacionSuperiorNotLoaded" style="display: none;"></span>


<!-- template.html -->
<span *ngIf="routingService.showFAInfo || this.genericService.showAllInfo" class="infoFADev" cdkDrag>
  <i class="material-icons infoFADevDragIcon" cdkDragHandle>open_with</i>
  <i class="material-icons infoFADevCloseIcon" (click)="closeConsoleLogFA()">close</i>
  <div style="margin-top: 20px;" class="infoFADev2">
    <mat-accordion>
      <mat-expansion-panel *ngIf="routingService.showFAInfo" [expanded]="true"
        (closed)="genericService.openHelpExpansionName = undefined"
        (opened)="this.genericService.openHelpExpansion('FA')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            FUNCTIONAL AREA
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngIf="genericService.openHelpExpansionName == 'FA'">
          <ng-container
            *ngTemplateOutlet="displayObject; context: { $implicit: routingService.showFAInfo }"></ng-container>
        </ng-container>
      </mat-expansion-panel>
      <mat-expansion-panel (closed)="genericService.openHelpExpansionName = undefined"
      (opened)="this.genericService.openHelpExpansion('historyInserts')">
      <mat-expansion-panel-header>
        <mat-panel-title>
          HISTORIAL INSERTS/UPDATES
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngIf="genericService.openHelpExpansionName == 'historyInserts'">
        <ng-container
          *ngTemplateOutlet="displayObject; context: { $implicit: functionsService.historyInserts }"></ng-container>
      </ng-container>
    </mat-expansion-panel>
      <mat-expansion-panel (closed)="genericService.openHelpExpansionName = undefined"
        (opened)="this.genericService.openHelpExpansion('queries')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            QUERIES INFO DEBBUG
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngIf="genericService.openHelpExpansionName == 'queries'">
          <ng-container
            *ngTemplateOutlet="displayObject; context: { $implicit: genericService.queriesInfoDebbug }"></ng-container>
        </ng-container>
      </mat-expansion-panel>
      <mat-expansion-panel (closed)="genericService.openHelpExpansionName = undefined"
        (opened)="this.genericService.openHelpExpansion('timmings')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            TIMMINGS INFO DEBBUG
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngIf="genericService.openHelpExpansionName == 'timmings'">
          <ng-container
            *ngTemplateOutlet="displayObject; context: { $implicit: genericService.timmingsInfoDebbug }"></ng-container>
        </ng-container>
      </mat-expansion-panel>
      <mat-expansion-panel (closed)="genericService.openHelpExpansionName = undefined"
        (opened)="this.genericService.openHelpExpansion('selectOptions')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            RESULTS
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngIf="genericService.openHelpExpansionName == 'selectOptions'">
          <ng-container
            *ngTemplateOutlet="displayObject; context: { $implicit: genericService.selectOptions }"></ng-container>
        </ng-container>
      </mat-expansion-panel>
      <mat-expansion-panel (closed)="genericService.openHelpExpansionName = undefined"
        (opened)="this.genericService.openHelpExpansion('tableData')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            TABLES RESULTS
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngIf="genericService.openHelpExpansionName == 'tableData'">
          <ng-container
            *ngTemplateOutlet="displayObject; context: { $implicit: genericService.tableData }"></ng-container>
        </ng-container>
      </mat-expansion-panel>
      <mat-expansion-panel (closed)="genericService.openHelpExpansionName = undefined"
        (opened)="this.genericService.openHelpExpansion('params')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            PARAMS
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngIf="genericService.openHelpExpansionName == 'params'">
          <button (click)="loadInfoFADevParams()" class="infoFADevToggleRefresh"><i class="material-icons">refresh</i>
            Refresh</button>
          <ng-container *ngIf="this.infoFADevParams">
            <ng-container
              *ngTemplateOutlet="displayObject; context: { $implicit: this.infoFADevParams }"></ng-container>
          </ng-container>
        </ng-container>
      </mat-expansion-panel>
      <mat-expansion-panel (closed)="genericService.openHelpExpansionName = undefined"
        (opened)="this.genericService.openHelpExpansion('auth')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            AUTHSERVICE
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngIf="genericService.openHelpExpansionName == 'auth'">
          <ng-container *ngTemplateOutlet="displayObject; context: { $implicit: this.authService }"></ng-container>
        </ng-container>
      </mat-expansion-panel>
      <mat-expansion-panel (closed)="genericService.openHelpExpansionName = undefined"
        (opened)="this.genericService.openHelpExpansion('generic')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            GENERICSERVICE
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngIf="genericService.openHelpExpansionName == 'generic'">
          <ng-container *ngTemplateOutlet="displayObject; context: { $implicit: this.genericService }"></ng-container>
        </ng-container>
      </mat-expansion-panel>
      <mat-expansion-panel (closed)="genericService.openHelpExpansionName = undefined"
        (opened)="this.genericService.openHelpExpansion('creator')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            GENERIC CREATOR SERVICE
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngIf="genericService.openHelpExpansionName == 'creator'">
          <ng-container
            *ngTemplateOutlet="displayObject; context: { $implicit: this.genericCreatorService }"></ng-container>
        </ng-container>
      </mat-expansion-panel>
      <mat-expansion-panel (closed)="genericService.openHelpExpansionName = undefined"
        (opened)="this.genericService.openHelpExpansion('functions')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            FUNCTIONSSERVICE
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngIf="genericService.openHelpExpansionName == 'functions'">
          <ng-container *ngTemplateOutlet="displayObject; context: { $implicit: this.functionsService }"></ng-container>
        </ng-container>
      </mat-expansion-panel>
      <mat-expansion-panel (closed)="genericService.openHelpExpansionName = undefined"
        (opened)="this.genericService.openHelpExpansion('window')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            WINDOW VALUES
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngIf="genericService.openHelpExpansionName == 'window'">
          <button (click)="loadInfoFADevWindowValues()" class="infoFADevToggleRefresh"><i
              class="material-icons">refresh</i> Refresh</button>
          <ng-container *ngIf="this.infoFADevWindowValues">
            <ng-container
              *ngTemplateOutlet="displayObject; context: { $implicit: this.infoFADevWindowValues }"></ng-container>
          </ng-container>
        </ng-container>
      </mat-expansion-panel>
      <mat-expansion-panel (closed)="genericService.openHelpExpansionName = undefined"
        (opened)="this.genericService.openHelpExpansion('eas')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            ELEMENTS ARRAY STRUCTURE
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngIf="genericService.openHelpExpansionName == 'eas'">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  PARENT
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container
                *ngTemplateOutlet="displayObject; context: { $implicit: this.genericService.parentStructureArray }"></ng-container>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  DSB
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container
                *ngTemplateOutlet="displayObject; context: { $implicit: this.genericService.dsbStructureArray }"></ng-container>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  EXTERNAL
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container
                *ngTemplateOutlet="displayObject; context: { $implicit: this.genericService.externalWindowStructureArray }"></ng-container>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  HEADER
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container
                *ngTemplateOutlet="displayObject; context: { $implicit: this.genericService.headerStructureArray }"></ng-container>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  FOOTER
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container
                *ngTemplateOutlet="displayObject; context: { $implicit: this.genericService.footerStructureArray }"></ng-container>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-container>
      </mat-expansion-panel>
      <mat-expansion-panel (closed)="genericService.openHelpExpansionName = undefined"
        (opened)="this.genericService.openHelpExpansion('easf')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            ELEMENTS ARRAY STRUCTURE FA
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngIf="genericService.openHelpExpansionName == 'easf'">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  PARENT
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container
                *ngTemplateOutlet="displayObject; context: { $implicit: this.genericService.parentStructureArrayFA }"></ng-container>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  DSB
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container
                *ngTemplateOutlet="displayObject; context: { $implicit: this.genericService.dsbStructureArrayFA }"></ng-container>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  EXTERNAL
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container
                *ngTemplateOutlet="displayObject; context: { $implicit: this.genericService.externalWindowStructureArrayFA }"></ng-container>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  HEADER
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container
                *ngTemplateOutlet="displayObject; context: { $implicit: this.genericService.headerStructureArrayFA }"></ng-container>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  FOOTER
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container
                *ngTemplateOutlet="displayObject; context: { $implicit: this.genericService.footerStructureArrayFA }"></ng-container>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-container>
      </mat-expansion-panel>
      <mat-expansion-panel (closed)="genericService.openHelpExpansionName = undefined"
        (opened)="this.genericService.openHelpExpansion('easq')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            ELEMENTS ARRAY STRUCTURE QUERIES
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngIf="genericService.openHelpExpansionName == 'easq'">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  PARENT
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container
                *ngTemplateOutlet="displayObject; context: { $implicit: this.genericService.parentStructureArrayQueries }"></ng-container>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  DSB
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container
                *ngTemplateOutlet="displayObject; context: { $implicit: this.genericService.dsbStructureArrayQueries }"></ng-container>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  EXTERNAL
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container
                *ngTemplateOutlet="displayObject; context: { $implicit: this.genericService.externalWindowStructureArrayQueries }"></ng-container>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  HEADER
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container
                *ngTemplateOutlet="displayObject; context: { $implicit: this.genericService.headerStructureArrayQueries }"></ng-container>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  FOOTER
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container
                *ngTemplateOutlet="displayObject; context: { $implicit: this.genericService.footerStructureArrayQueries }"></ng-container>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-container>
      </mat-expansion-panel>
      <mat-expansion-panel (closed)="genericService.openHelpExpansionName = undefined"
        (opened)="this.genericService.openHelpExpansion('easfs')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            ELEMENTS ARRAY STRUCTURE FIELDS
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngIf="genericService.openHelpExpansionName == 'easfs'">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  PARENT
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container
                *ngTemplateOutlet="displayObject; context: { $implicit: this.genericService.parentStructureArrayFields }"></ng-container>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  DSB
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container
                *ngTemplateOutlet="displayObject; context: { $implicit: this.genericService.dsbStructureArrayFields }"></ng-container>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  EXTERNAL
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container
                *ngTemplateOutlet="displayObject; context: { $implicit: this.genericService.externalWindowStructureArrayFields }"></ng-container>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  HEADER
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container
                *ngTemplateOutlet="displayObject; context: { $implicit: this.genericService.headerStructureArrayFields }"></ng-container>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  FOOTER
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container
                *ngTemplateOutlet="displayObject; context: { $implicit: this.genericService.footerStructureArrayFields }"></ng-container>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</span>

<ng-template #displayObject let-data>
  <div class="generic-buttons-red-revert" style="margin: 7px 0;" *ngIf="data && data['id_functional_area']">
    <button mat-button (click)="editFunctionalArea(data)"> EDIT FUNCTIONAL AREA </button>
  </div>
  <div *ngFor="let key of getObjectKeys(data)" class="openHelpExpansionContent">
    <ng-container *ngIf="data[key] !== undefined && data[key] !== null && data[key] != 'null' && key != 'expandedInfoCustom'">
      <ng-container *ngIf="isObject(data[key])">
        <b>
          {{ key }}
          <ng-container *ngIf="data[key]['idQuery']"> ({{ data[key]['idQuery'] }})</ng-container>
          :
        </b>
        <button *ngIf="data[key].expandedInfoCustom" (click)="toggle(data[key])" class="infoFADevToggle"><i
            class="material-icons">expand_less</i></button>
        <button *ngIf="!data[key].expandedInfoCustom" (click)="toggle(data[key])" class="infoFADevToggle"><i
            class="material-icons">expand_more</i></button>
        <div class="infoFADevArray" *ngIf="data[key] && data[key].expandedInfoCustom">
          <ng-container *ngTemplateOutlet="displayObject; context: { $implicit: data[key] }"></ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="!isObject(data[key]) && !isArray(data[key])">
        <b>{{ key }}:</b> {{ data[key] }}
      </ng-container>
      <ng-container *ngIf="isArray(data[key])">
        <b>{{ key }}:</b>
        <ng-container *ngIf="data[key].length > 0">
          <button *ngIf="data[key].expandedInfoCustom" (click)="toggle(data[key])" class="infoFADevToggle"><i
              class="material-icons">expand_less</i></button>
          <button *ngIf="!data[key].expandedInfoCustom" (click)="toggle(data[key])" class="infoFADevToggle"><i
              class="material-icons">expand_more</i></button>
          <div style="margin-left: 20px;" *ngIf="data[key].expandedInfoCustom">
            <ng-container *ngFor="let childItem of data[key]; let index = index">
              <div *ngIf="isArray(childItem) || isObject(childItem)">
                <b>
                  {{ index }}
                  <ng-container *ngIf="childItem && childItem['id_functional_area']"> ({{ childItem['id_functional_area'] }})</ng-container>:
                </b>
                <button *ngIf="childItem.expandedInfoCustom" (click)="toggle(childItem)" class="infoFADevToggle"><i
                    class="material-icons">expand_less</i></button>
                <button *ngIf="!childItem.expandedInfoCustom" (click)="toggle(childItem)" class="infoFADevToggle"><i
                    class="material-icons">expand_more</i></button>
                <div *ngIf="childItem.expandedInfoCustom" class="infoFADevArray">
                  <ng-container *ngTemplateOutlet="displayObject; context: { $implicit: childItem }"></ng-container>
                </div>
              </div>
              <div *ngIf="!isObject(childItem) && !isArray(childItem)">
                <b>{{ index }}: {{ childItem }}</b>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="!(data[key].length > 0)">
          -- NO RESULTS --
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-template>