<!--<h2 mat-dialog-title>Aviso legal y Política de privacidad </h2>-->

<h1 mat-dialog-title>Aviso legal, política de privacidad, y términos y condiciones de uso</h1>

<mat-dialog-content class="mat-typography">
  <h2><b>Te damos la bienvenida a 4DMOVIN</b></h2>

  <p>
    Las Condiciones de Uso rigen el uso de la plataforma 4DMOVIN e incluyen información sobre el Servicio de 4DMOVIN, que se detalla a continuación. Cuando te registras y creas una cuenta de 4DMOVIN o usas la plataforma tecnológica,
    <br>aceptas estas condiciones.
  </p>

  <p>
    El Servicio de 4DMOVIN es uno de los productos de Namastech, y cuando en el presente documento, por claridad, hablamos de 4DMOVIN como empresa nos estamos refiriendo a Namastech. Por lo tanto, estas Condiciones de uso
    <br>constituyen un acuerdo entre la empresa inmobiliaria y Namastech SL. Los datos de identificación de Namastech SL son los siguientes:
  </p>

  <ul>
    <li>Dirección fiscal: Calle Doctor Trueta, 26-28, 1º1ª, 08860, Castelldefels (Barcelona).
    <li>Número de Identificación Fiscal: B-64280068.
    <li>Teléfono de Contacto: +34 93 665 75 79.
    <li>Inscrita en el Registro Mercantil de Barcelona, Tomo 38869, Folio 0132, Página 338900
  </ul>

  <h2><b>Servicio de 4DMOVIN</b></h2>

  <p>

    Aceptamos proporcionarte el Servicio de 4DMOVIN, que incluye todos los productos, las funciones, las aplicaciones, los servicios, las tecnologías y el software que ofrecemos para cumplir la misión de 4DMOVIN:
    <br>profesionalizar, modernizar y mejorar la reputación del sector inmobiliario. El Servicio se compone de los siguientes aspectos (el "Servicio"):

  </p>

  <h3><b>Ofrece la posibilidad al usuario de descubrir inmuebles acorde a sus preferencias</b></h3>

  <p>
    El portal es la herramienta que permite al cliente ver una extensa variedad de inmuebles de manera exclusiva, es decir, exenta de inmuebles repetidos (optimizando los resultados de la búsqueda) y sólo podrán ofertarse en
    <br>el portal aquellos que sean considerados profesionales del sector, es decir, las inmobiliarias y los agentes inmobiliarios.
  </p>

  <h3><b>El portal 4DMOVIN supone un cambio de paradigma en las transacciones inmobiliarias</b></h3>

  <p>
    El proyecto 4DMOVIN trata de premiar la profesionalidad y la modernización tecnológica del sector inmobiliario, estos criterios son indispensables para comprender cómo funciona el posicionamiento interno dentro de
    <br>nuestro portal el cual está brevemente detallado en el apartado de Preguntas Frecuentes (FAQs) y, posteriormente, explicado con más dilación en el apartado “Reglamento” de dicho portal.
  </p>

  <h3><b>El perfil profesional</b></h3>
  <p>
    En el portal, dispondreis de un perfil profesional, con la imagen de vuestra marca, vuestros productos y reseñas de otros usuarios, elementos que os permitirán conocer mejor la opinión de vuestros clientes y potenciar
    <br>vuestra imagen corporativa.
  </p>

  <h3><b>Agendar visitas directamente</b></h3>
  <p>
    Desde el mismo portal se podrán programar mediante la herramienta Agenda visitas por streaming o presenciales de vuestros inmuebles.
  </p>

  <h3><b>Primer contacto con el cliente online y escoger segundas opciones rápidamente al terminar la visita</b></h3>
  <p>
    Se le proporciona al comercial una herramienta de gestión directa respecto las funcionalidades del portal beneficiando la comunicación fluída con el cliente y, después de las visitas, podréis ofrecer alternativas al
    <br>instante aumentando así las oportunidades de venta.
  </p>

  <h3><b>Realizar investigaciones e innovar</b></h3>
  <p>
    Usamos la información que tenemos para estudiar nuestro Servicio e investigar cómo poder mejorarlo, contribuir con el bienestar de nuestra comunidad y cumplir los objetivos ODS (Objetivos de Desarrollo Sostenible).
  </p>

  <h2><b>Política de Datos</b></h2>
  <p>
    Para brindar nuestro Servicio, debemos recopilar y usar tu información. En el apartado “Política de Datos”, se explica cómo recopilamos y usamos la información de los usuarios para mejorar nuestro Servicio.
  </p>

  <p>
    Respetamos la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales, conforme al mandato establecido en el artículo 18.4 de la Constitución Española.
  </p>

  <p>
    De este modo, 4DMOVIN que es el responsable del tratamiento de tus datos te pedirá el consentimiento mediante la redacción de este documento y el posterior consentimiento tácito para que podamos tratar con tus datos.
  </p>

  <p>
    Cabe destacar la total prohibición de cesión de datos de carácter personal o comercial sin la correspondiente autorización del titular de los datos. A tal efecto, 4DMOVIN:
  </p>

  <p>
    a) Accederá a los datos personales del Cliente y demás personas físicas relacionadas con el Cliente para cumplir con los servicios objeto del presente servicio contratado. La cesión de los datos personales podrá ser
    <br>destinada al uso comercial siempre y cuando no se cedan a terceros sin el consentimiento explícito previo de la empresa inmobiliaria.
  </p>

  <p>
    b) Se compromete a:
  </p>

  <ul>
    <li>Utilizar los datos de carácter personal a los que tenga acceso única y exclusivamente para cumplir con tus obligaciones contractuales.
    <li>Observar y adoptar cuantas medidas de seguridad sean necesarias para asegurar la confidencialidad, secreto e integridad de los datos de carácter personal a los que tenga acceso, así como a adoptar en el futuro
        cuantas medidas de seguridad sean exigidas por las leyes y reglamentos destinadas a preservar el secreto, confidencialidad e integridad en el tratamiento automatizado de datos personales.
    <li>A no ceder en ningún caso a terceras personas los datos de carácter personal a los que tenga acceso, excepto cuando la ley estableciera una obligación ulterior o con carácter comercial se podrán divulgar con
        previo consentimiento refrendado mediante firma, autorizando el acto, por parte del Cliente.
  </ul>

  <p>
    Las obligaciones establecidas para la Sociedad por la presente cláusula serán también de obligado cumplimiento para sus empleados, colaboradores, tanto externos como internos.
  </p>

  <p>
    La duración de las obligaciones de confidencialidad establecidas en el presente contrato será indefinida, manteniéndose en vigor con posterioridad a la finalización, por cualquier causa, de la relación entre las partes.
  </p>

  <h3><b>¿Qué datos personales se almacenan? </b></h3>
  <h3 style="text-align: center">Datos del cliente </h3>

  <p>Recogemos los datos de nuestros clientes que son necesarios para el cumplimiento adecuado del Servicio. Si te registras, aceptas recibir el newsletter y cualquier tipo de notificación o email con información de la
    empresa y del proyecto 4DMOVIN. En determinados casos necesitaremos más datos si os suscribís a los distintos Módulos que ofrece 4DMOVIN. También puedes introducir más datos de forma opcional. Cada uno de los datos que se almacenan se obtienen de los respectivos formularios. Si, por algún motivo, necesitáramos otros datos personales, te lo haríamos saber.
  </p>
  <p>
    Analizamos la utilización de nuestro Servicio; se recogen los datos de los clientes con el objetivo de mejorar nuestro Servicio.
  </p>

  <h3><b>¿Quién recopila los datos personales?</b></h3>
  <p>
  Básicamente, los únicos datos personales que obtenemos son los que tú nos das. No obtenemos datos personales de otras fuentes.
  </p>

  <h2><b>Tus Compromisos</b></h2>
  <p>
  Nos comprometemos a ofrecerte el Servicio, pero requerimos que asumas los compromisos que se indican a continuación.
  <p>
  <h3><b>Quién puede usar 4DMOVIN</b></h3>
  <p>
  Queremos que nuestro Servicio sea lo más inclusivo y abierto posible, pero también queremos que sea seguro y cumpla con la ley. Para ello, es necesario que te comprometas a cumplir algunas restricciones a fin de formar parte de la comunidad de 4DMOVIN:
  </p>
  <ul>
    <li>Debes tener capacidad legal plena o, estar acompañado de tu tutor legal, en caso de que sea limitada.
    <li>No debes tener prohibido el uso de ningún aspecto de nuestro Servicio en virtud de las leyes aplicables o la utilización de servicios relacionados con pagos si estás en una lista de partes denegadas aplicable.
    <li>No debemos haber inhabilitado previamente tu cuenta debido a una violación de la ley o a cualquiera de nuestras políticas.
  </ul>

  <h3><b>Cómo no puedes usar 4DMOVIN</b></h3>
  <p>
  Brindar un Servicio seguro y abierto para una comunidad amplia requiere que todos hagamos nuestra parte:
  </p>
  <ul>
    <li>No puedes hacerte pasar por otra persona ni proporcionar información incorrecta. Debes darnos información precisa y actualizada sobre tus datos de identificación fiscal. Asimismo, no puedes hacerte pasar por otra persona ni crear una cuenta en nombre de alguien más.
    <li>No puedes realizar actividades ilegales, engañosas o fraudulentas, ni actividades con fines ilegales o no autorizados.
    <li>No puedes infringir estas Condiciones o nuestras Políticas (ni ayudar o alentar a otros para que lo hagan). Para reportar conductas o información que sean impertinentes pueden realizarlo enviando un correo electrónico a soporte@movin.cloud
    <li>No puedes realizar actividades que interfieran con el Servicio o impidan que se opere como está previsto.
    <li>No puedes publicar información privada o confidencial ni realizar actividades que infrinjan los derechos económicos de otra persona.
  </ul>
  <h3><b>Permisos que nos concedes</b></h3>
  <p>
    Como parte de nuestro acuerdo, también nos concedes los permisos que necesitamos para proporcionarte el Servicio.
  </p>

  <ul>
    <li><b>No reclamamos la propiedad sobre tu contenido, pero nos concedes una licencia para usarlo.</b> Nada cambia respecto de tus derechos sobre tu contenido. No reclamamos la propiedad del contenido que publicas en el Servicio o por medio de él. En lugar de ello, cuando compartes, publicas o subes contenido que se encuentra protegido por derechos de propiedad intelectual (como fotos o videos) en nuestro Servicio, o en relación con éste, por el presente nos otorgas una licencia internacional, sublicenciable, transferible, libre de regalías y no exclusiva para alojar, usar, distribuir, modificar, administrar, copiar, mostrar o exhibir públicamente y traducir tu contenido, así como para crear trabajos derivados de él (de conformidad con tu configuración de privacidad y de la aplicación). Puedes eliminar el contenido o tu cuenta en cualquier momento para interrumpir esta licencia.
    <li>El Servicio de Listado Múltiple (SLM) inmobiliario es una colaboración entre profesionales del Real Estate. La finalidad principal de la SLM es la compartición del producto captado (inmuebles) con fines comerciales. Las inmobiliarias pertenecientes a una SLM tienen un código deontológico que deben cumplir acerca de las reglas que regulan la cooperación comercial.
    <li>Cuando una inmobiliaria, integrante de una SLM, publica en 4DMOVIN, entendemos que se están cumpliendo con los acuerdos y normas internas que les rigen. A modo de síntesi, destacamos dos ideas:
    <ul>
      <li>En ningún caso 4DMOVIN será responsable del posible quebrantamiento del código deontológico que vincula a las inmobiliarias de una SLM y,
      <li>En el momento en que una inmobiliaria (perteneciente al Servicio de Listado Múltiple) publique un inmueble de comercialización compartida, certifica que se cumple la coyuntura óptima para poder hacerlo. Como se especifica en el apartado anterior, 4DMOVIN no se hará responsable si existe incumpliento de cualquier modo entre Sociedades.
    </ul>
    <li>Permiso para usar tu nombre de usuario, foto del perfil e información sobre tus relaciones y las acciones que realizas con cuentas, anuncios y contenido patrocinado. Nos concedes permiso para mostrar tu nombre de usuario, foto del perfil e información sobre las acciones que realizas (como los Me gusta) o tus relaciones junto a cuentas, anuncios (cuando estén disponibles), ofertas y otro contenido.
    <li>Aceptas que podemos descargar e instalar actualizaciones del Servicio en tu dispositivo.
  </ul>

  <h2><b>Derechos adicionales que nos reservamos</b></h2>
  <ul>
    <li>Si usas contenido protegido por derechos de propiedad intelectual que poseemos e incluimos en nuestro Servicio, tales como imágenes, diseños o videos que ofrecemos y que tú agregas al contenido que creas o compartes, nos reservamos todos los derechos sobre dicho contenido, pero no sobre los tuyos.
    <li>El software ofrece una herramienta accesible a todas las inmobiliarias para que pongan una marca de agua a las fotografías que suban al portal, por lo que si no se hace (o si se hace también) y un tercero utiliza el material multimedia, 4DMOVIN no se hará responsable bajo ningún concepto de los perjuicios ocasionados a la inmobiliaria agraviada.
    <li>Debes obtener nuestro permiso por escrito o un permiso en virtud de una licencia de código abierto para modificar, descompilar o intentar de algún otro modo extraer nuestro código, o bien para crear trabajos derivados de él.
  </ul>

  <h2><b>Eliminación de contenido e inhabilitación o cancelación de la cuenta</b></h2>
  <ul>
    <li>Podemos eliminar cualquier contenido o información que compartas en el Servicio si consideramos que infringe estas Condiciones de uso o nuestras políticas, o si la ley lo exige. Podemos negarnos a proporcionarte o dejar de proporcionarte la totalidad o una parte del Servicio (incluso inhabilitar o cancelar tu cuenta) de inmediato si: infringes estas Condiciones de uso o nuestras políticas de forma clara, grave o reiterada, infringes reiteradamente los derechos de propiedad intelectual de otras personas o nos lo exige la ley. Si tomamos medidas para eliminar tu contenido porque infringe nuestras Normas comunitarias, o bien inhabilitamos o cancelamos tu cuenta, te enviaremos una notificación según corresponda. Si consideras que tu cuenta se canceló por error o quieres inhabilitarla o eliminarla de forma definitiva, consulta a nuestro Servicio de Atención al Cliente en soporte@movin.cloud
    <li>El contenido que elimines puede seguir apareciendo durante un tiempo limitado en copias de seguridad y seguirá estando visible donde otras personas lo hayan compartido.
  </ul>

  <h2><b>Nuestro acuerdo y qué ocurre si no estamos de acuerdo con las disposiciones</b></h2>
  <ul>
    <li>Si no se puede ejecutar alguna parte de este acuerdo, el resto continuará en vigor.
    <li>Cualquier modificación a nuestro acuerdo o exención de su cumplimiento deberá hacerse por escrito y estar firmada por nosotros. Si no cumplimos alguna parte de este acuerdo, ello no se considerará una exención a su cumplimiento.
    <li>Nos reservamos todos los derechos que no te hayamos concedido de forma expresa.
  </ul>
  <h3><b>A quién se conceden derechos en virtud de este acuerdo</b></h3>
  <ul>
    <li>Este acuerdo no concede derechos a ningún tercero.
    <li>No puedes transferir tus derechos u obligaciones en virtud de este acuerdo sin nuestro consentimiento.
    <li>Nuestros derechos y obligaciones se pueden asignar a otras personas. Por ejemplo, esto puede ocurrir si cambia nuestra titularidad, tal como una fusión, adquisición o venta de activos, o si la ley lo exige.
  </ul>
  <h3><b>Quién asume la responsabilidad si ocurre algo</b></h3>
  <ul>
    <li>Haremos todos los esfuerzos razonables para proporcionarte nuestro Servicio y mantener un entorno seguro y libre de errores. No obstante, no podemos garantizar que nuestro Servicio siempre funcionará sin interrupciones, demoras o imperfecciones. Siempre que hagamos todos los esfuerzos razonables, no asumiremos responsabilidad alguna por pérdidas que no surjan debido a nuestro incumplimiento de estas Condiciones o, de otro modo, como consecuencia de nuestras acciones; pérdidas que ni tú ni nosotros podemos prever de forma razonable en el momento de aceptar estas Condiciones; contenido publicado por otras personas que sea ofensivo, inapropiado, obsceno, ilegal o de otro modo cuestionable y que puedes encontrar en nuestro Servicio; y eventos que escapen a nuestro control razonable.
    <li>Tanto el Cliente como 4DMOVIN se comprometen a no divulgar la información y documentación confidencial referente a la otra parte de la que hayan tenido conocimiento por razón de la ejecución del presente contrato, salvo las indispensables para su cumplimiento, y a mantenerla en secreto, incluso después de la finalización del mismo. Ambas partes acuerdan tomar las medidas necesarias respecto a su personal e incluso terceros que puedan tener acceso a dichas información y documentación, a fin de garantizar la confidencialidad objeto de esta cláusula.
    <li>Aviso legal por el quebrantamiento de la cláusula anterior, en el supuesto que el Cliente revelase datos económicos, organizativos, comerciales o de carácter análogo que tengan la consideración de secreto empresarial, la Sociedad podrá reclamar que se le indemnice económicamente en concepto de revelación de secreto empresarial y por los perjuicios que hayan sido ocasionados, sin renunciar a las medidas legales pertinentes que se considerasen oportunas acometer.
    <li>En cualquier caso, no será responsable de las interrupciones del Servicio o tiempos de inactividad del Servidor, debido a mantenimiento especial, seguridad o cuestiones de capacidad, y también a algunos acontecimientos sobre los cuales 4DMOVIN no puede influir (p.e., anomalías en redes de comunicación públicas, corte de corriente, etc.). Los Servicios pueden ser suspendidos o afectados temporalmente por breves anomalías.
  </ul>


  <h2><b>Propiedad Industrial e Intelectual</b></h2>
  <p>
  Todos los derechos de propiedad intelectual sobre los diseños, bases de datos, programas de ordenador subyacentes (incluidos los códigos fuente), los diferentes elementos que integran la web (textos, gráficos, fotografías, vídeos, grabaciones sonoras, combinaciones de colores , etc.), Los "Contenidos", así como su estructura, selección y orden, son titularidad de 4DMOVIN o de sus licenciantes. Los signos distintivos incluidos en la web (marcas y nombres comerciales) son titularidad de 4DMOVIN o de sus licenciantes.
  </p>
  <p>
  El uso, por parte del usuario, del portal y de cualquier herramienta interna de gestión no supone la cesión de ningún derecho de propiedad intelectual y / o industrial sobre ello y los contenidos.
  </p>
  <p>
  En este sentido, queda expresamente prohibido al usuario la reproducción, transformación, distribución, comunicación pública, puesta a disposición, extracción o cualquier otra forma de difusión no autorizadas expresamente la web, sus contenidos o los signos distintivos de 4DMOVIN.
  </p>
  <p>
  La utilización no autorizada de los contenidos, así como los perjuicios ocasionados en los derechos de propiedad intelectual e industrial de 4DMOVIN, podrán dar lugar al ejercicio de las acciones que legalmente le correspondan, y a las responsabilidades que, en su caso, se deriven.
  </p>

  <h2><b>Cómo resolveremos las disputas</b></h2>
  <h3><b>Personas Jurídicas</b></h3>
  <p>
  En el supuesto que exista un conflicto entre las partes, éstas acuerdan someter la resolución de sus conflictos al método extrajudicial conocido como arbitraje (Ley 60/2003, de 23 de diciembre, de Arbitraje) y así resolver las disputas de la manera más económicamente favorable para ambas partes.
  </p>

  <h3><b>Material no solicitado</b></h3>
  <p>
  Los comentarios o las sugerencias siempre son bienvenidos. No obstante, podemos usarlos sin restricción u obligación alguna de ofrecerte una compensación, y no tenemos la obligación de mantenerlos en confidencialidad.
  </p>

  <h3><b>Actualización de estas Condiciones</b></h3>
  <p>
  Es posible que modifiquemos nuestro Servicio y políticas, y que debamos actualizar estas Condiciones de modo que reflejen nuestros Servicios y políticas con precisión. A menos que la ley disponga lo contrario, te enviaremos una notificación (por ejemplo, mediante nuestro Servicio) al menos siete (7) días antes de modificar estas Condiciones, y te daremos la oportunidad de revisar los cambios antes de que entren en vigor. Si sigues usando el Servicio, quedarás sujeto a las Condiciones actualizadas. Si no quieres aceptar estas ni otras Condiciones actualizadas, puedes eliminar tu cuenta enviando un correo electrónico a soporte@movin.cloud
  </p>

  <h2><b>Publicación en el portal</b></h2>
  <h3><b>Información requerida</b></h3>
  <p>
  Es indispensable para publicar en el portal que la empresa inmobiliaria proporcione los datos determinados. Esta información se clasifica en dos apartados:
  </p>
  <p>
    La información preceptiva sobre la inmobiliaria:
  </p>
  <p>
    Debe ser precisa y correcta, ya que, en caso contrario, no se dará de alta <i>el perfil de la inmobiliaria</i>, y por lo tanto, tampoco ningún inmueble:
  </p>
  <ul>
    <li>Logo inmobiliaria
    <li>Imagen banner (opcional, si no se suministra se pone el de defecto de 4DMOVIN)
    <li>Nombre de la inmobiliaria
    <li>Eslogan o descripción corta, en los diferentes idiomas en que quiere publicar
    <li>Descripción larga, en los diferentes idiomas en que quiere publicar
    <li>E-mail y teléfono de contacto
  </ul>
  <p>
  Es imperativo que los datos sean correctos, óptimos; si la inmobiliaria no aportase correctamente la información del inmueble requerida, la ficha del inmueble no sería dada de alta. La información obligatoria es la siguiente:
  </p>
  <ul>
    <li>Cinco (5) fotografías del inmueble
    <li>Descripción del inmueble, en los diferentes idiomas de publicación
    <li>Precio y dirección
    <li>Número de m2, habitaciones, baños
    <li>Piscina, parking, calefacción y aire acondicionado (En el supuesto que no se rellenen cada uno de los campos correspondientes, por defecto, se determinará que el inmueble carece de tales equipaciones).
    <li>Operación que se desea realizar respecto el inmueble: compraventa, alquiler...
    <li>Catastro del inmueble
    <li>Certificado energético de emisiones
    <li>Certificado energético de consumo
    <li>Tipología y subtipología del inmueble
    <li>País, población y código postal del inmueble
    <li>Coordenadas (latitud, longitud) de geolocalización de publicación
  </ul>
  <h3><b>Posicionamiento</b></h3>
  <p>
  Es imperativa la redacción del reglamento, la cual permitirá al cliente conocer cuáles son los requisitos que debe cumplir para conseguir el posicionamiento más eficiente posible dentro del portal.
  </p>
  <p>
    En primer lugar, <b>un 50% de la puntuación total</b> está basada en la puntuación general de la inmobiliaria que será desglosada de la siguiente manera:
  </p>
  <ul>
    <li>50% de la puntuación subtotal según la media obtenida de las reseñas de los clientes de la inmobiliaria.
    <li>25% de la puntuación subtotal según la media ponderada resultante de la totalidad de inmuebles de la inmobiliaria.
    <li>25% de la puntuación subtotal según la actividad realizada en las redes sociales (los criterios para calcular la puntuación todavía no están definidos, por lo tanto, no está disponible el 25% de la puntuación subtotal correspondiente a este apartado).
  </ul>
  <p>
    En segundo lugar, <b>un 50% de la puntuación total</b> está compuesta según una serie de criterios que son expuestos a continuación con la mayor claridad y brevedad posible:
  </p>
  <ul>
    <li>Un 40% de la puntuación subtotal atenderá a la posibilidad que el usuario pueda realizar una visita virtual del inmueble, es decir, que la inmobiliaria disponga de la opción para visitar el inmueble virtualmente.
    <li>Un 10% de la puntuación subtotal en relación a la realización de vídeos convencionales sobre el inmueble, siempre y cuando se cumplan aquellos criterios generales de profesionalidad que se requieren.
    <li>Un 15% de la puntuación subtotal según el material de SEO trabajado en el inmueble en concreto, los campos que deben ser completados son:
    <ul>
        Con un valor del 50% de la puntuación (sobre el 15% del subtotal que estamos trabajando):
      <ul>
        <li>Título del inmueble
        <li>Descripción larga del mismo
      </ul>
    </ul>
    <ul>

      Con un valor del 50% de la puntuación (sobre el 15% del subtotal que estamos trabajando):
      <ul>
        <li>Título de la página (el que aparece arriba en la descripción en la pestaña del buscador).
        <li>Alias del inmueble (lo que se introduce en la url).
        <li>Metadescripción (etiqueta HTML que contiene una breve descripción del contenido que hay en una página)
      </ul>
    </ul>
  </ul>
  <p>
    Para obtener la puntuación máxima en relación al 15% de la puntuación subtotal es necesario aportar correctamente los cinco (5) requisitos establecidos, en caso contrario, no se obtendrá la parte proporcional de la puntuación correspondiente a los datos aportados.
  </p>
  <ul>
    <li>Un 15% de la puntuación corresponderá al hecho que la inmobiliaria disponga de un mínimo de quince (15) fotografías del inmueble con los criterios de calidad correspondientes y las suba al portal, es decir, cada fotografía supone un uno por ciento (1%) de la puntuación subtotal.
    <li>Y el otro 20% restante corresponde a que las inmobiliarias aporten información extraordinaria sobre el inmueble, la información extraordinaria quedará determinada cuando esta opción esté disponible.
  </ul>
  <p>
    En el supuesto de que una inmobiliaria no cumpla con los criterios de profesionalidad exigidos, la información respecto el inmueble sea errónea, etc. las demás inmobiliarias gozarán de la prerrogativa de poder exigir el resarcimiento de la información (ya sea la gráfica (fotografías) o la descriptiva) por parte de la inmobiliaria infractora con la correspondiente penalización.
  </p>
  <p>
    En último lugar, una vez desglosados los criterios por los cuales se regirá el <b>100% de la puntuación total</b>, cabe destacar la posibilidad de obtener un <b>100% de puntuación adicional</b> siempre y cuando se haya cumplido correctamente los procedimientos para tratar de obtener el máximo puntaje del 100% posible.
  </p>
  <p>
    Este 100% de puntuación total adicional será desglosada en dos criterios:
  </p>
  <ul>
    <li>Supone un 80% extraordinario sobre el puntaje total adicional y para conseguirlo se deben cumplir dos requisitos:
      <ul>
        <li>El primer requisito valorado en un 40% sobre el valor total adicional consiste en que el cliente guarde en favoritos el inmueble de la inmobiliaria.
        <li> El segundo requisito valorado en el mismo valor (40% sobre el valor adicional) se conseguirá cuando el cliente guarde en favoritos el perfil de la inmobiliaria o que el usuario haya trabajado previamente con la inmobiliaria (función aún no disponible).
      </ul>
    <li>Supone un 20% extraordinario sobre el puntaje adicional y consistirá (aún no está disponible la opción) en publicidad de pago (MovInAds) para aquellas inmobiliarias que quieran sacarle el máximo rendimiento a sus inmuebles.
  </ul>

  <h3><b>Infografia</b></h3>

  <img src="http://imgfz.com/i/Wr2LCtc.png" style="display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;">

  <img src="http://imgfz.com/i/UFk7nJP.png" style="display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;">

  <img src="http://imgfz.com/i/lN3YqVx.png" style="display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;">

  <img src="http://imgfz.com/i/RybvCw6.png" style="display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;">

  <h3><b>Supuestos conflictivos y cómo los resolvemos</b></h3>
  <ul>
    <li>En caso de que suceda el excepcional acontecimiento de empate en la puntuación entre dos inmobiliarias, la inmobiliaria que se verá priorizada y por lo tanto visualizarán los usuarios será la que haya dado primeramente de alta el inmueble en el portal.
    <li>En la ficha general del inmueble las imágenes que se mostrarán al público son las de la inmobiliaria que tenga mejor puntuación total, es decir, el 100% extra no computa para calcular qué inmobiliaria posee mejor puntuación.
    <li>Contenido de vídeo:  No es un criterio obligatorio para poder publicar el inmueble en el portal. Ahora bien, si el usuario activa en el buscador la opción de vídeo, sólo le aparecerán las inmobiliarias que ofrezcan el servicio que se adapte a las necesidades del cliente, de modo que se descartarán permanentemente de la ficha general del inmueble aquellas que no se adapten a los parámetros del cliente y se premiará a aquellos que sí lo hagan.
    <li>Visitas virtuales: No es un criterio obligatorio para poder publicar el inmueble en el portal. Ahora bien, si el usuario activa en el buscador la opción de visita virtual, sólo le aparecerán las inmobiliarias que ofrezcan el servicio que se adapte a las necesidades del cliente, de modo que se descartarán permanentemente de la ficha general del inmueble aquellas que no se adapten a los parámetros del cliente y se premiará a aquellos que sí lo hagan.
    <li>En el caso que el cliente haya marcado ambos campos “vídeo” y “visita virtual” sólo aparecerán las inmobiliarias que ofrezcan la posibilidad de que el cliente disfrute de las dos opciones, ya que como anteriormente se ha dicho, éstos no son campos obligatorios, suponen un coste adicional para la inmobiliaria y ya que proporcionan una mejoría respecto la experiencia de usuario, también es justo que sean recompensados por ello.
    <li>Si el cliente ha filtrado los resultados de búsqueda con un idioma en concreto sólo le aparecerán las inmobiliarias que ofrezcan los servicios en el idioma escogido por el cliente. El cliente no visualizará las demás inmobiliarias, de esta manera el cliente podrá visualizar en el portal la inmobiliaria que mejor se adapte a sus pretensiones.
    <li>Los criterios para calcular la puntuación que regirá el posicionamiento interno de cada inmobiliaria dentro del portal podrán ser modificados, avisando previamente a las inmobiliarias en un periodo de quince (15) días de antelación. Podrán ser modificados tanto los requisitos (conceptos) como los valores porcentuales.
  </ul>


  <h2><b>Calendarización</b></h2>
  <p>
  La herramienta de calendarización es una funcionalidad que 4DMOVIN brinda para permitir al cliente un contacto más eficiente y humano con la inmobiliaria. Antes de proseguir con la explicación de las distintas tipologías de calendarización, es relevante destacar el tratamiento que deberá tener la empresa respecto de los usuarios en relación a si son leads o clientes ya existentes en su base de datos.
  </p>
  <h3 style="text-align: center">Leads</h3>
  <p>
  Los Leads, también conocidos como “potenciales nuevos clientes”, se darán de alta en la base de datos de 4DMOVIN y se compartirán los datos y preferencias con las inmobiliarias para que puedan ofrecer el mejor servicio a los clientes.
  </p>
  <h3 style="text-align: center">Clientes</h3>
  <p>
  Los Clientes son aquellos clientes que ya habían tenido relaciones comerciales previamente y ya existentes en sus bases de datos.
  </p>
  <p>
    La inmobiliaria deberá decidir si quiere que se compartan los clientes entre sus comerciales, si no fuese así, el cliente desarrollará las actividades comerciales pertinentes con el comercial que tenga asignado y le había atendido previamente.
  </p>
  <h3><b>Modelos de calendarización</b></h3>
  <p>
    Los Modelos de calendarización son los métodos por los cuales el lead o cliente tiene la posibilidad de agendar de forma automática una visita. Existen dos tipos: la Visita Presencial que consiste en la realización de una visita guiada por el comercial de la inmobiliaria de forma presencial y la Visita por Streaming la cual se basa en una interacción entre <i>Cliente</i> o <i>Lead</i> con el comercial mediante la funcionalidad “Stream” que brinda 4DMOVIN.
  </p>
  <p>
    Se trate de una visita presencial o una visita por streaming, en ambos casos, la aceptación o declinación de la visita propuesta por el cliente debe llevarse a cabo mediante el uso de la herramienta de gestión interna del portal.
  </p>
  <h3>Visita Presencial</h3>
  <p>
  En primer lugar, la inmobiliaria debe indicar:
  </p>
  <ul>
    <li>La disponibilidad horaria del comercial
    <li>La disponibilidad horaria del inmueble para ser visitada.
  </ul>
  <p>
    En caso de que no fueran proporcionados, la inmobiliaria no aparecerá como opción ni para los <i>leads</i> ni para los <i>clientes</i>. Es importante destacar que la empresa debe tener como mínimo un agente comercial.
  </p>
  <h3 style="text-align: center">Lead</h3>
  <p>
  Cuando el lead calendarice una visita presencial se enviará una notificación a la agenda de la herramienta de gestión interna del portal a todos los comerciales con disponibilidad horaria que encajen con las pretensiones del lead para que acepten o rechacen la visita. Cuando hayan aceptado la invitación, 4DMOVIN otorgará información a la inmobiliaria del lead.
  </p>
  <p>
    La hora de la visita queda definida según tres factores:
  </p>
  <p>
  El horario de visitas del inmueble proporcionado por la inmobiliaria.
  </p>
  <ul>
    <li>La disponibilidad de sus agentes comerciales.
    <li>La disponibilidad del lead.
  </ul>
  <p>
  Una vez recibida la petición de visita presencial, enviada a todos los comerciales, el primero que la acepte conseguirá el lead y, el resto de comerciales, no tendrán la opción de aceptar la visita.
  </p>
  <b>¿Qué sucede si ninguno de los comerciales está disponible?</b>
  <p>
  La inmobiliaria tiene un tiempo determinado para poder aceptar o rechazar la visita propuesta por el lead desde la herramienta interna que proporcionamos.
  </p>
  <ul>
    <li>Si la visita está programada para el <b>mismo dia</b>, los comerciales de la inmobiliaria dispondrán de <b>dos</b> (2) <b>horas</b> para aceptar o rechazar la visita,
    <li>Si la visita está programada para el <b>día siguiente o tienen un margen temporal superior</b>, la inmobiliaria dispondrá de <b>cinco</b> (5) <b>horas</b> para tomar una decisión.
  </ul>
  <p>
    En el supuesto que el lead no obtenga una respuesta en los límites temporales determinados, se le propondrá al <i>lead</i> otras inmobiliarias que tengan el inmueble en su ficha general para que pueda calendarizar óptimamente la visita presencial y se le notificará mismamente a la inmobiliaria informando de que han perdido a un <i>lead</i>.
  </p>
  <h3 style="text-align: center">Cliente</h3>
  <p>
    Cuando el <i>cliente</i> calendarice una visita presencial se enviará una notificación a la agenda de la herramienta de gestión interna del portal a los comerciales de la inmobiliaria escogida por el <i>cliente</i> para que acepten o rechacen la visita. En ese momento, 4DMOVIN informará a la inmobiliaria que su <i>cliente</i> quiere calendarizar una visita presencial. Al tratarse de <i>clientes</i> que ya han trabajado con la inmobiliaria, ésta deberá decidir si quiere que cuando entre una visita se notifique de la misma al comercial asignado para cada <i>cliente</i> (comercial con el que ya tuvo relación previamente) o, si no, por defecto, se enviará la notificación a todos los comerciales.
  </p>
  <p>
    La hora de la visita queda definida según tres factores:
  </p>
  <ul>
    <li>El horario de visitas del inmueble proporcionado por la inmobiliaria
    <li>La disponibilidad de sus agentes comerciales
    <li>La disponibilidad del <i>cliente</i>.
  </ul>
  <p>
    Una vez recibida la petición de visita presencial, enviada a el/los comercial/es, la deberán aceptar.
  </p>
  <b>¿Qué sucede si ninguno de los comerciales está disponible?</b>
  <p>
    Bien, la inmobiliaria tiene un tiempo determinado para poder aceptar o rechazar la visita propuesta por el <i>cliente</i>;
  </p>
  <ul>
    <li>Si la visita está programada para el <b>mismo dia</b>, los comerciales de la inmobiliaria dispondrán de <b>dos</b> (2) <b>horas</b> para aceptar o rechazar la visita,
    <li>Si la visita está programada para el <b>día siguiente o tienen un margen temporal superior</b>, la inmobiliaria dispondrá de <b>cinco</b> (5) <b>horas</b> para tomar una decisión.
  </ul>
  <p>
    En el supuesto que <b>el <i>cliente</i> no obtenga una respuesta en los límites temporales determinados</b>, se le informará al <i>cliente</i> que los agentes comerciales no están disponibles y que se pondrán en contacto con él en cuanto puedan, por otro lado, los agentes comerciales recibirán un aviso de que un <i>cliente</i> quería calendarizar una visita presencial y no ha sido atendido.
  </p>
  <h2>Visitas en Streaming</h2>
  <p>
  La calendarización mediante vídeo en streaming tiene la misma lógica que en la visitas presenciales pero únicamente se tiene en cuenta la disponibilidad horaria del comercial y no el horario de visita del inmueble. La herramienta que permite realizar la videollamada entre cliente e inmobiliaria es proporcionada por 4DMOVIN y el lead o cliente podrá acudir a ella para calendarizar una consulta por streaming o, si necesita inmediatez, podrá realizar la videollamada y será atendida por el comercial si tiene disponibilidad horaria.
  </p>
  <h3 style="text-align: center">Lead</h3>
  <p>
    Entonces, <b>¿Cuál será el procedimiento en las consultas por streaming?</b>
  </p>
  <p>
  Si la consulta hecha por el lead está calendarizada, el comercial llamará en la franja horaria acordada y se llevará a cabo la interacción virtual entre lead y comercial.
  </p>
  <p>
    Por otro lado, si la consulta no está calendarizada, el lead llamará por streaming, mediante la funcionalidad del portal, al comercial, y si éste responde, se llevará a cabo la interacción.
  </p>
  <p>
    Ahora bien, <b>¿Qué sucede si el lead se pone en contacto con los comerciales y ninguno estuviera disponible?</b>
  </p>
  <p>
    Al lead se le enviaría un mensaje proponiéndole dos posibles escenarios:
  </p>
  <ul>
    <li>El lead puede escoger esperar a que le devuelvan la llamada, por lo que el servicio 4DMOVIN enviará una notificación a los comerciales indicando que el lead espera una respuesta por parte de los comerciales de la inmobiliaria con la que ha escogido trabajar o;
    <li>Puede escoger cambiar de inmobiliaria, de esta manera el servicio 4DMOVIN comunicará a los comerciales que han perdido la llamada, por lo que al lead se le vuelve a dar opción de volver a realizar la llamada con otra inmobiliaria que tenga el inmueble en su ficha.
  </ul>
  <h3 style="text-align: center">Cliente</h3>
  <p>
    Entonces, <b>¿Cuál será el procedimiento en las consultas por streaming?</b>
  </p>
  <p>
  Si la consulta hecha por el cliente está calendarizada, el comercial llamará en la franja horaria acordada y se llevará a cabo la interacción virtual entre el cliente y el comercial.
  </p>
  <p>
    Por otro lado, si la consulta no está calendarizada, el cliente llamará por streaming, mediante la funcionalidad del portal, al comercial, y si este responde, se llevará a cabo la interacción.
  </p>
  <p>
    Ahora bien, <b>¿Qué sucede si el cliente se pone en contacto con los comerciales y ninguno estuviera disponible?</b>
  </p>
  <p>
    Al cliente se le enviará un mensaje informando que cuando los comerciales estén disponibles se pondrán en contacto con el cliente y, a su vez, el servicio 4DMOVIN enviará una notificación a los comerciales indicando que el cliente espera una respuesta por parte de los comerciales de la inmobiliaria con la que ha escogido trabajar.
  </p>

  <h2><b>Pago</b></h2>
  <p>
  El sistema de pago para adquirir nuestras distintas funcionalidades y servicios de pago es mediante Movins. Los Movins son monedas de cambio que son compradas y se pueden canjear por distintas prestaciones dentro del portal 4DMOVIN.
  </p>

  <h3><b>Métodos de pago</b></h3>
  <p>
  Es necesario que se facilite la cuenta corriente o tarjeta bancaria en el momento en que la inmobiliaria se da de alta en el portal 4DMOVIN. Para poder contratar cualquier servicio de los ofrecidos por 4DMOVIN, la inmobiliaria deberá adquirir el Plan de Movins que mejor se adapte a sus pretensiones. Es importante destacar que existen dos opciones respecto la compra de Movins. Cabe la posibilidad de:
  </p>
  <ul>
    <li>Renovar automáticamente una vez se hayan terminado los Movins (de modo que se renueven los Movins contratados del último Plan de Movins (cabe la posibilidad de aumentar o disminuir el Plan de Movins adquirido). 4DMOVIN incentiva el uso de este método ofreciendo condiciones de pago más favorables.
    <li>Contratar un Plan de Movins y una vez se agoten, cabrá la posibilidad de contratar otro Plan de Movins (aunque no se realizará de manera automática, se realizará con la voluntad expresa de la inmobiliaria).
  </ul>
  <p>
  El pago se realizará en el mismo momento en que la inmobiliaria contrate el Plan de Movins. Los pagos se computarán de la misma manera si se trata de un Plan de Movins Renovable o un Plan de Movins Puntual:
  </p>
  <p>
    En el primer caso, cuando se hayan finalizado los Movins se volverán a reponer y se enviará la factura a la empresa contratante; en el caso del Plan de Movins Puntual, se cobrará una vez contratado el Plan de Movins y cuando se agote, si se contrata un nuevo Plan de Movins, se emitirá una nueva factura al contratante.
  </p>
  <p>
  También se avisará a la inmobiliaria cuando los comerciales hayan gastado el 90% de los Movins.
  </p>
  <p>
  El cliente podrá desistir en todo momento de los servicios que 4DMOVIN, siempre teniendo en cuenta que queda denegado el reembolso de dinero a cambio de los Movins no utilizados.
  </p>
  <p>
  En última instancia, destacamos que el importe de los Movins queda establecido en la página web y que éste variará según criterios cuantitativos (mayor cantidad de Movins contratados, mejor precio) y temporales (mayor compromiso al contratar Movins cuando se agoten, mejor precio), ahora bien, el precio incluye los impuestos, gravámenes, tasas o derechos impuestos por las autoridades fiscales.
  </p>

  <!--<p> El titular de movin.cloud es Namastech, SL ( "Namastech"), cuyos datos de identificación son las siguientes: </p>
  <ul>
    <li>C. Doctor Trueta, 26-28, 1º 1ª. 08860 Castelldefels (Barcelona)</li>
    <li>B-64280068</li>
    <li>Teléfono: +34 93 665 75 79</li>
    <li>Inscrita en el Registro Mercantil de Barcelona, Tomo 38869, Folio 0132, Página 338900</li>
  </ul>-->

  <!--<h3><b>Acceso a la plataforma</b></h3>
  <p>
    El Aviso Legal regula el acceso a la plataforma y su utilización por parte de sus usuarios.
    <br>El acceso a la plataforma y su utilización conlleva la aceptación del Aviso Legal, así como del resto de políticas que, en su caso, se puedan prever en la plataforma.
    <br>Por lo tanto, rogamos al usuario que lea atentamente el Aviso Legal cuando se proponga utilizar la Plataforma, ya que puede modificarse en cualquier momento. Si no acepta el Aviso Legal, le rogamos se abstenga de utilizar la plataforma.
  <br></p>

  <h3><b>Utilización y funcionamiento de la plataforma</b></h3>
  <p>
    El usuario se compromete a hacer un uso diligente de la plataforma, así como de la información que se incluye, con total sujeción a la normativa aplicable, en el Aviso legal y, en su caso, al resto de términos y condiciones que se incluyan en la plataforma.
    <br>Igualmente, el usuario se compromete a no realizar ningún acto con objeto de dañar, inutilizar o sobrecargar la plataforma, o que impida, de cualquier forma, la normal utilización y funcionamiento del mismo.
    <br>Se informa al usuario que, en el supuesto de que incumpla el Aviso Legal o, en su caso, el resto de políticas de la plataforma, Namastech se reserva el derecho de limitar, suspender y terminar su acceso a la plataforma, adoptando cualquier medida técnica que sea necesaria.
    <br>Namastech hace sus mejores esfuerzos para mantener la plataforma en buen funcionamiento, evitando errores o, en su caso, reparándolos y manteniendo los contenidos de la plataforma debidamente actualizados. Sin embargo, Namastech no garantiza la disponibilidad y continuidad en el acceso a la plataforma ni la inexistencia de errores en sus contenidos, así como tampoco que estos últimos sean oportunamente actualizados.
  <br></p>

  <h3><b>Responsabilidad</b></h3>
  <p>
    Tanto el acceso a la plataforma como el uso que pueda hacerse de cualquier información que contenga se realizan bajo la responsabilidad exclusiva del usuario.
    <br>Namastech no se responsabiliza de ningún daño o perjuicio que pudiera derivarse, directa o indirectamente, del acceso o uso de la información contenida en la plataforma, incluyendo los producidos en los sistemas informáticos o los provocados por la introducción de virus o ataques informáticos. Tampoco será responsable de los daños que puedan sufrir los usuarios por un uso inadecuado de la plataforma ni de las caídas, interrupciones, ausencia o defecto en las comunicaciones y / o Internet.
    <br>Adicionalmente, Namastech no se hace responsable de ningún daño o perjuicio en el software o hardware del usuario que se derive del acceso o uso de la plataforma.
    <br>El usuario responderá de los daños y perjuicios que Namastech pueda sufrir como consecuencia del incumplimiento de cualquiera de las obligaciones a que quede sometido en virtud del Aviso Legal, de la legislación aplicable y / o del resto de políticas.
  <br></p>

  <h3><b>Política en materia de enlaces (links)</b></h3>
    <p>a) <i>Enlaces con la plataforma</i>
      <br>Quién que tenga intención de incluir en un sitio plataforma ( "plataforma Enlazando") un enlace que dirija a la plataforma de Namastech, deberá solicitarlo a Namastech y cumplir con la legislación vigente; en ningún caso, podrá alojar contenidos, propios o de terceros, que: (i) sean ilícitos, nocivos, violentos, racistas, denigrantes, etc .; y / o (ii) resulten inapropiados o no pertinentes en relación con Namastech.
      <br>El enlace no implica que Namastech avale, promocione, garantice, supervise o recomiende el contenido o los servicios de la plataforma Enlazando, ni que sea responsable de su contenido. En el supuesto de incumplimiento de cualquiera de los términos referidos anteriormente, Namastech procederá, de forma inmediata, a la revocación del consentimiento otorgado en la plataforma Enlazando, que deberá suprimir el enlace.
    <br></p>

    <p>b) <i>Enlaces con otras plataformas</i>
      <br>En la plataforma se pueden incluir diferentes enlaces que permitan al usuario acceder a otras plataformas ( "plataformas enlazados").
      <br>En ningún caso, la existencia de plataformas enlazados comporta recomendación, promoción, identificación o conformidad de Namastech con las manifestaciones, contenidos o servicios proporcionados a través de los sitios enlazados. En consecuencia, Namastech no se hace responsable del contenido, ni de los términos y condiciones de los sitios enlazados; el usuario es el único responsable de comprobarlos y aceptarlos cada vez que acceda y los utilice.
    <br></p>

  <h3><b>Propiedad Industrial e Intelectual</b></h3>
  <p>
    Todos los derechos de propiedad intelectual sobre los diseños, bases de datos, programas de ordenador subyacentes (incluidos los códigos fuente), los diferentes elementos que integran la plataforma (textos, gráficos, fotografías, vídeos, grabaciones sonoras, combinaciones de colores , etc.) [los "Contenidos"], así como su estructura, selección y orden, son titularidad de Namastech o de sus licenciantes. Los signos distintivos incluidos en la plataforma (marcas y nombres comerciales) son titularidad de Namastech o de sus licenciantes.
    <br>El uso de la plataforma por parte del usuario no supone la cesión de ningún derecho de propiedad intelectual y / o industrial sobre la plataforma y los contenidos.
    <br>En este sentido, queda expresamente prohibido al usuario la reproducción, transformación, distribución, comunicación pública, puesta a disposición, extracción o cualquier otra forma de difusión no autorizadas expresamente la plataforma, sus contenidos o los signos distintivos de Namastech.
    <br>La utilización no autorizada de los contenidos, así como los perjuicios ocasionados en los derechos de propiedad intelectual e industrial de Namastech, podrán dar lugar al ejercicio de las acciones que legalmente le correspondan, ya las responsabilidades que, en su caso, se deriven. 
  <br></p>

  <h3><b>Nulidad</b></h3>
  <p>
    Si cualquiera de las cláusulas del Aviso Legal es declarada, total o parcialmente, nula o ineficaz, tal nulidad o ineficacia afectará tan sólo a dicha disposición o la parte de la misma que resulte nula o ineficaz. A estos efectos, el Aviso Legal sólo dejará de tener validez exclusivamente respecto de la disposición nula o ineficaz, y ninguna otra parte o disposición quedará anulada, invalidada, perjudicada o afectada por esta nulidad o ineficacia, salvo que por resultar esencial debiera afectar el Aviso Legal de forma integral.
  <br></p>

  <h3><b>Protección de datos</b></h3>
  <p style="font-size: larger">
    De acuerdo con la Ley 34/2002 de Servicios de la Sociedad de la Información y de Comercio Electrónico y en cumplimiento de lo dispuesto en el Reglamento (UE) 2016/67 del Parlamento Europeo y del Consejo de 27 de abril de 2016, de Protección de Datos de Carácter Personal, Namastech informa al Usuario de la existencia de un tratamiento de datos de carácter personal.
  <br></p>

  <p style="font-size: larger">
    Responsable del tratamiento: NAMASTECH S.L. CIF: B64280068 Dirección C/Doctor Trueta 26-28, 1º1ª, 08860 Castelldefels-Barcelona correo electrónico: 4dmovin@movin.cloud
  <br></p>

  <p style="font-size: larger">
    El Usuario acepta la inclusión de los datos obtenidos durante la navegación por la plataforma, o proporcionadas rellenando cualquier formulario que aparezca, así como los derivados de las eventuales relaciones comerciales que puedan generarse, en nuestra base de datos.
  <br></p>

  <p style="font-size: larger">
    El Usuario podrá ejercer, respecto a los datos obtenidos, los derechos reconocidos en el nuevo Reglamento General de Protección de Datos y particularmente los derechos de acceso, rectificación o cancelación de datos y oposición, así como el de la revocación del consentimiento para la cesión de sus datos o para cualquiera de los usos descritos.
  <br></p>

  <p style="font-size: larger">
    Los derechos a los que se refiere el párrafo anterior podrán ser ejercitados por el Usuario mediante solicitud dirigida a Namastech S.L. C/Doctor Trueta 26-28, 1º1ª, 08860 Castelldefels (Barcelona), o bien mediante el envío de un correo electrónico a la dirección 4dmovin@movin.cloud
  <br></p>
  <p style="font-size: larger">
    Namastech S.L. declara haber adoptado todas las medidas de seguridad necesarias y adecuadas a lo que establece el Reglamento UE 2016/679 por el cual se aprueban las Medidas de Seguridad de los archivos automatizados que contengan datos de carácter personal.
    El Usuario acepta la inclusión de los datos recabados durante la navegación por la plataforma, o proporcionados mediante la cumplimentación de cualquier formulario que en él se encuentre, en un fichero automatizado de datos de carácter personal anteriormente descrito.
  <br></p>

  <h3><b>Política de cookies</b></h3>
  <p>
    Mediante el acceso y uso de la plataforma, aceptas que Namastech puede utilizar cookies propias y de terceros (en concreto, Google Analytics, Cloudflare, Facebook y Youtube), de sesión y permanentes, con el fin de permitir la navegación a través de la plataforma con características predefinidas, el seguimiento y análisis de su comportamiento y la gestión de espacios publicitarios.
    <br>Las cookies constituyen procedimientos automáticos de recogida de información relativa a las preferencias determinadas por un usuario de internet durante tu visita a un determinado plataforma con el fin de reconocerlo como usuario recurrente, personalizar su uso y facilitar una navegación más rápida.
    <br>Puedes configurar tu navegador para aceptar o rechazar por defecto todas las cookies, o para recibir un aviso en pantalla de la recepción de cada cookie y decidir en ese momento su implantación.
    <br>Los procedimientos para el bloqueo y eliminación de las cookies pueden diferir de un navegador de internet a otro, por lo que el usuario deberá consultar las instrucciones facilitadas por el navegador de internet, como las que se detallan a continuación:
  <br></p>
  <ul>
    <li>Configuración de cookies de Internet Explorer</li>
    <li>Configuración de cookies de Firefox</li>
    <li>Configuración de cookies de Google Chrome</li>
    <li>Configuración de cookies de Safari</li>
  </ul>
  <p>
    Estos navegadores están sometidos a cambios o modificaciones, por lo que no podemos garantizar que se ajusten completamente a la versión de su navegador.
    <br>En caso de que desee rechazar la utilización de cookies, puedes tener limitado el uso de algunas prestaciones de la plataforma. Si continúas navegando por la plataforma sin denegar tu autorización, implica que aceptas su uso por parte de Namastech.
    <br>Para cualquier cuestión adicional relacionada con el uso de cookies, puedes enviar tu petición mediante un correo electrónico a 4dmovin@movin.cloud.
  <br></p>-->
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Cerrar</button>
</mat-dialog-actions>
