<mat-accordion class="tabla-configuration" *ngIf="loaded && isInmo" >
  <mat-expansion-panel expanded="false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        PRIVACIDAD
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="admin-container" fxLayoutAlign="center start" fxFlex="100" fxLayout="row wrap">
      <span fxFlex="33.33" fxFlex.sm="100" fxFlex.xs="100">Información de mi perfil
        <!--<i class="material-icons" (click)="showCommunityPerfil()">visibility</i>-->
        <i class="material-icons" (click)="showAgency()">create</i>
      </span>
      <div fxFlex="33.33" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap">
        <span class="labels-padds" fxFlex="100">Tipo de perfil <i class="material-icons" (click)="showHelpButton(1)">help</i></span>
        <mat-radio-group fxFlex="100" [(ngModel)]="colaborationInfo.tipoPerfil" [ngModelOptions]="{standalone: true}" (ngModelChange)="confirmOperation(1)">
          <mat-radio-button [value]="1">Público</mat-radio-button>
          <mat-radio-button [value]="2">Privado</mat-radio-button>
        </mat-radio-group>
      </div>
      <div fxFlex="33.33" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap">
        <span class="labels-padds" fxFlex="100">Mensajes <i class="material-icons" (click)="showHelpButton(2)">help</i></span>
        <mat-radio-group fxFlex="100" [(ngModel)]="colaborationInfo.mensajes" [ngModelOptions]="{standalone: true}" (ngModelChange)="confirmOperation(8)">
          <mat-radio-button [value]="1">Todos</mat-radio-button>
          <mat-radio-button [value]="2">Solo mis colaboradores</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel expanded="false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        CONFIGURACIÓN COLABORADORES
      </mat-panel-title>
      <mat-panel-description>
        <span fxShow="true" fxShow.xs="false">Puedes decidir con quién quieres colaborar.</span>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="admin-container" fxLayoutAlign="center start" fxLayout="row wrap" fxFlex="100">
      <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap">
        <span class="labels-padds" fxFlex="100">Colaborar con mis Agrupaciones <i class="material-icons" (click)="showHelpButton(3)">help</i></span>
        <div fxFlex="100" fxLayout="row wrap">
          <mat-radio-group fxFlex="100" style="align-self: center;" [(ngModel)]="colaborationInfo.colaborarConAgrupaciones" (ngModelChange)="confirmOperation(2)">
            <mat-radio-button [value]="1">Todas</mat-radio-button>
            <mat-radio-button [value]="2">Ninguna</mat-radio-button>
            <mat-radio-button [value]="3">Personalizar</mat-radio-button>
          </mat-radio-group>
          <mat-form-field *ngIf="showCustomGroups" style="margin-top: 10px">
            <mat-select placeholder="Personalizar" (selectionChange)="setChecked($event)" [(ngModel)]="selectedOptions" multiple>
              <mat-option *ngFor="let group of grupos; let i = index" [value]="group">{{group.nombre}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap">
        <span class="labels-padds" fxFlex="100">Colaborar según aportacion <i class="material-icons" (click)="showHelpButton(4)">help</i></span>
        <div fxFlex="100" class="element_row">
          <mat-checkbox [(ngModel)]="colaborationInfo.basic" (click)="$event.stopPropagation()" (ngModelChange)="confirmOperation(3)">Beginner</mat-checkbox>
          <mat-checkbox [(ngModel)]="colaborationInfo.silver" (click)="$event.stopPropagation()" (ngModelChange)="confirmOperation(4)">Standard</mat-checkbox>
          <mat-checkbox [(ngModel)]="colaborationInfo.golden" (click)="$event.stopPropagation()" (ngModelChange)="confirmOperation(5)">Expert</mat-checkbox>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel expanded="false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        INMUEBLES COMPARTIDOS
      </mat-panel-title>
      <mat-panel-description>
        <span fxShow="true" fxShow.xs="false">Puedes decidir cómo quieres compartir tus inmuebles.</span>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div fxFlex="100" fxLayoutAlign="center start" fxLayout="row wrap" class="admin-container">
      <span fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap">
        <span fxFlex="100" style="margin-bottom: -0.6em;">FlatSharing <i class="material-icons" (click)="showHelpButton(5)">help</i></span>
        <span fxFlex="100">
      <mat-form-field>
      <mat-select [(ngModel)]="colaborationInfo.flatSharing" (ngModelChange)="confirmOperation(6)">
        <mat-option [disabled]="colaborationInfo.flatLending === 1 || colaborationInfo.flatLending === 2 || colaborationInfo.flatLending === 3" [value]="1">Todas</mat-option>
        <mat-option [disabled]="colaborationInfo.flatLending === 1 || colaborationInfo.flatLending === 2" [value]="2">Sólo exclusivas</mat-option>
        <mat-option [disabled]="colaborationInfo.flatLending === 1" [value]="3">Personalizada</mat-option>
        <mat-option [value]="0">Ninguna</mat-option>
      </mat-select>
    </mat-form-field>
      <i *ngIf="colaborationInfo.flatSharing === 3" class="material-icons" (click)="viewPropertyListFlatSharing()">visibility</i>
    </span>
      </span>
      <span fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap">
        <span fxFlex="100" style="margin-bottom: -0.6em;">FlatLending <i class="material-icons" (click)="showHelpButton(6)">help</i></span>
        <span fxFlex="100">
       <mat-form-field>
      <mat-select [(ngModel)]="colaborationInfo.flatLending" (ngModelChange)="confirmOperation(7)">
        <mat-option [disabled]="colaborationInfo.flatSharing === 1 || colaborationInfo.flatSharing === 2 || colaborationInfo.flatSharing === 3" [value]="1">Todas</mat-option>
        <mat-option [disabled]="colaborationInfo.flatSharing === 1 || colaborationInfo.flatSharing === 2" [value]="2">Sólo exclusivas</mat-option>
        <mat-option [disabled]="colaborationInfo.flatSharing === 1" [value]="3">Personalizada</mat-option>
        <mat-option [value]="0">Ninguna</mat-option>
      </mat-select>
    </mat-form-field>
        <i *ngIf="colaborationInfo.flatLending === 3" class="material-icons" (click)="viewPropertyListFlatLending()">visibility</i>
      </span>
      </span>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion class="tabla-configuration" *ngIf="loaded && !isInmo" >
  <mat-expansion-panel expanded="false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        PRIVACIDAD
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="admin-container" fxLayoutAlign="center start" fxFlex="100" fxLayout="row wrap">
      <span fxFlex="33.33" fxFlex.sm="100" fxFlex.xs="100">Información de mi perfil
        <!--<i class="material-icons" (click)="showCommunityPerfil()">visibility</i>-->
        <!--<i class="material-icons" (click)="showAgencyGroup()">create</i>-->
      </span>
      <div fxFlex="33.33" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap">
        <span class="labels-padds" fxFlex="100">Tipo de perfil <i class="material-icons" (click)="showHelpButton(1)">help</i></span>
        <mat-radio-group fxFlex="100" [(ngModel)]="colaborationInfo.tipoPerfil" [ngModelOptions]="{standalone: true}" (ngModelChange)="confirmOperation(9)">
          <mat-radio-button [value]="1">Público</mat-radio-button>
          <mat-radio-button [value]="2">Privado</mat-radio-button>
        </mat-radio-group>
      </div>
      <div fxFlex="33.33" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap">
        <span class="labels-padds" fxFlex="100">Mensajes <i class="material-icons" (click)="showHelpButton(2)">help</i></span>
        <mat-radio-group fxFlex="100" [(ngModel)]="colaborationInfo.mensajes" [ngModelOptions]="{standalone: true}" (ngModelChange)="confirmOperation(10)">
          <mat-radio-button [value]="1">Todos</mat-radio-button>
          <mat-radio-button [value]="2">Solo mis colaboradores</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel expanded="false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        CONFIGURACIÓN MIEMBROS
      </mat-panel-title>
      <mat-panel-description>
        <span fxShow="true" fxShow.xs="false">Puedes decidir con quién quieres colaborar.</span>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="admin-container" fxLayoutAlign="center start" fxLayout="row wrap" fxFlex="100">
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap">
        <span class="labels-padds" fxFlex="100">Ver Inmobiliarias de mis Miembros <i class="material-icons" (click)="showHelpButton(3)">help</i></span>
        <div fxFlex="100" fxLayout="row wrap">
          <mat-radio-group fxFlex="100" style="align-self: center;" [(ngModel)]="colaborationInfo.verInmos" (ngModelChange)="confirmOperation(11)">
            <mat-radio-button [value]="1">Todos</mat-radio-button>
            <mat-radio-button [value]="2">Nadie</mat-radio-button>
            <mat-radio-button [value]="3">Mis Colaboradores</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap">
        <span class="labels-padds" fxFlex="100">Ver Inmuebles de mis Miembros <i class="material-icons" (click)="showHelpButton(3)">help</i></span>
        <div fxFlex="100" fxLayout="row wrap">
          <mat-radio-group fxFlex="100" style="align-self: center;" [(ngModel)]="colaborationInfo.verViviendas" (ngModelChange)="confirmOperation(12)">
            <mat-radio-button [value]="1">Todos</mat-radio-button>
            <mat-radio-button [value]="2">Nadie</mat-radio-button>
            <mat-radio-button [value]="3">Mis Colaboradores</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div fxFlex="33" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap">
        <span class="labels-padds" fxFlex="100">Colaborar según aportacion <i class="material-icons" (click)="showHelpButton(4)">help</i></span>
        <div fxFlex="100" class="element_row">
          <mat-checkbox [(ngModel)]="colaborationInfo.basic" (click)="$event.stopPropagation()" (ngModelChange)="confirmOperation(13)">Beginner</mat-checkbox>
          <mat-checkbox [(ngModel)]="colaborationInfo.silver" (click)="$event.stopPropagation()" (ngModelChange)="confirmOperation(14)">Standard</mat-checkbox>
          <mat-checkbox [(ngModel)]="colaborationInfo.golden" (click)="$event.stopPropagation()" (ngModelChange)="confirmOperation(15)">Expert</mat-checkbox>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<span [ngClass]="{'loader-spinner0':true, 'loader-spinner0-out':loaded}">
  <mat-progress-spinner
    class="loader-spinner"
    [color]="'primary'"
    [value]="75"
    [mode]="'indeterminate'"
    [diameter]="120"
    [strokeWidth]="15">
  </mat-progress-spinner>
</span>

<auto-plantilla #AutoPlantillaComponent></auto-plantilla>
