import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from "@angular/material/sidenav";
import { SideNavService } from "../../utils/SideNavService";
import { MonitoringActions, MonitoringDecorator } from "../../services/track-decorators";
import { Strings } from "../../../strings/strings";

@Component({
  selector: 'app-knowledge-place',
  templateUrl: './knowledge-place.component.html',
  styleUrls: ['./knowledge-place.component.scss']
})
export class KnowledgePlaceComponent implements OnInit, AfterViewInit {

  @ViewChild('sidenav') sidenav: MatSidenav;
  public over = 'over';

  public typeContainer = 'knowledgeplace';
  public tipo = false;

  constructor(
    public sidenavService: SideNavService
  ) { }

  ngOnInit(): void {
    this.voidMethodForMonitoring(null);
  }
  @MonitoringDecorator(MonitoringActions.VIEW, Strings.USERTRACKVIEWCODE.KNOWLEDGE_SEARCH)
  private voidMethodForMonitoring(id) {
    return id;
  }
  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav, 1);
  }

}
