import { Injectable } from '@angular/core';

// Serivce
import { RoutingService } from "../routing/routing.service";
import { ClientsFunctionsService } from './clients-functions.service';
import { EndpointService} from "../endpoint/endpoint.service";
import { AuthService } from "../auth/auth.service";

// Material
import {MatDialog} from "@angular/material/dialog";

// Component

import * as _ from 'lodash';
import {MatSnackBar} from "@angular/material/snack-bar";


@Injectable({
  providedIn: 'root'
})
export class CommunityFunctionsService {

  public dialogRef = null;
  private route = null;

  public dateToStringDBDate : Date;
  public dateToday: string;
  public minDate: string;

  constructor(
    private routingService : RoutingService,
    public dialog : MatDialog,
    private authService: AuthService,
    public snackBar: MatSnackBar,
    public clientsFunctionsService: ClientsFunctionsService,
    private endpointService: EndpointService) { }

  getFunction(structure, param = null) {

    console.log("functions.service")
    console.log(structure);
    console.log(param);

    let id = structure['id_function'];

    switch (id) {
    case 500:
      console.log("Community Functions Service getTodo");
      this.getTodo(param.that, param.estado);
      break;
    case 501:
      console.log("Community Functions Service getColaboradores");
      this.getColaboradores(param.that, param.estado);
      break;
    case 502:
      console.log("Community Functions Service getMisColaboradores");
      this.getMisColaboradores(param.that);
      break;
    case 503:
      console.log("Community Functions Service getSolicitudesPendientes");
      this.getSolicitudesPendientes(param.that);
      break;
    case 504:
      console.log("Community Functions Service getAll");
      this.getAll(param.that);
      break;
    case 505:
      console.log("Community Functions Service changeBuscar");
      this.changeBuscar(param.that, param.newValue);
      break;
    case 506:
      console.log("Community Functions Service mostrarPerfilInmo");
      this.mostrarPerfilInmo(param.that, param.inmo);
      break;

    }
  }

  private dataResponse(that, data) {
      that.inmos = data['response'];
      that.inmosToShow = that.inmos.slice(0, 40);
      that.inmosLoaded = true;
      that.originalInmos = that.inmos;
  }

  public getTodo(that, estado: number): void {
    that.inmosLoaded = false;
    this.endpointService.getTodo(that.id, that.type, estado, this.authService.userId).subscribe(data => {
      if (data['errorCode'] === 0) {
          this.dataResponse(that, data);
        /*that.inmos = data['response'];
        that.inmosToShow = that.inmos.slice(0, 40);
        that.inmosLoaded = true;
        that.originalInmos = that.inmos;*/
      }
    });
  }

  public getColaboradores(that, estado: number): void {
    that.inmosLoaded = false;
    this.endpointService.getBuscarColaboradores(that.id, that.type, estado, this.authService.userId).subscribe(data => {
      if (data['errorCode'] === 0) {
        this.dataResponse(that, data);
        /*this.inmos = data['response'];
        this.inmosToShow = this.inmos.slice(0, 40);
        this.inmosLoaded = true;
        this.originalInmos = this.inmos;*/
      }
    });
  }

  public getMisColaboradores(that): void {
    that.inmosLoaded = false;
    this.endpointService.getMisColaboradores(that.id, that.type, this.authService.userId).subscribe(data => {
      if (data['errorCode'] === 0) {
        this.dataResponse(that, data);

        /*this.inmos = data['response'];
        this.inmosToShow = this.inmos.slice(0, 40);
        this.inmosLoaded = true;
        this.originalInmos = this.inmos;*/
      }
    });
  }

  public getSolicitudesPendientes(that): void {
    that.inmosLoaded = false;
    this.endpointService.getSolicitudesPendientes(that.id, that.type, this.authService.userId).subscribe(data => {
      if (data['errorCode'] === 0) {
        this.dataResponse(that, data);

        /*this.inmos = data['response'];
        this.inmosToShow = this.inmos.slice(0, 40);
        this.inmosLoaded = true;
        this.originalInmos = this.inmos;*/
      }
    });
  }

  public getAll(that): void {
    that.inmosLoaded = false;
    this.endpointService.getAllInmosAndMLS(that.id, that.type, this.authService.userId).subscribe(data => {
      if (data['errorCode'] === 0) {
        this.dataResponse(that, data);

        /*this.inmos = data['response'];
        this.inmosToShow = this.inmos.slice(0, 40);
        this.inmosLoaded = true;
        this.originalInmos = this.inmos;*/
      }
    });
  }

  public changeBuscar(that, newValue): void {
    newValue = newValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    that.inmosLoaded = false;
    that.inmos = that.originalInmos.filter(inmo =>
      (inmo.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()).includes(newValue)
      //|| (inmo.slogan.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()).includes(newValue)
    );
    that.inmosToShow = that.inmos.slice(0, 40);
    that.inmosLoaded = true;
  }

  public mostrarPerfilInmo(that, inmo: any) {
    let elmnt = document.getElementById("containerbuscar");
    elmnt.scrollTop = 0;
    that.showPerfilInmo = true;
    that.inmoURLperfil = inmo.url_canonica;
    that.inmoURLperfilId = inmo.id;
    that.isInmoPerfil = (inmo.esMLS === 0) ? true : false;
  }
}
