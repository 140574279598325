<div class="swapping">
  <span fxLayout="row wrap">
    <span fxFlex="100" class="titulo">Selecciona la cuenta con la que quieres acceder.</span>
    <span fxFlex="100" class="sub-titulo">Más adelante podrás cambiarla.</span>
  </span>
  <mat-list>
    <mat-list-item *ngFor="let account of authService.accountsGeneric; let i = index">
      <span fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center" class="the-each-row">
        <span fxFlex="85" fxLayoutAlign="start" (click)="selectOption(i)">
          <span class="my-image">
            <img class="my-image2" src="https://movin.cloud/assets/img/default/logo-inmo.png">
          </span>
          <span fxLayout="row wrap" class="my-domains">
            <span class="my-domains2">{{this.authService.accountsGeneric[i].nombre}} ({{this.authService.accountsGeneric[i].tipoNombre}})</span>
            <span *ngIf="this.authService.accountsGeneric[i].active" fxFlex="100" class="activado">Activo</span>
          </span>
        </span>
        <span fxFlex="15" class="adeu-adeu red" (click)="deleteAccount(i)"> <span fxHide="true" fxHide.sm="false" fxHide.xs="false" class="material-icons">logout </span> <span fxHide.sm="true" fxHide.xs="true">Abandonar</span></span>
      </span>
    </mat-list-item>
  </mat-list>
  <div class="generic-buttons-blue">
    <button (click)="closePopup()">Cancelar</button>
  </div>
</div>
