<mat-nav-list>
  <a *ngIf="!isAdmin" mat-list-item (click)="getAction(1)">
    <span mat-line>Hacer administrador</span>
  </a>

   <a *ngIf="isAdmin" mat-list-item (click)="getAction(2)">
    <span mat-line>Quitar como administrador</span>
  </a>

  <a mat-list-item (click)="getAction(3)">
    <span mat-line>Eliminar usuario del grupo</span>
  </a>

  <a mat-list-item (click)="getAction(4)">
    <span mat-line>Cancelar</span>
  </a>
</mat-nav-list>
