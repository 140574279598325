import { Component, Inject, HostListener, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventType, categoryTypes, defaultColors } from '../../objects/category';
import { Validators, UntypedFormControl } from '@angular/forms';
import { Strings } from 'strings/strings';

@Component({
	selector: 'color-picker-dialog',
	templateUrl: './color-picker-dialog.html',
	styleUrls: ['./color-picker-dialog.scss']
})

export class ColorPickerDialog implements OnInit {

	public strings = Strings.CALENDAR;

	editType: EventType;

	nameCategoryControl = new UntypedFormControl('', [Validators.required]);
	colorCategoryControl = new UntypedFormControl('', [Validators.required, Validators.pattern('(^#[0-9A-Fa-f]{6}$)|(^#[0-9A-Fa-f]{3}$)')]);
  isChecked: boolean = false;
  signable: boolean = false;
  portalVisitCompatibility: boolean = false;

	constructor(
		public dialogRef: MatDialogRef<ColorPickerDialog>,
		@Inject(MAT_DIALOG_DATA) public type: EventType) {
		if (type) {
			this.editType = type;
			this.nameCategoryControl.setValue(this.editType.name);
			this.colorCategoryControl.setValue(this.editType.color);
			this.isChecked = Boolean(this.editType.isPrivate);
			this.signable = Boolean(this.editType.firmar);
			this.portalVisitCompatibility = Boolean(this.editType.control_calendario);
		}
	}

	ngOnInit(): void {
		this.nameCategoryControl.valueChanges.subscribe(value => { this.categoryNameExists(value) });
	}

	onNoClick(): void {
		this.dialogRef.close();
  }

	get defaultColors() { return defaultColors; }


	getNameErrorMessage() {
		if (this.nameCategoryControl.hasError('required'))
			return this.strings.COLOR_PICKER_NAME_ERROR_MESSAGE;
		else if (this.categoryNameExists(this.nameCategoryControl.value))
			return this.strings.COLOR_PICKER_COLOR_ERROR_MESSAGE;
		return '';
	}

	categoryNameExists(name: string) {
		if (name == null)
			return false;
		let bool = false;
		categoryTypes.forEach(category => category.types.forEach(type => { if (type.name == name) bool = true; }));
		if (bool)
			this.nameCategoryControl.setErrors({ 'invalid': true });
		else
		this.nameCategoryControl.setErrors(null);
		return bool;
	}

	getColorErrorMessage() {
		return this.colorCategoryControl.hasError('required') ? 'You must enter a color' : this.colorCategoryControl.hasError('pattern') ? 'Invalid Color!' : '';
	}

  checkPrivateStatus(event: boolean) {
    this.isChecked = event;
  }
}
