import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: "root",
})
export class PaycometService {

  private url = 'https://sis-t.redsys.es:25443/sis/realizarPago';

  constructor(private http: HttpClient) { }

  getTransactionDetail(transactionId: string) {
    return this.http.get(`https://api.paycomet.com/gateway/json/operationinfo.php?transaction_id=${transactionId}`);
  }

  pagar(data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    const options = { headers: headers };
    return this.http.post(this.url, this.toFormUrlEncoded(data), options);
  }

  private toFormUrlEncoded(obj: any): string {
    let formBody = [];
    for (let property in obj) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(obj[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    return formBody.join('&');
  }

}