<!-- structure[e.key]['class_custom'] ---- [ngStyle]="styles[e.key]" -->
<ng-container *ngIf="structure[index] && structure[index]['child']">

  <ng-container *ngIf="structure[this.index]['type'] === 'simple'">
    <mat-list>
      <ng-container *ngFor="let e of structure[index]['child'] | keyvalue : genericService.returnZero">
        <mat-list-item
          *ngIf="structure[index]['child'][e.key] && structure[index]['child'][e.key]['id_functional_type'] == 105 && structure[index]['child'][e.key] && genericService.evaluateIfElements(structure[index], e.key) && structure[index]['child'][e.key]['id_functional_status_general'] !== 2"
          [fxHide]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || structure[index]['child'][e.key]['hide'] == 1"               
          [fxHide.xs]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_xs']=='true' && structure[index]['child'][e.key]['hide_xs'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_xs'] == 1"
          [fxHide.sm]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_s']=='true' && structure[index]['child'][e.key]['hide_s'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_s'] == 1"
          [fxHide.md]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_m']=='true' && structure[index]['child'][e.key]['hide_m'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_m'] == 1"
          [fxHide.lg]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_l']=='true' && structure[index]['child'][e.key]['hide_l'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_l'] == 1"
          [fxHide.xl]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_xl']=='true' && structure[index]['child'][e.key]['hide_xl'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_xl'] == 1"

          [ngClass]="{'mkt-no-available':structure[index]['child'][e.key]['id_functional_status_mkt'] != 1, 'highlight': structure[index]['child'][e.key]['id_functional_area'] == oldId}"
          [ngStyle]="structure[index]['child'][e.key]['styleParsed']['style']"
          [matTooltip]="structure[index]['child'][e.key]['tooltip']"
          [id]="structure[index]['child'][e.key]['id_functional_area']">

          <span *ngIf="structure[index]['child'][e.key]['label']">
            <mat-icon *ngIf="structure[index]['child'][e.key]['icon']" class="{{structure[index]['child'][e.key]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
              structure[index]['child'][e.key]['icon_type']=='Filled' ? 'material-icons' :
              structure[index]['child'][e.key]['icon_type'] =='Round' ? 'material-icons-round' :
              structure[index]['child'][e.key]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
              structure[index]['child'][e.key]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}"> {{ structure[index]['child'][e.key]['icon'] }}</mat-icon>
            <span [innerHTML]="structure[index]['child'][e.key]['label'] | safeHtml"></span>
          </span>

          <ng-container *ngIf="structure[index]['child'][e.key]['child']">
            <app-generic [structure]="structure[index]['child'][e.key]" [param]="param"></app-generic>
          </ng-container>
  
        </mat-list-item>
      </ng-container>
    </mat-list>
  </ng-container>
  
  <ng-container *ngIf="structure[this.index]['type'] === 'navigation'">
    <mat-nav-list>
      <ng-container *ngFor="let e of structure[index]['child'] | keyvalue : genericService.returnZero">
        <mat-list-item
          *ngIf="structure[index]['child'][e.key] && structure[index]['child'][e.key]['id_functional_type'] == 105 && genericService.evaluateIfElements(structure[index], e.key) && structure[index]['child'][e.key]['id_functional_status_general'] !== 2"

          [fxHide]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || structure[index]['child'][e.key]['hide'] == 1"               
          [fxHide.xs]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_xs']=='true' && structure[index]['child'][e.key]['hide_xs'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_xs'] == 1"
          [fxHide.sm]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_s']=='true' && structure[index]['child'][e.key]['hide_s'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_s'] == 1"
          [fxHide.md]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_m']=='true' && structure[index]['child'][e.key]['hide_m'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_m'] == 1"
          [fxHide.lg]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_l']=='true' && structure[index]['child'][e.key]['hide_l'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_l'] == 1"
          [fxHide.xl]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_xl']=='true' && structure[index]['child'][e.key]['hide_xl'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_xl'] == 1"

          [ngClass]="{'mkt-no-available':structure[index]['child'][e.key]['id_functional_status_mkt'] != 1, 'highlight': structure[index]['child'][e.key]['id_functional_area'] == oldId}"
          [ngStyle]="structure[index]['child'][e.key]['styleParsed']['style']"
          [matTooltip]="structure[index]['child'][e.key]['tooltip']"
          [id]="structure[index]['child'][e.key]['id_functional_area']">
          
          <a *ngIf="(structure[index]['child'][e.key]['url'] | slice:0:1) == '#'" (click)="scrollTo(structure[index]['child'][e.key]['url'])" ngClass]="{ 'highlight': structure[index]['child'][e.key]['id_functional_area'] == oldId }">
              <span *ngIf="structure[index]['child'][e.key]['label']">
                <mat-icon *ngIf="structure[index]['child'][e.key]['icon']" class="{{structure[index]['child'][e.key]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
                structure[index]['child'][e.key]['icon_type']=='Filled' ? 'material-icons' :
                structure[index]['child'][e.key]['icon_type'] =='Round' ? 'material-icons-round' :
                structure[index]['child'][e.key]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
                structure[index]['child'][e.key]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}"> {{ structure[index]['child'][e.key]['icon'] }}</mat-icon>
                <span [innerHTML]="structure[index]['child'][e.key]['label'] | safeHtml"></span>
              </span>
          </a>

          <a *ngIf="!(structure[index]['child'][e.key]['url'] | slice:0:1) == '#'" matLine target="_blank" href="{{ structure[index]['child'][e.key]['url'] | slice:0:1 }}">
            <span *ngIf="structure[index]['child'][e.key]['label']">
              <mat-icon *ngIf="structure[index]['child'][e.key]['icon']" class="{{structure[index]['child'][e.key]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
              structure[index]['child'][e.key]['icon_type']=='Filled' ? 'material-icons' :
              structure[index]['child'][e.key]['icon_type'] =='Round' ? 'material-icons-round' :
              structure[index]['child'][e.key]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
              structure[index]['child'][e.key]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}"> {{ structure[index]['child'][e.key]['icon'] }}</mat-icon>
              <span [innerHTML]="structure[index]['child'][e.key]['label'] | safeHtml"></span>
            </span>
          </a>

          <ng-container *ngIf="structure[index]['child'][e.key]['child']">
            <app-generic [structure]="structure[index]['child'][e.key]" [param]="param"></app-generic>
          </ng-container>
          
        </mat-list-item>
      </ng-container>
    </mat-nav-list>
  </ng-container>
  
  <ng-container *ngIf="structure[this.index]['type'] === 'action'">
    <mat-action-list>
      <ng-container *ngFor="let e of structure[index]['child'] | keyvalue : genericService.returnZero">
        <mat-list-item #item (click)="onClickItem(structure[index]['child'][e.key])" 
          *ngIf="structure[index]['child'][e.key] && structure[index]['child'][e.key]['id_functional_type'] == 105 && genericService.evaluateIfElements(structure[index], e.key) && structure[index]['child'][e.key]['id_functional_status_general'] !== 2"
          
          [fxHide]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || structure[index]['child'][e.key]['hide'] == 1"               
          [fxHide.xs]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_xs']=='true' && structure[index]['child'][e.key]['hide_xs'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_xs'] == 1"
          [fxHide.sm]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_s']=='true' && structure[index]['child'][e.key]['hide_s'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_s'] == 1"
          [fxHide.md]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_m']=='true' && structure[index]['child'][e.key]['hide_m'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_m'] == 1"
          [fxHide.lg]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_l']=='true' && structure[index]['child'][e.key]['hide_l'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_l'] == 1"
          [fxHide.xl]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_xl']=='true' && structure[index]['child'][e.key]['hide_xl'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_xl'] == 1"

          [ngClass]="{'mkt-no-available':structure[index]['child'][e.key]['id_functional_status_mkt'] != 1, 'highlight': structure[index]['child'][e.key]['id_functional_area'] == oldId}"
          [ngStyle]="structure[index]['child'][e.key]['styleParsed']['style']"
          [matTooltip]="structure[index]['child'][e.key]['tooltip']"
          [id]="structure[index]['child'][e.key]['id_functional_area']">

          <span *ngIf="structure[index]['child'][e.key]['label']">
            <mat-icon *ngIf="structure[index]['child'][e.key]['icon']" class="{{structure[index]['child'][e.key]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
            structure[index]['child'][e.key]['icon_type']=='Filled' ? 'material-icons' :
            structure[index]['child'][e.key]['icon_type'] =='Round' ? 'material-icons-round' :
            structure[index]['child'][e.key]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
            structure[index]['child'][e.key]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}"> {{ structure[index]['child'][e.key]['icon'] }}</mat-icon>
            <span [innerHTML]="structure[index]['child'][e.key]['label'] | safeHtml"></span>
          </span>

          <ng-container *ngIf="structure[index]['child'][e.key]['child']">
            <app-generic [structure]="structure[index]['child'][e.key]" [param]="param"></app-generic>
          </ng-container>

        </mat-list-item>
      </ng-container>
    </mat-action-list>
  </ng-container>
  
  <ng-container *ngIf="structure[this.index]['type'] === 'selection'">
    <mat-selection-list>
      <ng-container *ngFor="let e of structure[index]['child'] | keyvalue : genericService.returnZero">
        <mat-list-option
        *ngIf="structure[index]['child'][e.key] && structure[index]['child'][e.key]['id_functional_type'] == 105 && genericService.evaluateIfElements(structure[index], e.key) && structure[index]['child'][e.key]['id_functional_status_general'] !== 2"

        [fxHide]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || structure[index]['child'][e.key]['hide'] == 1"               
        [fxHide.xs]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_xs']=='true' && structure[index]['child'][e.key]['hide_xs'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_xs'] == 1"
        [fxHide.sm]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_s']=='true' && structure[index]['child'][e.key]['hide_s'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_s'] == 1"
        [fxHide.md]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_m']=='true' && structure[index]['child'][e.key]['hide_m'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_m'] == 1"
        [fxHide.lg]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_l']=='true' && structure[index]['child'][e.key]['hide_l'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_l'] == 1"
        [fxHide.xl]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_xl']=='true' && structure[index]['child'][e.key]['hide_xl'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_xl'] == 1"

        [ngClass]="{'mkt-no-available':structure[index]['child'][e.key]['id_functional_status_mkt'] != 1, 'highlight': structure[index]['child'][e.key]['id_functional_area'] == oldId}"
        [ngStyle]="structure[index]['child'][e.key]['styleParsed']['style']"
        [matTooltip]="structure[index]['child'][e.key]['tooltip']"
        [id]="structure[index]['child'][e.key]['id_functional_area']">
          
         <span *ngIf="structure[index]['child'][e.key]['label']">
            <mat-icon *ngIf="structure[index]['child'][e.key]['icon']" class="{{structure[index]['child'][e.key]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
            structure[index]['child'][e.key]['icon_type']=='Filled' ? 'material-icons' :
            structure[index]['child'][e.key]['icon_type'] =='Round' ? 'material-icons-round' :
            structure[index]['child'][e.key]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
            structure[index]['child'][e.key]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}"> {{ structure[index]['child'][e.key]['icon'] }}</mat-icon>
            <span [innerHTML]="structure[index]['child'][e.key]['label'] | safeHtml"></span>
          </span>

          <ng-container *ngIf="structure[index]['child'][e.key]['child']">
            <app-generic [structure]="structure[index]['child'][e.key]" [param]="param"></app-generic>
          </ng-container>    

        </mat-list-option>
      </ng-container>
    </mat-selection-list>
  </ng-container>
  
  <ng-container *ngIf="structure[this.index]['type'] === 'ordered'">
    <ol style="padding-left: 40px;">
      <ng-container *ngFor="let e of structure[index]['child'] | keyvalue : genericService.returnZero">
        <li
        *ngIf="structure[index]['child'][e.key] && structure[index]['child'][e.key]['id_functional_type'] == 105 && genericService.evaluateIfElements(structure[index], e.key) && structure[index]['child'][e.key]['id_functional_status_general'] !== 2"

        [fxHide]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || structure[index]['child'][e.key]['hide'] == 1"               
        [fxHide.xs]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_xs']=='true' && structure[index]['child'][e.key]['hide_xs'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_xs'] == 1"
        [fxHide.sm]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_s']=='true' && structure[index]['child'][e.key]['hide_s'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_s'] == 1"
        [fxHide.md]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_m']=='true' && structure[index]['child'][e.key]['hide_m'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_m'] == 1"
        [fxHide.lg]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_l']=='true' && structure[index]['child'][e.key]['hide_l'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_l'] == 1"
        [fxHide.xl]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_xl']=='true' && structure[index]['child'][e.key]['hide_xl'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_xl'] == 1"

        [ngClass]="{'mkt-no-available':structure[index]['child'][e.key]['id_functional_status_mkt'] != 1, 'highlight': structure[index]['child'][e.key]['id_functional_area'] == oldId}"
        [ngStyle]="structure[index]['child'][e.key]['styleParsed']['style']"
        [matTooltip]="structure[index]['child'][e.key]['tooltip']"
        [id]="structure[index]['child'][e.key]['id_functional_area']">
        
          <span *ngIf="structure[index]['child'][e.key]['label']">
            <mat-icon *ngIf="structure[index]['child'][e.key]['icon']" class="{{structure[index]['child'][e.key]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
            structure[index]['child'][e.key]['icon_type']=='Filled' ? 'material-icons' :
            structure[index]['child'][e.key]['icon_type'] =='Round' ? 'material-icons-round' :
            structure[index]['child'][e.key]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
            structure[index]['child'][e.key]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}"> {{ structure[index]['child'][e.key]['icon'] }}</mat-icon>
            <span [innerHTML]="structure[index]['child'][e.key]['label'] | safeHtml"></span>
          </span>

          <ng-container *ngIf="structure[index]['child'][e.key]['child']">
            <app-generic *ngIf="structure[index]['child'][e.key]['child']" [structure]="structure[index]['child'][e.key]" [param]="param"></app-generic>
          </ng-container>

        </li>
      </ng-container>
    </ol>
  </ng-container>
  
  <ng-container *ngIf="structure[this.index]['type'] === 'unordered'">
    <ul style="padding-left: 26px;">
      <ng-container *ngFor="let e of structure[index]['child'] | keyvalue : genericService.returnZero">
        <li *ngIf="structure[index]['child'][e.key] && structure[index]['child'][e.key]['id_functional_type'] == 105 && genericService.evaluateIfElements(structure[index], e.key) && structure[index]['child'][e.key]['id_functional_status_general'] !== 2"

        [fxHide]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || structure[index]['child'][e.key]['hide'] == 1"               
        [fxHide.xs]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_xs']=='true' && structure[index]['child'][e.key]['hide_xs'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_xs'] == 1"
        [fxHide.sm]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_s']=='true' && structure[index]['child'][e.key]['hide_s'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_s'] == 1"
        [fxHide.md]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_m']=='true' && structure[index]['child'][e.key]['hide_m'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_m'] == 1"
        [fxHide.lg]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_l']=='true' && structure[index]['child'][e.key]['hide_l'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_l'] == 1"
        [fxHide.xl]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_xl']=='true' && structure[index]['child'][e.key]['hide_xl'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_xl'] == 1"

        [ngClass]="{'mkt-no-available':structure[index]['child'][e.key]['id_functional_status_mkt'] != 1, 'highlight': structure[index]['child'][e.key]['id_functional_area'] == oldId}"
        [ngStyle]="structure[index]['child'][e.key]['styleParsed']['style']"
        [matTooltip]="structure[index]['child'][e.key]['tooltip']"
        [id]="structure[index]['child'][e.key]['id_functional_area']">
        
          <span *ngIf="structure[index]['child'][e.key]['label']">
            <mat-icon *ngIf="structure[index]['child'][e.key]['icon']" class="{{structure[index]['child'][e.key]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
            structure[index]['child'][e.key]['icon_type']=='Filled' ? 'material-icons' :
            structure[index]['child'][e.key]['icon_type'] =='Round' ? 'material-icons-round' :
            structure[index]['child'][e.key]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
            structure[index]['child'][e.key]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}"> {{ structure[index]['child'][e.key]['icon'] }}</mat-icon>
            <span [innerHTML]="structure[index]['child'][e.key]['label'] | safeHtml"></span>
          </span>
          
          <ng-container *ngIf="structure[index]['child'][e.key]['child']">
            <app-generic [structure]="structure[index]['child'][e.key]" [param]="param"></app-generic>
          </ng-container>

        </li>
      </ng-container>
    </ul>
  </ng-container>

</ng-container>