<div class="dialog-container" style="text-align: right">
  <button class="close-button" mat-button (click)="onNoClick()"><i class="material-icons">close</i></button>
</div>

<div class="alert alert-info">
  Indique el motivo por el que desea marcar este evento como cancelado
</div>

<div class="row-expansion1">
  <mat-form-field appearance="outline">
    <textarea required matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" placeholder="Motivo de cancelación" [(ngModel)]="tempComment" (ngModelChange)="tempComment = $event"></textarea>
  </mat-form-field>
</div>

<div>
  <button class="btns-filter btn-outline-success btn1" cdkFocusInitial style="margin: 0.5em" (click)="setMotivoCancelacion()" [disabled]="isInvalid()">
    <i style="margin-right: 0.3em;" class="material-icons">check</i>
    POSPONER
  </button>
</div>
