import {
  Component,
  OnDestroy,
  ViewChild,
  OnInit,
  Input,
  ElementRef
} from '@angular/core';
import { isSameDay, isSameMonth, parse } from 'date-fns';
import {
  CalendarMonthViewDay,
  CalendarEventTitleFormatter,
  CalendarEventTimesChangedEvent,
  CalendarDayViewBeforeRenderEvent,
  CalendarView,
  DAYS_OF_WEEK,
  CalendarDateFormatter
} from 'angular-calendar';
import { MatDialog } from '@angular/material/dialog';
import { Strings } from '../../../../strings/strings';
import { Subject } from 'rxjs';
import { EndpointService } from '../../../services/endpoint/endpoint.service';
import { AuthService } from '../../../services/auth/auth.service';
import {CustomEvent, CustomEventAction} from '../../../activities/objects/custom-event.component'
import { categoryTypes, EventType, setCategories } from '../../../activities/objects/category';
import { ManageCategoriesDialog } from './subcomponents/manage-categories-dialog/manage-categories-dialog';
import { MatDatepickerInputEvent, MatDatepicker } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';
import { AllEventsListDialog } from './subcomponents/all-events-list-dialog/all-events-list-dialog';
import {DeviceDetectorService} from 'ngx-device-detector';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CustomEventTitleFormatter } from './custom-event-title-formatter.provider';
import {CustomDateFormatter} from './custom-date-formatter.provider';
import {BottomSheetFinishedEvents} from "./subcomponents/bottom-sheet-finished-events/bottom-sheet-finished-events";
import {BottomSheetSharedCalendar} from "./subcomponents/bottom-sheet-shared-calendar/bottom-sheet-shared-calendar";
import {SimpleGroup} from "../../../activities/objects/simple-group";
import {NotificationsComponent} from "./subcomponents/notifications/notifications.component";
import {AdviceReportService} from "../../../services/advice-report/advice-report.service";
import {AgendaSettingsComponent} from "./subcomponents/agenda-settings/agenda-settings.component";
import { FunctionsService } from 'app/services/functions/functions.service';
import { GenericService } from 'app/services/generic/generic.service';
import { MessageDialogComponent } from 'app/utils/message-dialog/message-dialog.component';
import { ActivitiesService } from 'app/services/activities/activities.service';
import { ChooseAccountCalendarComponent } from './subcomponents/choose-account-calendar/choose-account-calendar.component';
// Joan: Error després de la migració de 9 a 14 -> Ho comento perquè em sembla que no es fa servir
//import { THIS_EXPR } from '@angular/compiler/src/interns/interns_ast';

export interface Periods {
  value: string;
  viewValue: string;
}

export interface Notifications {
  id: number
  tiempo: string;
  timing: number;
}

export interface Estado {
  id: number;
  estado: string;
}

/// <reference path="../../node_modules/@types/gapi/index.d.ts" />
declare var gapi : any;

@Component({
  selector: 'app-agenda-custom',
  styleUrls: ['./agenda-custom.component.scss'],
  templateUrl: './agenda-custom.component.html',
  providers: [
    { provide: CalendarEventTitleFormatter, useClass: CustomEventTitleFormatter },
    { provide: CalendarDateFormatter, useClass: CustomDateFormatter },
  ],
})

export class HomeComponent implements OnInit, OnDestroy {

  @ViewChild('filterInput') filterInput: ElementRef | undefined;
  @Input() structure = null;
  @Input() index = null;
  @Input() struct = null;

  public strings = Strings.CALENDAR;

  view: CalendarView = CalendarView.Week;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  previousMonth: Date = new Date();

  actions: CustomEventAction[] = [];

  //customEventsTotal: CustomEvent[] = [];

  customEvents: CustomEvent[] = [];

  deepCopyCustom;

  externalEvents: CustomEvent[] = [];

  nextEvents: CustomEvent[] = [];

  pendingEvents: CustomEvent[] = [];

  groups: SimpleGroup[] = [];
  // locale = 'en';
  locale = 'es';

  isList = false;

  weekStartsOn: number = (this.authService && this.authService.dia_inicio_calendario) ? this.authService.dia_inicio_calendario : 1;

  weekendDays: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];

  // daysInWeek = 7;
  /*fInicio: boolean;
  fFinal: boolean;*/
  dayStartHour: number;
  dayEndHour: number;

  // Diccionari per saber quins mesos estan calculats ja i no cal calcularlos un altre cop
  monthsCalculated = {};

  usersActive = [];

  filtersSharedCalendar = {};

  private destroy$ = new Subject();

  activeDayIsOpen = false;

  refresh: Subject<any> = new Subject();

  filterSelectedCategories: { id: number, value: boolean, private: number }[] = [];
  foreignCategories : boolean = false; // Joan: Ho he afegit

  showAllActivities: boolean = false;
  showShareCalendar: boolean = false;
  showClients: boolean = false;
  showUsers: boolean = false;
  showRememberConfig: boolean = false;
  
  monthToFilterEvents: string;
  yearToFilter: string;
  monthToFilter: string;

  // Joan
  //filterSelectedUsers: { id: number, value: boolean, name: string, userPic: string, accountName: string, accountId: number }[] = [];
  filterSelectedUsers: { id: number, value: boolean, name: string, userPic: string, accountName: string, accountIds: number[] }[] = [];
  // Joan: No es fa servir. S'omple i es passa a BottomSheetSharedCalendar però allà no es fa servir
  //myEventsFilter: { id: number, value: boolean, user: string };
  myGroupEventsFilter: { userId: number, id: number | any, value: boolean, user: string, type: string | any, name: string, type_account: number }[];

  myAccounts : any = [];
  googleCalendars : any;
  microsoftCalendars : any;

  deepCopy: any;

  public options = { year: '2-digit', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'};
  private pickerDate;
  @ViewChild('picker') datePicker: MatDatepicker<any>;
  @ViewChild('notifications') notifications: NotificationsComponent;
  loaded = false;
  structureListGeneric = [];
  filterSearch = false;
  showSearchBar = false;
  value = "";
  lastValue = "";

  numCategoriesFiltersSelected: number = 0;
  numAccountsFiltersSelected: number = 0;
  numUsersFiltersSelected: number = 0;

  @ViewChild('loginRef', {static: true }) loginElement: ElementRef;

  constructor(public dialog: MatDialog,
              private endpointService: EndpointService,
              public authService: AuthService,
              public activitiesService: ActivitiesService,
              public snackBar: MatSnackBar,
              public datepipe: DatePipe,
              private _bottomSheet: MatBottomSheet,
              public deviceService: DeviceDetectorService,
              public functionsService: FunctionsService,
              public genericService: GenericService,
              public adviceReport: AdviceReportService) {
  }

  ngOnInit(): void {
    this.activitiesService.customEventsTotal = [];
    this.activitiesService.customEventsGeneral = [];
    this.checkStructure();
    //this.structureListGeneric = this.genericService.findElementWithId(48471, false, false);
    if(this.structureListGeneric['child']) this.structureListGeneric = this.structureListGeneric['child'];
    const filterTextAgenda = JSON.parse(localStorage.getItem('filterTextAgenda'));
    if(filterTextAgenda != null) {
      this.value = filterTextAgenda;
      this.lastValue = filterTextAgenda;
    }

    const initFromAgenda = JSON.parse(localStorage.getItem('initFromAgenda'));
    if(initFromAgenda != null) {
      this.setView(initFromAgenda);
    } else {
      if (this.deviceService.isDesktop()) {
        this.setView(CalendarView.Week);
      } else {
        this.setView(CalendarView.Day);
      }
    }

    // const initDateAgenda = JSON.parse(localStorage.getItem('initDateAgenda'));
    const initDateAgenda = null;
    if(initDateAgenda != null) {
      this.viewDate = new Date(initDateAgenda);
    }

    if (this.activitiesService.showOnlyMyAgenda == null) {
      // Obtenir-lo del localStorage, si existeix. Si no, crear-lo, per defecte a true
      const tmpLS = JSON.parse(localStorage.getItem('show_only_my_agenda'));
      if (tmpLS == null) {
        this.activitiesService.showOnlyMyAgenda = true;
        localStorage.setItem('show_only_my_agenda', JSON.stringify('true'));
      }
      else {
        this.activitiesService.showOnlyMyAgenda = (tmpLS.trim().toLowerCase() == 'true' ? true : false);
      }
    }

    this.setAccountsFilter(); // Joan: Omple myAccounts i myGroupEventsFilter
    this.setUsersFilter(); // Joan: Omple filterSelectedUsers
    // Joan: La canvio per la de sota que estava comentada, perquè no tinc clar que setAllCategoriesAndFilters aporti més que setCategoriesAndFilters
    //this.setAllCategoriesAndFilters();
    this.setCategoriesAndFilters(); // Joan: Omple filterSelectedCategories i crida getGroupsAndEvents (que omple groups)
    this.setCalendarsSelected();

    // Joan: L'havia afegit però potser no cal perquè es fa al final de setEvents
    //this.filterCalendarEvents();
  }

  ngOnDestroy() {
    localStorage.setItem('initFromAgenda', JSON.stringify(this.view));
    // localStorage.setItem('initDateAgenda', JSON.stringify(this.viewDate));
    this.destroy$.next();
  }

  // Carregaré el mes anterior i el següent al que estic per si l'usuari canvia de mes que no hi hagi delay
  beforeMonthViewRender({ body }: {  body: CalendarMonthViewDay[] }): void {
    // Cada cop que es canvii de mes es carregarà de nou els events
    let actualDate = body[15].date.toISOString().substr(0, 19).replace('T', ' ');
    let actualMonth = (parseInt(actualDate.substr(5,2)) + 1 == 13 ? 1 : parseInt(actualDate.substr(5,2)) + 1);
    let actualYear = (parseInt(actualDate.substr(5,2)) + 1 == 13 ? parseInt(actualDate.substr(0,4)) + 1 : parseInt(actualDate.substr(0,4)));
    // Calculo el mes següent
    if(this.monthsCalculated[actualYear.toString() + '-' + actualMonth.toString()] == undefined){
      this.monthToFilter = actualMonth.toString();
      this.yearToFilter = actualYear.toString();
      this.setEvents(actualMonth.toString(), actualYear.toString());
    }

    // Calculo el mes anterior
    actualMonth = (parseInt(actualDate.substr(5,2)) - 1 == 0 ? 12 : parseInt(actualDate.substr(5,2)) - 1);
    actualYear = (parseInt(actualDate.substr(5,2)) - 1 == 0 ? parseInt(actualDate.substr(0,4)) - 1 : parseInt(actualDate.substr(0,4)));
    
    if(this.monthsCalculated[actualYear.toString() + '-' + actualMonth.toString()] == undefined){
      this.monthToFilter = actualMonth.toString();
      this.yearToFilter = actualYear.toString();
      this.setEvents(actualMonth.toString(), actualYear.toString());
    }

    // Aqui pinta cada event en la celda que li correspon del mes
    body.forEach(cell => {
      const groups: any = {};
      cell.events.forEach((event: CustomEvent) => {
        groups[event.type.color] = groups[event.type.color] || [];
        groups[event.type.color].push(event);
      });
      cell['eventGroups'] = Object.entries(groups);
    });
  }

  // Si estic en l'ultima setamana del mes em calculara els events del mes següent
  // Si estic en la primera setmana del mes em calculara els events del mes anterior
  beforeMonthViewRenderWeek(day: CalendarDayViewBeforeRenderEvent): void {
    // Cada cop que es canvii de mes es carregarà de nou els events
    // Miro la setmana següent
    let dateDay = day.period.end;
    dateDay.setDate(day.period.end.getDate() + 7);
    let fecha = dateDay.toISOString().substr(0, 19).replace('T', ' ');
    let month = fecha.substr(5,2);
    let year = fecha.substr(0,4);
    // Miro si el seguent mes esta calculat o no
    if(this.monthsCalculated[year + '-' + month] == undefined){
      this.monthToFilter = month;
      this.yearToFilter = year;
      this.setEvents(month, year);
    }
    // Miro la setmana anterior
    dateDay = day.period.start;
    fecha = dateDay.toISOString().substr(0, 19).replace('T', ' ');
    month = fecha.substr(5,2);
    year = fecha.substr(0,4);
    // Miro si l'anterior mes esta calculat o no
    if(this.monthsCalculated[year + '-' + month] == undefined){
      this.monthToFilter = month;
      this.yearToFilter = year;
      this.setEvents(month, year);
    }
  }

  // Si estic en l'ultim dia del mes em calculara els events del mes següent
  // Si estic en el primer dia del mes em calculara els events del mes anterior
  beforeMonthViewRenderDay(day: CalendarDayViewBeforeRenderEvent): void {
    // Cada cop que es canvii de mes es carregarà de nou els events
    let dateDay = day.period.end;
    dateDay.setDate(day.period.end.getDate() + 1);
    let fecha = dateDay.toISOString().substr(0, 19).replace('T', ' ');
    let month = fecha.substr(5,2);
    let year = fecha.substr(0,4);
    // Miro si el seguent mes esta calculat o no
    if(this.monthsCalculated[year + '-' + month] == undefined){
      this.monthToFilter = month;
      this.yearToFilter = year;
      this.setEvents(month, year);
    }
    // Miro el dia anterior
    dateDay = day.period.start;
    dateDay.setDate(day.period.start.getDate() - 1);
    fecha = dateDay.toISOString().substr(0, 19).replace('T', ' ');
    month = fecha.substr(5,2);
    year = fecha.substr(0,4);
    // Miro si el seguent mes esta calculat o no
    if(this.monthsCalculated[year + '-' + month] == undefined){
      this.monthToFilter = month;
      this.yearToFilter = year;
      this.setEvents(month, year);
    }
  }

  filterCalendarActivities(){
    // Filtro per les empresas o marques que estan a true
    let filterChecked = this.myGroupEventsFilter.filter(acc=> (acc.value == true));
    this.myGroupEventsFilter.forEach(group=> {
      this.filtersSharedCalendar[group.name+group.type] = group.value; 
    });
    
    let filteredDictionary = {};
    let elementsToFilter = [];
    for (let i in filterChecked) {
      let data = filterChecked[i];
      filteredDictionary[data.name] = data;
    }
    // Busco els elements al accountGeneric per tenir la seva informació
    this.authService.accountsGeneric.forEach(acc => {
      if(filteredDictionary[acc['nombre']] !== undefined)
        elementsToFilter.push(acc);
    });
    return elementsToFilter;
  }

  /* Las opciones de filtrado son optativas, si no recibo nada por parametro cargaré las correspondientes
  al mes en el que estoy y la empresa con la que he inciado sesion, si no, significa que es un filtrado especial
  de una nueva empresa que ha filtrado el usuario teniendo ya sus eventos cargados
  */
  setEvents(monthToFilter = null, yearToFilter = null, empresaId = null) {
    let tempArray: CustomEvent[] = [];
    let pendingEventsArray: CustomEvent[] = [];
    let tempExternalArray: CustomEvent[] = [];
    let nextEventsArray: CustomEvent[] = [];
    let customEventsMonth: CustomEvent[] = [];
    const date = new Date();
    
    let elementsToFilter = this.filterCalendarActivities();
    let dateFilters = {};

    // Filtro pels elements dels quals mostrare les seves activitats
    this.authService.getAccountInfo().then((res: any) => {
      // Filtro per les activitats del mes dels filtres seleccionats
      let monthFilter, yearFilter;

      // Inicializo las fechas de filtro a las actuales
      monthFilter = date.toISOString().substr(0, 19).replace('T', ' ').substr(5,2);
      yearFilter = date.toISOString().substr(0, 19).replace('T', ' ').substr(0,4);

      // He de filtrar por mes y por año y comprobar si ya tengo ese mes calculado
      if(monthToFilter !== undefined && monthToFilter !== null && yearToFilter !== undefined && yearToFilter !== null){
        // Tengo mes y año para filtrar -> no es la primera vez que cargo la pagina, entro aqui por que me ha cargado el usuario cambiando el mes
        monthFilter = monthToFilter;
        yearFilter = yearToFilter;
      }

      // Si voy a calcular una nueva empresa con eventos ya cargados
      if(empresaId !== null){
        // He de cargar todos los meses que el usuario ha cargado eventos ya

        Object.keys(this.monthsCalculated).forEach(function(value, key) {
          let month = value.substring(5);
          let year = value.substring(0,4);
          let monthArray = [];
          if(dateFilters[year] !== undefined){
            // Ya he cargado este año
            monthArray = dateFilters[year].concat(month);
            monthArray = monthArray.filter(function(item, pos) { return monthArray.indexOf(item) == pos; });
            dateFilters[year] = monthArray;
          } else {
            // Primera vez que cargo este año
            dateFilters[year] = [month];
          }
        });
      } else {
        dateFilters[yearFilter] = [monthFilter];
      }
      
      if(this.monthsCalculated[yearFilter+'-'+monthFilter] !== true || empresaId !== null){
        this.monthsCalculated[yearFilter+'-'+monthFilter] = true;
        // Si no he calculat el mes el calculo
        this.endpointService.getCalendarActivities(empresaId, this.authService.userId, elementsToFilter, dateFilters, this.showShareCalendar).subscribe(
          data => {

            Object.keys(data['response']).forEach(row => {
              const id = data['response'][row]['id'];
              const comments = data['response'][row]['comments'];
              const location = data['response'][row]['location'];
              const zone = data['response'][row]['zone'];
              const town = data['response'][row]['town'];
              const creatorId = data['response'][row]['creator_id'];
              const title = data['response'][row]['title'];
              const startDate = ((data['response'][row]['start_date']) == null) ? null : new Date((data['response'][row]['start_date']));
              const endDate = ((data['response'][row]['end_date']) == null) ? null : new Date((data['response'][row]['end_date']));
              const idCalendarioGoogle = data['response'][row]['id_calendario_google'];
              const typeId = data['response'][row]['type_id'];
              const typeName = data['response'][row]['type_name'];
              const typeColor = data['response'][row]['type_color'];
              const typeCategory = data['response'][row]['id_categoria'];
              const active = data['response'][row]['active'];
              const isPrivate = data['response'][row]['privada'];
              const firmar = data['response'][row]['firmar'];
              const control_calendario = data['response'][row]['control_calendario'];
              const signLike = data['response'][row]['firma_gusta'];
              const signNotify = data['response'][row]['firma_recibir_alertas'];
              const signDNI = data['response'][row]['firma_DNI'];
              const signImgURL = data['response'][row]['firma_img_url'];
              const signObservation = data['response'][row]['firma_observaciones'];
              const allDay = data['response'][row]['dia_completo'];
              const idSerie = data['response'][row]['id_serie'];
              const idAviso1 = data['response'][row]['id_aviso1'];
              const idAviso2 = data['response'][row]['id_aviso2'];
              const idAviso3 = data['response'][row]['id_aviso3'];
              const estado = data['response'][row]['estado'];
              const can_motivo = data['response'][row]['can_motivo'];
              const viviendas = data['response'][row]['viviendas'];
              
              
              let viviendasArray = [];
              let clientesArray = [];                                     
              let usuariosArray = [];
              let industrialesArray = [];
              let encargosArray = [];

              const arquitectos = data['response'][row]['clientes'];
              const idOferta = data['response'][row]['id_oferta'];
              const nombreOferta = data['response'][row]['nombre_oferta'];
              const nombreCreador = data['response'][row]['nombre_creador'];
              const idCuenta = data['response'][row]['id_cuenta'];
              const cuentaTipo = data['response'][row]['cuenta_tipo'];
              const idEmpresa = data['response'][row]['id_empresa'];
              const idParentPeriodicity = data['response'][row]['id_padre_periodicidad'];
              let oferta = null;
              let type: EventType = new EventType();
              let action = {
                label: '<img title="' + nombreCreador
                       + '" class="fotoEvento" src="' + (data['response'][row]['foto'] ? data['response'][row]['foto'] : "https://movin.cloud/assets/img/default/perfil.png")
                       + '" alt="">',
                onClick: ({ event }: { event: CustomEvent }): void => {}
              };
  
              if (typeId !== null && typeName !== null && typeColor !== null) {
                type = new EventType(typeId, typeName, typeColor, typeCategory, active, isPrivate, firmar, control_calendario);
              }

              if (startDate === null) {
                // Tratamos las tareas sin fecha
                const tempExternalEvent = new CustomEvent(title, comments, id, creatorId, startDate, endDate, idCalendarioGoogle, type, signLike,
                  signNotify, signDNI, signImgURL, signObservation, allDay, idSerie, idAviso1, idAviso2, idAviso3, estado, can_motivo,
                  usuariosArray, arquitectos, idOferta, nombreCreador, undefined, undefined, idCuenta, cuentaTipo, idParentPeriodicity, location, zone, town);
                
                  //this.authService.getAccount(idEmpresa).then(res => { tempExternalEvent.account = res; });
                  tempExternalEvent.account = this.authService.accountsGeneric.filter(e => (e.id === idEmpresa))[0];

                tempExternalEvent.actions = this.actions;
                
                // Verificar si el elemento ya existe en tempExternalArray
                const existeElemento = tempExternalArray.find(item => item.id === tempExternalEvent.id);
                if (!existeElemento) {
                  // Agregar el elemento solo si no existe
                  tempExternalArray = [...tempExternalArray, tempExternalEvent];
                }                
                this.externalEvents = tempExternalArray;
                this.activitiesService.addEventToExternalEventGeneral(tempExternalEvent, yearFilter, monthFilter);
              } else {
                // Tratamos los eventos normales, los que aparecen en nuestro calendario
                // Creamos el evento con los datos de la row
                const tempEvent = new CustomEvent(title, comments, id, creatorId, startDate, endDate, idCalendarioGoogle, type, signLike,
                  signNotify, signDNI, signImgURL, signObservation, allDay, idSerie, idAviso1, idAviso2, idAviso3, estado, can_motivo,
                  usuariosArray, arquitectos, idOferta, nombreCreador, undefined, undefined, idCuenta, cuentaTipo, idParentPeriodicity, location, zone, town);
              
                //this.authService.getAccount(idEmpresa).then(res => { tempEvent.account = res; });
                tempEvent.account = this.authService.accountsGeneric.filter(e => (e.id === idEmpresa))[0];

                tempEvent.actions.push(action);
                tempEvent.draggable = (creatorId === this.authService.userId) ? true : false;
                tempEvent.resizable.afterEnd = (creatorId === this.authService.userId) ? true : false;
                tempEvent.resizable.beforeStart = (creatorId === this.authService.userId) ? true : false;

                // Asigno la imagen del creador al service 
                this.activitiesService.imageUserActive = (tempEvent.idCreator == this.authService.userId ? tempEvent.actions : []);
                
                tempArray = [
                  ...tempArray, tempEvent
                ];
                customEventsMonth = tempArray;
  
                if (endDate !== null && endDate < new Date() && estado === 1 && creatorId === this.authService.userId) {
                  pendingEventsArray = [
                    ...pendingEventsArray, tempEvent
                  ];
                  this.pendingEvents = pendingEventsArray;
                }

  
                if (startDate > new Date() && estado === 1) {
                  nextEventsArray = [
                    ...nextEventsArray, tempEvent
                  ];
                  this.nextEvents = nextEventsArray;
                }
                //Añado el tempEvent al map
                this.activitiesService.addEventToCustomEventGeneral(tempEvent, yearFilter, monthFilter);
              }

              if(this.authService.userId == data['response'][row]['creator_id']) this.activitiesService.isGoogleCalendarActive = (data['response'][row]['google_calendar'] == 1);
              if(this.authService.userId == data['response'][row]['creator_id']) this.activitiesService.isMicrosoftCalendarActive = (data['response'][row]['microsoft_calendar'] == 1);

              // fin
            });
  
            this.nextEvents = this.nextEvents.sort((a, b) => a.start.getTime() - b.start.getTime());
            this.customEvents = this.customEvents.concat(customEventsMonth);

            if (this.authService.fechaInicioCal !== undefined && this.authService.fechaInicioCal !== null) {
              this.dayStartHour = +this.datepipe.transform(new Date('1970-01-01T' + this.authService.fechaInicioCal + '.0000'), 'HH');
            }
            if (this.authService.fechaFinCal !== undefined && this.authService.fechaFinCal !== null) {
              this.dayEndHour = +this.datepipe.transform(new Date('1970-01-01T' + this.authService.fechaFinCal + '.0000'), 'HH');
            }

            this.filterCalendarEvents();
            
            this.loaded = true;      
          });
      }
    });
  }

  public setUsersFilter(): void {
    let promise = new Promise<void>((resolve, reject) => {
      let allUsers: any = [];
      this.endpointService.getAllMyAccountsUsers(this.authService.accountsGeneric[0]['idEmpresa'], this.authService.userId).subscribe(data => {
        data['response'].forEach(e => {
          let user = {id: e.id,
                      name: e.nombre,
                      userPic: (e.foto ? e.foto : "https://movin.cloud/assets/img/default/perfil.png"),
                      accountName: e.cuenta_nombre, // string amb la concatenació del nom de totes les empreses/comptes a les que pertany l'usuari
                      accountIds: e.cuenta_ids
                     };
          allUsers.push(user);
        });

        this.filterSelectedUsers = [];
        let tmpFilterNotSelectedUsers = JSON.parse(localStorage.getItem('event_not_share_user'));
        if (tmpFilterNotSelectedUsers == null) {
          tmpFilterNotSelectedUsers = [];
          localStorage.setItem('event_not_share_user', JSON.stringify(tmpFilterNotSelectedUsers));
        }
        allUsers.forEach(user => {
          this.filterSelectedUsers.push({id: user.id,
                                         value: (tmpFilterNotSelectedUsers.indexOf(user.id) >= 0
                                                 || (!this.showShareCalendar && this.authService.userId != user.id) ? false : true),
                                         name: user.name,
                                         userPic: user.userPic,
                                         accountName: user.accountName,
                                         accountIds: user.accountIds});
        });
        resolve();
      });
    });
    promise.then(e => {});                        
  }

  public openBottomSheetSharedCalendar(): void {
    let usersPrevious = this.genericService.cloneVariable(this.filterSelectedUsers);
    let groupsPrevious = this.genericService.cloneVariable(this.groups);
    let myGroupEventsFilter = this.genericService.cloneVariable(this.myGroupEventsFilter); // Joan: Crec que no es fa servir. Però de moment no ho trec
    const bottomSheet = this._bottomSheet.open(BottomSheetSharedCalendar, {
      data: {usersFilter: this.filterSelectedUsers, groups: this.groups, myGroupEventsFilter: this.myGroupEventsFilter}
    });
    
    bottomSheet.afterDismissed().subscribe(result => {
      if (result.applyFilters) {
        // Joan: No cal assignar result.userFilter i result.groupFilter a this.filterSelectedUsers i this.groups, doncs ja tenen els valors correctes. Jan: groups si perque sino no s'esborren
        //this.filterSelectedUsers = result.usersFilter;
        this.groups = result.groupFilter;

        // Activar comptes a partir d'usuaris modificats:
        // - L'usuari s'ha activat: Activar els comptes de l'usuari
        // - L'usuari s'ha desactivat: Desactivar els comptes de l'usuari si no tenen usuaris actius
        this.updateAccountsFilters(usersPrevious);

        this.filterCalendarEvents();
      } else {
        this.filterSelectedUsers = usersPrevious;
        this.myGroupEventsFilter = myGroupEventsFilter;
        this.groups = [];
        for(let i in groupsPrevious) {
          for (let j in result.groupFilter) {
            if(groupsPrevious[i]['id'] == result.groupFilter[j]['id']) {
              groupsPrevious[i]['users'] = result.groupFilter[j]['users'];
              this.groups.push(groupsPrevious[i]);
            }
          }
        }
      }
    });
  }

  // Joan
  // Activar comptes a partir d'usuaris modificats:
  // - L'usuari s'ha activat: Activar els comptes de l'usuari
  // - L'usuari s'ha desactivat: Desactivar els comptes de l'usuari si no tenen usuaris actius
  public updateAccountsFilters(usersPrevious: any[]): void {
    for (let i = 0; i < this.filterSelectedUsers.length; i++) {
      if (this.filterSelectedUsers[i].value != usersPrevious[i].value) {
        if (this.filterSelectedUsers[i].value) {
          // - L'usuari s'ha activat: Activar els comptes de l'usuari
          //console.log("Usuari activat: " + this.filterSelectedUsers[i].name);
          this.myAccounts.filter(a => this.filterSelectedUsers[i].accountIds && this.filterSelectedUsers[i].accountIds.indexOf(a.id) >= 0).forEach(a => {
            a.value = true;
            //console.log("Compte activat: " + a.id);
          });
        }
        else {
          // - L'usuari s'ha desactivat: Desactivar els comptes de l'usuari si no tenen usuaris actius
          //console.log("Usuari desactivat: " + this.filterSelectedUsers[i].name);
          this.myAccounts.filter(a => this.filterSelectedUsers[i].accountIds && this.filterSelectedUsers[i].accountIds.indexOf(a.id) >= 0).forEach(a => {
            // Hi ha usuaris diferents de l'usuari en curs, que estiguin actius i que tinguin el compte en curs?
            if (this.filterSelectedUsers.filter(u => u.id != this.filterSelectedUsers[i].id && u.value && u.accountIds && u.accountIds.indexOf(a.id) >= 0).length == 0) {
              a.value = false;
              //console.log("Compte desactivat: " + a.id);
            }
          });
        }
      }
    }

    // Actualitzar localStorage
    let tmpFilterNotSelectedAccounts : number[] = [];
    this.myAccounts.forEach(acc => {
      if (!acc.value) {
        tmpFilterNotSelectedAccounts.push(acc.id);
      }
    });
    localStorage.setItem('event_not_share_account', JSON.stringify(tmpFilterNotSelectedAccounts));
  }

  public openChooseAccountCalendarComponent(): void {
    let accountsGenericPrevious = this.genericService.cloneVariable(this.myAccounts);
    const bottomSheet = this._bottomSheet.open(ChooseAccountCalendarComponent, {
      data: { myGroupEventsFilter: this.myAccounts }
    });
    bottomSheet.afterDismissed().subscribe(result => {
      if (result) {
        // Activar usuaris a partir de comptes modificats:
        // - El compte s'ha activat: Activar els usuaris del compte
        // - El compte s'ha desactivat: Desactivar els usuaris del compte si no estan a cap altre compte actiu
        // I els grups en funció de l'estat dels usuaris
        this.updateUsersFilters(accountsGenericPrevious);
        this.filterCalendarEvents();
      }
      else {
        this.myAccounts = accountsGenericPrevious;
      }
    });
  }

  // Joan
  // Activar usuaris a partir de comptes modificats:
  // - El compte s'ha activat: Activar els usuaris del compte
  // - El compte s'ha desactivat: Desactivar els usuaris del compte si no estan a cap altre compte actiu
  // I els grups en funció de l'estat dels usuaris
  public updateUsersFilters(accountsGenericPrevious: any[]): void {
    for (let i = 0; i < this.myAccounts.length; i++) {
      if (this.myAccounts[i].value != accountsGenericPrevious[i].value) {
        if (this.myAccounts[i].value) {
          // - El compte s'ha activat: Activar els usuaris del compte
          //console.log("Compte activat: " + this.myAccounts[i].id);
          this.filterSelectedUsers.filter(u => u.accountIds && u.accountIds.indexOf(this.myAccounts[i].id) >= 0).forEach(u => {
            u.value = true;
            //console.log("Usuari activat: " + u.id);

            // Actualitzar l'estat de l'usuari en els diferents grups on estigui, i del grup en funció de l'estat dels usuaris del grup
            this.groups.forEach(group => {
              group.users.filter(ug => ug.id == u.id).forEach(ug => {
                ug.value = true;
                //console.log("Usuari activat: " + ug.id + ", al grup: " + group.id);
              });
              
              // Actualitzar l'estat dels grups en funció de l'estat dels usuaris del grup
              group.value = (group.users.filter(ug => ug.value).length == group.users.length); // Un grup está activat si ho estan tots els seus usuaris
              //console.log("Group " + group.id + " -> activat: " + group.value);
            });
          });
        }
        else {
          // - El compte s'ha desactivat: Desactivar els usuaris del compte si no estan a cap altre compte actiu
          //console.log("Compte desactivat: " + this.myAccounts[i].id);
          this.filterSelectedUsers.filter(u => u.accountIds && u.accountIds.indexOf(this.myAccounts[i].id) >= 0).forEach(u => {
            // Hi ha comptes diferents del compte en curs, que estiguin actius i que hi pertanyi l'usuari en curs?
            if (this.myAccounts.filter(a => a.id != this.myAccounts[i].id && a.value && u.accountIds && u.accountIds.indexOf(a.id) >= 0).length == 0) {
              u.value = false;
              //console.log("Usuari desactivat: " + u.id);
              
              // Actualitzar l'estat de l'usuari en els diferents grups on estigui, i del grup en funció de l'estat dels usuaris del grup
              this.groups.forEach(group => {
                group.users.filter(ug => ug.id == u.id).forEach(ug => {
                  ug.value = false;
                  //console.log("Usuari desactivat: " + ug.id + ", al grup: " + group.id);
                });

                // Actualitzar l'estat dels grups en funció de l'estat dels usuaris del grup
                group.value = (group.users.filter(ug => ug.value).length == group.users.length); // Un grup está activat si ho estan tots els seus usuaris
                //console.log("Group " + group.id + " -> activat: " + group.value);
              });
            }
          });
        }
      }
    }

    // Actualitzar localStorage
    let tmpFilterNotSelectedUsers: number[] = [];
    this.filterSelectedUsers.forEach(user => {
      if (!user.value) {
        tmpFilterNotSelectedUsers.push(user.id);
      }
    });
    localStorage.setItem('event_not_share_user', JSON.stringify(tmpFilterNotSelectedUsers));
  }

  // Joan
  public filterCalendarEvents(): void {
    // Categories
    let selectedCategoryIds = [];
    let allCategoryIds = [];
    let tmpCount = 0;
    this.filterSelectedCategories.forEach(cat => {
      allCategoryIds.push(cat.id);
      if (cat.value) {
        selectedCategoryIds.push(cat.id);
        tmpCount++;
      }
    });
    this.numCategoriesFiltersSelected = tmpCount;
  
    // Accounts
    let selectedAccountIds = [];
    tmpCount = 0;
    this.myAccounts.forEach(account => {
      if (account.value) {
        tmpCount++;
        if (account.id === -1 ) {
          // Eventos de Google -> filtrar por los eventos y los tipos de eventos seleccionados
          //this.customEvents = this.customEvents.concat(this.activitiesService.customEventsTotal.filter(x=> x.account === undefined)); 
          let getCalendars = [];
          if(this.googleCalendars !== undefined && this.googleCalendars['getCalendars']) this.googleCalendars['getCalendars'].forEach(c => { getCalendars.push(c.id) });
          // Joan: De moment ho comento
          ///*
          let googleActivitiesAreShown = false;
          getCalendars.forEach(calendar => {
            // Hay tres tipos de eventos de Google:
            // 1. Festivos ('holiday') id -> 10
            // 2. Cumpleaños ('contacts') id -> 9
            // 3. calendario personal ('primmary' o cualquier otra cosa) id -> 8
            if(calendar.includes('holiday')) this.customEvents = this.customEvents.concat(this.activitiesService.customEventsTotal.filter(event=> event.account == undefined && event.type.id === 10));
            if(calendar.includes('contacts')) this.customEvents = this.customEvents.concat(this.activitiesService.customEventsTotal.filter(event=> event.account == undefined && event.type.id === 9));
            if(!calendar.includes('contacts') && !calendar.includes('holiday') && !googleActivitiesAreShown){
              googleActivitiesAreShown = true;
              this.customEvents = this.customEvents.concat(this.activitiesService.customEventsTotal.filter(event=> event.account == undefined && (event.type.id === 8 || (event.type.id!==9 && event.type.id!== 10))));
            }
          });
          //*/
        }
        else if (account.id === -2) {
          let getCalendars = [];
          if(this.microsoftCalendars !== undefined && this.microsoftCalendars['getCalendars']) this.microsoftCalendars['getCalendars'].forEach(c => { getCalendars.push(c.id)});
          let microsoftActivitiesAreShown = false;
          // Joan: De moment ho comento
          ///*
          getCalendars.forEach(calendar => {
            if(calendar.includes('holiday')) this.customEvents = this.customEvents.concat(this.activitiesService.customEventsTotal.filter(event=> event.account == undefined && event.type.id === 73));
            if(calendar.includes('contacts')) this.customEvents = this.customEvents.concat(this.activitiesService.customEventsTotal.filter(event=> event.account == undefined && event.type.id === 74));
            if(!calendar.includes('contacts') && !calendar.includes('holiday') && !microsoftActivitiesAreShown){
              microsoftActivitiesAreShown = true;
              this.customEvents = this.customEvents.concat(this.activitiesService.customEventsTotal.filter(event=> event.account == undefined && (event.type.id === 8 || (event.type.id!==73 && event.type.id!== 74))));
            }
          });
          //*/
        }
        else {
          selectedAccountIds.push(account.id);
        }  
      }
    });
    this.numAccountsFiltersSelected = tmpCount;

    // Users
    let selectedUserIds = [];
    tmpCount = 0;

    if (this.activitiesService.showOnlyMyAgenda) {
      selectedUserIds.push(this.authService.userId);
      tmpCount++;
    }
    else {
      this.filterSelectedUsers.forEach(user => {
        if (user.value) {
          tmpCount++;
          if (selectedUserIds.indexOf(user.id) == -1) {
            selectedUserIds.push(user.id);
          }
        }
      });
    }
    this.numUsersFiltersSelected = tmpCount;

    let custEvents = [];
    let extEvents = [];
    Object.keys(this.activitiesService.customEventsGeneral).forEach(user => {
      if (this.activitiesService.customEventsGeneral[user]['customEvents']) {  
        custEvents = custEvents.concat(this.activitiesService.customEventsGeneral[user]['customEvents'].filter(e =>
                                ((selectedUserIds.indexOf(e.idCreator) >= 0
                                  || (e.users.length > 0 && this.anyInvitedUserIsSelected(e.users, selectedUserIds)))
                                 && 
                                 ((e.type && e.type.isPrivate)
                                  || (e.account && selectedAccountIds.indexOf(e.account.id) >= 0))
                                 && e.type && (selectedCategoryIds.indexOf(e.type.id) >= 0
                                               || (this.foreignCategories
                                                   && (allCategoryIds.indexOf(e.type.id) == -1 || e.type.isPrivate))) && this.checkFilterString(e))));
      }
      if (this.activitiesService.customEventsGeneral[user]['externalEvents']) {
        extEvents = extEvents.concat(this.activitiesService.customEventsGeneral[user]['externalEvents'].filter(e =>
                                ((selectedUserIds.indexOf(e.idCreator) >= 0
                                  || (e.users.length > 0 && this.anyInvitedUserIsSelected(e.users, selectedUserIds)))
                                 &&
                                 ((e.type && e.type.isPrivate)
                                  || (e.account && selectedAccountIds.indexOf(e.account.id) >= 0))
                                 && e.type && (selectedCategoryIds.indexOf(e.type.id) >= 0
                                               || (this.foreignCategories
                                                   && (allCategoryIds.indexOf(e.type.id) == -1 || e.type.isPrivate))) && this.checkFilterString(e))));

        const idsEncontrados = new Set();
        extEvents = extEvents.filter((evento) => {
          if (idsEncontrados.has(evento.id)) {
            return false; // Descartar elemento duplicado
          } else {
            idsEncontrados.add(evento.id);
            return true; // Mantener elemento único
          }
        });
      }
    });
    this.customEvents = custEvents;
    this.externalEvents = extEvents;
  }

  public anyInvitedUserIsSelected(invitedUsers: any[], selectedUserIds: number[]): boolean {
    let foundUser = false;
    invitedUsers.forEach(iu => {
      if (selectedUserIds.indexOf(iu.id) >= 0) {
        //console.log("anyInvitedUserIsSelected:", invitedUsers, selectedUserIds);
        foundUser = true;
        return;
      }
    });
    return foundUser;
  }

  BottomSheetOpenPendentEvents(): void {
    const bottomSheet = this._bottomSheet.open(BottomSheetFinishedEvents, {
      data: this.pendingEvents
    });

    bottomSheet.afterDismissed().subscribe(() => {
      this.setEvents();
    });
  }

  // Entro a la funció quan afegeixo un nou event
  BottomSheetopenNewEvent(event: any): void {
    if(event.id) this.editActivityFromGeneric(event);
    else this.addActivityFromGeneric(event);
    return;
  }

  openBottomSheetManageCategories(): void {
    this.activeDayIsOpen = false;
    let filterSelectedCategoriesOriginal = this.genericService.cloneVariable(this.filterSelectedCategories);
    const bottomSheet = this._bottomSheet.open(ManageCategoriesDialog, {
      autoFocus: true,
      data: this.filterSelectedCategories
    });

    bottomSheet.afterDismissed().subscribe(result => {
      if (result) {
        this.foreignCategories = (localStorage.getItem('event_of_foreign_category_selected_') ? true : false);
        this.filterCalendarEvents();
      }
      else {
        this.filterSelectedCategories = filterSelectedCategoriesOriginal;
      }
    });
  }

  BottomSheetopenAllEventsList(): void {
    this.activeDayIsOpen = false;

    const bottomSheet = this._bottomSheet.open(AllEventsListDialog, {
      data: { events: this.customEvents }
    });
    bottomSheet.afterDismissed().subscribe(data => {
      if (data) {
        this.onClickEditExternalActivity(data);
      }
    });
  }

  dayClicked({ date, events }: { date: Date; events: CustomEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (events.length === 0) {
        this.activeDayIsOpen = false;
      } else if (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) {
        this.activeDayIsOpen = false;
      } else {
        this.viewDate = date;
        this.activeDayIsOpen = true;
      }
    }
  }

  eventTimesChanged({event, newStart, newEnd, allDay}: CalendarEventTimesChangedEvent): void {
    let index = this.customEvents.indexOf(this.customEvents.find(elementCustom => elementCustom.id == event.id));
    let customItemClone = this.genericService.cloneVariable(this.customEvents[index]);
    const c_event = this.externalEvents.filter(e => e.id === event.id);
    const externalIndex = this.externalEvents.indexOf(c_event[0]);
    if (typeof allDay !== 'undefined') { event.allDay = allDay; }
    event.start = newStart;
    if (newEnd) { event.end = newEnd; }
    if (externalIndex > -1) {
      this.onClickEditExternalActivity(event);
    }

    this.refresh.next();
  }

  handleEvent(action: string, event: any): void {
    switch (action) {
      case 'Clicked':
        this.onClickEditActivity(event);
        break;
      case 'HourClicked':
        this.BottomSheetopenNewEvent(event);
        break;
      case 'Dropped or resized':
        // this.refresh.next();
        break;
    }
  }

  externalDrop(event: CustomEvent) {
    if (this.externalEvents.indexOf(event) === -1) {
      const body = {
        title: event.title, comments: event.comment, location: event.location, zone: event.zone, town: event.town, creator_id: this.authService.getLoggedInUserId(),
        category_tipe: event.type.id, property_id: null,
        property_reference: null,
        client_id: null,
        start_date: event.start, end_date: event.end, all_day: (event.allDay ? 1 : 0), id_serie: (event.idSerie !== null ? event.idSerie : null), id_aviso1: (event.idAviso1 !== null ? event.idAviso1 : null),
        id_aviso2: (event.idAviso2  !== null ? event.idAviso2 : null), id_aviso3: (event.idAviso3 !== null ? event.idAviso3 : null),
        estado: event.estado, can_motivo: (event.estado === 3 ? event.can_motivo : null),
        clientes: [], viviendas: [],
        usuarios: (event.users ? event.users : []), id_oferta: event.id_oferta, id_cuenta: event.accountId, cuenta_tipo: event.accountType
      };
      this.endpointService.updateCalendarActivity(body, event.id).subscribe(data => {
        this.customEvents = this.customEvents.filter(listEvent => listEvent !== event);
        this.nextEvents = this.nextEvents.filter(listEvent => listEvent !== event);
        this.externalEvents.push(event);
      });
    }
  }

  // Se llama cuando edito una tarea sin fecha 
  onClickEditExternalActivity(event: any): void {
    if(event.id) this.editActivityFromGeneric(event);
    else this.addActivityFromGeneric(event);
    return;
  }

  setView(view: CalendarView, from = 1) {
    if(from == 1) {
      this.view = view;
      this.isList = false;
    }
    else this.isList = true;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  setDate(event: MatDatepickerInputEvent<Date>) {
    this.viewDate = event.value;
  }

  // Joan: Es crida des de ngOnInit (aquesta o setAllCategoriesAndFilters, aquesta última de monent comentada) i des de BottomSheetopenNewEvent
  public setCategoriesAndFilters(): void {
    let promise = new Promise<void>((resolve, reject) => {
      this.endpointService.getCategories(this.authService.getLoggedInUserId(), this.authService.returnActiveAccountId(), this.authService.returnActiveAccountType(), this.authService.isOrphan).subscribe(data => {
        setCategories(data); // Joan: Sembla que omple this.categoryTypes a partir de data

        this.filterSelectedCategories = [];
        let tmpFilterNotSelectedCategories = JSON.parse(localStorage.getItem('event_not_selected_category'));
        if (tmpFilterNotSelectedCategories == null) {
          tmpFilterNotSelectedCategories = [];
          localStorage.setItem('event_not_selected_category', JSON.stringify(tmpFilterNotSelectedCategories));
        }

        this.categoryTypes.forEach(ct => {
          ct.types.forEach(category => {
            this.filterSelectedCategories.push({id: category.id,
                                                //value: (localStorage.getItem('event_category_selected_' + category.id) === "false" ? false : true),
                                                value: (tmpFilterNotSelectedCategories.indexOf(category.id) == -1),
                                                private: category.isPrivate});
          });
        });

        this.foreignCategories = (localStorage.getItem('event_of_foreign_category_selected_') ? true : false);
  
        this.getGroupsAndEvents();
        resolve();
      });
    });
    promise.then(e => {});
  }

  // Joan: No entenc aquesta funció tenint l'anterior... Només es crida des de ngOnInit. De moment, he comentat la crida
  public setAllCategoriesAndFilters(): void {
    this.authService.accountsGeneric.forEach(account => {
      this.endpointService.getCategories(this.authService.getLoggedInUserId(), account.id, this.authService.returnActiveAccountType(), this.authService.isOrphan).subscribe(data => {
        if ((account.id == this.authService.returnActiveAccountId())) {
          this.filterSelectedCategories = [];
          setCategories(data); // Joan: Sembla que omple this.categoryTypes a partir de data

          let tmpFilterNotSelectedCategories = JSON.parse(localStorage.getItem('event_not_selected_category'));
          if (tmpFilterNotSelectedCategories == null) {
            tmpFilterNotSelectedCategories = [];
            localStorage.setItem('event_not_selected_category', JSON.stringify(tmpFilterNotSelectedCategories));
          }
  
          this.categoryTypes.forEach(ct => {
            ct.types.forEach(category => {
              this.filterSelectedCategories.push({id: category.id,
                                                  value: (tmpFilterNotSelectedCategories.indexOf(category.id) == -1),
                                                  private: category.isPrivate});
            });
          });
          this.getGroupsAndEvents();
        }
      });
    });
  }

  setCalendarsSelected() {
    this.endpointService.getCalendarsGoogle(this.authService.userId).subscribe(data => {
      this.activitiesService.addCalendarsSelected(data['response']['getCalendars']);
    });
    this.endpointService.getCalendarsMicrosoft(this.authService.userId).subscribe(data => {
      this.activitiesService.addCalendarsSelected(data['response']['getCalendars']);
    });
  }

  public setAccountsFilter(): void {
    this.myAccounts = [];

    let tmpFilterNotSelectedAccounts = JSON.parse(localStorage.getItem('event_not_share_account'));
    if (tmpFilterNotSelectedAccounts == null) {
      tmpFilterNotSelectedAccounts = [];
      localStorage.setItem('event_not_share_account', JSON.stringify(tmpFilterNotSelectedAccounts));
    }

    this.authService.accountsGeneric.forEach(acc => {
      this.myAccounts.push({id: acc.id,
                          active: acc.active,
                          value: (tmpFilterNotSelectedAccounts.indexOf(acc.id) == -1),
                          nombre: acc.nombre,
                          tipoNombre: acc.tipoNombre});
    });
    
    this.myGroupEventsFilter = this.myAccounts.filter(a => a.value);

    this.activitiesService.isGoogleCalendarActive = (tmpFilterNotSelectedAccounts.indexOf(-1) == -1);
    if (this.myAccounts.find(x => x.id === -1) == undefined) {
      this.myAccounts.push({id: -1, active: false, value: this.activitiesService.isGoogleCalendarActive, nombre: "Google Calendar", tipoNombre: "Marca"});
    }

    this.activitiesService.isMicrosoftCalendarActive = (tmpFilterNotSelectedAccounts.indexOf(-2) == -1);
    if (this.myAccounts.find(x => x.id === -2) == undefined) {
      this.myAccounts.push({id: -2, active: false, value: this.activitiesService.isMicrosoftCalendarActive, nombre: "Microsoft Calendar (Outlook)", tipoNombre: "Marca"});
    }

    // Joan: Si només hi ha un compte, l'activem
    if (this.myAccounts.length == 1) {
      this.myAccounts[0].value = true;
    }
  }

  // Joan: Funció revisada i retocada
  public setActiveUsers(): void {
    // Guardaremos los usuarios de los que sí hemos de filtrar sus actividades
    // Estos usuarios pueden estar activos en la agenda compartida en los usuarios o en usuarios de grupos activos
    let users = [];

    // Añado los usuarios activos
    this.filterSelectedUsers.forEach(u => {
      if (u.value && users.indexOf(u.id) == -1) {
        users.push(u.id);
      }
    });

    // Añado los usuarios que estan activos en grupos activos
    this.groups.filter(g => g.value).forEach(g => {
      g.users.forEach(u => {
        if (users.indexOf(u.id) == -1) {
          users.push(u.id);
        }
      });
    });

    // Añado el propio del usuario
    if (users.indexOf(this.authService.accountsGeneric[0].id) == -1) {
      users.push(this.authService.accountsGeneric[0].id);
    }

    this.usersActive = users;
  }

  // Joan: He refet la funció. Ara torna els grups sempre en el mateix ordre
  //       De moment, deixo les 2 funcions que es criden al final: setActiveUsers i setEvents
  //       Es crida des de setCategoriesAndFilters i setAllCategoriesAndFilters (comentada a ngOnInit)
  public getGroupsAndEvents(): void {
    let promise = new Promise<void>((resolve, reject) => {
      this.groups = [];
      // Cargamos los grupos y sus correspondientes informaciones
      this.endpointService.getAllGroups(this.authService.userId).subscribe(data => {
        const allGroups = data['response'];
        for (let index = 0; index < allGroups.length; index++) {
          // Carreguem la informació dels usuaris del grup i la seva informació
          //console.log("userGroup " + allGroups[index].id_grupo, data1['response']);
          let tempUsersArray: any = [];
          allGroups[index].users.forEach(userGroup => {
            const ind = this.filterSelectedUsers.findIndex(u => u.id == userGroup.id_usuario);
            tempUsersArray.push({id: userGroup.id_usuario,
                                 name: userGroup.nombre,
                                 userPic: (userGroup.foto ? userGroup.foto : "https://movin.cloud/assets/img/default/perfil.png"),
                                 empresaId: userGroup.id_empresa,
                                 cuenta_nombre: userGroup.cuenta_nombre,
                                 value: (ind > 0 ? this.filterSelectedUsers[ind].value : false)});
          });
          
          this.groups[index] = new SimpleGroup(allGroups[index].id_grupo,
                                               // Joan: L'estat del grup depèn de l'estat dels usuaris del grup: un grup está activat si ho estan tots els seus usuaris
                                               (tempUsersArray.filter(ug => ug.value).length == tempUsersArray.length),
                                               allGroups[index].nombre,
                                               this.authService.userId,
                                               tempUsersArray,
                                               null,
                                               null,
                                               allGroups[index].id_empresa);
        }
        // Joan: Ho comento i ho cvanvio a setEvents
        //this.setActiveUsers();
        this.setEvents();
        resolve();
      });
    });
    promise.then(e => {});
  }

  get categoryTypes() {
    const categories = categoryTypes.filter(obj => {
      obj.types = obj.types.filter(el => el.active === 1 && el.visible == 1);
      return obj.types.length > 0;
    });
    return categories;
  }
  
  // S'obre el EditEventDialog d'un event ja creat per poder editar-lo i/o esborrarlo
  onClickEditActivity(event: any): void {
    if(event.id) this.editActivityFromGeneric(event);
    else this.addActivityFromGeneric(event);
    return;
  }

  openAgendaSettings() {
    let calendarsSelectedPrevious = this.genericService.cloneVariable(this.activitiesService.calendarsSelected);
    const dialogRef = this.dialog.open(AgendaSettingsComponent, {
      width: 'auto',
      height: 'auto',
      data: {userID: this.authService.userId, calendarDayInit: this.authService.dia_inicio_calendario, showRememberConfig: this.showRememberConfig}
    });

    dialogRef.afterClosed().subscribe(result => {
      calendarsSelectedPrevious.sort();
      this.activitiesService.calendarsSelected.sort();
      // Calendar changes tiene todos los que estaban antes y ahora no
      let calendarChanges = calendarsSelectedPrevious.filter(n => !this.activitiesService.calendarsSelected.includes(n));
      // Incluyo los que están ahora y antes no
      calendarChanges = calendarChanges.concat(this.activitiesService.calendarsSelected.filter(n => !calendarsSelectedPrevious.includes(n)))
      if(calendarChanges.length > 0){
        if(this.activitiesService.isGoogleCalendarActive !== undefined){
          if(!this.activitiesService.isGoogleCalendarActive){
            this.customEvents = this.customEvents.filter(x=> x.type.id !== 8 && x.type.id !== 10 && x.type.id !== 9);
          } else {
            // He de comprobar los calendarios que tiene activo el usuario
            this.endpointService.getCalendarsGoogle(this.authService.userId).subscribe( data=> {
              this.googleCalendars = data['response'];
              let calendars = [];  data['response']['calendars'].forEach(c => { calendars.push(c.id) });
              let getCalendars = []; data['response']['getCalendars'].forEach(c => { getCalendars.push(c.id) });
              calendars.sort(); getCalendars.sort();
              let notSelectedCalendars = calendars.filter(n => !getCalendars.includes(n));
              let newSelectedCalendars = getCalendars.filter(n => !calendarsSelectedPrevious.includes(n));
              // Elimino los eventos que ya no estan marcados
              notSelectedCalendars.forEach(calendar => {
                this.customEvents = this.customEvents.filter(e=> e.idCalendarioGoogle !== calendar);
                this.customEvents = this.customEvents.concat(this.activitiesService.customEventsTotal.filter(e=> e.idCalendarioGoogle === calendar && e.type.id !== 8 && e.type.id !== 9 && e.type.id !== 10));
                this.refresh.next();
              });
              newSelectedCalendars.forEach(calendar => {
                this.customEvents = this.customEvents.concat(this.activitiesService.customEventsTotal.filter(x=> x.idCalendarioGoogle == calendar && (x.type.id == 8 || x.type.id == 9 || x.type.id == 10)));
                this.refresh.next();
              });
            });
          }
        }
        if(this.activitiesService.isMicrosoftCalendarActive !== undefined){
          if(!this.activitiesService.isMicrosoftCalendarActive){
            this.customEvents = this.customEvents.filter(x=> x.type.id !== 72 && x.type.id !== 73 && x.type.id !== 74);
          } else {
            // He de comprobar los calendarios que tiene activo el usuario
            this.endpointService.getCalendarsMicrosoft(this.authService.userId).subscribe( data=> {
              this.googleCalendars = data['response'];
              let calendars = [];  data['response']['calendars'].forEach(c => { calendars.push(c.id) });
              let getCalendars = []; data['response']['getCalendars'].forEach(c => { getCalendars.push(c.id) });
              calendars.sort(); getCalendars.sort();
              let notSelectedCalendars = calendars.filter(n => !getCalendars.includes(n));
              let newSelectedCalendars = getCalendars.filter(n => !calendarsSelectedPrevious.includes(n));
              // Elimino los eventos que ya no estan marcados
              notSelectedCalendars.forEach(calendar => {
                this.customEvents = this.customEvents.filter(e=> e.idCalendarioGoogle !== calendar);
                this.customEvents = this.customEvents.concat(this.activitiesService.customEventsTotal.filter(e=> e.idCalendarioGoogle === calendar && e.type.id !== 72 && e.type.id !== 73 && e.type.id !== 74));
                this.refresh.next();
              });
              newSelectedCalendars.forEach(calendar => {
                this.customEvents = this.customEvents.concat(this.activitiesService.customEventsTotal.filter(x=> x.idCalendarioGoogle == calendar && (x.type.id == 72 || x.type.id == 73 || x.type.id == 74)));
                this.refresh.next();
              });
            });
          }
        }
      }
      if (result) {
        this.dayStartHour = parseInt(result['dateStartCalendar'].split(':')[0], 10);
        this.dayEndHour = parseInt(result['dateEndCalendar'].split(':')[0], 10);
        this.weekStartsOn = this.authService.dia_inicio_calendario = result['startDay'];
      }
      this.refresh.next();
    });
  }

  addActivityFromGeneric (activity) {
    let x = {id_function: 35, internal_routing_id_functional_area: 33949, refresh_params: 1, id_functional_parent_initial: 89};
    if(activity && activity['date']) {
      this.functionsService.addWindowParam(this.functionsService.formatDate(activity['date'], true), 1, "actividades_calendario", "fecha_inicio", 89); // Hay que poner en el header porque el Dialog esta en el Header
      x['refresh_params'] = 2;
    }
    this.functionsService.getFunction(x, null);
  }

  editActivityFromGeneric(actividad) {
    /*this.functionsService.addWindowParam(idActividad, 1, "actividades_calendario", "id", this.struct[this.index]['id_functional_parent_initial']);
    this.functionsService.addWindowParam(idActividad, 1, "actividades_calendario", "id", 89);
    let x = {id_function: 5, internal_routing_id_functional_area: 34400, refresh_params: 2};
    this.functionsService.getFunction(x, null);*/
    let url = "ficha-actividad";
    if(actividad && actividad.type && actividad.type.parentId && actividad.type.parentId == 11) url = "pedido-actividades-servicios";
    this.functionsService.addWindowParam(actividad.id, 1, "actividades_calendario", "id", this.struct[this.index]['id_functional_parent_initial'], true, 'output');
    let x = {id_function: 1, internal_routing: url, id_functional_parent_initial: this.struct[this.index]['id_functional_parent_initial'], no_save_params: true};
    this.functionsService.getFunction(x, null);
  }

  checkStructure() {
    if(this.structure != undefined){
      for(let i in this.structure){
        if(this.structure[i]['id_functional_area'] == 85){
          this.showShareCalendar = (this.structure[i]['id_functional_type'] === 5 && this.structure[i]['id_functional_status_mkt'] === 1) ? true : false;
        } else if(this.structure[i]['id_functional_area'] == 86){
          this.showAllActivities = (this.structure[i]['id_functional_type'] === 5 && this.structure[i]['id_functional_status_mkt'] === 1) ? true : false;
        } else if(this.structure[i]['id_functional_area'] == 138){
          this.showRememberConfig = (this.structure[i]['id_functional_type'] === 5 && this.structure[i]['id_functional_status_mkt'] === 1) ? true : false;
        }
      }
    }
  }

  openSearchBar() {
    this.showSearchBar = true;
    setTimeout(() => {
      if (this.filterInput && this.filterInput.nativeElement) {
        this.filterInput.nativeElement.focus();
      }
    });  
  }

  deleteFilter() {
    this.filterSearch = false;
    this.showSearchBar = false;
    this.value = "";
    this.lastValue = "";
    this.saveFilterAgenda();
  }

  searchValue(value) {
    this.lastValue = this.genericService.cloneVariable(this.value);
    this.value = value;
    this.showSearchBar = false;
    this.filterSearch = true;
    this.saveFilterAgenda();
  }

  cleanFilter() {
    this.value = "";
    this.lastValue = "";
    this.saveFilterAgenda();
  }

  saveFilterAgenda() {
    this.filterCalendarEvents();
    localStorage.setItem('filterTextAgenda', JSON.stringify(this.value));
  }

  checkFilterString(obj) {
    let objCloned = this.genericService.cloneVariable(obj);
    let filter = this.genericService.limpiarCadena(this.value);
    if(!filter || filter == "") return true;
    return this.genericService.checkFilterStringObject(objCloned, filter);;
  }
}
