import {EventEmitter, Injectable, Output, ViewChild} from "@angular/core";
import {EndpointService} from "../endpoint/endpoint.service";
import {Router} from "@angular/router";
import {AuthService} from "../auth/auth.service";
import {Values} from "../../../values/values";
import {MatSnackBar} from "@angular/material/snack-bar";
import { MatDialog } from '@angular/material/dialog';
import { DialogUserProfileComponent } from "app/generic/components-custom/agenda-custom/subcomponents/notifications/dialog-user-profile/dialog-user-profile.component";
import {FunctionsService} from "../functions/functions.service";
import {GenericService} from "../generic/generic.service";
import {RoutingService} from "../routing/routing.service";
import {MarketplaceGeneralComponent} from "app/places/marketplace-stepper/marketplace-general/marketplace-general.component";
import { NotificationsCustomComponent } from "../../generic/components-custom/notifications-custom/notifications-custom.component";

@Injectable({
  providedIn: 'root',
})
export class AdviceReportService {

  private dato: any;
  public noti: any;
  private buttonActionId: any;
  private redirect = false;

  private accionesPendientes = new Array();

  public routes = Values.ROUTES;

  public notificationsAlt = [];
  public notifications = [];
  public notificationSuperior = [];
  public preguntasYrespuestas = [];
  public preguntasYrespuestasGeneral = [];
  public notificationsComponent : NotificationsCustomComponent;

  public lastArea = null;

  @Output() superior: EventEmitter<boolean> = new EventEmitter();
  @Output() quest: EventEmitter<boolean> = new EventEmitter();
  @Output() notiEGO: EventEmitter<boolean> = new EventEmitter();

  @Output() open: EventEmitter<any> = new EventEmitter();
  @Output() openSuperior: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<number> = new EventEmitter();
  @Output() homeClose: EventEmitter<number> = new EventEmitter();
  @Output() notiHome: EventEmitter<any> = new EventEmitter();

  @Output() closeRemoto: EventEmitter<number> = new EventEmitter();
  @Output() homeCloseRemoto: EventEmitter<number> = new EventEmitter();

  constructor(private router: Router,
              public snackBar: MatSnackBar,
              private authService: AuthService,
              public dialog: MatDialog,
              private endpointService: EndpointService,
              private routingService: RoutingService,
              private functionsService: FunctionsService,
              private genericService: GenericService) {
  }

  public pulsado(i: number, s: any, index?){
    console.log(s);
    this.dato = s;
    this.buttonActionId = i;
    this.buttonSwitch(i, index);
  }

  /*public pulsado2(i: number, s: any, n: any){
    this.dato = s;
    this.noti = n;
    this.buttonActionId = i;
    this.buttonSwitch(i);
  }*/

  private buttonSwitch(i: number, notification_index?){

    console.log(i);
    switch (i) {
      case 1:
        this.externLink(this.dato);
        /*this.endpointService.insertEgoAction(this.data.id, '2020-06-25 10:31:57', horaClick, 2, this.dato).subscribe(data => {
          console.log('SE HA INSERTADO CORRECTAMENTE EL MONITOREO');
        });*/
        // TODO: Monitorear pasando el id_notificacion, accion = 2, info_accion = this.dato
        break;
      case 2:
        this.internLink(this.dato);
        /*this.endpointService.insertEgoAction(this.data.id, '2020-06-25 10:31:57', horaClick, 2, this.dato).subscribe(data => {
          console.log('SE HA INSERTADO CORRECTAMENTE EL MONITOREO');
        });*/
        // TODO: Monitorear pasando el id_notificacion, accion = 2, info_accion = this.dato
        break;
      case 3:
        /*this.endpointService.insertEgoAction(this.data.id, '2020-06-25 10:31:57', horaClick, 1, this.dato).subscribe(data => {
          console.log('SE HA INSERTADO CORRECTAMENTE EL MONITOREO');
        });*/
        this.logout();
        break;
      case 4:
        /*this.endpointService.insertEgoAction(this.data.id, '2020-06-25 10:31:57', horaClick, 1, this.dato).subscribe(data => {
          console.log('SE HA INSERTADO CORRECTAMENTE EL MONITOREO');
        });*/
        //Marc: Cierra la ventana/notificación, no hace falta poner nada adicional
        console.log('test');
        break;
      case 5:
        /*this.endpointService.insertEgoAction(this.data.id, '2020-06-25 10:31:57', horaClick, 1, this.dato).subscribe(data => {
          console.log('SE HA INSERTADO CORRECTAMENTE EL MONITOREO');
        });*/
        this.notification(this.dato);
        break;
      case 6:
        //console.log("Usuario aceptado");
        this.acceptUser(notification_index);
        break;
      case 7:
        //console.log("Nop");
        this.rejectUser();
        break;
      case 8:
        //console.log("Nop");
        this.contract();
        break;
      case 9:
        //console.log("Nop");
        this.privacity();
        break;
      case 10:
        this.acceptCollaboration();
        break;
      case 11:
        this.rejectCollaboration();
        break;
      case 12:
        this.acceptInmoGroupRequest();
        break;
      case 13:
        this.rejectInmoGroupRequest();
        break;
      case 14:
        this.acceptInmoGroupInvitation();
        break;
      case 15:
        this.rejectInmoGroupInvitation();
        break;
      case 16:
        this.acceptUserGroupRequest();
        break;
      case 17:
        this.rejectUserGroupRequest();
        break;
      case 18:
        this.acceptUserGroupInvitation();
        break;
      case 19:
        this.rejectUserGroupInvitation();
        break;
      case 20:
        this.inmoLeaveGroup();
        break;
      case 21:
        this.userLeaveGroup();
        break;
      case 24:
        this.openMarketProduct(this.dato);
        break;
      case 25:
        this.openKnowledgeProduct(this.dato);
        break;
      case 26:
        this.notificationSuperiorFunction(this.dato);
        break;
      case 27:
        this.mailchimp();
        break;
      case 28:
        this.cerrarRemoto(this.dato);
        break;
      case 29:
        this.reloadPage();
        break;
      case 30:
        this.functionsService.getFunction(null, null, null, 21);
        break;
      case 31:
        this.functionsService.getFunction(null, null, null, 24);
        break;
    }
  }
  private reloadPage() {
    console.log('Reloading page');
    setTimeout(function(){
      this.genericService.refreshStructure(2);
    }, 0);

  }

  private logout(){
    this.authService.logout();
  }

  private externLink(link: any){
    //console.log(link);
    window.open(link, "_blank");
  }

  private internLink(id: any){
    console.log("intern Link")
    console.log(id)
    //this.genericService.go('#' + id);
    this.endpointService.getInternalRouteById(id).subscribe(data => {
      console.log(data)
      if(data['response'] !== null && data['response']['internal_routing']){
        if('/' + data['response']['internal_routing'] != this.routingService.urlWithoutHashtag) this.genericService.go(this.authService.labelLanguage + "/" + data['response']['internal_routing'] + '#' + id, false)
        else this.functionsService.goRouting('#' + id, false) 
      }
    });
  }

  private notification(id: number, notis?: any, redirect?: any){

    //console.log("LLAMO A LAS NOTIFICACIONES");

    //let redirect = this.redirect;
    console.log("notis",notis);
    if(notis) console.log(JSON.parse(JSON.stringify(notis)));
    console.log("id",id);

    if(id != null){
      console.log("urru: -1");
        this.endpointService.newNotiEgo(this.authService.userId, this.authService.getIdEmpresa(), id).subscribe(data => {
          this.loadNotis();
        });
    }else{
      if(this.notifications.find(sup => sup.id_plantilla_ego === notis[0].id) === undefined) {
        console.log("urru: 2");

        this.endpointService.newNotiEgo(this.authService.userId, this.authService.getIdEmpresa(), notis[0].id).subscribe(data => {
          notis.splice(0,1);
          this.loadNotis();
          if(notis.length > 0){
            if(notis[0].action == 5) this.notification(null, notis, redirect);
            else if (notis[0].action == 26)  this.notificationSuperiorFunction(null, notis, redirect);
          }else{
            this.executeAction(redirect);
          }
        });
      }else{
        console.log("urru: 3");
        notis.splice(0,1);
        if(notis.length > 0){
          if(notis[0].action == 5) this.notification(null, notis, redirect);
          else if (notis[0].action == 26)  this.notificationSuperiorFunction(null, notis, redirect);
        }else{
          this.executeAction(redirect);
        }
      }
    }
  }

  private notificationSuperiorFunction(id: number, notis?: any, redirect?: any){
    console.log(notis);
    console.log("id: ", id)
    if(notis)console.log(JSON.parse(JSON.stringify(notis)))
    if(id != null) {
      this.endpointService.newNotiEgo(this.authService.userId, this.authService.getIdEmpresa(), id).subscribe(data => {
        this.loadNotis();
      });
    }else{

      if(this.notifications.find(sup => sup.id_plantilla_ego === notis[0].id) === undefined) {
        this.endpointService.newNotiEgo(this.authService.userId, this.authService.getIdEmpresa(), notis[0].id).subscribe(data => {
          this.loadNotis();
          notis.splice(0,1);
          if(notis.length > 0){
            if(notis[0].action == 5) this.notification(null, notis, redirect);
            else if (notis[0].action == 26)  this.notificationSuperiorFunction(null, notis, redirect);
          }else{
            this.executeAction(redirect);
          }
        });
      }else{
        notis.splice(0,1);
        if(notis.length > 0){
          if(notis[0].action == 5) this.notification(null, notis, redirect);
          else if (notis[0].action == 26)  this.notificationSuperiorFunction(null, notis, redirect);
        }else{
          this.executeAction(redirect);
        }
      }

    }
  }

  public deleteNotification(id: number){
    console.log('deleting notification n:', id);
    this.close.emit(id);
  }

  public deleteHomeNotification(id: number){
    console.log('deleting home notification n:', id);

    this.homeClose.emit(id);
  }

  public acceptUser(notification_index?) {
    console.log('ACCEPTING USER', this.dato, this.authService.inmoId);

    // DialogSelect
    const dialogRef = this.dialog.open(
      DialogUserProfileComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        inmoId: this.authService.inmoId,
        message: 'Seleccionar perfil... etc',
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data){ // no ha aceptado
        const tipo = data['profile_id'];
        this.endpointService.acceptUser(this.dato, this.noti, tipo).subscribe(data => {
          this.deleteHomeNotification(notification_index); // emit event "close notification" where index = notification_index
          this.endpointService.queueEmail(data['response']['user'][0].email, this.dato, this.buttonActionId, this.authService.empresaId).subscribe(res => {
            //console.log("ACEPTADO");
          });
          // this.endpointService.
        });
      }
    });

  }

  public rejectUser(){
      console.log('Rechazando c');
    this.endpointService.rejectUser(this.dato, this.noti).subscribe(data => {
        console.log(data);
        this.endpointService.queueEmail(data['response']['user'][0].email, this.dato, this.buttonActionId, this.authService.empresaId).subscribe(res => {
          console.log("RECHAZADO");
        });
      });
  }

  public acceptCollaboration() {
    //console.log('ACEPTO LA COLABORACION');
    //console.log(this.dato);
    //console.log(this.noti);

    this.endpointService.acceptCollaboration(this.dato, this.noti).subscribe(data => {
      //console.log(data);
      this.endpointService.createDynamicEgoNotification(this.dato, this.noti, this.buttonActionId, this.authService.empresaId).subscribe(res => {
        //console.log("ACEPTADA");
      });
    });
  }

  public rejectCollaboration() {
    //console.log('RECHAZO LA COLABORACION');
    //console.log(this.dato);
    //console.log(this.noti);
    this.endpointService.rejectCollaboration(this.dato, this.noti).subscribe(data => {
      //console.log(data);
      this.endpointService.createDynamicEgoNotification(this.dato, this.noti, this.buttonActionId, this.authService.empresaId).subscribe(res => {
        //console.log("RECHAZADA");
      });
    });
  }

  public acceptInmoGroupRequest() {
    //console.log('Acepto la solicitud de la inmo');
    //console.log(this.dato);
    //console.log(this.noti);

    this.endpointService.acceptInmoGroupRequest(this.dato, this.noti).subscribe(data => {
      //console.log(data);
      this.endpointService.createDynamicEgoNotification(this.dato, this.noti, this.buttonActionId, this.authService.empresaId).subscribe(res => {
        //console.log("ACEPTADA");
      });
    });
  }

  public rejectInmoGroupRequest() {
    //console.log('Rechazo la solicitud de la inmo');
    //console.log(this.dato);
    //console.log(this.noti);

    this.endpointService.rejectInmoGroupRequest(this.dato, this.noti).subscribe(data => {
      //console.log(data);
      this.endpointService.createDynamicEgoNotification(this.dato, this.noti, this.buttonActionId, this.authService.empresaId).subscribe(res => {
        //console.log("RECHAZADA");
      });
    });
  }

  public acceptInmoGroupInvitation() {
    this.endpointService.acceptInmoGroupInvitation(this.dato, this.noti).subscribe(data => {
      //console.log(data);
      this.endpointService.createDynamicEgoNotification(this.dato, this.noti, this.buttonActionId, this.authService.empresaId).subscribe(res => {
        //console.log("ACEPTADA");
      });
    });
  }

  public rejectInmoGroupInvitation() {
    this.endpointService.rejectInmoGroupInvitation(this.dato, this.noti).subscribe(data => {
      //console.log(data);
      this.endpointService.createDynamicEgoNotification(this.dato, this.noti, this.buttonActionId, this.authService.empresaId).subscribe(res => {
        //console.log("RECHAZADA");
      });
    });
  }

  public acceptUserGroupRequest() {
    this.endpointService.acceptUserGroupRequest(this.dato, this.noti).subscribe(data => {
      //console.log(data);
      this.endpointService.createDynamicEgoNotification(this.dato, this.noti, this.buttonActionId, this.authService.empresaId).subscribe(res => {
        //console.log("ACEPTADA");
      });
    });
  }

  public rejectUserGroupRequest() {
    this.endpointService.rejectUserGroupRequest(this.dato, this.noti).subscribe(data => {
      //console.log(data);
      this.endpointService.createDynamicEgoNotification(this.dato, this.noti, this.buttonActionId, this.authService.empresaId).subscribe(res => {
        //console.log("RECHAZADA");
      });
    });
  }

  public acceptUserGroupInvitation() {
    this.endpointService.acceptUserGroupInvitation(this.dato, this.noti).subscribe(data => {
      //console.log(data);
      this.endpointService.createDynamicEgoNotification(this.dato, this.noti, this.buttonActionId, this.authService.empresaId).subscribe(res => {
        //console.log("ACEPTADA");
      });
    });
  }

  public rejectUserGroupInvitation() {
    this.endpointService.rejectUserGroupInvitation(this.dato, this.noti).subscribe(data => {
      //console.log(data);
      this.endpointService.createDynamicEgoNotification(this.dato, this.noti, this.buttonActionId, this.authService.empresaId).subscribe(res => {
        //console.log("RECHAZADA");
      });
    });
  }

  public inmoLeaveGroup() {
    this.endpointService.inmoLeaveGroup(this.dato, this.noti).subscribe(data => {
      //console.log(data);
      this.endpointService.createDynamicEgoNotification(this.dato, this.noti, this.buttonActionId, this.authService.empresaId).subscribe(res => {
      });
    });
  }

   public userLeaveGroup() {
    this.endpointService.userLeaveGroup(this.dato, this.noti).subscribe(data => {
      //console.log(data);
      this.endpointService.createDynamicEgoNotification(this.dato, this.noti, this.buttonActionId, this.authService.empresaId).subscribe(res => {
      });
    });
  }

  public contract(){
    this.endpointService.acceptContract(this.dato).subscribe(data => {

    });
  }

  public privacity(){
    this.endpointService.acceptPrivacity(this.dato).subscribe(data => {

    });
  }

  public openMarketProduct(id){
    console.log("REDIRECT EGO MARKET");
    // Aquí que abra el popup de compra directamente
    this.endpointService.getInfoProduct(this.authService.empresaId,this.authService.getIdCompanyType(true), this.authService.languageId, this.authService.adminArea.id_admin_area, id).subscribe(data => {
      if (data['errorCode'] === 0 && data['response'].length > 0) {
        let modulo = data['response'][0];
        if(modulo.id_product > 0) this.openCheckout(modulo);
        else {
          this.snackBar.open('Este producto ya no está disponible', 'X', {
            duration: 6000, panelClass: ['red-green']
          });
        }
      } else if(data['errorCode'] === 1) {
        // Deberíamos comprobar si el producto está comprado y saltar un snackbar
        this.snackBar.open('El producto ya está comprado', 'X', {
          duration: 6000, panelClass: ['red-green']
        });
      } else {
        this.snackBar.open('Este producto ya no está disponible', 'X', {
          duration: 6000, panelClass: ['red-green']
        });
      }
    });

    
    /* OLD MARKET PRE-GENERIC
    localStorage.setItem("market", id);
    this.router.navigate([this.routes.MARKETPLACE]);
    */
  }

  public openCheckout(modulo) {
    this.endpointService.getMovinsInmo(this.authService.inmoId, this.authService.getIdCompanyType()).subscribe(data => {
      // Abrimos el dialog de checkout (stepper con 3 componentes: resumen+dependencias, configuración, checkout (o compra-movins)
      const dialogRef = this.dialog.open(MarketplaceGeneralComponent, {
        data: { moduloInfo: modulo, movins: data['response'][0].total_movins }
      });

      dialogRef.afterClosed().subscribe(data => {
        if (data === null || (data && data[0] === true)) {
          // Al terminar la compra de un módulo
          this.genericService.refreshStructure(2);
          // this.dialog.open(DemoDialogComponent, { });
        } else {
          if (data && data[1] !== null) {
            this.openCheckout(data[1]);
          }
        }
      });
    });
  }

  public openKnowledgeProduct(id){
    /*this.genericService.openInEditMode = false;
    localStorage.setItem("knowledge", id);

    this.router.navigate([this.routes.KNOWLEDGE_ADMIN]);*/

    this.snackBar.open('La ID del producto de knowledge es ' + id, 'X', {
      duration: 6000, panelClass: ['red-green']
    });
  }

  public mailchimp(){
    let name = "marc";
    let email = "marc@namastech.com";

    this.endpointService.mailchimpBackend(name, email).subscribe( data =>{});

  }

  public newNotiHome(info, button){
    this.notiHome.emit({info: info, button: button});
  }

  public knowledge(id: number){
    this.open.emit({id: id, button: false});
  }

  public cerrarRemoto(id: number){
    this.endpointService.getPlantilla(id, this.authService.empresaId).subscribe(data =>{
      if(data['response']['plantilla'][0].id_tipo == 3){
        this.homeCloseRemoto.emit(id);
      }else if(data['response']['plantilla'][0].id_tipo == 4){
        this.closeRemoto.emit(id)
      }
    });
  }

  public tratarAcciones(acciones: any, redireccion: any, index?:any){
    if(redireccion.length > 0){

        //this.redirect = true;

        let not = new Array()

        for(let x = 0; x < acciones.length; x ++) {
          if (acciones[x].action == 5 || acciones[x].action == 26) not.push(acciones[x]);//this.notification(acciones[x].id);
          //else if (acciones[x].action == 26) this.notificationSuperiorFunction(acciones[x].id);
          else this.accionesPendientes.push(acciones[x]);
        }

        if(not.length > 0){
          if(not[0].action == 5) this.notification(null, not, redireccion[0]);
          else if (not[0].action == 26)  this.notificationSuperiorFunction(null, not, redireccion[0]);
        }else{
          this.executeAction(redireccion[0], index);
        }

        //this.redirect = false;
        //this.executeAction(redireccion[0]);

    }else{
      for(let x = 0; x < acciones.length; x++) this.executeAction(acciones[x], index);
    }
  }

  public tratarAccionesPendientes(){
    for(let x = 0; x < this.accionesPendientes.length; x++) this.executeAction(this.accionesPendientes[x]);
    this.accionesPendientes = new Array();
  }

  public executeAction(accion: any, index?:any){
    let dato = null;

    if (accion.action == 1) dato = accion.text;
    else if (accion.action == 2) dato = accion.id;
    else if (accion.action == 5 || accion.action == 26) dato = accion.id;
    else if (accion.action === 6 || accion.action === 7) dato = accion.id;
    else if (accion.action === 8) dato = this.authService.inmoId;
    else if (accion.action === 9) dato = this.authService.userId;
    else if (accion.action == 24) dato = accion.id;
    else if (accion.action == 25) dato = accion.id;
    else if (accion.action == 28) dato = accion.id;


    this.pulsado(accion.action, dato, index);

  }

  questions(screen){
    this.preguntasYrespuestas = [];
    this.preguntasYrespuestasGeneral = [];

    this.endpointService.getQuestionsAndAnswers(screen).subscribe( data =>{
      this.preguntasYrespuestas = data['response']['questions'];
      this.preguntasYrespuestasGeneral = data['response']['questionsGeneral'];
      this.quest.emit();
    });
  }

  newNotifications (user, screen, company){
    this.endpointService.getEgoNotification(screen, user, company).subscribe(data => {
      let noti = data['response'];
      for (let x = 0; x < noti.length; x++) {
        if (this.notifications.find(sup => sup.id_plantilla_ego === noti[x].id_plantilla_ego) === undefined) {
          this.notifications.push({id_plantilla_ego: noti[x].id_plantilla_ego, id_not: noti[x].id_not});
          this.open.emit({id: noti[x]['id_plantilla_ego'], noti: data['response']})
        }
      }
    });
  }

  newNotificationSuperior (user, screen, company){
    this.endpointService.getEgoNotificationSuperior(screen, user, company).subscribe(data =>{
      let newSuperior = data['response'];
      for(let x = 0; x < newSuperior.length; x++){
        if(this.notificationSuperior.find(sup => sup.id_plantilla_ego === newSuperior[x].id_plantilla_ego) === undefined){
          this.notificationSuperior.push(newSuperior[x]);
          //this.openSuperior.emit({id: newSuperior[x]['id_plantilla_ego'], noti: data['response']});
        }
      }
      this.superior.emit();
    });
  }

  clickMonitoreoEgo(idNot, idStep, idButton, iniDate, user){
    this.endpointService.clickEgoMonitoreo(idNot, idStep, idButton, this.lastArea, user, iniDate).subscribe(data=>{});
  }

  /*reset(){
    this.notifications = [];
    this.notificationSuperior = [];
  }*/

  public loadNotis() {
    setTimeout(() =>{ // Si han pasado % segundos y no ha terminado que ponga this.functionFinish a true de nuevo

      // Deberemos juntar los endpoints y hacer que las llamadas al backend no reemplace la array sino que la complemente pues si tienes cosas abiertas se cierra y vuleve a abrir (en notificaciones portal por ejemplo)

      // Portal (Notis Home)
      this.notificationsComponent.notis1.setNotifications(true);
      
      // EGO
      this.notificationsComponent.notis2.setEgoNotifications(); // Notificaciones superior derecha
      this.notiEGO.emit();
      this.newNotifications(this.authService.userId, this.routingService.moduleId, this.authService.getIdEmpresa()); // Notificaciones ego generales
      this.newNotificationSuperior(this.authService.userId, this.routingService.moduleId, this.authService.getIdEmpresa()); // Notificaciones superiores
      
      // Agenda
      this.notificationsComponent.notis3.setActivitiesNotifications(true);
      this.notificationsComponent.notis3.setPendingEvents();

      //this.loadNotis();
    } , 1000000);
  }}
