import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {EndpointService} from "../../../services/endpoint/endpoint.service";
import {AuthService} from "../../../services/auth/auth.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatStepper} from "@angular/material/stepper";
import {DomSanitizer} from "@angular/platform-browser";
import {StripePaymentComponent} from "../../../stripe/stripe-payment/stripe-payment.component";

@Component({
  selector: 'app-marketplace-general',
  templateUrl: './marketplace-general.component.html',
  styleUrls: ['./marketplace-general.component.scss']
})
export class MarketplaceGeneralComponent implements OnInit {

  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('stripePayment') stripePayment: StripePaymentComponent;

  public movins: number; // numero de movins que se disponen
  public stepperControl: number; // variable que sirve para controlar el index del stepper

  public modulo: any; // info del modulo seleccionado
  public moduloDependencias = []; // info de las dependencias del modulo determinado
  public modulosLista = []; // variable para guardar lista de los id de los modulos
  public carritoCompra = []; // variable para ir almacenando los elementos del carro
  public totalCarro: number;
  public productosRelacionados = [];
  public widgetsChecked: boolean;
  public teWidgets: boolean;

  public stepsArray = [];

  public movinsMinimasAComprar: number;

  public widgets: any; // info de los widgets

  public showAddMovinCoin = false; // Cargar el componente solo al entrar

  public verWidgets: boolean;
  public esWidget: boolean;

  // Variables auxiliares:
  public existeixenWidgets: boolean;
  public firstConfirm: boolean;

  // Checkout:
  public precioFinal: number;
  public numMovins: number;
  public suscripcion: number;

  // Descuentos
  public voucherCode;

  constructor(
    public dialogRef: MatDialogRef<MarketplaceGeneralComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { moduloInfo, movins, verWidgets, misModulos },
    private endpointService: EndpointService,
    public authService: AuthService,
    public snackBar: MatSnackBar,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.initialize();
  }

  public initialize(): void {
    this.data.verWidgets == 1 ? this.verWidgets = true : this.verWidgets = false;
    this.widgetsChecked = false;

    // TODO Z: Es mejor actualizar la query para que devuelva un 0 en vez de null. zZzZzz '$getMovinsMarketplaceQuery'
    if(this.data.movins != null) {
      this.movins = parseInt(this.data.movins, 10);
    } else {
      this.movins = 0;
    }

    this.modulo = this.data.moduloInfo;
    this.modulosLista.push(this.modulo.id_product);
    this.carritoCompra.push(this.modulo);
    this.insertarCarritoCompra();

    this.modulo.id_product_type == 2 ? this.esWidget = true : this.esWidget = false;

    this.totalCarro = parseFloat(this.modulo.precio);
    this.stepperControl = 0;
    this.existeixenWidgets = true;
    this.firstConfirm = true;

    // Recuperamos las dependencias de el modulo seleccionado
    this.endpointService.getDependenciesOfProduct(this.modulo.id_product, this.authService.inmoId, this.authService.getIdCompanyType(), this.authService.languageId, 1).subscribe(data => {
      if (data['errorCode'] === 0) {
        this.moduloDependencias = data['response'];
        (this.moduloDependencias).forEach((elem, index) => {
          this.modulosLista.push(elem.id_product);
          if (elem.cumpleDependencia === 0) this.firstConfirm = false;
        });
      }
    });

    // this.showCompraMovins = false;
    // this.loading = false;
    // this.carritoCompra.push(this.data.moduloInfo);
    // this.totalCompra = this.data.moduloInfo.precio;
    // this.movins = parseInt(this.data.movins, 10);

    // Para pasar directamente al step widgets
    if (this.verWidgets) {
      // this.getWidgets(0);
    }
  }

  public nextStep(i: number, actualStep: number): void {
    console.log("nextstep:" + actualStep + " hacia " + i);
    this.stepper.selectedIndex = i;
    this.stepsArray.push(actualStep);
  }

  public backStep(): void {
    const lastIndex = this.stepsArray.pop();
    this.stepper.selectedIndex = lastIndex;
  }

  public getWidgets(actualStep: number): void {
    if (this.widgetsChecked !== true) {
      this.teWidgets = true;
      this.endpointService.getWidgetsByList(this.modulosLista, this.authService.getIdCompany(), this.authService.getIdCompanyType(), this.authService.languageId, this.authService.adminArea.id_admin_area).subscribe(data => {
        if (data['errorCode'] === 0) {
          this.widgets = data['response'];

          // Si venimos de Ver Widgets, solo queremos mostrar los que no tenemos comprados
          let widgetsActuales;
          if (this.verWidgets) {
            widgetsActuales = this.data.misModulos.filter(m => m.id_product_type === 2);
          }

          Object.entries(this.widgets).forEach(([key, value]) => {
            if (this.verWidgets) {
              this.widgets[key] = this.widgets[key].filter(({ id_product: id1 }) => !widgetsActuales.some(({ id_product: id2 }) => id2 === id1));
            }

            // @ts-ignore
            value.forEach((e, i) => {
              if (e.cumpleRestriccion === 0) this.teWidgets = false;
              else { e.added = true; this.addWidgetCarro(e); }
            });
          });
          if (Object.keys(this.widgets).length > 0) {
            if (this.teWidgets) {
              this.nextStep(1, actualStep);
            }
            else {
              this.firstConfirm = false;
              console.log('mostrar mensaje de error, ya que algunos widgets estan restringidos.');
            }
          } else {
            this.nextStep(2, actualStep);
          }

          (this.moduloDependencias).forEach((elem, index) => {
            this.carritoCompra.push(elem);
            this.totalCarro += parseFloat(elem.precio);
          });

          // Calcular total carrito y evitar duplicados
          this.carritoCompra = Array.from(new Set(this.carritoCompra.map(a => a.id_product))).map(id_product => {
            return this.carritoCompra.find(a => a.id_product === id_product)
          })
          this.totalCarro = 0;
          this.carritoCompra.forEach(p => {
            this.totalCarro += parseFloat(p.precio);
          });

        }
      });
      this.widgetsChecked = true;
    } else {
      if (Object.keys(this.widgets).length > 0) {
        if (this.teWidgets) {
          this.nextStep(1, actualStep);
        }
        else {
          this.firstConfirm = false;
          console.log('mostrar mensaje de error, ya que algunos widgets estan restringidos.');
        }
      } else {
        this.nextStep(2, actualStep);
      }
    }
    console.log(this.widgets)
  }

  public confirmarCompra(actualStep: number): void {
    // Comprovar si es tenen Movins
    if (this.movins >= this.totalCarro) {
      this.movins = this.movins - this.totalCarro;
      if (this.verWidgets) this.carritoCompra.splice(0,1);
      this.endpointService.addProducts(this.authService.inmoId, this.authService.getIdCompanyType(), this.authService.userId, this.carritoCompra, this.totalCarro).subscribe(data => {
        if (data['errorCode'] === 0) {

          // Eliminar productos del carrito
          this.endpointService.deleteElementsCarrito(this.carritoCompra, this.authService.userId).subscribe(data => { });

          this.getProductosRelacionados();
          this.snackBar.open('¡Compra realizada con éxito!', 'X', {
            duration: 7000, panelClass: ['green-snackbar']
          });
        }
      });
    } else {
      // AQUÍ Z

      // SI NO CUESTA NADA PUES SE AÑADE Y LISTO. USAR EL ADD PRODUCT Y TAL
      if (this.totalCarro == 0) {
        this.endpointService.addProducts(this.authService.inmoId, this.authService.getIdCompanyType(), this.authService.userId, this.carritoCompra, this.totalCarro).subscribe(data => {
          if (data['errorCode'] === 0) {

            // Eliminar productos del carrito
            console.log("Borrar carrito")
            console.log(this.carritoCompra)
            this.endpointService.deleteElementsCarrito(this.carritoCompra, this.authService.userId).subscribe(data => { });

            this.getProductosRelacionados();
            this.snackBar.open('¡Módulo añadido con éxito!', 'X', {
              duration: 3000, panelClass: ['green-snackbar']
            });
          }
        });
      } else{
        // SI CUESTA ALGO PUES PAGAR Y LISTO
        this.showAddMovinCoin = true;
        this.nextStep(3, actualStep);
      }

    }
  }

  public cerrarStepper() {
    this.dialogRef.close([true]);
  }

  public cerrarStepperYAbrir(p: any): void {
    this.dialogRef.close([false, p]);
  }

  public openStripe($event: any): void {
    this.suscripcion = 0;
    this.numMovins = $event.movin;
    // this.precioFinal = $event.final; // Este es sin IVA
    this.precioFinal = $event.finalIva;
    this.stripePayment.pay(this.precioFinal, this.numMovins, this.suscripcion);
  }

  save(n: object) {
    //this.loading = true;
    if (n['error'] === 0) {
      /*this.endpointService.setPlanesMovin(
        this.authService.inmoId, this.authService.userId, this.numMovins, this.precioFinal,
        this.suscripcion, null, null, n['suscription'], 0, n['paid']).subscribe(data => {
        if (data['errorCode'] === 0) {
          this.comprarModulos(1);
        }
      });*/
      this.endpointService.insertPlanMovin(this.authService.getIdCompany(), this.authService.getIdCompanyType(), this.authService.userId, this.numMovins, this.precioFinal, n['paid']).subscribe(data => {
        if (data['errorCode'] === 0) {
          this.movins += this.numMovins;
          if (this.movins >= this.totalCarro) {
            this.movins = this.movins - this.totalCarro;
            this.endpointService.addProducts(this.authService.getIdCompany(), this.authService.getIdCompanyType(), this.authService.userId, this.carritoCompra, this.totalCarro).subscribe(data => {
              if (data['errorCode'] === 0) {
                this.getProductosRelacionados();
                this.snackBar.open('¡Compra realizada con éxito!', 'X', {
                  duration: 7000, panelClass: ['green-snackbar']
                });
              }
            });
          } else {
            this.snackBar.open('¡Oops! Parece que hubo un error...', 'X', {
              duration: 7000, panelClass: ['red-snackbar']
            });
            this.dialogRef.close(false);
          }
        }
      });
    } else {
      this.snackBar.open('¡Oops! Parece que hubo un error con tu solicitud', 'X', {
        duration: 4000, panelClass: ['red-snackbar']
      });
    }
  }

  public getProductosRelacionados(): void {
    this.endpointService.productosRelacionados(this.modulo.id_product, this.authService.inmoId, this.authService.getIdCompanyType(), this.authService.languageId, this.authService.adminArea.id_admin_area).subscribe(data => {
      if (data['errorCode'] === 0) {
        this.productosRelacionados = data['response'];
        if (this.productosRelacionados.length > 0) {
          this.nextStep(5, null);
        } else {
          this.cerrarStepper();
        }
      }
    });
  }

  public printLogInfo(): void {
    console.log('****************************************************************');
    console.log('movins: ');
    console.log(this.movins);
    console.log('stepperControl: ');
    console.log(this.stepperControl);
    console.log('modulo: ');
    console.log(this.modulo);
    console.log('dependencias: ');
    console.log(this.moduloDependencias);
    console.log('widgets: ');
    console.log(this.widgets);
    console.log('carro: ');
    console.log(this.carritoCompra);
    console.log('total carro: ', this.totalCarro);
    console.log('productos relacionados: ');
    console.log(this.productosRelacionados);
    console.log('stepsArray:');
    console.log(this.stepsArray);
    console.log('****************************************************************');
  }

  public getModuloNameById(key: string): string {
    const aux = this.carritoCompra.filter(elem => elem.id_product === parseInt(key, 10));
    return aux[0].nombre;
  }

  public addWidgetCarro(w: any) {
    w['added'] = true;
    this.carritoCompra.push(w);
    this.totalCarro += parseFloat(w.precio);
  }

  public quitarWidgetCarro(w: any) {
    const idWidget = w.id_product;
    w['added'] = false;
    const elem = this.carritoCompra.filter(elem => elem.id_product === idWidget);
    this.carritoCompra = this.carritoCompra.filter(elem => elem.id_product !== idWidget);
    console.log(elem);
    if (elem[0]['inserted'] !== undefined && elem[0]['inserted'] !== null) this.endpointService.updateSingleCarrito(elem[0]['inserted']).subscribe();
    this.totalCarro -= parseFloat(w.precio);
  }

  public widgetCarro(widget: any): void {
    if (widget.added === true) {
      this.addWidgetCarro(widget);
    } else if (widget.added === false) {
      this.quitarWidgetCarro(widget);
    }
  }

  public insertarCarritoCompra(): void {
    (this.carritoCompra).forEach((elem, index) => {
      let result = this.endpointService.insertarSingleCarrito(this.authService.inmoId, this.authService.getIdCompanyType(), this.authService.userId, elem, this.authService.languageId).subscribe(data => {
        if (result) this.carritoCompra[index] = elem;
        else delete this.carritoCompra[index];
        if (data['errorCode'] !== 0) {
          this.snackBar.open('¡Oops! Parece que hubo un error con tu solicitud', 'X', { duration: 4000, panelClass: ['red-snackbar'] });
        }
      });
    });
  }

  public applyVoucherCode() {
    this.endpointService.getVoucherCodeDiscounts(this.voucherCode, this.authService.adminArea, this.authService.getIdCompanyType()).subscribe(data => {
      if (data['errorCode'] === 0) {

        if (data['response'].length) {
          let intersection = this.carritoCompra.filter(item1 => data['response'].some(item2 => item1.id_product == item2.id_product));

          intersection.forEach(producto => {
            let descuento = data['response'].find(obj => { return obj.id_product === producto.id_product; });

            let price_discount = descuento.price_discount;
            let price_discount_perc = descuento.price_discount_perc;

            // Si el producto no tiene descuento o es inferior al nuevo
            if ((!producto.price_discount || price_discount > parseFloat(producto.price_discount)) || (!producto.price_discount_perc || price_discount_perc > parseFloat(producto.price_discount_perc))) {

              // Aplicar descuento
              producto.price_discount = price_discount;
              producto.price_discount_perc = price_discount_perc;

              // Calcular precio con descuento
              if (price_discount > 0) {
                producto.precio = parseFloat(producto.price_base) - parseFloat(price_discount);

              } else if (price_discount_perc > 0) {
                producto.precio = parseFloat(producto.price_base) * (1-(parseFloat(price_discount_perc)/100))
              }

              // Evitar negativos
              if (producto.precio < 0 || !producto.precio)
                producto.precio = 0;
            }
          });
          this.calculaTotal();
          this.snackBar.open('¡Descuento aplicado!', 'X', { duration: 4000, panelClass: ['green-snackbar'] });
        } else
          this.snackBar.open('¡El descuento no es válido!', 'X', { duration: 4000, panelClass: ['red-snackbar'] });
      } else
        this.snackBar.open('¡Oops! Parece que hubo un error con tu solicitud', 'X', { duration: 4000, panelClass: ['red-snackbar'] });
    });

    this.voucherCode = '';
  }

  calculaTotal() {
    this.totalCarro = 0;
    this.carritoCompra.forEach(elem => {
      if (elem.precio) this.totalCarro += parseFloat(elem.precio);
    });

    // Round to 2 decimals
    this.totalCarro = Number(this.totalCarro.toFixed(2));
  }
}
