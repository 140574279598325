import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import { RoutingService } from 'app/services/routing/routing.service';
import { GenericService } from 'app/services/generic/generic.service';
import { GenericCreatorService } from 'app/services/generic/custom/generic-creator.service';
import { FunctionsService } from 'app/services/functions/functions.service';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.css']
})
export class TabComponent implements OnInit, OnDestroy {
  @Input() structure = null;
  @Input() index = null;
  private monitoreo;
  public loaded = false;

  constructor(
      public functionsService: FunctionsService,
      private routingService: RoutingService,
      public genericService: GenericService,
      public genericCreatorService: GenericCreatorService) { }

  ngOnInit(): void {
    const id = this.routingService.urlHashtag;
    let finded = false;
    let count = -1;
    if(id && id !== 'undefined' && id !== 'null' && id !== '') {
      for(let i in this.structure[this.index]['child']){
        if(this.checkIfValidTab(this.structure[this.index]['child'][i], i)) {
          ++count;
          if(this.structure[this.index]['child'][i]['id_functional_area'] == id) {
            this.structure[this.index]['child'][i]['tabActiveNow'] = true;
            this.structure[this.index]['child'][0]['tabActive'] = count;
            finded = true;
            break;
          }
        }
      }
      count = -1;
    }
    if(!finded) {
      count = -1;
      for(let i in this.structure[this.index]['child']){
        if(this.checkIfValidTab(this.structure[this.index]['child'][i], i)) {
          ++count;
          if(this.structure[this.index]['child'][i]['tabActiveNow']) {
            this.structure[this.index]['child'][i]['tabActiveNow'] = true;
            this.structure[this.index]['child'][0]['tabActive'] = count;
            console.log("this.structure[this.index]['child'][i]['id_functional_area']", this.structure[this.index]['child'][i]['id_functional_area'])
            finded = true;
            break;
          }
        }
      }
    }
    if(!finded) {
      for(let i in this.structure[this.index]['child']){
        if(this.checkIfValidTab(this.structure[this.index]['child'][i], i)) {
          this.structure[this.index]['child'][i]['tabActive'] = 0;
          this.structure[this.index]['child'][i]['tabActiveNow'] = true;
          break;
        }
      }
    }
    console.log("adadasd", this.structure[this.index], this.structure[this.index]['child']);
    /*this.endPointService.insertMonitoringInformation(
      this.authService.getLoggedInUserId(), this.structure[this.index]['child'][this.structure[this.index]['child'][0]['tabActive']]['id_functional_area'], this.structure[this.index]['child'][this.structure[this.index]['child'][0]['tabActive']]['id_functional_area'], this.genericService.staticHTML).subscribe(data => {
      this.monitoreo = data['response'];
    });*/
    this.loaded = true;
  }

  ngOnDestroy() {
    //this.endPointService.updateMonitoringInformation(this.monitoreo, this.genericService.staticHTML).subscribe(data => {});
  }

  getIdByIndex(index) : string{
    let count = -1;
    for(let i in this.structure[this.index]['child']) {
      if(this.structure[this.index]['child'][i]['id_functional_type'] == 4 && this.structure[this.index]['child'][i]['child'] && this.genericService.evaluateIfElements(this.structure[this.index], i) && this.structure[this.index]['child'][i]['id_functional_status_general'] != 2) {
        ++count;
        if(index == count) {
          if(this.structure[this.index]['child'][i]['creatingMode']){
            return this.structure[this.index]['child'][i]['idTmp'];
          } else {
            return this.structure[this.index]['child'][i]['id_functional_area'];
          }
        }
      }
    }
  }

  onTabChange(event){
    let id = this.getIdByIndex(event['index']);
    if(id){
      console.log("id", id, event, this.structure[this.index]['child'])
      for(let i in this.structure[this.index]['child']) {
        if(this.structure[this.index]['child'][i]['creatingMode']){
          this.structure[this.index]['child'][i]['tabActiveNow'] = (id == this.structure[this.index]['child'][i]['idTmp']);
        } else {
          this.structure[this.index]['child'][i]['tabActiveNow'] = (id == this.structure[this.index]['child'][i]['id_functional_area']);
        }
      }


      /*if(this.structure[this.index]['child'][event['index']]['monitoreo'] != 0) {
        const url = this.routingService.urlWithoutHashtag;
        this.location.replaceState(url + '#' + id);
        this.endPointService.insertMonitoringInformation(
          this.authService.getLoggedInUserId(), id, id, this.genericService.staticHTML).subscribe(data => {
          this.monitoreo = data['response'];
        });
        if(this.monitoreo != id){
          this.endPointService.updateMonitoringInformation(this.monitoreo, this.genericService.staticHTML).subscribe(data => {
          });
        }
      }*/
    }
  }

  checkIfValidTab(element, key) {
    return element['id_functional_type'] == 4 && (element['child'] || this.structure[this.index]['creatingMode']) && this.genericService.evaluateIfElements(this.structure[this.index], key) && element['id_functional_status_general'] != 2 && this.genericService.checkChildActive(element, false, false);
  }
}
