    <!--<div *ngFor="let e of authService.arrayProductos | keyvalue: genericService.returnZero; let i = index;" class="product-row">
    <ng-container *ngFor="let a of authService.arrayProductos[e.key] | keyvalue: genericService.returnZero; let j = index;">
        <img *ngIf="checkIfIsValue(1, 'rest_productos_imagenes', 'url', authService.arrayProductos[e.key][a.key])" fxFlex="30" style="object-fit: cover;" src="{{authService.arrayProductos[e.key][a.key]['value']}}">
    </ng-container>
    <div fxFlex="70" style="padding-left: 10px;">
        <ng-container *ngFor="let a of authService.arrayProductos[e.key] | keyvalue: genericService.returnZero; let j = index;">
            <p class="product-title" *ngIf="checkIfIsValue(1, 'rest_productos', 'nombre', authService.arrayProductos[e.key][a.key])">
                {{authService.arrayProductos[e.key][a.key]['value']}} 
            </p>
            <div *ngIf="checkIfIsValue(1, 'rest_productos', 'precio', authService.arrayProductos[e.key][a.key])">
                {{authService.arrayProductos[e.key][a.key]['field']}}
                {{authService.arrayProductos[e.key][a.key]['value']}} €
            </div>
            <div *ngIf="checkIfIsValue(1, 'rest_pedidos', 'id_mesa', authService.arrayProductos[e.key][a.key])">
                {{authService.arrayProductos[e.key][a.key]['field']}}
                {{authService.arrayProductos[e.key][a.key]['value']}} 
            </div>
            <div *ngIf="checkIfIsValue(1, 'rest_pedidos_productos_opciones_preparacion', 'id_opcion', authService.arrayProductos[e.key][a.key]) && authService.arrayProductos[e.key][a.key]['value'].length && authService.arrayProductos[e.key][a.key]['value'].indexOf('') === -1">
                {{authService.arrayProductos[e.key][a.key]['label']}}
                {{authService.arrayProductos[e.key][a.key]['value_label']}} 
            </div>
            <div *ngIf="checkIfIsValue(1, 'rest_pedidos_productos', 'cantidad', authService.arrayProductos[e.key][a.key]) is true">
                {{authService.arrayProductos[e.key][a.key]['field']}}
                {{authService.arrayProductos[e.key][a.key]['value']}} 
            </div>
            <div *ngIf="checkIfIsValue(1, 'rest_pedidos_productos', 'importe', authService.arrayProductos[e.key][a.key]) is true">
                {{authService.arrayProductos[e.key][a.key]['field']}}
                {{authService.arrayProductos[e.key][a.key]['value']}} €
            </div>
            <div *ngIf="checkIfIsValue(1, 'rest_pedidos_productos', 'comentario', authService.arrayProductos[e.key][a.key]) is true && authService.arrayProductos[e.key][a.key]['value'] != ''">
                {{authService.arrayProductos[e.key][a.key]['field']}}
                {{authService.arrayProductos[e.key][a.key]['value']}} 
            </div>
            <div *ngIf="checkIfIsValue(1, 'rest_pedidos_productos_opciones_medidas', 'id_opcion', authService.arrayProductos[e.key][a.key]) && authService.arrayProductos[e.key][a.key]['value'].length && authService.arrayProductos[e.key][a.key]['value'].indexOf('') === -1">
                {{authService.arrayProductos[e.key][a.key]['label']}}
                {{authService.arrayProductos[e.key][a.key]['value_label']}} 
            </div>
            <div *ngIf="checkIfIsValue(1, 'rest_pedidos_productos_opciones_suplementos', 'id_opcion', authService.arrayProductos[e.key][a.key]) && authService.arrayProductos[e.key][a.key]['value'].length && authService.arrayProductos[e.key][a.key]['value'].indexOf('') === -1">
                {{authService.arrayProductos[e.key][a.key]['label']}}
                {{authService.arrayProductos[e.key][a.key]['value_label']}} 
            </div>
            <div *ngIf="checkIfIsValue(1, 'rest_pedidos_productos_opciones_opciones', 'id_opcion', authService.arrayProductos[e.key][a.key]) && authService.arrayProductos[e.key][a.key]['value'].length && authService.arrayProductos[e.key][a.key]['value'].indexOf('') === -1">
                {{authService.arrayProductos[e.key][a.key]['label']}}
                {{authService.arrayProductos[e.key][a.key]['value_label']}} 
            </div>
        </ng-container>
    </div>
</div>-->

<div fxLayout="row wrap" *ngIf="loaded">
    <div fxFlex="33.33" fxFlex.sm="100" fxFlex.xs="100" *ngFor="let e of arrayProducts | keyvalue: genericService.returnZero; let i = index;" class="product-row">
        <div class="product-row-2">
            <img *ngIf="arrayProducts[e.key].image" fxFlex="35" class="product-img" src="{{arrayProducts[e.key].image}}">
            <div fxFlex="65" style="padding: 10px;">    
                <p class="product-title">{{arrayProducts[e.key].name}}</p>
                <span class="product-quantity">
                    <text class="material-icons decrease quantity" text-anchor="middle" dominant-baseline="central" (click)="decreaseQuantity(e.key)">do_not_disturb_on</text>
                    <span class="product-quantity"> {{arrayProducts[e.key].quantity}} </span>
                    <text class="material-icons increase quantity" text-anchor="middle" dominant-baseline="central" (click)="increaseQuantity(e.key)">add_circle</text>
                </span>
                <p class="product-unit-price">{{arrayProducts[e.key].unit_price}}€</p>
                <p *ngIf="arrayProducts[e.key].measures != []" class="product-measures">{{arrayProducts[e.key].measures}}</p>
                <p *ngIf="arrayProducts[e.key].supplements != []" class="product-supplements">{{arrayProducts[e.key].supplements}}</p>
                <p *ngIf="arrayProducts[e.key].options != []" class="product-options">{{arrayProducts[e.key].options}}</p>
                <p *ngIf="arrayProducts[e.key].preparations != []" class="product-preperation">{{arrayProducts[e.key].preparations}}</p>
                <p *ngIf="arrayProducts[e.key].comment" class="product-comment">{{arrayProducts[e.key].comment}}</p>
                <p *ngIf="!arrayProducts[e.key].comment" class="product-comment-no">No extra comments</p>
                <p class="product-price">{{arrayProducts[e.key].price}}€</p>
            </div>
        </div>
    </div>
</div>
<mat-spinner style="margin:0 auto;" diameter="20" *ngIf="!loaded"></mat-spinner>