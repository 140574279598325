<div class="loggin" ngClass.sm="loggin2" ngClass.xs="loggin2"> <!--*ngIf="!authService.userId">-->
    <form fxLayout="row wrap" fxFlex="100">
      <a *ngIf="validate" style="font-weight: 700; margin-top: 2em; text-align: right; width: 100%; font-size: 11px; text-transform: uppercase;" (click)="changeEmail()">{{strings.CHANGE_EMAIL}}</a>
      <mat-form-field ngStyle.sm="padding: 0.5em 0;" ngStyle.xs="padding: 0.5em 0;" appearance="outline" fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <input matInput placeholder="Introduce tu email" required autocomplete [(ngModel)]="user" [ngModelOptions]="{standalone: true}" [disabled]="validate">
      </mat-form-field>

      <a *ngIf="validate" style="font-weight: 700; margin-top: 2em; text-align: right; width: 100%; font-size: 11px; text-transform: uppercase;" (click)="recoverPasswordClick()">{{strings.RECOVER_PASSWORD}}</a>
      <mat-form-field *ngIf="validate" ngStyle.sm="padding: 0.5em 0;" ngStyle.xs="padding: 0.5em 0;" appearance="outline" fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
        <input matInput placeholder="Introduce tu contraseña" [type]="hide ? 'password' : 'text'" required [(ngModel)]="password" [ngModelOptions]="{standalone: true}">
        <div class="mat-icon-button" matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
          <mat-icon style="font-size: 14px;">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </div>
      </mat-form-field>

      <button *ngIf="!validate" fxFlex="100" fxFlex.sm="100" fxFlex.xs="100" name="go" (click)="validateEmail()">{{strings.GO}}</button>
      <button *ngIf="validate" fxFlex="100" fxFlex.sm="100" fxFlex.xs="100" name="go" (click)="loginUser()">{{strings.GO}}</button>
    </form>
</div>