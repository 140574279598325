<div *ngIf="!showReport">
<!--  <button (click)="queueEmail()">ENVIAR EMAIL PENDIENTE</button>-->
  <div class="example-container mat-elevation-z3 taulesgrans" style="overflow: auto; height: calc(100vh - 5.2em - 2em);">
    <table mat-table [dataSource]="dataSourcePlantilla" class="mat-elevation-z8" matSort>

      <ng-container matColumnDef="operations">
        <th mat-header-cell *matHeaderCellDef class="buttons-head">
          <button style="width: 100%;" (click)="addNewWorkflowReport()" matTooltip="Nueva plantilla">
            <i class="material-icons">add</i>
            <i class="material-icons">feedback</i>
          </button>
        </th>
        <td mat-cell *matCellDef="let element">
          <button click-stop-propagation (click)="editWorkflowReport(element.id)" class="btn btn-warning erp-status-btn"><i class="material-icons">create</i></button>
          <!-- NOTA: QUAN EL MODAL PER ELIMINAR UN EVENT ESTIGUI BÉ, CRIDAR A deleteWorkflowEvent(element.id) en lloc de confirmDelete -->
          <button click-stop-propagation (click)="confirmDelete(element.id)" class="btn btn-danger erp-status-btn">
            <i class="material-icons">delete_forever</i>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{strings.NAME}} </th>
        <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{strings.TYPE}} </th>
        <td mat-cell *matCellDef="let element"> {{element.tipo}} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{strings.STATUS}} </th>
        <td mat-cell *matCellDef="let element"> {{eventStatusList[element.estado]}} </td>
      </ng-container>

      <ng-container matColumnDef="creator">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{strings.CREATOR}} </th>
        <td mat-cell *matCellDef="let element"> {{element.usuario}} </td>
      </ng-container>

      <ng-container matColumnDef="modification_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{strings.MODIFICATION_DATE}} </th>
        <td mat-cell *matCellDef="let element"> {{element.fecha_creacion}} </td>
      </ng-container>

      <ng-container matColumnDef="execute_ego_cron">
        <th mat-header-cell *matHeaderCellDef class="buttons-head">
          <button style="width: 100%;" (click)="executeCron()" matTooltip="Ejecutar CRON">
            <i class="material-icons">send</i>
          </button>
        </th>
        <td mat-cell *matCellDef="let element"></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row (click)="editWorkflowReport(row.id)" *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
  </div>
  <div class="bottom-table">
    <div class="bottom-table2">
      <mat-form-field class="input-filter">
        <input matInput [(ngModel)]="inputFilter" (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
      </mat-form-field>
    </div>
    <mat-paginator style="width: 50%;" [pageSizeOptions]="[50,100,200]"></mat-paginator>
  </div>
</div>

  <div *ngIf="showReport" class="row; container-fluid">
    <dynamic-reportdetails #DynamicReportDetailsComponent
                  [selectedID]="selectedID"
                  [bodyReport]="bodyReport"
                  [containerArray]="containerArray"
                  [buttonArray]="buttonArray"
                  [edit]="edit"
                  [dropContainerArray]="dropContainerArray"
                  [selectedEvent]="selectedEvent"
                  [idEvent]="idEvent"
                  [plantillasArray]="plantillasArray"
                  [stepArray]="stepArray"
                  [dropStepArray]="dropStepArray"
                  [iconArray]="iconArray"
                  [notification]="notification"
                  [notificationSuperior]="notificationSuperior"
                  [notificationSuperiorHome]="notificationSuperiorHome"
                  [journeyArray]="journeyArray">
    </dynamic-reportdetails>
  </div>

<auto-plantilla #AutoPlantillaComponent>

</auto-plantilla>


