import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { EndpointService } from "../../services/endpoint/endpoint.service";
import { AuthService } from "../../services/auth/auth.service";
import { Values } from "../../../values/values";

@Component({
  selector: 'app-stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['./stripe-payment.component.css']
})
export class StripePaymentComponent implements OnInit {

  @Output() sendBuyEvent = new EventEmitter();

  handler: any = null;

  constructor(private endpointService: EndpointService,
              public authService: AuthService) { }

  ngOnInit(): void {
    this.loadStripe();
  }

  // Cargar el script de stripe
  loadStripe() {
    if(!window.document.getElementById('stripe-script')) {
      let s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://checkout.stripe.com/checkout.js";
      window.document.body.appendChild(s);
    }
  }

  pay(amount: number, quantity: number, suscription: number): any {
    const description =  quantity + ' movins';

    this.endpointService.checkInmoStripe(this.authService.getIdCompany()).subscribe(data1 => {
;      if(data1['errorCode'] == 0) {

          this.endpointService.getMovinsInmo(this.authService.getIdCompany(), this.authService.getIdCompanyType()).subscribe(t => {
            if ((t['response'][0].total_movins == 0 && suscription == 1) || suscription == 0) {
              this.endpointService.chargePaymentStripe(null, amount, description, this.authService.getIdCompany()).subscribe(data2 => {
                console.log(data2);
                if(data2['errorCode'] == 200) {
                  this.sendBuyEvent.emit({'suscription': suscription, 'paid': 1, 'error': 0});
                } else {
                  this.sendBuyEvent.emit({'suscription': suscription, 'paid': 0, 'error': 1});
                }
              });
            } else {
              this.sendBuyEvent.emit({'suscription': suscription, 'paid': 0, 'error': 0});
            }
          });
      } else {

        this.handler = (<any>window).StripeCheckout.configure({
          key: Values.STRIPE_KEY,
          // image: 'https://movin.cloud/assets/img/logo.png',
          locale: 'ES',
          currency: 'EUR',
          token: (token: any) => {

            this.endpointService.chargePaymentStripe(token, amount, description, this.authService.getIdCompany()).subscribe(data2 => {
              console.log(data2);
              if(data2['errorCode'] == 200) {
                this.sendBuyEvent.emit({'suscription': suscription, 'paid': 1, 'error': 0});
              } else {
                this.sendBuyEvent.emit({'suscription': suscription, 'paid': 0, 'error': 1});
              }
            });

          }
        });

        this.handler.open({
          name: 'Movin',
          description: 'Comprar ' + description,
          amount: amount * 100
        });

      }
    });



  }
}
