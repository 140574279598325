import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { EndpointService } from "../../services/endpoint/endpoint.service";
import { AuthService } from "../../services/auth/auth.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import {AutoPlantillaComponent} from "../../auto-plantilla/auto-plantilla.component";
import {ChatService} from "../../services/chat.service";
import {Values} from "../../../values/values";

@Component({
  selector: 'app-inmo-card',
  templateUrl: './inmo-card.component.html',
  styleUrls: ['./inmo-card.component.scss']
})
export class InmoCardComponent implements OnInit {

  @Output() event = new EventEmitter();

  @Input() inmo: any; // info de la inmo
  @Input() tipo: any; // indica el estado: 1->nada/rechazado, 2->colaborando, 3->pendiente
  @Input() esMLS: any; // indica si es una mls/grupo (1) o no (0)
  @Input() type: any; // indica si somocs inmo (1) o somos grupo (2)
  @Input() permisos: any; // indica si tenemos permisos o no, para enviar colaboraciones
  @Input() structure;

  public showMessageButton: boolean;
  public showFollowButton: boolean;
  public showInmoCollaborateButton: boolean;
  public showGroupCollaborateButton: boolean;

  // Variables para los botones: etiquetaX (nombre del botón), accionX (id de la acción al pulsar), showEtiquetaX (para mostrar o no el boton)
  public etiqueta1: string;
  public accion1: number;
  public showEtiqueta1 = true;

  public etiqueta2: string;
  public accion2: number;
  public showEtiqueta2 = true;
  public enviarMensajeBloqueado = false;
  @ViewChild('AutoPlantillaComponent') public AutoPlantillaComponent: AutoPlantillaComponent;

  public etiqueta3: string;
  public showEtiqueta3 = false;
  public routes = Values.ROUTES;


  public etiquetasFinished = false;

  // Logros
  public logrosBody;
  public isBasic = false;
  public isSilver = false;
  public isGolden = false;

  constructor(
    private router: Router,
    private endpointService: EndpointService,
    public authService: AuthService,
    public chatService: ChatService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.checkStructure();
    this.establecerEtiquetas();
  }

  private establecerEtiquetas(): void {
    // ETIQUETA 1
      if (this.showInmoCollaborateButton) { // YO soy una inmo
        if (this.esMLS === 0) { // la carta es la de una inmo
          switch (this.tipo) {
            case 1: { this.etiqueta1 = 'Colaborar'; this.accion1 = 1; break; }
            case 2: { this.etiqueta1 = 'Colaborando'; this.accion1 = 2; break; }
            case 3: {
              if (this.inmo.esSolicitada === 1) { this.etiqueta1 = 'Acceptar'; this.accion1 = 3; break; }
              else if (this.inmo.esSolicitada === 0) { this.etiqueta1 = 'Pendiente'; this.accion1 = 4; break; }
            }}
        } else if (this.esMLS === 1) { // la carta es la de un grupo
          switch (this.tipo) {
            case 1: { this.etiqueta1 = 'Unirme'; this.accion1 = 5; break; }
            case 2: { this.etiqueta1 = 'Miembro'; this.accion1 = 6; break; }
            case 3: {
              if (this.inmo.esSolicitada === 1) { this.etiqueta1 = 'Acceptar'; this.accion1 = 7; break; }
              else if (this.inmo.esSolicitada === 0) { this.etiqueta1 = 'Pendiente'; this.accion1 = 8; break; }
            }}
        }
      } else if (this.showGroupCollaborateButton) { // YO soy un grupo
        if (this.esMLS === 0) { // la carta es la de una inmo
          switch (this.tipo) {
            case 1: { this.etiqueta1 = 'Invitar'; this.accion1 = 9; break; }
            case 2: { this.etiqueta1 = 'Miembro'; this.accion1 = 10; break; }
            case 3: {
              if (this.inmo.esSolicitada === 1) { this.etiqueta1 = 'Acceptar'; this.accion1 = 11; break; }
              else if (this.inmo.esSolicitada === 0) { this.etiqueta1 = 'Pendiente'; this.accion1 = 12; break; }
            }}
        } else if (this.esMLS === 1) { // la carta es la de un grupo
          this.showEtiqueta1 = false;
        }
      }


    if (this.tipo === 4) {
      // No mostrar botón de acciones (primer boton) ya que cuando tipo === 4, es
      // porque el usuario no tiene permitido colaborar (usuarios.puede_colaborar === 0)
      this.showEtiqueta1 = false;
    }

    // ETIQUETA 2 -> mensajes
    if (this.inmo.mensajes === 1 || (this.inmo.mensajes === 2 && this.tipo === 2)) {
      /*this.etiqueta2 = 'Mensaje';*/ this.accion2 = 1; this.enviarMensajeBloqueado = false;
    } else if (this.inmo.mensajes === 2 && this.tipo !== 2) {
      /*this.etiqueta2 = 'Mensaje';*/ this.accion2 = null; this.enviarMensajeBloqueado = true;
    }

    // ETIQUETA 3 -> colaborando a traves de grupo
    if (this.inmo.esMLS != 1 && this.inmo.colaboraGrup === 1) {
      this.etiqueta3 = "Colaborando a través de un grupo"
      this.showEtiqueta3 = true;
    }

    if (this.tipo === 3 && (this.showInmoCollaborateButton || this.showGroupCollaborateButton)) {
      if (this.inmo.esSolicitada === 1) { this.etiqueta2 = 'Rechazar'; this.accion2 = 13; this.enviarMensajeBloqueado = false; }
    }

    // Cargar Datos de Logros.
    let promise = new Promise<void>((resolve) => {
      if (!this.esMLS) { // Soc una Inmo
        this.endpointService.getLogrosData(this.inmo.id, true).subscribe(data=>{
          this.logrosBody = data['response'][0];
          resolve();
        });
      } else {
        this.endpointService.getLogrosData(this.inmo.id, false).subscribe(data=>{
          this.logrosBody = data['response'][0];
          resolve();
        });
      }
    });
    promise.then(e => {
      if (this.logrosBody['basic'] == 1) {
        this.isBasic = true;
      } else if (this.logrosBody['silver'] == 1) {
        this.isSilver = true;
      } else if (this.logrosBody['golden'] == 1) {
        this.isGolden = true;
      }
    });

    this.etiquetasFinished = true;
  }

  private establecerEtiquetasOld(): void {
    // ETIQUETA 1
    if (this.permisos) { // Comprueva si el usuario tiene permisos para invitar/acceptar/etc
      if (this.type === 1) { // YO soy una inmo
        if (this.esMLS === 0) { // la carta es la de una inmo
          switch (this.tipo) {
            case 1: { this.etiqueta1 = 'Colaborar'; this.accion1 = 1; break; }
            case 2: { this.etiqueta1 = 'Colaborando'; this.accion1 = 2; break; }
            case 3: {
              if (this.inmo.esSolicitada === 1) { this.etiqueta1 = 'Acceptar'; this.accion1 = 3; break; }
              else if (this.inmo.esSolicitada === 0) { this.etiqueta1 = 'Pendiente'; this.accion1 = 4; break; }
            }}
        } else if (this.esMLS === 1) { // la carta es la de un grupo
          switch (this.tipo) {
            case 1: { this.etiqueta1 = 'Unirme'; this.accion1 = 5; break; }
            case 2: { this.etiqueta1 = 'Miembro'; this.accion1 = 6; break; }
            case 3: {
              if (this.inmo.esSolicitada === 1) { this.etiqueta1 = 'Acceptar'; this.accion1 = 7; break; }
              else if (this.inmo.esSolicitada === 0) { this.etiqueta1 = 'Pendiente'; this.accion1 = 8; break; }
            }}
        }
      } else if (this.type === 2) { // YO soy un grupo
        if (this.esMLS === 0) { // la carta es la de una inmo
          switch (this.tipo) {
            case 1: { this.etiqueta1 = 'Invitar'; this.accion1 = 9; break; }
            case 2: { this.etiqueta1 = 'Miembro'; this.accion1 = 10; break; }
            case 3: {
              if (this.inmo.esSolicitada === 1) { this.etiqueta1 = 'Acceptar'; this.accion1 = 11; break; }
              else if (this.inmo.esSolicitada === 0) { this.etiqueta1 = 'Pendiente'; this.accion1 = 12; break; }
            }}
        } else if (this.esMLS === 1) { // la carta es la de un grupo
          this.showEtiqueta1 = false;
        }
      }
    } else {
      // Usuario no es responsable de la community --> boton Recomendar
      if(this.tipo == 2){
        // Dentro de colaboraciones, no podemos recomendar.
        this.etiqueta1 = 'Miembro'; this.accion1 = null;
      } else {
        // Podemos recomandar.
        this.etiqueta1 = 'Recomendar';
        this.accion1 = 14;
      }
    }

    if (this.tipo === 4) {
      // No mostrar botón de acciones (primer boton) ya que cuando tipo === 4, es
      // porque el usuario no tiene permitido colaborar (usuarios.puede_colaborar === 0)
      this.showEtiqueta1 = false;
    }

    // ETIQUETA 2 -> mensajes
    if (this.inmo.mensajes === 1 || (this.inmo.mensajes === 2 && this.tipo === 2)) {
      /*this.etiqueta2 = 'Mensaje';*/ this.accion2 = 1; this.enviarMensajeBloqueado = false;
    } else if (this.inmo.mensajes === 2 && this.tipo !== 2) {
      /*this.etiqueta2 = 'Mensaje';*/ this.accion2 = null; this.enviarMensajeBloqueado = true;
    }

    // ETIQUETA 3 -> colaborando a traves de grupo
    if (this.inmo.esMLS != 1 && this.inmo.colaboraGrup === 1) {
      this.etiqueta3 = "Colaborando a través de un grupo"
      this.showEtiqueta3 = true;
    }

    if (this.tipo === 3) {
      if (this.inmo.esSolicitada === 1) { this.etiqueta2 = 'Rechazar'; this.accion2 = 13; this.enviarMensajeBloqueado = false; }
    }

    // Cargar Datos de Logros.
    let promise = new Promise<void>((resolve) => {
      if (!this.esMLS) { // Soc una Inmo
        this.endpointService.getLogrosData(this.inmo.id, true).subscribe(data=>{
          this.logrosBody = data['response'][0];
          resolve();
        });
      } else {
        this.endpointService.getLogrosData(this.inmo.id, false).subscribe(data=>{
          this.logrosBody = data['response'][0];
          resolve();
        });
      }
    });
    promise.then(e => {
      if (this.logrosBody['basic'] == 1) {
        this.isBasic = true;
      } else if (this.logrosBody['silver'] == 1) {
        this.isSilver = true;
      } else if (this.logrosBody['golden'] == 1) {
        this.isGolden = true;
      }
    });

    this.etiquetasFinished;
  }

  public toPerfil(inmo) {
    this.event.emit(inmo);
  }

  public ejecutarAccion(accion: number): void {
    // Nomes s'ha d'obir dialog quan es cancela colaboració (ja sigui que la colaboracio ja existeix o be quan esta pendiente (la has enviat tu))
    // id accion --> 2, 4, 6, 8, 10, 12
    this.etiquetasFinished = false;

    if (accion === 2 || accion === 4 || accion === 6 || accion === 8 || accion === 10 || accion === 12) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, { disableClose: true , data: { tipo: accion } });

      dialogRef.afterClosed().subscribe(confirmar => {
        if (confirmar === true) {
          switch (accion) {
            case 2: { this.updateColaboracion(this.inmo.colaborationId, 2); break; } // Inmo --> cancelo colaboracion con inmo
            case 4: { this.updateColaboracion(this.inmo.colaborationId, 2); break; } // Inmo --> cancelo solicitud que estaba pendiente
            case 6: { this.updateGrupo(this.inmo.colaborationId, 2, 8); break; } // Inmo --> cancelo (dejo) un grupo
            case 8: { this.updateGrupo(this.inmo.colaborationId, 2); break; } // Inmo --> cancelo solicitud que estaba pendiente
            case 10: { this.updateGrupo(this.inmo.colaborationId, 2, 11); break; } // Grupo --> expulso a una inmo
            case 12: { this.updateGrupo(this.inmo.colaborationId, 2); break; } // Grupo --> cancelo solicitud (de invitación a una inmo) que estaba pendiente
          }
        }
        else this.etiquetasFinished = true;
      });
    } else {
      switch (accion) {
        case 1: { this.solicitarColaboracion(this.inmo.id); break; } // Inmo --> solicito colaborar a inmo
        // case 1: { this.solicitarColaboracion(this.inmo.id); break; } // Inmo --> solicito colaborar a inmo
        case 3: { this.updateColaboracion(this.inmo.colaborationId, 1); break; } // Inmo --> acepto solicitud de colaboración
        case 5: { this.invitarInmo(this.inmo.id, this.authService.inmoId, 0); break; } // Inmo --> solicito unirme grupo
        case 7: { this.updateGrupo(this.inmo.colaborationId, 1); break; } // Inmo --> acepto la invitación de un grupo
        case 9: { this.invitarInmo(this.authService.groupId, this.inmo.id, 1); break; } // Grupo --> invito a una inmo
        case 11: { this.updateGrupo(this.inmo.colaborationId, 1); break; } // Grupo --> acepto una inmo al grupo
        case 13: { this.rechazarSolicitud(this.inmo); break; } // Inmo, Grupo --> rechaza solicitud que estaba pendiente
      }
    }
  }

  public rechazarSolicitud(inmo: any): void {
    if (this.type === 1) { // Soy una inmo
      if (inmo.esMLS === 0) { // rechazo colaboracion de una inmo
        this.updateColaboracion(inmo.colaborationId, 2);
      } else { // rechazo colaboracion de un grupo
        this.updateGrupo(this.inmo.colaborationId, 2);
      }
    } else { // Soy un grupo
      if (inmo.esMLS === 0) { // rechazo colaboracion de una inmo
        this.updateGrupo(this.inmo.colaborationId, 2);
      }
    }
  }

  // EGO
  public solicitarColaboracion(idInmoSolicitada: number): void {
    const estado = (this.authService.inmoTipoPerfil === 1 && this.inmo.tipo_perfil === 1) ? 1 : 0;
    const esPrivada = (this.authService.inmoTipoPerfil === 1 && this.inmo.tipo_perfil === 1) ? 0 : 1;
    const body = { inmoSolicitante: this.authService.inmoId, inmoSolicitada: idInmoSolicitada,
      idUsuario: this.authService.userId, estado, esPrivada};
    this.AutoPlantillaComponent.egoButton(2, body).then(res => {
      if (res['errorCode'] === 0) {
        this.inmo.colaborationId = res['response'];
        if (estado === 1) {
          this.tipo = 2;
        } else {
          this.inmo.esSolicitada = 0;
          this.tipo = 3;
        }
        this.establecerEtiquetas();
      }
    });
  }

  /*
  // No EGO
  public solicitarColaboracion(idInmoSolicitada: number): void {
    const estado = (this.authService.inmoTipoPerfil === 1 && this.inmo.tipo_perfil === 1) ? 1 : 0;
    const esPrivada = (this.authService.inmoTipoPerfil === 1 && this.inmo.tipo_perfil === 1) ? 0 : 1;

    this.endpointService.solicitarColaboracion(this.authService.inmoId, idInmoSolicitada, this.authService.userId, estado, esPrivada).subscribe(data => {
      if (data['errorCode'] === 0) {
        this.inmo.colaborationId = data['response'];
        if (estado === 1) {
          this.tipo = 2;
        } else {
          this.inmo.esSolicitada = 0;
          this.tipo = 3;
        }
        this.establecerEtiquetas();
      }
    });
  }
 */

  public updateColaboracion(colaborationId: number, estado: number): void {
    this.endpointService.actualizarColaboracion(colaborationId, estado).subscribe(data => {
      if (data['errorCode'] === 0) {
        if (estado === 2) this.tipo = 1;
        else if (estado === 1) this.tipo = 2;
        this.establecerEtiquetas();
      }
    });
  }

  public invitarInmo(idGrupo: number, idInmo: number, invitada: number): void {
    let estado = 0;
    if (this.type === 1) { // Somos inmo
      estado = (this.authService.inmoTipoPerfil === 1 && this.inmo.tipo_perfil === 1) ? 1 : 0;
    } else { // Somos grupo
      estado = (this.authService.groupTipoPerfil === 1 && this.inmo.tipo_perfil === 1) ? 1 : 0;
    }

    let body = {idGrupo: idGrupo, idInmo: idInmo, invitada: invitada, userId: this.authService.userId, estado: estado};
    this.AutoPlantillaComponent.egoButton(invitada === 0 ? 5 : 6, body).then(res => {
      if (res['errorCode'] === 0) {
        this.inmo.colaborationId = res['response'];
        if (estado === 1) {
          this.tipo = 2;
        } else {
          this.inmo.esSolicitada = 0;
          this.tipo = 3;
        }
        this.establecerEtiquetas();
      }
    });

    /*this.endpointService.solicitarGrupo(idGrupo, idInmo, invitada, this.authService.userId, estado).subscribe(data => {

    });*/
  }

  public updateGrupo(colaborationId: number, estado: number, idAction?: number): void {
    if (idAction) {
      let body = {colaborationId: colaborationId, estado: estado};
      this.AutoPlantillaComponent.egoButton(idAction, body).then(res => {
        if (res['errorCode'] === 0) {
          switch (estado) {
            case 1: {
              this.tipo = 2;
              break;
            }
            case 2: {
              this.tipo = 1;
              break;
            }
          }
          this.establecerEtiquetas();
        }
      });
    }
    else {
      this.endpointService.actualizarGrupo(colaborationId, estado).subscribe(data => {
        if (data['errorCode'] === 0) {
          switch (estado) {
            case 1: {
              this.tipo = 2;
              break;
            }
            case 2: {
              this.tipo = 1;
              break;
            }
          }
          this.establecerEtiquetas();
        }
      });
    }
  }

  public enviarMensaje(accion: number): void {
    this.chatService.createInmoGroup(this.inmo.id, this.authService.user_token).then(res => {
      let checkExist = setInterval(async () => {
        if (this.chatService.PrivateChatRoomsMap.has(res['roomId'])) {
          this.chatService.selectedChat = {id: res['roomId'], chatType: res['roomType']};
          await this.router.navigate([this.routes.CHAT]).then(() => {
          })
          await clearInterval(checkExist);
        }
      }, 200);
    });
  }
    public follow(): void {
      const tipo = (this.inmo.esMLS === 0) ? 1 : 2; // tipo = 1 --> inmos, tipo = 2 --> grupos
    this.endpointService.insertNewFollow(this.authService.userId, tipo, this.inmo.id).subscribe(data => {
      if (data['errorCode'] === 0) {
        this.inmo.followId = data['response'];
      }
    });
  }

  public unfollow(): void {
    this.endpointService.deleteFollow(this.inmo.followId).subscribe(data => {
      if (data['errorCode'] === 0) {
        this.inmo.followId = null;
      }
    });
  }

  checkStructure() {
    this.showMessageButton = this.structure != undefined && this.structure['130'] != undefined && this.structure['130']['id_functional_type'] == 5 ? true : false;
    this.showFollowButton = this.structure != undefined && this.structure['131'] != undefined && this.structure['131']['id_functional_type'] == 5 ? true : false;
    this.showInmoCollaborateButton = this.structure != undefined && this.structure['132'] != undefined && this.structure['132']['id_functional_type'] == 5 ? true : false;
    this.showGroupCollaborateButton = this.structure != undefined && this.structure['133'] != undefined && this.structure['133']['id_functional_type'] == 5 ? true : false;
  }
}
