import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-traffic-light',
  templateUrl: './traffic-light.component.html',
  styleUrls: ['./traffic-light.component.css']
})
export class TrafficLightComponent implements OnInit {

  @Input() structure = null;
  @Input() index : number;

  constructor() { }

  ngOnInit(): void {
  }

}
