import { Injectable } from '@angular/core';
import {MatSidenav} from "@angular/material/sidenav";

@Injectable()
export class SideNavService {

  public sidenav: MatSidenav;
  private sidenavPDF: MatSidenav;
  public text_pdf: string;
  public url_item_intern: string;

  public setSidenav(sidenav: MatSidenav, id: number) {
    if(id === 0) this.sidenav = sidenav;
    else if (id === 1) this.sidenavPDF = sidenav;
  }

  public open(id: number) {
    if(id === 0) return this.sidenav.open();
    else if (id === 1) return this.sidenavPDF.open();
  }

  public close(id: number) {
    if(id === 0) return this.sidenav.close();
    else if (id === 1) return this.sidenavPDF.close();
  }

  public isOpened(id: number) {
    if(this.sidenav != undefined){
      if(id === 0) return this.sidenav.opened;
      else if (id === 1) return this.sidenavPDF.opened;
    }
    //console.log('suuuu', this.sidenav);
  }

  public toggle(id: number): void {
    if(id === 0) this.sidenav.toggle();
    else if (id === 1) this.sidenavPDF.toggle();
  }

  public toggleDrawer(i: number, text_pdf: string, url_item_intern: string): void {
    let url = '';
    this.text_pdf = text_pdf;
    this.url_item_intern = url.concat(url_item_intern, '#toolbar=0');
    if (i === 1) this.sidenavPDF.toggle();
  }

}
