<mat-accordion>
  <mat-expansion-panel expanded>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Configuración de la plantilla
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="row-expansion5">

      <mat-checkbox [(ngModel)]="bodyReport.active">Activado cron</mat-checkbox>
      <mat-checkbox  style="margin-left: 2em;" [(ngModel)]="bodyReport.activeFrontend">Activado frontend</mat-checkbox>

      <!--<mat-checkbox [(ngModel)]="bodyReport.interactive">Interactuación</mat-checkbox>

      <mat-form-field style="padding-left: 20px; padding-right: 75px;">
        <input matInput type="number" placeholder="Nº Veces" maxlength="100"
               [(ngModel)]="bodyReport.num_veces" autocomplete="off">
      </mat-form-field>-->

    </div>

    <div class="row-expansion5">

      ¿Repetir?

      <mat-radio-group [(ngModel)]="bodyReport.interactive" [ngModelOptions]="{standalone: true}" (change)="radioInteractiveMode()">
        <mat-radio-button [value]="1" color="primary">Si</mat-radio-button>
        <mat-radio-button [value]="0" color="primary">No</mat-radio-button>
      </mat-radio-group>

      <mat-form-field style="padding-left: 20px; padding-right: 75px;">
        <input matInput type="number" placeholder="Nº Veces" maxlength="100"
               [(ngModel)]="bodyReport.num_veces" autocomplete="off" [disabled]="bodyReport.interactive == 0">
      </mat-form-field>

    </div>

    <div>
      <a *ngIf="journeyArray.length == 0">No hay journeys existentes</a>
      <mat-form-field *ngIf="journeyArray.length > 0">
        <mat-label>Vincular journey</mat-label>
        <input matInput type="text" [formControl]="journeyGroup.get('journey')" [matAutocomplete]="autoSoft" autocomplete="movinAutoSoft">
        <mat-autocomplete autoActiveFirstOption#autoSoft="matAutocomplete" [displayWith]="displayFnJourney.bind(this)">
          <mat-option class="inmo-list" *ngFor="let option of filteredOptionsJourney | async" [value]="option">
            {{option.nombre}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <a *ngIf="journeyArray.length > 0 && bodyReport.journey == null">No se ha vinculado ningun journey</a>
      <a *ngIf="journeyArray.length > 0 && bodyReport.journey != null">Journey vinculado: {{getNameJourney(bodyReport.journey)}} <i class="material-icons" click-stop-propagation (click)="bodyReport.journey = null">close</i></a>
    </div>

    <div class="row-expansion5">

      <mat-form-field>
        <input matInput type="text" placeholder="Nombre" maxlength="100"
               [(ngModel)]="bodyReport.name" autocomplete="off">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Tipo</mat-label>
        <mat-select [(value)]="bodyReport.idType" [disabled]="edit" (selectionChange)="isHome()">
          <mat-option *ngFor="let t of type" [value]="t.id">{{t.nombre}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Modulo</mat-label>
        <mat-select [(ngModel)]="selectedEvent.modulo" (ngModelChange)="onMainModuloChange()">
<!--          <mat-option *ngFor="let modulo of moduloList" [value]="modulo.id">{{modulo.modul_name}}</mat-option>-->
          <mat-option *ngFor="let modulo of listaModulos" [value]="modulo.id">{{modulo.name}}</mat-option>
<!--          <mat-option [value]="7">Usuarios</mat-option>-->
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Pantalla</mat-label>
        <mat-select [(value)]="bodyReport.myScreen" multiple [disabled]="bodyReport.idType == 3">
          <mat-option *ngFor="let s of screenArray" [value]="s.id">{{s.nombre}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Ejecutar sobre</mat-label>
        <!--            NOTA IBRA: Cambiar el tipo de ejecución y el cuándo ejecutar no deberia implicar resetear la condiciones y acciones-->
        <!--            <mat-select [(ngModel)]="selectedEvent.executionTarget" (ngModelChange)="onMainModuloChange()">-->
        <mat-select [(ngModel)]="selectedEvent.executionTarget">
          <mat-option *ngFor="let item of executeOverList" [value]="item.id">{{item.nombre}}</mat-option>
        </mat-select>
      </mat-form-field>

    </div>

  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Diseño de la plantilla
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <span fxFlex="100" fxLayout="row wrap">

      <div fxFlex="100">
        <mat-checkbox *ngIf="bodyReport.useMode === 1" [(ngModel)]="bodyReport.personalizado" class="normalText thecheck">Personalizado</mat-checkbox>
      </div>

      <div class="row-expansion5" fxFlex="100">

        <mat-form-field *ngIf="bodyReport.idType == 5" class="buit">
          <input matInput type="number" placeholder="Tiempo (segundos)" maxlength="100"
                 [(ngModel)]="bodyReport.snackTime" autocomplete="off">
        </mat-form-field>

        <mat-form-field class="buit" *ngIf="bodyReport.idType == 1 || bodyReport.idType == 2 || bodyReport.idType == 4 || bodyReport.idType == 5">
          <input matInput type="color" placeholder="Color fondo" [(ngModel)]="bodyReport.backgroundColor"/>
        </mat-form-field>

        <div class="buit">

          <mat-checkbox [(ngModel)]="bodyReport.cerrar" style="padding-right: 10px">Botón de cerrar</mat-checkbox>

          <mat-form-field>
            <input matInput type="color" placeholder="Color cerrar" [(ngModel)]="bodyReport.colorCerrar"/>
          </mat-form-field>
        </div>

        <div class="buit">
          <mat-checkbox [(ngModel)]="bodyReport.closeOutsideWindow" style="padding-right: 10px">Cerrar fuera de la ventana</mat-checkbox>
        </div>

        <div class="buit">
          <mat-checkbox [(ngModel)]="bodyReport.hideStepHeader" style="padding-right: 10px">Ocultar header stepper</mat-checkbox>
        </div>

        <div class="buit">
          <mat-checkbox [(ngModel)]="bodyReport.soloStepActual" style="padding-right: 10px">Solo título step actual</mat-checkbox>
        </div>

      </div>

      <div fxFlex="100" *ngIf="(bodyReport.idType == 3 || bodyReport.idType == 4 || bodyReport.idType == 5) && !bodyReport.personalizado"
           class="row-expansion5">

        <mat-form-field>
          <input matInput type="text" placeholder="Título" maxlength="100"
                 [(ngModel)]="bodyReport.title" autocomplete="off">
        </mat-form-field>

        <mat-form-field>
          <input matInput type="color" placeholder="Color título" [(ngModel)]="bodyReport.titleColor"/>
        </mat-form-field>

        <mat-form-field>
          <input matInput type="text" placeholder="Subtítulo" maxlength="100"
                 [(ngModel)]="bodyReport.subtitle" autocomplete="off">
        </mat-form-field>

        <mat-form-field>
          <input matInput type="color" placeholder="Color subtítulo" [(ngModel)]="bodyReport.subtitleColor"/>
        </mat-form-field>

        <div class="row-expansion2 buit" style="display: flex;">
          <p>Icono</p>
          <mat-icon *ngIf="bodyReport.icon_active == true" style="background-color: greenyellow;" class="material-icons mdc-select__icon" tabindex="0" role="button" (click)="icono(0)">{{bodyReport.icon}}</mat-icon>
          <mat-icon *ngIf="bodyReport.icon_active == false" style="background-color: red;" class="material-icons mdc-select__icon" tabindex="0" role="button" (click)="icono(0)">{{bodyReport.icon}}</mat-icon>
        </div>

      </div>

      <div fxFlex="100" *ngIf="bodyReport.idType == 6 && !bodyReport.personalizado" class="row-expansion5">

        <mat-form-field>
          <input matInput type="text" placeholder="Pregunta"
                 [(ngModel)]="bodyReport.title" autocomplete="off">
        </mat-form-field>

        <!--        <mat-form-field>-->
        <!--          <input matInput type="color" placeholder="Color pregunta" [(ngModel)]="bodyReport.subtitleColor"/>-->
        <!--        </mat-form-field>-->

      </div>

      <div fxFlex="100" *ngIf="bodyReport.idType == 6 && !bodyReport.personalizado" class="row-expansion1">

        <mat-form-field>
          <textarea matInput type="text" rows="5" cols="40" placeholder="Introduzca aquí tu respuesta..."
                    [(ngModel)]="bodyReport.subtitle"></textarea>
        </mat-form-field>

      </div>

      <!--<div *ngIf="(bodyReport.idType == 1 || bodyReport.idType == 2) && !bodyReport.personalizado">

        <button mat-button (click)="addStep()">More container</button>
        <div cdkDropList class="example-list" (cdkDropListDropped)="dropStep($event)">

          <div class="example-box" *ngFor="let s of stepArray; let is = index" cdkDrag>

            <button mat-button (click)="addContainer(is)">More container</button>
            <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">

              <div class="example-box" *ngFor="let c of s[is].containerArray; let ic = index" cdkDrag>

                <mat-form-field>
                  <mat-label>Tipo</mat-label>
                  <mat-select [(value)]="c.idType">
                    <mat-option *ngFor="let t of container" [value]="t.id">{{t.nombre}}</mat-option>
                  </mat-select>
                </mat-form-field>

                <button class="btn erp-status-btn btnjan" (click)="showImageSelector(ic)" style="margin-right: 3% !important;" *ngIf="c.idType == 4">
                  <i class="material-icons">add<mat-icon [matBadge]="images.length" matBadgeOverlap="false" matBadgeColor="warn" matTooltip="Añadir imagen">burst_mode</mat-icon></i>
                </button>

                <div class="col-sm-3" style="padding: 25px" *ngIf="c.idType == 4">
                  <img [src]="c.container" style="width:200px; height:auto;">
                </div>

                <mat-form-field *ngIf="c.idType != 4">
                  <input matInput type="text" placeholder=""
                         [(ngModel)]="c.container" autocomplete="off">
                </mat-form-field>

                <mat-form-field *ngIf="c.idType == 1 || c.idType == 2 || c.idType == 3">
                  <input matInput type="color" placeholder=""
                         [(ngModel)]="c.color" autocomplete="off">
                </mat-form-field>

                <button mat-button (click)="deleteContainer(ic)">DELETE</button>

              </div>
            </div>

          </div>
        </div>
      </div>-->

      <!-- <mat-checkbox [(ngModel)]="bodyReport.personalizado">Personalizado</mat-checkbox>-->

      <div fxFlex="100" *ngIf="bodyReport.personalizado">
        <div style="width: 100%;">
          <h5>Mensaje</h5>
        </div>
        <div style="width: 100%; margin: 2em 0; display: flex;">

          <div style="width: 85%;">
            <angular-editor id="contentEditor" (viewMode)="onViewMode($event)" (input)="getHTMLContent($event)"
                            [(ngModel)]="bodyReport.mensaje" [config]="editorConfig" (blur)="fnSetPlaceHolder('');"></angular-editor>
            <!--<div class="html">
              {{ htmlContent }}
            </div>-->
          </div>

          <div style="margin-left: 1%; width: 20%;">

            <mat-form-field style="padding-left: 10%;">
              <mat-label>Modulo</mat-label>
              <mat-select [(ngModel)]="bodyReport.mensaje_id_modulo" (ngModelChange)="onModuloChange()">
                <mat-option *ngFor="let modulo of modulos" [value]="modulo.id">
                  {{modulo.modul_name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div style="padding-left: 30%;">
              <h6>Campos</h6>
            </div>

            <div style="height: 200px; overflow-y: scroll;" *ngIf="fieldsToShow.length > 0">
              <mat-chip-list class="mat-chip-list-stacked">
                <mat-chip style="width: 90%;" *ngFor="let field of fieldsToShow" (click)="selectedField(field)" color="primary" selected>{{field['nombre_frontend']}}</mat-chip>
              </mat-chip-list>
            </div>

            <div *ngIf="fieldsToShow.length <= 0" style="padding-left: 20%;"> No hay campos </div>
          </div>
        </div>
      </div>

      <div fxFlex="100" fxLayoutAlign="space-around start" *ngIf="bodyReport.idType != 6 && bodyReport.idType != 1 && bodyReport.idType != 2">
       <span fxFlex="25" fxLayout="row wrap" *ngFor="let b of buttonArray; let index = index" style="border: 1px solid #22222261; padding: 1em; border-radius: 5px;">
          <div fxFlex="100" fxLayout="row wrap">
            <mat-checkbox [(ngModel)]="b.active" fxFlex="100" style="font-weight: bold; padding-bottom: 0.5em;">{{b.name}}</mat-checkbox>
            <mat-form-field fxFlex="100" >
              <input matInput type="text" placeholder="Texto" maxlength="100" style="padding-right: 5px;"
                     [(ngModel)]="b.text" autocomplete="off">
            </mat-form-field>
            <mat-form-field fxFlex="100">
              <input matInput type="text" placeholder="Tooltip" maxlength="100"
                     [(ngModel)]="b.tooltip" autocomplete="off">
            </mat-form-field>
            <mat-form-field fxFlex="100">
              <input matInput type="color" placeholder="Color texto" [(ngModel)]="b.colorText"/>
            </mat-form-field>
            <mat-form-field fxFlex="100">
              <input matInput type="color" placeholder="Color fondo" [(ngModel)]="b.colorBackground"/>
            </mat-form-field>
            <mat-form-field fxFlex="100">
              <input matInput type="color" placeholder="Color borde" [(ngModel)]="b.colorEdge"/>
            </mat-form-field>
            <i *ngIf="b.icon_active == true" fxFlex="100" style="background-color: greenyellow;" class="material-icons mdc-select__icon" tabindex="0" role="button" (click)="icono(index+1)">{{b.icon}}</i>
            <i *ngIf="b.icon_active == false" fxFlex="100" style="background-color: red;" class="material-icons mdc-select__icon" tabindex="0" role="button" (click)="icono(index+1)">{{b.icon}}</i>
          </div>
          <mat-divider cdkDropList fxFlex="100" fxLayout="row wrap" style="margin: 25px;"></mat-divider>
          <span fxFlex="100" fxLayout="row wrap" style="padding-bottom: 0.5em; text-align: center; font-weight: bold;">ACCIONES</span>
          <div fxFlex="100" fxLayout="row wrap">
            <span cdkDropList fxFlex="100" fxLayout="row wrap"  class="example-list" (cdkDropListDropped)="dropActionNoStep(index, $event)">
              <span fxFlex="100" fxLayout="row wrap" class="example-box" *ngFor="let a of b.actionArray; let ia = index" cdkDrag>

                <!-- Aquí empieza la cajita-->
                <mat-form-field fxFlex="100" >
                 <mat-label>Acción</mat-label>
                 <mat-select [(value)]="a.action" (selectionChange)="actionChangeNoStep(index, ia)">
                   <mat-option *ngFor="let t of actionArray" [value]="t.id">{{t.nombre}}</mat-option>
                 </mat-select>
               </mat-form-field>

                <mat-form-field *ngIf="a.action == 1" fxFlex="100">
                 <input matInput type="text" placeholder="Link externo" [(ngModel)]="a.text"/>
                </mat-form-field>

                <mat-form-field *ngIf="a.action == 2" fxFlex="100">
                  <mat-label>Link interno</mat-label>
                  <input matInput type="text" [formControl]="functionalArea.get('fa')" [matAutocomplete]="autoFunctionalArea" autocomplete="movinInmoSearch">
                  <mat-autocomplete autoActiveFirstOption#autoFunctionalArea="matAutocomplete" [displayWith]="displayFnFunctionalArea.bind(this)">
                    <mat-option class="inmo-list" *ngFor="let option of filteredOptionsFunctionalArea | async" [value]="option" (onSelectionChange)="getIdFunctionalAreaNostep(option, index, ia)">
                      {{option.nombre}}
                    </mat-option>
                  </mat-autocomplete>
                  <a *ngIf="a.action == 2 && a.id" fxFlex="100" style="font-weight: 500;">Plantilla seleccionada: {{getNameFunctionalAreaNostep(index, ia)}}</a>
                  <a *ngIf="a.action == 2 && !a.id" fxFlex="100" style="font-weight: 500; color: rgb(var(--tertiary-color)); font-style: italic;">No se ha seleccionado plantilla</a>

                </mat-form-field>

                <mat-form-field *ngIf="a.action == 5" fxFlex="100">
                  <mat-label>Buscar plantilla</mat-label>
                  <input matInput type="text" [formControl]="plantilla.get('plant')" [matAutocomplete]="autoPlantilla" autocomplete="movinInmoSearch">
                  <mat-autocomplete autoActiveFirstOption#autoPlantilla="matAutocomplete" [displayWith]="displayFnPlantilla.bind(this)">
                    <mat-option class="inmo-list" *ngFor="let option of filteredOptionsPlantilla | async" [value]="option" (onSelectionChange)="getIdPlantillaNostep(option, index, ia)">
                      {{option.nombre}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <a *ngIf="a.action == 5 && a.id" fxFlex="100" style="font-weight: 500;">Plantilla seleccionada: {{getNamePlantillaNostep(index, ia)}}</a>
                <a *ngIf="a.action == 5 && !a.id" fxFlex="100" style="font-weight: 500; color: rgb(var(--tertiary-color)); font-style: italic;">No se ha seleccionado plantilla</a>

                <mat-form-field *ngIf="a.action == 24" fxFlex="100">
                  <mat-label>Buscar producto marketplace</mat-label>
                  <input matInput type="text" [formControl]="plantilla.get('plant')" [matAutocomplete]="autoPlantilla" autocomplete="movinInmoSearch">
                  <mat-autocomplete autoActiveFirstOption#autoPlantilla="matAutocomplete" [displayWith]="displayFnMarketplace.bind(this)">
                    <mat-option class="inmo-list" *ngFor="let option of filteredOptionsMarketplace | async" [value]="option" (onSelectionChange)="getIdPlantillaNostep(option, index, ia)">
                      {{option.nombre}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <a *ngIf="a.action == 24 && a.id" fxFlex="100" style="font-weight: 500;">Producto de marketplace seleccionada: {{getNameMarketplaceNostepe(index, ia)}}</a>
                <a *ngIf="a.action == 24 && !a.id" fxFlex="100" style="font-weight: 500; color: rgb(var(--tertiary-color)); font-style: italic;">No se ha seleccionado producto de marketplace</a>

                <mat-form-field *ngIf="a.action == 25" fxFlex="100">
                  <mat-label>Buscar producto knowledgeplace</mat-label>
                  <input matInput type="text" [formControl]="plantilla.get('plant')" [matAutocomplete]="autoPlantilla" autocomplete="movinInmoSearch">
                  <mat-autocomplete autoActiveFirstOption#autoPlantilla="matAutocomplete" [displayWith]="displayFnKnowledgeplace.bind(this)">
                    <mat-option class="inmo-list" *ngFor="let option of filteredOptionsKnowledge | async" [value]="option" (onSelectionChange)="getIdPlantillaNostep(option, index, ia)">
                      {{option.nombre}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <a *ngIf="a.action == 25 && a.id" fxFlex="100" style="font-weight: 500;">Producto de knowledgeplace seleccionada: {{getNameKnowledgeNostep(index, ia)}}</a>
                <a *ngIf="a.action == 25 && !a.id" fxFlex="100" style="font-weight: 500; color: rgb(var(--tertiary-color)); font-style: italic;">No se ha seleccionado producto de knowledgeplace</a>

                <mat-form-field *ngIf="a.action == 26" fxFlex="100">
                  <mat-label>Buscar plantilla superior</mat-label>
                  <input matInput type="text" [formControl]="plantilla.get('plant')" [matAutocomplete]="autoPlantilla" autocomplete="movinInmoSearch">
                  <mat-autocomplete autoActiveFirstOption#autoPlantilla="matAutocomplete" [displayWith]="displayFnPlantillaSuperior.bind(this)">
                    <mat-option class="inmo-list" *ngFor="let option of filteredOptionsPlantillaSuperior | async" [value]="option" (onSelectionChange)="getIdPlantillaSuperiorNostep(option, index, ia)">
                      {{option.nombre}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <a *ngIf="a.action == 26 && a.id" fxFlex="100" style="font-weight: 500;">Plantilla seleccionada: {{getNamePlantillaSuperiorNostep(index, ia)}}</a>
                <a *ngIf="a.action == 26 && !a.id" fxFlex="100" style="font-weight: 500; color: rgb(var(--tertiary-color)); font-style: italic;">No se ha seleccionado plantilla</a>

                <mat-form-field *ngIf="a.action == 28" fxFlex="100">
                  <mat-label>Buscar plantilla superior</mat-label>
                  <input matInput type="text" [formControl]="plantilla.get('plant')" [matAutocomplete]="autoPlantilla" autocomplete="movinInmoSearch">
                  <mat-autocomplete autoActiveFirstOption#autoPlantilla="matAutocomplete" [displayWith]="displayFnPlantillaSuperiorHome.bind(this)">
                    <mat-option class="inmo-list" *ngFor="let option of filteredOptionsPlantillaSuperiorHome | async" [value]="option" (onSelectionChange)="getIdPlantillaSuperiorHomeNostep(option, index, ia)">
                      {{option.nombre}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <a *ngIf="a.action == 28 && a.id" fxFlex="100" style="font-weight: 500;">Plantilla seleccionada: {{getNamePlantillaSuperiorHomeNostep(index, ia)}}</a>
                <a *ngIf="a.action == 28 && !a.id" fxFlex="100" style="font-weight: 500; color: rgb(var(--tertiary-color)); font-style: italic;">No se ha seleccionado plantilla</a>
                <!-- Aquí termina la cajita-->

                <button fxFlex="100" fxLayoutAlign="center center" style="margin: 1em 0; color: rgb(var(--tertiary-color)) !important; width: 100%; border: 1px solid rgb(var(--tertiary-color)) !important;" mat-button (click)="deleteActionNoStep(index, ia)">ELIMINAR ACCIÓN</button>
              </span>
            </span>
            <button fxFlex="50" fxLayoutAlign="center center" fxLayout="row wrap" mat-button style="margin: 3em 25%;" (click)="addActionNoStep(index)"><span style="text-align: center; width: 100%;">+ Añadir acción</span></button>
          </div>
        </span>
      </div>

      <span fxFlex="100" fxLayout="row wrap" *ngIf="(bodyReport.idType == 1 || bodyReport.idType == 2) && !bodyReport.personalizado">
        <mat-accordion>
            <span cdkDropList class="example-list" (cdkDropListDropped)="dropStep($event)">
              <mat-expansion-panel *ngFor="let s of stepArray; let is = index" cdkDrag >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <button click-stop-propagation (click)="deleteStep(is)" class="btn btn-danger erp-status-btn">
                      <i class="material-icons">delete_forever</i>
                    </button>
                    <button click-stop-propagation class="btn-warning" (click)="editNameStep(is)">
                      <i class="material-icons">create</i>
                    </button>
                    <button *ngIf="s.icon[0].icon_active == true" click-stop-propagation (click)="iconoStep(is,0)">
                      <i  style="background-color: greenyellow;" class="material-icons mdc-select__icon" >{{s.icon[0].icon}}</i>
                    </button>
                    <button *ngIf="s.icon[0].icon_active == false" click-stop-propagation (click)="iconoStep(is,0)">
                      <i  style="background-color: red;" class="material-icons mdc-select__icon" >{{s.icon[0].icon}}</i>
                    </button>
                    {{s.name}}
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <span fxLayout="row wrap" fxFlex="100">
                  <span cdkDropList fxFlex="100" fxLayout="row wrap"  class="example-list" (cdkDropListDropped)="drop(is, $event)">
                    <span fxFlex="100" fxLayout="row wrap" class="example-box" *ngFor="let c of s.container; let ic = index" cdkDrag>
                      <span fxFlex="50" fxLayoutAlign="space-around center" >
                        <button mat-button (click)="deleteContainer(is, ic)">DELETE</button>
                        <mat-form-field>
                          <mat-label>Tipo</mat-label>
                          <mat-select [(value)]="c.idType">
                            <mat-option *ngFor="let t of container" [value]="t.id">{{t.nombre}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field *ngIf="c.idType == 1 || c.idType == 2 || c.idType == 3">
                          <input matInput type="color" placeholder=""
                                 [(ngModel)]="c.color" autocomplete="off">
                        </mat-form-field>
                      </span>
                      <span fxLayout="row wrap" fxFlex="50" fxLayoutAlign="space-around center">
                          <mat-form-field fxFlex="100" *ngIf="c.idType != 4">
                            <input matInput type="text" placeholder=""
                                   [(ngModel)]="c.container" autocomplete="off">
                          </mat-form-field>
                         <span fxFlex="100">
                            <button class="btn erp-status-btn btnjan" (click)="showImageSelector(is, ic)" style="margin-right: 3% !important;" *ngIf="c.idType == 4">
                              <i class="material-icons">add<mat-icon [matBadge]="images.length" matBadgeOverlap="false" matBadgeColor="warn" matTooltip="Añadir imagen">burst_mode</mat-icon></i>
                            </button>
                            <div class="col-sm-3" style="padding: 25px" *ngIf="c.idType == 4">
                              <img [src]="c.container" style="width:200px; height:auto;">
                            </div>
                          </span>
                        </span>
                      </span>
                    <button fxFlex="100" mat-button (click)="addContainer(is)">+ Añadir apartado</button>
                  </span>

                  <mat-divider cdkDropList fxFlex="100" fxLayout="row wrap" style="margin: 10px;"></mat-divider>

                  <div fxFlex="100" class="row-expansion8" fxLayoutAlign="space-around start">
                    <span fxFlex="25" fxLayout="row wrap" *ngFor="let b of s.buttons; let ib = index" style="border: 1px solid #22222261; padding: 1em; border-radius: 5px;">
                      <div fxFlex="100" fxLayout="row wrap">
                        <mat-checkbox fxFlex="100" [(ngModel)]="b.active" style="font-weight: bold; padding-bottom: 0.5em;">{{b.name}}</mat-checkbox>
                        <mat-form-field fxFlex="100">
                          <input matInput type="text" placeholder="Texto" maxlength="100" style="padding-right: 5px;"
                                 [(ngModel)]="b.text" autocomplete="off">
                        </mat-form-field>
                        <mat-form-field fxFlex="100">
                          <input matInput type="text" placeholder="Tooltip" maxlength="100"
                                 [(ngModel)]="b.tooltip" autocomplete="off">
                        </mat-form-field>
                        <mat-form-field fxFlex="100">
                          <input matInput type="color" placeholder="Color texto" [(ngModel)]="b.colorText"/>
                        </mat-form-field>
                        <mat-form-field fxFlex="100">
                          <input matInput type="color" placeholder="Color fondo" [(ngModel)]="b.colorBackground"/>
                        </mat-form-field>
                        <mat-form-field fxFlex="100">
                          <input matInput type="color" placeholder="Color borde" [(ngModel)]="b.colorEdge"/>
                        </mat-form-field>
                        <i fxFlex="100" *ngIf="b.icon_active == true" style="background-color: greenyellow;" class="material-icons mdc-select__icon" tabindex="0" role="button" (click)="iconoStep(is,ib+1)">{{b.icon}}</i>
                        <i fxFlex="100" *ngIf="b.icon_active == false" style="background-color: red;" class="material-icons mdc-select__icon" tabindex="0" role="button" (click)="iconoStep(is,ib+1)">{{b.icon}}</i>
                      </div>
                      <mat-divider cdkDropList fxFlex="100" fxLayout="row wrap" style="margin: 25px;"></mat-divider>
                      <span fxFlex="100" fxLayout="row wrap" style="padding-bottom: 0.5em; text-align: center; font-weight: bold;">ACCIONES</span>
                      <div fxFlex="100" fxLayout="row wrap">
                        <span cdkDropList fxFlex="100" fxLayout="row wrap"  class="example-list" (cdkDropListDropped)="dropActionStep(is, ib, $event)">
                          <span fxFlex="100" fxLayout="row wrap" class="example-box" *ngFor="let a of b.actionArray; let ia = index" cdkDrag>
                            <!-- Aquí empieza la cajita-->
                            <mat-form-field fxFlex="100">
                              <mat-label>Acción</mat-label>
                              <mat-select [(value)]="a.action" (selectionChange)="actionChange(ib, is, ia)">
                                <mat-option *ngFor="let t of actionArray" [value]="t.id">{{t.nombre}}</mat-option>
                              </mat-select>
                            </mat-form-field>

                            <mat-form-field *ngIf="a.action == 1" fxFlex="100">
                              <input matInput type="text" placeholder="Link externo" [(ngModel)]="a.text"/>
                            </mat-form-field>

                            <mat-form-field *ngIf="a.action == 2" fxFlex="100">
                              <mat-label>Link interno</mat-label>
                              <input matInput type="text" [formControl]="functionalArea.get('fa')" [matAutocomplete]="autoFunctionalArea" autocomplete="movinInmoSearch">
                              <mat-autocomplete autoActiveFirstOption#autoFunctionalArea="matAutocomplete" [displayWith]="displayFnFunctionalArea.bind(this)">
                                <mat-option class="inmo-list" *ngFor="let option of filteredOptionsFunctionalArea | async" [value]="option" (onSelectionChange)="getIdFunctionalArea(option, ib, is, ia)">
                                      {{option.nombre}}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                             <a fxFlex="100" *ngIf="a.action == 2 && a.id" style="font-weight: 500;">Plantilla seleccionada: {{getNameFunctionalArea(ib, is, ia)}}</a>
                            <a fxFlex="100" *ngIf="a.action == 2 && !a.id" style="font-weight: 500; color: rgb(var(--tertiary-color)); font-style: italic;">No se ha seleccionado plantilla</a>

                            <mat-form-field fxFlex="100" *ngIf="a.action == 5">
                              <mat-label>Buscar plantilla</mat-label>
                              <input matInput type="text" [formControl]="plantilla.get('plant')" [matAutocomplete]="autoPlantilla" autocomplete="movinInmoSearch">
                              <mat-autocomplete autoActiveFirstOption#autoPlantilla="matAutocomplete" [displayWith]="displayFnPlantilla.bind(this)">
                                <mat-option class="inmo-list" *ngFor="let option of filteredOptionsPlantilla | async" [value]="option" (onSelectionChange)="getIdPlantilla(option, ib, is, ia)">
                                  {{option.nombre}}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                            <a fxFlex="100" *ngIf="a.action == 5 && a.id" style="font-weight: 500;">Plantilla seleccionada: {{getNamePlantilla(ib, is, ia)}}</a>
                            <a fxFlex="100" *ngIf="a.action == 5 && !a.id" style="font-weight: 500; color: rgb(var(--tertiary-color)); font-style: italic;">No se ha seleccionado plantilla</a>

                            <mat-form-field fxFlex="100" *ngIf="a.action == 24">
                              <mat-label>Buscar producto marketplace</mat-label>
                              <input matInput type="text" [formControl]="plantilla.get('plant')" [matAutocomplete]="autoPlantilla" autocomplete="movinInmoSearch">
                              <mat-autocomplete autoActiveFirstOption#autoPlantilla="matAutocomplete" [displayWith]="displayFnMarketplace.bind(this)">
                                <mat-option class="inmo-list" *ngFor="let option of filteredOptionsMarketplace | async" [value]="option" (onSelectionChange)="getIdPlantilla(option, ib, is, ia)">
                                  {{option.nombre}}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                            <a fxFlex="100" *ngIf="a.action == 24 && a.id" style="font-weight: 500;">Producto de marketplace seleccionada: {{getNameMarketplace(ib, is, ia)}}</a>
                            <a fxFlex="100" *ngIf="a.action == 24 && !a.id" style="font-weight: 500; color: rgb(var(--tertiary-color)); font-style: italic;">No se ha seleccionado producto de marketplace</a>

                            <mat-form-field fxFlex="100" *ngIf="a.action == 25">
                              <mat-label>Buscar producto knowledgeplace</mat-label>
                              <input matInput type="text" [formControl]="plantilla.get('plant')" [matAutocomplete]="autoPlantilla" autocomplete="movinInmoSearch">
                              <mat-autocomplete autoActiveFirstOption#autoPlantilla="matAutocomplete" [displayWith]="displayFnKnowledgeplace.bind(this)">
                                <mat-option class="inmo-list" *ngFor="let option of filteredOptionsKnowledge | async" [value]="option" (onSelectionChange)="getIdPlantilla(option, ib, is, ia)">
                                  {{option.nombre}}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                            <a fxFlex="100" *ngIf="a.action == 25 && a.id" style="font-weight: 500;">Producto de knowledgeplace seleccionada: {{getNameKnowledge(ib, is, ia)}}</a>
                            <a fxFlex="100" *ngIf="a.action == 25 && !a.id" style="font-weight: 500; color: rgb(var(--tertiary-color)); font-style: italic;">No se ha seleccionado producto de knowledgeplace</a>

                            <mat-form-field fxFlex="100" *ngIf="a.action == 26">
                              <mat-label>Buscar plantilla superior</mat-label>
                              <input matInput type="text" [formControl]="plantilla.get('plant')" [matAutocomplete]="autoPlantilla" autocomplete="movinInmoSearch">
                              <mat-autocomplete autoActiveFirstOption#autoPlantilla="matAutocomplete" [displayWith]="displayFnPlantillaSuperior.bind(this)">
                                <mat-option class="inmo-list" *ngFor="let option of filteredOptionsPlantillaSuperior | async" [value]="option" (onSelectionChange)="getIdPlantillaSuperior(option, ib, is, ia)">
                                  {{option.nombre}}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                            <a fxFlex="100" *ngIf="a.action == 26 && a.id" style="font-weight: 500;">Plantilla superior seleccionada: {{getNamePlantillaSuperior(ib, is, ia)}}</a>
                            <a fxFlex="100" *ngIf="a.action == 26 && !a.id" style="font-weight: 500; color: rgb(var(--tertiary-color)); font-style: italic;">No se ha seleccionado plantilla superior</a>

                            <mat-form-field fxFlex="100" *ngIf="a.action == 28">
                              <mat-label>Buscar plantilla superior</mat-label>
                              <input matInput type="text" [formControl]="plantilla.get('plant')" [matAutocomplete]="autoPlantilla" autocomplete="movinInmoSearch">
                              <mat-autocomplete autoActiveFirstOption#autoPlantilla="matAutocomplete" [displayWith]="displayFnPlantillaSuperiorHome.bind(this)">
                                <mat-option class="inmo-list" *ngFor="let option of filteredOptionsPlantillaSuperiorHome | async" [value]="option" (onSelectionChange)="getIdPlantillaSuperiorHome(option, ib, is, ia)">
                                  {{option.nombre}}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                            <a fxFlex="100" *ngIf="a.action == 28 && a.id" style="font-weight: 500;">Plantilla superior seleccionada: {{getNamePlantillaSuperiorHome(ib, is, ia)}}</a>
                            <a fxFlex="100" *ngIf="a.action == 28 && !a.id" style="font-weight: 500; color: rgb(var(--tertiary-color)); font-style: italic;">No se ha seleccionado plantilla superior</a>
                            <!-- Aquí termina la cajita-->
                            <button fxFlex="100" fxLayoutAlign="center center" style="margin: 1em 0; color: rgb(var(--tertiary-color)) !important; width: 100%; border: 1px solid rgb(var(--tertiary-color)) !important;" mat-button (click)="deleteActionStep(ib, is, ia)">ELIMINAR ACCIÓN</button>
                          </span>
                        </span>
                        <button fxFlex="50" fxLayoutAlign="center center" fxLayout="row wrap" mat-button style="margin: 3em 25%;"><span style="text-align: center; width: 100%;" (click)="addActionStep(ib, is)">+ Añadir acción</span></button>
                      </div>
                    </span>
                  </div>
                </span>
              </mat-expansion-panel>
            </span>
          </mat-accordion>
        <button fxFlex="100" mat-button (click)="addStep()">Añadir nuevo step</button>
      </span>

    </span>

  </mat-expansion-panel>

  <mat-expansion-panel *ngIf="bodyReport.idType != 6">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Cuando usar
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div>
      <mat-radio-group [(ngModel)]="bodyReport.useMode" [ngModelOptions]="{standalone: true}" (change)="radioUseMode()" [disabled]="edit">
        <mat-radio-button [value]="0" color="primary">Acción</mat-radio-button>
        <mat-radio-button [value]="1" color="primary">Programado</mat-radio-button>
      </mat-radio-group>
    </div>

    <div *ngIf="bodyReport.useMode == 0">
      <mat-form-field>
        <mat-label>Acciones sin asignar</mat-label>
        <mat-select multiple [(value)]="bodyReport.buttonActionXAsignar">
          <mat-option *ngFor="let action of buttonActionArray" [value]="action.id">
            {{action.button_nom}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="bodyReport.useMode == 0 && edit == true">
      <mat-form-field>
        <mat-label>Acciones asignadas</mat-label>
        <mat-select multiple [(value)]="bodyReport.buttonActionAsignats">
          <mat-option *ngFor="let action of buttonActionArrayActive" [value]="action.id">
            {{action.button_nom}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="bodyReport.useMode == 1">
      <mat-form-field>
        <mat-label>Cuando ejecutar</mat-label>
        <!--            NOTA IBRA: Cambiar el tipo de ejecución y el cuándo ejecutar no deberia implicar resetear la condiciones y acciones-->
        <!--            <mat-select [(ngModel)]="selectedEvent.executionTime" (ngModelChange)="onMainModuloChange()">-->
        <mat-select [(ngModel)]="selectedEvent.executionTime">
          <mat-option *ngFor="let item of whenExecuteList" [value]="item.id">{{item.nombre}}</mat-option>
        </mat-select>
      </mat-form-field>


    <mat-card *ngIf="selectedEvent.executionTime===3" style="width: 29%; margin-right: 1%; margin-top: 1%; margin-bottom: 1%;">
      <div style="display: flex; flex-direction: column;">
        <mat-form-field>
          <mat-label>Fecha de ejecucion</mat-label>
          <input matInput [matDatepicker]="dp" disabled [(ngModel)]="bodyReport.fechaEjecucion" (ngModelChange)="getDate($event)">
          <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
          <mat-datepicker #dp disabled="false"></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Hora inicio</mat-label>
          <input readonly matInput placeholder="Escoja una hora" [owlDateTime]="dt1" autocomplete="off" [(ngModel)]="bodyReport.horaEjecucion" (ngModelChange)="getTime($event)" [owlDateTimeTrigger]="dt1">
          <span matSuffix><i class="material-icons">calendar_today</i></span>
          <owl-date-time #dt1 pickerType="timer" [hour12Timer]="false"></owl-date-time>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Hora finalización</mat-label>
          <input readonly matInput placeholder="Escoja una hora" [owlDateTime]="dt2" autocomplete="off" [(ngModel)]="bodyReport.horaFinal" (ngModelChange)="getFinalTime($event)" [owlDateTimeTrigger]="dt2">
          <span matSuffix><i class="material-icons">calendar_today</i></span>
          <owl-date-time #dt2 pickerType="timer" [hour12Timer]="false"></owl-date-time>
        </mat-form-field>

        <!--<mat-checkbox class="mat-checkbox-pre" [(ngModel)]="selectedEvent.repeat">Repetir</mat-checkbox>
        <mat-form-field *ngIf="selectedEvent.repeat">
          <mat-label>Cada cuanto repetir</mat-label>
          <input matInput placeholder="Cada cuanto repetir" [(ngModel)]="selectedEvent.repeat_offset">
        </mat-form-field>

        <mat-form-field *ngIf="selectedEvent.repeat">
          <mat-label>Magnitud</mat-label>
          <mat-select [(ngModel)]="selectedEvent.repeat_magnitude">
            <mat-option [value]="1">Hora/s</mat-option>
            <mat-option [value]="2">Dia/s</mat-option>
            <mat-option [value]="3">Semana/s</mat-option>
          </mat-select>
        </mat-form-field>-->
        <!--          <owl-date-time-inline style="margin-top: 1.2em" [(ngModel)]="selectedEvent.hour" [hour12Timer]="false"></owl-date-time-inline>-->

      </div>
    </mat-card>
    <mat-card *ngIf="selectedEvent.executionTime===4" style="width: 29%; margin-right: 1%; margin-top: 1%; margin-bottom: 1%;">
      <div style="display: flex; flex-direction: column;">
        <mat-form-field>
          <mat-label>Días</mat-label>
          <mat-select [(ngModel)]="selectedEvent.days_of_repetition" multiple>
            <mat-option *ngFor="let day of daysOfWeek" [value]="day.id">{{day.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Hora</mat-label>
          <input readonly matInput placeholder="Escoja una hora" [owlDateTime]="dt1" autocomplete="off" [(ngModel)]="bodyReport.horaEjecucion" (ngModelChange)="getTime($event)" [owlDateTimeTrigger]="dt1">
          <!--            <input readonly matInput placeholder="Escoja una hora" [owlDateTime]="dt1" autocomplete="off" [(ngModel)]="selectedEvent.hour" [owlDateTimeTrigger]="dt1">-->
          <span matSuffix><i class="material-icons">calendar_today</i></span>
          <owl-date-time #dt1 pickerType="timer" [hour12Timer]="false"></owl-date-time>
        </mat-form-field>
      </div>
    </mat-card>
    <mat-card *ngIf="selectedEvent.executionTime===5" style="width: 29%; margin-right: 1%; margin-top: 1%; margin-bottom: 1%;">
        <div style="display: flex; flex-direction: column;">
          <mat-form-field>
            <mat-label>Días</mat-label>
            <mat-select [(ngModel)]="selectedEvent.days_of_repetition" multiple>
              <mat-option *ngFor="let day of daysOfWeek" [value]="day.id">{{day.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <!--Fecha Inicio-->
          <mat-form-field>
            <mat-label>Fecha de inicio</mat-label>
            <input matInput [matDatepicker]="dp" disabled [(ngModel)]="bodyReport.fechaEjecucion" (ngModelChange)="getDate($event)">
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp disabled="false"></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Hora inicio</mat-label>
            <input readonly matInput placeholder="Escoja una hora" [owlDateTime]="dt1" autocomplete="off" [(ngModel)]="bodyReport.horaEjecucion" (ngModelChange)="getTime($event)" [owlDateTimeTrigger]="dt1">
            <span matSuffix><i [owlDateTimeTrigger]="dt1" class="material-icons">calendar_today</i></span>
            <owl-date-time #dt1 pickerType="timer" [hour12Timer]="false"></owl-date-time>
          </mat-form-field>

          <!--Fecha Fin-->
          <mat-form-field>
            <mat-label>Fecha de finalización</mat-label>
            <input matInput [matDatepicker]="dpf" disabled [(ngModel)]="selectedEvent.fecha_final_date" (ngModelChange)="getFinalDate($event)">
            <mat-datepicker-toggle matSuffix [for]="dpf"></mat-datepicker-toggle>
            <mat-datepicker #dpf disabled="false"></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Hora finalización</mat-label>
            <input readonly matInput placeholder="Escoja una hora" [owlDateTime]="dt2" autocomplete="off" [(ngModel)]="bodyReport.horaFinal" (ngModelChange)="getFinalTime($event)" [owlDateTimeTrigger]="dt2">
            <span matSuffix><i [owlDateTimeTrigger]="dt2" class="material-icons">calendar_today</i></span>
            <owl-date-time #dt2 pickerType="timer" [hour12Timer]="false"></owl-date-time>
          </mat-form-field>
        </div>
      </mat-card>
    </div>

  </mat-expansion-panel>

  <mat-expansion-panel *ngIf="bodyReport.idType != 6 && bodyReport.useMode == 1">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Condiciones
      </mat-panel-title>
      <mat-panel-description> </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-checkbox [(ngModel)]="selectedEvent.consulta_estatica">Consulta estática</mat-checkbox>
    <div *ngIf="selectedEvent.consulta_estatica">
      <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="15" [(ngModel)]="selectedEvent.query" [ngModelOptions]="{standalone: true}"
                style="background: #d1ecf1; border-radius: 0.3em; padding: 1em;">
                </textarea>
    </div>
    <div *ngIf="!selectedEvent.consulta_estatica">
      <div style="width: 100%; display: flex; flex-direction: column">
        <div *ngIf="dataLoaded && selectedEvent.conditions.length > 0 && getCompatibleModuloList().length>0">
          <div style="width: 100%; margin: 1%;" *ngFor="let container of selectedEvent.conditions; let i = index">
            <mat-expansion-panel>
              <mat-expansion-panel-header class="disable_ripple">
                <mat-panel-title style="text-transform: uppercase;">Condición {{i + 1}} <i (click)="deleteWorkflowEventContainer(container)" class="material-icons" style="color: red;">delete_forever</i></mat-panel-title>
              </mat-expansion-panel-header>

              <!-- Modulo -->
              <mat-form-field appearance="outline">
                <mat-label>Modulo</mat-label>
                <mat-select [(ngModel)]="container.conditionModulo" (ngModelChange)="onChangeModuloFromCondition(container)">
                  <mat-option *ngFor="let modulo of getCompatibleModuloList()" [value]="modulo.id">{{modulo.modul_name}}</mat-option>
                </mat-select>
              </mat-form-field>


              <div *ngFor="let condition of container.containerConditions">
                <mat-divider></mat-divider>
                <div class="row-expansion4">
                  <p class="custom-title"> Características </p>
                  <!-- Campo -->
                  <mat-form-field>
                    <mat-label>Campo</mat-label>
                    <mat-select [(ngModel)]="condition.field" (ngModelChange)="onChangeField(condition)">
                      <mat-option *ngFor="let field of getFieldList(selectedEvent.modulo, container.conditionModulo)"
                                  [value]="field.id">{{field.nombre_frontend}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <!-- Condición -->
                  <mat-form-field>
                    <mat-label>Condición</mat-label>
                    <mat-select [(ngModel)]="condition.operator">
                      <mat-option *ngFor="let operator of getOperatorList(condition.field, container.conditionModulo)"
                                  [value]="operator.id">{{operator.nombre_frontend}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <!-- Tipo -->
                  <mat-form-field>
                    <mat-label>Tipo</mat-label>
                    <mat-select [(ngModel)]="condition.type">
                      <mat-option *ngFor="let type of getTypesForField(getFieldByModulosAndId(selectedEvent.modulo, container.conditionModulo, condition.field))"
                                  [value]="type.id">{{type.tipo}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <button style="text-align: right !important;" click-stop-propagation (click)="deleteWorkflowEventContainerCondition(container,condition)"
                          class="btn btn-danger erp-status-btn buit">
                    <i class="material-icons">cancel</i> Eliminar caracaterística
                  </button>
                </div>

                <!-- Valor -->
                <div class="row-expansion4 tamano-completo">
                  <p class="custom-title"> Valor </p>
                  <div style="display: inline-flex; flex-direction: column;" *ngIf="getFieldTypeByModulosAndId(selectedEvent.modulo, container.conditionModulo, condition.field); let fieldType">
                    <div *ngIf="condition.type===1">
                      <mat-form-field *ngIf="fieldType!='date' && fieldType!='enum' && fieldType!=='extern'">
                        <input matInput type="text" [(ngModel)]="condition.value" placeholder="">
                      </mat-form-field>

                      <mat-form-field class="dates-inputs" *ngIf="fieldType==='date'">
                        <mat-label>Fecha</mat-label>
                        <input matInput [matDatepicker]="dp" disabled [(ngModel)]="condition.value">
                        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                        <mat-datepicker #dp disabled="false"></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field *ngIf="fieldType==='enum'">
                        <mat-label>Valor1</mat-label>
                        <mat-select [(ngModel)]="condition.value">
                          <mat-option *ngFor="let opt of getOptionsForEnum(container.conditionModulo, condition.field)"
                                      [value]="opt.id">{{opt.name}}</mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field *ngIf="fieldType==='extern'">
                        <mat-label>Valor2</mat-label>
                        <mat-select [(ngModel)]="condition.value">
                          <mat-option *ngFor="let opt of getExternOptions(container.conditionModulo, condition.field)"
                                      [value]="opt.id">{{opt.name}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div *ngIf="condition.type===2">
                      <mat-form-field *ngIf="fieldType!='string' && fieldType!='date' && fieldType!='enum'">
                        <mat-label>Valor</mat-label>
                        <mat-select [(ngModel)]="condition.value">
                          <mat-optgroup *ngFor="let group of container.numberFieldsInConditions" [label]="group.moduleName">
                            <mat-option *ngFor="let opt of group.items"  [value]="opt.id"> {{opt.nombre_frontend}} </mat-option>
                          </mat-optgroup>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field *ngIf="fieldType==='string'">
                        <mat-label>Valor</mat-label>
                        <mat-select [(ngModel)]="condition.value">
                          <!--                              <mat-optgroup *ngFor="let group of getStrings(container.conditionModulo)">-->
                          <mat-optgroup *ngFor="let group of container.stringFieldsInConditions" [label]="group.moduleName">
                            <mat-option *ngFor="let opt of group.items"  [value]="opt.id"> {{opt.nombre_frontend}} </mat-option>
                          </mat-optgroup>
                          <!--                              <mat-option *ngFor="let opt of getStrings(container.conditionModulo)"   [value]="opt.id"> {{opt.nombre_frontend}} </mat-option>-->
                        </mat-select>
                      </mat-form-field>

                      <div *ngIf="fieldType==='date'" style="display: inline-flex; flex-direction: row;">
                        <mat-form-field>
                          <mat-label> Cuándo </mat-label>
                          <mat-select [(ngModel)]="condition.value">
                            <mat-optgroup *ngFor="let group of container.dateFieldsInConditions" [label]="group.moduleName">
                              <mat-option *ngFor="let opt of group.items"  [value]="opt.id"> {{opt.nombre_frontend}} </mat-option>
                            </mat-optgroup>
                          </mat-select>
                        </mat-form-field>

                        <mat-form-field>
                          <mat-label> Operador </mat-label>
                          <mat-select [(ngModel)]="condition.dateOffsetOperation">
                            <mat-option [value]="1">+</mat-option>
                            <mat-option [value]="2">-</mat-option>
                          </mat-select>
                        </mat-form-field>

                        <mat-form-field>
                          <mat-label> Cantidad </mat-label>
                          <input matInput type="number" min="1"
                                 [(ngModel)]="condition.dateOffsetValue"
                                 placeholder="">
                        </mat-form-field>

                        <mat-form-field>
                          <mat-label> Unidad de tiempo </mat-label>
                          <mat-select [(ngModel)]="condition.dateOffsetType">
                            <mat-option [value]="1">Hora/s</mat-option>
                            <mat-option [value]="2">Dia/s</mat-option>
                            <mat-option [value]="3">Semana/s</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <div *ngIf="condition.type===3">
                      <mat-form-field *ngIf="fieldType==='enum'">
                        <mat-label>Valor</mat-label>
                        <mat-select [(ngModel)]="condition.valueList" multiple>
                          <mat-option *ngFor="let opt of getOptionsForEnum(container.conditionModulo, condition.field)" [value]="opt.id">{{opt.name}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>

              <div class="btns-filter" style="margin-top: 1.5em; text-align: center; cursor: pointer;" (click)="addNewWorkflowEventCondition(container)">
                <i class="material-icons">add</i>  AÑADIR CARACTERÍSTICA
              </div>
            </mat-expansion-panel>
          </div>

        </div>
        <button class="btns-filter" style="margin-top: 1.5em; text-align: center; cursor: pointer;" [disabled]="canAddConditionContainer" (click)="addNewWorkflowEventConditionContainer()">
          <i class="material-icons">add</i>  AÑADIR CONDICIÓN
        </button>
      </div>
      <div *ngIf="dataLoaded && getCompatibleModuloList().length===0">
        <h5>
          No hay modulos compatibles para el modulo seleccionado.
        </h5>
      </div>
    </div>

  </mat-expansion-panel>

</mat-accordion>
