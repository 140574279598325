import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface DialogData {
  producto: any;
  productos: any;
}

@Component({
  selector: 'app-shopping-cart-delete-dialog',
  templateUrl: './shopping-cart-delete-dialog.component.html',
  styleUrls: ['./shopping-cart-delete-dialog.component.css']
})
export class ShoppingCartDeleteDialogComponent {

  constructor(public dialogRef: MatDialogRef<ShoppingCartDeleteDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onCancel() {
    this.dialogRef.close(false);
  }

  onAceptar() {
    this.dialogRef.close(true);
  }
}
