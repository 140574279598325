import {Component, OnInit, ViewChild} from '@angular/core';
import {EndpointService} from "../services/endpoint/endpoint.service";
import {MatDialogRef} from "@angular/material/dialog";
import {AuthService} from "../services/auth/auth.service";
import {DomSanitizer} from "@angular/platform-browser";
import { AutoPlantillaComponent} from "../auto-plantilla/auto-plantilla.component";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-demo-dialog',
  templateUrl: './demo-dialog.component.html',
  styleUrls: ['./demo-dialog.component.scss']
})
export class DemoDialogComponent implements OnInit {

  @ViewChild('AutoPlantillaComponent') public AutoPlantillaComponent: AutoPlantillaComponent;

  public productos: any;
  public egoButtonApp: any;
  public loaded = false;

  constructor(
    public endpointService: EndpointService,
    public sanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<DemoDialogComponent>,
    private authService: AuthService,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.endpointService.getProductsOfCompany(this.authService.inmoId, 1, this.authService.languageId).subscribe(data => {
      if (data['errorCode'] === 0) {
        this.productos = data['response'];

        // Mirar si los productos tienen demo y si el usuario ya la ha realizado
        this.endpointService.getDemosInfo(this.authService.userId, this.productos).subscribe(data => {
          if (data['errorCode'] === 0) {
            this.productos.forEach(p => {
              let demo = data['response'].find(d => { return d.id_product == p.id_product });

              if (demo && demo.demoRealizada === 0 && demo.id_plantilla_ego)
                p.puedeHacerDemo = 1;
              else
                p.puedeHacerDemo = 0;
            });
          }
        });

      }

      this.loaded = true;
    });
  }

  onComenzar(product: any) {
    this.dialogRef.close();
    // Buscar plantilla para este producto
    this.endpointService.getPlantillaEgoButtonApp(product.id_product, this.authService.userId).subscribe(data => {
      if (data['errorCode'] === 0) {
        this.egoButtonApp = data['response'][0];

        if (this.egoButtonApp.id_plantilla_ego == 0) {
          this.snackBar.open('Plantilla no disponible', 'X', { duration: 6000, panelClass: ['red-snackbar'] });
        }
        else if (this.egoButtonApp.id_plantilla_ego > 0) {
          this.AutoPlantillaComponent.staticButton(this.egoButtonApp.id);

          if (!this.egoButtonApp.realizada) {
            this.endpointService.setDemoRealizada(this.authService.userId, product.id_product).subscribe(data => {
              if (data['errorCode'] === 0) {
                let result = this.productos.find(p => {
                  return p.id_product === product.id_product;
                })
                result.realizado = 1;
              }
            });
          }

        }
      } else {
        this.snackBar.open('No se ha encontrado plantilla', 'X', { duration: 6000, panelClass: ['red-snackbar'] });
      }
    });
  }

  onCancel(){
    this.dialogRef.close();
  }
}
