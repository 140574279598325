<div class="dialog-container" fxLayout="row wrap" style="margin-top: 1.5em;">
	<label fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="title-input">Categorías</label>
  <span fxFlex="50" fxFlex.sm="100" fxFlex.xs="100">
    <span class="generic-buttons-blue-revert"><button class="close-button" mat-button (click)="onClose(true)"><i class="material-icons">check</i> APLICAR FILTROS</button></span>
    <span class="generic-buttons-blue"><button style="margin-right: 10px;" class="close-button" mat-button (click)="onClose(false)"><i class="material-icons">close</i> VOLVER</button></span>
  </span>
</div>
<div class="shareEvents" fxLayout="row wrap" style="padding: 0 6px; margin-bottom: 10px;">
  <span fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="generic-buttons-blue"><button style="line-height: inherit !important;" mat-button (click)="openColorPickerDialog(null)"><i class="material-icons">add</i> Añadir nueva categoría</button></span>
  <span fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" (click)="check_uncheck_all(!checkAll)" class="selectAllTable">
    <span *ngIf="!checkAll">Marcar todo</span>
    <span *ngIf="checkAll">Desmarcar todo</span>
    <mat-checkbox [ngModel]="checkAll" (click)="$event.stopPropagation()" (change)="check_uncheck_all($event.checked)" style="padding-right: 10px; margin-left: 6px; padding-right: 34px;"></mat-checkbox>
  </span>
</div>
<div class="scroll-lists-agenda list-group">
	<ul class="categories-list">
    <ng-container  *ngFor="let type of allCategories; let i = index;">
      <li (click)="onSelect(type)" *ngIf="type.visible != 0" class="list-group-item" (click)="changeStatus(type.id, !type.value, i)">
        <span [ngClass]="{'agenda-selectors-background-allways': true, 'agenda-selectors-background': (i%2 == 0)}">
          <a *ngIf="(type.parentId === 3)" (click)="openColorPickerDialog(type); $event.stopPropagation()"><i class="material-icons blue">edit</i></a>
          <a *ngIf="(type.parentId === 3)" (click)="openDeleteConfirmEventDialog(); $event.stopPropagation()"><i class="material-icons red">delete</i></a>
          <div class="category-small-circle" [ngStyle]="{'background': type.color}"></div>
          {{type.name}}
          <mat-checkbox *ngIf="filterSelectedCategories" [ngModel]="type.value" (click)="$event.stopPropagation()" (change)="changeStatus(type.id, $event.checked, i)" style="float: right; padding-right: 20px;"></mat-checkbox>
        </span>
      </li>
    </ng-container>
    <li  (change)="changeForeignEvents(!foreignCategories)">
      <i class="material-icons">people</i> Actividades de categorias creadas por otros usuarios
      <mat-checkbox [(ngModel)]="foreignCategories" (change)="changeForeignEvents($event.checked)" style="float: right; padding-right: 30px;"></mat-checkbox>
    </li>
	</ul>
</div>
