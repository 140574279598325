<div *ngIf="tipo.tipo == 2">
  <p>¿Estás seguro de que quieres cancelar la colaboración?</p>
  <div class="dialog-footer-2">
    <button mat-button (click)="sendEvent(true)">Cancelar colaboración</button>
    <button mat-button (click)="sendEvent(false)">Cerrar</button>
  </div>
</div>

<div *ngIf="tipo.tipo == 4">
  <p>¿Estás seguro de que quieres cancelar tu solicitud de colaboración?</p>
  <div class="dialog-footer-2">
    <button mat-button (click)="sendEvent(true)">Cancelar solicitud</button>
    <button mat-button (click)="sendEvent(false)">Cerrar</button>

  </div>
</div>

<div *ngIf="tipo.tipo == 6">
  <p>¿Estás seguro de que quieres abandonar este grupo?</p>
  <div class="dialog-footer-2">
    <button mat-button (click)="sendEvent(true)">Abandonar grupo</button>
    <button mat-button (click)="sendEvent(false)">Cerrar</button>
  </div>
</div>

<div *ngIf="tipo.tipo == 8">
  <p>¿Estás seguro de que quieres cancelar tu solicitud de acceso a este grupo?</p>
  <div class="dialog-footer-2">
    <button mat-button (click)="sendEvent(true)">Cancelar solicitud</button>
    <button mat-button (click)="sendEvent(false)">Cerrar</button>
  </div>
</div>

<div *ngIf="tipo.tipo == 10">
  <p>¿Estás seguro que quieres expulsar la inmobiliaria del grupo?</p>
  <div class="dialog-footer-2">
    <button mat-button (click)="sendEvent(true)">Expulsar inmobiliaria</button>
    <button mat-button (click)="sendEvent(false)">Cerrar</button>
  </div>
</div>

<div *ngIf="tipo.tipo == 10">
  <p>¿Estás seguro que quieres cancelar tu invitación para entrar en el grupo a esta inmobiliaria?</p>
  <div class="dialog-footer-2">
    <button mat-button (click)="sendEvent(true)">Cancelar invitación</button>
    <button mat-button (click)="sendEvent(false)">Cerrar</button>
  </div>
</div>

<div *ngIf="tipo.tipo != 2 && tipo.tipo != 4 && tipo.tipo != 6 && tipo.tipo != 8 && tipo.tipo != 10 && tipo.tipo != 12">
  <p>¿Estás seguro de que quieres realizar esta acción?</p>
  <div class="dialog-footer-2">
    <button mat-button (click)="sendEvent(true)">Realizar acción</button>
    <button mat-button (click)="sendEvent(false)">Cerrar</button>
  </div>
</div>