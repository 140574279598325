import { Injectable } from '@angular/core';
import { CustomEvent, CustomEventAction } from 'app/activities/objects/custom-event.component';


@Injectable()
export class ActivitiesService {


    // Es el diccionario de todos los custom events de los usuarios que hemos cargado
    public customEventsGeneral = {};

    // Es un array con todas las actividades
    public customEventsTotal = [];

    // Guarda la información y las opciones de notifications
    public notifications = null;

    // Guarda la estructura de los items que tienen actividades en sus componentes
    public controlsItemsActivities = {};

    // Guarda los valores activos de las cuentas
    public accountsActive = {};

    // Mantengo el valor de si Google Calendar esta activo o no
    public isGoogleCalendarActive : boolean;

    public isMicrosoftCalendarActive: boolean;
    
    // Mantengo la información de los calendarios activos
    public calendarsSelected = [];

    // Imagen del usuario
    public imageUserActive: CustomEventAction[] = [];

    // Diccionario donde mantengo las diferentes categorias de cada empresa
    public categoriesByAccount = {};

    public showOnlyMyAgenda : boolean = null;

    constructor() {
    }


    // Añado el customEvents al customEventsGeneral
    public addEventToCustomEventGeneral(tempEvent, year, month){
        // Diferenciamos de los eventos de tipo festivos, los cargaremos siempre y no los tocaremos
        if(tempEvent.type.id === 10 && tempEvent.type.id === 74) tempEvent['idCreator'] = -1;
        if(this.customEventsGeneral[tempEvent['idCreator']] !== undefined){
            // Ya esta definido, no es su primer evento
            // Compruebo si ya he calculado su mes y año
            this.customEventsGeneral[tempEvent['idCreator']]['customEvents'] = this.customEventsGeneral[tempEvent['idCreator']]['customEvents'].concat(tempEvent);
        } else {
            // Aún no está definido, es su primer evento
            let tempArray: CustomEvent[] = [tempEvent];
            this.customEventsGeneral[tempEvent['idCreator']] = {customEvents: [], externalEvents: [], monthToFilter: {}};
            this.customEventsGeneral[tempEvent['idCreator']]['customEvents'] = tempArray;
            this.customEventsGeneral[tempEvent['idCreator']]['monthToFilter'][year+'-'+month] = true
        }
        this.customEventsTotal.push(tempEvent);
    }

    // Añado el externalEvent al customEventsGeneral
    public addEventToExternalEventGeneral(externalEvent, year, month){
        if(this.customEventsGeneral[externalEvent['idCreator']] !== undefined){
            // Ya esta definido, no es su primer evento
            this.customEventsGeneral[externalEvent['idCreator']]['externalEvents'] = this.customEventsGeneral[externalEvent['idCreator']]['externalEvents'].concat(externalEvent);
        } else {
            // Aún no está definido, es su primer evento
            let tempArray: CustomEvent[] = [externalEvent];
            this.customEventsGeneral[externalEvent['idCreator']] = {customEvents: [], externalEvents: [], monthToFilter: {}};
            this.customEventsGeneral[externalEvent['idCreator']]['externalEvents'] = tempArray;
            this.customEventsGeneral[externalEvent['idCreator']]['monthToFilter'][year+'-'+month] = true

        }
    }

    // Añado los calendarios activos de la persona
    public addCalendarsSelected(calendars){
        if(calendars !== undefined && Array.isArray(calendars)) {
            this.calendarsSelected = [];
            calendars.forEach(c => { this.calendarsSelected.push(c.id); });
        }
    }

    public clearCustomEventsGeneral(){
        this.customEventsGeneral = {};
        this.customEventsTotal = [];
    }

    public setCategories(account, categories){
        if(this.categoriesByAccount[account] == undefined){
            this.categoriesByAccount[account] = {data: []};
            this.categoriesByAccount[account]['data'] = categories;
        }
    }

    public setControls(structure){
        this.controlsItemsActivities[structure['id_functional_area']] = structure;
    }
    
    public changeDateFormat(date, type) {
        let newDate = new Date(date);

        let formatedDate = '';

        if (type == 1) {
            formatedDate =
            ("00" + newDate.getDate()).slice(-2) + "/" +
            ("00" + (newDate.getMonth() + 1)).slice(-2) + "/" +
            newDate.getFullYear() + " " +
            ("00" + newDate.getHours()).slice(-2) + ":" +
            ("00" + newDate.getMinutes()).slice(-2) + ":" +
            ("00" + newDate.getSeconds()).slice(-2);
        } else {
            formatedDate =
            newDate.getFullYear() + "-" +
            ("00" + (newDate.getMonth() + 1)).slice(-2) + "-" +
            ("00" + newDate.getDate()).slice(-2) + " " +
            ("00" + newDate.getHours()).slice(-2) + ":" +
            ("00" + newDate.getMinutes()).slice(-2) + ":" +
            ("00" + newDate.getSeconds()).slice(-2);
        }

        return formatedDate;
    }
}
