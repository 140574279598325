import {Component, ElementRef, Inject, Input, ViewChild, OnInit, ChangeDetectorRef} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {EndpointService} from '../../services/endpoint/endpoint.service';
import {AuthService} from '../../services/auth/auth.service';
import {Values} from '../../../values/values';

import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


@Component({
  selector: 'bottom-sheet-image-selector',
  templateUrl: 'bottom-sheet-image-selector.html',
})
export class BottomSheetImageSelector implements OnInit{

  //public backendRequest: string;
  public imageType: number;
  public images: any;
  public imagesAux: any;  //where the checkbox are stored
  public acceptButtonText = 'Aceptar';
  public declineButtonText = 'Declinar';
  public uploadImageButtonText = 'Subir imagen';

  public imgURL = null;
  public imagePath: FileList;
  public file: File;
  public formData: FormData;
  public url = '';

  @ViewChild("fileUpload", { static: false }) fileUpload: ElementRef;
  files  = [];

  constructor(private _bottomSheetRef: MatBottomSheetRef<BottomSheetImageSelector>,
              private endpointService: EndpointService,
              private authService: AuthService,
              private _bottomSheet: MatBottomSheet,
              private changeDetectorRef: ChangeDetectorRef, //refresca el bottom Sheet
              @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
    //this.backendRequest = data.backendRequest;
    this.imageType = data.imageType;
    this.images = data.images;
  }

  ngOnInit(){

    this.imagesAux = new Array(this.images.size);
    this.images.map((t,index) => this.imagesAux[index] = t.selected);
  }

  closeBottomSheet(buttonOption: number){
    if (buttonOption === 1){
      this.images.map((t,index) => t.selected = this.imagesAux[index]);
    }
    this._bottomSheetRef.dismiss(buttonOption);
  }

  onClickAceptUpload() {
    //this.loadImages.emit();

    /*this.endpointService.insertEmailImage(this.authService.inmoId).subscribe(data => {
    });*/
  }

  /*showImageUpload() {

    let bottomSheet = this._bottomSheet.open(BottomSheetUploadImage, {
      data : {backendRequest : this.backendRequest}
    });

    bottomSheet.afterDismissed().subscribe(data => {
      if (data === 1){
        this.onClickAceptUpload();
        let str = bottomSheet.instance.imageName;
        let address = Values.ROOT_URL + '/empresas/' + this.authService.inmoId + '/plantillas/imagenes_email/' + str;  //funciona mentres no es canvi la estructura en el servidor

        this.images.push({src: address, selected: false});
        this.imagesAux.push(false);

      } else {
        //this.onClickDeclineUpload();
      }
      let bottomSheet2 = this._bottomSheet.open(BottomSheetImageSelector, {
        data : {backendRequest : this.backendRequest, images : this.images}
      });
    });
  }*/

  deleteImage(url, index){

    console.log(url);

    let usefulURL;

    if(this.imageType === 1){
      usefulURL = url.split('empresas/');

      this.endpointService.deleteTempleteImage(usefulURL[1]).subscribe(data => {
        if (data['errorCode'] === 0){
          this.images = this.images.filter( (t,i) => i !== index);
          this.changeDetectorRef.detectChanges(); //component per refrescar el botom sheet, sense ell les imatges que es posaven no es refrescaven (important)
        }
      });
    }
    else if(this.imageType === 2){
      usefulURL = url.split('ego/');

      this.endpointService.deleteImageEgo(usefulURL[1]).subscribe(data => {
        if (data['errorCode'] === 0){
          this.images = this.images.filter( (t,i) => i !== index);
          this.changeDetectorRef.detectChanges(); //component per refrescar el botom sheet, sense ell les imatges que es posaven no es refrescaven (important)
        }
      });
    }
  }

  uploadFileEmail(file) {
    const formData = new FormData();
    formData.append('file', file.data);
    file.inProgress = true;
    this.endpointService.uploadTempleteImage(formData, this.authService.inmoId).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(event.loaded + 0.000000001 / event.total);
            break;
          case HttpEventType.Response:
            return event;
        }
        this.changeDetectorRef.detectChanges(); //component per refrescar el botom sheet, sense ell les imatges que es posaven no es refrescaven (important)

      }),
      catchError((error: HttpErrorResponse) => {
        file.inProgress = false;
        return of(`${file.data.name} upload failed.`);
      })).subscribe((event: any) => {
      if (typeof (event) === 'object') {
        const subs = event.body.response.split('vhosts/');
        const subsubs = subs[1].split('movin.cloud/');
        this.images.unshift({
          src: Values.ROOT_URL + '/' + subsubs[2],
          selected: false
        });
        this.changeDetectorRef.detectChanges(); //component per refrescar el botom sheet, sense ell les imatges que es posaven no es refrescaven (important)
      }
    });
  }

  uploadFileEgo(file) {
    const formData = new FormData();
    formData.append('file', file.data);
    file.inProgress = true;
    this.endpointService.insertEgoImage(formData).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(event.loaded + 0.000000001 / event.total);
            break;
          case HttpEventType.Response:
            return event;
        }
        this.changeDetectorRef.detectChanges(); //component per refrescar el botom sheet, sense ell les imatges que es posaven no es refrescaven (important)

      }),
      catchError((error: HttpErrorResponse) => {
        file.inProgress = false;
        return of(`${file.data.name} upload failed.`);
      })).subscribe((event: any) => {
      if (typeof (event) === 'object') {
        const subs = event.body.response.split('vhosts/');
        const subsubs = subs[1].split('movin.cloud/');
        this.images.unshift({
          src: Values.ROOT_URL + '/' + subsubs[2],
          selected: false
        });
        this.changeDetectorRef.detectChanges(); //component per refrescar el botom sheet, sense ell les imatges que es posaven no es refrescaven (important)
      }
    });
  }

  private uploadFiles() {
    this.fileUpload.nativeElement.value = '';

    if(this.imageType === 1) this.uploadFileEmail(this.files[this.files.length - 1]);
    else if(this.imageType === 2) this.uploadFileEgo(this.files[this.files.length - 1]);

    /*this.files.forEach(file => {
      this.uploadFile(file);
    });*/
  }

  addImage() {
    const fileUpload = this.fileUpload.nativeElement;fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++)
      {
        const file = fileUpload.files[index];
        this.files.push({ data: file, inProgress: false, progress: 0});
      }
      this.uploadFiles();
    };
    fileUpload.click();
  }

  onlyOne(index){
    console.log(this.imagesAux);
    for(let i = 0; i < this.imagesAux.length; i++) if(i != index) this.imagesAux[i] = false;
    console.log(this.imagesAux);

  }

}
