<ng-container *ngIf="actualElement !== undefined && actualElement !== null && this.actualElement['formCreator'] !== undefined">
    <form [formGroup]="this.actualElement['formCreator']" class="formGeneralJourneys">
        <div class="add_nodes">
            <mat-form-field appearance="outline">
                <mat-label>Nombre</mat-label>
                <input matInput formControlName="name" #nameAction maxlength="100">
                <mat-hint align="end">{{nameAction.value?.length || 0}}/100</mat-hint>                        
            </mat-form-field>
            
            <mat-form-field appearance="outline">
                <mat-label>Notas</mat-label>
                <textarea matInput formControlName="notes"></textarea>
            </mat-form-field>


            <mat-form-field appearance="outline">
                <mat-label>Action</mat-label>
                <mat-select formControlName="id_type_action">
                    <ng-container *ngIf="this.actualElement['id_category'] != 2">
                        <mat-option *ngFor="let action of journeyService.actionTypes" [value]="action.id">
                            <span class="material-icons-outlined">{{action.icon}}</span> {{action.name}}
                        </mat-option>
                    </ng-container>
                    <ng-container *ngIf="this.actualElement['id_category'] == 2">
                        <mat-option *ngFor="let action of journeyService.triggerTypes" [value]="action.id">
                            <span class="material-icons-outlined">{{action.icon}}</span> {{action.name}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>

        <ng-container *ngIf="showExtraInfo">
            <div class="autocomplete_div" *ngIf="autoComplete">
                <div class="form-group" *ngIf="type != 5">
                    <mat-form-field appearance="outline" *ngIf="this.nameActualAutocomplete == 'id_template'" [ngClass]="{'generic-input-disabled': this.journeyService.optionAutocompleteSelected}">
                        <mat-label>{{autoCompleteTitle}}</mat-label>
                        <input [attr.disabled]="this.journeyService.optionAutocompleteSelected ? true :null" type="text" aria-label="internal_name" matInput
                            [matAutocomplete]="autoComplete" formControlName="id_template" required>
                        <mat-autocomplete autoActiveFirstOption #autoComplete="matAutocomplete">
                            <mat-option *ngFor="let item of autocompleteData | async" [value]="item.nombre">
                                <span class="">{{item.icon}}</span> {{item.nombre}}
                            </mat-option>
                        </mat-autocomplete>
                        <span matSuffix *ngIf="this.journeyService.optionAutocompleteSelected" class="closeAutocomplete"><mat-icon (click)="clickClose(); $event.stopPropagation()" class="material-icons">close</mat-icon></span>
                    </mat-form-field>

                    <mat-form-field appearance="outline" *ngIf="this.nameActualAutocomplete == 'id_tag'" [ngClass]="{'generic-input-disabled': this.journeyService.optionAutocompleteSelected}">
                        <mat-label>{{autoCompleteTitle}}</mat-label>
                        <input [attr.disabled]="this.journeyService.optionAutocompleteSelected ? true :null" type="text" aria-label="internal_name" matInput
                            [matAutocomplete]="autoComplete" formControlName="id_tag" required>
                        <mat-autocomplete autoActiveFirstOption #autoComplete="matAutocomplete">
                            <mat-option *ngFor="let item of autocompleteData | async" [value]="item.nombre">
                                {{item.nombre}}
                            </mat-option>
                        </mat-autocomplete>
                        <span matSuffix *ngIf="this.journeyService.optionAutocompleteSelected" class="closeAutocomplete"><mat-icon (click)="clickClose(); $event.stopPropagation()" class="material-icons">close</mat-icon></span>
                    </mat-form-field>

                    <mat-form-field appearance="outline" *ngIf="this.nameActualAutocomplete == 'id_action_new'" [ngClass]="{'generic-input-disabled': this.journeyService.optionAutocompleteSelected}">
                        <mat-label>{{autoCompleteTitle}}</mat-label>
                        <input [attr.disabled]="this.journeyService.optionAutocompleteSelected ? true :null" type="text" aria-label="internal_name" matInput
                            [matAutocomplete]="autoComplete" formControlName="id_action_new" required>
                        <mat-autocomplete autoActiveFirstOption #autoComplete="matAutocomplete">
                            <mat-option *ngFor="let item of autocompleteData | async" [value]="item.name">
                                {{item.name}}
                            </mat-option>
                        </mat-autocomplete>
                        <span matSuffix *ngIf="this.journeyService.optionAutocompleteSelected" class="closeAutocomplete"><mat-icon (click)="clickClose(); $event.stopPropagation()" class="material-icons">close</mat-icon></span>
                    </mat-form-field>

                    <mat-form-field appearance="outline" *ngIf="this.nameActualAutocomplete == 'id_campaign'" [ngClass]="{'generic-input-disabled': this.journeyService.optionAutocompleteSelected}">
                        <mat-label>{{autoCompleteTitle}}</mat-label>
                        <input [attr.disabled]="this.journeyService.optionAutocompleteSelected ? true :null" type="text" aria-label="internal_name" matInput
                            [matAutocomplete]="autoComplete" formControlName="id_campaign" required>
                        <mat-autocomplete autoActiveFirstOption #autoComplete="matAutocomplete">
                            <mat-option *ngFor="let item of autocompleteData | async" [value]="item.name">
                                {{item.name}}
                            </mat-option>
                        </mat-autocomplete>
                        <span matSuffix *ngIf="this.journeyService.optionAutocompleteSelected" class="closeAutocomplete"><mat-icon (click)="clickClose(); $event.stopPropagation()" class="material-icons">close</mat-icon></span>
                    </mat-form-field>

                    <mat-form-field appearance="outline" *ngIf="this.nameActualAutocomplete == 'id_template_sent'" [ngClass]="{'generic-input-disabled': this.journeyService.optionAutocompleteSelected}">
                        <mat-label>{{autoCompleteTitle}}</mat-label>
                        <input [attr.disabled]="this.journeyService.optionAutocompleteSelected ? true :null" type="text" aria-label="internal_name" matInput
                            [matAutocomplete]="autoComplete" formControlName="id_template_sent" required>
                        <mat-autocomplete autoActiveFirstOption #autoComplete="matAutocomplete">
                            <mat-option *ngFor="let item of autocompleteData | async" [value]="item.nombre">
                                {{item.nombre}}
                            </mat-option>
                        </mat-autocomplete>
                        <span matSuffix *ngIf="this.journeyService.optionAutocompleteSelected" class="closeAutocomplete"><mat-icon (click)="clickClose(); $event.stopPropagation()" class="material-icons">close</mat-icon></span>
                    </mat-form-field>

                    <ng-container *ngIf="this.nameActualAutocomplete2 == 'id_empresa_email_config'">
                        <mat-form-field *ngIf="journeyService.emailConfigArray.length && journeyService.emailConfigArray.length > 0" appearance="outline" [ngClass]="{'generic-input-disabled': this.journeyService.optionAutocompleteSelected2}">
                            <mat-label>{{autoCompleteTitle2}}</mat-label>
                            <input [attr.disabled]="this.journeyService.optionAutocompleteSelected2 ? true :null" type="text" aria-label="internal_name" matInput
                                [matAutocomplete]="autoComplete" formControlName="id_empresa_email_config" required>
                            <mat-autocomplete autoActiveFirstOption #autoComplete="matAutocomplete">
                                <mat-option *ngFor="let item of autocompleteData2 | async" [value]="item.email">
                                    {{item.email}}
                                </mat-option>
                            </mat-autocomplete>
                            <span matSuffix *ngIf="this.journeyService.optionAutocompleteSelected2" class="closeAutocomplete"><mat-icon (click)="clickClose(); $event.stopPropagation()" class="material-icons">close</mat-icon></span>
                        </mat-form-field>
                        <p *ngIf="!journeyService.emailConfigArray.length || journeyService.emailConfigArray.length == 0" style="color: red;">Es necesario Configurar un email de empresa para el envío</p>
                    </ng-container>
                </div>
                
            
                <div class="form-group" *ngIf="type == 5" style="display: flex;">
                    <mat-form-field appearance="outline" fxFlex="50">
                        <mat-label>Temporalidad</mat-label>
                        <input matInput type="number" placeholder="Enter time to wait" formControlName="num_temporality" required>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="50">
                        <mat-label>Unidad de tiempo</mat-label>
                        <mat-select formControlName="id_temporality" required>
                            <mat-option *ngFor="let time of this.journeyService.temporalities" [value]="time.id_temporality">
                                {{time.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            
            </div>
        </ng-container>

        <div fxLayout="row" fxLayoutAlign="end">
            <div class="generic-buttons-blue" style="margin-right: 10px;">
                <button mat-raised-button (click)="cancelElement()">Cancelar</button>
            </div>
            <div class="generic-buttons-blue-revert" *ngIf="this.nameActualAutocomplete2 != 'id_empresa_email_config' || (journeyService.emailConfigArray.length && journeyService.emailConfigArray.length > 0)">
                <button mat-raised-button (click)="submitElement()">Añadir</button>
            </div>
        </div>
    </form>
</ng-container>