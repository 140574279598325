import { Component, OnInit, Input } from '@angular/core';
import { GenericService } from "../../../services/generic/generic.service";

@Component({
  selector: 'app-graphs',
  templateUrl: './graphs.component.html',
  styleUrls: ['./graphs.component.css']
})
export class GraphsComponent implements OnInit {

  @Input() structure: any;
  @Input() index: number;

  // INFO COMPONENT: https://canvasjs.com/angular-charts/
  public chartOptions = {};
  public loaded = false;
  public dataQuery = [];

  constructor(
    public genericService: GenericService,
  ) { }

  ngOnInit(): void {
    const idQuery: number = this.structure[this.index]["id_query"];
    if (idQuery > 0 && this.genericService.selectOptions[idQuery] !== undefined && this.genericService.selectOptions[idQuery] !== null) {
      if(Array.isArray(this.genericService.selectOptions[idQuery])) {
        this.dataQuery = this.genericService.selectOptions[idQuery];
      } else {
        this.dataQuery[0] = this.genericService.selectOptions[idQuery];
      }
    }

    let type = this.structure[this.index]['type'];
    let label = "";
    if(this.structure[this.index]['label']) label = this.structure[this.index]['label'];
    let text = "";
    if(this.structure[this.index]['text']) text = this.structure[this.index]['text'];
    if(this.structure[this.index]["show_total_graphs"] == 1) {
      let total = 0;
      for(let d in this.dataQuery) {
        this.dataQuery[d]['y'] = +this.dataQuery[d]['y'];
        let decimalesTotal: number = this.contarDecimales(total);
        let decimalesValor: number = this.contarDecimales(this.dataQuery[d]['y']);
        let mayorNumero = decimalesValor > decimalesTotal ? decimalesValor : decimalesTotal;
        total = parseFloat((total + this.dataQuery[d]['y']).toFixed(+mayorNumero));
      }
      label = label + ": " + total + text;
    }

    if(this.dataQuery.length > 0) {
      switch (type) {
  
        case "pie":
          let dataQueryPie = []; 
          dataQueryPie = this.dataQuery.map(item => ({ y: item.y, name: item.label.toString() }));
          this.chartOptions = {
            animationEnabled: true,
            title: {
              text: label
            },
            data: [{
              type: type,
              startAngle: -90,
              indexLabel: "{name}: {y}" + text,
              yValueFormatString: "#,###.##''",
              dataPoints: dataQueryPie
            }]
          }
          break;
  
        case "column":
          this.chartOptions = {
            title: {
              text: label
            },
            animationEnabled: true,
            data: [{
              type: "column",
              animationEnabled: true,
              toolTip: {
                shared: true
              },
              legend: {
                horizontalAlign: "right",
                verticalAlign: "center",
                reversed: true
              },
              axisY: {
                includeZero: true
              },
              dataPoints: this.dataQuery
            }]
          }
          break;
        case "line":
          let dataQueryLine = []; 
          dataQueryLine = this.dataQuery.map(item => ({ y: +item.y, name: item.label.toString(), label: item.label.toString() }));
  
          this.chartOptions = {
            animationEnabled: true,
            title: {
              text: label
            },
            data: [{
              type: type,
              startAngle: -90,
              indexLabel: "{y}" + text,
              yValueFormatString: "#,###.##",
              dataPoints: dataQueryLine
            }]
          }
          break;
        default:
          // 
          break;
      }
    }

    setTimeout(() => {
      this.loaded = true;
    });
  }

  private contarDecimales(numero: number): number {
    const partes = numero.toString().split(".");
    if (partes.length === 1) {
        return 0; // No hay parte decimal
    }
    return partes[1].length;
}

}
