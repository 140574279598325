<div class="dialog-container" style="text-align: right">
  <button class="close-button" mat-button (click)="onNoClick()"><i class="material-icons">close</i></button>
</div>

<div class="alert alert-info" style="color: white">
  Seleccione la fecha a la que desea posponer el evento
</div>
<div style="display: flex; flex-direction: column; margin-bottom: 1em;">
  <mat-form-field class="dates-inputs" style="cursor: pointer">
    <mat-label>Fecha de inicio</mat-label>
    <input matInput class="form-control" type="text" mwlFlatpickr [altInput]="true"
           [ngModel]="dateStart" (ngModelChange)="dateStart = $event" (ngModelChange)="refresh.next()"
           [convertModelValue]="true" [enableTime]="true" [time24hr]="true" dateFormat="Y-m-dTH:i"
           altFormat="F j, Y H:i" [required]="true"/>
    <mat-icon matSuffix *ngIf="dateStart" (click)="dateStart = null">close</mat-icon>
    <mat-icon matSuffix *ngIf="!dateStart">calendar_today</mat-icon>
    <mat-error *ngIf="startDateIsInvalid()">{{getStartDateErrorMessage()}}</mat-error>
  </mat-form-field>

  <mat-form-field class="dates-inputs" style="cursor: pointer">
    <mat-label>Fecha Fin</mat-label>
    <input matInput class="form-control" type="text" mwlFlatpickr [ngModel]="dateEnd"
           (ngModelChange)="dateEnd = $event" (ngModelChange)="refresh.next()" [altInput]="true"
           [convertModelValue]="true" [enableTime]="true" [time24hr]="true" dateFormat="Y-m-dTH:i"
           altFormat="F j, Y H:i"/>
    <mat-icon matSuffix *ngIf="dateEnd" (click)="dateEnd = null">close</mat-icon>
    <mat-icon matSuffix *ngIf="!dateEnd">calendar_today</mat-icon>
    <mat-error *ngIf="endDateIsInvalid()">{{getEndDateErrorMessage()}}</mat-error>
  </mat-form-field>
</div>

<div>
  <button class="btns-filter btn-outline-success btn1" cdkFocusInitial style="margin: 0.5em" [disabled]="(startDateIsInvalid() || endDateIsInvalid())"
  (click)="postponeActivity()">
    <i style="margin-right: 0.3em;" class="material-icons">check</i>
    POSPONER
  </button>
</div>
