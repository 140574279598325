import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import { GenericService } from 'app/services/generic/generic.service';
import { RoutingService } from 'app/services/routing/routing.service';
import { SideNavService } from 'app/utils/SideNavService';
import {EndpointService} from "../../../services/endpoint/endpoint.service";
import {AuthService} from "../../../services/auth/auth.service";

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() structure = null;

  private sidenavId;
  private monitoreo;

  constructor(private routingService: RoutingService,
    private genericService: GenericService,
    private sidenav: SideNavService,
    private endpointService: EndpointService,
    public authService: AuthService) { }

  ngOnInit(): void {
    this.sidenavId = this.structure['id_functional_parent_inital_dsb'];
    this.endpointService.insertMonitoringInformation(this.authService.getLoggedInUserId(), this.sidenavId, this.sidenavId, this.genericService.staticHTML, this.genericService.paramControlVariables).subscribe(data => {
      this.monitoreo = data['response'];
    });
  }

  ngOnDestroy() {
    this.endpointService.updateMonitoringInformation(this.monitoreo, this.genericService.staticHTML).subscribe(data => {});
  }

  ngAfterViewInit() {
    if(this.routingService.urlHashtag != null) {
      const id = this.routingService.urlHashtag;
      for(const key in this.structure){
        if(key == id){
          this.sidenav.open(0);
          break;
        }else{
          const element = this.genericService.findElementWithIdLoop(this.structure, id);
          if(element){
            this.sidenav.open(0);
            break;
          }
        }
      }
    }
  }
}
