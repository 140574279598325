import { formatDate, Time } from "@angular/common";
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Values } from '../../../values/values';
import { UntypedFormControl } from '@angular/forms';
import { EndpointService } from '../endpoint/endpoint.service';
import { RedirectionService } from '../redirection/redirection.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable({ providedIn: 'root' })

@Injectable()
export class AuthService {

  @Output() clienteNew: EventEmitter<any> = new EventEmitter();
  @Output() monitoringIndex: EventEmitter<number> = new EventEmitter();

  private profilePicSubscription = new Subject<void>();
  public profilePic$ = this.profilePicSubscription.asObservable();
  profilePicController = new UntypedFormControl();


  private userFullNameSubscription = new Subject<void>();
  public userFullName$ = this.userFullNameSubscription.asObservable();

  private inmobiliariaNameSubscription = new Subject<void>();
  public inmobiliariaName$ = this.inmobiliariaNameSubscription.asObservable();

  private groupNameSubscription = new Subject<void>();
  public groupName$ = this.groupNameSubscription.asObservable();

  public redirectURL: string;

  public userId: number;
  public checkUserIsDeveloping = 0;
  public userFullName: string;
  public profileId: number;
  public profileIdGeneric: number;
  public finishToLogued = true;

  public googleToken: string;
  public microsoftToken: string;

  public inmoId: number;
  public groupId: number;
  public empresaId: number;
  public empresaIdCategoryGeneric: number;
  public empresaIdCompanyType = [];
  public empresaCategoryGeneric: string;

  public activeTypeAccount: number;

  public groupType: number;
  public groupName: string;
  public groupTipoPerfil: number;
  public groupURL: string;
  public companyGenericName: string;

  public isMovinUser: boolean;
  public isResponsible: boolean;
  public isOrphan: boolean;
  public languageId: number;
  public labelLanguage: string;
  public arrayLanguages = [];
  public fechaInicioCal: Time;
  public fechaFinCal: Time;
  public idAviso1: number;
  public idAviso2: number;
  public idAviso3: number;
  public profilePic: string;
  public isInmobiliari: number;
  public espera: number; // Si el usuari que tiene una inmo esta o no en espera
  public cargo: number;
  public inmobiliariaName: string;
  public inmoURL: string;
  public inmoTipoPerfil: number;
  public rango_colabo: number;
  public responsable_community: number;
  public puede_colaborar: number;
  public user_token: string;
  public dia_inicio_calendario: number;

  public modifica_actividades_otros: boolean = false;
  
  public connectedVideoConference: boolean;

  private expirationDate: any;

  private userInfo: any;

  public routes: any;

  public primary_color = "#5a8abe";
  public secondary_color = "#fddd00";
  public tertiary_color = "#882334";

  public accounts: any;

  public accountsGeneric: any;

  public adminArea: any;

  public headerStructure = null;
  public footerStructure = null;
  public externalWindowStructure = null;

  public arrayProductos = [];
  public productsCounter = 0;
  public productsPriceCounter = 0;
  public productsPriceCounterLabel = "0,00";

  public arrayEstancias = [];
  public arrayEstanciasLabel = "";
  public reservaInfo = [];
  public numPersonas = 0;
  public numAdultos = 0;
  public numNinos = 0;
  public numBebes = 0;
  public lastUrl = "";
  public estanciasCounter = 0;
  public daysCounter = 0;
  public estanciasPriceCounter = 0;
  public estanciasPriceCounterLabel = "0,00";

  public importDeportActivity = 0;
  
  loadingInit: boolean;

  // public espera: boolean;

  constructor(private endpointService: EndpointService,
              private redirectionService: RedirectionService,
              public dialog: MatDialog,
              private router: Router) {
    this.redirectURL = null;

    this.userId = null;
    this.userFullName = null;
    this.profileId = null;
    this.profileId = null;

    this.inmoId = null;
    this.groupId = null;

    this.groupType = null;
    this.groupTipoPerfil = null;
    this.groupURL = null;

    this.isMovinUser = false;
    this.isResponsible = false;
    this.isOrphan = false;
    this.languageId = null;
    this.fechaInicioCal = null;
    this.fechaFinCal = null;
    this.idAviso1 = null;
    this.idAviso2 = null;
    this.idAviso3 = null;
    this.expirationDate = null;
    this.profilePic = null;
    this.connectedVideoConference = false;
    this.isInmobiliari = null;
    this.espera = null;
    this.cargo = null;
    this.userInfo = null;
    this.inmobiliariaName = null;
    this.inmoURL = null;
    this.rango_colabo = null;
    this.inmoTipoPerfil = null;
    this.responsable_community = null;
    this.puede_colaborar = null;
    this.user_token = null;
    this.dia_inicio_calendario = null;

    this.accounts = [];
    this.accountsGeneric = [];

    this.routes = Values.ROUTES;

    /*
    this.profilePicController.valueChanges
    .pipe(
      debounceTime(500),
    ).subscribe(
      this.profilePicSubscription.next();
    );*/
  }


/* Joan
  // Check if user is logged in, it checks if expirationDate is newer than expectes and unlogs
  public isLoggedIn() {
    if (this.userId) {
      if (this.expirationDate) {
        if (this.expirationDate > Math.round(new Date().getTime() / 1000)) {
          return true;
        } else {
          this.logout();
          return false;
        }
      }
      return false;
    } else {
      return false;
    }
  }
*/

  public getUserInfoRoute(): Promise<void> {
    return new Promise<void>((resolve) => {
     // this.genericService.getUserInfo(2);
    });
  }

  public isUserSessionActive():boolean {
    // return this.userId && this.expirationDate && this.expirationDate > Math.round(new Date().getTime() / 1000);
    return (this.userId > 0);
  }

  public login() {
    /* Joan
    // Joan: Chapuza per poder arrancar - TODO: Arreglar-ho
    if(this.redirectionService.redirectionJoinGroup){
      window.location.href = this.redirectionService.redirectionURL;
      this.redirectionService.redirectionJoinGroup = false;
    } else{
      this.router.navigate([Values.ROUTES.LANDING]).then(() => {
          window.location.reload();
          //hauria de cridar genericService.refreshStructure(2) pero generaria dependencia circular
      });
    }
    */
    //this.router.navigate([Values.ROUTES.LANDING]).then(() => {
    this.router.navigate(['']).then(() => {
      window.location.reload();
      //hauria de cridar genericService.refreshStructure(2) pero generaria dependencia circular
    });
  }

  public logout(route = '', extern_url = false) {
    this.finishToLogued = false;
    /* Joan
    // Con esto evitamos cerrar sesion si tenemos cambios sin guardar por el método de login que usamos
    if (sessionStorage.getItem('__s__') == null) {
      localStorage.removeItem('_i_');
      localStorage.removeItem('_e_');
      location.reload();
    } else {
      this.router.navigate([Values.ROUTES.LOGIN]);
    }
    this.userInfo = null;
    */
    //Joan: Code added instead above lines

    this.userId = null;
    this.user_token = null;
    this.expirationDate = null;
    this.userInfo = null;
    this.accounts = [];
    localStorage.removeItem('_i_');
    localStorage.removeItem('_e_');
    localStorage.removeItem('_in_');
    localStorage.removeItem('_itk_');

    //browser.manage().deleteAllCookies().then(r => );
    sessionStorage.clear();
    localStorage.clear();

    //this.genericService.clearHistory();

    //localStorage.removeItem('_t_');

    if(extern_url) {
      window.location.href = route;
    } else {
      this.router.navigate([route]).then(() => {
        window.location.reload();
        //hauria de cridar genericService.refreshStructure(2) pero generaria dependencia circular
      });
    }
  }

  // ------ Joan: Revisar token vs userId - Esta barrejat en l'aplicacio

  public getLoggedInUserId() {
    return this.userId;
  }

  public getTokenFromLS() {
    this.userId = parseInt(localStorage.getItem('_i_'), 10) || null;  // Si devuelve NaN pongo null
    this.user_token = localStorage.getItem('_itk_') || null;  // Si devuelve NaN pongo null
    this.expirationDate = parseInt(localStorage.getItem('_e_'), 10) || null;
  }

  public getAccount(id) {
     return new Promise(async (resolve, reject) => {
       let acc = this.accountsGeneric.filter(e => (e.id === id))[0];
       resolve(acc);
     });
  }

  public returnDefaultAccount() {
     return new Promise((resolve, reject) => {
       let acc;
       acc = this.accountsGeneric.filter(e => (e.active === true))[0];
       resolve(acc);
       /*if (!this.isOrphan) {
         acc = this.accounts.filter(e => (e.type === 'inmo'))[0];
         resolve(acc);
       }
       else {
         acc = this.accounts.filter(e => (e.type === 'group'))[0];
         resolve(acc);
       }*/
     })
  }

  public returnActiveAccountType() {
    return this.accountsGeneric.filter(e => (e.active === true))[0].type_account;
  }

  public returnActiveAccountId() {
    return this.accountsGeneric.filter(e => (e.active === true))[0].id;
  }

  public getAccountInfo() {
     return new Promise((resolve, reject) => {
       let acc = this.accountsGeneric.filter(e => (e.active === true))[0];
       // Filtro per typeAccount == 1 -> Marca
       resolve({inmoId: acc.type_account === 1 ? acc.idInmobiliaria : null, groupId: acc.type_account === 1 ? acc.id_grupo : null })
     });
  }

  public loadAdminArea() {
    // Joan: Nomes carregar si hi ha inmoId
    // Els usuaris orfes no tindran adminArea, pero no els hi cal perque no tenen activat el marketplace
    this.adminArea = null;
    if (this.inmoId) {
      this.endpointService.getAdminAreaByInmo(this.inmoId).subscribe(data => {
        if (data['errorCode'] === 0) {
          this.adminArea = data['response'][0];
        }
        return this.adminArea;
      })
    }
    else {
      return this.adminArea;
    }
  }

  public formateaFecha(fecha) {
    let timezone = '';
    this.adminArea.timezone < 0 ? timezone = '-' : timezone = '+';
    timezone += this.adminArea.timezone;
    timezone += '00';
    return formatDate(fecha, 'dd/MM/yyyy', this.adminArea.locale, timezone);
  }

// Joan: Revisar condicions de getIdCompanyType i getIdCompany
  public getIdCompanyType(generic = false): number {
    if (!generic) {
      if (this.inmoId !== null && this.espera == 0) {
        return 1;
      }
      else if (this.groupId !== null) {
        return this.groupType + 1;
      }
      else if(this.userId > 0){ //  this.isOrphan
        return 0;
      }
      return -1;
    } else {
      for (let i = 0; i < this.accountsGeneric.length; i++) {
        if(this.accountsGeneric[i].active) {
          return this.accountsGeneric[i].type_account;
        }
      }
      return -1; // TODO JOAN: Aquí si no té obrim el popup de SwapAccounts (this.openAccountMode();) sempre que hi hagi un id_user (sessió iniciada) (es podrá treure del login). Fer en una funció perque es crida a varis llocs
    }
  }

  // Devuelve el id de la empresa activa
  public getIdEmpresa(): number {
    for (let i = 0; i < this.accountsGeneric.length; i++) {
      if(this.accountsGeneric[i].active) {
        return this.accountsGeneric[i].idEmpresa;
      }
    }
    return -1;
  }

  public getIdCompany(generic = false): number {
    if (!generic) {
      if (this.inmoId !== null && this.espera == 0) {
        return this.inmoId;
      }
      else if (this.groupId !== null) {
        return this.groupId;
      }
      else { //  this.isOrphan
        return -1;
      }
    } else {
      for (let i = 0; i < this.accountsGeneric.length; i++) {
        if(this.accountsGeneric[i].active) return this.accountsGeneric[i].id;
      }
      return 0; // TODO JOAN: Aquí si no té obrim el popup de SwapAccounts sempre que hi hagi un id_user (sessió iniciada)  (es podrá treure del login). Fer en una funció perque es crida a varis llocs
    }
  }

  public newClient(i){
    this.clienteNew.emit(i);
  }

}

export class SubjectManager<T> {
  public Loaded = false;
  public Data: T;
  public Subject: Subject<any>;

  constructor() {
    this.Subject = new Subject<any>();
  }
}
