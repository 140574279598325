import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from 'app/services/auth/auth.service';
import { EndpointService } from 'app/services/endpoint/endpoint.service';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { FunctionsService } from 'app/services/functions/functions.service';
@Component({
  selector: 'app-paypal-custom',
  templateUrl: './paypal-custom.component.html',
  styleUrls: ['./paypal-custom.component.sass'],
})

export class PaypalCustomComponent implements OnInit {

  constructor(private endpointService: EndpointService,
    private authService: AuthService,
    public sanitizer: DomSanitizer,
    private functionsService: FunctionsService
  ) {}

  @Input() structure = null;
  @Input() index: number;

  public payPalConfig?: IPayPalConfig;
  public showSuccess: boolean = false;
  public finishLoad: boolean = false;

  private clientId = null;
  private payData = null;
  private urlOk = null;
  private urlKo = null;
  private pagat = null;
  
  ngOnInit(): void {
    this.pagat = false;
    let idPayment = this.functionsService.loadPaymentData(this.structure[this.index]);
    this.endpointService.payGeneric(idPayment, 2).subscribe((data) => {
      if(data['errorCode'] == 0) {
        this.clientId = data['response']['clientId'];
        this.payData = data['response']['paymentData'];
        this.urlOk = data['response']['urlOk'];
        this.urlKo = data['response']['urlKo'];

        this.payPalConfig = {
          currency: this.payData['name_paypal'],
          clientId: this.clientId,
          createOrderOnClient: (data) => <ICreateOrderRequest>{
            intent: 'CAPTURE', 
            purchase_units: [{
              items:[{
                name: 'Producto',
                quantity: '1',
                unit_amount: {
                  currency_code: this.payData['name_paypal'],
                  value: this.payData['amount']
                }
              }],
              amount: {
                currency_code: this.payData['name_paypal'],
                value: this.payData['amount'],
                breakdown: {
                  item_total: {
                      currency_code: this.payData['name_paypal'],
                      value: this.payData['amount']
                  }
                }
              },
            }]
          },      
          advanced: {
            commit: 'true'
          },
          style: {
            label: 'paypal',
            layout: 'vertical'
          },
          onApprove: (data, actions) => {
            return fetch("/my-server/capture-paypal-order", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                orderID: data.orderID
              })
            })
          },
          onClientAuthorization: (data) => {
            //console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
            this.showSuccess = true;
            // AQUÍ PONEMOS EL REDIRECCIONAMIENTO
            console.log('Vaig a aquesta URL:    ', this.urlOk);
            window.open(this.urlOk, "_self");
          },
          onCancel: (data, actions) => {
            console.log('OnCancel', data, actions);
            // AQUÍ PONEMOS EL REDIRECCIONAMIENTO
            window.open(this.urlKo, "_self");
            //Una vez en la otra página, se hace la actualización de paid y metodoDePago

            
          },
          onError: err => {
            console.log('OnError', err);
          },
          onClick: (data, actions) => {
            this.pagat = false;
            console.log('onClick', data, actions);
          },
        };
        this.finishLoad = true;
      }
    });
    if(this.pagat) {//enviar info de que ha pagado al backend
      console.log("Se ha realizado el pago");
    }
    else {//enviar info de que no ha pagado al backend
      console.log("Ha habido algun problema al realizar el pago");
    }
  }

}