export class EmailAction {

  public id: number;
  public email: number;
  public emailFrom: string;
  public emailName: string;
  public template: number;
  public emailType: number;
  public targetListId: number;
  public fieldEmailValue: string;
  public bindedFields: any;

  constructor() {
    this.clearBody();
  }

  public clearBody() {
    this.id = null;
    this.email = null;
    this.template = null;
    this.emailType = null;
    this.targetListId = null;
    this.fieldEmailValue = null;
    this.bindedFields = new Array();
    this.emailFrom = null;
    this.emailName = null;
  }

  public handleResponse(data: any) {
    this.id = data.core.id;
    this.email = data.core.id_email_remitente;
    this.template = data.core.plantilla;
    this.emailType = data.core.id_email_tipo;
    this.fieldEmailValue = data.core.valor_email_campo;
    this.targetListId = data.core.id_lista_destinatarios;
    this.bindedFields = data.binded_fields;
    this.emailFrom = data.core.email_from;
    this.emailName = data.core.email_from_name;

  }
}
