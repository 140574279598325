import {
  ComponentRef,
  ComponentFactoryResolver,
  Injectable,
  Inject,
  ReflectiveInjector
} from '@angular/core';
// import * as jsPlumb from 'jsplumb';
// import { jsPlumb } from 'jsplumb';
import { NodeComponent, Node } from '../../generic/components-custom/node/node.component';
import {ViewContainerRef} from '@angular/core';


@Injectable()
export class NodeService {

  public rootViewContainer: any;

  jsPlumbInstance: any;

  vcRef: any;

  constructor(private factoryResolver: ComponentFactoryResolver) {
    // jsPlumb.ready(function() {
    //   this.jsPlumbInstance = jsPlumb.getInstance(); 
    // })
   // this.jsPlumbInstance = jsPlumb.getInstance(); 
  }

  public setRootViewContainerRef(viewContainerRef) {
    console.log("viewContainerRef ", viewContainerRef);
    
    this.rootViewContainer = viewContainerRef;
  }

  public addDynamicNode(node: Node) {
    // const factory = this.factoryResolver.resolveComponentFactory(NodeComponent);
    // const component = factory.create(this.rootViewContainer.parentInjector);
    const component = this.vcRef.createComponent(NodeComponent);
    console.log("in NodeService.." , component );
    (<any>component.instance).node = node;
    (<any>component.instance).jsPlumbInstance = this.jsPlumbInstance;
    console.log("this.rootViewContainer ===>> ", this.rootViewContainer);
    
    this.rootViewContainer.insert(component.hostView);
  }
 
  addConnection(connection) {
    this.jsPlumbInstance.connect({ uuids: connection.uuids });
  }

  public clear() {
    this.rootViewContainer.clear();
  }
}