export class Strings {

	static LOGIN = {
		GO: "Acceder",
		REMEMBER_PASSWORD: "Recordar contraseña",
		USERNAME: "Nombre de usuario",
		EMAIL: "Correo electrónico",
		CODE: "Introduce el código recibido en el mail",
		NEW_PASS: "Introduce la nueva contraseña",
		REPEAT_PASS: "Repite la contraseña",
		SEND_MAIL: "Enviar mail de recuperación",
		CHECK_CODE: "Comprobar código",
		CHANGE_PASSWORD: "Cambiar contraseña",
		RECOVER_PASSWORD: "Recuperar contraseña",
		CHANGE_EMAIL: "Cambiar usuario"
	}

	static APP_C_STRINGS = {
		AGENDA: 'Agenda',
		INMO: 'Inmobiliarias',
		GROUP: 'Grupos',
		INMO_SEARCH: 'Ver inmobiliarias',
		ADD_INMO: 'Agregar inmobiliaria',
		ADD_INMO_GROUP: 'Registrar grupo',
		SAW_INMO_GROUP: 'Ver grupos',
		DEMO_INMO: 'Crear nueva demo',
		PROPERTIES: "Inmuebles",
		CLIENTS: "Clientes",
		LEADS: "Demandas",
		ADDCLIENT: "Añadir cliente",
		ADDFLAT: "Añadir inmueble",
		ADDOFFER: "Añadir oferta",
		CLIENTS_SEARCH: "Lista de clientes",
		PROPERTIES_SEARCH: "Lista de inmuebles",
		OFFERS_SEARCH: "Lista de ofertas",
		OFFERS: "Ofertas",
		AUTOMATIONS: "Automations",
		ADD_ACTIVITY: "Añadir actividad",
		ADD_CATEGORY: "Añadir categoría",
		CAMPAIGNS: "Campañas",
		REPORTS: "Reportes",
		PRIVILEGIES: "Privilegios",
		USERS: "Usuarios",
		LOCATION: "Ubicaciones",
		SETTINGS: "Configuración",
		LISTLEADS: "Demandas en curso",
		PENDINGLEADS: "Demandas pendientes",
		CLOSESESION: "Cerrar sesión",
		MODAL_CLOSE_SESSION_TITLE: '¿Estás seguro?',
		MODAL_CLOSE_SESSION_BODY: 'Todos los cambios que no hayas guardado se perderán.',
		MODAL_CLOSE_SESSION_YES: 'Salir',
		MODAL_CLOSE_SESSION_CANCEL: 'Cancelar',
		MAINTENANCE: "Mantenimiento",
		WORKFLOW: "Workflow",
		PROFILE: 'Perfil',
		OPTIONS: 'Opciones',
		MARKETPLACE: 'Marketplace',
		PORTALS: 'Portales'
	}

	static MULTISELECTORVALUES = {
		SEARCH: 'Buscar',
		CLEAN: 'Limpiar',
		SELECTED: 'Seleccionado',
		DESCRIPTION: 'Descripción',
		BARCODE: 'Código barras',
		PRICE: 'Precio',
		SELECT_ALL: 'Marcar todas',
		CHECKED_SINGULAR: 'seleccionado',
		CHECKED_PLURAL: 'seleccionados',
		SELECT: 'Selecciona'
	}

	static CALENDAR = {
		DELETE_ACTIVITY_DIALOG_MESSAGE: '¿Estás seguro que deseas eliminar este evento?',
		DELETE_ACTIVITY_PERIOD_MESSAGE: '¿Quieres borrar todos los eventos o solo este?', // Jan escribir como se quiera
		UPDATE_ACTIVITY_PERIOD_MESSAGE: '¿Quieres editar todos los eventos o solo este?', // Jan escribir como se quiera
		START_DATE_ERROR_MESSAGE: 'Debes indicar una fecha de inicio',
		END_DATE_ERROR_MESSAGE: 'La fecha de final debe ser posterior a la fecha de inicio',
		PERIOD_END_DATE_ERROR_MESSAGE: 'El fin de la periodiciad debe ser posterior a la fecha inicio',
		DELETE_CATEGORY_DIALOG_MESSAGE: '¿Estás seguro que deseas eliminar esta categoría?',
		COLOR_PICKER_NAME_ERROR_MESSAGE: 'Debes indicar un nombe',
		COLOR_PICKER_COLOR_ERROR_MESSAGE: 'El nombre ya existe',
		DELETE_GROUP_DIALOG_MESSAGE: '¿Estás seguro que deseas eliminar este grupo?'
	}

	static SEARCHFLATS = {
		STATUS: 'Estado',
		SUBSTATUS: 'Subestado',
		CAPTOR: 'Captador',
		INTERNALNAME: 'Nombre interno',
		REFERENCE: 'Referencia',
		SCORE: 'Score',
		OWNER: 'Propietario',
		TYPEOPERATION: 'Tipo operación',
		TYPEOP: 'Tipo op.',
		ADDRESS: 'Dirección',
		RENTSALE: 'Alquiler-Venta',
		POPULATION: 'Población',
		ZONE: 'Zona',
		HOUSINGTYPE: 'Tipo',
		SUBHOUSINGTYPE: 'Subtipo inmueble',
		//M2_BUILT: 'm² Construidos',
		M2_TOTALS: 'm² totales',
		M2: 'm²',
		ROOMS: 'Habitaciones',
		BATHROOMS: 'Baños',
		TERRACE: 'Terraza',
		PARKING: 'Parking',
		PROVINCE: 'Provincia',
		QUALIFICATION: 'Calificación',
		ELEVATOR: 'Ascensor',
		MINPRICE: 'Precio mín.',
		MAXPRICE: 'Precio máx.',
		SEARCH: 'Buscar',
		SEARCHFILTERS: 'Buscador',
		POOL: 'Piscina',
		AMOUNT_SELL: 'Importe venta',
		AMOUNT_RENT: 'Importe alquiler',
		PRICE: 'Precio',
		ENTRYDATE: 'Fecha alta',
		ACTIVE: 'Disp.',
		LAST_ACTIVITY: 'Última Actividad',
		CLEAR: 'Limpiar',
		NO: 'No',
		YES: 'Sí',
		ALL: 'Todos',
		DATEFROM: 'Fecha alta: Desde',
		DATETO: 'Hasta',
		RESULTS_LENGTH: 'resultado/s obtenido/s',
		CONTACT_SOURCE: 'Portales:',
		IMAGE: 'Imagen',
		CREATOR: 'Creador',
		OLD_REF: 'Ref. antiguas',
		NO_AGENT: 'Sin agente',
		NO_CAPTOR: 'Sin Captador asignado',
		HAVE_KEYS: 'Tenemos llaves',
		EXCLUSIVE: 'Exclusiva',
		FEATURED_WEB: 'Destacado web',
		BANNER: 'Banner',
		SEO: 'SEO',
		DATEPUBLICATION: 'Fecha publicacion',
		PUBLICATIONDATE: 'F. publ.',
		POSTER: 'Cartel'
	}

	static WORKFLOW = {
		EMAILTEMPLATESLIST: 'Plantillas Emails',
		PDFTEMPLATESLIST: 'Plantillas PDF',
		EMAILTEMPLATEDETAILS: 'Detalles de la plantilla',
		EVENTSLIST: 'Eventos',
		EVENTDETAILS: 'Configuracion del evento',
		CAMPAIGNSLIST: 'Campañas',
		WORKFLOWMANAGER: 'Administrador del workflow',
		DYNAMICREPORT: 'Plantillas'
	}

	static EVENTS = {
		NAME: 'Nombre',
		STATUS: 'Estado',
		CREATOR: 'Creador',
		MODIFICATION_DATE: 'Fecha de modificación',
		DISCHARGE_DATE: 'Fecha de alta',
		MODAL_CREATE: 'Create',
		MODAL_SAVE: 'Save',
		MODAL_CANCEL: 'Cancel',
		ELIMINAR_EVENTO_PREGUNTA: '¿Quieres eliminar para siempre este evento?',
		ELIMINAR_EVENTO_SI: 'Si',
		ELIMINAR_EVENTO_NO: 'No',
		TYPE: 'tipo'
	}

	static USERTRACKVIEWCODE = {
		CLIENTS_SEARCH: 'busqueda_clientes',
		INFO_CLIENTES: 'datos_generales',
		INTERESES_ALERTAS: 'intereses_alertas',
		MATCHING_CLIENTE: 'matching_cliente',
		PROPIEDADES_CLIENTE: 'propiedades_cliente',
		ACTIVIDADES_CLIENTE: 'actividades_cliente',
		DOCS_CLIENTE: 'docs_cliente',
		OFERTAS_CLIENTE: 'ofertas_cliente',
		PROPERTIES_SEARCH: 'busqueda_inmuebles',
		INFORME_PROPIEDAD: 'informe_inmueble',
		ACTIVIDAD_PROPIEDAD: 'actividad_inmueble',
		PROPIETARIO_PROPIEDAD: 'propietario_inmueble',
		MATCHING_PROPIEDAD: 'matching_inmueble',
		OFERTAS_PROPIEDAD: 'ofertas_inmueble',
		MARKETING_PROPIEDAD: 'marketing_inmueble',
		DOCS_PROPIEDAD: 'docs_inmueble',
		OFFERS_SEARCH: 'busqueda_ofertas',
		LANDING_SEARCH: 'landing_search',
		KNOWLEDGE_SEARCH: 'knowledge_search',
		COMMUNITY_SEARCH: 'community_search',
		MARKETPLACE_SEARCH: 'marketplace_search',
		AGENDA: 'agenda'
	}

	static INMO = {
	NAME_INMO: 'Nombre de la inmobiliaria',
	EMAIL: 'Correo',
	TELEPHONE: 'Teléfono',
	ADDRESS: 'Dirección',
	MPOST: 'Código postal',
	LOCALIZATION: 'Población',
	PROVINCE: 'Provincia',
	COUNTRY: 'País',
	ZONE: 'Zona',
	ADMIN_INMO_NAME: 'Nombre',
	ADMIN_INMO_SURNAME: 'Apellido',
	GROUP: '¿Forma parte de un grupo?',
	RESPONSABLE_NAME: 'Nombre responsable',
	RESPONSABLE_SURNAME: 'Apellido responsable',
	GROPU_NAME: 'Nombre grupo',
	FACEBOOK: 'Link Facebook',
	TWITTER: 'Link Twitter',
	LINKEDIN: 'Link Linkedin',
	INSTAGRAM: 'Link Instagram',
	YOUTUBE: 'Link Youtube',
	ASSOCIATION: 'Asociación',
	WORKER_NUMBER: 'Número de trabajadores',
	ACTUAL_SOFT: 'Otro software actual',
	ACTUAL_PORTALS: 'Portales actuales',
	INMO_TYPE: 'Tipo de inmobiliaria',
	EMAILING: '¿Quiere recibir información a su correo?',
	CONTRACT: '¿Contrato?',
	SEASON_EMAIL: 'Correo campaña',
	RENT_PREFIX: 'Prefijo alquiler',
	SALE_PREFIX: 'Prefijo venta',
	RENT_SUFFIX: 'Sufijo alquiler',
	SALE_SUFFIX: 'Sufijo venta',
	USER_INMO: 'Asignar usuario movin',
	WEB: 'Link web',
	GROUP_TYPE: 'Tipo grupo',
	MODULES: 'Modulo',
	INMO_INFO: 'DATOS GENERALES',
	INMO_EMAIL: 'CONFIGURACIÓN EMAIL',
	LOGO: 'MULTIMEDIA',
	OTHER_USERS: 'USUARIOS',
	PROFILES: 'PERFILES DE USUARIOS',
	CIF: 'CIF',
	DIR_BILL: 'Dirección para la facturación',
	BUSINESS: 'Nombre empresa',
	CODE_BANK: 'Cuenta bancaria',
	CODE_COLEGIADO: 'Código colegiado',
	CODE_CREDIT: 'Número de tarjeta',
	CODE_SECURITY: 'Código de seguridad',
	SOFTWARE: 'Software actual',
	TITULAR: 'Titular',
	IBAN: 'IBAN',
	BIC: 'BIC',
	MOVINS: 'Movins'
	}
}
