<ng-container *ngIf="this.loaded && structure[this.index]['items'] && structure[this.index]['items'].length > 0">
    <p-carousel *ngIf="!functionsService.downloadingStaticPage" [value]="structure[this.index]['items']"
        [numVisible]="structure[this.index]['carousel_num_visible']"
        [numScroll]="structure[this.index]['carousel_num_scroll']"
        [circular]="this.structure[this.index]['carousel_circular'] == 1" [page]="this.page"
        (click)="genericService.stopPropagation($event)" [responsiveOptions]="responsiveOptions"
        (onPage)="setPage($event, structure[this.index]['carousel_num_scroll'], structure[this.index]['carousel_num_visible'], structure[this.index]['carousel_num_scroll_s'], structure[this.index]['carousel_num_visible_s'], structure[this.index]['carousel_num_scroll_xs'], structure[this.index]['carousel_num_visible_xs'])"
        [ngClass]="{'show-buttons': structure[this.index]['show_buttons_carousel']}">
        <ng-template let-product pTemplate="item">
            <div class="product-item" *ngIf="product.orden <= maxElement && product.orden >= minElement">
                <div class="product-item-content" [id]="product['id_functional_area']"
                    [ngClass]="product['class_custom']" [ngStyle]="product?.['styleParsed']?.['style']">
                    <div class="p-mb-3" *ngIf="product.image" style="height: 100%;">
                        <img [ngClass]="{'no-click-img': !product['id_function']}" (click)="onClickEvent(product)"
                            [ngStyle]="{'max-height': 'calc((10vh + 35vw)/' + this.heightIMG + ')'}"
                            [src]="product.image" (error)="product.image = this.genericService.defaultNoImage">
                    </div>
                    <div>
                        <h4 class="p-mb-1" *ngIf="product.title" [innerHTML]="product.title | safeHtml"></h4>
                        <h6 class="p-mt-0 p-mb-3" *ngIf="product.description"
                            [innerHTML]="product.description | safeHtml"></h6>
                        <span [class]="'product-badge status-1'" *ngIf="product.text"
                            [innerHTML]="product.text | safeHtml"></span>
                        <ng-container *ngIf="product.element['child']">
                            <app-generic [structure]="product.element"></app-generic>
                        </ng-container>
                        <!--<div class="car-buttons p-mt-5">
                            <p-button type="button" styleClass="p-button p-button-rounded p-mr-2" icon="pi pi-search"></p-button>
                            <p-button type="button" styleClass="p-button-success p-button-rounded p-mr-2" icon="pi pi-star"></p-button>
                            <p-button type="button" styleClass="p-button-help p-button-rounded" icon="pi pi-cog"></p-button>
                        </div>-->
                    </div>
                </div>
            </div>
        </ng-template>
    </p-carousel>
    <div *ngIf="functionsService.downloadingStaticPage">
        <ng-container *ngFor="let e of structure[this.index]['child'] | keyvalue : genericService.returnZero">
            <ng-container *ngIf="structure[this.index]['child'][e.key]">
                <div class="product-item" style="padding: 10px;">
                    <div class="product-item-content" [ngClass]="structure[this.index]['child'][e.key]['class_custom']"
                        [ngStyle]="structure[this.index]['child'][e.key]?.['styleParsed']?.['style']">
                        <div class="p-mb-3" *ngIf="structure[this.index]['child'][e.key]['url']" style="height: 100%;">
                            <img [ngStyle]="{'max-height': 'calc((10vh + 35vw)/' + this.heightIMG + ')'}"
                                [src]="structure[this.index]['child'][e.key]['url']"
                                (error)="structure[this.index]['child'][e.key]['url'] = this.genericService.defaultNoImage">
                        </div>
                        <div>
                            <h4 class="p-mb-1" *ngIf="structure[this.index]['label']"
                                [innerHTML]="structure[this.index]['label'] | safeHtml"></h4>
                            <h6 class="p-mt-0 p-mb-3" *ngIf="structure[this.index]['description']"
                                [innerHTML]="structure[this.index]['description'] | safeHtml"></h6>
                            <span [class]="'product-badge status-1'" *ngIf="structure[this.index]['text']"
                                [innerHTML]="structure[this.index]['text'] | safeHtml"></span>
                            <ng-container *ngIf="structure[this.index]['child'][e.key]['child']">
                                <app-generic [structure]="structure[this.index]['child'][e.key]"></app-generic>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</ng-container>