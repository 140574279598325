<ng-container *ngIf="structure[index] && structure[index]['child'] && loaded">
  <ng-container *ngIf="structure[index] && (structure[this.index]['type'] === 'vertical-linear' || structure[this.index]['type'] === 'vertical')">
    <mat-vertical-stepper #stepper [selectedIndex]="structure[index]['selectedIndex']" (selectionChange)="stepperChange($event)">
      <ng-container *ngFor="let e of structure[index]['child'] | keyvalue : genericService.returnZero">
        <mat-step [stepControl]="structure[index]['child'][e.key]['formStep']"
        *ngIf="structure[index]['child'][e.key] && structure[index]['child'][e.key]['id_functional_type'] == 95 && genericService.evaluateIfElements(structure[index], e.key) && structure[index]['child'][e.key]['id_functional_status_general'] !== 2"

        [fxFlex]="structure[index]['child'][e.key]['width_general']"
        [fxFlex.xs]="structure[index]['child'][e.key]['width_xs'] != 0 ? structure[index]['child'][e.key]['width_xs'] : structure[index]['child'][e.key]['width_general']"
        [fxFlex.sm]="structure[index]['child'][e.key]['width_s'] != 0 ? structure[index]['child'][e.key]['width_s'] : structure[index]['child'][e.key]['width_general']"
        [fxFlex.md]="structure[index]['child'][e.key]['width_m'] != 0 ? structure[index]['child'][e.key]['width_m'] : structure[index]['child'][e.key]['width_general']"
        [fxFlex.lg]="structure[index]['child'][e.key]['width_l'] != 0 ? structure[index]['child'][e.key]['width_l'] : structure[index]['child'][e.key]['width_general']"
        [fxFlex.xl]="structure[index]['child'][e.key]['width_xl'] != 0 ? structure[index]['child'][e.key]['width_xl'] : structure[index]['child'][e.key]['width_general']"

        [fxHide]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || structure[index]['child'][e.key]['hide'] == 1"
        [fxHide.xs]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_xs']=='true' && structure[index]['child'][e.key]['hide_xs'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_xs'] == 1"
        [fxHide.sm]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_s']=='true' && structure[index]['child'][e.key]['hide_s'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_s'] == 1"
        [fxHide.md]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_m']=='true' && structure[index]['child'][e.key]['hide_m'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_m'] == 1"
        [fxHide.lg]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_l']=='true' && structure[index]['child'][e.key]['hide_l'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_l'] == 1"
        [fxHide.xl]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_xl']=='true' && structure[index]['child'][e.key]['hide_xl'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_xl'] == 1"

        [ngClass]="structure[index]['child'][e.key]['id_functional_status_mkt'] != 1 ? structure[index]['child'][e.key]['class_custom']+ ' ' + 'mkt-no-available' : structure[index]['child'][e.key]['class_custom']"
        [ngStyle]="structure[index]['child'][e.key]['styleParsed']['style']"
        [matTooltip]="structure[index]['child'][e.key]['tooltip']"
        [id]="structure[index]['child'][e.key]['id_functional_area']">
          <ng-template matStepLabel>
            {{ structure[index]['child'][e.key]['label'] }}
          </ng-template>
          <ng-container *ngIf="(structure[index]['child'][e.key]['child'] || structure[index]['creatingMode']) && this.structure[index]['child'][e.key]['stepActiveNow']">
            <app-generic [structure]="structure[index]['child'][e.key]"></app-generic>
          </ng-container>
        </mat-step>
      </ng-container>
    </mat-vertical-stepper>
  </ng-container>

  <ng-container *ngIf="structure[index] && (structure[this.index]['type'] === 'horizontal' || structure[this.index]['type'] === 'horizontal-linear')">
    <mat-horizontal-stepper #stepper [selectedIndex]="structure[index]['selectedIndex']" (selectionChange)="stepperChange($event)">
      <ng-container *ngFor="let e of structure[index]['child'] | keyvalue : genericService.returnZero">
        <mat-step [stepControl]="structure[index]['child'][e.key]['formStep']"
        *ngIf="structure[index]['child'][e.key] && structure[index]['child'][e.key]['id_functional_type'] == 95 && genericService.evaluateIfElements(structure[index], e.key) && structure[index]['child'][e.key]['id_functional_status_general'] !== 2"

        [fxFlex]="structure[index]['child'][e.key]['width_general']"
        [fxFlex.xs]="structure[index]['child'][e.key]['width_xs'] != 0 ? structure[index]['child'][e.key]['width_xs'] : structure[index]['child'][e.key]['width_general']"
        [fxFlex.sm]="structure[index]['child'][e.key]['width_s'] != 0 ? structure[index]['child'][e.key]['width_s'] : structure[index]['child'][e.key]['width_general']"
        [fxFlex.md]="structure[index]['child'][e.key]['width_m'] != 0 ? structure[index]['child'][e.key]['width_m'] : structure[index]['child'][e.key]['width_general']"
        [fxFlex.lg]="structure[index]['child'][e.key]['width_l'] != 0 ? structure[index]['child'][e.key]['width_l'] : structure[index]['child'][e.key]['width_general']"
        [fxFlex.xl]="structure[index]['child'][e.key]['width_xl'] != 0 ? structure[index]['child'][e.key]['width_xl'] : structure[index]['child'][e.key]['width_general']"

        [fxHide]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || structure[index]['child'][e.key]['hide'] == 1"
        [fxHide.xs]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_xs']=='true' && structure[index]['child'][e.key]['hide_xs'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_xs'] == 1"
        [fxHide.sm]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_s']=='true' && structure[index]['child'][e.key]['hide_s'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_s'] == 1"
        [fxHide.md]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_m']=='true' && structure[index]['child'][e.key]['hide_m'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_m'] == 1"
        [fxHide.lg]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_l']=='true' && structure[index]['child'][e.key]['hide_l'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_l'] == 1"
        [fxHide.xl]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_xl']=='true' && structure[index]['child'][e.key]['hide_xl'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_xl'] == 1"

        [ngClass]="structure[index]['child'][e.key]['id_functional_status_mkt'] != 1 ? structure[index]['child'][e.key]['class_custom']+ ' ' + 'mkt-no-available' : structure[index]['child'][e.key]['class_custom']"
        [ngStyle]="structure[index]['child'][e.key]['styleParsed']['style']"
        [matTooltip]="structure[index]['child'][e.key]['tooltip']"
        [id]="structure[index]['child'][e.key]['id_functional_area']">
          <ng-template matStepLabel>
            {{ structure[index]['child'][e.key]['label'] }}
          </ng-template>
          <ng-container *ngIf="(structure[index]['child'][e.key]['child'] || structure[index]['creatingMode']) && this.structure[index]['child'][e.key]['stepActiveNow']">
            <app-generic [structure]="structure[index]['child'][e.key]"></app-generic>
          </ng-container>
        </mat-step>
      </ng-container>
    </mat-horizontal-stepper>
  </ng-container>
</ng-container>
