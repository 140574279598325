<mat-form-field appearance="outline">
    <mat-label>Show options</mat-label>
    <mat-select [(ngModel)]="showOptions" (selectionChange)="changeShowOptions()">
        <mat-option value="1">Only Primary Keys</mat-option>
        <mat-option value="2">Only Primary Keys and Foreign Keys</mat-option>
        <mat-option value="3">All fields</mat-option>
    </mat-select>
</mat-form-field>
<div class="parent-mermaid" *ngIf="loaded">
    <div id="mermaid-diagram" [innerHtml]="mermaidDefinition"></div>
</div>