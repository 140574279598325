import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {EndpointService} from "../../services/endpoint/endpoint.service";
import {AuthService} from "../../services/auth/auth.service";
import {DomSanitizer} from "@angular/platform-browser";
import {MatDialog} from "@angular/material/dialog";
import {Values} from "../../../values/values";
import {SideNavService} from "../../utils/SideNavService";
import {MarketplaceGeneralComponent} from "../marketplace-stepper/marketplace-general/marketplace-general.component";
import {BajaDialogComponent} from "../../marketplace/baja-dialog/baja-dialog.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AdviceReportService} from "../../services/advice-report/advice-report.service";
import {GenericService} from "../../services/generic/generic.service";

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  //@ViewChild('HeaderComponent') public HeaderComponent: HeaderComponent;
  @Input() type: number;
  //@Input() data: any;
  public data: any;
  public dataLoaded: boolean;

  public numberVisible: number;

  public tipos = Values.KNOWLEDGE_TYPES;
  public knowledge_index = -1;

  public dataPack: any;
  public showPack = false;
  public showMisModulos = true;
  public videoUrl;
  public movinsComprados: boolean;
  public egoCanLoad: boolean;
  public totalModulos: number;

  // Opciones para el carousel. Lo ponemos en variables, por si las queremos modificar segun el 'type'.
  public numVisible = 5;
  public numScroll = 1;
  public circular = false;
  public page = 0;
  public responsiveOptions = [];
  public responsiveOptions2 = [];

  constructor(
    private endpointService: EndpointService,
    public adviceReportService: AdviceReportService,
    private authService: AuthService,
    public sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private sidenavService: SideNavService,
    public snackBar: MatSnackBar,
    public genericService: GenericService
  ) {
    if (this.type === 10) {
      this.responsiveOptions = [
        {breakpoint: '1250px', numVisible: 6, numScroll: 1},
        {breakpoint: '1024px', numVisible: 3, numScroll: 1},
        {breakpoint: '960px', numVisible: 2, numScroll: 1},
        {breakpoint: '600px', numVisible: 1, numScroll: 1}
      ];
    } else {
      this.responsiveOptions = [
          { breakpoint: '1250px', numVisible: 5, numScroll: 1 },
          { breakpoint: '1280px', numVisible: 3, numScroll: 1 },
          { breakpoint: '960px',  numVisible: 2, numScroll: 1 },
          { breakpoint: '600px',  numVisible: 1, numScroll: 1 }
        ];
    }
  }

  ngOnInit(): void {
    this.localStorageListener();
    this.initialize();
  }

  public initialize(): void {
    this.numberVisible = 5;
    this.dataLoaded = false;
    // Según el 'type' los datos se obtienen de una forma u otra, y  el  carousel se comportara diferente.
    switch (this.type) {
      case 1: // marketplace -> mis modulos
        /*this.endpointService.getModulosInmo(this.authService.inmoId).subscribe(data => {
          if (data['errorCode'] === 0) {
            this.data = data['response'];
            this.dataLoaded = true;
          }
        });*/
        this.endpointService.getProductsOfCompany(this.authService.inmoId, this.authService.getIdCompanyType(), this.authService.languageId).subscribe(data => {
          if (data['errorCode'] === 0) {
            this.data = data['response'];

            this.dataLoaded = true;
            if (this.data[0] && this.data[0].id === -1) this.showMisModulos = false;

            this.calculaTotal(this.data);
            this.dateFormat(this.data);
          }
        });
        break;
      case 2: // marketplace -> todos los modulos
        /*this.endpointService.getModulosOfertas(this.authService.inmoId).subscribe(data => {
          if (data['errorCode'] === 0) {
            this.data = data['response'];
            this.dataLoaded = true;
          }
        });*/
        this.endpointService.getAvailableProducts(this.authService.inmoId,this.authService.getIdCompanyType(), this.authService.languageId, this.authService.adminArea.id_admin_area).subscribe(data => {
          if (data['errorCode'] === 0) {
            this.data = data['response'];
            this.dataLoaded = true;
            this.calculaTotal(this.data);
          }
        });

        this.endpointService.getMovinsAdquiridos(this.authService.inmoId, this.authService.getIdCompanyType()).subscribe(data => {
          data['errorCode'] === 0 && Array.isArray(data['response']) && data['response'].length ? this.movinsComprados = true : this.movinsComprados = false;
        });
        break;
      case 3: // marketplace -> packs
        this.endpointService.getPacks().subscribe(data => {
          if (data['errorCode'] === 0) {
            this.data = data['response'];
            this.dataLoaded = true;
          }
        });
        break;
      case 4: // knowledgeplace -> news area // notas de prensa
        this.endpointService.getNewsArea().subscribe(data => {
          if (data['errorCode'] === 0) {
            this.data = data['response'];
            this.processImagesList(this.data);
            this.numVisible = 5;
            this.dataLoaded = true;
          }
        });
        break;
      case 5: // knowledgeplace -> cinema // videoteca
        this.endpointService.getCinema().subscribe(data => {
          if (data['errorCode'] === 0) {
            this.data = data['response'];
            this.processImagesList(this.data);
            this.dataLoaded = true;
          }
        });
        break;
      case 6: // knowledgeplace -> library // libros
        this.endpointService.getLibrary().subscribe(data => {
          if (data['errorCode'] === 0) {
            this.data = data['response'];
            this.numVisible = 8;
            this.responsiveOptions = [
              { breakpoint: '1250px', numVisible: 6, numScroll: 1 },
              { breakpoint: '1024px', numVisible: 4, numScroll: 1 },
              { breakpoint: '960px',  numVisible: 3, numScroll: 1 },
              { breakpoint: '600px',  numVisible: 2, numScroll: 1 }
            ];
            //console.log('****************************************');
            //console.log(this.data);
            this.processImagesList(this.data);
            //console.log('----------------------------------------');
            //console.log(this.data);
            //console.log('****************************************');
            this.dataLoaded = true;
          }
        });
        break;
      case 7: // knowledgeplace -> ReportSpace // informes
        this.endpointService.getReportSpace().subscribe(data => {
          if (data['errorCode'] === 0) {
            this.data = data['response'];
            this.processImagesList(this.data);
            this.dataLoaded = true;
          }
        });
        break;
      case 8: // knowledgeplace -> academy // cursos
        this.endpointService.getAcademy().subscribe(data => {
          if (data['errorCode'] === 0) {
            this.data = data['response'];
            this.processImagesList(this.data);
            this.dataLoaded = true;
          }
        });
        break;
      case 9: // knowledgeplace -> PlaySpace
        this.endpointService.getPlaySpace().subscribe(data => {
          if (data['errorCode'] === 0) {
            this.data = data['response'];
            this.processImagesList(this.data);
            this.dataLoaded = true;
          }
        });
        break;
      case 10: // landing -> knowledge
        this.endpointService.getKnowledgeInfo().subscribe(data => {
          if (data['errorCode'] === 0) {
            this.data = [];
            let i = 0;
            Object.keys(data['response']).forEach(row => {
              const obj = data['response'][row];
              obj['index'] = i;
              this.data.push(obj);
              i += 1;
            });
            this.numVisible = 6;
            this.responsiveOptions = [
              {breakpoint: '1250px', numVisible: 6, numScroll: 1},
              {breakpoint: '1024px', numVisible: 3, numScroll: 1},
              {breakpoint: '960px', numVisible: 2, numScroll: 1},
              {breakpoint: '600px', numVisible: 1, numScroll: 1}
            ];
            this.responsiveOptions2 = [
              { breakpoint: '1250px', numVisible: 6, numScroll: 1 },
              { breakpoint: '1024px', numVisible: 4, numScroll: 1 },
              { breakpoint: '960px',  numVisible: 3, numScroll: 1 },
              { breakpoint: '600px',  numVisible: 2, numScroll: 1 }
            ];
            this.dataLoaded = true;
          }
        });
        break;
      case 11: // knowledgeplace -> Transformacion
        this.endpointService.getTransformacion().subscribe(data => {
          if (data['errorCode'] === 0) {
            this.data = data['response'];
            this.processImagesList(this.data);
            this.dataLoaded = true;
          }
        });
        break;

      case 12: // Marketplace -> PROXIMAMENTE
        this.endpointService.getComingSoonProducts(this.authService.inmoId,this.authService.getIdCompanyType(), this.authService.languageId, 1).subscribe(data => {
          if (data['errorCode'] === 0) {
            this.data = data['response'];
            this.dataLoaded = true;
          }
        });
        break;
    }
  }

  public localStorageListener() {
    if (this.type === 2) {

      let originalSetItem = localStorage.setItem;
      localStorage.setItem = function(key, value) {

        let event = new CustomEvent("itemInserted", {
          detail: {
            key: key,
            value: value
          }
        });

        document.dispatchEvent(event);
        originalSetItem.apply(this, arguments);
      };

      let func = (e) => {
          this.openMarketProduct(e.detail.key, e.detail.value);
      }

      document.addEventListener("itemInserted", func, false);
    }
  }

  public openMarketProduct(key, value) {
    if (key === "market") {
      let modulo = this.data.find(obj => obj.id_product == value);

      if (modulo) {
        this.openCheckout(modulo);
      } else {
        this.snackBar.open('El producto ya está comprado', 'X', {
          duration: 6000, panelClass: ['red-green']
        });
      }
    }
    localStorage.removeItem("market");
  }

  public displayPack(id: any): void {
    if (this.showPack === true) {
      this.showPack = false;
    } else {
      this.endpointService.getModulosPacks(id).subscribe(data => {
        if (data['errorCode'] === 0) {
          this.dataPack = data['response'];
          this.showPack = true;
        }
      });
    }
  }

  public showCarousel(i: number): void {
    this.knowledge_index = this.knowledge_index !== i ? i : -1;
    console.log(i);
    if (i === 2) this.numberVisible = 8;
    else this.numberVisible = 5;
  }

  public openCheckout(modulo: any): void {
    this.endpointService.getMovinsInmo(this.authService.inmoId, this.authService.getIdCompanyType()).subscribe(data => {
      // Abrimos el dialog de checkout (stepper con 3 componentes: resumen+dependencias, configuración, checkout (o compra-movins)
      const dialogRef = this.dialog.open(MarketplaceGeneralComponent, {
        data: { moduloInfo: modulo, movins: data['response'][0].total_movins }
      });

      dialogRef.afterClosed().subscribe(data => {
        if (data === null || (data && data[0] === true)) {
          // Al terminar la compra de un módulo
          this.genericService.refreshStructure(2);
        } else {
          if (data && data[1] !== null) {
            this.openCheckout(data[1]);
          }
        }
      });
    });
  }

  public openWidgets(modulo: any): void {
    // Miramos si puede comprar más widgets
    let modulosLista = [];
    modulosLista.push(modulo.id_product);

    // Primero recuperamos las dependencias de el modulo seleccionado
    this.endpointService.getDependenciesOfProduct(modulo.id_product, this.authService.inmoId, this.authService.getIdCompanyType(), this.authService.languageId, this.authService.adminArea.id_admin_area).subscribe(data => {
      if (data['errorCode'] === 0) {
        (data['response']).forEach((elem, index) => {
          modulosLista.push(elem.id_product);
        });
      }
    });

    this.endpointService.getWidgetsByList(modulosLista, this.authService.getIdCompany(), this.authService.getIdCompanyType(), this.authService.languageId, this.authService.adminArea.id_admin_area).subscribe(data => {
      if (data['errorCode'] === 0) {
        let widgets = data['response'];
        let widgetsActuales = this.data.filter(m => m.id_product_type === 2);

        Object.entries(widgets).forEach(([key, value]) => {
          widgets[key] = widgets[key].filter(({ id_product: id1 }) => !widgetsActuales.some(({ id_product: id2 }) => id2 === id1));
        });

        // @ts-ignore
        if (Object.entries(widgets)[0] && Object.entries(widgets)[0][1].length) {
          // Abre dialog widgets
          this.endpointService.getMovinsInmo(this.authService.inmoId, this.authService.getIdCompanyType()).subscribe(data => {
            const dialogRef = this.dialog.open(MarketplaceGeneralComponent, {
              data: { moduloInfo: modulo, movins: data['response'][0].total_movins, verWidgets: true, misModulos: this.data }
            });

            dialogRef.afterClosed().subscribe(data => {
              if (data === null || data[0] === true) { this.genericService.refreshStructure(2); }
            });
          });
        } else {
          this.snackBar.open('Ya tienes todos los widgets de este módulo', 'X', {  duration: 4000, panelClass: ['green-snackbar']  });
        }
      }
    });



  }

  public processImagesList(data: any) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].id !== -1) {
        data[i].imagen = this.genericService.processImage(data[i]);
      }
    }
  }

  public openVideoDialog(card: any, templateRef: TemplateRef<any>): void {
    this.videoUrl = this.getVideoIframe(card.video);
    this.dialog.open(templateRef);
  }

  public getVideoIframe(url) {
    let video, results;

    if (url === null) return '';
    results = url.match('[\\?&]v=([^&#]*)');
    video   = (results === null) ? url : results[1];

    return this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + video);
  }

  public updateProductSubscription(card: any, estate: boolean) {
    // Dar de baja
    if (!estate) {
      this.endpointService.getDependentsOfProduct(card.id_product, this.authService.languageId).subscribe(data => {
        if (data['errorCode'] === 0) {

          let productosActuales = this.data;
          let productosAfectados = data['response'];
          let intersection = productosActuales.filter(item1 => productosAfectados.some(item2 => item1.id_product === item2.id_product))

          const dialogRef = this.dialog.open(BajaDialogComponent, {
            width: 'auto',
            data: { modulo:card, productos: intersection },
            autoFocus: true
          });
          dialogRef.afterClosed().subscribe(result => {
            // Si se acepta el dialog
            if (result) {
              intersection.push(card);

              this.endpointService.updateProductsEstate(intersection, estate).subscribe(data => {
                if (data['errorCode'] === 0) {
                  intersection.forEach( p => {
                    this.data.find(x => x.id_product === p.id_product).estate = estate;
                  });

                  this.snackBar.open('Se ha cancelado la renovación de ' + data['response'] + ' producto/s', 'X', {
                    duration: 8000,
                    panelClass: ['red-snackbar']
                  });
                } else {
                  this.snackBar.open('Se ha producido un error', 'X', {
                    duration: 8000,
                    panelClass: ['red-snackbar']
                  });
                }
              });
            }
          });

        }
      });

    // Renovar
    } else {
      this.endpointService.getDependenciesOfProduct(card.id_product,this.authService.getIdCompany(), this.authService.getIdCompanyType() ,this.authService.languageId, this.authService.adminArea.id_admin_area).subscribe(data => {
        if (data['errorCode'] === 0) {
          let productosActuales = this.data;
          let productosAfectados = data['response'];
          let intersection = productosActuales.filter(item1 => productosAfectados.some(item2 => item1.id_product === item2.id_product))
          intersection.push(card);

          this.endpointService.updateProductsEstate(intersection, estate).subscribe(data => {
            if (data['errorCode'] === 0) {
              intersection.forEach( p => {
                this.data.find(x => x.id_product === p.id_product).estate = estate;
              });

              this.snackBar.open('Se han renovado ' + data['response'] + ' producto/s', 'X', { duration: 6000, panelClass: ['green-snackbar'] });
            } else {
              this.snackBar.open('Se ha producido un error', 'X', { duration: 8000, panelClass: ['red-snackbar'] });
            }
          });
        }
      });
    }

  }

  dateFormat(products: any) {
    products.forEach(product => {
      product.fecha_desactivacion = this.authService.formateaFecha(product.fecha_desactivacion);
    });
  }

  calculaTotal(data: any) {
    this.totalModulos = 0;
    data.forEach(d => {
      if (d.precio)
        this.totalModulos += parseFloat(d.precio);
      else if (d.precio_compra)
        this.totalModulos += parseFloat(d.precio_compra);
    });
  }
}
