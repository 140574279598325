import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface DialogData {
  numElementos: number;
  valor: boolean;
}

@Component({
  selector: 'app-portal-warning-dialog',
  templateUrl: './portal-warning-dialog.component.html',
  styleUrls: ['./portal-warning-dialog.component.css']
})
export class PortalWarningDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PortalWarningDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit(): void {
  }

  onCancel() {
    this.dialogRef.close();
  }

  onAceptar() {
    this.dialogRef.close();
  }
}
