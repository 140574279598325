import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AutoAdviceHomeComponent} from "../auto-advice-home/auto-advice-home.component";

@Component({
  selector: 'app-auto-components',
  templateUrl: './auto-components.component.html',
  styleUrls: ['./auto-components.component.css']
})
export class AutoComponentsComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AutoAdviceHomeComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void { }

}
