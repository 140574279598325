<div>

  <mat-toolbar color="primary">
    <h1 style="text-align: center;">
      Elige una imagen
    </h1>
  </mat-toolbar>

  <div>
    <div style="display: flex; flex-flow: row wrap; overflow-y: scroll; height: 60vh;">
      <div style="position: relative; width: 17%; margin-right: 3%;">
        <mat-card matRipple style="height: 94%; margin-bottom: 3%; margin-top: 3%">
          <div (click)="addImage()">
            <i style="margin: calc(50% - 15px); font-size: 30px; color: #42678f;" class="material-icons" matTooltip="Subir imagen">add_circle_outline</i>
            <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" accept="image/*" style="display:none;" />
          </div>
        </mat-card>
      </div>

      <div *ngIf="images.length <= 0">
        No hay imágenes subidas
      </div>

      <div *ngFor="let image of images; let index = index" style="position: relative; width: 17%; margin-right: 3%;">

        <mat-card style="height: 94%; margin-bottom: 3%; margin-top: 3%">
            <mat-card-actions>
              <mat-checkbox [(ngModel)]="imagesAux[index]" style="margin-right: 32%;" (change)="onlyOne(index)" >Mostrar</mat-checkbox>
              <i class="material-icons" (click)="deleteImage(image.src,index);">delete</i>
            </mat-card-actions>
            <img mat-card-image style="vertical-align: top" [src]="image.src" width="100%" height="auto">
          </mat-card>
      </div>
    </div>
  </div>

  <li *ngFor="let file of files">
    <div>{{file.data.name}}</div>
    <mat-progress-bar [value]="file.progress"></mat-progress-bar>
    <span id="file-label"></span>
  </li>

  <div class="dialog-footer-2">
    <!--button (click)="addImage()"><mat-icon>file_upload</mat-icon>{{uploadImageButtonText}}</button-->
    <button (click)="closeBottomSheet(1)">{{acceptButtonText}}</button>
    <button (click)="closeBottomSheet(2)">{{declineButtonText}}</button>
  </div>

</div>
