import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

import { MarketplaceFunctions } from '../../services/functions/functions.marketplace.service'


export interface DialogData {
  modulo: any;
  productos: any;
}

@Component({
  selector: 'app-baja-dialog',
  templateUrl: './baja-dialog.component.html',
  styleUrls: ['./baja-dialog.component.css']
})
export class BajaDialogComponent {

  constructor(public dialogRef: MatDialogRef<BajaDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData,
              public marketplaceFunctionsService: MarketplaceFunctions) {}

  onCancel() {
    this.marketplaceFunctionsService.getFunction(619,{that: this});
  }

  onAceptar() {
    this.marketplaceFunctionsService.getFunction(620,{that: this});
  }

}
