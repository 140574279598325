import {MessageChat} from "./message-chat";
import {UserChat} from "./user-chat";


export class RoomChat {
  id: string;
  roomTypeId: number;
  date: any;
  users: UserChat[];
  messages: MessageChat[];
  newmessages: MessageChat[];
  auxMessages: MessageChat[];
  usersMap: Map<string, UserChat>;
  messagesMap: Map<string, MessageChat>;
  infoCargada: boolean;
  snapshot: number;
  nonReadMessages: number;
  myInfo: UserChat;
  allMessagesLoaded: boolean;
  startPagination: string;
  lastReadMessage: string;
  lastMessage: any;
  message;
  belongs = true;

  constructor(id?: string, roomTypeId?: number, date?: any, users?: UserChat[],
              messages?: MessageChat[], usersMap?: Map<string, UserChat>,messagesMap?: Map<string, MessageChat>, lastMessage?: any , message?: string) {
    this.id = id || '';
    this.roomTypeId = roomTypeId || -1;
    this.date = date || null;
    this.users = users || [];
    this.messages = messages || [];
    this.newmessages = [];
    this.usersMap = usersMap || new Map<string, UserChat>();
    this.messagesMap = messagesMap || new Map<string, MessageChat>();
    this.infoCargada = false;
    this.snapshot = 0;
    this.nonReadMessages = 0;
    this.allMessagesLoaded = false;
    this.startPagination = undefined;
    this.lastReadMessage = undefined;
    this.lastMessage = lastMessage || null;
    this.message = message;
  }
}
