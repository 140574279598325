<div class="each-noti">
  <i *ngIf="iconActive" id="iconing" [className]="'material-icons'" matTooltip="{{iconTooltipe}}" [ngStyle]="{'color': colorIcon}">{{icon}}</i><!-- ¿afegir class animation? -->
  <span style="width: 100%; display: inline-grid; padding-left: 7px !important;" fxLayout="row wrap">
    <a fxFlex="100" style="font-size: 11px; text-transform: uppercase; color: #fff !important; font-weight: bold; padding: 0.5em 0;" *ngIf="!personalizado" [ngStyle]="{'color': colorTitle}">AUTO ADVICE ?{{title}}</a>
    <i fxFlex="100" style="font-size: 11px; color: #fff !important;" *ngIf="!personalizado" [ngStyle]="{'color':  colorSubtitle}">{{subtitle}}</i>
    <i fxFlex="100" style="font-size: 11px; color: #fff !important;" *ngIf="personalizado" [innerHTML]="mensaje | safeHtml"></i>

    <span class="container-buttons" fxFlex="100">
      <button *ngIf="button1" (click)="buttonAction(0)" matTooltip="{{button1Tooltipe}}" [ngStyle]="{'background': button1Background, 'color': button1Color, 'border': '1px solid ' + button1Border}" class="btn-success-2"><i *ngIf="button1ActiveIcon" [className]="'material-icons'" [ngStyle]="{'color': button1IconColor}">{{button1Icon}}</i>{{button1Text}}</button>
      <button *ngIf="button2" (click)="buttonAction(1)" matTooltip="{{button2Tooltipe}}" [ngStyle]="{'background': button2Background, 'color': button2Color, 'border': '1px solid ' + button2Border}" class="btn-success-2"><i *ngIf="button2ActiveIcon" [className]="'material-icons'" [ngStyle]="{'color': button2IconColor}">{{button2Icon}}</i>{{button2Text}}</button>
      <button *ngIf="button3" (click)="buttonAction(2)" matTooltip="{{button3Tooltipe}}" [ngStyle]="{'background': button3Background, 'color': button3Color, 'border': '1px solid ' + button3Border}" class="btn-success-2"><i *ngIf="button3ActiveIcon" [className]="'material-icons'" [ngStyle]="{'color': button3IconColor}">{{button3Icon}}</i>{{button3Text}}</button>
    </span>
  </span>
  <mat-icon *ngIf="close" (click)="closeHomeNotification()" class="removeit" [ngStyle]="{'color': colorClose}">cancel</mat-icon>
  <!--<mat-icon *ngIf="close" class="removeit" (click)="closeWindow()" [ngStyle]="{'color': colorClose}">cancel</mat-icon>-->
</div>
<mat-divider style="margin: 5px 15px;"></mat-divider>
