import { Injectable } from '@angular/core';

@Injectable()
export class RedirectionService {
  public redirectionURL = null;
  public redirectionJoinGroup = false;
  public shouldRegister = false;

  constructor() {
  }
}
