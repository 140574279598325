<!-- LANGUAGE -->
<div fxShow="true" fxShow.sm="false" fxShow.xs="false" *ngIf="!authService.userId" class="language" fxLayout="column" fxLayoutAlign="space-between center">
    <div>
      <select class="form-control-plaintext text-uppercase"
              #selectedLang
              (change)="switchLang(selectedLang.value)">
        <option *ngFor="let language of authService.arrayLanguages"
                [value]="language.id_language"
                [selected]="language.id_language === authService.languageId">
          {{ language.label }}
        </option>
      </select>
    </div>
</div>

<!-- SOCIALS -->
<div fxShow="true" fxShow.sm="false" fxShow.xs="false" *ngIf="!authService.userId" class="socials" fxLayout="column" fxLayoutAlign="space-between center">
<div class="s-item" fxFlex="20">
    <a href="{{ 'web.socials.facebookUrl' | translate}}" target="_blank" onclick="gtag('event', 'click', {'event_category': 'botó', 'event_label': 'facebook', 'value': '7'});">
    <img title="{{ 'web.socials.facebookImgTitle' | translate}}" alt="{{ 'web.socials.facebookImgAlt' | translate}}" src="../../../assets/icons/social_icons/{{facebookImg}}">
    </a>
</div>
<div class="s-item" fxFlex="20">
    <a href="{{ 'web.socials.instagramUrl' | translate}}" target="_blank" onclick="gtag('event', 'click', {'event_category': 'botó', 'event_label': 'instagram', 'value': '8'});">
    <img title="{{ 'web.socials.instagramImgTitle' | translate}}" alt="{{ 'web.socials.instagramImgAlt' | translate}}" src="../../../assets/icons/social_icons/{{instagramImg}}">
    </a>
</div>
<div class="s-item" fxFlex="20">
    <a href="{{ 'web.socials.twitterUrl' | translate}}" target="_blank" onclick="gtag('event', 'click', {'event_category': 'botó', 'event_label': 'twitter', 'value': '9'});">
    <img title="{{ 'web.socials.twitterImgTitle' | translate}}" alt="{{ 'web.socials.twitterImgAlt' | translate}}" src="../../../assets/icons/social_icons/{{twitterImg}}">
    </a>
</div>
<div class="s-item" fxFlex="20">
    <a href="{{ 'web.socials.youtubeUrl' | translate}}" target="_blank" onclick="gtag('event', 'click', {'event_category': 'botó', 'event_label': 'youtube', 'value': '10'});">
    <img title="{{ 'web.socials.youtubeImgTitle' | translate}}" alt="{{ 'web.socials.youtubeImgAlt' | translate}}" src="../../../assets/icons/social_icons/{{youtubeImg}}">
    </a>
</div>
<div class="s-item" fxFlex="20">
    <a href="{{ 'web.socials.linkedinUrl' | translate}}" target="_blank" onclick="gtag('event', 'click', {'event_category': 'botó', 'event_label': 'linkedin', 'value': '11'});">
    <img title="{{ 'web.socials.linkedinImgTitle' | translate}}" alt="{{ 'web.socials.linkedinImgAlt' | translate}}" src="../../../assets/icons/social_icons/{{linkedinImg}}">
    </a>
</div>
</div>
