<div>
  <mat-form-field appearance="outline" style="width: 100%;">
    <mat-label>Introducir el email</mat-label>
    <input matInput [formControl]="emailFormControl" #checker placeholder="e.g. usuario@ejemplo.com" [attr.disabled]="true">
    <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
      Por favor introduzca un email correcto
    </mat-error>
    <mat-error *ngIf="emailFormControl.hasError('required')">
      Es necesario introducir <strong>Email</strong>
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" style="width: 100%;">
    <mat-label>Especificar un mensaje</mat-label>
    <input matInput [formControl]="messageFormControl" [attr.disabled]="true">
    <mat-error *ngIf="messageFormControl.hasError('required')">
      Es necesario introducir un <strong>mensaje</strong>
    </mat-error>
  </mat-form-field>
  <div class="dialog-footer-2">
    <button (click)="closeBottomSheet(2)">Cancelar</button>
    <button [disabled]="(!emailFormControl.valid && !messageFormControl.valid)" (click)="closeBottomSheet(1)">Enviar</button>
  </div>
</div>
