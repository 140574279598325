<div class="container2 agenda-container">
    <div class="m-2">
        <mat-form-field appearance="outline" *ngIf="showSearchBar">
            <input #filterInput placeholder="Buscar..." matInput type="text" [(ngModel)]="value" (keyup.enter)="searchValue($event.target.value); $event.stopPropagation()" >
            <span class="generic-buttons-blue-revert button-filter-table" matPrefix *ngIf="value != '' && value != lastValue">
                <button mat-raised-button aria-label="Search" (click)="searchValue(value); $event.stopPropagation()">
                  Filtrar
                </button>
              </span>
            <mat-icon *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="cleanFilter(); $event.stopPropagation()" matTooltip="Limpiar">close</mat-icon> 
            <mat-icon *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="deleteFilter(); $event.stopPropagation()" matTooltip="Resetear">refresh</mat-icon> 
          </mat-form-field>
        <div class="row top-home">
            <div class="col-md-4 theselector">
                <div class="btn-group" style="float: left">
                <div class="btn btn-primary" (click)="setView(CalendarView.Month); $event.stopPropagation()" [class.active]="view === CalendarView.Month">Mes</div>
                <div class="btn btn-primary" (click)="setView(CalendarView.Week); $event.stopPropagation()" [class.active]="view === CalendarView.Week">Semana</div>
                <div class="btn btn-primary" (click)="setView(CalendarView.Day); $event.stopPropagation()" [class.active]="view === CalendarView.Day">Día</div>
                </div>
            </div>

            <div class="col-md-4 text-center align-text-middle thedate">
                <div class="arrows text-right" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()"><i class="material-icons">arrow_back</i></div>
                <mat-form-field appearance="none" class="matFormFieldForDatePicker">
                <input style="display: none;" matInput [matDatepicker]="datepicker" [(ngModel)]="viewDate">
                <button class="datepicker" (click)="datepicker.open(); $event.stopPropagation()">{{ viewDate | calendarDate:(view + 'ViewTitle'):locale:weekStartsOn }}</button>
                <mat-datepicker #datepicker startView="year" [startAt]="viewDate" ></mat-datepicker>
                </mat-form-field>
                <div class="arrows text-left" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()"><i class="material-icons">arrow_forward</i></div>
            </div>

            <div class="col-md-4 thebuttons" style="padding-right: calc(15px - 0.3rem);">    
                <div class="btn-group">
                  <span *ngIf="!showSearchBar" matBadge="!" [matBadgeHidden]="!this.value || this.value == ''" class="badge-form-change second-bar-tools icons-header-custom">
                    <button class="btn btnjan eseee" (click)="openSearchBar(); $event.stopPropagation()" matTooltip="Filtrar"><i class="material-icons">search</i></button>
                  </span>
                  <span *ngIf="showSearchBar" class="badge-form-change second-bar-tools icons-header-custom">
                    <button class="btn btnjan eseee" (click)="deleteFilter(); $event.stopPropagation()" matTooltip="Resetear filtro"><i class="material-icons">search_off</i></button>
                  </span>
                </div>
              </div>
        </div>
        <div class="row calendarintern">
            <ng-template #customCellTemplate let-day="day" let-locale="locale">
                <div class="cal-cell-top">
                <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
                <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
                </div>
                <div class="cell-totals">
                <span *ngFor="let group of day.eventGroups" [ngStyle]="{'background': group[0]}" class="badge badge-{{ group[0] }}"> {{ group[1].length }}</span>
                </div>
            </ng-template>
            <ng-template #eventSegment let-days="days" let-locale="locale">
                <label class="cal-event"> <i class="fa fa-fw fa-pencil"></i>
                </label>
            </ng-template>
            <div [ngSwitch]="view" *ngIf="loaded">
                <mwl-calendar-month-view
                *ngSwitchCase="CalendarView.Month"
                [viewDate]="viewDate"
                [events]="customEvents"
                [refresh]="refresh"
                [activeDayIsOpen]="activeDayIsOpen"
                (dayClicked)="dayClicked($event.day)"
                (eventClicked)="handleEvent('Clicked', $event.event)"
                (eventTimesChanged)="eventTimesChanged($event)"
                [locale]="locale"
                [weekStartsOn]="weekStartsOn"
                [weekendDays]="weekendDays"
                [cellTemplate]="customCellTemplate"
                >
                </mwl-calendar-month-view>
                <mwl-calendar-week-view
                *ngSwitchCase="CalendarView.Week"
                [viewDate]="viewDate"
                [events]="customEvents"
                [refresh]="refresh"
                (eventClicked)="handleEvent('Clicked', $event.event)"
                (hourSegmentClicked)="handleEvent('HourClicked', $event)"
                (eventTimesChanged)="eventTimesChanged($event)"
                [locale]="locale"
                [weekStartsOn]="weekStartsOn"
                [weekendDays]="weekendDays"
                [dayStartHour]="dayStartHour"
                [dayEndHour]="dayEndHour"
                [hourSegmentHeight]="70"
                [hourSegments]="1"
                >
                </mwl-calendar-week-view>
                <mwl-calendar-day-view
                *ngSwitchCase="CalendarView.Day"
                [viewDate]="viewDate"
                [events]="customEvents"
                [refresh]="refresh"
                (eventClicked)="handleEvent('Clicked', $event.event)"
                (eventTimesChanged)="eventTimesChanged($event)"
                [locale]="locale"
                (hourSegmentClicked)="handleEvent('HourClicked', $event)"
                [dayStartHour]="dayStartHour"
                [dayEndHour]="dayEndHour"
                [hourSegmentHeight]="70"
                [hourSegments]="1"
                >
                </mwl-calendar-day-view>
            </div>
        </div>
    </div>
</div>
  