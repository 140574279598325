import { Component, OnInit, Input } from '@angular/core';
import {EndpointService} from "../../../../services/endpoint/endpoint.service";
import {AuthService} from '../../../../services/auth/auth.service';

@Component({
  selector: 'app-landing-social-media',
  templateUrl: './landing-social-media.component.html',
  styleUrls: ['./landing-social-media.component.css']
})
export class LandingSocialMediaComponent implements OnInit {

  public accounts = [];
  public loaded = false;
  @Input() from: number;
  public arrayRedes = [];

  constructor(public endpointService: EndpointService,
              public authService: AuthService) { }

  ngOnInit(): void {
    this.endpointService.getSocialMediaAccounts(this.authService.getIdEmpresa(), this.from).subscribe(data => {
      this.accounts = data['response'];
      //(<any>window).twttr.widgets.load();
      this.loaded = true;
    });
  }
}
