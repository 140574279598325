<ng-template matStepLabel>Seleccionar usuarios</ng-template>
<!--<span>{{contacts | json}}</span>-->
<div class="example-container taulesgrans" style="overflow: auto; height: calc(65vh - 59px); min-width: 50em;">
  <table class="mat-elevation-z3" mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="photo">
      <th mat-header-cell *matHeaderCellDef>Foto</th>
      <td mat-cell *matCellDef="let element; let index = index">
        <img class="fotoEvento2" src="{{element.profilePictureURL}}" alt="" style="max-width:100%; max-height:100%;">
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
      <td mat-cell *matCellDef="let element">{{element.name}} {{element.surname}}</td>
    </ng-container>

    <ng-container matColumnDef="add">
      <th mat-header-cell *matHeaderCellDef>Añadir usuario</th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox *ngIf="!chatService.PrivateChatRoomsMap.get(groupId).usersMap.has(element.userToken)"
                      (change)="changeStatusUser(element.userToken,$event.checked, element.name.concat(' ').concat(element.surname))"></mat-checkbox>
        <span *ngIf="chatService.PrivateChatRoomsMap.get(groupId).usersMap.has(element.userToken)">Este usuario ya se encuentra en el grupo</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="admin">
      <th mat-header-cell *matHeaderCellDef>Hacer admin</th>
      <td mat-cell *matCellDef="let element" (click)="element.check = !element.check">{{element.phone}}
        <mat-checkbox *ngIf="!chatService.PrivateChatRoomsMap.get(groupId).usersMap.has(element.userToken)" [disabled]="!selectedUsers.has(element.userToken)" (change)="changeStatusUserAdmin(element.userToken,$event.checked)"></mat-checkbox>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="cursor: pointer;"></tr>
  </table>
</div>

<!--<div class="users">
  <ul class="users-list" style="list-style-type: none;">
    <li *ngFor="let users of contacts; let i = index;" style="height: 3em;">
      <mat-checkbox (change)="changeStatusUser(users.userToken,$event.checked)" style="padding-right: 20px;"></mat-checkbox>
      <img class="fotoEvento2" src="{{users.profilePictureURL}}" alt="" style="max-width:100%; max-height:100%;">
      {{users.name}} {{users.surname}}
      <mat-checkbox [disabled]="!selectedUsers.has(users.userToken)" (change)="changeStatusUserAdmin(users.userToken,$event.checked)" style="float: right; padding-right: 20px;"></mat-checkbox>
      <span *ngIf="selectedUsers.has(users.userToken)" style="float: right; padding-right: 20px;"><b>Admin </b></span>
    </li>
  </ul>
</div>-->

<div>
  <button (click)="addUser(1)" mat-button>Cancelar</button>
  <button (click)="addUser(2)" mat-button>Aceptar</button>
</div>
