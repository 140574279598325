<mat-form-field appearance="{{ this.structure[this.index]['class'] }}" [formGroup]="genericForm" [ngClass]="{'generic-input-disabled': this.structure[this.index]['id_functional_status_general'] === 3}">

  <mat-label  [innerHTML]="label | safeHtml"></mat-label>
  <input matInput
         type="text"
         style="text-transform: capitalize;"
         id="{{this.structure[this.index]['bd_field']}}"
         formControlName="{{this.structure[this.index]['id_functional_area'] + '-' + this.structure[this.index]['bd_field']}}"
         [required]="this.structure[this.index]['id_functional_status_general'] === 5"
         [attr.disabled]="this.structure[this.index]['id_functional_status_general'] === 3 ? '' : null"
         #locationSearchInput
         [matAutocomplete]="autoGroup"
         autocomplete="off">

  <mat-autocomplete #autoGroup="matAutocomplete" (optionSelected)="optionSelected($event)" autoActiveFirstOption>
    <mat-option *ngIf="isSearching || moreCharacters" [disabled]="true">
      <span style="display: flex; align-items: center; font-size: 12px; font-style: italic;">
        <mat-spinner class="yellowSpinner" [diameter]="17" [strokeWidth]="2" style="margin-right: 5px;"></mat-spinner><span *ngIf="moreCharacters"> {{ 'form.error.moreCharacters' | translate}} {{this.numCharactersSearch}} {{ 'form.error.moreCharacters2' | translate}} </span>
      </span>
    </mat-option>
    <ng-container *ngIf="apiResponse['errorCode'] != -1; else notFound">
      <ng-container *ngFor="let group of apiResponse['response']">
        <mat-optgroup [label]="group.country" *ngIf="!(isSearching || moreCharacters)">
          <mat-option *ngFor="let location of group.locations" [value]="location" [disabled]="this.structure[this.index]['id_functional_status_general'] === 3">
            {{deleteCountryCode(location.fullname)}}
          </mat-option>
        </mat-optgroup>
      </ng-container>
    </ng-container>
    <ng-template #notFound>
      <ng-container *ngIf="!(isSearching || moreCharacters)">
        <mat-option [disabled]="true">
          {{ 'form.error.notFound' | translate}}
        </mat-option>
      </ng-container>
    </ng-template>
  </mat-autocomplete>
  <span
    *ngIf="inputValueLabel !== null && (this.structure[this.index]['form_field_empty'] == 1 || this.structure[this.index]['form_field_empty'] != 1) && this.structure[this.index]['id_functional_status_general'] != 3"
    matSuffix aria-label="Clear" class="clear-input" (click)="clear(); $event.stopPropagation()">
      <mat-icon>close</mat-icon>
  </span>

</mat-form-field>
