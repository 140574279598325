<ng-container *ngIf="loaded && structure[this.index]['child'] && structure[this.index]['child'].length > 0">
  <mat-tab-group mat-align-tabs="start" [(selectedIndex)]="structure[this.index]['child'][0]['tabActive']" (selectedTabChange)="onTabChange($event)" *ngIf="!functionsService.downloadingStaticPage">
    <ng-container *ngFor="let e of structure[this.index]['child'] | keyvalue : genericService.returnZero">
      <mat-tab [id]="structure[this.index]['child'][e.key]['creatingMode'] ? structure[this.index]['child'][e.key]['idTmp'] : structure[this.index]['child'][e.key]['id_functional_area']" *ngIf="structure[this.index]['child'][e.key] && this.checkIfValidTab(this.structure[this.index]['child'][e.key], e.key)">
        <ng-template mat-tab-label>
          <span 
                (click)="genericCreatorService.editOption(structure[this.index]['child'][e.key])"
                
                [fxFlex]="structure[this.index]['child'][e.key]['width_general']"
                [fxFlex.xs]="structure[this.index]['child'][e.key]['width_xs'] != 0 ? structure[this.index]['child'][e.key]['width_xs'] : structure[this.index]['child'][e.key]['width_general']"
                [fxFlex.sm]="structure[this.index]['child'][e.key]['width_s'] != 0 ? structure[this.index]['child'][e.key]['width_s'] : structure[this.index]['child'][e.key]['width_general']"
                [fxFlex.md]="structure[this.index]['child'][e.key]['width_m'] != 0 ? structure[this.index]['child'][e.key]['width_m'] : structure[this.index]['child'][e.key]['width_general']"
                [fxFlex.lg]="structure[this.index]['child'][e.key]['width_l'] != 0 ? structure[this.index]['child'][e.key]['width_l'] : structure[this.index]['child'][e.key]['width_general']"
                [fxFlex.xl]="structure[this.index]['child'][e.key]['width_xl'] != 0 ? structure[this.index]['child'][e.key]['width_xl'] : structure[this.index]['child'][e.key]['width_general']"
  
                [fxHide]="(structure[this.index]['child'][e.key]['hide']=='true' && structure[this.index]['child'][e.key]['hide'] != 0) || structure[this.index]['child'][e.key]['hide'] == 1"
                [fxHide.xs]="(structure[this.index]['child'][e.key]['hide']=='true' && structure[this.index]['child'][e.key]['hide'] != 0) || (structure[this.index]['child'][e.key]['hide_xs']=='true' && structure[this.index]['child'][e.key]['hide_xs'] != 0) || structure[this.index]['child'][e.key]['hide'] == 1 || structure[this.index]['child'][e.key]['hide_xs'] == 1"
                [fxHide.sm]="(structure[this.index]['child'][e.key]['hide']=='true' && structure[this.index]['child'][e.key]['hide'] != 0) || (structure[this.index]['child'][e.key]['hide_s']=='true' && structure[this.index]['child'][e.key]['hide_s'] != 0) || structure[this.index]['child'][e.key]['hide'] == 1 || structure[this.index]['child'][e.key]['hide_s'] == 1"
                [fxHide.md]="(structure[this.index]['child'][e.key]['hide']=='true' && structure[this.index]['child'][e.key]['hide'] != 0) || (structure[this.index]['child'][e.key]['hide_m']=='true' && structure[this.index]['child'][e.key]['hide_m'] != 0) || structure[this.index]['child'][e.key]['hide'] == 1 || structure[this.index]['child'][e.key]['hide_m'] == 1"
                [fxHide.lg]="(structure[this.index]['child'][e.key]['hide']=='true' && structure[this.index]['child'][e.key]['hide'] != 0) || (structure[this.index]['child'][e.key]['hide_l']=='true' && structure[this.index]['child'][e.key]['hide_l'] != 0) || structure[this.index]['child'][e.key]['hide'] == 1 || structure[this.index]['child'][e.key]['hide_l'] == 1"
                [fxHide.xl]="(structure[this.index]['child'][e.key]['hide']=='true' && structure[this.index]['child'][e.key]['hide'] != 0) || (structure[this.index]['child'][e.key]['hide_xl']=='true' && structure[this.index]['child'][e.key]['hide_xl'] != 0) || structure[this.index]['child'][e.key]['hide'] == 1 || structure[this.index]['child'][e.key]['hide_xl'] == 1"
  
                [ngClass]="structure[this.index]['child'][e.key]['id_functional_status_mkt'] != 1 ? structure[this.index]['child'][e.key]['class_custom']+ ' ' + 'mkt-no-available' : structure[this.index]['child'][e.key]['class_custom']"
                [ngStyle]="structure[this.index]['child'][e.key]['styleParsed']['style']"
                [matTooltip]="structure[this.index]['child'][e.key]['tooltip']"
                [id]="structure[this.index]['child'][e.key]['creatingMode'] ? structure[this.index]['child'][e.key]['idTmp'] : structure[this.index]['child'][e.key]['id_functional_area']">
            <mat-icon *ngIf="structure[this.index]['child'][e.key]['icon'] && structure[this.index]['child'][e.key]['icon'] != ''" class="{{structure[this.index]['child'][e.key]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
              structure[this.index]['child'][e.key]['icon_type']=='Filled' ? 'material-icons' :
              structure[this.index]['child'][e.key]['icon_type'] =='Round' ? 'material-icons-round' :
              structure[this.index]['child'][e.key]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
              structure[this.index]['child'][e.key]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}"> {{ structure[this.index]['child'][e.key]['icon'] }}</mat-icon>
            <span [innerHTML]="structure[this.index]['child'][e.key]['label'] | safeHtml"></span>
          </span>
        </ng-template>
        <ng-container *ngIf="(structure[this.index]['child'][e.key]['child'] || structure[this.index]['creatingMode']) && structure[this.index]['child'][e.key]['tabActiveNow']">
          <app-generic [structure]="structure[this.index]['child'][e.key]"></app-generic>
        </ng-container>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
  <div *ngIf="functionsService.downloadingStaticPage">
    <ng-container *ngFor="let e of structure[this.index]['child'] | keyvalue : genericService.returnZero">
      <ng-container *ngIf="structure[this.index]['child'][e.key] && this.checkIfValidTab(this.structure[this.index]['child'][e.key], e.key)">
        <h4 style="padding-top: 10px;">
          <mat-icon *ngIf="structure[this.index]['child'][e.key]['icon'] && structure[this.index]['child'][e.key]['icon'] != ''" class="{{structure[this.index]['child'][e.key]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
            structure[this.index]['child'][e.key]['icon_type']=='Filled' ? 'material-icons' :
            structure[this.index]['child'][e.key]['icon_type'] =='Round' ? 'material-icons-round' :
            structure[this.index]['child'][e.key]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
            structure[this.index]['child'][e.key]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}"> {{ structure[this.index]['child'][e.key]['icon'] }}</mat-icon>
          <span [innerHTML]="structure[this.index]['child'][e.key]['label'] | safeHtml"></span>
        </h4>
        <ng-container *ngIf="structure[this.index]['child'][e.key]['child']">
          <app-generic [structure]="structure[this.index]['child'][e.key]"></app-generic>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
