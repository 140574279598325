<div id="container" class="outer_container" style="width: calc(100vw - 1.2vw); padding: 3vh 50px;">
    <svg [attr.width]="maxWidthPX" [attr.height]="maxHeightPX" *ngIf="this.elements.length > 0">
        <g *ngFor="let element of elements | keyvalue: genericService.returnZero; let i = index;" class="node-journey node_{{elements[element.key].tmpId}}  node_type_{{elements[element.key].id_type}} node_action_type_{{elements[element.key].id_type_action}}">

            <rect *ngIf="elements[element.key].id_type != '2' && elements[element.key].id_type != '4' && elements[element.key].id_type != '19'" [attr.x]="elements[element.key].x" [attr.y]="elements[element.key].y"
                [attr.width]="elements[element.key].width" (click)="openDialog(elements[element.key], elements[element.key].name, elements[element.key].id)"
                [attr.height]="elements[element.key].height" rx="3" ry="3" />
            <text *ngIf="elements[element.key].id_type != '2' && elements[element.key].id_type != '4' && elements[element.key].id_type != '19'" [attr.x]="elements[element.key].x + elements[element.key].width / 2"
                [attr.y]="elements[element.key].y + (elements[element.key].height / 2) - 10" (click)="openDialog(elements[element.key], elements[element.key].name, elements[element.key].id)"
                text-anchor="middle" font-weight="500" font-size="14px" dominant-baseline="central" fill="#fff">{{elements[element.key].name}}</text>
            <text *ngIf="elements[element.key].id_type != '2' && elements[element.key].id_type != '4' && elements[element.key].id_type != '19'" [attr.x]="elements[element.key].x + elements[element.key].width / 2"
                [attr.y]="elements[element.key].y + (elements[element.key].height / 2) + 10" (click)="openDialog(elements[element.key], elements[element.key].name, elements[element.key].id)"
                text-anchor="middle" font-weight="300" font-size="12px" dominant-baseline="central" fill="#fff">{{elements[element.key].typeLabel}}{{elements[element.key].typeSubabel}}</text>

            <rect *ngIf="elements[element.key].type == 'yes' && (elements[element.key].id_type == '4' || elements[element.key].id_type == '19')" [attr.x]="elements[element.key].x"
                [attr.y]="elements[element.key].y" [attr.width]="elements[element.key].width"
                (click)="openDialog(elements[element.key], elements[element.key].name, elements[element.key].id)" [attr.height]="elements[element.key].height" fill="green" rx="40"
                ry="40" />
            <rect *ngIf="elements[element.key].type == 'no' && (elements[element.key].id_type == '4' || elements[element.key].id_type == '19')" [attr.x]="elements[element.key].x"
                [attr.y]="elements[element.key].y" [attr.width]="elements[element.key].width"
                (click)="openDialog(elements[element.key], elements[element.key].name, elements[element.key].id)" [attr.height]="elements[element.key].height" fill="red" rx="40"
                ry="40" />
            <text *ngIf="elements[element.key].id_type == '4' || elements[element.key].id_type == '19'" [attr.x]="elements[element.key].x + elements[element.key].width / 2"
                [attr.y]="elements[element.key].y + (elements[element.key].height / 2)" (click)="openDialog(elements[element.key], elements[element.key].name, elements[element.key].id)"
                text-anchor="middle" font-size="14px" font-weight="400" dominant-baseline="central" fill="#fff">{{elements[element.key].name}}</text>

            <text *ngIf="elements[element.key].id_type == '4' || elements[element.key].id_type == '1' || elements[element.key].id_type == '19'" class="material-icons-outlined paste-button-text" 
                [attr.x]="elements[element.key].x + 17" [attr.y]="elements[element.key].y + 17"
                (click)="openDialog(elements[element.key], elements[element.key].name, elements[element.key].id)" text-anchor="middle" font-size="14px" font-weight="400" dominant-baseline="central" fill="#fff">{{elements[element.key].icon}}</text>

            <g *ngIf="elements[element.key].id_type == '2'" class="add-elements">
                <rect [attr.x]="elements[element.key].x + 10" [attr.y]="elements[element.key].y + 15" [attr.width]="30"
                    (click)="openDialog(elements[element.key], elements[element.key].name, elements[element.key].id)" [attr.height]="30" fill="rgb(var(--tertiary-color))" rx="30" ry="30" />
                <text [attr.x]="elements[element.key].x + 30 / 2 + 10" [attr.y]="elements[element.key].y + (30 / 2) + 15"
                    (click)="openDialog(elements[element.key], elements[element.key].name, elements[element.key].id)" text-anchor="middle" font-weight="500" dominant-baseline="central"
                    fill="#fff">{{elements[element.key].name}}</text>
                
                <rect *ngIf="this.journeyService.copyElement" class="paste-button-text" [attr.x]="elements[element.key].x + 10 + elements[element.key].width/1.5" [attr.y]="elements[element.key].y + 15" [attr.width]="30"
                    (click)="pasteElement(elements[element.key])" [attr.height]="30" fill="rgb(var(--tertiary-color))" rx="30" ry="30" />
                <text *ngIf="this.journeyService.copyElement" class="material-icons-outlined paste-button-text" [attr.x]="elements[element.key].x + 30 / 2 + 10 + elements[element.key].width/1.5" [attr.y]="elements[element.key].y + (30 / 2) + 15"
                    (click)="pasteElement(elements[element.key])" text-anchor="middle" font-weight="500" dominant-baseline="central"
                    fill="#fff">content_copy</text>
            </g>

            <g class="button-group" *ngIf="elements[element.key].id_type != '2' && elements[element.key].id_type != '3' && elements[element.key].id_type != '4' && elements[element.key].id_type != '19'">

                <g class="button-group-delete">
                    <rect class="delete-button" (click)="deleteElement(elements[element.key])"
                        [attr.x]="elements[element.key].x + elements[element.key].width - 30" [attr.y]="elements[element.key].y - 22" [attr.width]="30"
                        [attr.height]="22" fill="red" rx="1" ry="1" />
                    <text class="material-icons-outlined delete-button-text"
                        (click)="deleteElement(elements[element.key])"
                        [attr.x]="elements[element.key].x + elements[element.key].width - 15" [attr.y]="elements[element.key].y - 11" text-anchor="middle"
                        dominant-baseline="central" fill="#fff">delete</text>
                </g>

                <g class="button-group-copy" *ngIf="elements[element.key].id_category != 2 && elements[element.key].id_type_action != 16">
                    <rect class="copy-button" (click)="copyElement(elements[element.key])" [attr.x]="elements[element.key].x + elements[element.key].width - 60"
                        [attr.y]="elements[element.key].y - 22" [attr.width]="30" [attr.height]="22" fill="green" rx="1" ry="1" />
                    <text class="material-icons-outlined edit-button-text" (click)="copyElement(elements[element.key])"
                        [attr.x]="elements[element.key].x + elements[element.key].width - 45" [attr.y]="elements[element.key].y - 11" text-anchor="middle"
                        dominant-baseline="central" fill="#fff">content_copy</text>
                </g>

            </g>
        </g>
        <g *ngFor="let line of lines">
            <line [attr.x1]="line.startX" [attr.y1]="line.startY" [attr.x2]="line.endX" [attr.y2]="line.endY"
                stroke="#959595" stroke-width="3" />
            <text *ngIf="line.typeLine == 2" class="material-icons rounded" style="font-size: 35px;" [attr.x]="line.endX"
                [attr.y]="line.endY" text-anchor="middle" dominant-baseline="central"
                fill="#959595">arrow_drop_down</text>
        </g>
    </svg>
</div>