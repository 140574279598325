import { Component, Inject, OnInit } from '@angular/core';
import { EndpointService } from "../../../../../services/endpoint/endpoint.service";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { BodyTime } from "../../../../../add-new-inmo/body/body-time";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "../../../../../services/auth/auth.service";

@Component({
  selector: 'app-agenda-settings',
  templateUrl: './agenda-settings.component.html',
  styleUrls: ['./agenda-settings.component.scss']
})
export class AgendaSettingsComponent implements OnInit {

  public calendarsMicrosoft = new Array();
  public calendarsSelectedMicrosoft = new Array();
  public calendarsSelectedMicrosoftInit = new Array();
  public calendarsGoogle = new Array();
  public calendarsSelectedGoogle = new Array();
  public calendarsSelectedGoogleInit = new Array();
  public calendarEmpresaGoogle = 0;
  public calendarEmpresaMicrosoft = 0;

  public bodyTime: BodyTime;
  public ActivitiesReReminderArray;
  public userData = null;
  public calendarFirstDay: number;
  public loaded = false;
  public loadedMicrosoft = false;
  public loadedGoogle = false;
  public showRememberConfig: boolean;


  constructor(public dialog: MatDialog,
    private endpointService: EndpointService,
    public snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<AgendaSettingsComponent>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.calendarFirstDay = data.calendarDayInit;
    this.showRememberConfig = data.showRememberConfig;
  }

  ngOnInit(): void {
    this.bodyTime = new BodyTime();
    this.endpointService.getAllFromUser(this.authService.userId).subscribe(data => {
      if (data['errorCode'] === 0) {
        this.userData = data['response'];
        this.ActivitiesReReminderArray = data['response']['activitiesReReminder'];
        const initDate = this.userData.hora_inicio_cal.split(':');
        this.userData.hora_inicio_cal = initDate[0] + ":" + initDate[1];
        const endDate = this.userData.hora_fin_cal.split(':');
        this.userData.hora_fin_cal = endDate[0] + ":" + endDate[1];
        this.calendarEmpresaGoogle = data['response']['id_empresa_calendario_google'];
        this.calendarEmpresaMicrosoft = data['response']['id_empresa_calendario_microsoft'];
        this.loaded = true;
      }
    });

    this.endpointService.getCalendarsGoogle(this.authService.userId).subscribe(
      data=> {
        if (data['errorCode'] === 0) {
          this.calendarsGoogle= data['response']['calendars'];
          this.calendarsSelectedGoogle = new Array();
          for(let i = 0; i < data['response']['getCalendars'].length; i++) this.calendarsSelectedGoogle.push(data['response']['getCalendars'][i].id);
          this.calendarsSelectedGoogleInit = this.calendarsSelectedGoogle;
          this.loadedGoogle = true;
        } else this.loadedGoogle = true;
      }
    );

    this.endpointService.getCalendarsMicrosoft(this.authService.userId).subscribe(
      data=> {
        if(data['errorCode'] === 0){
          this.calendarsMicrosoft = data['response']['calendars'];
          this.calendarsSelectedMicrosoft = new Array();
          for(let i = 0; i < data['response']['getCalendars'].length; i++) this.calendarsSelectedMicrosoft.push(data['response']['getCalendars'][i].id);
          this.calendarsSelectedMicrosoftInit = this.calendarsSelectedMicrosoft;
          this.loadedMicrosoft = true;
        } else this.loadedMicrosoft = true;
      }
    );
  }

  timeChangedStartCalendar(event) {
    this.userData.hora_inicio_cal = event;
  }

  timeChangedEndCalendar(event) {
    this.userData.hora_fin_cal = event;
  }

  updateInfo() {
    this.endpointService.updateAgendaSettings(this.authService.userId, this.userData, this.calendarFirstDay).subscribe(data => {
      this.responseEndpoint(data);
      this.dialogRef.close({ startDay: this.calendarFirstDay, dateStartCalendar: this.userData.hora_inicio_cal, dateEndCalendar: this.userData.hora_fin_cal });
    });
  }

  responseEndpoint(data, from = 0) {
    if (data['errorCode'] === 0) {
      let message = "Guardado correctamente";
      if(from == 1) message = "Se ha vinculado correctamente";
      if(from == 2) message = "Se ha desvinculado correctamente";
      this.snackBar.open(message, 'X', {
        duration: 6000,
        panelClass: ['lightgreen-snackbar']
      });
    } else {
      this.snackBar.open('Error', 'X', {
        duration: 6000,
        panelClass: ['red-snackbar']
      });
    }
  }

  guardarGoogle(){
    this.endpointService.setCalendarsGoogle(this.authService.userId, this.getCalendarsGoogle()).subscribe(data=>{
      this.responseEndpoint(data);
      if (data['errorCode'] === 0) {
        this.calendarsSelectedGoogleInit = this.calendarsSelectedGoogle;
      }
    });
  }

  guardarMicrosoft(){
    this.endpointService.setCalendarsMicrosoft(this.authService.userId, this.getCalendarsMicrosoft()).subscribe(data=>{
      this.responseEndpoint(data);
      if (data['errorCode'] === 0) {
        this.calendarsSelectedMicrosoftInit = this.calendarsSelectedMicrosoft;
      }
    });
  }

  borrarGoogle(){
    this.endpointService.unsetCalendarsGoogle(this.authService.userId).subscribe(data=>{
      this.responseEndpoint(data);
      if (data['errorCode'] === 0) {
        this.calendarsSelectedGoogle = new Array();
        this.calendarsSelectedGoogleInit = new Array();
      }
    });
  }

  borrarMicrosoft(){
    this.endpointService.unsetCalendarsMicrosoft(this.authService.userId).subscribe(data=>{
      this.responseEndpoint(data);
      if (data['errorCode'] === 0) {
        this.calendarsSelectedMicrosoft = new Array();
        this.calendarsSelectedMicrosoftInit = new Array();
      }
    });
  }

  getCalendarsGoogle(){
    let calendars = new Array();
    this.calendarsSelectedGoogle.map( cs =>{
      this.calendarsGoogle.map(c =>{
        if(cs == c.id) calendars.push(c);
      });
    });
    return calendars;
  }

  getCalendarsMicrosoft(){
    let calendars = new Array();
    this.calendarsSelectedMicrosoft.map( cs =>{
      this.calendarsMicrosoft.map(c =>{
        if(cs == c.id) calendars.push(c);
      });
    });
    return calendars;
  }

  public areArrayEquals(array1, array2){
    if(array1.length != array2.length) return false;
    for(let i = 0; i < array1.length; i++){
      if(array1[i] != array2[i]) return false;
    }
    return true;
  }

  public vincularMicrosoft(){
    this.endpointService.vincularMicrosoftCalendar(this.authService.userId, this.authService.empresaId).subscribe(data=>{
      if(data['errorCode'] == 1) window.open(data['response'],"_self");
      if (data['errorCode'] === 0) this.responseEndpoint(data, 1);
    });
  }

  public desvincularMicrosoft(){
    this.endpointService.desvincularMicrosoftCalendar(this.authService.userId).subscribe(data=>{
      this.responseEndpoint(data, 2);
      if (data['errorCode'] === 0) {
        this.authService.microsoftToken = null;
      }
    });
  }

  public vincularGoogle(){
    this.endpointService.vincularGoogleCalendar(this.authService.userId, this.authService.empresaId).subscribe(data=>{
      if(data['errorCode'] == 1) window.open(data['response'],"_self");
      if (data['errorCode'] === 0) this.responseEndpoint(data, 1);
    });
  }

  public desvincularGoogle(){
    this.endpointService.desvincularGoogleCalendar(this.authService.userId).subscribe(data=>{
      this.responseEndpoint(data, 2);
      if (data['errorCode'] === 0) {
        this.authService.googleToken = null;
      }
    });
  }

  public calendarEmpresaGoogleChange() {
    this.endpointService.updateDefaultEmpresaCalendarioUsuario(this.calendarEmpresaGoogle, this.authService.userId, 1).subscribe(data=>{
      this.snackBar.open('Empresa actualizada', 'X', {
        duration: 6000,
        panelClass: ['green-snackbar']
      });
    });
  }

  public calendarEmpresaMicrosoftChange() {
    this.endpointService.updateDefaultEmpresaCalendarioUsuario(this.calendarEmpresaMicrosoft, this.authService.userId, 2).subscribe(data=>{
      this.snackBar.open('Empresa actualizada', 'X', {
        duration: 6000,
        panelClass: ['green-snackbar']
      });
    });
  }

  
	onNoClick(): void {
		this.dialogRef.close();
	}
}
