<ng-container *ngIf="structure[index] && structure[index]['child'] && loaded">
  <mat-card
    [ngStyle]="{'background-color': (this.structure[this.index]['color_card'] &&
      this.structure[this.index]['color_card'] !== null && startsWithHashtag(this.structure[this.index]['color_card']) ) ? this.structure[this.index]['color_card'] : '#fff'}">
    <ng-container *ngFor="let e of structure[index]['child'] | keyvalue : genericService.returnZero">
      <ng-container
        *ngIf="structure[index]['child'][e.key] && genericService.evaluateIfElements(structure[index], e.key) && structure[index]['child'][e.key]['id_functional_status_general'] !== 2">
        <ng-container *ngIf="structure[index]['child'][e.key]['id_functional_type'] == 114">
          <mat-card-header
            [fxHide]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || structure[index]['child'][e.key]['hide'] == 1"
            [fxHide.xs]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_xs']=='true' && structure[index]['child'][e.key]['hide_xs'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_xs'] == 1"
            [fxHide.sm]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_s']=='true' && structure[index]['child'][e.key]['hide_s'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_s'] == 1"
            [fxHide.md]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_m']=='true' && structure[index]['child'][e.key]['hide_m'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_m'] == 1"
            [fxHide.lg]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_l']=='true' && structure[index]['child'][e.key]['hide_l'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_l'] == 1"
            [fxHide.xl]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_xl']=='true' && structure[index]['child'][e.key]['hide_xl'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_xl'] == 1"
            [ngClass]="structure[index]['child'][e.key]['id_functional_status_mkt'] != 1 ? structure[index]['child'][e.key]['class_custom'] + ' ' + 'mkt-no-available' : structure[index]['child'][e.key]['class_custom']"
            [ngStyle]="structure[index]['child'][e.key]['styleParsed']['style']"
            [id]="structure[index]['child'][e.key]['id_functional_area']">
            <div mat-card-avatar
              *ngIf="structure[index]['child'][e.key]['url'] && structure[index]['child'][e.key]['url'] != ''">
              <img src="{{ structure[index]['child'][e.key]['url'] }}"
                alt="{{ structure[index]['child'][e.key]['label'] }}">
            </div>
            <mat-card-title [innerHTML]="structure[index]['child'][e.key]['label'] | safeHtml"></mat-card-title>
            <mat-card-subtitle [innerHTML]="structure[index]['child'][e.key]['text'] | safeHtml"></mat-card-subtitle>
          </mat-card-header>
        </ng-container>

        <ng-container *ngIf="structure[index]['child'][e.key]['id_functional_type'] == 115">
          <img mat-card-image src="{{ structure[index]['child'][e.key]['url'] }}"
            alt="{{ structure[index]['child'][e.key]['label'] }}">
          <mat-card-content
            [fxHide]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || structure[index]['child'][e.key]['hide'] == 1"
            [fxHide.xs]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_xs']=='true' && structure[index]['child'][e.key]['hide_xs'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_xs'] == 1"
            [fxHide.sm]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_s']=='true' && structure[index]['child'][e.key]['hide_s'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_s'] == 1"
            [fxHide.md]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_m']=='true' && structure[index]['child'][e.key]['hide_m'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_m'] == 1"
            [fxHide.lg]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_l']=='true' && structure[index]['child'][e.key]['hide_l'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_l'] == 1"
            [fxHide.xl]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_xl']=='true' && structure[index]['child'][e.key]['hide_xl'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_xl'] == 1"
            [ngClass]="structure[index]['child'][e.key]['id_functional_status_mkt'] != 1 ? structure[index]['child'][e.key]['class_custom'] + ' ' + 'mkt-no-available' : structure[index]['child'][e.key]['class_custom']"
            [ngStyle]="structure[index]['child'][e.key]['styleParsed']['style']"
            [id]="structure[index]['child'][e.key]['id_functional_area']">
            <p [innerHTML]="structure[index]['child'][e.key]['text'] | safeHtml"></p>
          </mat-card-content>
        </ng-container>

        <ng-container
          *ngIf="structure[index]['child'][e.key]['id_functional_type'] != 114 && structure[index]['child'][e.key]['id_functional_type'] != 115 && structure[index]['child'][e.key]['id_functional_type'] != 116">
          <app-generic [structure]="structure[index]['child'][e.key]"></app-generic>
        </ng-container>

        <ng-container *ngIf="structure[index]['child'][e.key]['id_functional_type'] == 116">
          <mat-card-actions>
            <app-generic [structure]="structure[index]['child'][e.key]"></app-generic>
          </mat-card-actions>
        </ng-container>
      </ng-container>
    </ng-container>
  </mat-card>
</ng-container>