<div [ngClass]="{'header-hide':stephide}" class="notis0" [ngStyle]="{'background': background}">

  <div *ngIf="stepArray.length <= 1" style="margin: 0.3em;" class="each-noti" fxLayout="row wrap">
    <div fxFlex="100" class="conten-dialog" fxLayout="row wrap">
      <div *ngIf="!personalizado" fxFlex="100" fxLayout="row wrap">
        <span *ngFor="let c of stepArray[0].container; let i = index" fxFlex="100" style="width: 0px;">
          <span class="tittle-noti" *ngIf="c.idType == 1" [ngStyle]="{'color': c.color}">{{c.container}}</span>
          <i class="subtittle-noti" *ngIf="c.idType == 2" [ngStyle]="{'color':  c.color}">{{c.container}}</i>
          <p class="text-noti" *ngIf="c.idType == 3" [ngStyle]="{'color': c.color}">{{c.container}}</p>
          <img class="img-noti" *ngIf="c.idType == 4" [src]="c.container">
          <iframe *ngIf="c.idType == 5" width="560" height="315" [src]="c.container" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class="video-noti"></iframe>
          <!-- No funciona si src es dinámico --><iframe *ngIf="c.idType == 6" [src]="c.container" width="700" height="520" frameborder="0" marginheight="0" marginwidth="0" style="width: 100%; min-width: 350px;">S&#39;Loading…</iframe>
        </span>
      </div>
      <i style="font-size: 10px;" *ngIf="personalizado" [innerHTML]="mensaje | safeHtml"></i>
    </div>
    <span fxFlex="100" ngClass.sm="button-small-er" ngClass.xs="button-small-er" class="container-buttons">
      <ng-container *ngIf="stepArray[0].buttons[0] !== null && stepArray[0].buttons[0] !== undefined">
        <button fxFlex="30" fxFlex.sm="100" fxFlex.xs="100" *ngIf="stepArray[0].buttons[0]?.active" (click)="buttonAction(0, 0)"  matTooltip="{{stepArray[0].buttons[0]?.icon_tooltipe}}" [ngStyle]="{'background': stepArray[0].buttons[0]?.colorBackground, 'color': stepArray[0].buttons[0]?.colorText, 'border': '1px solid ' + stepArray[0].buttons[0]?.colorEdge}"><i *ngIf="stepArray[0].buttons[0]?.icon_active" [className]="'material-icons'" [ngStyle]="{'color': stepArray[0].buttons[0]?.icon_color}">{{stepArray[0].buttons[0]?.icon}}</i>{{stepArray[0].buttons[0]?.text}}</button>
      </ng-container>
      <ng-container *ngIf="stepArray[0].buttons[0] === null || stepArray[0].buttons[0] === undefined">
        <span fxFlex="30" fxFlex.sm="100" fxFlex.xs="100"></span>
      </ng-container>
      <ng-container *ngIf="stepArray[0].buttons[1] !== null && stepArray[0].buttons[1] !== undefined">
        <button fxFlex="30" fxFlex.sm="100" fxFlex.xs="100" *ngIf="stepArray[0].buttons[1]?.active" (click)="buttonAction(1, 0)"  matTooltip="{{stepArray[0].buttons[1]?.icon_tooltipe}}" [ngStyle]="{'background': stepArray[0].buttons[1]?.colorBackground, 'color': stepArray[0].buttons[1]?.colorText, 'border': '1px solid ' + stepArray[0].buttons[1]?.colorEdge}"><i *ngIf="stepArray[0].buttons[1]?.icon_active" [className]="'material-icons'" [ngStyle]="{'color': stepArray[0].buttons[1]?.icon_color}">{{stepArray[0].buttons[1]?.icon}}</i>{{stepArray[0].buttons[1]?.text}}</button>
      </ng-container>
      <ng-container *ngIf="stepArray[0].buttons[1] === null || stepArray[0].buttons[1] === undefined">
        <span fxFlex="30" fxFlex.sm="100" fxFlex.xs="100"></span>
      </ng-container>
      <ng-container *ngIf="stepArray[0].buttons[2] !== null && stepArray[0].buttons[2] !== undefined">
        <button fxFlex="30" fxFlex.sm="100" fxFlex.xs="100" *ngIf="stepArray[0].buttons[2]?.active" (click)="buttonAction(2, 0)"  matTooltip="{{stepArray[0].buttons[2]?.icon_tooltipe}}" [ngStyle]="{'background': stepArray[0].buttons[2]?.colorBackground, 'color': stepArray[0].buttons[2]?.colorText, 'border': '1px solid ' + stepArray[0].buttons[2]?.colorEdge}"><i *ngIf="stepArray[0].buttons[2]?.icon_active" [className]="'material-icons'" [ngStyle]="{'color': stepArray[0].buttons[2]?.icon_color}">{{stepArray[0].buttons[2]?.icon}}</i>{{stepArray[0].buttons[2]?.text}}</button>
      </ng-container>
      <ng-container *ngIf="stepArray[0].buttons[2] === null || stepArray[0].buttons[2] === undefined">
        <span fxFlex="30" fxFlex.sm="100" fxFlex.xs="100"></span>
      </ng-container>
    </span>
    <mat-icon *ngIf="close" (click)="closePopup()" class="removeit" [ngStyle]="{'color': colorClose}">close</mat-icon>
  </div>

  <div *ngIf="stepArray.length > 1" class="each-noti" style="margin: 0.3em;" fxLayout="row wrap">
    <div [ngClass]="{'header-step-out':onlyOneHeader}" fxFlex="100" fxLayout="row wrap">
      <mat-horizontal-stepper fxFlex="100" [linear]="true" #stepper>
      <mat-step *ngFor="let s of stepArray; let si = index" state="{{si}}">
        <ng-template matStepLabel>{{s.name}}</ng-template>
        <div fxFlex="100" fxLayout="row wrap">
          <div *ngIf="!personalizado || stepper.selectedIndex == si" fxFlex="100" fxLayout="row wrap">
            <span *ngFor="let c of s.container; let i = index" fxFlex="100" style="width: 0px;">
              <span class="tittle-noti" *ngIf="c.idType == 1" [ngStyle]="{'color': c.color}">{{c.container}}</span>
              <i class="subtittle-noti" *ngIf="c.idType == 2" [ngStyle]="{'color':  c.color}">{{c.container}}</i>
              <p class="text-noti" *ngIf="c.idType == 3" [ngStyle]="{'color': c.color}">{{c.container}}</p>
              <img class="img-noti" *ngIf="c.idType == 4" [src]="c.container">
              <iframe *ngIf="c.idType == 5" width="560" height="315" [src]="c.container" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class="video-noti"></iframe>
              <!-- No funciona si src es dinámico --><iframe *ngIf="c.idType == 6" [src]="c.container" width="700" height="520" frameborder="0" marginheight="0" marginwidth="0" style="width: 100%; min-width: 350px;">S&#39;Loading…</iframe>
            </span>
          </div>
          <i style="font-size: 10px;" *ngIf="personalizado" [innerHTML]="mensaje | safeHtml"></i>
        </div>
        <span fxFlex="100" fxLayout="row wrap" ngClass.sm="button-small-er" ngClass.xs="button-small-er" class="container-buttons">
          <ng-container *ngIf="s.buttons[0] !== null && s.buttons[0] !== undefined">
            <button fxFlex="30" fxFlex.sm="100" fxFlex.xs="100" *ngIf="s.buttons[0]?.active" (click)="buttonAction(0, si)"  matTooltip="{{s.buttons[0]?.icon_tooltipe}}" [ngStyle]="{'background': s.buttons[0]?.colorBackground, 'color': s.buttons[0]?.colorText, 'border': '1px solid ' + s.buttons[0]?.colorEdge}"><i *ngIf="s.buttons[0]?.icon_active" [className]="'material-icons'" [ngStyle]="{'color': s.buttons[0]?.icon_color}">{{s.buttons[0]?.icon}}</i>{{s.buttons[0]?.text}}</button>
          </ng-container>
          <ng-container *ngIf="s.buttons[0] === null || s.buttons[0] === undefined">
            <span fxFlex="30" fxFlex.sm="100" fxFlex.xs="100"></span>
          </ng-container>
          <ng-container *ngIf="s.buttons[1] !== null && s.buttons[1] !== undefined">
            <button fxFlex="30" fxFlex.sm="100" fxFlex.xs="100" *ngIf="s.buttons[1]?.active" (click)="buttonAction(1, si)"  matTooltip="{{s.buttons[1]?.icon_tooltipe}}" [ngStyle]="{'background': s.buttons[1]?.colorBackground, 'color': s.buttons[1]?.colorText, 'border': '1px solid ' + s.buttons[1]?.colorEdge}"><i *ngIf="s.buttons[1]?.icon_active" [className]="'material-icons'" [ngStyle]="{'color': s.buttons[1]?.icon_color}">{{s.buttons[1]?.icon}}</i>{{s.buttons[1]?.text}}</button>
          </ng-container>
          <ng-container *ngIf="s.buttons[1] === null || s.buttons[1] === undefined">
            <span fxFlex="30" fxFlex.sm="100" fxFlex.xs="100"></span>
          </ng-container>
          <ng-container *ngIf="s.buttons[2] !== null && s.buttons[2] !== undefined">
            <button fxFlex="30" fxFlex.sm="100" fxFlex.xs="100" *ngIf="s.buttons[2]?.active" (click)="buttonAction(2, si)"  matTooltip="{{s.buttons[2]?.icon_tooltipe}}" [ngStyle]="{'background': s.buttons[2]?.colorBackground, 'color': s.buttons[2]?.colorText, 'border': '1px solid ' + s.buttons[2]?.colorEdge}"><i *ngIf="s.buttons[2]?.icon_active" [className]="'material-icons'" [ngStyle]="{'color': s.buttons[2]?.icon_color}">{{s.buttons[2]?.icon}}</i>{{s.buttons[2]?.text}}</button>
          </ng-container>
          <ng-container *ngIf="s.buttons[2] === null || s.buttons[2] === undefined">
            <span fxFlex="30" fxFlex.sm="100" fxFlex.xs="100"></span>
          </ng-container>
        </span>
      </mat-step>

      <ng-template *ngFor="let s of stepArray; let si = index" matStepperIcon="{{si}}"><mat-icon>{{s.icon[0].icon}}</mat-icon></ng-template>

    </mat-horizontal-stepper>
      <mat-icon *ngIf="close" (click)="closePopup()" class="removeit" [ngStyle]="{'color': colorClose}">close</mat-icon>
    </div>
  </div>
</div>
