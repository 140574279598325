import {EventConditionBody} from './workflow-event-condition-body';

export class ModulConditionContainer {
  public conditionModulo: number;
  public containerConditions: Array<EventConditionBody>;
  public stringFieldsInConditions = [];
  public dateFieldsInConditions = [];
  public numberFieldsInConditions = [];

  constructor(modulo: number) {
    this.conditionModulo = modulo;
    this.containerConditions = [];
    this.stringFieldsInConditions  = [];
    this.dateFieldsInConditions  = [];
    this.numberFieldsInConditions  = [];
  }
}
