import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'app/services/auth/auth.service';
import { GenericService } from "app/services/generic/generic.service";
import { RoutingService } from "app/services/routing/routing.service";
import { Location } from '@angular/common';

@Component({
  selector: 'app-rrss-home-custom',
  templateUrl: './rrss-home-custom.component.html',
  styleUrls: ['./rrss-home-custom.component.scss']
})
export class SocialMediaCustomComponent implements OnInit {

  @Output() language: EventEmitter<number> = new EventEmitter();

  // SOCIALS
  public facebookImg;
  public instagramImg;
  public twitterImg;
  public youtubeImg;
  public linkedinImg;

  constructor(public translate: TranslateService,
              public authService: AuthService,
              public genericService: GenericService,
              public routingService: RoutingService,
              private location: Location) { }

  ngOnInit(): void {
    console.log('CUSTOM' , this.authService.arrayLanguages);
    this.translate.get('web').subscribe((translations) => {
      if (this.genericService.webpSupported) {
        this.facebookImg = translations.socials.facebookImgWebm;
        this.instagramImg = translations.socials.instagramImgWebm;
        this.twitterImg = translations.socials.twitterImgWebm;
        this.youtubeImg = translations.socials.youtubeImgWebm;
        this.linkedinImg = translations.socials.linkedinImgWebm;
      } else {
        this.facebookImg = translations.socials.facebookImgPng;
        this.instagramImg = translations.socials.instagramImgPng;
        this.twitterImg = translations.socials.twitterImgPng;
        this.youtubeImg = translations.socials.youtubeImgPng;
        this.linkedinImg = translations.socials.linkedinImgPng;
      }
    });
  }


  switchLang(lang: string) {
    this.translate.use(lang);
    this.language.emit();
    console.log(this.routingService.url);
    let actualURL = this.routingService.url.split(this.authService.labelLanguage + "/")[1];
    let actualLabel = "";
    console.log("hello");
    for(let x in this.authService.arrayLanguages) {
      if(this.authService.arrayLanguages[x]['id_language'] == lang) {
        actualLabel = this.authService.arrayLanguages[x]['label'];
        this.authService.languageId = this.authService.arrayLanguages[x]['id_language'];
        localStorage.setItem("_ul_", this.authService.arrayLanguages[x]['id_language']);
      }
    }
    actualURL = actualLabel + "/" + actualURL;
    this.location.replaceState(actualURL);
    this.genericService.refreshStructure(2)
  }
}
