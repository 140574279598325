<div class="notis0">
  <mat-horizontal-stepper class="general-stepper" [linear]="true" #stepper>
    <mat-step label="Carrito">
      <div style="display: flex; margin-top: 10px;" fxLayout="row wrap">
        <i class="material-icons">shopping_cart</i>
        <span style="font-weight: bolder; font-size: 18px; margin-left: 10px; text-transform: uppercase;">Carrito de la
          compra <i class="material-icons"
            matTooltip="El carrito de la compra se ha generado con los productos que has intentado comprar a pesar de no haber terminado la compra"
            style="cursor: help;">help_outlined</i></span>
        <mat-divider fxFlex="100" style="margin-bottom: 0.5em;"></mat-divider>
      </div>

      <div style="display: flex; overflow: auto;" fxLayout="row wrap">
        <div *ngFor="let c of cartArray; let i = index;" fxFlex="100" fxLayout="row wrap"
          style="justify-content: space-between; margin-top: 1em; margin-bottom: 0.5em">
          <div fxFlex="46" style="text-transform: uppercase; font-weight: 700;"
            [ngStyle]="{'color': (c.id_product_type === 1 ? 'rgb(var(--secondary-color))' : '#fff')}">
            <i class="material-icons" matTooltip="Quitar del carrito" (click)="eliminar(c)"
              id="deleter">remove_circle</i>
            {{c.name}} <i class="material-icons" matTooltip="{{c.description}}"
              style="cursor: help; font-size: 18px;">info</i>
          </div>
          <div fxFlex="27" style="text-align: center;">
            <span *ngIf="c.id_product_type === 1" style="text-transform: uppercase; font-weight: 500;">Producto</span>
            <span *ngIf="c.id_product_type === 2" style="text-transform: uppercase; font-weight: 500;">Widget</span>
          </div>
          <div fxFlex="27" fxLayout="row wrap" style="text-align: end;">
            <div fxFlex="100" *ngIf="c.precio < c.price_base" style="text-decoration: line-through; color: red">
              {{c.price_base}} MOVS</div>
            <div fxFlex="100" *ngIf="c.price_discount && c.price_discount != 0" style="color: grey">Descuento:
              -{{c.price_discount}} MOVS</div>
            <div fxFlex="100" *ngIf="c.price_discount_perc && c.price_discount_perc != 0" style="color: grey">Descuento:
              -{{c.price_discount_perc}}%</div>
            <div fxFlex="100">{{c.precio}} MOVS</div>
          </div>
        </div>
        <i *ngIf="cartArray.length <= 0" style="text-align: center">Aún no tienes productos en el carrito</i>
      </div>

      <div style="display: flex;" fxLayout="row wrap" *ngIf="cartArray.length > 0">
        <mat-divider fxFlex="100" style="margin-bottom: 0.5em;"></mat-divider>

        <div fxFlex="100" fxLayout="row wrap"
          style="justify-content: space-between; color: rgb(var(--secondary-color)); font-size: 16px; margin-top: 0.5em;">
          <div fxFlex="73" fxLayout="row wrap"> </div>
          <div fxFlex="27" fxLayout="row wrap" fxLayoutAlign="end center">
            <mat-form-field class="input_descuento" fxFlex="100" style="margin: 0 !important;">
              <mat-label>Descuento</mat-label>
              <input matInput type="text" [(ngModel)]="voucherCode">
              <button matSuffix *ngIf="voucherCode != null && voucherCode != ''" class="button-general"
                style="margin: 0;" (click)="applyVoucherCode()"><span class="material-icons">check_circle_outline</span></button>
            </mat-form-field>
          </div>
        </div>

        <div fxFlex="100" fxLayout="row wrap"
          style="justify-content: space-between; color: rgb(var(--secondary-color)); font-size: 16px; margin-top: 0.5em;">
          <div fxFlex="46" fxLayout="row wrap"></div>
          <div fxFlex="27" fxLayout="row wrap" fxLayoutAlign="center center">
            <div style="font-weight: bold; text-transform: uppercase; text-align: center;">Total</div>
          </div>
          <div fxFlex="27" fxLayout="row wrap" fxLayoutAlign="end center">
            <div style="text-align: right; font-weight: bold;">{{totalCarro}} MOVS</div>
          </div>
        </div>

      </div>

      <div fxFlex="100" fxLayout="row wrap" style="justify-content: space-between; margin-top: 1em">
        <button class="button-general" (click)="cancelar()">Cancelar</button>
        <button class="button-general" (click)="comprar()" *ngIf="cartArray.length > 0">Finalizar compra</button>
      </div>
    </mat-step>
    <mat-step label="Comprar">
      <add-movin-coin (sendEvent)="openStripe($event)" [type]="1" [minMovins]="totalCarro - movins"></add-movin-coin>
      <app-stripe-payment #stripePayment (sendBuyEvent)="save($event)"></app-stripe-payment>
    </mat-step>
  </mat-horizontal-stepper>
</div>