import { Component, Input, OnInit } from '@angular/core';
import { Values } from "../../../values/values";

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit {

  @Input() type: string;
  @Input() landing: boolean;
  public elements: any;

  constructor() { }

  ngOnInit(): void {
    this.initialize();
  }

  public initialize(): void {
    if (this.landing === false) {
      switch (this.type) {
        case 'marketplace': {
          this.elements = Values.MARKETPLACE_TYPES;
          break;
        }
        case 'knowledgeplace': {
          this.elements = Values.KNOWLEDGE_TYPES2;
          break;
        }
      }
    } else {
      switch (this.type) {
        case 'knowledgeplace': {
          this.elements = Values.KNOWLEDGE_TYPES_LANDING;
          break;
        }
      }
    }
  }
}
