import { Pipe, PipeTransform } from '@angular/core';
import {RoomChat} from "./models/room-chat";
import {GroupRoomChat} from "./models/group-room-chat";
import {InmoGroupRoom} from "./models/inmo-group-room";

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], searchText: string, opType: number): any[] {
    if(!items) return [];
    if(!searchText) return items;
    searchText = searchText.toLowerCase();
    switch (opType) {
      case 1:
        // NOTA IBRA: Este caso es poco eficiente
        return Array.from(items.values()).filter(item => {
          if (item?.value?.roomTypeId === 1) {
            return (item?.value?.usersMap.values().next().value?.nombre?.toLowerCase().includes(searchText) ||
              item?.value?.usersMap.values().next().value?.nombre_inmo?.toLowerCase().includes(searchText));
          }
          else if (item?.value?.roomTypeId === 2) {
            return (item?.value?.groupName?.toLowerCase().includes(searchText));
          }
        });
      case 2:
        return items.filter( it => {
          return (it?.name?.toLowerCase().includes(searchText) ||
            it?.surname?.toLowerCase().includes(searchText) ||
            it?.inmoName?.toLowerCase().includes(searchText));
        });
    }

  }

  findRooms(roomMap, userId, opType) {
    console.log(roomMap);
    switch (opType) {
      case 1:
        return Array.from(roomMap.values()).filter((item: RoomChat | GroupRoomChat | InmoGroupRoom) => item.roomTypeId === 1);
    }
  }
}
