import { Strings } from 'strings/strings';

export class EventType {
	id: number;
	name: string;
	color: string;
	// categoryId: number;
	parentId: number;
	active: number;
	isPrivate: number;
	firmar: number;
  control_calendario: number;
  visible: number;
  value: boolean;

	constructor(id?: number, name?: string, color?: string, parentId?: number, active?: number, isPrivate?: number, firmar?: number, control_calendario?: number, visible?: number, value?: boolean) {
		this.id = id || null;
		this.name = name || '';
		this.color = color || '000000';
		// this.categoryId = categoryId || -1;
		this.parentId = parentId || null;
		this.active = active || 1;
		this.isPrivate = isPrivate || 0;
		this.firmar = firmar || 0;
		this.control_calendario = control_calendario || 0;
		this.visible = firmar || 1;
		this.value = true;
	}
}

export interface CategoryType {
	id: number,
	name: string,
  visible: number,
	types: EventType [];
}

export let categoryTypes: CategoryType [] = [];

export let defaultColors: string[] = [
		'#ffffff',
		'#000105',
		'#3e6158',
		'#3f7a89',
		'#96c582',
		'#b7d5c4',
		'#bcd6e7',
		'#7c90c1',
		'#9d8594',
		'#dad0d8',
		'#4b4fce',
		'#4e0a77',
		'#a367b5',
		'#ee3e6d',
		'#d63d62',
		'#c6a670',
		'#f46600',
		'#cf0500',
		'#efabbd',
		'#8e0622',
		'#f0b89a',
		'#f0ca68',
		'#62382f',
		'#c97545',
		'#c1800b'
	];

export function setCategories(data) {
	let tempArray: CategoryType [] =[];
	Object.keys(data['response']).forEach(row => {
		let categoryId = data['response'][row]['category_id'];
		let categoryName = data['response'][row]['category'];
		let typeId = data['response'][row]['type_id'];
		let typeName = data['response'][row]['type'];
		let color = data['response'][row]['color'];
		let active = data['response'][row]['active'];
		let isPrivate = data['response'][row]['privada'];
		let firmar = data['response'][row]['firmar'];
		let control_calendario = data['response'][row]['control_calendario'];
    	let visible = data['response'][row]['visible'];
		let value = true;

		if (tempArray.filter(category => category.id == categoryId).length === 0)
			tempArray.push({ id: categoryId, name: categoryName, types: [{ id: typeId, name: typeName, color: color, parentId: categoryId, active: active,
          isPrivate: isPrivate, firmar: firmar, control_calendario: control_calendario, visible: visible, value: value }],  visible: visible });
		else {
			if (tempArray.filter(category => category.id == categoryId)[0].types.filter(type => type.id == typeId).length === 0)
				tempArray.filter(category => category.id == categoryId)[0].types.push({ id: typeId, name: typeName, color: color, parentId: categoryId,
          			active: active, isPrivate: isPrivate, firmar: firmar, control_calendario: control_calendario, visible: visible, value: value });
			else
				tempArray.filter(category => category.id == categoryId)[0].types.filter(type => type.id == typeId)[0].color = color;
		}
	});
	
	categoryTypes = tempArray;
}

export function addType(categoryId: number, typeName: string, color: string){

	let typeId = -1;
	let active = 1;
	let esPrivada = 0;
	let firmar = 0;
	let control_calendario = 0;
	let visible = 1;
	let value = true;
	if (categoryTypes.filter(category => category.id == categoryId)[0].types.filter(type => type.id == typeId).length === 0)
		categoryTypes.filter(category => category.id == categoryId)[0].types.push({ id: typeId, name: typeName, color: color, parentId: categoryId, active: active,
      isPrivate: esPrivada, firmar: firmar, control_calendario: control_calendario, visible: visible, value: value });
	else
		categoryTypes.filter(category => category.id == categoryId)[0].types.filter(type => type.id == typeId)[0].color = color;
}

export function modifyType(id: number, name: string, color: string){
	categoryTypes.forEach(obj => {obj.types.forEach(el => {if(el.id == id) {el.name = name; el.color = color;}})});
}

export function deleteType(id: number){
	categoryTypes = categoryTypes.filter(obj => {obj.types = obj.types.filter(el => el.id != id); return obj.types.length > 0;});
}
