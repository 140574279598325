<div mat-dialog-title>
  <p>¡Bienvenid@ a 4DMOVIN!</p>
</div>
<div mat-dialog-content>
  <div>
    <p>Como es la primera vez que accedes, indica tu contraseña de acceso.</p>
  </div>
  <form [formGroup]="formGroup" fxLayout="row warp">
    <mat-form-field fxFlex="50" appearance="outline">
      <input matInput formControlName="pass1" required #checker1 placeholder="{{Strings.NEW_PASS}}" [type]="password1 ? 'password' : 'text'">
      <button mat-icon-button matSuffix (click)="password1 = !password1" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="password1">
        <mat-icon>{{password1 ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field fxFlex="50" appearance="outline">
      <input matInput formControlName="pass2" required #checker2 placeholder="{{Strings.REPEAT_PASS}}" [type]="password2 ? 'password' : 'text'">
      <button mat-icon-button matSuffix (click)="password2 = !password2" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="password2">
        <mat-icon>{{password2 ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
      <div *ngIf="formErrors.pass2" class="alert alert-danger">
        {{ formErrors.pass2 }}
      </div>
    </mat-form-field>
    <div fxFlex="100" fxLayout="row">
      <div class="generic-buttons-blue" style="margin-right: 10px;">
        <button mat-button (click)="closeModal()">Cerrar</button>
      </div>
      <div class="generic-buttons-blue-revert">
        <button mat-button (click)="submitNewPassword()">{{Strings.CHANGE_PASSWORD}}</button>
      </div>
    </div>
  </form>
</div>
