<div class="inmo-card" fxLayout="row wrap">
  <span fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start">
    <div fxFlex="25" class="inmo-image" (click)="toPerfil(inmo)">
      <img class="inmo-logo" [src]="inmo.logo_cuadrado">
      <span *ngIf="isBasic === true || isSilver === true || isGolden === true" class="material-icons positioning-icon-2">verified</span>
      <span *ngIf="isBasic === true" matTooltip="Beginner" class="material-icons positioning-icon" style="color: #ff4646 !important">verified</span>
      <span *ngIf="isSilver === true" matTooltip="Standard" class="material-icons positioning-icon" style="color: rgb(var(--secondary-color)) !important">verified</span>
      <span *ngIf="isGolden === true" matTooltip="Expert" class="material-icons positioning-icon" style="color: #71b5ff !important">verified</span>
    </div>
    <div fxFlex="45" fxLayoutAlign="start start" fxLayout="row wrap" class="inmo-description" (click)="toPerfil(inmo)">
      <span fxFlex="100" class="titulo-inmo">{{inmo.nombre}}</span>
      <span class="category" *ngIf="esMLS === 0" fxFlex="100">Inmobiliaria</span>
      <span class="category" *ngIf="esMLS === 1" fxFlex="100">{{inmo.tipo_grupo}}</span>
      <span fxFlex="100" class="slogan-inmo">{{inmo.slogan}}</span>
    </div>
    <div fxFlex="30" class="inmo-buttons" fxLayout="row wrap" style="margin: 15px 0 0;">
      <button fxFlex="100" *ngIf="showEtiqueta1" class="button-registry" (click)="ejecutarAccion(accion1)">
        <span *ngIf="etiquetasFinished">{{etiqueta1}}</span>
        <mat-spinner style="margin:0 auto;" diameter="20" *ngIf="!etiquetasFinished"></mat-spinner>
      </button>

      <button *ngIf="inmo.followId === null && showFollowButton" fxFlex="100" class="button-registry" (click)="follow()">Seguir</button>
      <button *ngIf="inmo.followId !== null && showFollowButton" fxFlex="100" class="button-registry" (click)="unfollow()">Siguiendo</button>

      <button fxFlex="100" *ngIf="showEtiqueta2 && accion2 === 13" class="button-registry" (click)="ejecutarAccion(accion2)">Rechazar</button>
      <!-- Mensaje OG
      <button [disabled]="enviarMensajeBloqueado" [ngClass]="{'button-registry': true, 'button-registry-bloqued': enviarMensajeBloqueado}"
              fxFlex="100" *ngIf="showEtiqueta2 && accion2 !== 13" (click)="enviarMensaje(accion2)"><span *ngIf="enviarMensajeBloqueado" class="material-icons">lock</span> Mensaje
      </button>
      -->
      <button [ngClass]="{'button-registry': true}"
              fxFlex="100" *ngIf="showEtiqueta2 && accion2 !== 13 && showMessageButton" (click)="enviarMensaje(accion2)"> Mensaje
      </button>
    </div>
  </span>
  <span class="category2" *ngIf="showEtiqueta3" fxFlex="100">{{etiqueta3}}</span>
</div>
<auto-plantilla #AutoPlantillaComponent></auto-plantilla>

