import {ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CustomEvent } from '../../../../../activities/objects/custom-event.component'
import { categoryTypes, EventType, deleteType, setCategories } from '../../../../../activities/objects/category'
import { EndpointService } from '../../../../../services/endpoint/endpoint.service';
import { AuthService } from '../../../../../services/auth/auth.service';
import { DeleteConfirmDialog } from '../../../../../utils/delete-confirm-dialog/delete-confirm-dialog';
import { Strings } from 'strings/strings';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';

import { FunctionsService } from 'app/services/functions/functions.service';
import { ColorPickerDialog } from 'app/activities/subcomponents/color-picker-dialog/color-picker-dialog';

@Component({
	selector: 'manage-categories-dialog',
	templateUrl: 'manage-categories-dialog.html',
  styleUrls: ['./manage-categories-dialog.scss']
})

export class ManageCategoriesDialog implements OnInit { // si continua com un bottomSheet li hauriem de canvair el nom

	public strings = Strings.CALENDAR;

	selectedType: EventType;

	allCategories: EventType[];

	filterSelectedCategories: { id: number, value: boolean }[];
  valor = true;
	checkAll: boolean;
	foreignCategories: boolean;
  categoriesToDelete : {profileId: number, typeId: number}[];

  constructor(
		// public dialogRef: MatDialogRef<ManageCategoriesDialog>, antic de quan era un dialog
    private _bottomSheetRef: MatBottomSheetRef<ManageCategoriesDialog>,
    public router: Router,
		public dialog: MatDialog,
    public snackBar: MatSnackBar,
		public endpointService: EndpointService,
		public authService: AuthService,
		private _changeDetectorRef: ChangeDetectorRef,
		// @Inject(MAT_DIALOG_DATA) public data: { id: number, value: boolean }[], antic de quan era un dialog
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { id: number, value: boolean }[]) {
    
    this.foreignCategories = (localStorage.getItem('event_of_foreign_category_selected_') ? true : false);
    this.filterSelectedCategories = data;
    this.checkIfAllChecked();

    this.allCategories = new Array();
    // Joan
    //categoryTypes.forEach(ct => ct.types.forEach(el => this.allCategories.push(el)));
    categoryTypes.forEach(ct => {
      ct.types.forEach(el => {
        let i = this.filterSelectedCategories.findIndex(c => c.id == el.id);
        if (i >= 0) {
          el.value = this.filterSelectedCategories[i].value;
        }
        else {
          el.value = false;
        }
        this.allCategories.push(el);
      });
    });
  }

	ngOnInit(): void {
    this._bottomSheetRef.backdropClick().subscribe(e => {
      this.onClose(false);
    });

    this.categoriesToDelete = [];

    // this.chek_uncheck_all(true);

    // Joan
    //this.filterSelectedCategories = this.data;
    // Joan
    /*
    this.endpointService.getCategories(this.authService.getLoggedInUserId(), this.authService.returnActiveAccountId(), this.authService.returnActiveAccountType(), this.authService.isOrphan).subscribe(data => {
      setCategories(data);
    });
    */
    console.log("Category dialog: allCategories, filterSelectedCategories & categoryTypes", this.allCategories, this.filterSelectedCategories, categoryTypes);
	}

  onClose(applyFilter = false): void {
    if (applyFilter) {
      // Esborrar de la BD les categories que s'hagin esborrat
      for (let i = 0; i < this.categoriesToDelete.length; i++) {
        this.endpointService.deleteCategoryType(this.categoriesToDelete[i].profileId, this.categoriesToDelete[i].typeId).subscribe(data => {});
        this.endpointService.updateCalendarActivityCategory(1, this.categoriesToDelete[i].typeId).subscribe(data => {});
      }

      // Actualitzar localStorage
      let tmpFilterNotSelectedCategories : number[] = [];
      this.filterSelectedCategories.forEach(c => {
        if (!c.value) {
          tmpFilterNotSelectedCategories.push(c.id);
        }
      });
      localStorage.setItem('event_not_selected_category', JSON.stringify(tmpFilterNotSelectedCategories));

      if (this.foreignCategories) {
        localStorage.setItem('event_of_foreign_category_selected_', 'true');
      }
      else {
        localStorage.removeItem('event_of_foreign_category_selected_');
      }
    }

    this._bottomSheetRef.dismiss(applyFilter);
  }

  checkIfAllChecked() {
    // Joan
    //this.checkAll = (this.data.filter(d => d.value).length == this.data.length) && this.foreignCategories;
    this.checkAll = (this.filterSelectedCategories.filter(d => d.value).length == this.filterSelectedCategories.length) && this.foreignCategories;
  }

  changeStatus(id: number, event: boolean, i) {
    // Joan: Ho moc al sortir de la pantalla
    /*
    if (event) {
      localStorage.removeItem('event_category_selected_' + id);
    } else {
      localStorage.setItem('event_category_selected_' + id, 'false');
    }
    */

    //this.allCategories[i].value = !this.allCategories[i].value;
    this.allCategories[i].value = event;
    this.filterSelectedCategories.filter(c => c.id == id).forEach(c => {
        c.value = event;
    });

    this.checkIfAllChecked();
  }

  changeForeignEvents(event: boolean) {
    // Joan: Ho moc al sortir de la pantalla
    /*
	  if (event) {
      localStorage.setItem('event_of_foreign_category_selected_', 'true');
    } else {
      localStorage.removeItem('event_of_foreign_category_selected_');
    }
    */

    this.checkIfAllChecked();
  }

  check_uncheck_all(event: boolean) {
    this.checkAll = event;

    this.allCategories.forEach(c => {
      c.value = event;

      // Joan: Ho moc al sortir de la pantalla
      /*
      if (event) localStorage.removeItem('event_category_selected_' + c.id);
      else localStorage.setItem('event_category_selected_' + c.id, 'false');
      */
    });

    this.filterSelectedCategories.forEach(c => {
      c.value = event;
    });

    // Joan: Ho moc al sortir de la pantalla
    /*
    if (event) localStorage.setItem('event_of_foreign_category_selected_', 'true');
    else localStorage.removeItem('event_of_foreign_category_selected_');
    */
    this.foreignCategories = event;
  }

  clearFilters() {
    this.allCategories.forEach(category => {
      category.value = false;
      this.filterSelectedCategories.forEach(c => c.value = false);
      // Joan: Ho moc al sortir de la pantalla
      //localStorage.removeItem('event_category_selected_' + category.id);
    })
  }

  onSelect(type: EventType): void {
    this.selectedType = type;
  }

  get categoryTypes() {
    const categories = categoryTypes.filter(obj => {
      obj.types = obj.types.filter(el => el.active == 1 && el.visible == 1);
      return obj.types.length > 0;
    });
    return categories;
  }

  openColorPickerDialog(type: EventType): void {
    console.log('openColorPickerDialog');
    const dialogRef = this.dialog.open(ColorPickerDialog, {
      width: 'auto',
      height: 'auto',
      data: type,
      autoFocus: true
    });

    dialogRef.afterClosed().subscribe(result => {
      // type is null => add new category
      if (type == null && result != null) {
        // Se ha añadido
        const name = result['name'];
        const color = result['color'];
        const isPrivate = result['isPrivate'];
        const signable = result['signable'];
        const portalVisitCompatibility = result['portalVisitCompatibility'];

        const profileId = this.authService.getLoggedInUserId();
        this.endpointService.addNewCategoryType({ name: name, color: color,
          isPrivate: isPrivate, signable: signable, portalVisitCompatibility: portalVisitCompatibility}, profileId,  this.authService.returnActiveAccountId(), this.authService.returnActiveAccountType()).subscribe(data => {

          let body = {name: name, color: color, isPrivate: isPrivate, firmar: signable,
            parentId: 3, id: data['response'], visible: 1, control_calendario: 0, active: 1, value: true}; // TODO: Fer dinamic
          this.allCategories.push(body);
          this.filterSelectedCategories.push({id: data['response'], value: true});
          this._changeDetectorRef.detectChanges();

          
          this.endpointService.getCategories(profileId, this.authService.returnActiveAccountId(),
            this.authService.returnActiveAccountType(), this.authService.isOrphan).subscribe(data_cat => {
            setCategories(data_cat);
            this.snackBar.open('Categoría añadida', 'X', {
              duration: 6000,
              panelClass: ['lightgreen-snackbar']
            });
          });
        });
      }
      else if (type != null && result != null) {
        // Se ha actualizado
        const name = result['name'];
        const color = result['color'];
        const isPrivate = result['isPrivate'];
        const signable = result['signable'];
        const portalVisitCompatibility = result['portalVisitCompatibility'];

        const typeId = type.id;
        const profileId = this.authService.getLoggedInUserId();
        this.endpointService.updateCategoryType({ name: name, color: color, isPrivate: isPrivate, signable: signable, portalVisitCompatibility: portalVisitCompatibility}, profileId, typeId).subscribe(data => { });
        this.endpointService.getCategories(profileId, this.authService.returnActiveAccountId(), this.authService.returnActiveAccountType(), this.authService.isOrphan).subscribe(data => {
          // Actualizo las varibales para front-end
          let index = this.allCategories.findIndex(c=> c.id == result.id);
          this.allCategories[index]['color'] = result['color'];
          this.allCategories[index]['isPrivate'] = result['isPrivate'];
          this.allCategories[index]['name'] = result['name'];
          this.allCategories[index]['firmar'] = result['signable'];
          this.allCategories[index]['value'] = true; // TODO: Cal agafar-ho del local storage
          this._changeDetectorRef.detectChanges();
          setCategories(data);
        });
      }
    });
  }

  openDeleteConfirmEventDialog(): void {
    const dialogRef = this.dialog.open(DeleteConfirmDialog, {
      width: 'auto',
      height: 'auto',
      data: this.strings.DELETE_ACTIVITY_DIALOG_MESSAGE
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const typeId = this.selectedType.id;
        const profileId = this.authService.getLoggedInUserId();
        // Joan: Ho moc al sortir de la pantalla
        //this.endpointService.deleteCategoryType(profileId, typeId).subscribe(data => { });
        this.categoriesToDelete.push({profileId: profileId, typeId: typeId});
        this.snackBar.open('Categoría eliminada', 'X', {
          duration: 6000, panelClass: ['red-snackbar']
        });
        deleteType(typeId);
        this.allCategories = this.allCategories.filter(category => category.id !== this.selectedType.id);
        this._changeDetectorRef.detectChanges();
        // this.updateEventsCategoryDeleted(typeId);
        // Joan: Ho moc al sortir de la pantalla
        //this.endpointService.updateCalendarActivityCategory(1, typeId).subscribe(data => { });
      }
      // this._changeDetectorRef.detectChanges();
    });
  }

  updateEventsCategoryDeleted(category: number) {
    let tempArray: CustomEvent[] = [];
    this.endpointService.getCalendarActivitiesByCategories(this.authService.userId, category).subscribe(data => {
      Object.keys(data['response']).forEach(row => {
        const id = data['response'][row]['id'];
        const comments = data['response'][row]['comments'];
        const creatorId = data['response'][row]['creator_id'];
        const title = data['response'][row]['title'];
        const startDate = ((data['response'][row]['start_date']) == null) ? null : new Date((data['response'][row]['start_date']).replace(' ', 'T') + 'Z');
        const endDate = ((data['response'][row]['end_date']) == null) ? null : new Date((data['response'][row]['end_date']).replace(' ', 'T') + 'Z');
        const idCalendarioGoogle = data['response'][row]['id_calendario_google'];
        const typeId = data['response'][row]['type_id'];
        const typeName = data['response'][row]['type_name'];
        const typeColor = data['response'][row]['type_color'];
        const signLike = data['response'][row]['firma_gusta'];
        const signNotify = data['response'][row]['firma_recibir_alertas'];
        const signDNI = data['response'][row]['firma_DNI'];
        const signImgURL = data['response'][row]['firma_img_url'];
        const signObservation = data['response'][row]['firma_observaciones'];
        const allDay = data['response'][row]['dia_completo'];
        // let property = null;
        // let client = null;
        let type: EventType = new EventType();

        if (typeId !== null && typeName !== null && typeColor !== null) {
          type = new EventType(typeId, typeName, typeColor);
        }
        const tempEvent = new CustomEvent(title, comments, id, creatorId, startDate, endDate, idCalendarioGoogle, type, signLike, signNotify, signDNI, signImgURL, signObservation, allDay);
        tempArray = [
          ...tempArray, tempEvent
        ];
      });
    });
  }
}