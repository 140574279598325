import {ChangeDetectorRef, Component, Inject,  OnInit, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CustomEvent } from '../../../../../activities/objects/custom-event.component'
import { setCategories } from '../../../../../activities/objects/category'
import { UntypedFormControl, Validators } from '@angular/forms';
import { EndpointService } from '../../../../../services/endpoint/endpoint.service';
import { AuthService } from '../../../../../services/auth/auth.service';
import { ActivitiesService } from '../../../../../services/activities/activities.service';
import { DeleteConfirmDialog } from '../../../../../utils/delete-confirm-dialog/delete-confirm-dialog';
import { Strings } from 'strings/strings';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import { FunctionsService } from 'app/services/functions/functions.service';


@Component({
	selector: 'all-events-list-dialog',
	templateUrl: 'all-events-list-dialog.html',
	styleUrls: ['./all-events-list-dialog.scss']
})

export class AllEventsListDialog implements OnInit { //si el mantenim com a bottomSheet li hauriem de canviar el nom

  displayedColumns: string[] = ['edit', 'delete', 'sign', 'title', 'category', 'date'];

  public strings = Strings.CALENDAR;
  public options = { year: 'numeric', month: 'numeric', day: 'numeric' };

	selectedEvent: CustomEvent;
	customEvents: CustomEvent[];
	filteredEvents: CustomEvent[];

	tableColumns: string[] = ['', '', 'Título', 'Categoría', 'Inicio', 'Fin'];

	startDate: UntypedFormControl;
	endDate: UntypedFormControl;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public dataSource: MatTableDataSource<any>;
  public loaded = false;
  public inputFilter = '';
  public showFirma: boolean = (this.activitiesService.controlsItemsActivities[7130] !== undefined && this.activitiesService.controlsItemsActivities[7130]['id_functional_status_mkt'] === 1);
	constructor(
		//public dialogRef: MatDialogRef<AllEventsListDialog>, old
    private _bottomSheetRef: MatBottomSheetRef<AllEventsListDialog>,
    public router: Router,
		public dialog: MatDialog,
    public _changeDetectorRef: ChangeDetectorRef,
		@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
		public endpointService: EndpointService,
		public authService: AuthService,
    public activitiesService: ActivitiesService,
    public functionsService: FunctionsService) {

		this.customEvents = data.events;
    
    let date = new Date();
    this.startDate = new UntypedFormControl(new Date());
    date.setMonth(date.getMonth() + 3);
    this.endDate = new UntypedFormControl(date);
    this.filteredEvents = this.customEvents.filter(event => (event.start >= this.startDate.value && event.start <= this.endDate.value));

    this.startDate.valueChanges.subscribe(val => {
      this.filteredEvents = this.customEvents.filter(event => (event.start >= val && event.start <= this.endDate.value));
      this.setDataSourceTable(this.filteredEvents);
    });

		this.endDate.valueChanges.subscribe(val => {
			this.filteredEvents = this.customEvents.filter(event => (event.start >= this.startDate.value && event.start <= val));
			this.setDataSourceTable(this.filteredEvents);
		});
	}

	ngOnInit(): void {
		this.endpointService.getCategories(this.authService.getLoggedInUserId(), this.authService.returnActiveAccountId(), this.authService.returnActiveAccountType(), this.authService.isOrphan).subscribe(data => { setCategories(data) });
		this.setDataSourceTable(this.filteredEvents);
	}

	onNoClick(): void {
		//this.dialogRef.close(); old
		this._bottomSheetRef.dismiss();
	}

  setDataSourceTable(filteredEvents: CustomEvent[]) {
    let promise = new Promise<void>((resolve, reject) => {
      this.dataSource = new MatTableDataSource(filteredEvents);
      resolve();
    });
    promise.then(e => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.applyFilter(this.inputFilter);
      this.loaded = true;
    });
  }

  public applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

	onSelect(event: CustomEvent): void {
		this.selectedEvent = event;
	}

  public buttonSignClick(event: CustomEvent) {
    this.functionsService.addWindowParam(event['id'], 1, "actividades_calendario", "id", 89);
    let x = {id_function: 35, internal_routing_id_functional_area: 34400};
    this.functionsService.getFunction(x, null);
  }

  //called to sign an activity
  public buttonViewSign(event: CustomEvent) {
    this.functionsService.addWindowParam(event['id'], 1, "actividades_calendario", "id", 89);
    let x = {id_function: 5, internal_routing_id_functional_area: 34400};
    this.functionsService.getFunction(x, null);
  }

	openEditEventDialog(event: CustomEvent): void {
    this._bottomSheetRef.dismiss(event);
	}

	openDeleteConfirmEventDialog(event: CustomEvent): void {
		const dialogRef = this.dialog.open(DeleteConfirmDialog, {
			width: 'auto',
			height: 'auto',
			data: this.strings.DELETE_ACTIVITY_DIALOG_MESSAGE
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result && event.id !== null) {
				this.endpointService.deleteCalendarActivity(event.id).subscribe(data => { });
				//this.dialogRef.close(); old
        this._bottomSheetRef.dismiss();
      }
		});
	}

}
