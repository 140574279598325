export class UserChat {
  id: string;
  nombre: string;
  avatar: string;
  online: boolean;
  nombre_inmo: string;
  es_admin: boolean;
  permiso_lectura: boolean;
  permiso_escritura: boolean;

  constructor(id?: string, nombre?: string, avatar?: string, nombre_inmo?: string,  online?: boolean, es_admin?: boolean,
              permiso_lectura?: boolean, permiso_escritura?: boolean) {
    this.id = id || '';
    this.nombre = nombre || '';
    this.avatar = avatar || '';
    this.nombre_inmo = nombre_inmo || '';
    this.online = online || false;
    this.es_admin = es_admin || false;
    this.permiso_lectura = permiso_lectura || false;
    this.permiso_escritura = permiso_escritura || false;
  }
}
