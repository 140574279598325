import {Component, Input, OnInit} from '@angular/core';
import { AuthService } from 'app/services/auth/auth.service';
import { FunctionsService } from 'app/services/functions/functions.service';
import { GenericService } from 'app/services/generic/generic.service';
import { fi } from 'date-fns/locale';


@Component({
  selector: 'app-restaurant-pedido-actual-carta-custom',
  templateUrl: './restaurant-pedido-actual-carta-custom.component.html',
  styleUrls: ['./restaurant-pedido-actual-carta-custom.component.scss']
})
export class RestaurantPedidoActualCartaCustomComponent implements OnInit {

  public arrayProducts = [];
  public loaded = false;

  constructor(
    public authService: AuthService,
    public genericService: GenericService,
    public functionsService: FunctionsService,

  ) {}
  
  ngOnInit(): void {
    console.log("hello 00", this.authService.arrayProductos)
    
    for(let i in this.authService.arrayProductos) {
      let obj = {'image': "NULL", 'unit_price': 0.00, 'price': 0.00, 'name': "NULL", 'quantity': 0.00, 'comment': "NULL", 'measures':[], 'supplements':[], 'options':[], 'preparations':[]}
      for(let j in this.authService.arrayProductos[i]) {
        if(this.authService.arrayProductos[i][j]['field'] == "url") obj.image = this.authService.arrayProductos[i][j]['value'];
        if(this.authService.arrayProductos[i][j]['field'] == "importe") obj.price = this.authService.arrayProductos[i][j]['value'];
        if(this.authService.arrayProductos[i][j]['field'] == "nombre") obj.name = this.authService.arrayProductos[i][j]['value'];
        if(this.authService.arrayProductos[i][j]['field'] == "cantidad") obj.quantity = parseInt(this.authService.arrayProductos[i][j]['value']);
        if(this.authService.arrayProductos[i][j]['field'] == "comentario") obj.comment = this.authService.arrayProductos[i][j]['value'];
        if(this.authService.arrayProductos[i][j]['table'] == "rest_pedidos_productos_opciones_medidas") obj.measures = this.authService.arrayProductos[i][j]['value_label'];
        if(this.authService.arrayProductos[i][j]['table'] == "rest_pedidos_productos_opciones_suplementos") obj.supplements = this.authService.arrayProductos[i][j]['value_label'];
        if(this.authService.arrayProductos[i][j]['table'] == "rest_pedidos_productos_opciones_opciones") obj.options = this.authService.arrayProductos[i][j]['value_label'];
        if(this.authService.arrayProductos[i][j]['table'] == "rest_pedidos_productos_opciones_preparacion") obj.preparations = this.authService.arrayProductos[i][j]['value_label'];
        
      }
      // let variable = this.functionsService.cloneVariable(Math.round((obj.price/obj.quantity + Number.EPSILON) * 100) / 100);
      // console.log(variable, "VAAAAA");
      // let variable1 = this.functionsService.cloneVariable(obj.price);
      // console.log(variable1, "VAAAAA1");
      // let variable2 = this.functionsService.cloneVariable(obj.quantity);
      // console.log(variable2, "VAAAAA2");

      obj.unit_price = Math.round((obj.price/obj.quantity + Number.EPSILON) * 100) / 100;
      this.arrayProducts.push(obj);
    }

    console.log("hello", this.arrayProducts);
    this.loaded = true;
  }

  checkIfIsValue(database, table, field, e) {
//    if(e.table == table && e.database == database && e.field == field && field == "id_opcion") console.log("OP",e);
    return  (e.database == database && e.table == table && e.field == field);
  }

  decreaseQuantity(index) {
    console.log("holaGlobal", this.authService.arrayProductos[index]);
    console.log("holaLocal", this.arrayProducts[index]);
    this.authService.productsCounter -= 1;
    this.authService.productsPriceCounter = Math.round((this.authService.productsPriceCounter-this.arrayProducts[index].unit_price + Number.EPSILON) * 100) / 100;
    this.authService.productsPriceCounterLabel = (this.authService.productsPriceCounter).toString().replace(".", ",");
    if (this.arrayProducts[index].quantity == 1) {
      this.arrayProducts.splice(index, 1);
      this.authService.arrayProductos.splice(index, 1);
    }
    else {
      this.arrayProducts[index].quantity -= 1;
      let newPrice =  this.functionsService.cloneVariable(Math.round((this.arrayProducts[index].price-this.arrayProducts[index].unit_price + Number.EPSILON) * 100) / 100);
      // console.log("VAAAA3", newPrice);
      this.arrayProducts[index].price = newPrice;
      for(let j in this.authService.arrayProductos[index]) {
        if (this.authService.arrayProductos[index][j]['field'] == "cantidad") {
          this.authService.arrayProductos[index][j]['value'] -= 1;
        }
        else if (this.authService.arrayProductos[index][j]['field'] == "importe") {
          this.authService.arrayProductos[index][j]['value'] = newPrice;
        }
      }
    }

    localStorage.setItem("productsCounter", JSON.stringify(this.authService.productsCounter));
    localStorage.setItem("productsPriceCounter", JSON.stringify(this.authService.productsPriceCounter));
    localStorage.setItem("productsPriceCounterLabel", JSON.stringify(this.authService.productsPriceCounterLabel));
    localStorage.setItem("arrayProductos", JSON.stringify(this.authService.arrayProductos));
  }

  increaseQuantity(index) {
    console.log("hola", this.arrayProducts[index]);
    this.authService.productsCounter += 1;
    this.authService.productsPriceCounter = Math.round((this.authService.productsPriceCounter + this.arrayProducts[index].unit_price + Number.EPSILON) * 100) / 100;
    this.authService.productsPriceCounterLabel = (this.authService.productsPriceCounter).toString().replace(".", ",");
    this.arrayProducts[index].quantity = parseInt(this.arrayProducts[index].quantity)+1;
    let newPrice =  this.functionsService.cloneVariable(Math.round((this.arrayProducts[index].price+this.arrayProducts[index].unit_price + Number.EPSILON) * 100) / 100);
    this.arrayProducts[index].price = newPrice;
    for(let j in this.authService.arrayProductos[index]) {
      if (this.authService.arrayProductos[index][j]['field'] == "cantidad") {
        this.authService.arrayProductos[index][j]['value'] = parseInt(this.authService.arrayProductos[index][j]['value'])+1;
      }
      else if (this.authService.arrayProductos[index][j]['field'] == "importe") {

        this.authService.arrayProductos[index][j]['value'] = newPrice;
      }
    }

    localStorage.setItem("productsCounter", JSON.stringify(this.authService.productsCounter));
    localStorage.setItem("productsPriceCounter", JSON.stringify(this.authService.productsPriceCounter));
    localStorage.setItem("productsPriceCounterLabel", JSON.stringify(this.authService.productsPriceCounterLabel));
    localStorage.setItem("arrayProductos", JSON.stringify(this.authService.arrayProductos));
  }
}
