export class SimpleGroup {
  id: number;
  value: boolean;
  name: string;
  id_account: number;
  type: number;
  typeName: string;
  id_empresa: number;
  users: any;

  constructor(id?: number, value?: boolean, name?: string, id_account?: number, users?, type?: number, typeName?: string, id_empresa?: number) {
    this.id = id || -1;
    this.value = value || false;
    this.name = name || '';
    this.id_account = id_account || -1;
    this.type = type || -1;
    this.typeName = typeName || '';
    this.id_empresa = id_empresa || -1;
    this.users = users || [];
  }

  // Joan: Semla que només es cridava des d'un lloc -> Ho he canviat -> Ho comento
  /*
  setStatus() {
    let check = true;
    let i = 0;
    while (check && i<this.users.length) {
      if (localStorage.getItem('event_share_user_' + this.users[i]['id'])) { check = false; }
      i++;
    }
    this.value = check;
  }
  */

  // Joan: No es crida des de cap lloc - Ho comento
  /*
  setAccountStatus() {
    //REVISAAAAAR!!
    return new Promise((resolve, reject) => {
      let group = -1;
      if (localStorage.getItem('event_share_account_' + this.id_account)) {
        this.value = false;
        group = this.id;
      }
      resolve(group);
    });
  }
  */
}
