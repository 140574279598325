<ng-container *ngIf="loaded">
  <ng-container *ngFor="let e of structure[this.index]['child'] | keyvalue : genericService.returnZero">
    <span *ngIf="structure[this.index]['child'][e.key] && genericService.evaluateIfsParams(structure[this.index], e.key, this.idTable, this.idRow, this.param) && structure[this.index]['child'][e.key]['id_functional_status_general'] !== 2"
      [fxHide]="(structure[this.index]['child'][e.key]['hide']=='true' && structure[this.index]['child'][e.key]['hide'] != 0) || structure[this.index]['child'][e.key]['hide'] == 1"
      [fxHide.xs]="(structure[this.index]['child'][e.key]['hide']=='true' && structure[this.index]['child'][e.key]['hide'] != 0) || (structure[this.index]['child'][e.key]['hide_xs']=='true' && structure[this.index]['child'][e.key]['hide_xs'] != 0) || structure[this.index]['child'][e.key]['hide'] == 1 || structure[this.index]['child'][e.key]['hide_xs'] == 1"
      [fxHide.sm]="(structure[this.index]['child'][e.key]['hide']=='true' && structure[this.index]['child'][e.key]['hide'] != 0) || (structure[this.index]['child'][e.key]['hide_s']=='true' && structure[this.index]['child'][e.key]['hide_s'] != 0) || structure[this.index]['child'][e.key]['hide'] == 1 || structure[this.index]['child'][e.key]['hide_s'] == 1"
      [fxHide.md]="(structure[this.index]['child'][e.key]['hide']=='true' && structure[this.index]['child'][e.key]['hide'] != 0) || (structure[this.index]['child'][e.key]['hide_m']=='true' && structure[this.index]['child'][e.key]['hide_m'] != 0) || structure[this.index]['child'][e.key]['hide'] == 1 || structure[this.index]['child'][e.key]['hide_m'] == 1"
      [fxHide.lg]="(structure[this.index]['child'][e.key]['hide']=='true' && structure[this.index]['child'][e.key]['hide'] != 0) || (structure[this.index]['child'][e.key]['hide_l']=='true' && structure[this.index]['child'][e.key]['hide_l'] != 0) || structure[this.index]['child'][e.key]['hide'] == 1 || structure[this.index]['child'][e.key]['hide_l'] == 1"
      [fxHide.xl]="(structure[this.index]['child'][e.key]['hide']=='true' && structure[this.index]['child'][e.key]['hide'] != 0) || (structure[this.index]['child'][e.key]['hide_xl']=='true' && structure[this.index]['child'][e.key]['hide_xl'] != 0) || structure[this.index]['child'][e.key]['hide'] == 1 || structure[this.index]['child'][e.key]['hide_xl'] == 1">
      <ng-container *ngIf="!structure[this.index]['child'][e.key]['child'] || !structure[this.index]['child'][e.key]['child'][0] || structure[this.index]['child'][e.key]['child'][0]['id_functional_type'] != 13">
        <ng-container *ngIf="structure[this.index]['child'][e.key]['id_functional_type'] != 143">
          <button mat-menu-item (mouseenter)="openMenuTrigger(null, structure[this.index]['child'][e.key])" class="menu-button"
            [ngClass]="structure[this.index]['child'][e.key]['id_functional_status_mkt'] != 1 ? structure[this.index]['child'][e.key]['class_custom'] + ' ' + 'mkt-no-available' : genericService.highlightHeaderElements.includes(structure[this.index]['child'][e.key]['id_functional_area']) ? 'highlight' + ' ' + structure[this.index]['child'][e.key]['class_custom'] + ' ' + 'mkt-no-available' : structure[this.index]['child'][e.key]['class_custom'] "
            [ngStyle]="structure[this.index]['child'][e.key]['styleParsed']['style']"
            [matTooltip]="structure[this.index]['child'][e.key]['tooltip']"
            [id]="structure[this.index]['child'][e.key]['id_functional_area']"
            (click)="onClickEvent(this, e.key)">
              <i *ngIf="structure[this.index]['child'][e.key]['icon']" class="{{structure[this.index]['child'][e.key]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
              structure[this.index]['child'][e.key]['icon_type']=='Filled' ? 'material-icons' :
              structure[this.index]['child'][e.key]['icon_type'] =='Round' ? 'material-icons-round' :
              structure[this.index]['child'][e.key]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
              structure[this.index]['child'][e.key]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}"> {{ structure[this.index]['child'][e.key]['icon'] }} </i>
              <span [innerHTML]="this.structure[this.index]['child'][e.key]['label'] | safeHtml"></span>
          </button>
        </ng-container>
        <ng-container *ngIf="structure[this.index]['child'][e.key]['id_functional_type'] == 143">
          <button mat-menu-item (mouseenter)="openMenuTrigger(null, structure[this.index]['child'][e.key])" class="menu-button"
          [ngClass]="structure[this.index]['child'][e.key]['id_functional_status_mkt'] != 1 ? structure[this.index]['child'][e.key]['class_custom'] + ' ' + 'mkt-no-available' : genericService.highlightHeaderElements.includes(structure[this.index]['child'][e.key]['id_functional_area']) ? 'highlight' + ' ' + structure[this.index]['child'][e.key]['class_custom'] + ' ' + 'mkt-no-available' : structure[this.index]['child'][e.key]['class_custom'] "
          [ngStyle]="structure[this.index]['child'][e.key]['styleParsed']['style']"
          [matTooltip]="structure[this.index]['child'][e.key]['tooltip']"
          [id]="structure[this.index]['child'][e.key]['id_functional_area']"
          (click)="onClickEvent(this, e.key)">
            <i *ngIf="structure[this.index]['child'][e.key]['icon']" class="{{structure[this.index]['child'][e.key]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
            structure[this.index]['child'][e.key]['icon_type']=='Filled' ? 'material-icons' :
            structure[this.index]['child'][e.key]['icon_type'] =='Round' ? 'material-icons-round' :
            structure[this.index]['child'][e.key]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
            structure[this.index]['child'][e.key]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}"> {{ structure[this.index]['child'][e.key]['icon'] }} </i>
            <span [innerHTML]="this.structure[this.index]['child'][e.key]['label'] | safeHtml"></span> <span> {{ this.genericService.applicationVersion }}  ({{ this.genericService.applicationVersionDate }})</span>

        </button>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="structure[this.index]['child'][e.key]['child'] && structure[this.index]['child'][e.key]['child'][0] && structure[this.index]['child'][e.key]['child'][0]['id_functional_type'] == 13">
        <button mat-menu-item #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="submenu" class="menu-button"
          (click)="onClickMenu(e.key)" (mouseenter)="openMenuTrigger(menuTrigger, structure[this.index]['child'][e.key])"
          [ngClass]="structure[this.index]['child'][e.key]['id_functional_status_mkt'] != 1 ? structure[this.index]['child'][e.key]['class_custom'] + ' ' + 'mkt-no-available' : genericService.highlightHeaderElements.includes(structure[this.index]['child'][e.key]['id_functional_area']) ? 'highlight' + ' ' + structure[this.index]['child'][e.key]['class_custom'] + ' ' + 'mkt-no-available' : structure[this.index]['child'][e.key]['class_custom']"
          [ngStyle]="structure[this.index]['child'][e.key]['styleParsed']['style']"
          [matTooltip]="structure[this.index]['child'][e.key]['tooltip']"
          [id]="structure[this.index]['child'][e.key]['id_functional_area']">
            <i *ngIf="structure[this.index]['child'][e.key]['icon']" class="{{structure[this.index]['child'][e.key]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
            structure[this.index]['child'][e.key]['icon_type']=='Filled' ? 'material-icons' :
            structure[this.index]['child'][e.key]['icon_type'] =='Round' ? 'material-icons-round' :
            structure[this.index]['child'][e.key]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
            structure[this.index]['child'][e.key]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}"> {{ structure[this.index]['child'][e.key]['icon'] }} </i>
            <span [innerHTML]="this.structure[this.index]['child'][e.key]['label'] | safeHtml"></span>
        </button>
  
        <mat-menu #submenu="matMenu">
          <span>
            <app-generic [structure]="structure[this.index]['child'][e.key]" [param]="param"></app-generic>
          </span>
        </mat-menu>
      </ng-container>

    </span>
  </ng-container>
</ng-container>