import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { Router } from "@angular/router";
import { Values } from "../../../values/values";
import { AuthService } from "../../services/auth/auth.service";
import { MessageDialogComponent } from "../../utils/message-dialog/message-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { ColaborationBody } from "./colaboration-body";
import { EndpointService } from "../../services/endpoint/endpoint.service";
import { GenericService } from "../../services/generic/generic.service";
import { LocalStorageService } from "../../services/local-storage.service";
import { BodyGroup } from "../../inmo-group/body/body-group";
import { AutoPlantillaComponent} from "../../../app/auto-plantilla/auto-plantilla.component";

@Component({
  selector: 'app-community-administrator',
  templateUrl: './community-administrator.component.html',
  styleUrls: ['./community-administrator.component.scss']
})
export class CommunityAdministratorComponent implements OnInit {

  @Input() isInmo: boolean; // Indica si estoy visitando ajustes de un grupo o una inmo
  @ViewChild('AutoPlantillaComponent') public AutoPlantillaComponent: AutoPlantillaComponent;

  public routes = Values.ROUTES;

  // Common Variables
  public colaborationInfo = new ColaborationBody();
  public inmoId: number;
  public groupId: number;
  public loaded = false;

  public tipoPerfil = 0;
  public basic = false;
  public silver = false;
  public golden = false;
  public mensajes = 0;

  // Variables for Ajustes Inmo
  public colaborarConAgrupaciones = 0;
  public FlatSharing = 0;
  public FlatLending = 0;
  public grupos: BodyGroup[] = [];
  public tempBool: number;
  public showCustomGroups: boolean;
  public selectedOptions: any;

  // Variables for Ajustes Grupo
  public verInmos = 0;
  public verViviendas = 0;

  constructor(public router: Router,
              public authService: AuthService,
              public dialog: MatDialog,
              private endpointService: EndpointService,
              private genericService: GenericService,
              private localStorageService: LocalStorageService) {}

  ngOnInit(): void {
    let p2 = new Promise<void>((resolve, reject) => {
      setTimeout(async () => {
        this.inmoId = this.authService.inmoId;
        this.groupId = this.authService.groupId;
        resolve();
      }, 500);
    });

    p2.then(res => {
      if (this.isInmo) {
        this.endpointService.getCommunityInfo(this.authService.inmoId).subscribe(async data => {
          await this.colaborationInfo.handleResponse(data['response'], false);
          this.colaborarConAgrupaciones = this.colaborationInfo.colaborarConAgrupaciones;
          this.tipoPerfil = this.colaborationInfo.tipoPerfil;
          this.basic = this.colaborationInfo.basic;
          this.silver = this.colaborationInfo.silver;
          this.golden = this.colaborationInfo.golden;
          this.FlatSharing = this.colaborationInfo.flatSharing;
          this.FlatLending = this.colaborationInfo.flatLending;
          this.mensajes = this.colaborationInfo.mensajes;
          this.showCustomGroups = this.colaborationInfo.colaborarConAgrupaciones === 3;
          this.endpointService.getGruposOrderByPuntuation(this.inmoId,this.inmoId).subscribe(data2 => {
            this.grupos = data2['response'];
            if (this.showCustomGroups) {
              let temp = [];
              this.grupos.forEach(grupo =>{
                if(grupo['colaboraGrup'] == 1){
                  temp.push(grupo);
                }
                this.selectedOptions = temp;
              });
            }
            this.loaded = true;
          });
        });
      } else {
        this.endpointService.getCommunityInfoGroup(this.authService.groupId).subscribe(async data => {
          await this.colaborationInfo.handleResponse(data['response'], true);
          this.tipoPerfil = this.colaborationInfo.tipoPerfil;
          this.basic = this.colaborationInfo.basic;
          this.silver = this.colaborationInfo.silver;
          this.golden = this.colaborationInfo.golden;
          this.mensajes = this.colaborationInfo.mensajes;
          this.verInmos = this.colaborationInfo.verInmos;
          this.verViviendas = this.colaborationInfo.verViviendas;
          this.loaded = true;
        });
      }
    });
  }

  showCommunityPerfil() {
    this.router.navigate([Values.ROUTES.COMMUNITY, 'inmobiliaria', this.authService.inmoURL]);
  }


  public showAgency(): void {
    //this.router.navigate([this.routes.SETTINGS]);
    this.genericService.openInEditMode = false;
    localStorage.setItem("movin", this.authService.inmoId.toString());
    this.localStorageService.agency();
    //localStorage.setItem("seeMovin", "agency");
    this.router.navigate([this.routes.INMO]);
  }

  public showAgencyGroup(): void {
    //this.router.navigate([this.routes.SETTINGS]);
    this.genericService.openInEditMode = false;
    localStorage.setItem("movin", this.authService.groupId.toString());
    this.localStorageService.agency();
    //localStorage.setItem("seeMovin", "agency");
    // TRET TEMPORALMENT A QUAN ES DEIDEIXI RUTA this.router.navigate([this.routes.INMO_GROUP]);
  }

  confirmOperation(i: number) {
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        width: 'auto',
        height: 'auto',
        data: {
          message: '¿Está seguro?',
          labelButton1: 'No',
          labelButton2: 'Sí'
        }
      });

      dialogRef.afterClosed().subscribe(data => {
        if (data === 2) {
          switch (i) {
            case 1: //Tipo de perfil
              this.endpointService.updateProfileType(this.colaborationInfo.tipoPerfil, this.inmoId).subscribe(data => {
                this.tipoPerfil = this.colaborationInfo.tipoPerfil;
              });
              break;
            case 2: //Colaborar con mis Agrupaciones
              this.showCustomGroups = this.colaborationInfo.colaborarConAgrupaciones === 3
              this.endpointService.updateColaborarSegunMisAgrupaciones(this.colaborationInfo.colaborarConAgrupaciones,
                this.inmoId).subscribe(data => {
                this.colaborarConAgrupaciones = this.colaborationInfo.colaborarConAgrupaciones;
                if (this.showCustomGroups) {
                  this.selectedOptions = this.grupos;
                } else {
                  if (this.colaborationInfo.colaborarConAgrupaciones === 1) {
                    this.endpointService.updateColaborarSegunMisAgrupacionesPerson(this.grupos,true).subscribe(data => {});
                  } else if (this.colaborationInfo.colaborarConAgrupaciones === 2) {
                    this.endpointService.updateColaborarSegunMisAgrupacionesPerson(this.grupos,false).subscribe(data => {});;
                  }
                }
              });
              break;
            case 3: //Colaborar según aportacion
              this.endpointService.colaborarConBasic(this.colaborationInfo.basic ? 1 : 0, this.inmoId).subscribe(data => {
                this.basic = this.colaborationInfo.basic;
              });
              break;
            case 4: //Colaborar según aportacion
              this.endpointService.colaborarConSilver(this.colaborationInfo.silver ? 1 : 0, this.inmoId).subscribe(data => {
                this.silver = this.colaborationInfo.silver;
              });
              break;
            case 5: //Colaborar según aportacion
              this.endpointService.colaborarConGolden(this.colaborationInfo.golden ? 1 : 0, this.inmoId).subscribe(data => {
                this.golden = this.colaborationInfo.golden;
              });
              break;
            case 6: //Gestionar mis viviendas compartidas (FlatSharing)

              break;
            case 7: //Gestionar mis viviendas compartidas (FlatLending)

              break;
            case 8: //Mensajes
              this.endpointService.updateRecibirMensajes(this.colaborationInfo.mensajes, this.inmoId).subscribe(data => {
                this.mensajes = this.colaborationInfo.mensajes;
              });
              break;
            case 9: // Tipo de Perfil (Grupo)
              this.endpointService.updateProfileTypeGroup(this.colaborationInfo.tipoPerfil, this.groupId).subscribe(data => {
                this.tipoPerfil = this.colaborationInfo.tipoPerfil;
              });
              break;
            case 10: // Mensajes (Grupo)
              this.endpointService.updateRecibirMensajesGroup(this.colaborationInfo.mensajes, this.groupId).subscribe(data => {
                this.mensajes = this.colaborationInfo.mensajes;
              });
              break;
            case 11: // Ver Inmos (Grupo)
              this.endpointService.updateVerInmosGroup(this.colaborationInfo.verInmos, this.groupId).subscribe(data => {
                this.verInmos = this.colaborationInfo.verInmos;
              });
              break;
            case 12: // Ver Viviendas (Grupo)
              this.endpointService.updateVerViviendasGroup(this.colaborationInfo.verViviendas, this.groupId).subscribe(data => {
                this.verViviendas = this.colaborationInfo.verViviendas;
              });
              break;
            case 13: // Basic (Grupo)
              this.endpointService.colaborarConBasicGrupo(this.colaborationInfo.basic ? 1 : 0, this.groupId).subscribe(data => {
                this.basic = this.colaborationInfo.basic;
              });
              break;
            case 14: // Silver (Grupo)
              this.endpointService.colaborarConSilverGrupo(this.colaborationInfo.silver ? 1 : 0, this.groupId).subscribe(data => {
                this.silver = this.colaborationInfo.silver;
              });
              break;
            case 15: // Golden (Grupo)
              this.endpointService.colaborarConGoldenGrupo(this.colaborationInfo.golden ? 1 : 0, this.groupId).subscribe(data => {
                this.golden = this.colaborationInfo.golden;
              });
              break;
          }
        }
        else {
          switch (i) {
            case 1: //Tipo de perfil
              this.colaborationInfo.tipoPerfil = this.tipoPerfil;
              break;
            case 2: //Colaborar con mis Agrupaciones
              this.colaborationInfo.colaborarConAgrupaciones = this.colaborarConAgrupaciones;
              break;
            case 3: //Colaborar según aportacion (Basic)
              this.colaborationInfo.basic = this.basic
              break;
            case 4: //Colaborar según aportacion (Silver)
              this.colaborationInfo.silver = this.silver;
              break;
            case 5: //Colaborar según aportacion (Golden)
              this.colaborationInfo.golden = this.golden;
              break;
            case 6: //Gestionar mis viviendas compartidas (FlatSharing)
              this.colaborationInfo.flatSharing = this.FlatSharing;
              break;
            case 7: //Gestionar mis viviendas compartidas (FlatLending)
              this.colaborationInfo.flatLending = this.FlatLending;
              break;
            case 8: //Mensajes
              this.colaborationInfo.mensajes = this.mensajes;
              break;
            case 9: //Tipo de perfil (Grupo)
              this.colaborationInfo.tipoPerfil = this.tipoPerfil;
              break;
            case 10: //Mensajes (Grupo)
              this.colaborationInfo.mensajes = this.mensajes;
              break;
            case 11: //Ver Inmos (Grupo)
              this.colaborationInfo.verInmos = this.verInmos;
              break;
            case 12: //Ver Viviendas (Grupo)
              this.colaborationInfo.verViviendas = this.verViviendas;
              break;
            case 13: //Basic (Grupo)
              this.colaborationInfo.basic = this.basic;
              break;
            case 14: //Silver (Grupo)
              this.colaborationInfo.silver = this.silver;
              break;
            case 15: //Golden (Grupo)
              this.colaborationInfo.golden = this.golden;
              break;
          }
        }
      });
    }

  viewPropertyListFlatSharing() {

  }

  viewPropertyListFlatLending() {
  
  }

  setChecked(event: any) {
    this.selectedOptions = event.value;
    this.endpointService.updateColaborarSegunMisAgrupacionesPerson(this.grupos,false).subscribe(data => {
    });
    this.endpointService.updateColaborarSegunMisAgrupacionesPerson(this.selectedOptions,true).subscribe(data => {
    });
  }

  showHelpButton(id_button) {
    let id_ego_button = null;
    // Id del Boton depende del id de la tabla plantillas_ego_buttons_APP.
    // En dev, solo provamos con el primer boton y una plantilla de prueba.
    // Antes de subir a prod, definir el resto de plantillas y botones.
    switch (id_button) {
      case 1:
        id_ego_button = 18
        break;
      case 2:
        id_ego_button = 19
        break;
      case 3:
        id_ego_button = 20
        break;
      case 4:
        id_ego_button = 21
        break;
      case 5:
        id_ego_button = 22
        break;
      case 6:
        id_ego_button = 23
        break;
    }
    this.AutoPlantillaComponent.staticButton(id_ego_button);
  }
}
