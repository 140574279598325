<div class="fondo-negro">
  <mat-sidenav-container class="example-container" [hasBackdrop]="true">
    <mat-sidenav #sidenav [mode]="over" fxFlex="60" fxFlex.sm="100" fxFlex.xs="100" fxFlex.md="80">
      <div class="notas-p">
        <div class="top-pdf">
          <h6 class="t-pdf">{{sidenavService.text_pdf}}</h6>
          <mat-icon (click)="sidenav.toggle()" class="removeit">close</mat-icon>
        </div>
        <iframe *ngIf="sidenavService.url_item_intern" [src]="sidenavService.url_item_intern | safe" class="pdf"></iframe>
      </div>
    </mat-sidenav>
    <mat-sidenav-content class="custom-mat-drawer-content">
      <app-container [type]="typeContainer" [landing]="this.tipo"></app-container>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
