<div style="min-height: 90vh;">
  <div class="dialog-container" fxLayout="row wrap" style="margin-top: 1.5em;">
    <label fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="title-input">Agenda compartida</label>
    <span fxFlex="50" fxFlex.sm="100" fxFlex.xs="100">
      <span class="generic-buttons-blue-revert"><button class="close-button" mat-button (click)="onClose(true)"><i class="material-icons">check</i> APLICAR FILTROS</button></span>
      <span class="generic-buttons-blue"><button style="margin-right: 10px;" class="close-button" mat-button (click)="onClose(false)"><i class="material-icons">close</i> VOLVER</button></span>
    </span>
  </div>
  
  <mat-checkbox style="font-weight: 700; margin: 1.5em 0; padding: 0 6px;" [(ngModel)]="checkShowOnlyMyAgenda" (click)="$event.stopPropagation()">Ver sólo mi agenda</mat-checkbox>
  <p *ngIf="checkShowOnlyMyAgenda" class="red" style="font-weight: 700; padding: 0 6px;">
  Ahora sólo se mostrarán tus eventos o los eventos en los que seas participante.
  </p>
  
  <mat-tab-group *ngIf="!checkShowOnlyMyAgenda" mat-align-tabs="start" class="generic-tab-full-width-2 tabs-agenda-compartida">
    <mat-tab label="Grupos">
      <div class="dialog-container" style="display: flex; flex-direction: column; margin-top: 0.5em;">
        <div class="shareEvents" fxLayout="row wrap" style="padding: 0 6px; margin-bottom: 10px; margin-right: 20px;">
          <span class="generic-buttons-blue" fxFlex="50" fxFlex.sm="100" fxFlex.xs="100">
            <button style="line-height: inherit !important;" mat-button (click)="openCreateGroupDialog()"><i class="material-icons">add</i> Añadir nuevo grupo</button>
          </span>
          <span (click)="checkUncheckAllGroups(!checkAllGroups)" class="selectAllTable" fxFlex="50" fxFlex.sm="100" fxFlex.xs="100">
            <span *ngIf="!checkAllGroups">Marcar todo</span>
            <span *ngIf="checkAllGroups">Desmarcar todo</span>
            <mat-checkbox [ngModel]="checkAllGroups" (click)="$event.stopPropagation()" (change)="checkUncheckAllGroups($event.checked)" style="padding-right: 10px; margin-left: 6px;"></mat-checkbox>
          </span>
        </div>
        <div class="scroll-lists-agenda">
          <ul class="list-group" style="padding: 0 6px;">
            <li class="list-group-item" *ngFor="let group of groupFilter; let i = index;" (click)="changeStatusGroup(group.id, !group.value)">
              <span [ngClass]="{'agenda-selectors-background-allways': true, 'agenda-selectors-background': (i%2 == 0)}">
                  <a (click)="openEditGroupDialog(group); $event.stopPropagation()"><i class="material-icons blue" style="font-size: 17px;">edit</i></a>
                  <a (click)="openDeleteConfirmGroupDialog(group.id); $event.stopPropagation()"><i class="material-icons red" style="font-size: 17px;">delete</i></a>
                  {{group.name}}
                  <mat-checkbox [ngModel]="group.value" (click)="$event.stopPropagation()" (change)="changeStatusGroup(group.id, $event.checked)" style="float: right; padding-right: 20px;"></mat-checkbox>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Usuarios">
      <div class="dialog-container" style="margin-top: 0.5em;">
        
        <mat-form-field appearance="outline">
          <input matInput (keyup)="applyFilter($event)" placeholder="Buscar..." #input>
        </mat-form-field>
        <div *ngIf="tableLoaded" class="example-container taulesgrans" style="overflow: auto; max-height: calc(65vh - 59px); min-width: 50em;">
  
          <table class="mat-elevation-z3" mat-table [dataSource]="dataSource" matSort>
            
            <ng-container matColumnDef="visible">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox [ngModel]="checkAllUsers" (change)="checkUncheckAllUsers($event.checked)"></mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let element">
                <mat-checkbox style="padding-right: 2em;" [ngModel]="element.value" (click)="$event.stopPropagation()" (change)="changeStatusUser(element.id, $event.checked)"></mat-checkbox>
              </td>
            </ng-container>
      
            <ng-container matColumnDef="photo">
              <th mat-header-cell *matHeaderCellDef>Foto</th>
              <td mat-cell *matCellDef="let element; let index = index">
                <img class="fotoEvento2" src="{{element.userPic}}" alt="" style="max-width:100%; max-height:100%;">
              </td>
            </ng-container>
      
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
              <td mat-cell *matCellDef="let element" (click)="element.value = !element.value;
              changeStatusUser(element.id, element.value)">{{element.name}}</td>
            </ng-container>
      
            <!---<ng-container matColumnDef="account_type">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo de cuenta</th>
              <td mat-cell *matCellDef="let element" (click)="element.value = !element.value;
              changeStatusUser(element.id, element.value)">{{element.accountTypeName}}</td> 
            </ng-container> -->
      
            <ng-container matColumnDef="account_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre de la cuenta</th>
              <td mat-cell *matCellDef="let element" (click)="element.value = !element.value;
              changeStatusUser(element.id, element.value)">{{element.accountName}}</td>
            </ng-container>
      
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="cursor: pointer;"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>