import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {UntypedFormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {Observable, Subscription} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-chip-autocomplete',
  templateUrl: './chip-autocomplete.component.html',
  styleUrls: ['./chip-autocomplete.component.css']
})
export class ChipAutocompleteComponent implements OnInit, OnDestroy {

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  optionCtrl = new UntypedFormControl();
  filteredOptions: Observable<string[]>;
  options: string[] = [];
  allOptions: string[] = [];
  filteredOptionsLength: number = 0;

  @ViewChild('optionInput', {static: false}) optionInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;

  @Input() filterInitial: any;
  @Input() filterIndex: any;
  @Input() filterFields: any;
  private eventsSubscription: Subscription;
  @Input() resetFilter: Observable<void>;
  @Output() filterChanged = new EventEmitter<any[]>();

  constructor() {}

  ngOnInit(): void {
    this.eventsSubscription = this.resetFilter.subscribe(() => this.clearFilter());
    this.allOptions = this.filterFields['value'];
    this.options = this.filterInitial;
    this.filteredOptions = this.optionCtrl.valueChanges.pipe(
      startWith(""),
      map(chip => {
        //console.log("map: chip=[" + chip + "] optionsAuto.length=" + this.optionsAuto.length);
        const filterValue = (chip == null ? '' : this.normalize(chip).toLowerCase());
        let result = this.allOptions.filter(option =>this.normalize(option).toLowerCase().includes(filterValue));
        //console.log("result del filtre:" + result.length);
        this.filteredOptionsLength = result.length;
        //this.filteredOptionsNotShown = (this.filteredOptionsLength > 200 ? this.filteredOptionsLength - 200 : 0);
        return this.filteredOptionsLength == 0
               ? result.slice()
               : (this.filteredOptionsLength <= 200 ? result : result.slice(0, 199));
      })
    );
  }

  ngOnDestroy(): void {
    this.eventsSubscription.unsubscribe();
  }

  add(event: MatChipInputEvent): void {
    // Inicialitzat this.addOnBlur a false per no utilitzar la funcionalitat add
    // Add option only when addOnBlur
    // and MatAutocomplete is not open to avoid conflicting with OptionSelected Event
    if (this.addOnBlur && !this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      // Add our option
      if ((value || '').trim()) {
        this.options.push(value.trim());
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.optionCtrl.setValue(null);
    }
  }

  remove(option: string): void {
    const index = this.options.indexOf(option);

    if (index >= 0) {
      this.options.splice(index, 1);
    }

    this.newFilterValues();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.options.push(event.option.viewValue);
    //this.optionInput.nativeElement.value = ''; --> No funciona i fa que no funcioni el codi de darrera
    this.optionCtrl.setValue(null);
  
    // Chapuza per fer que el desplegable obri totes les opcions i no nomes l'ultima seleccionada...
    this.filteredOptions = this.optionCtrl.valueChanges.pipe(
      startWith(""),
      map(chip => {
        //console.log("map: chip=[" + chip + "] optionsAuto.length=" + this.optionsAuto.length);
        const filterValue = (chip == null ? '' : this.normalize(chip).toLowerCase());
        let result = this.allOptions.filter(option =>this.normalize(option).toLowerCase().includes(filterValue));
        //console.log("result del filtre:" + result.length);
        this.filteredOptionsLength = result.length;
        //this.filteredOptionsNotShown = (this.filteredOptionsLength > 200 ? this.filteredOptionsLength - 200 : 0);
        return this.filteredOptionsLength == 0
               ? result.slice()
               : (this.filteredOptionsLength <= 200 ? result : result.slice(0, 199));
      })
    );

    this.newFilterValues();
  }

  newFilterValues(): void {
    let filter: any[] = [];
    filter.push(this.filterIndex);
    filter.push(this.options);
    this.filterChanged.emit(filter);
  }

  clearFilter(): void {
    this.options = [];
    //this.optionInput.nativeElement.value = ''; --> No funciona i fa que no funcioni el codi de darrera
    this.optionCtrl.setValue(null);
  
    // Chapuza per fer que el desplegable obri totes les opcions i no nomes l'ultima seleccionada + funcioni filtre...
    this.filteredOptions = this.optionCtrl.valueChanges.pipe(
      startWith(""),
      map(chip => {
        //console.log("map: chip=[" + chip + "] optionsAuto.length=" + this.optionsAuto.length);
        const filterValue = (chip == null ? '' : this.normalize(chip).toLowerCase());
        let result = this.allOptions.filter(option =>this.normalize(option).toLowerCase().includes(filterValue));
        //console.log("result del filtre:" + result.length);
        this.filteredOptionsLength = result.length;
        //this.filteredOptionsNotShown = (this.filteredOptionsLength > 200 ? this.filteredOptionsLength - 200 : 0);
        return this.filteredOptionsLength == 0
               ? result.slice()
               : (this.filteredOptionsLength <= 200 ? result : result.slice(0, 199));
      })
    );

    this.newFilterValues();
  }
  
  private _filter(value: string): string[] {
    if (value != null) {
      const filterValue = value.toLowerCase();

      //return this.allOptions.filter(option => option.toLowerCase().indexOf(filterValue) === 0); --> Busca 
      return this.allOptions.filter(option => option.toLowerCase().includes(filterValue));
      }
  }

  
  private normalize(text: string): string {
    return text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-zA-Z0-9 ]/g, "");
  }
}
