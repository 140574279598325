<div style="padding: 0 2vw;" fxLayout="row wrap" fxFlex="100">
  <div *ngFor="let e of elements; let i = index" style="margin-top: 5em;" fxLayout="row wrap" fxFlex="100">
    <div *ngIf="e.main === 1" class="tittlees" fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start center">
      <span [ngClass]="{'span-yellow': type === 'knowledgeplace', 'span-blue': type === 'marketplace'}"></span>
      <h1>{{e.name}}</h1>
    </div>

    <div *ngIf="e.main === 2" fxLayout="row wrap" fxFlex="100">
      <span *ngIf="type === 'knowledgeplace'" class="slogan-movin" ngClass.sm="slogan-movin2" ngClass.xs="slogan-movin2" fxFlex="100">Knowledge Place</span>
      <span *ngIf="type === 'marketplace'" class="slogan-movin" ngClass.sm="slogan-movin2" ngClass.xs="slogan-movin2" fxFlex="100">Market Place</span>
      <span *ngIf="type === 'knowledgeplace'" class="novedades" fxFlex.sm="100" fxFlex.xs="100" ngClass.sm="novedades2" ngClass.xs="novedades2">[NOVEDADES]</span>
      <span *ngIf="type === 'marketplace'" class="novedades" fxFlex.sm="100" fxFlex.xs="100" ngClass.sm="novedades2" ngClass.xs="novedades2">[OFERTAS]</span>
    </div>

    <app-carousel fxFlex="100" fxLayout="row wrap" [type]="e.type"></app-carousel>
  </div>
</div>
