<div class="dialog-container" style="text-align: right">
  <button class="close-button" mat-button (click)="onNoClick()"><i class="material-icons">close</i></button>
</div>

<div class="alert alert-info">
  A continuación se muestran los eventos que han finalizado pero aún siguen marcados como abiertos.
</div>

<div class="example-container taulesgrans" style="overflow: auto; height: calc(50vh);">
  <div class="mat-elevation-z3">
    <table mat-table [dataSource]="dataSource" matSort>

      <!-- Nombre del evento -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <!-- Fecha inicio del evento -->
      <ng-container matColumnDef="dateStart">
        <th mat-header-cell *matHeaderCellDef> Fecha de inicio </th>
        <td mat-cell *matCellDef="let element"> {{element.dateStart}} </td>
      </ng-container>

      <!-- Fecha fin del evento -->
      <ng-container matColumnDef="dateEnd">
        <th mat-header-cell *matHeaderCellDef> Fecha de finalización </th>
        <td mat-cell *matCellDef="let element"> {{element.dateEnd}} </td>
      </ng-container>

      <!-- Estado del evento -->
      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef> Estado </th>
        <td mat-cell *matCellDef="let element"> {{element.state}} </td>
      </ng-container>

      <!-- Evento = terminado -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="text-align: center"> ¿Terminado? </th>
          <td mat-cell *matCellDef="let element" style="display: flex;">
            <button class="btns-filter btn-outline-success btn1" cdkFocusInitial style="margin: 0.5em; display: flex; align-items: center; justify-content: center;" (click)="setEventFinished(element)">
              <i style="margin-right: 0.3em; align-self: center;" class="material-icons">check</i>
              Sí
            </button>
            <button class="btns-filter btn-outline-danger btn1" cdkFocusInitial style="margin: 0.5em; display: flex; align-items: center; justify-content: center;" (click)="setEventCancelled(element)">
              <i style="margin-right: 0.3em; align-self: center;" class="material-icons">clear</i>
              No
            </button>
            <button class="btns-filter btn-outline-warning btn1" cdkFocusInitial style="margin: 0.5em; display: flex; align-items: center; justify-content: center;" (click)="postponeEvent(element)">
              <i style="margin-right: 0.3em; align-self: center;" class="material-icons">access_time</i>
              Posponer
            </button>
          </td>
      </ng-container>



      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  <!--  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>-->
  </div>
</div>
<div class="bottom-table">
  <div class="bottom-table2">
    <mat-form-field class="input-filter">
      <input matInput [(ngModel)]="inputFilter" (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
    </mat-form-field>
  </div>
  <mat-paginator style="width: 50%;" [length]="PENDING_DATA.length" [pageSize]="5" [pageSizeOptions]="[5, 10, 20, 50]"></mat-paginator>
</div>
<span [ngClass]="{'loader-spinner0':true, 'loader-spinner0-out':loaded}">
  <mat-progress-spinner
    class="loader-spinner"
    [color]="'primary'"
    [value]="75"
    [mode]="'indeterminate'"
    [diameter]="120"
    [strokeWidth]="15">
  </mat-progress-spinner>
</span>
