<div class="color-picker">
	<div style="display: flex; justify-content: space-between;">
		<mat-form-field appearance="outline" style="padding-right: 30px;">
			<mat-label>Nombre</mat-label>
			<input matInput [formControl]="nameCategoryControl" required>
			<mat-error *ngIf="nameCategoryControl.invalid">{{getNameErrorMessage()}}</mat-error>
		</mat-form-field>

		<div style="display: flex; flex-direction: column; align-items: flex-end;">
		<mat-checkbox [(ngModel)]="isChecked" (change)="checkPrivateStatus($event.checked)" labelPosition="before" style="float: right; padding-right: 20px;">Privada</mat-checkbox>
		<mat-checkbox [(ngModel)]="signable" (change)="signable = $event.checked" labelPosition="before" style="float: right; padding-right: 20px;">Se puede firmar</mat-checkbox>
		<mat-checkbox [(ngModel)]="portalVisitCompatibility" (change)="portalVisitCompatibility = $event.checked" labelPosition="before" style="float: right; padding-right: 20px;">Aparecerá ocupado en el calendario</mat-checkbox>
		</div>
 	</div>
	<div class="opened">
		<div class="colors">
			<div (click)="colorCategoryControl.setValue(paint)" *ngFor="let paint of defaultColors" class="circle"
				[ngStyle]="{'background': paint}">
			</div>
		</div>
		<div class="hex-code">
			<mat-form-field appearance="outline" style="padding-right: 30px; width: 240px;">
				<mat-label>Color seleccionado</mat-label>
				<input matInput type="text" maxlength="7" [formControl]="colorCategoryControl" required>
				<mat-error *ngIf="colorCategoryControl.invalid">{{getColorErrorMessage()}}</mat-error>
			</mat-form-field>
			<div *ngIf="colorCategoryControl.value" class="circle" [ngStyle]="{'background': colorCategoryControl.value}"></div>
		</div>
	</div>
</div>
<button mat-button (click)="onNoClick()">Cerrar</button>
<button *ngIf="(type != null)" mat-button [disabled]="nameCategoryControl.invalid || colorCategoryControl.invalid"
        [mat-dialog-close]="{id: editType.id, name: nameCategoryControl.value, color: colorCategoryControl.value, isPrivate: (this.isChecked ? 1 : 0),
        signable: (this.signable ? 1 : 0), portalVisitCompatibility: (this.portalVisitCompatibility ? 1 : 0)}">Editar</button>
<button *ngIf="(type == null)" mat-button [disabled]="nameCategoryControl.invalid || colorCategoryControl.invalid"
        [mat-dialog-close]="{name: nameCategoryControl.value, color: colorCategoryControl.value, isPrivate: (this.isChecked ? 1 : 0),
        signable: (this.signable ? 1 : 0), portalVisitCompatibility: (this.portalVisitCompatibility ? 1 : 0)}">Añadir</button>
