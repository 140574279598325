import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {NavigationEnd, Router} from "@angular/router";
import {isPlatformBrowser} from "@angular/common";
import {Observable, Subject} from "rxjs";

@Injectable()
export class LocalStorageService {

  private storageSub= new Subject<String>();

  constructor() { }


  watchStorage(): Observable<any> {
    return this.storageSub.asObservable();
  }

  agency(){
    localStorage.setItem("seeMovin", "agency");
    this.storageSub.next('changed');
  }

  user(){
    localStorage.setItem("seeMovin", "users");
    this.storageSub.next('changed');
  }

  email(){
    localStorage.setItem("seeMovin", "email");
    this.storageSub.next('changed');
  }

  group(){
    localStorage.setItem("seeGroup", "group");
    this.storageSub.next('changed');
  }

  userGroup(){
    localStorage.setItem("seeGroup", "users");
    this.storageSub.next('changed');
  }

}
