import { Injectable } from '@angular/core';

// Serivce
import { RoutingService } from "../routing/routing.service";
import { EndpointService} from "../endpoint/endpoint.service";

// Material
import {MatDialog} from "@angular/material/dialog";
import { MatTableDataSource } from '@angular/material/table';

import * as _ from 'lodash';
import { FormControl, Validators } from '@angular/forms';
import { Values } from 'values/values';
//import { InterestDialogComponent } from 'app/clients/subcomponents/matching/interest-dialog/interest-dialog.component';
import { MessageDialogComponent } from 'app/utils/message-dialog/message-dialog.component';
//import { SignCInfoDialog } from 'app/clients/subcomponents/client-info/sign-c-info-dialog/sign-c-info-dialog';
//import { BottomSheetMatching } from 'app/clients/subcomponents/matching/bottom-sheet-matching/bottom-sheet-matching';

@Injectable({
  providedIn: 'root'
})
export class ClientsFunctionsService {
  constructor(private routingService : RoutingService,
    public dialog : MatDialog,
    private endpointService: EndpointService) { }



  getFunction(structure, param = null) {

    console.log("clients-function.service")
    console.log(structure);
    console.log(param);

    let id = structure['id_function'];
    let route = structure['internal_routing'];

    switch (id) {
    case 3:
      console.log("3.- onLoadDesignatedAgent")
      this.onLoadDesignatedAgent(param);
      break;
    case 8:
      console.log("8.- onChangePrincipal")
      if(param["emit"]) {
        param["component"].changedPrincipal.emit(param["index"]);
        break;
      }
      param["component"].principal = param["index"];
      break;
    case 9:
      console.log("9.- onChangeDesignatedAgents");
      param.onChangeDesignatedAgent.emit();
      break;
    case 10:
      console.log("10.- onChangeCategory");
      this.onChangeCategory(param);
      break;
    case 11:
      console.log("11.- onChangeClientType")
      param.onChangeClientType.emit();
      break;
    case 20:
      console.log("20.- checkExpansion")
      param["component"].expandedElement = (param["component"].expandedElement === param["element"]) ? null : param["element"];
      break;
    case 31:
      console.log("31.- onLoadClientType")
      this.onLoadClientType(param);
      break;
    case 32:
      console.log("getPredefinedValues")
      this.getPredefinedValues(param);
      break;
    }
  }

  private onLoadDesignatedAgent(param: any) {
    let flag1, flag2;
      for (let i = 0; i < param.agentsArray.length; i++) {
        if (param.agentsArray[i].id == param.bodyClientInfo.designatedAgent) {

          if (param.agentsArray[i].foto != null)
            param.bodyClientInfo.profilePictureURL = param.rootProfilePicURL + param.agentsArray[i].foto;
          else param.bodyClientInfo.profilePictureURL = null;

          flag1 = true;//new
          //return;
        }
        if (param.agentsArray[i].id == param.bodyClientInfo.designatedAgent2) {
          if (param.agentsArray[i].foto != null)
            param.bodyClientInfo.profilePictureURL2 = param.rootProfilePicURL + param.agentsArray[i].foto;
          else param.bodyClientInfo.profilePictureURL2 = null;

          flag2 = true;//new
        }
      }
      if (flag1 && flag2) return;

      if (flag1 && !flag2) {
        param.bodyClientInfo.profilePictureURL2 = null;
        return;
      }

      if (!flag1 && flag2) {
        param.bodyClientInfo.profilePictureURL = null;
        return;
      }

      param.bodyClientInfo.profilePictureURL = null;
      param.bodyClientInfo.profilePictureURL2 = null;
  }

  private onChangeCategory(param: any) {
    if (param.bodyClientInfo.buyerCategory == 1 || param.bodyClientInfo.buyerCategory == 2 || param.bodyClientInfo.buyerCategory == 3) { //A,B,C
      param.bodyClientInfo.setActive(true, false, false);
    }
    else if (param.bodyClientInfo.buyerCategory == 4 || param.bodyClientInfo.buyerCategory == 5 || param.bodyClientInfo.buyerCategory == 6) {
      param.bodyClientInfo.setActive(false);
    }
    else if (param.bodyClientInfo.tenantCategory == 7 || param.bodyClientInfo.tenantCategory == 8 || param.bodyClientInfo.tenantCategory == 9) { //A,B,C
      param.bodyClientInfo.setActive(true, false, false);
    }
    else {
      param.bodyClientInfo.setActive(false);
    }
  }

  private getPredefinedValues(param: any) {
    this.endpointService.getActivitiesPrefefinedValues(param.idModule, param.idClient).subscribe(data => {
      //this.handleGetPredefinedValues(data['response'], param);
      param.refreshActivitiesList();
    });

    param.creatorFullName = param.authService.userFullName;
    param.bodyActivity.creatorId = param.authService.userId;
  }

  private onLoadClientType(param: any) {
    if (param["new"]) {
      console.log("onLoadClientType - new");
      if (param["component"].bodyClientInfo.clientType != null) {
        param["component"].showBuyerInterests = (param["component"].bodyClientInfo.clientType.indexOf(3) > -1 && param["component"].showMatching); // Comprador
        param["component"].showTenantInterests = (param["component"].bodyClientInfo.clientType.indexOf(4) > -1 && param["component"].showMatching); // Inquilino
      }
    }
    else {
      console.log("onLoadClientType - not new");
      if (param["component"].originalBodyClientInfo.clientType
        && !param["component"].originalBodyClientInfo.clientType.includes(3)
        && param["component"].bodyClientInfo.clientType.includes(3)
        && !param["component"].buyerInterest) {
        param["component"].snackBar.open('Es necesario añadir un matching de comprador', 'X', {
          duration: 6000, panelClass: ['lightblue-snackbar']
        });
        param["component"].getInterest(true);
      } else if (param["component"].originalBodyClientInfo.clientType
        && param["component"].originalBodyClientInfo.clientType.includes(3)
        && !param["component"].bodyClientInfo.clientType.includes(3)) {
        param["component"].buyerInterest = null;
      }

      if (param["component"].originalBodyClientInfo.clientType
        && !param["component"].originalBodyClientInfo.clientType.includes(4)
        && param["component"].bodyClientInfo.clientType.includes(4)
        && !param["component"].tenantInterest) {
        param["component"].snackBar.open('Es necesario añadir un matching de inquilino', 'X', {
          duration: 6000, panelClass: ['lightblue-snackbar']
        });
        param["component"].getInterest(false);
      } else if (param["component"].originalBodyClientInfo.clientType
        && param["component"].originalBodyClientInfo.clientType.includes(4)
        && !param["component"].bodyClientInfo.clientType.includes(4)) {
        param["component"].tenantInterest = null;
      }
    }
  }

}
