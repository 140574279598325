<mat-form-field appearance="outline" class="example-chip-list">
    <mat-label style="text-transform: uppercase;">{{filterFields['label']}}</mat-label>
    <mat-chip-list #chipList aria-label="Option selection">
      <mat-chip
        *ngFor="let option of options"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(option)"
      >
        {{option}}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input
        placeholder="Nuevo valor..."
        #optionInput 
        [formControl]="optionCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="add($event)"
      >
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" autoActiveFirstOption>
      <mat-option *ngIf="filteredOptionsLength > 100" [disabled]="true">
        <span *ngIf="filteredOptionsLength <= 200" style="display: flex; align-items: center; font-size: 12px; font-style: italic;">
          {{filteredOptionsLength}}
        </span>
        <span *ngIf="filteredOptionsLength > 200" style="display: flex; align-items: center; font-size: 12px; font-style: italic;">
          1-200 de {{filteredOptionsLength}}
        </span>
      </mat-option>
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{option}}
      </mat-option>
    </mat-autocomplete>
</mat-form-field>
