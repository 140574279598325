<div *ngIf="childs !== null || childs !== undefined" fxLayout="row" style="min-width: 330px;" fxLayoutAlign="center center">
    <div *ngIf="childs.id_subtipo == 3 || childs.id_subtipo == 4; else printCondition" fxFlex="100"> <!-- Tipo AND/OR -->
        <app-events-conditions-custom-loop class="component-class" [conditionsArray]="childs.children" [firstElement]="false" [(conditionType)]="childs.id_subtipo" [conditionId]="childs.id" fxFlex="100"></app-events-conditions-custom-loop>
    </div>
    <ng-template #printCondition >

        <div fxLayout="row wrap" style="text-align: center;" [formGroup]="addConditionForm">
            <!-- Origin table -->
            <mat-form-field appearance="outline" fxFlex="50">
                <mat-label>Tabla</mat-label>
                <mat-select formControlName="conditionTable" [(ngModel)]="childs.bd_table" (selectionChange)="selectTable(childs.bd_table, true); updateCondition(childs, undefined, childs.bd_table)" required>
                    <mat-option *ngFor="let table of functionsService.arrayTables" [value]="table.bd_table">{{ table.internal_name }}</mat-option>    
                </mat-select>
            </mat-form-field>
    
            <!-- Origin field -->
            <mat-form-field appearance="outline" fxFlex="50" *ngIf="childs.bd_table !== undefined">
                <mat-label>Campo</mat-label>
                <input formControlName="conditionCampo" type="text" matInput [(ngModel)]="childs.bd_field" [formControl]="myControl" [matAutocomplete]="auto" required>                
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (selectionChange)="updateCondition(childs, undefined, undefined, chidls.bd_field)">
                    <mat-option *ngFor="let field of filteredOptions | async" [value]="field">{{ field }}</mat-option>    
                </mat-autocomplete>
            </mat-form-field>
    
            <!-- Operator -->
            <mat-form-field appearance="outline" fxFlex="50" *ngIf="childs.bd_field !== undefined">
                <mat-label>Operador</mat-label>
                <mat-select formControlName="conditionOperador" [(ngModel)]="childs.id_operador" (selectionChange)="updateCondition(childs, undefined, undefined, undefined, childs.id_operador)" required>
                    <mat-option *ngFor="let operator of functionsService.arrayOperators" [value]="operator.id">{{ operator.nombre_frontend }}</mat-option>    
                </mat-select>
            </mat-form-field>
    
            <!-- Condition Type -->
            <mat-form-field appearance="outline" fxFlex="50" *ngIf="childs.id_operador !== undefined">
                <mat-label>Tipo Condición</mat-label>
                <mat-select formControlName="conditionTipo" [(ngModel)]="childs.id_subtipo" (selectionChange)="updateCondition(childs, childs.id_subtipo)" required>
                <mat-option [value]="1"> Valor </mat-option>
                <mat-option [value]="2"> Campo </mat-option>
                </mat-select>
            </mat-form-field>  
    
            <!-- Tipo valor -->
            <ng-container *ngIf = "childs.id_subtipo == 1 && childs.values !== undefined">
                <!-- Valores -->
                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>Valor</mat-label>
                        <input formControlName="conditionValor" matInput [(ngModel)]="childs.values[0].valor" [value]="childs.values[0].valor" (input)="updateCondition(childs, undefined, undefined, undefined, undefined, undefined, undefined, childs.values[0].valor)" required/>
                </mat-form-field>
            </ng-container>
    
            <!-- Tipo campo -->
            <ng-container *ngIf = "childs.id_subtipo == 2">
                <!-- Destination table -->
                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>Tabla2</mat-label>
                    <mat-select formControlName="conditionTable2" [(ngModel)]="childs.bd_table2" (selectionChange)="selectTable(childs.bd_table2, false); updateCondition(childs, undefined, undefined, undefined, undefined, childs.bd_table2)" required>
                        <mat-option *ngFor="let table of functionsService.arrayTables" [value]="table.bd_table">{{ table.internal_name }}</mat-option>    
                    </mat-select>
                </mat-form-field>
                <!-- Destination field -->
                <mat-form-field appearance="outline" fxFlex="50" *ngIf="childs.bd_table2 !== undefined">
                    <mat-label>Campo2</mat-label>
                    <input formControlName="conditionCampo2" type="text" matInput [(ngModel)]="childs.bd_field2" [formControl]="myControl2" [matAutocomplete]="auto" required>                
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (selectionChange)="updateCondition(childs, undefined, undefined, undefined, undefined, undefined, childs.bd_field2)">
                        <mat-option *ngFor="let field of filteredOptions2 | async" [value]="field">{{ field }}</mat-option>    
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
            
            <div fxFlex="100" class="generic-buttons-red-revert" style="position: relative; padding-bottom: 10px;">
                <button mat-raised-button (click)="deleteCondition(childs); checkIfTransform(childs.id_parent, childs)"><mat-icon class="material-icons">delete</mat-icon></button>
            </div>
        </div>


        <div class="generic-buttons-blue-revert" style="position: relative; padding: 0 10px;">
            <button mat-raised-button (click)="extendSimpleCondition(childs, functionsService.arrayConditions, childs.id, childs.id_subtipo, childs.id_parent, childs.id_evento, childs.id_reporte, childs.bd_table, childs.bd_field, childs.id_operador, childs.bd_table2, childs.bd_field2, childs.values)"><mat-icon class="material-icons">add</mat-icon></button>
        </div>        
    </ng-template>
</div>
