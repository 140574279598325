<div class="container2 agenda-container">
  <div class="m-2">
    <mat-form-field appearance="outline" *ngIf="showSearchBar">
      <input #filterInput placeholder="Buscar..." matInput type="text" [(ngModel)]="value" (keyup.enter)="searchValue($event.target.value)" >
      <span class="generic-buttons-blue-revert button-filter-table" matPrefix *ngIf="value != '' && value != lastValue">
        <button mat-raised-button aria-label="Search" (click)="searchValue(value); $event.stopPropagation()">
          Filtrar
        </button>
      </span>
      <mat-icon *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="cleanFilter()" matTooltip="Limpiar">close</mat-icon> 
      <mat-icon *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="deleteFilter()" matTooltip="Resetear">refresh</mat-icon> 
    </mat-form-field>
    <div class="row top-home">
      <div class="col-md-4 theselector">
        <div class="btn-group" style="float: left">
          <div class="btn btn-primary" (click)="setView(CalendarView.Month)" [class.active]="view === CalendarView.Month && !isList">
            <i class="material-icons">calendar_view_month</i> Mes
          </div>
          
          <div class="btn btn-primary" (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week && !isList">
            <i class="material-icons">calendar_view_week</i> Semana
          </div>
          
          <div class="btn btn-primary" (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day && !isList">
            <i class="material-icons">calendar_view_day</i> Día
          </div>
          <!-- <div class="btn btn-primary" (click)="setView(CalendarView.Day, 2)" [class.active]="isList">Lista</div> -->
        </div>
      </div>

      <div *ngIf="!isList" class="col-md-4 text-center align-text-middle thedate">
        <div class="arrows text-right" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()"><i class="material-icons">arrow_back</i></div>
        <mat-form-field appearance="none" class="matFormFieldForDatePicker">
          <input style="display: none;" matInput [matDatepicker]="datepicker" [(ngModel)]="viewDate">
          <button class="datepicker" (click)="datepicker.open()">{{ viewDate | calendarDate:(view + 'ViewTitle'):locale:weekStartsOn }}<!--<mat-icon>date_range</mat-icon>--></button>
          <mat-datepicker #datepicker startView="year" [startAt]="viewDate" ></mat-datepicker>
        </mat-form-field>
        <div class="arrows text-left" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()"><i class="material-icons">arrow_forward</i></div>
      </div>

      <div *ngIf="isList" class="col-md-4 text-center align-text-middle thedate"></div>
      
      <div class="col-md-4 thebuttons" style="padding-right: calc(15px - 0.3rem);">    
        <div class="btn-group">
          <span *ngIf="!showSearchBar" matBadge="!" [matBadgeHidden]="!this.value || this.value == ''" class="badge-form-change second-bar-tools icons-header-custom">
            <button class="btn btnjan eseee" (click)="openSearchBar()" matTooltip="Filtrar"><i class="material-icons">search</i></button>
          </span>
          <span *ngIf="showSearchBar" class="badge-form-change second-bar-tools icons-header-custom">
            <button class="btn btnjan eseee" (click)="deleteFilter()" matTooltip="Resetear filtro"><i class="material-icons">search_off</i></button>
          </span>
          <span [matBadge]="numCategoriesFiltersSelected" [matBadgeHidden]="numCategoriesFiltersSelected == 0" class="badge-form-change second-bar-tools icons-header-custom">
            <button class="btn btnjan eseee" (click)="openBottomSheetManageCategories()" matTooltip="Categorías de actividades"><i class="material-icons">library_add</i></button>
          </span>
          <span *ngIf="showAllActivities" class="badge-form-change second-bar-tools icons-header-custom">
            <button class="btn btnjan eseee" (click)="BottomSheetopenAllEventsList()" matTooltip="Ver todas las actividades"><i class="material-icons">remove_red_eye</i></button>
          </span>
          <span *ngIf="showShareCalendar" [matBadge]="numUsersFiltersSelected" [matBadgeHidden]="numUsersFiltersSelected == 0" class="badge-form-change second-bar-tools icons-header-custom">
            <button class="btn btnjan eseee" (click)="openBottomSheetSharedCalendar()" matTooltip="Agenda compartida"><i class="material-icons">people</i></button>
          </span>
          <span *ngIf="myAccounts && (myAccounts.length > 1 || numAccountsFiltersSelected <= 0)" [matBadge]="numAccountsFiltersSelected" [matBadgeHidden]="numAccountsFiltersSelected == 0" class="badge-form-change second-bar-tools icons-header-custom">
            <button class="btn btnjan eseee" (click)="openChooseAccountCalendarComponent()" matTooltip="Tus cuentas"><i class="material-icons">switch_account</i></button>
          </span>
          <span class="badge-form-change second-bar-tools icons-header-custom">
            <button class="btn btnjan eseee" (click)="openAgendaSettings()" matTooltip="Ajustes del calendario"><i class="material-icons">settings</i></button>
          </span>
        </div>
        <!--        <button class="btn btnjan eseee" (click)="BottomSheetOpenPendentEvents()" matBadge="{{ this.pendingEvents.length }}"
        data-toggle="modal_1" data-target="#myModal_1" matTooltip="Ver eventos pendientes"><i class="material-icons">notifications</i></button>-->
        <!--<button class="btn btnjan eseee" mwlCalendarToday [(viewDate)]="viewDate" matTooltip="Hoy"><i class="material-icons">today</i></button>-->
      </div>
    </div>
    <!--<div class="row top-home" *ngIf="!deviceService.isDesktop()">
      <div class="text-center thedate" style="width: 100%;">
        <div class="arrows" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()"><i class="material-icons">arrow_back</i></div>
        <input style="display: none;" matInput [matDatepicker]="datepicker" [(ngModel)]="viewDate">
        <mat-datepicker #datepicker startView="year" [startAt]="viewDate" (monthSelected)="monthSelectedHandler($event,datepicker)"></mat-datepicker>
        <h6><button class="datepicker" (click)="datepicker.open()">{{ viewDate | calendarDate:(view + 'ViewTitle'):locale }}</button></h6>
        <div class="arrows" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()"><i class="material-icons">arrow_forward</i></div>
      </div>
    </div>-->
    <div class="row">
      <div class="col-md-9">
        <div class="row calendarintern">
          <ng-template #customCellTemplate let-day="day" let-locale="locale">
            <div class="cal-cell-top">
              <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
              <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
            </div>
            <div class="cell-totals">
              <span *ngFor="let group of day.eventGroups" [ngStyle]="{'background': group[0]}" class="badge badge-{{ group[0] }}"> {{ group[1].length }}</span>
            </div>
          </ng-template>
          <ng-template #eventSegment let-days="days" let-locale="locale">
            <label class="cal-event"> <i class="fa fa-fw fa-pencil"></i>
            </label>
          </ng-template>
          <div [ngSwitch]="view" *ngIf="loaded && !isList">
            <mwl-calendar-month-view
              *ngSwitchCase="CalendarView.Month"
              [viewDate]="viewDate"
              [events]="customEvents"
              [refresh]="refresh"
              [activeDayIsOpen]="activeDayIsOpen"
              (dayClicked)="dayClicked($event.day)"
              (eventClicked)="handleEvent('Clicked', $event.event)"
              (eventTimesChanged)="eventTimesChanged($event)"
              [locale]="locale"
              [weekStartsOn]="weekStartsOn"
              [weekendDays]="weekendDays"
              [cellTemplate]="customCellTemplate"
              (beforeViewRender)="beforeMonthViewRender($event)"
            >
            </mwl-calendar-month-view>
            <mwl-calendar-week-view
              *ngSwitchCase="CalendarView.Week"
              [viewDate]="viewDate"
              [events]="customEvents"
              [refresh]="refresh"
              (eventClicked)="handleEvent('Clicked', $event.event)"
              (hourSegmentClicked)="handleEvent('HourClicked', $event)"
              (eventTimesChanged)="eventTimesChanged($event)"
              [locale]="locale"
              [weekStartsOn]="weekStartsOn"
              [weekendDays]="weekendDays"
              [dayStartHour]="dayStartHour"
              [dayEndHour]="dayEndHour"
              [hourSegmentHeight]="70"
              [hourSegments]="1"
              (beforeViewRender)="beforeMonthViewRenderWeek($event)"
            >
            </mwl-calendar-week-view>
            <mwl-calendar-day-view
              *ngSwitchCase="CalendarView.Day"
              [viewDate]="viewDate"
              [events]="customEvents"
              [refresh]="refresh"
              (eventClicked)="handleEvent('Clicked', $event.event)"
              (eventTimesChanged)="eventTimesChanged($event)"
              [locale]="locale"
              (hourSegmentClicked)="handleEvent('HourClicked', $event)"
              [dayStartHour]="dayStartHour"
              [dayEndHour]="dayEndHour"
              [hourSegmentHeight]="70"
              [hourSegments]="1"
              (beforeViewRender)="beforeMonthViewRenderDay($event)"
            >
            </mwl-calendar-day-view>
          </div>
          <ng-container *ngIf="loaded && isList">
            <ng-container *ngIf="structureListGeneric['hide_frontend'] != 1">
              <app-generic [structure]="structureListGeneric"></app-generic>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <span [ngClass]="{'loader-spinner0':true, 'loader-spinner0-out':loaded}">
        <mat-progress-spinner
          class="loader-spinner"
          [color]="'primary'"
          [value]="75"
          [mode]="'indeterminate'"
          [diameter]="120"
          [strokeWidth]="15">
        </mat-progress-spinner>
      </span>
      <div class="col-md-3">

        <div class="generic-buttons-blue-revert">
          <button class="btns-filter" style="width: 100%;" (click)="BottomSheetopenNewEvent($event)">
            <i class="material-icons">add</i> AÑADIR ACTIVIDAD
          </button>
        </div>

        <div style="margin-top: 15px;">
          <b>Tareas sin fecha</b>
          <div class="card tasking" mwlDroppable (drop)="externalDrop($event.dropData.event)" dragOverClass="drag-over">
            <div class="card-body">
              <p *ngIf="externalEvents.length === 0"><em>No hay tareas añadidas</em></p>
              <div *ngFor="let event of externalEvents" mwlDraggable [dropData]="{event: event}" dragActiveClass="drag-active">
                <div class="category-circle" [ngStyle]="{'background': event.color.primary}"></div>
                <a class="citas" (click)="onClickEditExternalActivity(event)">
                  {{ event.title }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
