<div class="dialog-container">
  <label *ngIf="creatingEvent && !isOnlyNewUsers" class="title-input">Crear nuevo grupo</label>
  <label *ngIf="!creatingEvent && !isOnlyNewUsers" class="title-input">Editar grupo {{grupo.name}}</label>
  <button class="close-button" mat-button (click)="onNoClick()"><i class="material-icons">close</i></button>
</div>
<div>
  <mat-form-field appearance="outline" *ngIf="!isOnlyNewUsers" style="width: 100%;">
    <input matInput placeholder="Nombre del grupo" [formControl]="nameCategoryControl" required>
    <mat-error *ngIf="nameCategoryControl.invalid">{{getNameErrorMessage()}}</mat-error>
  </mat-form-field>

<!--  <div class="users">
    <ul class="users-list">
      <li *ngFor="let type of allUsers; let i = index;" style="height: 3em;">
        <img class="fotoEvento2" src="{{type.userPic}}" alt="" style="max-width:100%; max-height:100%;">
        {{type.name}}
        <mat-checkbox [ngModel]="type.value" (change)="type.value = $event.checked" style="float: right; padding-right: 20px;"></mat-checkbox>
      </li>
    </ul>
  </div>-->

  <div style="margin-top: 3em;">
    <h5>Usuarios</h5>
    <div class="example-container taulesgrans" style="overflow: auto; max-height: calc(65vh - 59px);">
      <table class="mat-elevation-z3" mat-table [dataSource]="dataSource" matSort>
        
        <ng-container *ngIf="creatingEvent" matColumnDef="icon">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox [ngModel]="checkAll" (change)="checkUncheckAll($event.check)"></mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox style="padding-right: 2em;" [ngModel]="element.value" (change)="checkUncheck(element.id, $event.check)"></mat-checkbox>
          </td>
        </ng-container>

        <ng-container *ngIf="!creatingEvent" matColumnDef="icon">
          <th mat-header-cell *matHeaderCellDef class="yellow" style="font-size: 14px !important; text-align: center;" (click)="addUserToGroup()"> + </th>
          <td mat-cell *matCellDef="let element">
            <i class="material-icons red" style="font-size: 17px !important;" (click)="deleteUserFromGroup(element)">delete</i>
          </td>
        </ng-container>

        <ng-container matColumnDef="photo">
          <th mat-header-cell *matHeaderCellDef>Foto</th>
          <td mat-cell *matCellDef="let element; let index = index">
            <img class="fotoEvento2" src="{{element.userPic}}" alt="" style="max-width:100%; max-height:100%;">
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
          <td mat-cell *matCellDef="let element" (click)="element.value = !element.value">{{element.name}}</td>
        </ng-container>

        <ng-container matColumnDef="account_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre de la cuenta</th>
          <td mat-cell *matCellDef="let element" (click)="element.value = !element.value">{{element.accountName}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="cursor: pointer;"></tr>
      </table>
    </div>
  </div>
  <span class="generic-buttons-blue-revert">
    <button *ngIf="creatingEvent" style="float: right; margin-top: 50px;" mat-button (click)="createGroup()" [disabled]="(nameCategoryControl.invalid && !isOnlyNewUsers)">Añadir</button>
    <button *ngIf="!creatingEvent" style="float: right; margin-top: 50px;" mat-button (click)="editGroup()" [disabled]="nameCategoryControl.invalid">Guardar</button>
  </span>

</div>
