<div style="color: #333; background-color: white; overflow: auto;" fxFlex="100" fxLayout="row" *ngIf="loaded">
    <div style="width:300px; min-width: 333px; flex-direction: column; margin: 7px;" *ngFor="let item of accounts">
        <p style="color: white; background-color: #333;">{{item.nombre_perfil}}</p>
        <ng-contanier *ngIf="from == 2 || from == 3 || from == 4">
            <div style="background-color: rgb(238, 233, 233); margin-bottom: 5px;" fxFlex="100" fxLayout="column" *ngFor="let post of item.posts">
                <div fxFlex="100" fxLayout="row">
                    <p fxFlex="30">Post Nº {{post.id_post}}.</p>
                    <p style="text-align: right;" fxFlex="70">Publicación: {{post.fecha_publicacion}}</p>
                </div>
                <p>{{post.texto}}</p>
            </div>
        </ng-contanier>
        <ng-container *ngIf="from == 1">
            <ng-contanier *ngIf="item.tipo_red == 1">
                <iframe src="https://facebook.com"></iframe>
            </ng-contanier>
            <ng-contanier *ngIf="item.tipo_red == 2">
                <iframe src="https://instagram.com"></iframe>
            </ng-contanier>
            <ng-contanier *ngIf="item.tipo_red == 3">
                <iframe src="https://linkedin.com/feed/"></iframe>
            </ng-contanier>
            <ng-contanier *ngIf="item.tipo_red == 4">
                <div style="background-color: #fff;" *ngFor="let post of item.posts">
                    <blockquote class="twitter-tweet">
                        <p lang="en" dir="ltr">{{post.text}}
                    </blockquote>
                    <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
                </div>
            </ng-contanier>
        </ng-container>
    </div>
</div>