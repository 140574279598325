import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { GenericCreatorService } from 'app/services/generic/custom/generic-creator.service';
import { GenericService } from 'app/services/generic/generic.service';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith, distinctUntilChanged } from 'rxjs/operators';
import { AddGenericElementDialogComponent } from '../add-generic-element-dialog/add-generic-element-dialog.component';


@Component({
  selector: 'app-generic-custom-creator-options',
  templateUrl: './generic-custom-creator-options.component.html',
  styleUrls: ['./generic-custom-creator-options.component.scss']
})
export class GenericCustomCreatorOptionsComponent implements OnInit, OnDestroy {

  @Input() actualElement: any;
  variableObservable: Observable<string>;
  oldActualSelectedElement = {};
  isHeaderAdded = false;
  isContentAdded = false;
  isActionAdded = false;
  isDynamicAdded = false;
  tablesInitialized = {};
  fieldsInitialized = {};
  public defaultAngularClass = ''
  public filteredSubTypes: any[] = [];
  public filteredAngularClasses: string[];
  public filteredCustomClasses: string[];
  public filteredFunctionalSubTypes: string[];
  private subscribeArray = [];
  public buttonsTypesClasses = ["generic-buttons-blue-revert", "generic-buttons-blue", "generic-buttons-red-revert", "generic-buttons-red", "generic-buttons-yellow-revert", "generic-buttons-yellow", "generic-buttons-black-revert", "generic-buttons-black"];
  public customClassesList: any[] = [];
  private formChildrenIdsDict = {
    'input': 6,
    'selection': 12,
    'checkbox': 15,
    'file-upload': 113,
    'location': 121,
    'sign': 146
  }
  public formChildrenIds = Object.values(this.formChildrenIdsDict);

  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @ViewChild('companyTypeInput') companyTypeInput: ElementRef<HTMLInputElement>;
  @ViewChild('companyTypeInputRestriction') companyTypeInputRestriction: ElementRef<HTMLInputElement>;
  @ViewChild('profilePermissionInput') profilePermissionInput: ElementRef<HTMLInputElement>;

  constructor(public genericCreatorService: GenericCreatorService,
    public genericService: GenericService,
    public dialog: MatDialog,
    public formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.initialize();
  }

  ngAfterViewInit() {
    this.updateSelectionsOnType();
  }

  ngOnDestroy(): void {
    console.log('destroyed');
    if(this.actualElement.id_functional_type == 9 && this.genericCreatorService.arrayElementsGenericCreator[0] && this.genericCreatorService.arrayElementsGenericCreator[0]['child']) {
      this.genericCreatorService.refreshTables(this.genericCreatorService.arrayElementsGenericCreator[0]['child']);
    }
    this.subscribeArray.forEach((subscription) => {
      if(subscription !== undefined) subscription.unsubscribe()
    });
  }

  initialize() {
    console.log('called', this.actualElement);
    this.genericCreatorService.listElementOptionToggle = false;
    if (this.genericCreatorService.activeTabIndex != 1) this.genericCreatorService.activeTabIndex = 1;
    this.actualElement.hasSubEle = false;
    this.oldActualSelectedElement = this.genericService.cloneVariable(this.actualElement);
    if (this.genericCreatorService.actualSelectedSubElement === undefined) {
      this.genericCreatorService.arrayElementsTipo.forEach((item: any) => {
        if ((item.id_functional_type == this.actualElement.id_functional_type && item.id_functional_type_child > 0) || (this.actualElement['child'] && this.actualElement['child'].length && this.actualElement['child'].length > 0)) {
          this.actualElement.hasSubEle = true;
          this.genericCreatorService.actualSelectedFunctionalTypeChild = item.id_functional_type_child;
        }
      })
    }
    this.actualElement['formCreator'] = this.genericCreatorService.formBuilderCustom(this.actualElement);
    this.actualElement['formCreator'].controls["id_functional_area"].disable();
    // this.actualElement['formCreator'].get('id_functional_status_general').setValue(1);
    this.genericCreatorService.filterTypes(this.actualElement['id_functional_type']);
    if (this.actualElement?.mode_type == undefined) {
      this.actualElement['mode_type'] = 'update';
    }
    // Call Value changes function after 3 seconds
    let sbscr = this.actualElement['formCreator'].valueChanges.pipe(debounceTime(100), distinctUntilChanged()).subscribe({
      next: (value) => {
        this.genericCreatorService.formValueSubscribe(value, this.actualElement);
        this.changeSelectorHide(value['hide']);
        // this.genericCreatorService.setActualSelectedElement(this.actualElement);
      }
    });
    this.subscribeArray.push(sbscr);


    // For Text if subtype is 'a' then url is required
    let sbscr2 = this.actualElement['formCreator'].controls['type'].valueChanges.subscribe(value => {
      if (this.actualElement.id_functional_type == 101) {
        if (value == 'a') {
          this.actualElement['formCreator'].get('url').setValidators(Validators.required);
        } else {
          this.actualElement['formCreator'].get('url').clearValidators();
        }
        this.actualElement['formCreator'].get('url').updateValueAndValidity();
      }
    })
    this.subscribeArray.push(sbscr2);


    // For Graph if show total graph is selected then text is required
    let sbscr3 = this.actualElement['formCreator'].controls['show_total_graphs'].valueChanges.subscribe(value => {
      if (this.actualElement.id_functional_type == 14) {
        if (value == true) {
          this.actualElement['formCreator'].get('text').setValidators(Validators.required);
        } else {
          this.actualElement['formCreator'].get('text').clearValidators();
        }
        this.actualElement['formCreator'].get('text').updateValueAndValidity();
      }
    })
    this.subscribeArray.push(sbscr3);

    this.genericCreatorService.idProductMktOptions = this.filterAutocomplete('id_product_mkt_autocomplete', this.genericCreatorService.id_product_mkt);
    this.genericCreatorService.idProductMktNoOptions = this.filterAutocomplete('id_product_mkt_no_autocomplete', this.genericCreatorService.id_product_mkt_no);
    this.genericCreatorService.idConditionOptions = this.filterAutocomplete('id_condition_autocomplete', this.genericCreatorService.id_condition);
    this.genericCreatorService.conditionInternalRoutingOptions = this.filterAutocomplete('condition_internal_routing_autocomplete', this.genericCreatorService.condition_internal_routing, "internal_routing");
    this.genericCreatorService.profilePermissionOptions = this.filterAutocomplete('profiles_permissions', this.genericCreatorService.permissions_group);
    this.genericCreatorService.idFunctionOptions = this.filterAutocomplete('id_function_autocomplete', this.genericCreatorService.functions);
    this.genericCreatorService.idConditionBadgeOptions = this.filterAutocomplete('id_condition_badge_autocomplete', this.genericCreatorService.id_condition);
    this.genericCreatorService.idQueryOptions = this.filterAutocomplete('id_query_autocomplete', this.genericCreatorService.queries);
    this.genericCreatorService.idQueryOptionsNGFOR = this.filterAutocomplete('id_query_ngfor_autocomplete', this.genericCreatorService.queries);

    // Add new item to the Data Validity expansion
    if (this.actualElement.excluding_data_type_fa_restrictions) {
      for (let item in this.actualElement['excluding_data_type_fa_restrictions']) {
        this.actualElement['excluding_data_type_fa_restrictions'][item]['excludingFaRestrictionForm'] = this.genericCreatorService.formDataValidity(this.actualElement['excluding_data_type_fa_restrictions'][item]);
        this.suscribeDataValidityForm(this.actualElement['excluding_data_type_fa_restrictions'][item]);
      }
    }

    // Add new item to the Restriction expansion
    if (this.actualElement.functional_area_restrictions) {
      for (let item in this.actualElement['functional_area_restrictions']) {
        this.actualElement['functional_area_restrictions'][item]['restrictionForm'] = this.genericCreatorService.formRestriction(this.actualElement['functional_area_restrictions'][item]);
        this.suscribeRestrictionForm(this.actualElement['functional_area_restrictions'][item]);
      }
    }

    if (this.actualElement['id_functional_type'] == 8 && this.actualElement['class_custom_original'] && this.actualElement['class_custom_original'].includes("generic-tab-full-width")) this.actualElement['formCreator'].controls['tabType'].setValue("true");

    if (this.actualElement['id_functional_type'] == 5 && this.actualElement['class_custom_original']) {
      for (let i in this.buttonsTypesClasses) {
        if (this.actualElement['class_custom_original'].includes(this.buttonsTypesClasses[i])) {
          this.actualElement['formCreator'].controls['buttonType'].setValue(this.buttonsTypesClasses[i]);
          break;
        }
      }
    }
  }

  updateSelectionsOnType() {
    this.genericCreatorService.id_functional_type = this.actualElement['id_functional_type'];
    this.filteredFunctionalSubTypes = this.genericCreatorService.functional_subtype.filter((item) => item.id_functional_type === this.genericCreatorService.id_functional_type);
    this.filteredAngularClasses = this.genericCreatorService.angular_classes.filter((item) => item.id_functional_type === this.genericCreatorService.id_functional_type);

    if (this.actualElement['id_functional_type'] === 5) {
      this.filteredCustomClasses = this.genericCreatorService.custom_classes.filter((item) => item.id_functional_type === 5 && !this.buttonsTypesClasses.includes(item.internal_name));
    } else {
      this.filteredCustomClasses = this.genericCreatorService.custom_classes.filter((item) => item.id_functional_type === this.genericCreatorService.id_functional_type);
    }

    console.log('FUNCTIONAL TYPE: ', this.genericCreatorService.id_functional_type)
    console.log('TIPO: ', this.genericCreatorService.arrayElementsTipo)
    console.log('FILTERED ANGULAR CLASSES: ', this.filteredAngularClasses)
    console.log('FILTERED CUSTOM CLASSES: ', this.filteredCustomClasses)
    console.log('FILTERED FUNCTIONAL SUBTYPES: ', this.filteredFunctionalSubTypes)
    this.setAngularClassDefault();
  }

  onTypeChange(event: any) {
    this.actualElement['id_functional_type'] = event.value;
    this.updateSelectionsOnType();
    this.refreshElement();
  }

  filterAutocomplete(field: string, array: any[], name = 'internal_name') {
    if (this.actualElement && this.actualElement['formCreator'] && this.actualElement['formCreator'].controls[field]) {
      return this.actualElement['formCreator'].controls[field].valueChanges.pipe(
        startWith(''),
        map((value: any) => this.genericCreatorService._filterAutocomplete(value || '', array, field, name)),
      );
    } else {
      return [];
    }
  }

  setAngularClassDefault() {
    switch (this.genericCreatorService.id_functional_type) {
      case 5:
        this.defaultAngularClass = this.filteredAngularClasses.filter(item => (item['id_class_angular'] === 2))[0]['internal_name'];
        break;
      case 6:
        this.defaultAngularClass = this.filteredAngularClasses.filter(item => (item['id_class_angular'] === 11))[0]['internal_name'];
        break;
      case 12:
        this.defaultAngularClass = this.filteredAngularClasses.filter(item => (item['id_class_angular'] === 15))[0]['internal_name'];
        break;

      default:
        this.defaultAngularClass = null;
    }

    this.actualElement['formCreator'].get('class').setValue(this.defaultAngularClass);
  }

  onToggle(event: any) {
    if (event.checked == true) {
      console.log('in true');
      this.actualElement['formCreator'].get('label').clearValidators();
      this.actualElement['child'] = [];
      this.actualElement['child'] = [];
    } else {
      this.actualElement['formCreator'].get('label').setValidators(Validators.required);
    }
    this.updateSelectionsOnType();
    this.actualElement['formCreator'].get('label').updateValueAndValidity();
    console.log('==>', this.actualElement['formCreator'])
  }

  suscribeDataValidityForm(obj) {
    let sbscr = obj['excludingFaRestrictionForm'].valueChanges.subscribe(value => {
      this.genericCreatorService.formValueSubscribe(value, obj);
    })
    this.genericCreatorService.companyTypeOptionsRestriction = obj['excludingFaRestrictionForm'].controls['companyTypes'].valueChanges.pipe(
      startWith(''),
      map((value: any) => this.genericCreatorService._filterAutocomplete(value || '', this.genericCreatorService.company_type, 'companyTypes')),
    );
    this.subscribeArray.push(sbscr);
  }

  suscribeRestrictionForm(obj) {
    let sbscr = obj['restrictionForm'].valueChanges.subscribe(value => {
      this.genericCreatorService.formValueSubscribe(value, obj);
    })
    this.genericCreatorService.companyTypeOptionsRestriction = obj['restrictionForm'].controls['companyTypes'].valueChanges.pipe(
      startWith(''),
      map((value: any) => this.genericCreatorService._filterAutocomplete(value || '', this.genericCreatorService.company_type, 'companyTypes')),
    );
    this.subscribeArray.push(sbscr);
  }


  drop(event: CdkDragDrop<object[]>) {
    console.log('caleld', event.currentIndex, event.previousIndex);
    let positionsMoved = event.currentIndex - event.previousIndex
    moveItemInArray(this.actualElement.child, event.previousIndex, event.currentIndex);
    this.actualElement.child.forEach((item, index) => {
      item['order_general'] = index
      if (item['mode_type'] == undefined) {
        item['mode_type'] = 'update';
      }
    })
  }

  /**
   * Option param id_functional_type, will be there in case of card sub element only
   * @param id_functional_type 
   */
  addNewItem(id_functional_type?: any) {
    console.log('this.actualElement**1', this.actualElement)
    let newObj = {}
    newObj = this.genericService.cloneVariable(this.genericCreatorService.defaultArray);
    this.genericCreatorService.prepareNewEleToAdd(newObj)
    newObj['id_functional_type'] = id_functional_type ? id_functional_type : this.genericCreatorService.actualSelectedFunctionalTypeChild;
    if (this.actualElement['id_functional_type'] === 11) {
      newObj['form_field'] = 1
    } else {
      newObj['form_field'] = 0
    }
    if (this.genericCreatorService.listElementOptionToggle || newObj['id_functional_type'] !== 105) newObj['child'] = [];
    this.genericCreatorService.filterTypes(this.genericCreatorService.actualSelectedFunctionalTypeChild);
    this.genericCreatorService.pushNewElementInParentChilds(this.actualElement, newObj)
    this.genericCreatorService.checkLanguageLoop(this.genericCreatorService.arrayElementsGenericCreator, this.genericCreatorService.currentLanguage)
    this.openSettingPopup(newObj)
  }

  addNewCardItem(type: any) {
    let id_functional_type: any;
    if (type === 'header') {
      id_functional_type = 114
    } else if (type === 'content') {
      id_functional_type = 115
    } else if (type === 'action') {
      id_functional_type = 116
    } else {
      id_functional_type = 140
    }
    this.addNewItem(id_functional_type);
  }

  openSettingPopup(element) {
    console.log(element)
    console.log('this.actualElement**', this.actualElement)
    this.genericCreatorService.isSubElement = true;
    this.genericCreatorService.actualSelectedSubElement = element;
    this.genericCreatorService.filterStatusOptions(element['id_functional_type']);
    this.genericCreatorService.expandedIndex = 1;
    const dialogRef = this.dialog.open(AddGenericElementDialogComponent, {});
    dialogRef.afterClosed().subscribe(added => {
      this.genericCreatorService.isSubElement = false;
      this.genericCreatorService.actualSelectedSubElement = undefined;
      this.genericCreatorService.expandedIndex = 8;
      if (added == undefined || !added) {
      } else {
        this.actualElement['child'].push(this.genericCreatorService.actualSelectedSubElement)
      }
      if (this.actualElement['id_functional_type'] == 100) {
        this.isHeaderAdded = this.actualElement['child'].some(e => e.id_functional_type == 114)
        this.isContentAdded = this.actualElement['child'].some(e => e.id_functional_type == 115)
        this.isActionAdded = this.actualElement['child'].some(e => e.id_functional_type == 116)
        this.isDynamicAdded = this.actualElement['child'].some(e => e.id_functional_type == 140)
      } else {
        this.isHeaderAdded = false;
        this.isContentAdded = false;
        this.isActionAdded = false;
        this.isDynamicAdded = false;
      }
      // if(this.genericCreatorService.listElementOptionToggle == true){
      //   console.log('In clear child condition');
      //   this.actualElement['child'] = [];
      //   this.actualElement['child'] = [];
      // }
    });
  }

  displayFn(item: any) {
    return item && item.internal_name ? item.internal_name : '';
  }

  displayRoutingFn(item: any) {
    return item && item.internal_routing ? item.internal_routing : '';
  }

  addNewExcludingFaRestrictionObj() {
    let obj = this.genericService.cloneVariable(this.genericCreatorService.get_all_columns_excluding_data_type_fa_restrictions);
    obj['excludingFaRestrictionForm'] = this.genericCreatorService.formDataValidity(obj);
    obj['mode_type'] = 'insert';
    this.suscribeDataValidityForm(obj);
    this.actualElement.excluding_data_type_fa_restrictions.push(obj);
  }

  removeExcludingFaRestrictionObj(index) {
    this.actualElement.excluding_data_type_fa_restrictions[index]['mode_type'] = 'delete'
    console.log(this.actualElement.excluding_data_type_fa_restrictions)
  }
  removeRestrictionObj(index) {
    this.actualElement.functional_area_restrictions[index]['mode_type'] = 'delete'
  }

  addNewRestriction() {
    let obj = this.genericService.cloneVariable(this.genericCreatorService.get_all_columns_fa_restrictions);
    obj['restrictionForm'] = this.genericCreatorService.formRestriction(obj);
    obj['mode_type'] = 'insert';
    this.suscribeRestrictionForm(obj);
    this.actualElement.functional_area_restrictions.push(obj)
    console.log('this.actualElement', this.actualElement);
  }

  // Add Company
  selectedCompany(event: MatAutocompleteSelectedEvent, index: any): void {
    this.actualElement['excluding_data_type_fa_restrictions'][index]['excludingFaRestrictionForm'].controls['id_company_type'].value.push(event.option.viewValue);
    this.companyTypeInput.nativeElement.value = '';
    this.actualElement['excluding_data_type_fa_restrictions'][index]['excludingFaRestrictionForm'].controls['companyTypes'].setValue(null);
  }

  // Remove company from added companies
  removeCompany(item: any, itemIndex: any): void {
    const index = this.actualElement['excluding_data_type_fa_restrictions'][itemIndex]['excludingFaRestrictionForm'].controls['id_company_type'].value.indexOf(item);
    if (index >= 0) {
      this.actualElement['excluding_data_type_fa_restrictions'][itemIndex]['excludingFaRestrictionForm'].controls['id_company_type'].value.splice(index, 1);
    }
  }

  // Add Company For Restriction Expansion
  selectedCompanyRestriction(event: MatAutocompleteSelectedEvent, index: any): void {
    this.actualElement['functional_area_restrictions'][index]['restrictionForm'].controls['id_company_type'].value.push(event.option.viewValue);
    this.companyTypeInputRestriction.nativeElement.value = '';
    this.actualElement['functional_area_restrictions'][index]['restrictionForm'].controls['companyTypes'].setValue(null);
  }

  // Remove company from added companies For Restriction Expansion
  removeCompanyRestriction(item: any, itemIndex: any): void {
    const index = this.actualElement['functional_area_restrictions'][itemIndex]['restrictionForm'].controls['selectedCompanyTypes'].value.indexOf(item);
    if (index >= 0) {
      this.actualElement['functional_area_restrictions'][itemIndex]['restrictionForm'].controls['selectedCompanyTypes'].value.splice(index, 1);
    }
  }

  // Add Profile Permission
  selectedProfilePermission(event: MatAutocompleteSelectedEvent, index: any): void {
    this.actualElement['formCreator'].controls['permissions_group_functional_area'].value.push(event.option.viewValue);
    this.profilePermissionInput.nativeElement.value = '';
    this.actualElement['formCreator'].controls['profiles_permissions'].setValue(null);
  }

  // Remove Profile Permission
  removeProfilePermission(item: any, itemIndex: any): void {
    const index = this.actualElement['formCreator'].controls['permissions_group_functional_area'].value.indexOf(item);
    if (index >= 0) {
      this.actualElement['formCreator'].controls['permissions_group_functional_area'].value.splice(index, 1);
    }
  }

  onTabType(event) {
    if (event.checked == true) {
      this.actualElement['class_custom_original'] = "generic-tab-full-width " + this.actualElement['class_custom_original'];
      this.actualElement['class_custom'] = "generic-tab-full-width " + this.actualElement['class_custom'];
    } else {
      this.actualElement['class_custom_original'] = this.actualElement['class_custom_original'].replace("generic-tab-full-width ", "");
      this.actualElement['class_custom'] = this.actualElement['class_custom'].replace("generic-tab-full-width ", "");
    }
    this.actualElement['class_custom_original'] = this.actualElement['class_custom_original'].replace("null", "");
    this.actualElement['class_custom'] = this.actualElement['class_custom'].replace("null", "");
    this.genericService.asignFrontend([this.actualElement], this.actualElement['id_functional_parent_initial']);
    this.actualElement['formCreator'].controls['class_custom_original'].setValue(this.actualElement['class_custom_original']);
  }

  setValueForClass(newValue) {
    console.log('this.actualElement', this.actualElement)
    console.log('newValue', newValue)
    for (let i in this.buttonsTypesClasses) {
      if (!this.actualElement['class_custom_original']) this.actualElement['class_custom_original'] = this.buttonsTypesClasses[i];
      if (!this.actualElement['class_custom']) this.actualElement['class_custom'] = this.buttonsTypesClasses[i];

      this.actualElement['class_custom_original'] = this.actualElement['class_custom_original'].replace(this.buttonsTypesClasses[i], "");
      this.actualElement['class_custom'] = this.actualElement['class_custom'].replace(this.buttonsTypesClasses[i], "");
    }
    this.actualElement['class_custom_original'] = newValue + " " + this.actualElement['class_custom_original'];
    this.actualElement['class_custom'] = this.actualElement['class_custom'];
    this.actualElement['class_custom_original'] = this.actualElement['class_custom_original'].replace("null", "");
    this.actualElement['class_custom'] = this.actualElement['class_custom'].replace("null", "");
    this.actualElement['formCreator'].controls['class_custom_original_creator'].setValue(this.genericService.cloneVariable(this.actualElement['class_custom_original']).split(' ').filter((item) => item !== ''));

    this.genericService.asignFrontend([this.actualElement], this.actualElement['id_functional_parent_initial']);
  }

  public onClassCustomOriginalSelect(optionSelected) {
    if (this.actualElement['id_functional_type'] === 5) {
      const firstCustomClassValue = this.actualElement['class_custom_original'].split(' ')[0];

      const { joinedOptionsArray, selectedOptions } = this.removeRepeatedClasses(optionSelected, firstCustomClassValue);

      this.actualElement['class_custom_original'] = joinedOptionsArray.join(' ');
      this.actualElement['class_custom_original_creator'] = selectedOptions;

      return;
    }

    if (this.actualElement['id_functional_type'] === 101) {
      const firstCustomClassValue = this.actualElement['class_custom'].split(' ')[0];

      const { joinedOptionsArray, selectedOptions } = this.removeRepeatedClasses(optionSelected, firstCustomClassValue);

      this.actualElement['class_custom'] = joinedOptionsArray.join(' ').replace('null', '');
      this.actualElement['class_custom_original_creator'] = selectedOptions;

      return;
    }

    if (this.actualElement['id_functional_type'] === 6 || this.actualElement['id_functional_type'] === 12) {
      this.actualElement['class_custom'] = optionSelected.value;
      const firstCustomClassValue = this.actualElement['class_custom'];

      const { joinedOptionsArray, selectedOptions } = this.removeRepeatedClasses(optionSelected, firstCustomClassValue);

      this.actualElement['class_custom'] = joinedOptionsArray.join(' ').replace('null', '');
      this.actualElement['class_custom_original_creator'] = selectedOptions;

      return;
    }
  }

  private removeRepeatedClasses(classesArray, firstCustomClassValue) {
    const selectedOptions = classesArray.value;
    const joinedOptions = [firstCustomClassValue, ...selectedOptions];
    const joinedOptionsSet = new Set(joinedOptions);
    const joinedOptionsArray = Array.from(joinedOptionsSet);

    return { joinedOptionsArray, selectedOptions };
  }

  loadTables() {
    if (this.actualElement['id_db']) {
      let idDb = this.actualElement['id_db'];
      if (this.genericCreatorService.loadTables[idDb] === undefined && this.genericCreatorService.tables[idDb] === undefined && this.conditionDependingOfFuntionalTypes('bd_table_autocomplete')) {
        this.genericCreatorService.loadTables[idDb] = true;
        this.genericCreatorService.getTablesFromDatabase(idDb);
      }
    }
    return true;
  }

  loadFields() {
    if (this.conditionDependingOfFuntionalTypes('bd_field_autocomplete')) {
      setTimeout(() => {
        if (this.actualElement['id_db'] && this.actualElement['bd_table']) {
          let idDb = this.actualElement['id_db'];
          let bdTable = this.actualElement['bd_table'];
          if (this.genericCreatorService.fields[idDb] === undefined || this.genericCreatorService.fields[idDb][bdTable] === undefined) {
            this.genericCreatorService.getFieldsFromTable(idDb, bdTable);
          }
        }
      });
    }
  }

  initializeTables() {
    let idDb = this.actualElement['id_db'];
    if (!this.genericCreatorService.tables[idDb] || this.genericCreatorService.tables[idDb] === "") return false;
    if (!this.tablesInitialized[idDb]) {
      this.genericCreatorService.tablesOptions = this.filterAutocomplete('bd_table_autocomplete', this.genericCreatorService.tables[idDb]);
      this.loadFields();
      this.tablesInitialized[idDb] = true;
    }
    return this.genericCreatorService.tables[idDb] && this.genericCreatorService.tables[idDb].length > 0;
  }

  initializeFields() {
    let idDb = this.actualElement['id_db'];
    let bdTable = this.actualElement['bd_table'];
    if (!this.genericCreatorService.fields[idDb] || this.genericCreatorService.fields[idDb] === "") return false;
    if (!this.genericCreatorService.fields[idDb][bdTable] || this.genericCreatorService.fields[idDb][bdTable] === "") return false;
    if (!this.fieldsInitialized[idDb] || !this.fieldsInitialized[idDb][bdTable]) {
      this.genericCreatorService.fieldsOptions = this.filterAutocomplete('bd_field_autocomplete', this.genericCreatorService.fields[idDb][[bdTable]]);
      if (!this.fieldsInitialized[idDb]) this.fieldsInitialized[idDb] = {};
      this.fieldsInitialized[idDb][bdTable] = true;
    }
    return this.genericCreatorService.fields[idDb][bdTable] && this.genericCreatorService.fields[idDb][bdTable].length > 0;
  }

  fillAutocompleteOption(field, value, objLabel = null) {
    let val = value;
    if (objLabel !== null) {
      val = value[objLabel];
    }
    if (field == "condition_internal_routing") {
      val = "/" + val;
    }
    this.actualElement[field] = this.genericService.cloneVariable(val);
  }

  conditionDependingOfFuntionalTypesExpansion(fields) {
    for(let i in fields) {
      if(this.conditionDependingOfFuntionalTypes(fields[i])) return true;
    }
  }

  conditionDependingOfFuntionalTypes(field) {
    return this.genericCreatorService.conditionDependingOfFuntionalTypes(field, this.actualElement['id_functional_type']);
  }

  changeSelectorHide(value) {
    if(value == 1) {
      this.actualElement['hide'] = 1;
      this.actualElement['hide_initial'] = 1;
      this.actualElement['hide_xs'] = 0;
      this.actualElement['hide_s'] = 0;
      this.actualElement['hide_m'] = 0;
      this.actualElement['hide_l'] = 0;
      this.actualElement['hide_xl'] = 0;
    } else {
      this.actualElement['hide'] = 0;
      this.actualElement['hide_initial'] = 0;
    }
    console.log("adfadaad", value, this.actualElement['hide']);
  }

  refreshElement() {
    this.actualElement['showSubCreator'] = true;
    setTimeout(() => {
      this.actualElement['showSubCreator'] = false;
    });
  }

  changeIconType(event) {
    this.actualElement['icon_type_original'] = event.value;
    this.actualElement['icon_type'] = event.value;
    this.actualElement['initializedElement'] = false;
    this.refreshElement(); 
  }

  changeHide(event) {
    setTimeout(() => {    
      if(event.value == 0) {
        this.actualElement['hide_xs'] = 0;
        this.actualElement['hide_s'] = 0;
        this.actualElement['hide_m'] = 0;
        this.actualElement['hide_l'] = 0;
        this.actualElement['hide_xl'] = 0;
      }
      if(event.value == 1) {
        this.actualElement['hide_xs'] = 1;
        this.actualElement['hide_s'] = 1;
        this.actualElement['hide_m'] = 1;
        this.actualElement['hide_l'] = 1;
        this.actualElement['hide_xl'] = 1;
      }
    }, 1000);
  }

  onChangeAlign() {
    setTimeout(() => {
      this.actualElement['align_general'] = this.actualElement['align_horizontal'] + " " + this.actualElement['align_vertical'];
      this.actualElement['align_xs'] = this.actualElement['align_general'];
      this.actualElement['align_s'] = this.actualElement['align_general'];
      this.actualElement['align_m'] = this.actualElement['align_general'];
      this.actualElement['align_l'] = this.actualElement['align_general'];
      this.actualElement['align_xl'] = this.actualElement['align_general'];
    }, 200);
  }
  
  onChangeTextAlign() {
    setTimeout(() => {
      // Obtiene el valor actual del estilo
      let currentStyle = this.actualElement['style'];
      // Obtiene el valor de text-align
      let textAlign = this.actualElement['text_align'];
      
      // Expresión regular para buscar la propiedad text-align en el estilo
      let textAlignRegex = /text-align\s*:\s*[^;]+;/;
      
      if(!currentStyle || currentStyle == "null" || currentStyle == 0) currentStyle = "";
      // Si ya existe text-align, reemplázalo. De lo contrario, añádelo.
      if (textAlignRegex.test(currentStyle)) {
          // Reemplaza el text-align existente
          this.actualElement['style'] = currentStyle.replace(textAlignRegex, 'text-align: ' + textAlign + ';');
      } else {
          // Añade el text-align al final
          this.actualElement['style'] = currentStyle + ' text-align: ' + textAlign + ';';
      }
        // Establecer el nuevo valor de estilo en el formulario
        this.actualElement['formCreator'].get('style').setValue(this.actualElement['style']);
    }, 200);
  }
}
