import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {MatIconModule} from '@angular/material/icon'
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {MatTableDataSource} from "@angular/material/table";
import {EndpointService} from "../../../services/endpoint/endpoint.service";
import {Strings} from "../../../../strings/strings";
import {WorkflowEventBody} from "../../objects/workflow-event-body";
import {DynamicReportDetailsComponent} from "../dynamic-reportdetails/dynami-reportdetails.component";
import {DynamicReportBody} from "./body/dynamic-report-body";
import {AuthService} from "../../../services/auth/auth.service";
import {IconScreenComponent} from "../dynamic-reportdetails/iconscreen/icon-screen.component";
import {MatDialog} from "@angular/material/dialog";
import {AutoPlantillaComponent} from "../../../auto-plantilla/auto-plantilla.component";
import {AutoSnackbarComponent} from "../../../auto-plantilla/auto-snackbar/auto-snackbar.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AutoAdviceSuperiorComponent} from "../../../auto-plantilla/auto-advice-superior/auto-advice-superior.component";
import {AutoAdviceHomeComponent} from "../../../auto-plantilla/auto-advice-home/auto-advice-home.component";
import {AutoPopupComponent} from "../../../auto-plantilla/auto-popup/auto-popup.component";
import {AutoBottomSheetComponent} from "../../../auto-plantilla/auto-bottom-sheet/auto-bottom-sheet.component";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {forkJoin} from "rxjs";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MessageDialogComponent} from "../../../utils/message-dialog/message-dialog.component";

@Component({
  selector: 'dynamic-report',
  templateUrl: './dynamic-report.component.html',
  styleUrls: ['./dynamic-report.component.css']
})

export class DynamicReportComponent implements OnInit{

  //public autoPlantilla: AutoPlantillaComponent;

  @ViewChild('DynamicReportDetailsComponent') public DynamicReportDetailsComponent: DynamicReportDetailsComponent;

  @Input() inputFilter;
  @Input() journeyArray;

  public iconD;
  public colorD;
  public typeD;
  public toolD;
  public activeD;

  public strings = Strings.EVENTS;
  private selectedID: any;
  public showReport: boolean;
  public edit: boolean;

  private idEvent: any;

  public bodyReport: DynamicReportBody;
  public selectedEvent : WorkflowEventBody;

  public containerArray : {idType: number, container: any, color: string, id: number} [] = new Array();
  public buttonArray: {active: boolean, name: string, text: string, tooltip: string, actionArray: [], colorText: string,
    colorBackground: string, colorEdge: string, icon: string, icon_color: string, icon_type: number, icon_tooltipe: string,
    icon_active: boolean, link: string, pantalla: number, noti: number, order: number} [] = new Array();
  public iconArray: {icon: string, icon_color: string, icon_type: number, icon_tooltipe: string, icon_active: boolean} [] = new Array();

  public stepArray;

  public dropContainerArray;
  public dropStepArray;

  //@ViewChild('DynamicReportDetailsComponent') public DynamicReportDetailsComponent: DynamicReportDetailsComponent;
  @ViewChild('AutoPlantillaComponent') public AutoPlantillaComponent: AutoPlantillaComponent;

  @Output() sendEditReport = new EventEmitter();
  @Output() sendShowOtherButtons = new EventEmitter();
  @Output() updatePlantillas = new EventEmitter();
  @Output() iniPlantillas = new EventEmitter();
  //@Output() applyFilter = new EventEmitter();

  @Input() plantillasArray;
  @Input() notification;
  @Input() notificationSuperior;
  @Input() dataSourcePlantilla;
  @Input() notificationSuperiorHome;
  public eventStatusList = Array();

  displayedColumns: string[] = ['operations', 'name', 'type', 'status',  'creator', 'modification_date', 'execute_ego_cron'];

  public load = false;

  constructor(public material: MatIconModule,
              public endpointService: EndpointService,
              public authService: AuthService,
              private snackBar:MatSnackBar,
              private dialog: MatDialog,
              private bottomSheet: MatBottomSheet) { }

  ngOnInit(): void {

    this.showReport = false;
    this.edit = false;

    this.dropContainerArray = new Array();

    this.eventStatusList.push(
      'Parado', 'Activo'
    );

    this.resetButtons();

    this.iniPlantillas.emit();

  }

  public addNewWorkflowReport() {

    this.selectedID = -1;
    this.idEvent = -1;
    this.bodyReport = new DynamicReportBody();
    this.selectedEvent = new WorkflowEventBody();

    this.dropContainerArray = new Array();
    this.stepArray = new Array();
    this.dropStepArray = new Array();

    this.resetButtons();

    this.containerArray = new Array();
    this.iconArray = new Array();

    this.containerArray.push({idType: 1, container: null, color: "#000000", id: 0});
    this.iconArray.push({icon: "event", icon_color: "#000000", icon_type: 1, icon_tooltipe: null, icon_active: true});

    this.stepArray.push({container : this.containerArray, buttons: this.buttonArray, name: "Step 1", id: 0, icon: this.iconArray});

    this.sendShowOtherButtons.emit();
    this.showReport = true;
    this.edit = false;

    // this.router.navigate([this.routes.EVENTS_DETAIL, -1]);
  }

  public editWorkflowReport(i) {

    this.selectedID = i;

    this.bodyReport = new DynamicReportBody();
    this.selectedEvent = new WorkflowEventBody();

    this.resetButtons();

    this.containerArray = new Array();
    this.stepArray = new Array();
    this.dropContainerArray = new Array();
    this.dropStepArray = new Array();

    this.endpointService.getPlantilla(this.selectedID, this.authService.empresaId).subscribe(data =>{

      this.idEvent = data['response']['plantilla'][0].id_event;

      this.bodyReport.handlePlantillaInfoResponse(data['response']['plantilla'][0], data['response']['screen'], data['response']['icon']);

      if(data['response']['plantilla'][0].id_tipo == 1 || data['response']['plantilla'][0].id_tipo == 2){

        for(let x = 0; x < data['response']['step']['length']; x++){

          if(data['response']['step'][x]['cont']['length'] > 0){
            for (let i = 0 ; i < data['response']['step'][x]['cont']['length'] ; i++)
              this.containerArray.push({idType: data['response']['step'][x]['cont'][i].id_plantillas_ego_tipo_contenido,
                container: data['response']['step'][x]['cont'][i].contenido, color: data['response']['step'][x]['cont'][i].contenido_color,
                id: data['response']['step'][x]['cont'][i].id});
          }

          let iconArray = [];

          if(data['response']['step'][x]['icon'][0] != null)
            iconArray.push({icon: data['response']['step'][x]['icon'][0].icon_nombre,
            icon_color: data['response']['step'][x]['icon'][0].icon_color,
            icon_type: data['response']['step'][x]['icon'][0].id_icon_clase,
            icon_tooltipe: data['response']['step'][x]['icon'][0].icon_tootltip,
            icon_active: true});
          else{
            iconArray.push({icon: "event", icon_color: "#000000", icon_type: 1, icon_tooltipe: null, icon_active: false});
          }

          if(data['response']['step'][x]['button1'][0] != null) {

            if(data['response']['step'][x]['iconButton1']['length'] > 0){

              this.iconD = data['response']['step'][x]['iconButton1'][0].icon_nombre;
              this.colorD = data['response']['step'][x]['iconButton1'][0].icon_color;
              this.typeD = data['response']['step'][x]['iconButton1'][0].id_icon_clase;
              this.toolD = data['response']['step'][x]['iconButton1'][0].icon_tootltip;
              this.activeD = true;

            } else {

              this.iconD = "event";
              this.colorD = "#000000";
              this.typeD = 1;
              this.toolD = null;
              this.activeD = false;

            }

            this.buttonArray[0] = {
              active: true,
              name: "Botón 1: ",
              text: data['response']['step'][x]['button1'][0].button_texto,
              tooltip: data['response']['step'][x]['button1'][0].button_tooltip,
              actionArray: data['response']['step'][x]['button1'][0].action,
              colorText: data['response']['step'][x]['button1'][0].button_color_texto,
              colorBackground: data['response']['step'][x]['button1'][0].button_color_fondo,
              colorEdge: data['response']['step'][x]['button1'][0].button_color_borde,
              icon: this.iconD,
              icon_color: this.colorD,
              icon_type: this.typeD,
              icon_tooltipe: this.toolD,
              icon_active: this.activeD,
              link: data['response']['step'][x]['button1'][0].button_accion_link,
              pantalla: data['response']['step'][x]['button1'][0].id_pantalla,
              noti: data['response']['step'][x]['button1'][0].id_plantilla_ego_button,
              order: 1
            };

          }else{

            this.iconD = "event";
            this.colorD = "#000000";
            this.typeD = 1;
            this.toolD = null;
            this.activeD = false;

            this.buttonArray[0] = {
              active: false,
              name: "Botón 1: ",
              text: null,
              tooltip: null,
              actionArray: [],
              colorText: "#000000",
              colorBackground: "#000000",
              colorEdge: "#000000",
              icon: this.iconD,
              icon_color: this.colorD,
              icon_type: this.typeD,
              icon_tooltipe: this.toolD,
              icon_active: this.activeD,
              link: null,
              pantalla: null,
              noti: null,
              order: 1
            };
          }

          if(data['response']['step'][x]['button2'][0] != null) {

            if(data['response']['step'][x]['iconButton2']['length'] > 0){

              this.iconD = data['response']['step'][x]['iconButton2'][0].icon_nombre;
              this.colorD = data['response']['step'][x]['iconButton2'][0].icon_color;
              this.typeD = data['response']['step'][x]['iconButton2'][0].id_icon_clase;
              this.toolD = data['response']['step'][x]['iconButton2'][0].icon_tootltip;
              this.activeD = true;

            }else{

              this.iconD = "event";
              this.colorD = "#000000";
              this.typeD = 1;
              this.toolD = null;
              this.activeD = false;

            }

            this.buttonArray[1] = {
              active: true,
              name: "Botón 2: ",
              text: data['response']['step'][x]['button2'][0].button_texto,
              tooltip: data['response']['step'][x]['button2'][0].button_tooltip,
              actionArray: data['response']['step'][x]['button2'][0].action,
              colorText: data['response']['step'][x]['button2'][0].button_color_texto,
              colorBackground: data['response']['step'][x]['button2'][0].button_color_fondo,
              colorEdge: data['response']['step'][x]['button2'][0].button_color_borde,
              icon: this.iconD,
              icon_color: this.colorD,
              icon_type: this.typeD,
              icon_tooltipe: this.toolD,
              icon_active: this.activeD,
              link: data['response']['step'][x]['button2'][0].button_accion_link,
              pantalla: data['response']['step'][x]['button2'][0].id_pantalla,
              noti: data['response']['step'][x]['button2'][0].id_plantilla_ego_button,
              order: 2
            };
          }else{

            //.log("2 VACIO")

            this.iconD = "event";
            this.colorD = "#000000";
            this.typeD = 1;
            this.toolD = null;
            this.activeD = false;

            this.buttonArray[1] = {
              active: false,
              name: "Botón 2: ",
              text: null,
              tooltip: null,
              actionArray: [],
              colorText: "#000000",
              colorBackground: "#000000",
              colorEdge: "#000000",
              icon: this.iconD,
              icon_color: this.colorD,
              icon_type: this.typeD,
              icon_tooltipe: this.toolD,
              icon_active: this.activeD,
              link: null,
              pantalla: null,
              noti: null,
              order: 2
            };
          }

          if(data['response']['step'][x]['button3'][0] != null){

            if(data['response']['step'][x]['iconButton3']['length'] > 0){

              this.iconD = data['response']['step'][x]['iconButton3'][0].icon_nombre;
              this.colorD = data['response']['step'][x]['iconButton3'][0].icon_color;
              this.typeD = data['response']['step'][x]['iconButton3'][0].id_icon_clase;
              this.toolD = data['response']['step'][x]['iconButton3'][0].icon_tootltip;
              this.activeD = true;

            }else{

              this.iconD = "event";
              this.colorD = "#000000";
              this.typeD = 1;
              this.toolD = null;
              this.activeD = false;

            }

            this.buttonArray[2] = {
              active: true, name: "Botón 3: ",
              text: data['response']['step'][x]['button3'][0].button_texto,
              tooltip: data['response']['step'][x]['button3'][0].button_tooltip,
              actionArray: data['response']['step'][x]['button3'][0].action,
              colorText: data['response']['step'][x]['button3'][0].button_color_texto,
              colorBackground: data['response']['step'][x]['button3'][0].button_color_fondo,
              colorEdge: data['response']['step'][x]['button3'][0].button_color_borde,
              icon: this.iconD,
              icon_color: this.colorD,
              icon_type: this.typeD,
              icon_tooltipe: this.toolD,
              icon_active: this.activeD,
              link: data['response']['step'][x]['button3'][0].button_accion_link,
              pantalla: data['response']['step'][x]['button3'][0].id_pantalla,
              noti: data['response']['step'][x]['button3'][0].id_plantilla_ego_button,
              order: 3
            };

          }else{

            this.iconD = "event";
            this.colorD = "#000000";
            this.typeD = 1;
            this.toolD = null;
            this.activeD = false;

            this.buttonArray[2] = {
              active: false,
              name: "Botón 3: ",
              text: null,
              tooltip: null,
              actionArray: [],
              colorText: "#000000",
              colorBackground: "#000000",
              colorEdge: "#000000",
              icon: this.iconD,
              icon_color: this.colorD,
              icon_type: this.typeD,
              icon_tooltipe: this.toolD,
              icon_active: this.activeD,
              link: null,
              pantalla: null,
              noti: null,
              order: 3
            };
          }

          this.stepArray.push({container : this.containerArray,
                               buttons: this.buttonArray,
                               name: data['response']['step'][x]['nombre_step'],
                               id: data['response']['step'][x]['id_step'],
                               icon: iconArray});

          this.resetButtons();
          this.containerArray = new Array();

        }

      }else{

        if(data['response']['button1'][0]) {

          if(data['response']['iconButton1']['length'] > 0){

            this.iconD = data['response']['iconButton1'][0].icon_nombre;
            this.colorD = data['response']['iconButton1'][0].icon_color;
            this.typeD = data['response']['iconButton1'][0].id_icon_clase;
            this.toolD = data['response']['iconButton1'][0].icon_tootltip;
            this.activeD = true;

          } else {

            this.iconD = "event";
            this.colorD = "#000000";
            this.typeD = 1;
            this.toolD = null;
            this.activeD = false;

          }

          this.buttonArray[0] = {
            active: true,
            name: "Botón 1: ",
            text: data['response']['button1'][0].button_texto,
            tooltip: data['response']['button1'][0].button_tooltip,
            actionArray: data['response']['button1'][0].action,
            colorText: data['response']['button1'][0].button_color_texto,
            colorBackground: data['response']['button1'][0].button_color_fondo,
            colorEdge: data['response']['button1'][0].button_color_borde,
            icon: this.iconD,
            icon_color: this.colorD,
            icon_type: this.typeD,
            icon_tooltipe: this.toolD,
            icon_active: this.activeD,
            link: data['response']['button1'][0].button_accion_link,
            pantalla: data['response']['button1'][0].id_pantalla,
            noti: data['response']['button1'][0].id_plantilla_ego_button,
            order: 1
          };
        }else{

          this.iconD = "event";
          this.colorD = "#000000";
          this.typeD = 1;
          this.toolD = null;
          this.activeD = false;

          this.buttonArray[0] = {
            active: false,
            name: "Botón 1: ",
            text: null,
            tooltip: null,
            actionArray: [],
            colorText: "#000000",
            colorBackground: "#000000",
            colorEdge: "#000000",
            icon: this.iconD,
            icon_color: this.colorD,
            icon_type: this.typeD,
            icon_tooltipe: this.toolD,
            icon_active: this.activeD,
            link: null,
            pantalla: null,
            noti: null,
            order: 1
          };
        }

        if(data['response']['button2'][0]) {

          if(data['response']['iconButton2']['length'] > 0){

            this.iconD = data['response']['iconButton2'][0].icon_nombre;
            this.colorD = data['response']['iconButton2'][0].icon_color;
            this.typeD = data['response']['iconButton2'][0].id_icon_clase;
            this.toolD = data['response']['iconButton2'][0].icon_tootltip;
            this.activeD = true;

          }else{

            this.iconD = "event";
            this.colorD = "#000000";
            this.typeD = 1;
            this.toolD = null;
            this.activeD = false;

          }

          this.buttonArray[1] = {
            active: true,
            name: "Botón 2: ",
            text: data['response']['button2'][0].button_texto,
            tooltip: data['response']['button2'][0].button_tooltip,
            actionArray: data['response']['button2'][0].action,
            colorText: data['response']['button2'][0].button_color_texto,
            colorBackground: data['response']['button2'][0].button_color_fondo,
            colorEdge: data['response']['button2'][0].button_color_borde,
            icon: this.iconD,
            icon_color: this.colorD,
            icon_type: this.typeD,
            icon_tooltipe: this.toolD,
            icon_active: this.activeD,
            link: data['response']['button2'][0].button_accion_link,
            pantalla: data['response']['button2'][0].id_pantalla,
            noti: data['response']['button2'][0].id_plantilla_ego_button,
            order: 2
          };
        }else{

          //.log("2 VACIO")

          this.iconD = "event";
          this.colorD = "#000000";
          this.typeD = 1;
          this.toolD = null;
          this.activeD = false;

          this.buttonArray[1] = {
            active: false,
            name: "Botón 2: ",
            text: null,
            tooltip: null,
            actionArray: [],
            colorText: "#000000",
            colorBackground: "#000000",
            colorEdge: "#000000",
            icon: this.iconD,
            icon_color: this.colorD,
            icon_type: this.typeD,
            icon_tooltipe: this.toolD,
            icon_active: this.activeD,
            link: null,
            pantalla: null,
            noti: null,
            order: 2
          };
        }

        if(data['response']['button3'][0]){

          if(data['response']['iconButton3']['length'] > 0){

            this.iconD = data['response']['iconButton3'][0].icon_nombre;
            this.colorD = data['response']['iconButton3'][0].icon_color;
            this.typeD = data['response']['iconButton3'][0].id_icon_clase;
            this.toolD = data['response']['iconButton3'][0].icon_tootltip;
            this.activeD = true;

          }else{

            this.iconD = "event";
            this.colorD = "#000000";
            this.typeD = 1;
            this.toolD = null;
            this.activeD = false;

          }

          this.buttonArray[2] = {
            active: true, name: "Botón 3: ",
            text: data['response']['button3'][0].button_texto,
            tooltip: data['response']['button3'][0].button_tooltip,
            actionArray: data['response']['button3'][0].action,
            colorText: data['response']['button3'][0].button_color_texto,
            colorBackground: data['response']['button3'][0].button_color_fondo,
            colorEdge: data['response']['button3'][0].button_color_borde,
            icon: this.iconD,
            icon_color: this.colorD,
            icon_type: this.typeD,
            icon_tooltipe: this.toolD,
            icon_active: this.activeD,
            link: data['response']['button3'][0].button_accion_link,
            pantalla: data['response']['button3'][0].id_pantalla,
            noti: data['response']['button3'][0].id_plantilla_ego_button,
            order: 3
          };
        }else{

          this.iconD = "event";
          this.colorD = "#000000";
          this.typeD = 1;
          this.toolD = null;
          this.activeD = false;

          this.buttonArray[2] = {
            active: false,
            name: "Botón 3: ",
            text: null,
            tooltip: null,
            actionArray: [],
            colorText: "#000000",
            colorBackground: "#000000",
            colorEdge: "#000000",
            icon: this.iconD,
            icon_color: this.colorD,
            icon_type: this.typeD,
            icon_tooltipe: this.toolD,
            icon_active: this.activeD,
            link: null,
            pantalla: null,
            noti: null,
            order: 3
          };
        }

      }

      this.sendEditReport.emit();
      this.showReport = true;
      this.edit = true;

    });

    // this.router.navigate([this.routes.EVENTS_DETAIL, i]);
  }

  public deleteWorkflowReport(id) {

  }

  public confirmDelete(id) {

    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        message: '¿Seguro que quiere eliminar esta plantilla?',
        labelButton1: 'Cancelar',
        labelButton2: 'Eliminar'
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data === 2) this.endpointService.dropPlantilla(id).subscribe(data =>{
        this.updatePlantillas.emit();
      });
    });

  }

  public onClickReturn() {
    this.showReport = false;
  }

  public saveReportTemplate(){

    this.endpointService.saveReport(this.bodyReport, this.containerArray, this.buttonArray, this.authService.userId, this.stepArray).subscribe( data =>{

      //this.endpointService.insertEvent(this.selectedEvent, 2).subscribe( data =>{});

      if(this.bodyReport.idType != 6){// &&  this.bodyReport.useMode != 0) {
      this.DynamicReportDetailsComponent.createEvent(data['response'].plantilla);
      }
      this.showReport = false;
      this.updatePlantillas.emit();

    });
  }

  public transformFieldTagsForBackend(value: string) {

    const splitted = value.split('<mark id="chip">['); //class="marker-green"
    let stringForBackend = splitted[0];

    for (let i = 1; i < splitted.length; i++) {

      const subsplit = splitted[i].split(']'+ '</mark>');
      stringForBackend +=  '---' + subsplit[0] + '---';
      if (subsplit.length >= 1) {
        for (let j = 1; j < subsplit.length - 1; j++) {
          stringForBackend += subsplit[j] + '" />';
        }
        stringForBackend += subsplit[subsplit.length - 1];
      }
    }
    return stringForBackend;
  }

  public saveEditReportTemplate(){
    if (this.bodyReport.personalizado === true) {
      let value = this.DynamicReportDetailsComponent.htmlContent;
      let mensaje = this.transformFieldTagsForBackend(value);
      this.bodyReport.mensaje = mensaje;
    }
    this.endpointService.updateReport(this.bodyReport, this.selectedID, this.containerArray, this.buttonArray, this.dropContainerArray, this.stepArray, this.dropStepArray).subscribe( data =>{
      if(this.bodyReport.idType != 6 &&  this.bodyReport.useMode != 0) {
        this.DynamicReportDetailsComponent.updateEvent();
      }
      this.showReport = false;
      this.updatePlantillas.emit();
    });


  }

  public preview(){

    //this.AutoPlantillaComponent.openDemo(this.bodyReport, this.buttonArray, this.containerArray);
    this.AutoPlantillaComponent.openDemo(this.bodyReport, this.buttonArray, this.stepArray);

    // @ts-ignore
    /*this.autoPlantilla = new AutoPlantillaComponent();

    switch (this.bodyReport.idType) {
      case 1:
        this.AutoPlantillaComponent.openBottomSheet(this.bodyReport, this.buttonArray, this.containerArray);
        break;
      case 2:
        this.AutoPlantillaComponent.openPopUp(this.bodyReport, this.buttonArray, this.containerArray);
        break;
      case 3:
        this.AutoPlantillaComponent.openAdviceHome(this.bodyReport, this.buttonArray);
        break;
      case 4:
        this.AutoPlantillaComponent.openAdviceSuperior(this.bodyReport, this.buttonArray);
        break;
      case 5:
        //this.autoPlantilla.openSnackBar(this.bodyReport, this.buttonArray, this.bodyReport.snackTime);
        //this.openSnackBar(this.bodyReport, this.buttonArray, this.bodyReport.snackTime);
        this.AutoPlantillaComponent.openSnackBar(this.bodyReport, this.buttonArray, this.bodyReport.snackTime);
        break;
    }*/

  }

  /*openBottomSheet(info, button, container){
    this.bottomSheet.open(AutoBottomSheetComponent,
      {data: { plantilla: info, boton: button, contenedor: container }
    });
  }

  openPopUp(info, button, container){

    const dialogRef = this.dialog.open(AutoPopupComponent,{
      width: 'auto',
      height: 'auto',
      data:{plantilla: info, boton: button, contenedor: container}
    });

    dialogRef.afterClosed().subscribe( data=>{
    });

  }

  openAdviceHome(info, button){

    const dialogRef = this.dialog.open(AutoAdviceHomeComponent,{
      width: 'auto',
      height: 'auto',
      data:{plantilla: info, boton: button}
    });

    dialogRef.afterClosed().subscribe( data=>{
    });

  }

  openAdviceSuperior(info, button){

    const dialogRef = this.dialog.open(AutoAdviceSuperiorComponent,{
      width: 'auto',
      height: 'auto',
      data:{plantilla: info, boton: button}
    });

    dialogRef.afterClosed().subscribe( data=>{
    });

  }

  openSnackBar(info, button, time){
    this.snackBar.openFromComponent(AutoSnackbarComponent, {
      duration: time * 1000, data: {plantilla: info, boton: button}
    });
  }*/


  public executeCron() {
    this.endpointService.executeEgoCron().subscribe(() => { });
  }

  resetButtons(){

    this.buttonArray = new Array();

    let x = 0;

    while (x < 3){

      let n = x +1;

      this.buttonArray.push({active: true, name: "Botón " + n + ": ", text: null, tooltip: null, actionArray: [], colorText: "#000000",
        colorBackground: "#000000", colorEdge: "#000000", icon: "event", icon_color: "#000000", icon_type: 1,
        icon_tooltipe: null, icon_active: true, link: null, pantalla: 1, noti: null, order: n});

      x++;
    }
  }

  public applyFilter(filterValue: string): void {
    this.dataSourcePlantilla.filter = filterValue.trim().toLowerCase();

    if (this.dataSourcePlantilla.paginator) {
      this.dataSourcePlantilla.paginator.firstPage();
    }
  }

/*  queueEmail() {
    this.endpointService.queueEmail('ielboukre@gmail.com', 9, 1, 19, 43).subscribe(res => {
    });
  }*/
}
