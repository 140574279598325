import { Injectable, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class SeoService {

  constructor(
    private titleService: Title,
    private metaService: Meta,
    @Inject(DOCUMENT) private doc) {}

  public setTitle(title: string) {
    console.log(title);
    this.titleService.setTitle(title);
  }

  public setMetaTag(tag: string, content: string) {
    this.metaService.updateTag({name: tag, content: content});
  }

  public addMetaTag(tag: string, content: string) {
    this.metaService.addTag({name: tag, content: content});
  }

  public removeMetaTag(tag: string) {
    this.metaService.removeTag(tag);
  }

  public setCanonicalLink(href: string) {
    let link:HTMLLinkElement = this.doc.head.querySelector('link[rel="canonical"]');
    if (link !== null) {
      link.href = href;
    }
    else {
      link = this.doc.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', href);
      this.doc.head.appendChild(link);
    }
  }

  public removeAlternates() {
    let link:HTMLLinkElement[] = this.doc.head.querySelectorAll('link[rel="alternate"]');
    for (let i = 0; i < link.length; i++) {
      link[i].parentNode.removeChild(link[i]);
    }
  }

  public setAlternateLink(href: string, lang: string) {
    let link:HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'alternate');
    link.setAttribute('href', href);
    link.setAttribute('hreflang', lang);
    this.doc.head.appendChild(link);
  }

  public setSEO(language: string, title: string, description: string, keywords: string, cononical: string, alternative: string) {
    this.removeAlternates();

    this.addMetaTag('robots', 'Index, Follow');

    this.setTitle(title);
    this.setMetaTag('description', description);
    this.setMetaTag('keywords', keywords);

    this.setCanonicalLink(cononical);
    this.setAlternateLink(alternative, language);
  }

  /***** Falta afegir el SEO per xarxes socials: Agafar de Printboo *****/

}
