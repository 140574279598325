<!--<p>{{group | json}}</p>-->
<!--<p>{{group.usersMap | json}}</p>-->

<div class="infoContainer" fxLayout="row wrap" fxFlex="100">
  <div class="infoHeader" fxFlex="100" fxLayout="row wrap">
    <div class="img-group">
      <img (click)="addImage()" class="groupPic" src="{{group.groupPic}}"
      alt="" style="max-width:100%; max-height:100%;" >
      <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" accept="image/*" style="display:none;">
    </div>
    <form [formGroup]="firstFormGroup">
      <span fxLayout="row wrap" fxFlex="100">
        <ng-container *ngIf="editName" >
          <span fxFlex="100" style="display: flex; margin-bottom: 1em;">
            <mat-form-field appearance="outline" fxFlex="100">
              <mat-label>Nombre del grupo</mat-label>
              <input matInput placeholder="Nombre del grupo" formControlName="firstCtrlGroupName">
              <mat-error *ngIf="firstFormGroup.get('firstCtrlGroupName').hasError('required')">
                Debe elegir un <strong>nombre</strong> para el grupo
              </mat-error>
              <div matSuffix fxLayout="row">
                <mat-icon (click)="cancelEditGroupName()" matTooltip="Cancelar" class="material-icons">close</mat-icon>
                <mat-icon [disabled]="!firstFormGroup.get('firstCtrlGroupName').valid" (click)="saveName(firstFormGroup.get('firstCtrlGroupName').value)"
                matTooltip="Guardar nombre del grupo" class="material-icons">check</mat-icon>
              </div>
            </mat-form-field>
          </span>
        </ng-container>

        <ng-container *ngIf="!editName">
          <span fxFlex="100" style="display: flex; margin-bottom: 1em;">
            <mat-form-field appearance="outline" fxFlex="100">
              <mat-label>Nombre del grupo</mat-label>
              <input readonly [attr.disabled]="true" matInput placeholder="Nombre del grupo" formControlName="firstCtrlGroupName">
              <mat-icon matSuffix *ngIf="this.group?.onlyAdminCanEditGroupInfo === false || (this.group?.onlyAdminCanEditGroupInfo === true && group?.myInfo?.es_admin)"
                class="material-icons" (click)="editName = true" matTooltip="Editar nombre del grupo">edit</mat-icon>  
            </mat-form-field>
          </span>
        </ng-container>

        <ng-container *ngIf="editDescription">
          <span fxFlex="100" style="display: flex;">
            <mat-form-field appearance="outline" fxFlex="100">
              <mat-label>Descripción del grupo</mat-label>
              <textarea style="overflow: scroll !important;" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5" cdkAutosizeMaxRows="5"
                        name="name" type="text" placeholder="Descripción del grupo" formControlName="firstCtrlGroupDescription"></textarea>
              <div matSuffix fxLayout="row">
                <mat-icon (click)="cancelEditDescription()" matTooltip="Cancelar" class="material-icons">close</mat-icon>
                <mat-icon [disabled]="!firstFormGroup.get('firstCtrlGroupDescription').valid" (click)="saveDescription(firstFormGroup.get('firstCtrlGroupDescription').value)"
                matTooltip="Guardar descrpición del grupo" class="material-icons">check</mat-icon>
              </div>
              <mat-error *ngIf="firstFormGroup.get('firstCtrlGroupDescription').hasError('required')">
                Debe elegir una <strong>descripción</strong> para el grupo
              </mat-error>
            </mat-form-field>
          </span>
        </ng-container>

        <ng-container *ngIf="!editDescription">
          <span fxFlex="100" style="display: flex;">
            <mat-form-field appearance="outline" fxFlex="100">
              <mat-label>Descripción del grupo</mat-label>
              <textarea readonly [attr.disabled]="true" style="overflow: scroll !important;" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5" cdkAutosizeMaxRows="5"
              name="name" type="text" placeholder="Descripción del grupo" formControlName="firstCtrlGroupDescription"></textarea>
              <mat-icon matSuffix *ngIf="this.group?.onlyAdminCanEditGroupInfo === false || (this.group?.onlyAdminCanEditGroupInfo === true && group?.myInfo?.es_admin)"
                class="material-icons" (click)="editDescription = true" matTooltip="Editar descripción del grupo">edit</mat-icon>              
            </mat-form-field>
          </span>
        </ng-container>

      </span>
    </form><!--    <span>{{group.groupName}}</span>-->
  </div>

  <mat-divider fxFlex="100"></mat-divider>

  <div class="infoBody" fxFlex="100" fxLayout="row wrap">
    <div fxFlex="100" fxLayout="row wrap">
      <span fxFlex="50" class="members">MIEMBROS: <span class="red">{{group.usersMap.size}}</span></span>
      <!--    <span>{{group.description}}</span>-->
      <div fxFlex="50" class="add-member blue"  *ngIf="!this.group?.onlyAdminCanAdd || (this.group?.onlyAdminCanAdd === true && group?.myInfo?.es_admin)" (click)="addUserToGroup(group.id)" matTooltip="Añadir miembro">AÑADIR MIEMBRO
        <i class="material-icons group-new">person_add_alt_1</i>
<!--        <i class="material-icons group-new" matTooltip="Eliminar grupo">delete_forever</i>-->
      </div>
    </div>
    <div class="users" fxFlex="100">

      <ul fxFlex="100" fxLayout="row wrap">
        <li *ngFor="let user of group.usersMap| keyvalue; let i = index;" style="margin: 1em 0;" fxFlex="100" fxLayout="row wrap">
          <span fxFlex="50" fxLayout="row wrap">
            <img class="fotoEvento2" src="{{user.value.avatar}}" alt="" style="max-width:100%; max-height:100%;">
            <span fxLayout="row wrap">
              <span fxFlex="100" *ngIf="user.value.id === group?.myInfo?.id" style="font-weight: 600;">Tú</span> <!--Tiene que ser un token-->
              <span fxFlex="100" *ngIf="user.value.id !== group?.myInfo?.id" style="font-weight: 600;">{{user.value.nombre}}</span> <!--Tiene que ser un token-->
              <span fxFlex="100" class="adminText" *ngIf="user.value.es_admin === true">Admin</span>
            </span>
          </span>
          <span fxFlex="50" fxLayout="row wrap" class="members-edit blue" *ngIf="user.value.id !== authService.user_token && group?.myInfo?.es_admin"
          (click)="openBottomSheetEditUserGroup(group.id, user.value.id, user.value.es_admin, group?.myInfo?.nombre, user.value.nombre)">EDITAR</span>
        </li>
      </ul>
    </div>
  </div>

  <mat-divider fxFlex="100"></mat-divider>

<!--  CONFIG -->
  <div class="infoFooter" fxFlex="100" *ngIf="group?.myInfo?.es_admin" fxLayout="row wrap">
    <span class="config-tittle">CONFIGURACIÓN
        <i *ngIf="!editConfig" (click)="editConfig = true" matTooltip="Editar configuracion del grupo" class="material-icons blue">edit</i>
        <i *ngIf="editConfig" (click)="saveConfiguration()" matTooltip="Guardar configuración del grupo" class="material-icons blue">check</i>
        <i *ngIf="editConfig" (click)="cancelEditConfiguration()" matTooltip="Cancelar" class="material-icons blue">close</i>
    </span>

    <ng-container *ngIf="editConfig">
      <div fxLayout="row wrap">
        <mat-checkbox class="answers" fxFlex="100" [(ngModel)]="onlyAdminCanChat" (click)="$event.stopPropagation()">Solo puede hablar el administrador</mat-checkbox>
        <mat-checkbox class="answers" fxFlex="100" [(ngModel)]="onlyAdminCanEditGroupInfo" (click)="$event.stopPropagation()">Solo puede editar información del grupo el administrador</mat-checkbox>
        <mat-checkbox class="answers" fxFlex="100" [(ngModel)]="onlyAdminCanAdd" (click)="$event.stopPropagation()">Solo puede añadir usuarios el administrador</mat-checkbox>
        <!--      <mat-checkbox formControlName="firstCtrlOnlyAdminCanAdd" (click)="$event.stopPropagation()"></mat-checkbox>-->
        <div class="answers" fxLayout="row wrap" fxFlex="100">
          <span fxFlex="100" class="labels-padds">Tipo de grupo</span>
          <mat-radio-group [(ngModel)]="groupType">
            <mat-radio-button [value]="2">Privado</mat-radio-button>
            <mat-radio-button style="margin-left: 1em;" [value]="1">Público</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!editConfig">
      <div fxLayout="row wrap">
        <span class="answers" fxFlex="100">Solo puede hablar el administrador: <b>{{onlyAdminCanChat ? 'Si' : 'No'}}</b></span>
        <span class="answers" fxFlex="100">Solo puede editar información del grupo el administrador: <b>{{onlyAdminCanEditGroupInfo ? 'Si' : 'No'}}</b></span>
        <span class="answers" fxFlex="100">Solo puede añadir usuarios el administrador: <b>{{onlyAdminCanAdd ? 'Si' : 'No'}}</b></span>
        <span class="answers" fxFlex="100">Tipo de grupo: <b>{{groupType === 1 ? 'Público' : 'Privado'}}</b></span>
      </div>
    </ng-container>
  </div>
</div>
