<ng-container *ngIf="!structure[this.index]['creatingMode']">
  <ng-container *ngIf="structure[this.index]['type'] === 'image'">
    <div class="all-drag-drop-file" appDragAndDrop (click)="imgUpload.click(); $event.stopPropagation()" (fileDropped)="onFileDropped($event)">
      <form #uploadForm="ngForm" #uploaderForm>
        <input #imgUpload
          id="{{ structure[this.index]['bd_field'] }}"
          type="file"
          accept="{{acceptedImgTypes}}"
          [multiple]="structure[this.index]['multiple'] == 1 ? true : false"
          placeholder="{{ structure[this.index]['description'] }}"
          name="{{ structure[this.index]['name'] }}"
          [required]="this.structure[this.index]['id_functional_status_general'] === 5"
          [attr.disabled]="this.structure[this.index]['id_functional_status_general'] === 3 ? '' : null"
          [readonly]="this.structure[this.index]['id_functional_status_general'] === 3"
          (change)="changeSelectedImage($event)"
          class="file-input">
      </form>
      <div class="file-upload">
        <button (click)="imgUpload.click(); $event.stopPropagation()">
          <mat-icon *ngIf="structure[index]['icon'] && structure[index]['icon'] != ''"
          class="{{structure[index]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
          structure[index]['icon_type']=='Filled' ? 'material-icons' :
          structure[index]['icon_type'] =='Round' ? 'material-icons-round' :
          structure[index]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
          structure[index]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}"
          >{{ structure[index]['icon'] }}</mat-icon>
          <mat-icon *ngIf="!structure[index]['icon'] || structure[index]['icon'] == ''" class="material-icons-outlined">attach_file</mat-icon>
          <ng-container *ngIf="structure[index]['label'] && structure[index]['label'] != ''">
            {{ structure[index]['label'] }}
          </ng-container>
          <ng-container *ngIf="!structure[index]['label'] || structure[index]['label'] == ''">
            Upload file
          </ng-container>
          <ng-container *ngIf="imgURL.length > 0 && structure[this.index]['multiple'] == 1 && maxArchives > 1">
            ({{ imgURL.length + "/" + maxArchives }})
          </ng-container>
          <ng-container *ngIf="imgURL.length > 1 && (structure[this.index]['multiple'] !== 1 && !(maxArchives > 1))">
            ({{ imgURL.length }})
          </ng-container>
        </button>
        <h6>o</h6>
        <h6><mat-icon>upload_file</mat-icon>Arrastra aquí la imagen</h6>
        <p class="restrictions-files">Máximo {{maxSizeMB}}MB por imagen ({{ acceptedImgTypesFormatted }})</p>
      </div>
    </div>
    <div fxLayout="row wrap">
      <div *ngFor="let image of imgURL; let i = index" class="image-preview" (click)="$event.stopPropagation()">
        <img [src]="image"/>
        <button
          (click)="diselectImage(i); $event.stopPropagation()">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="structure[this.index]['type'] === 'document'">
    <div class="all-drag-drop-file" appDragAndDrop (click)="docUpload.click(); $event.stopPropagation()" (fileDropped)="onFileDropped($event)">
      <form #uploadForm="ngForm" #uploaderForm>
        <input #docUpload
          id="{{ structure[this.index]['bd_field'] }}"
          type="file"
          accept="{{acceptedDocTypes}}"
          multiple="structure[this.index]['multiple'] == 1 ? true : false"
          placeholder="{{ structure[this.index]['description'] }}"
          name="{{ structure[this.index]['name'] }}"
          [required]="this.structure[this.index]['id_functional_status_general'] === 5"
          [multiple]="structure[this.index]['multiple'] == 1 ? true : false"
          [attr.disabled]="this.structure[this.index]['id_functional_status_general'] === 3 ? '' : null"
          [readonly]="this.structure[this.index]['id_functional_status_general'] === 3"
          (change)="changeSelectedDoc($event)"
          class="file-input">
      </form>
      <div class="file-upload">
        <button (click)="docUpload.click(); $event.stopPropagation()">
          <mat-icon *ngIf="structure[index]['icon'] && structure[index]['icon'] != ''"
          class="{{structure[index]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
          structure[index]['icon_type']=='Filled' ? 'material-icons' :
          structure[index]['icon_type'] =='Round' ? 'material-icons-round' :
          structure[index]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
          structure[index]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}"
          >{{ structure[index]['icon'] }}</mat-icon>
          <mat-icon *ngIf="!structure[index]['icon'] || structure[index]['icon'] == ''" class="material-icons-outlined">attach_file</mat-icon>
          <ng-container *ngIf="structure[index]['label'] && structure[index]['label'] != ''">
            {{ structure[index]['label'] }}
          </ng-container>
          <ng-container *ngIf="!structure[index]['label'] || structure[index]['label'] == ''">
            Upload file
          </ng-container>
          <ng-container *ngIf="docURL.length > 0 && structure[this.index]['multiple'] == 1 && maxArchives > 1">
            ({{ docURL.length + "/" + maxArchives }})
          </ng-container>
          <ng-container *ngIf="docURL.length > 1 && (structure[this.index]['multiple'] !== 1 && !(maxArchives > 1))">
            ({{ docURL.length }})
          </ng-container>
        </button>
        <h6>o</h6>
        <h6><mat-icon class="material-icons-outlined">upload_file</mat-icon>Arrastra aquí el documento</h6>
        <p class="restrictions-files">Máximo {{maxSizeMB}}MB por fichero ({{ acceptedDocTypesFormatted }})</p>
      </div>
    </div>
    <div fxLayout="row wrap">
      <mat-list fxFlex="100">
        <div *ngFor="let doc of docURL; let i = index" class="doc-preview" (click)="$event.stopPropagation()">
          <mat-list-item style="height: 40px;">
            <mat-icon class="material-icons-outlined">upload_file</mat-icon> {{ doc }}
            <button
              (click)="diselectDoc(i); $event.stopPropagation()">
              <mat-icon>clear</mat-icon>
            </button>
          </mat-list-item>
        </div>
      </mat-list>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="structure[this.index]['creatingMode']">
  <div class="all-drag-drop-file">
    <form #uploadForm="ngForm" #uploaderForm>
      <input #imgUpload
        id="{{ structure[this.index]['bd_field'] }}"
        type="file"
        accept="{{acceptedImgTypes}}"
        [multiple]="structure[this.index]['multiple'] == 1 ? true : false"
        placeholder="{{ structure[this.index]['description'] }}"
        name="{{ structure[this.index]['name'] }}"
        [required]="this.structure[this.index]['id_functional_status_general'] === 5"
        [attr.disabled]="true"
        [readonly]="true"
        class="file-input">
    </form>
    <div class="file-upload">
      <button>
        <mat-icon *ngIf="structure[index]['icon'] && structure[index]['icon'] != ''"
        class="{{structure[index]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
        structure[index]['icon_type']=='Filled' ? 'material-icons' :
        structure[index]['icon_type'] =='Round' ? 'material-icons-round' :
        structure[index]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
        structure[index]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}"
        >{{ structure[index]['icon'] }}</mat-icon>
        <mat-icon *ngIf="!structure[index]['icon'] || structure[index]['icon'] == ''" class="material-icons-outlined">attach_file</mat-icon>
        <ng-container *ngIf="structure[index]['label'] && structure[index]['label'] != ''">
          {{ structure[index]['label'] }}
        </ng-container>
        <ng-container *ngIf="!structure[index]['label'] || structure[index]['label'] == ''">
          Upload file
        </ng-container>
        <ng-container *ngIf="imgURL.length > 0 && structure[this.index]['multiple'] == 1 && maxArchives > 1">
          ({{ imgURL.length + "/" + maxArchives }})
        </ng-container>
        <ng-container *ngIf="imgURL.length > 1 && (structure[this.index]['multiple'] !== 1 && !(maxArchives > 1))">
          ({{ imgURL.length }})
        </ng-container>
      </button>
      <h6>o</h6>
      <h6><mat-icon>upload_file</mat-icon>Arrastra aquí la imagen</h6>
      <p class="restrictions-files">Máximo {{maxSizeMB}}MB por imagen ({{ acceptedImgTypesFormatted }})</p>
    </div>
  </div>
  <div fxLayout="row wrap">
    <div *ngFor="let image of imgURL; let i = index" class="image-preview" (click)="$event.stopPropagation()">
      <img [src]="image"/>
      <button
        (click)="diselectImage(i); $event.stopPropagation()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>
</ng-container>