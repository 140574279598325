import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
//import socketIo from 'socket.io-client';
import * as socketIo from 'socket.io-client';
//si da error al socket usar el de abajo
//import {io} from 'socket.io-client';
import {environment} from "../../environments/environment";
import {Event} from "./chat.service";

@Injectable({
  providedIn: 'root'
})
export class SocketioService {

  private socket;

  constructor() { }

  public initSocket(userId: string): void {
    this.socket = socketIo(environment.SOCKET_ENDPOINT,
      { path:'/api/socket', query:{userId:userId}});//token para identificar el socket
  }
/////////para el futuro
  public send(message): void {
    this.socket.emit('message', message);
  }

  public sendTyping(selectedChat, who){
    this.socket.emit('typing', {selectedchat: selectedChat, who: who});
  }

  public sendNoLongerTyping(selectedChat, who){
    this.socket.emit('notyping', {selectedchat: selectedChat, who: who});
  }


  public sendEnteredToChat(selectedChat, who){
    this.socket.emit('enteredChat', {selectedchat: selectedChat, who: who});

  }
/////// event listener


public onEnteredChat(){
  return new Observable<any>(observer => {
    this.socket.on('changeMessagesToSeen', (data: any) => observer.next(data));
  });
}

public onTyping(): Observable<any> {
  return new Observable<any>(observer => {
    this.socket.on('onTyping', (data: any) => observer.next(data));
  });
}

public onNoLongerTyping(): Observable<any> {
  return new Observable<any>(observer => {
    this.socket.on('onNoLongerTyping', (data: any) => observer.next(data));
  });
}

public onMessage(): Observable<any> {
  return new Observable<any>(observer => {
    this.socket.on('new_message', (data: any) => observer.next(data));
  });
}

  public onGroup(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('new_group', (data: any) => observer.next(data));
    });
  }

  public onUpdateGroup (): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('update_group', (data: any) => observer.next(data));
    });
  }

  public onUserOnline (): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('user_online', (data: any) => observer.next(data));
    });
  }

  public onUpdateUser (): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('update_user', (data: any) => observer.next(data));
    });
  }

/// connection events control
  public onEvent(event: Event): Observable<any> {
    return new Observable<Event>(observer => {
      this.socket.on(event, () => observer.next());
    });
  }
}
