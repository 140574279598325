import { Component, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'delete-confirm-dialog',
	templateUrl: './delete-confirm-dialog.html'
})
// public v1: any;

export class DeleteConfirmDialog {
	constructor(
		public dialogRef: MatDialogRef<DeleteConfirmDialog>,
		@Inject(MAT_DIALOG_DATA) public message: string) {
	  // this.v1 = message.data;
  }

	onNoClick(): void {
		this.dialogRef.close();
	}
}
