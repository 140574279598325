import {Component, Input, OnInit} from '@angular/core';
import {YouTubePlayer} from "@angular/youtube-player";

@Component({
  selector: 'app-knowledge-video',
  templateUrl: './knowledge-video.component.html',
  styleUrls: ['./knowledge-video.component.css']
})
export class KnowledgeVideoComponent implements OnInit {
  @Input() card;
  constructor() { }

  ngOnInit(): void {
    this.loadVideo();
  }

  pauseVideoFrame(event: YouTubePlayer) {
    event.pauseVideo();
  }

  playVideoFrame(event: YouTubePlayer) {
    event.playVideo();
  }

  public loadVideo() {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);
  }
}
