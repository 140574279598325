<div class="notis0 mkt-dialoguing" fxLayout="row wrap">
  <span fxFlex="100" style="font-weight: bolder; font-size: 18px; text-align: left; margin-left: 10px; padding: 10px 0;">TUTORIALES <i class="material-icons" matTooltip="Aquí podrás realizar los tutoriales de los productos que has adquirido" style="cursor: help;">help_outlined</i></span>
  
  <div *ngIf="!loaded" fxLayoutAlign="center center" fxFlex="100">
    <mat-spinner class="yellowSpinner"></mat-spinner>
  </div>

  <ng-container *ngIf="loaded">
    <div fxFlex="100" fxLayout="row wrap" style="height: 50vh; overflow: scroll; margin-bottom: 20px;">
      <div fxFlex="25" fxFlex.sm="50" fxFlex.xs="100" fxLayout="row wrap" *ngFor="let producto of productos">
        <div class="container-card modulos" fxLayout="row wrap" fxFlex="100">
          <div class="main-container" fxFlex="100" fxLayout="row wrap">
            <div class="left-part" fxFlex="50" fxLayout="row wrap" fxLayoutAlign=" start" >
              <span class="titulo-modulo" fxFlex="100">{{producto.nombre}}</span>
              <div *ngIf="producto.precio" class="price" fxFlex="100" fxLayout="row wrap">
                <span fxFlex="100">{{producto.precio}} MOVS</span>
              </div>
            </div>
            <div fxFlex="50" class="right-part">
              <div class="category-product-0">
                <span class="category-product" fxFlex="100" *ngIf="producto.id_product_type == 1">Producto</span>
                <span class="category-product" fxFlex="100" *ngIf="producto.id_product_type == 2">Widget</span>
              </div>
              <img *ngIf="producto.imagen" [src]="sanitizer.bypassSecurityTrustResourceUrl(producto.imagen)">
            </div>
          </div>
          <!-- Hover -->
          <div class="text-modulo" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center">
            <span fxFlex="100" class="description-modulo">{{producto.descripcion}}</span>
          </div>
          <button class="button-registry" fxFlex="100" *ngIf="producto.puedeHacerDemo" (click)="onComenzar(producto)">Empezar tutorial</button>
          <span *ngIf="producto.realizado"> [Realizado]</span>
        </div>
      </div>
    </div>
  
    <div *ngIf="!(productos && productos.length)">
      No se han encontrado tutoriales para tus módulos.
    </div>
  </ng-container>

  <div fxFlex="100" style="padding: 10px 0">
    <button mat-button fxFlex.sm="100" fxFlex.xs="100" (click)="onCancel()">Cerrar</button>
  </div>

</div>

<auto-plantilla #AutoPlantillaComponent></auto-plantilla>
