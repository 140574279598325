<div [ngClass]="{'header-hide':stephide}" class="notis0" [ngStyle]="{'background': background}">

  <div *ngIf="stepArray.length <= 1" style="margin: 0.3em;" class="each-noti" fxLayout="row wrap">
    <div fxFlex="100" fxLayout="row wrap">
      <div *ngIf="!personalizado" fxFlex="100" fxLayout="row wrap">
        <span *ngFor="let c of stepArray[0].container; let i = index" fxFlex="100" style="width: 0px;">
          <span class="tittle-noti" *ngIf="c.idType == 1" [ngStyle]="{'color': c.color}">{{c.container}}</span>
          <i class="subtittle-noti" *ngIf="c.idType == 2" [ngStyle]="{'color':  c.color}">{{c.container}}</i>
          <p class="text-noti" *ngIf="c.idType == 3" [ngStyle]="{'color': c.color}">{{c.container}}</p>
          <img *ngIf="c.idType == 4" [src]="c.container" class="img-noti">
          <iframe *ngIf="c.idType == 5" width="560" height="315" [src]="this.sanitizer.bypassSecurityTrustResourceUrl( 'https://www.youtube.com/embed/' + c.container)" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class="video-noti"></iframe>
          <!-- No funciona si src es dinámico --><iframe *ngIf="c.idType == 6" [src]="this.sanitizer.bypassSecurityTrustResourceUrl(c.container)" width="700" height="520" frameborder="0" marginheight="0" marginwidth="0" style="width: 100%; min-width: 350px;">S&#39;Loading…</iframe>
        </span>
      </div>
      <i style="font-size: 10px;" *ngIf="personalizado" [innerHTML]="mensaje | safeHtml"></i>
    </div>
    <span fxFlex="100" class="container-buttons">
      <button *ngIf="stepArray[0].buttons[0]?.active" (click)="buttonAction(0, 0)"  matTooltip="{{stepArray[0].buttons[0]?.icon_tooltipe}}" [ngStyle]="{'background': stepArray[0].buttons[0]?.colorBackground, 'color': stepArray[0].buttons[0]?.colorText, 'border': '1px solid ' + stepArray[0].buttons[0]?.colorEdge}"><i *ngIf="stepArray[0].buttons[0]?.icon_active" [className]="'material-icons'" [ngStyle]="{'color': stepArray[0].buttons[0]?.icon_color}">{{stepArray[0].buttons[0]?.icon}}</i>{{stepArray[0].buttons[0]?.text}}</button>
      <button *ngIf="stepArray[0].buttons[1]?.active" (click)="buttonAction(1, 0)"  matTooltip="{{stepArray[0].buttons[1]?.icon_tooltipe}}" [ngStyle]="{'background': stepArray[0].buttons[1]?.colorBackground, 'color': stepArray[0].buttons[1]?.colorText, 'border': '1px solid ' + stepArray[0].buttons[1]?.colorEdge}"><i *ngIf="stepArray[0].buttons[1]?.icon_active" [className]="'material-icons'" [ngStyle]="{'color': stepArray[0].buttons[1]?.icon_color}">{{stepArray[0].buttons[1]?.icon}}</i>{{stepArray[0].buttons[1]?.text}}</button>
      <button *ngIf="stepArray[0].buttons[2]?.active" (click)="buttonAction(2, 0)"  matTooltip="{{stepArray[0].buttons[2]?.icon_tooltipe}}" [ngStyle]="{'background': stepArray[0].buttons[2]?.colorBackground, 'color': stepArray[0].buttons[2]?.colorText, 'border': '1px solid ' + stepArray[0].buttons[2]?.colorEdge}"><i *ngIf="stepArray[0].buttons[2]?.icon_active" [className]="'material-icons'" [ngStyle]="{'color': stepArray[0].buttons[2]?.icon_color}">{{stepArray[0].buttons[2]?.icon}}</i>{{stepArray[0].buttons[2]?.text}}</button>
    </span>
    <mat-icon *ngIf="close" (click)="closeBottomSheet()" class="removeit" [ngStyle]="{'color': colorClose}">close</mat-icon>
  </div>

  <div *ngIf="stepArray.length > 1" class="each-noti" style="margin: 0.3em;" fxLayout="row wrap">
    <div [ngClass]="{'header-step-out':onlyOneHeader}" fxFlex="100" fxLayout="row wrap">
      <mat-horizontal-stepper fxFlex="100" [linear]="true" #stepper>
      <mat-step *ngFor="let s of stepArray; let si = index">
        <ng-template matStepLabel>{{s.name}}</ng-template>
        <div fxFlex="100" fxLayout="row wrap">
          <div *ngIf="!personalizado" fxFlex="100" fxLayout="row wrap">
              <span *ngFor="let c of s.container; let i = index" fxFlex="100" style="width: 0px;">
                <span class="tittle-noti" *ngIf="c.idType == 1" [ngStyle]="{'color': c.color}">{{c.container}}</span>
                <i class="subtittle-noti"  *ngIf="c.idType == 2" [ngStyle]="{'color':  c.color}">{{c.container}}</i>
                <p class="text-noti"  *ngIf="c.idType == 3" [ngStyle]="{'color': c.color}">{{c.container}}</p>
                <img class="img-noti"  *ngIf="c.idType == 4" [src]="c.container">
                <iframe *ngIf="c.idType == 5" width="560" height="315" [src]="this.sanitizer.bypassSecurityTrustResourceUrl( 'https://www.youtube.com/embed/' + c.container)" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen  class="video-noti" ></iframe>
                <!-- No funciona si src es dinámico --><iframe *ngIf="c.idType == 6" [src]="this.sanitizer.bypassSecurityTrustResourceUrl(c.container)" width="700" height="520" frameborder="0" marginheight="0" marginwidth="0" style="width: 100%; min-width: 350px;">S&#39;Loading…</iframe>
              </span>
          </div>
          <i style="font-size: 10px;" *ngIf="personalizado" [innerHTML]="mensaje | safeHtml"></i>
        </div>
        <span fxFlex="100" class="container-buttons">
          <button *ngIf="s.buttons[0]?.active" (click)="buttonAction(0, si)"  matTooltip="{{s.buttons[0]?.icon_tooltipe}}" [ngStyle]="{'background': s.buttons[0]?.colorBackground, 'color': s.buttons[0]?.colorText, 'border': '1px solid ' + s.buttons[0]?.colorEdge}"><i *ngIf="s.buttons[0]?.icon_active" [className]="'material-icons'" [ngStyle]="{'color': s.buttons[0]?.icon_color}">{{s.buttons[0]?.icon}}</i>{{s.buttons[0]?.text}}</button>
          <button *ngIf="s.buttons[1]?.active" (click)="buttonAction(1, si)"  matTooltip="{{s.buttons[1]?.icon_tooltipe}}" [ngStyle]="{'background': s.buttons[1]?.colorBackground, 'color': s.buttons[1]?.colorText, 'border': '1px solid ' + s.buttons[1]?.colorEdge}"><i *ngIf="s.buttons[1]?.icon_active" [className]="'material-icons'" [ngStyle]="{'color': s.buttons[1]?.icon_color}">{{s.buttons[1]?.icon}}</i>{{s.buttons[1]?.text}}</button>
          <button *ngIf="s.buttons[2]?.active" (click)="buttonAction(2, si)"  matTooltip="{{s.buttons[2]?.icon_tooltipe}}" [ngStyle]="{'background': s.buttons[2]?.colorBackground, 'color': s.buttons[2]?.colorText, 'border': '1px solid ' + s.buttons[2]?.colorEdge}"><i *ngIf="s.buttons[2]?.icon_active" [className]="'material-icons'" [ngStyle]="{'color': s.buttons[2]?.icon_color}">{{s.buttons[2]?.icon}}</i>{{s.buttons[2]?.text}}</button>
        </span>
      </mat-step>
    </mat-horizontal-stepper>
      <mat-icon *ngIf="close" (click)="closeBottomSheet()" class="removeit" [ngStyle]="{'color': colorClose}">close</mat-icon>
   </div>
  </div>
</div>
