<!--  Aquesta part hi ha inputs  -->

<div fxLayout="row wrap" fxFlex="100">
  <!--<h1 fxShow="true" fxShow.sm="false" fxShow.xs="false" fxFlex="100" class="slogan-movin2">WELCOME TO THE REAL ESTATE <span>[R]</span>EVOLUTION</h1>-->

  <mat-spinner *ngIf="!loadedInfo" class="loader-spinner-landing" [diameter]="60" [strokeWidth]="12"></mat-spinner>

  <div *ngIf="loadedInfo" fxLayout="row wrap" fxFlex="100" [ngClass]="{'entrada-triumfal':!loadedInfo, 'tabla-puntuaciones':loadedInfo}" style="visibility: hidden; opacity: 0;">

    <!-- SPINNER, NOMBRE INMO, ESTRELLAS Y PUNTUACION -->
    <div fxFlex="25" fxFlex.sm="100" fxFlex.xs="100" class="puntuation-inmo">
      <div fxLayout="row wrap" class="rating-opinion-1" fxFlex="100" fxLayoutAlign="center start">
        <div class="rating">
          <a class="spinner-group spinner-0" href="https://4dmovin.com/es/inmobiliaria/{{this.inmo['seo_alias']}}" target="_blank">
            <div class="spinner-fons" [ngStyle]="{'background-image': 'url(' + this.inmo['logo_cuadrado'] + ')'}"><div class="text-view"><span>Ver el perfil en el portal</span><span class="material-icons icon">visibility</span></div></div>
            <mat-progress-spinner mode="determinate" value="100" class="background"
                                  [ngClass]="{'spinner-red': this.roundNumber(this.puntuacion) < 50,
                            'spinner-yellow': this.roundNumber(this.puntuacion) < 90 && this.roundNumber(this.puntuacion) >= 50,
                            'spinner-blue': this.roundNumber(this.puntuacion) >= 90}"></mat-progress-spinner>
            <mat-progress-spinner mode="determinate" value="{{this.roundNumber(this.puntuacion)}}"
                                  [ngClass]="{'spinner-red': this.roundNumber(this.puntuacion) < 50,
                            'spinner-yellow': this.roundNumber(this.puntuacion) < 90 && this.roundNumber(this.puntuacion) >= 50,
                            'spinner-blue': this.roundNumber(this.puntuacion) >= 90}"></mat-progress-spinner>
          </a>
        </div>
        <h1 fxShow="false" fxShow.sm="true" fxShow.xs="true" fxFlex="100">{{this.inmo['nombre']}}</h1>
        <p fxFlex="100" class="stars" [ngClass]="{'red': this.roundNumber(this.puntuacion) < 50,
                            'yellow': this.roundNumber(this.puntuacion) < 90 && this.roundNumber(this.puntuacion) >= 50,
                            'blue': this.roundNumber(this.puntuacion) >= 90}">
          <i *ngFor="let number of [1,2,3,4,5]">
            <i *ngIf="number <= this.stars" class="material-icons">star</i>
            <i *ngIf="number < this.stars && number+1 > this.stars" class="material-icons">star_half</i>
            <i *ngIf="number-1 >= this.stars" class="material-icons">star_border</i>
          </i>
        </p>
        <h2 fxFlex="100" [ngClass]="{'red': this.roundNumber(this.puntuacion) < 50,
                            'yellow': this.roundNumber(this.puntuacion) < 90 && this.roundNumber(this.puntuacion) >= 50,
                            'blue': this.roundNumber(this.puntuacion) >= 90}">{{this.roundNumber(this.puntuacion)}}/100</h2>
      </div>
    </div>

    <!-- GRID 9 CATEGORIAS, solo se muestra la barra de puntuacion en los modulos 1 y 2 -->
    <div fxFlex="75" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap">
      <h1 fxFlex="100" fxShow.sm="false" fxShow.xs="false" class="slogan-movin">CENTRO DE CONTROL DE <span class="yellow">{{this.inmo['nombre']}}</span></h1>
      <div *ngFor="let categoria of categorias" fxFlex="27.33" class="tabla-puntuaciones-2" fxFlex.sm="90" fxFlex.xs="90" fxLayout="row wrap">
        <h1 *ngIf="categoria['id_puntuacion_modulo'] === 1 || categoria['id_puntuacion_modulo'] === 2" (click)="openCategoria(categoria)" style="cursor: pointer;" fxFlex="100">{{categoria['nombre']}} <span class="material-icons">visibility</span></h1>
        <div class="bar-group" *ngIf="categoria['id_puntuacion_modulo'] === 1 || categoria['id_puntuacion_modulo'] === 2" fxFlex="100">
          <mat-progress-bar class="loading-scores" mode="determinate" value="{{this.roundNumber(categoria['punt100'])}}"
                            [ngClass]="{'progress-red': this.roundNumber(categoria['punt100']) < 50,
                            'progress-yellow': this.roundNumber(categoria['punt100']) < 90 && this.roundNumber(categoria['punt100']) >= 50,
                            'progress-blue': this.roundNumber(categoria['punt100']) >= 90}"></mat-progress-bar>
          <span class="background2">{{this.roundNumber(categoria['punt100'])}}%</span>
        </div>
        <h1 *ngIf="categoria['id_puntuacion_modulo'] !== 1 && categoria['id_puntuacion_modulo'] !== 2" fxFlex="100">{{categoria['nombre']}}</h1>
        <div *ngIf="categoria['id_puntuacion_modulo'] !== 1 && categoria['id_puntuacion_modulo'] !== 2" class="comming-soon" fxFlex="100">
          Coming Soon
        </div>
      </div>

      <!-- TAB GROUP CATEGORIA SELECCIONADA -->
      <div fxLayout="row wrap" fxFlex="100" class="tab-group-categoria" style="padding-top: 2em;">
        <h5 *ngIf="showCategoria" fxFlex="100">{{categoriaSelected['nombre']}}<span (click)="closeCategoria()" class="material-icons">close</span></h5>
        <mat-tab-group *ngIf="showCategoria" fxFlex="100">
          <mat-tab *ngFor="let tab of staticTabs">
            <ng-template mat-tab-label>
              <span>{{tab['nombre']}}</span>
            </ng-template>
            <ng-template matTabContent>
              <!-- AUXILIAR -->
              <div fxLayout="row wrap">
                <mat-accordion fxLayout="row wrap" fxFlex="100">
                  <mat-expansion-panel *ngFor="let value of camposModulo[tab['id']]">
                    <mat-expansion-panel-header (click)="actualizarValorMostrar(value)">
                      <mat-panel-title>
                        <span [ngClass]="getElementsGreen(value) === value.length ? 'class-green' : 'class-red'">
                          {{value[0]['nombre_gru' +
                        'po']}}
                          <span *ngIf="value.length > 1">({{getElementsGreen(value)}}/{{value.length}})</span>
                        </span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="rellenar-flat" *ngFor="let element of currentValorToShow;">
                      <!-- Poner referencia en el caso de que sea multielement -->
                      <span class="rellenar-flat-text" *ngIf="element['referencia'] !== undefined && element['referencia'] !== null" [ngClass]="element['contenido'] !== null ? 'class-green' : 'class-red'">
                        <span class="material-icons">home</span> {{element['referencia']}}
                      </span>

                      <!-- EDITABLE -->
                      <span class="rellenar-flat-text" *ngIf="element['contenido'] !== null && element['editar'] !== true" style="color: green;">
                        <span style="color: white">{{element['nombre']}}: </span>
                        <span *ngIf="(element['tipo_dato'] === 'bit' || element['tipo_dato'] === 'checkbox') && (element['contenido'] === '1' || element['contenido'] === 1 || element['contenido'] === true)">Sí</span>
                        <span *ngIf="(element['tipo_dato'] === 'bit' || element['tipo_dato'] === 'checkbox') && (element['contenido'] === '0' || element['contenido'] === 0 || element['contenido'] === false)">No</span>
                        <span *ngIf="element['tipo_dato'] !== 'bit' && element['tipo_dato'] !== 'checkbox'">{{element['contenido']}}</span>
                        <span class="material-icons" (click)="editarValor(element)">create</span>
                      </span>

                      <!--<span *ngIf="element['contenido'] === null" style="color: red;">Debes añadir: {{element['nombre']}}</span>-->

                      <!-- EDITING -->
                      <form style="display: flex;" *ngIf="element['contenido'] !== null && element['editar'] === true" (ngSubmit)="actualizarCampo(element, this.authService.inmoId, element['nombre'], true)">
                        <mat-form-field appearance="outline" *ngIf="element['tipo_dato'] === 'int' || element['tipo_dato'] === 'email' || element['tipo_dato'] === 'string' || element['tipo_dato'] === 'telefono' || element['tipo_dato'] === 'fecha' || element['tipo_dato'] === 'enum'">
                          <input *ngIf="element['tipo_dato'] === 'int'" type="number" [(ngModel)]="element['new-valor']" matInput placeholder="Editar {{element['nombre']}}" name="-1">
                          <input *ngIf="element['tipo_dato'] === 'email'" type="email" [(ngModel)]="element['new-valor']" matInput placeholder="Editar {{element['nombre']}}" name="-1">
                          <input *ngIf="element['tipo_dato'] === 'string'" [(ngModel)]="element['new-valor']" matInput placeholder="Editar {{element['nombre']}}" name="-1">
                          <input *ngIf="element['tipo_dato'] === 'telefono'" type="tel" pattern="[0-9]{9}" [(ngModel)]="element['new-valor']" matInput placeholder="Editar {{element['nombre']}}" name="-1">
                          <input *ngIf="element['tipo_dato'] === 'fecha'" type="date" [(ngModel)]="element['new-valor']" matInput name="-1">
                          <mat-select [(ngModel)]="element['new-valor']" *ngIf="element['tipo_dato'] === 'enum'" placeholder="Escoge una opción" name="-1">
                            <ng-container *ngIf="options !== null && options.length && isOpen; else loading">
                              <mat-option *ngFor="let option of options" [value]="option.id">
                                {{option.nombre}}
                              </mat-option>
                            </ng-container>
                            <ng-template #loading>
                              <mat-option disabled>
                                <div class="container-p">
                                  <span>Loading...</span>
                                  <mat-spinner class="spinner" diameter="20"></mat-spinner>
                                </div>
                              </mat-option>
                            </ng-template>
                          </mat-select>
                        </mat-form-field>
                        <div *ngIf="element['tipo_dato'] === 'imagen' || element['tipo_dato'] === 'bit' || element['tipo_dato'] === 'checkbox' || element['tipo_dato'] === null ">
                          <mat-radio-group *ngIf="element['tipo_dato'] === 'checkbox'" [(ngModel)]="element['new-valor']" [ngModelOptions]="{standalone: true}">
                            <mat-radio-button style="margin-right: 10px;" [value]="1">Sí</mat-radio-button>
                          </mat-radio-group>
                          <input *ngIf="element['tipo_dato'] === 'imagen'" type="file" accept="image/*" (change)="onFileUpload($event, element)" #fileUpload name="fileUpload">
                          <mat-radio-group *ngIf="element['tipo_dato'] === 'bit'" [(ngModel)]="element['new-valor']" [ngModelOptions]="{standalone: true}">
                            <mat-radio-button style="margin-right: 10px;" [value]="1">Sí</mat-radio-button>
                            <mat-radio-button [value]="0">No</mat-radio-button>
                          </mat-radio-group>
                        </div>
                        <button class="button-out-raro" type="submit"><span class="material-icons">save</span></button>
                        <button class="button-out-raro" (click)="quitarEditar(element)"><span class="material-icons">cancel</span></button>
                      </form>

                      <!-- CREATING -->
                      <form style="display: flex;" *ngIf="element['contenido'] === null" (ngSubmit)="actualizarCampo(element, this.authService.inmoId, element['nombre'], false)">
                        <mat-form-field appearance="outline" *ngIf="element['tipo_dato'] === 'int' || element['tipo_dato'] === 'email' || element['tipo_dato'] === 'string' || element['tipo_dato'] === 'telefono' || element['tipo_dato'] === 'fecha' || element['tipo_dato'] === 'enum'">
                          <input *ngIf="element['tipo_dato'] === 'int'" type="number" [(ngModel)]="element['new-valor']" matInput placeholder="Añadir {{element['nombre']}}" name="-1">
                          <input *ngIf="element['tipo_dato'] === 'email'" type="email" [(ngModel)]="element['new-valor']" matInput placeholder="Añadir {{element['nombre']}}" name="-1">
                          <input *ngIf="element['tipo_dato'] === 'string'" [(ngModel)]="element['new-valor']" matInput placeholder="Añadir {{element['nombre']}}" name="-1">
                          <input *ngIf="element['tipo_dato'] === 'telefono'" type="tel" pattern="[0-9]{9}" [(ngModel)]="element['new-valor']" matInput placeholder="Añadir {{element['nombre']}}" name="-1">
                          <input *ngIf="element['tipo_dato'] === 'fecha'" type="date" [(ngModel)]="element['new-valor']" matInput name="-1">
                          <mat-select [(ngModel)]="element['new-valor']" *ngIf="element['tipo_dato'] === 'enum'" placeholder="Escoge una opción" name="-1">
                            <ng-container *ngIf="options !== null && options.length && isOpen; else loading">
                              <mat-option *ngFor="let option of options" [value]="option.id">
                                {{option.nombre}}
                              </mat-option>
                            </ng-container>
                            <ng-template #loading>
                              <mat-option disabled>
                                <div class="container-p">
                                  <span>Loading...</span>
                                  <mat-spinner class="spinner" diameter="20"></mat-spinner>
                                </div>
                              </mat-option>
                            </ng-template>
                          </mat-select>
                        </mat-form-field>
                        <div *ngIf="element['tipo_dato'] === 'imagen' || element['tipo_dato'] === 'bit' || element['tipo_dato'] === 'checkbox' || element['tipo_dato'] === null">
                          <!--<mat-checkbox *ngIf="element['tipo_dato'] === 'checkbox'" [(ngModel)]="element['new-valor']" name="-1">Acceptar</mat-checkbox>-->
                          <mat-radio-group *ngIf="element['tipo_dato'] === 'checkbox'" [(ngModel)]="element['new-valor']" [ngModelOptions]="{standalone: true}">
                            <mat-radio-button style="margin-right: 10px;" [value]="1">Sí</mat-radio-button>
                          </mat-radio-group>
                          <input *ngIf="element['tipo_dato'] === 'imagen'" type="file" accept="image/*" (change)="onFileUpload($event, element)" #fileUpload2 name="fileUpload">
                          <mat-radio-group *ngIf="element['tipo_dato'] === 'bit'" [(ngModel)]="element['new-valor']" [ngModelOptions]="{standalone: true}">
                            <mat-radio-button style="margin-right: 10px;" [value]="1">Sí</mat-radio-button>
                            <mat-radio-button [value]="0">No</mat-radio-button>
                          </mat-radio-group>
                        </div>
                        <button class="button-out-raro" type="submit"><span class="material-icons">save</span></button>
                      </form>

                    </div>
                    <div class="puntuation-paginator">
                      <mat-paginator *ngIf="value.length >= 10" [length]="value.length" (page)="onPageChange($event)" [pageSize]="10" [pageSizeOptions]="[10]"></mat-paginator>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>

                <div *ngIf="camposModulo[tab['id']].length <= 0" class="no-data">
                  Todavía no hay información disponible.
                </div>
              </div>

            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>

  </div>

  <!-- CAROUSEL CON LAS INMOS ORDENADAS -->
  <div *ngIf="loadedInfo && loadedInfoInmos" [ngClass]="{'entrada-triumfal':!loadedInfo && !loadedInfoInmos, 'tabla-puntuaciones':loadedInfo && loadedInfoInmos}" style="visibility: hidden; opacity: 0; padding: 4vw 0 0 0;" fxLayout="row wrap" fxFlex="100">
    <div fxLayout="row wrap" fxFlex="10" fxFlex.sm="20" fxFlex.xs="25" style="height: 4em; width: 4em min-height: calc(100% + 5px);">
      <div fxFlex="100">
        <img class="imagen-inmo" [src]="this.inmoPropia.logo_cuadrado" matTooltip="{{this.inmoPropia.orden}}. {{this.inmoPropia.nombre}}">
      </div>
      <span fxFlex="100" class="titulo-position yellow">{{this.inmoPropia.orden}}. {{this.inmoPropia.nombre}}</span>
    </div>
    <div fxLayout="row wrap" fxFlex="90" fxFlex.sm="80" fxFlex.xs="75" class="lista-inmos">
      <p-carousel [value]="this.allInmos" [numVisible]="15" [numScroll]="5" (onPage)="setPage($event)" [circular]="false" [page]="0" [responsiveOptions]="this.responsiveOptions">
        <ng-template let-inmo pTemplate="item">
          <div fxLayout="row wrap" *ngIf="inmo.orden <= maxElement && inmo.orden >= minElement">
            <div fxFlex="100">
              <img [src]="inmo.logo_cuadrado" matTooltip="{{inmo.orden}}. {{inmo.nombre}}">
            </div>
            <span fxFlex="100" class="titulo-position">{{inmo.orden}}. {{this.inmo.nombre}}</span>
          </div>
        </ng-template>
      </p-carousel>
    </div>
  </div>

</div>
