import {Component, OnDestroy, OnInit} from "@angular/core";
import {Strings} from "../../../../../../strings/strings";
import {Subject} from "rxjs";
import {EndpointService} from "../../../../../services/endpoint/endpoint.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'motivo-cancelacion',
  templateUrl: './motivo-cancelacion.html',
  styleUrls: ['./motivo-cancelacion.scss'],
})
export class MotivoCancelacion implements OnInit, OnDestroy {

  public strings = Strings.CALENDAR;
  refresh: Subject<any> = new Subject();
  tempComment: any;
  constructor(private endpointService: EndpointService,
              public dialog: MatDialog,
              public snackBar: MatSnackBar,
              private _dialogRef: MatDialogRef<MotivoCancelacion>) {}
  ngOnInit() {}

  ngOnDestroy() {}

  onNoClick() {
    this._dialogRef.close({state: false});
  }

  setMotivoCancelacion() {
    this._dialogRef.close({state: true, motivo: this.tempComment});
  }

  isInvalid() {
    return !(this.tempComment !== null && this.tempComment !== undefined && this.tempComment !== "");
  }
}
