import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from "@angular/forms";
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-bottom-sheet-new-chat-contact',
  templateUrl: './bottom-sheet-new-chat-contact.component.html',
  styleUrls: ['./bottom-sheet-new-chat-contact.component.css']
})
export class BottomSheetNewChatContactComponent implements OnInit {

  emailFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.email,
  ]);

  messageFormControl = new UntypedFormControl('', [Validators.required]);
  public myId: string;
  constructor(private _bottomSheetRef: MatBottomSheetRef<BottomSheetNewChatContactComponent>,
              @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
    this.myId = data.myId;
  }

  ngOnInit(): void {
  }

  closeBottomSheet(operation: number) {
    if (operation === 1){
      console.log('Voy a enviarle un email a ', this.emailFormControl.value);
      console.log('El contenido del mensaje es... ', this.messageFormControl.value);
    } else {
      this._bottomSheetRef.dismiss();
    }
  }
}
