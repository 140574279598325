<ng-container *ngIf="singleElement.id_tipo !== 3 ; else recursive">
  <div style="width: 100%; position: relative" class="singleElement">
    <span class="creating-mode-buttons">
      <span class="generic-buttons-blue">
        <button mat-raised-button matTooltip="Edit" (click)="editElement(singleElement)">
          <i class="material-icons">edit</i>
        </button>
      </span>
      <span class="generic-buttons-red">
        <button mat-raised-button matTooltip="Delete" (click)="deleteElement(singleElement)">
          <i class="material-icons">delete</i>
        </button>
      </span>
      <span class="generic-buttons-black">
        <button mat-raised-button matTooltip="Guardar como elemento reutilizable" (click)="preSaveElementReuse(singleElement)">
          <i class="material-icons">on_device_training</i>
        </button>
      </span>
      <span class="generic-buttons-black">
        <button mat-raised-button matTooltip="Copy" (click)="copyElement(singleElement)">
          <i class="material-icons">content_copy</i>
        </button>
      </span>
      <span style="width: 32px;">
        <span fxFlex="100" fxLayout="row wrap">
          <span class="generic-buttons-black" fxFlex="100" fxLayout="row">
            <button fxFlex="100" class="move-icons" matTooltip="Move up" matTooltipPosition="above" mat-raised-button
              (click)="moveElementUpDown(singleElement, 'up', this.templateService.arrayElementsPlantilla[0]['children'])">
              <i style="line-height: 13px;" class="material-icons">keyboard_arrow_up</i>
            </button>
          </span>
          <span class="generic-buttons-black" fxFlex="100" fxLayout="row">
            <button fxFlex="100" class="move-icons" mat-raised-button matTooltip="Move down" matTooltipPosition="below"
              (click)="moveElementUpDown(singleElement, 'down', this.templateService.arrayElementsPlantilla[0]['children'])">
              <i style="line-height: 13px;" class="material-icons">keyboard_arrow_down</i>
            </button>
          </span>
        </span>
      </span>
    </span>
    <span class="elemento-contenido-custom-dinamico" (click)="editElement(singleElement); $event.stopPropagation()">
      <span *ngIf="singleElement.html_result" [innerHTML]="this.htmlResultSanitized() | safeHtml"></span>
    </span>
  </div>
</ng-container>

<ng-template #recursive>
  <div fxLayout="row wrap" class="singleElement" fxFlex="100" style="width: 100%; position: relative">
    <span class="creating-mode-buttons" fxFlex="100">
      <span class="generic-buttons-blue">
        <button mat-raised-button matTooltip="Edit" (click)="editElement(singleElement)">
          <i class="material-icons">edit</i>
        </button>
      </span>
      <span class="generic-buttons-red">
        <button mat-raised-button matTooltip="Delete" (click)="deleteElement(singleElement)">
          <i class="material-icons">delete</i>
        </button>
      </span>
      <span class="generic-buttons-black">
        <button mat-raised-button matTooltip="Guardar como elemento reutilizable" (click)="preSaveElementReuse(singleElement)">
          <i class="material-icons">on_device_training</i>
        </button>
      </span>
      <span class="generic-buttons-black">
        <button mat-raised-button matTooltip="Copy" (click)="copyElement(singleElement)">
          <i class="material-icons">content_copy</i>
        </button>
      </span>
      <span style="width: 32px;">
        <span fxFlex="100" fxLayout="row wrap">
          <span class="generic-buttons-black" fxFlex="100" fxLayout="row">
            <button fxFlex="100" class="move-icons" matTooltip="Move up" matTooltipPosition="above" mat-raised-button
              (click)="moveElementUpDown(singleElement, 'up', this.templateService.arrayElementsPlantilla[0]['children'])">
              <i style="line-height: 13px;" class="material-icons">keyboard_arrow_up</i>
            </button>
          </span>
          <span class="generic-buttons-black" fxFlex="100" fxLayout="row">
            <button fxFlex="100" class="move-icons" mat-raised-button matTooltip="Move down" matTooltipPosition="below"
              (click)="moveElementUpDown(singleElement, 'down', this.templateService.arrayElementsPlantilla[0]['children'])">
              <i style="line-height: 13px;" class="material-icons">keyboard_arrow_down</i>
            </button>
          </span>
        </span>
      </span>
    </span>
    <span fxFlex="100" fxLayout="row" [ngClass]="getClassList()" [ngStyle]="getStyleObject()">
      <div *ngFor="let e of singleElement.children | keyvalue : genericService.returnZero" [ngClass]="{
          w100: singleElement.columns == 100,
          w50:
            singleElement.columns == 5050 ||
            (singleElement.columns == 255025 && e.key == 1),
          w30:
            (singleElement.columns == 3070 && e.key == 0) ||
            (singleElement.columns == 7030 && e.key == 1),
          w70:
            (singleElement.columns == 3070 && e.key == 1) ||
            (singleElement.columns == 7030 && e.key == 0),
          w33: singleElement.columns == 333333,
          w25: singleElement.columns == 255025 && (e.key == 0 || e.key == 2)
        }">
        <app-events-template-custom-loop class="component-class" [templateElementsArray]="singleElement.children[e.key].children" [idPlantilla]="idPlantilla" [idParent]="singleElement.children[e.key].idTmp" [parentItem]="singleElement.children[e.key]"></app-events-template-custom-loop>
      </div>
    </span>
  </div>

</ng-template>