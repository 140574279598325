import { Component, OnInit, Input } from '@angular/core';
import { FunctionsService } from "app/services/functions/functions.service";

interface Operator {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'app-events-conditions-custom-loop',
  templateUrl: './events-conditions-custom-loop.component.html',
  styleUrls: ['./events-conditions-custom-loop.component.css'],
})
export class EventsConditionsCustomLoopComponent implements OnInit {
  
  @Input() structure = null;
  @Input() firstElement = true;
  @Input() index: number;
  @Input() param: any = null;
  @Input() conditionsArray;
  @Input() conditionsTables: [];
  @Input() conditionType: number;
  @Input() conditionId: number;
  @Input() idEvento;
  @Input() idReporte;


  display = false;
  allId: number[] = [];
  conditionsInit = [];
  public minimized = false;
  
  public idFirstCondition = 0;
  //public myStyle = null;;

  constructor(
    public functionsService: FunctionsService
  ) { }

  operators: Operator[] = [
    {value: 3, viewValue: 'AND'},
    {value: 4, viewValue: 'OR'},
  ];
  
  ngOnInit(): void {
    this.conditionsInit = this.conditionsArray;
    if(this.conditionsInit && this.conditionsInit.length > 0) {
      this.idFirstCondition = this.conditionsInit[0]["id"];
    }
    console.log(this.idReporte, "this is the ID of the report")
    //this.myStyle = '.condition-' + this.idFirstCondition + ':hover .condition-button-' + this.idFirstCondition + ' button { visibility: visible !important; }';
  }
  
  ngOnChanges(): void {
    this.display = false;
  }
  
  onPress() {
    this.display = true;
  }
  
  newSimpleCondition(arrayConditions, idParent, idEvento, idReporte) {
    arrayConditions.push({id_parent: idParent, id_evento: idEvento, id_reporte: idReporte,
    values: [{valor: ''}],
    borrado: 0})
  }
    
  newGroupCondition(arrayConditions, idParent, idEvento, idReporte) {
    arrayConditions.push({id_subtipo: 3, id_parent: idParent, id_evento: idEvento, id_reporte: idReporte, bd_table: 0, bd_field: 0, id_operador: 0, bd_table2: 0, bd_field2: 0, 
    children: [{id_subtipo: null, id_parent: null, id_evento: idEvento, id_reporte: idReporte, values: [{valor: ''}], borrado: 0}, 
              {id_subtipo: null, id_parent: null, id_evento: idEvento, id_reporte: idReporte, values: [{valor: ''}], borrado: 0}], 
    values: [{valor: ''}],
    borrado: 0})
  }

  newGroupConditionUnique(arrayConditions, idParent) {
    arrayConditions.push({id_subtipo: 3, id_parent: idParent, id_evento: this.idEvento, id_reporte: this.idReporte, bd_table: 0, bd_field: 0, id_operador: 0, bd_table2: 0, bd_field2: 0, 
    children: [{id_subtipo: null, id_parent: null, id_evento: this.idEvento, id_reporte: this.idReporte, values: [{valor: ''}], borrado: 0}], 
    values: [{valor: ''}],
    borrado: 0})
  }

  updateConditionType(type) {
    this.searchTree(this.functionsService.arrayConditions[0], this.conditionId, type);
    console.log(this.functionsService.arrayConditions, "arrayConditions")
  }

  searchTree(arrayConditions, conditionid, type){
    if(arrayConditions.id == conditionid){
      arrayConditions.id_subtipo = type;
      return;
    }else if (arrayConditions.children != null){
      var i;
      var result = null;
      for(i=0; result == null && i < arrayConditions.children.length; i++){
          result = this.searchTree(arrayConditions.children[i], conditionid, type);
      }
      return;
    }
    return null;
  }

  minimize() {
    this.minimized = !this.minimized;
  }

  public filterArrayCondition() {
    return this.conditionsArray.filter(function(element) {
      return element.borrado != 1;
    });
  }
}