import { Component, EventEmitter, Input, OnInit, Output, ViewChild, Renderer2, ElementRef, HostListener } from "@angular/core";
import { FormControl } from "@angular/forms";
import { EndpointService } from "app/services/endpoint/endpoint.service";
import {
  AngularEditorConfig,
  AngularEditorService,
} from "@kolkov/angular-editor";
import { Observable } from "rxjs/internal/Observable";
import { map, startWith } from "rxjs/internal/operators";
import { set } from "date-fns";

@Component({
  selector: "app-chip-creator-custom",
  templateUrl: "./chip-creator-custom.component.html",
  styleUrls: ["./chip-creator-custom.component.scss"],
})
export class ChipCreatorCustomComponent implements OnInit {
  chipLabelControl = new FormControl("", []);
  chipList: any;
  toggled: boolean = false;
  @Input() htmlContent: any = "";
  @Input() label: any = "";
  @Input() showToolBar: any = true;
  @Input() showIcons: any = true;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    showToolbar: this.showToolBar,
    sanitize: false,
    defaultParagraphSeparator: "",
    toolbarPosition: 'bottom',
    toolbarHiddenButtons: [
      [
        'subscript',
        'superscript',
        'heading',
        'fontName'
      ],
      [
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat'
      ]
    ]
  };
  @ViewChild("editor") editor: any;
  public showSelector = false;
  public showingSelector = false;
  filteredOptions: Observable<any>;
  @Output() newChipEvent = new EventEmitter();
  constructor(
    public endpointService: EndpointService,
    public htmlEditorService: AngularEditorService,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    this.config.showToolbar = this.showToolBar;

    const editorElement = this.elementRef.nativeElement.querySelector('.angular-editor-wrapper');

    this.renderer.listen(editorElement, 'paste', (event) => {
      event.preventDefault();
      const text = (event.originalEvent || event).clipboardData.getData('text/plain');

      // Inserta el texto sin formato en el editor
      this.insertTextAtCursor(text);
    });

    this.endpointService.getChipsInfo().subscribe({
      next: (data: any) => {
        this.chipList = data.response;
        this.filteredOptions = this.chipLabelControl.valueChanges.pipe(
          startWith(""),
          map((value: any) => {
            const name = typeof value === "string" ? value : value?.label;
            return name ? this._filter(name as string) : this.chipList.slice();
          })
        );
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  displayFn(user: any): string {
    return user && user.label ? user.label : "";
  }

  private _filter(value: any) {
    const filterValue = value.toLowerCase();

    return this.chipList.filter((option) =>
      option.label.toLowerCase().includes(filterValue)
    );
  }

  addChip() {
    this.showingSelector = true;
    this.showSelector = !this.showSelector;
  }

  addText(id: any) {
    let label = "";
    let nombre_chip = "";
    for (let key in this.chipList) {
      if (this.chipList[key]["id"] == id) {
        label = this.chipList[key]["label"];
        nombre_chip = this.chipList[key]["nombre_chip"];
        break;
      }
    }
    this.editor.focus();
    this.editor.editorService.restoreSelection();
    this.editor.editorService.insertHtml(
      "<mark contenteditable='false'><span style='border: 1px solid #fff; padding: 3px 0.5em; background-color: rgba(var(--primary-color), 0.9); color: #fff; border-radius: 6px; white-space: nowrap;'><span style='display: none;'>__" + nombre_chip + "__</span>" +
      label + "<span style='display: none;'>--" + nombre_chip + "--</span></span></mark>&nbsp;"
    );
    this.chipLabelControl.setValue("");
    this.showSelector = false;
    this.newChipEvent.emit(this.htmlContent);
  }

  sendHtmlCode(event) {
    this.newChipEvent.emit(event);
  }

  handleSelection(event) {
    let emoji = "" + event.char;
    console.log(event.char);
    this.editor.focus();
    this.editor.editorService.restoreSelection();
    this.editor.editorService.insertHtml(emoji);
    this.newChipEvent.emit(this.htmlContent);
  }

  insertTextAtCursor(text: string) {
    const editor = this.editor; // Asegúrate de obtener una referencia al editor de texto angular-editor
    const doc = editor.doc;

    const sel = doc.getSelection();
    const range = sel.getRangeAt(0);

    range.deleteContents();

    // Utiliza getPlainText para obtener el texto sin formato a partir del HTML
    const plainText = this.getPlainText(text);

    const textNode = doc.createTextNode(plainText);
    range.insertNode(textNode);

    // Coloca el cursor al final del texto insertado
    range.setStartAfter(textNode);
    range.setEndAfter(textNode);

    sel.removeAllRanges();
    sel.addRange(range);
  }

  getPlainText(html: string): string {
    // Expresión regular para eliminar los atributos style y class
    const cleanedHtml = html.replace(/(style|class)="[^"]*"/gi, '');
    return cleanedHtml;
  }

  onPaste(event: Event): void {
    // Evita que el evento de pegado predeterminado ocurra
    event.preventDefault();

    // Obtén el texto pegado desde el portapapeles
    const clipboardData = (event as ClipboardEvent).clipboardData || window['clipboardData'];
    const plainText = clipboardData.getData('text/plain');

    // Inserta el texto sin formato en el editor
    this.insertTextAtCursorOnPaste(plainText);
  }

  insertTextAtCursorOnPaste(text: any): void {
    const editor = this.editor.textArea.nativeElement;

    // Obtiene la posición del cursor
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const startOffset = range.startOffset;
    const endOffset = range.endOffset;

    // Inserta el texto en la posición del cursor
    if (startOffset && endOffset) {
      if (editor.value) {
        const textBefore = editor.value.substring(0, startOffset);
        const textAfter = editor.value.substring(endOffset);
        editor.value = textBefore + text + textAfter;
      }

      // Ajusta la posición del cursor después de la inserción
      const newStartOffset = startOffset + text.length;
      if (editor.firstChild && editor.firstChild.textContent && editor.firstChild.textContent.length > newStartOffset) {
        range.setStart(editor.firstChild, newStartOffset);
        range.setEnd(editor.firstChild, newStartOffset);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }

    // Dispara el evento de cambio para notificar al editor sobre la modificación
    editor.dispatchEvent(new Event('input'));
  }

  // Aquí comprobamos que si se ha 
  @HostListener('document:click', ['$event'])
  detectarClickEnDocumento(event: MouseEvent) {
    if (this.showSelector && !this.isChipSelector(event)) {
      if (this.showingSelector) this.showingSelector = false;
      else this.showSelector = false;
    }
  }

  isChipSelector(event: FocusEvent) {
    const inputElement = event.target as HTMLInputElement;
    return inputElement.placeholder === 'Buscar chip';
  }
}