<div class="notis0" [ngStyle]="{'background': background}">
  <div class="each-noti" fxHide="false" fxHide.sm="true" fxHide.xs="true">
    <i *ngIf="iconActive" id="iconing" [className]="'material-icons'" matTooltip="{{iconTooltipe}}" [ngStyle]="{'color': colorIcon}">{{icon}}</i><!-- ¿afegir class animation? -->
    <span style="display: inline-grid; padding-left: 35px !important; width: 100%;">
      <span style="padding-top: 2px;">
        <span style="font-size: 12px; text-transform: uppercase; font-weight: bold; vertical-align: text-top;" *ngIf="!personalizado" [ngStyle]="{'color': colorTitle}">{{title}}</span>
        <span style="font-size: 12px; vertical-align: text-top;" *ngIf="personalizado" [innerHTML]="mensaje | safeHtml"></span>
        <span style="font-size: 12px; vertical-align: text-top; padding: 0 1em; font-weight: 600;" *ngIf="subtitle" [ngStyle]="{'color': colorSubtitle}">-</span>
        <span style="font-size: 12px; vertical-align: text-top; font-weight: 600;" *ngIf="!personalizado" [ngStyle]="{'color': colorSubtitle}">{{subtitle}}</span>
        <button *ngIf="button1" (click)="buttonAction(0)" matTooltip="{{button1Tooltipe}}" [ngStyle]="{'background': button1Background, 'color': button1Color, 'border': '1px solid ' + button1Border}"><i *ngIf="button1ActiveIcon" [className]="'material-icons'" [ngStyle]="{'color': button1IconColor}">{{button1Icon}}</i>{{button1Text}}</button>
        <button *ngIf="button2" (click)="buttonAction(1)" matTooltip="{{button2Tooltipe}}" [ngStyle]="{'background': button2Background, 'color': button2Color, 'border': '1px solid ' + button2Border}"><i *ngIf="button2ActiveIcon" [className]="'material-icons'" [ngStyle]="{'color': button2IconColor}">{{button2Icon}}</i>{{button2Text}}</button>
        <button *ngIf="button3" (click)="buttonAction(2)" matTooltip="{{button3Tooltipe}}" [ngStyle]="{'background': button3Background, 'color': button3Color, 'border': '1px solid ' + button3Border}"><i *ngIf="button3ActiveIcon" [className]="'material-icons'" [ngStyle]="{'color': button3IconColor}">{{button3Icon}}</i>{{button3Text}}</button>
      </span>
    </span>
    <mat-icon *ngIf="close" (click)="closeSuperiorNotification()" class="removeit" [ngStyle]="{'color': colorClose}">cancel</mat-icon>
  </div>
  <div class="each-noti" fxHide="true" fxHide.sm="false" fxHide.xs="false">
    <span style="display: inline-grid; width: 100%;">
      <span style="padding-top: 2px;" fxFlex="100" fxLayout="row wrap">
        <span style="padding: calc(0.5vh + 0.5vw); font-size: 12px; text-transform: uppercase; font-weight: bold; vertical-align: text-top;" fxFlex="100" fxLayout="row wrap" *ngIf="!personalizado" [ngStyle]="{'color': colorTitle}">{{title}}</span>
        <span style="padding: calc(0.5vh + 0.5vw); font-size: 12px; vertical-align: text-top;" fxFlex="100" fxLayout="row wrap" *ngIf="personalizado" [innerHTML]="mensaje | safeHtml"></span>
        <span fxFlex="100" fxLayout="row wrap">
          <button *ngIf="button1" (click)="buttonAction(0)" matTooltip="{{button1Tooltipe}}" [ngStyle]="{'background': button1Background, 'color': button1Color, 'border': '1px solid ' + button1Border}"><i *ngIf="button1ActiveIcon" [className]="'material-icons'" [ngStyle]="{'color': button1IconColor}">{{button1Icon}}</i>{{button1Text}}</button>
          <button *ngIf="button2" (click)="buttonAction(1)" matTooltip="{{button2Tooltipe}}" [ngStyle]="{'background': button2Background, 'color': button2Color, 'border': '1px solid ' + button2Border}"><i *ngIf="button2ActiveIcon" [className]="'material-icons'" [ngStyle]="{'color': button2IconColor}">{{button2Icon}}</i>{{button2Text}}</button>
          <button *ngIf="button3" (click)="buttonAction(2)" matTooltip="{{button3Tooltipe}}" [ngStyle]="{'background': button3Background, 'color': button3Color, 'border': '1px solid ' + button3Border}"><i *ngIf="button3ActiveIcon" [className]="'material-icons'" [ngStyle]="{'color': button3IconColor}">{{button3Icon}}</i>{{button3Text}}</button>
        </span>
      </span>
    </span>
    <mat-icon *ngIf="close" (click)="closeSuperiorNotification()" class="removeit" [ngStyle]="{'color': colorClose}">cancel</mat-icon>
  </div>
</div>
