<div *ngIf="loaded" style="max-width: 100%; width: 100%;"
[ngClass]="structure['id_functional_status_mkt'] != 1 ? structure['class_custom'] + ' ' + 'mkt-no-available' : structure['class_custom']"
[ngStyle]="structure.styleParsed?.style"
[id]="structure['id_functional_area']">
  <mat-checkbox *ngIf="this.authService.checkUserIsDeveloping == 1" [(ngModel)]="this.genericService.devModeGeneric" (change)="this.genericService.devModeGenericFunction()">Dev</mat-checkbox>

  <ng-container *ngFor="let e of structure['child'] | keyvalue : genericService.returnZero">
    <ng-container *ngIf="structure['child'][e.key] && genericService.evaluateIfElements(structure, e.key) && structure['child'][e.key]['id_functional_status_general'] !== 2">

      <h1 mat-dialog-title *ngIf="structure['child'][e.key]['id_functional_type'] === 86"
      [fxHide]="(structure['child'][e.key]['hide']=='true' && structure['child'][e.key]['hide'] != 0) || structure['child'][e.key]['hide'] == 1"
      [fxHide.xs]="(structure['child'][e.key]['hide']=='true' && structure['child'][e.key]['hide'] != 0) || (structure['child'][e.key]['hide_xs']=='true' && structure['child'][e.key]['hide_xs'] != 0) || structure['child'][e.key]['hide'] == 1 || structure['child'][e.key]['hide_xs'] == 1"
      [fxHide.sm]="(structure['child'][e.key]['hide']=='true' && structure['child'][e.key]['hide'] != 0) || (structure['child'][e.key]['hide_s']=='true' && structure['child'][e.key]['hide_s'] != 0) || structure['child'][e.key]['hide'] == 1 || structure['child'][e.key]['hide_s'] == 1"
      [fxHide.md]="(structure['child'][e.key]['hide']=='true' && structure['child'][e.key]['hide'] != 0) || (structure['child'][e.key]['hide_m']=='true' && structure['child'][e.key]['hide_m'] != 0) || structure['child'][e.key]['hide'] == 1 || structure['child'][e.key]['hide_m'] == 1"
      [fxHide.lg]="(structure['child'][e.key]['hide']=='true' && structure['child'][e.key]['hide'] != 0) || (structure['child'][e.key]['hide_l']=='true' && structure['child'][e.key]['hide_l'] != 0) || structure['child'][e.key]['hide'] == 1 || structure['child'][e.key]['hide_l'] == 1"
      [fxHide.xl]="(structure['child'][e.key]['hide']=='true' && structure['child'][e.key]['hide'] != 0) || (structure['child'][e.key]['hide_xl']=='true' && structure['child'][e.key]['hide_xl'] != 0) || structure['child'][e.key]['hide'] == 1 || structure['child'][e.key]['hide_xl'] == 1"

      [ngClass]="structure['child'][e.key]['id_functional_status_mkt'] != 1 ? structure['child'][e.key]['class_custom'] + ' ' + 'mkt-no-available' : structure['child'][e.key]['class_custom']"
      [ngStyle]="structure['child'][e.key].styleParsed?.style"
      [id]="structure['child'][e.key]['id_functional_area']"

      [innerHTML]="structure['child'][e.key]['label'] | safeHtml"
      (click)="genericCreatorService.editOption(structure['child'][e.key])"
      ></h1>

      <div mat-dialog-content *ngIf="structure['child'][e.key]['id_functional_type'] === 87"
      [fxHide]="(structure['child'][e.key]['hide']=='true' && structure['child'][e.key]['hide'] != 0) || structure['child'][e.key]['hide'] == 1"
      [fxHide.xs]="(structure['child'][e.key]['hide']=='true' && structure['child'][e.key]['hide'] != 0) || (structure['child'][e.key]['hide_xs']=='true' && structure['child'][e.key]['hide_xs'] != 0) || structure['child'][e.key]['hide'] == 1 || structure['child'][e.key]['hide_xs'] == 1"
      [fxHide.sm]="(structure['child'][e.key]['hide']=='true' && structure['child'][e.key]['hide'] != 0) || (structure['child'][e.key]['hide_s']=='true' && structure['child'][e.key]['hide_s'] != 0) || structure['child'][e.key]['hide'] == 1 || structure['child'][e.key]['hide_s'] == 1"
      [fxHide.md]="(structure['child'][e.key]['hide']=='true' && structure['child'][e.key]['hide'] != 0) || (structure['child'][e.key]['hide_m']=='true' && structure['child'][e.key]['hide_m'] != 0) || structure['child'][e.key]['hide'] == 1 || structure['child'][e.key]['hide_m'] == 1"
      [fxHide.lg]="(structure['child'][e.key]['hide']=='true' && structure['child'][e.key]['hide'] != 0) || (structure['child'][e.key]['hide_l']=='true' && structure['child'][e.key]['hide_l'] != 0) || structure['child'][e.key]['hide'] == 1 || structure['child'][e.key]['hide_l'] == 1"
      [fxHide.xl]="(structure['child'][e.key]['hide']=='true' && structure['child'][e.key]['hide'] != 0) || (structure['child'][e.key]['hide_xl']=='true' && structure['child'][e.key]['hide_xl'] != 0) || structure['child'][e.key]['hide'] == 1 || structure['child'][e.key]['hide_xl'] == 1"

      [ngClass]="structure['child'][e.key]['id_functional_status_mkt'] != 1 ? structure['child'][e.key]['class_custom'] + ' ' + 'mkt-no-available' : structure['child'][e.key]['class_custom']"
      [ngStyle]="structure['child'][e.key].styleParsed?.style"
      [id]="structure['child'][e.key]['id_functional_area']">
        <ng-container *ngIf="structure['child'][e.key] && structure['child'][e.key]['child']">
          <app-generic [structure]="structure['child'][e.key]"></app-generic>
        </ng-container>
      </div>

      <div mat-dialog-content *ngIf="structure['child'][e.key]['id_functional_type'] !== 86 && structure['child'][e.key]['id_functional_type'] !== 87 && structure['child'][e.key]['id_functional_type'] !== 88">
        <app-generic [structure]="structure"></app-generic>
      </div>

      <div mat-dialog-actions *ngIf="structure['child'][e.key]['id_functional_type'] === 88"
      [fxHide]="(structure['child'][e.key]['hide']=='true' && structure['child'][e.key]['hide'] != 0) || structure['child'][e.key]['hide'] == 1"
      [fxHide.xs]="(structure['child'][e.key]['hide']=='true' && structure['child'][e.key]['hide'] != 0) || (structure['child'][e.key]['hide_xs']=='true' && structure['child'][e.key]['hide_xs'] != 0) || structure['child'][e.key]['hide'] == 1 || structure['child'][e.key]['hide_xs'] == 1"
      [fxHide.sm]="(structure['child'][e.key]['hide']=='true' && structure['child'][e.key]['hide'] != 0) || (structure['child'][e.key]['hide_s']=='true' && structure['child'][e.key]['hide_s'] != 0) || structure['child'][e.key]['hide'] == 1 || structure['child'][e.key]['hide_s'] == 1"
      [fxHide.md]="(structure['child'][e.key]['hide']=='true' && structure['child'][e.key]['hide'] != 0) || (structure['child'][e.key]['hide_m']=='true' && structure['child'][e.key]['hide_m'] != 0) || structure['child'][e.key]['hide'] == 1 || structure['child'][e.key]['hide_m'] == 1"
      [fxHide.lg]="(structure['child'][e.key]['hide']=='true' && structure['child'][e.key]['hide'] != 0) || (structure['child'][e.key]['hide_l']=='true' && structure['child'][e.key]['hide_l'] != 0) || structure['child'][e.key]['hide'] == 1 || structure['child'][e.key]['hide_l'] == 1"
      [fxHide.xl]="(structure['child'][e.key]['hide']=='true' && structure['child'][e.key]['hide'] != 0) || (structure['child'][e.key]['hide_xl']=='true' && structure['child'][e.key]['hide_xl'] != 0) || structure['child'][e.key]['hide'] == 1 || structure['child'][e.key]['hide_xl'] == 1"

      [ngClass]="structure['child'][e.key]['id_functional_status_mkt'] != 1 ? structure['child'][e.key]['class_custom'] + ' ' + 'mkt-no-available' : structure['child'][e.key]['class_custom']"
      [ngStyle]="structure['child'][e.key].styleParsed?.style"
      [id]="structure['child'][e.key]['id_functional_area']">
        <ng-container *ngIf="(structure['child'][e.key] && structure['child'][e.key]['child']) || structure['child'][e.key]['creatingMode']">
          <app-generic [structure]="structure['child'][e.key]"></app-generic>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>

<div *ngIf="!loaded" fxLayoutAlign="center center" fxFlex="100">
  <mat-spinner [diameter]="50"></mat-spinner>
</div>