<h1 mat-dialog-title>Indica el perfil del nuevo usuario USERNAME</h1>
<div mat-dialog-content>
  <!--<p>What's your favorite animal?</p>-->
  <mat-form-field appearance="outline">
    <mat-label>Perfil usuario</mat-label>
    <mat-select [(ngModel)]="selectedValue" >
      <mat-option *ngFor="let perfil of perfiles" [value]="perfil.id">
        {{ perfil.nombre}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false" cdkFocusInitial (click)="onClick()">Aceptar</button>
</div>