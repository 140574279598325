<div id="registry-user" class="registry-user" fxLayout="row wrap" ngStyle.xs="background-image: url('https://movin.cloud/assets/img/FotoFormulari.png'); background-attachment: fixed; background-size: contain;" ngStyle.sm="background-image: url('https://movin.cloud/assets/img/FotoFormulari.png'); background-attachment: fixed; background-size: contain;">

  <div class="fomular" ngStyle.sm="padding: 3em 4.5vw;" ngStyle.xs="padding: 3em 4.5vw;" fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap" style="height: 100%;">
    <h1 fxFlex="100" ngStyle.xs="font-size: 2rem;" ngStyle.sm="font-size: 2rem;">Accede a 4DMOVIN</h1>
    <span fxFlex="100">
      <app-login #LoginComponent (onLogin2Emitter)="onLogin2()"></app-login>
    </span>
    <span fxLayout="row wrap" fxFlex="100">
      <a fxFlex="100" fxFlexAlign="center" style="font-weight: bold; margin-top: 1.5em; text-align: left; width: 100%; font-size: 11px; text-transform: uppercase;" (click)="registrar()">No tengo usuario todavía</a>
      <a fxFlex="100" fxFlexAlign="center" style="margin-top: 4em; text-align: center; width: 100%; font-size: 12px; text-transform: uppercase; font-weight: 600;" (click)="recomendar()" [cdkCopyToClipboard]="'https://movin.cloud/registro-4dmovin'">¿Te gustaría recomendar 4DMOVIN a tus compañeros del sector?</a>
      <span fxFlex="100" fxFlexAlign="center">
        <button style="margin-top: 1em; text-align: center; text-transform: uppercase; font-weight: 600; width: 15em; margin-right: calc(50% - 7.5em); margin-left: calc(50% - 7.5em);" (click)="recomendar()" [cdkCopyToClipboard]="'https://movin.cloud/#49'">RECOMENDAR</button>
      </span>
    </span>
  </div>

  <div class="imagen" fxFlex="50" fxShow.sm="false" fxShow.xs="false" *ngIf="deviceService.isDesktop()">
    <!--<div style="background-image: url('https://movin.cloud/assets/img/FotoFormulari.png'); background-size: 100% 100%; width: 100%; height: 100%;"></div>-->
    <img src="../assets/img/{{img}}" title="{{ 'web.form.imgTitle' | translate}}" alt="{{ 'web.form.imgAlt' | translate}}">
  </div>

</div>
