import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.css']
})

export class InputDialogComponent implements OnInit {

  title: string = null;
  message: string = null;

  input = null;

  constructor(
    // private fb: FormBuilder,
    private dialogRef: MatDialogRef<InputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {

    if (data.title) {
      this.title = data.title;
    }
    if (data.message) {
      this.message = data.message;
    }
  }

  ngOnInit(): void {
  }

  clickButton(buttonOption: number) {
    if(buttonOption == 1) this.dialogRef.close(this.input);
    else this.dialogRef.close(null);
  }

}
