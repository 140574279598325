import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { EndpointService } from '../endpoint/endpoint.service';
import { AuthService } from '../auth/auth.service';
import { Values } from '../../../values/values';

@Injectable()
export class PrivilegiesControlService {
  //NOTA Ibra: He puesto estas propiedades como públicas porque si no desde el header no puede acceder a ellas
  public clientPermissions = null;
  public propertyPermissions = null;
  public userPermissions = null;
  public userProfile = null;
  public userType: number = null;
  public clientFieldsPermissionsArray = new Array();
  public propertyFieldsPermissionsArray = new Array();
  public clientTabsPermissionsArray = new Array();
  public propertyTabsPermissionsArray = new Array();
  public inmoTabsPermissionsArray = new Array();
  public offerTabsPermissionsArray = new Array();
  public maintenanceTabsPermissionsArray = new Array();
  public userFieldsPermissionsArray = new Array();
  public offersPermissionObj = new PermissionObject();
  public reportsPermission = new PermissionObject();

  public clientTabClientInfoEdit: boolean;
  public clientTabPropertiesEdit: boolean;
  public clientTabMatchingEdit: boolean;
  public clientTabActivitiesEdit: boolean;
  public clientTabOffersEdit: boolean;
  public clientTabDocsEdit: boolean;

  public tabPermissions = new Array();

  // Permisos de pestaña
  public tabPrivilegiesShow = false;
  public tabUsersShow = false;
  public tabLocationShow = false;
  public tabSettingsShow = true;
  public tabWorkflowShow = true;

  public tabEmailTemplatesShow = false;
  public tabPdfTemplatesShow = false;
  public tabEventsShow = false;
  public tabCampaignsShow = false;
  public tabReportsShow = false;
  public tabWorkflowAdminShow = false;

  public permissionsLoaded = false;
  public showPrivilegiesDropdown = false;

  private permissionDictionary = {};
  //private permissionDictionary = Values.PERMISSION_LEVELS;

  constructor(private endpointService: EndpointService,
              private authService: AuthService,
              private router: Router,
              public snackBar: MatSnackBar) {}

  public getUserPermissionsAndNavigate(url: string): void {
    this.endpointService.getUserPermissions(this.authService.userId).subscribe(data => {
      console.log("permisos" , data);
      if (data['errorCode'] === 0) {
        this.clientPermissions = data['response'].clientsPermissions;
        this.propertyPermissions = data['response'].propertiesPermissions;
        // this.userPermissions = data['response'].usersPermissions;
        this.userProfile = data['response'].idProfile;
        this.userType = this.userProfile.tipo;
        this.clientFieldsPermissionsArray = data['response'].clientsFieldPermission;
        this.propertyFieldsPermissionsArray = data['response'].propertiesFieldPermission;
        this.clientTabsPermissionsArray = data['response'].clientTabsPermission;
        this.propertyTabsPermissionsArray = data['response'].propertyTabsPermission;
        this.inmoTabsPermissionsArray = data['response'].inmoTabsPermission;
        this.offerTabsPermissionsArray = data['response'].offerTabsPermission;
        this.maintenanceTabsPermissionsArray = data['response'].maintenanceTabsPermission;
        this.userFieldsPermissionsArray = data['response'].userFieldPermission;
        this.createOfferPermission(data['response'].offersPermission);
        this.createReportsPermission(data['response'].reportsPermission);
        this.maintenanceTabsPrivilegies();
        // this.handleShowPrivDropdown(this.userPermissions.acceso); // esto era el menu para entrar a mantenimiento
        this.permissionsLoaded = true;

        /*if (this.authService.isMovinUser) {
          this.router.navigate([Values.ROUTES.ADMIN_INMO]);
        } else if (url) {
          this.router.navigate([url]);
        }*/
        
        if(url != null && url != undefined) {
          console.log('routing');
          this.router.navigate([url]);
        }

      }
      else {
        // No tiene privilegios. Se queda donde estaba
        this.snackBar.open('Se ha producido un error al obtener los permisos de acceso', 'X', {
          duration: 7000, panelClass: ['red-snackbar']
        });
      }
    });
  }

  private createOfferPermission(data) {
    this.offersPermissionObj.acceso = data.acceso;
    this.offersPermissionObj.editar = data.editar;
    this.offersPermissionObj.crear = data.crear;
    this.offersPermissionObj.eliminar = data.eliminar;
    this.offersPermissionObj.exportar = data.exportar;
  }

  private createReportsPermission(data) {
    this.reportsPermission.acceso = data.acceso;
    this.reportsPermission.editar = data.editar;
    this.reportsPermission.crear = data.crear;
    this.reportsPermission.eliminar = data.eliminar;
    this.reportsPermission.exportar = data.exportar;
  }

  private handleShowPrivDropdown(userAccess: number) {
    if (userAccess === 1) { // Entro si tengo acceso 1 (Todo)
      this.showPrivilegiesDropdown = true; // Muestro desplegable privilegios
    }
    else {
      this.showPrivilegiesDropdown = false;
    }
  }

  // Perfil del usuario -- Joan: Movido a AuthService:profileId. Se recoge en el login
  public getUserProfile() {
    return this.userProfile.perfil;
  }

  // Tipo de usuario
  public getUserType() {
    return this.userType;
  }

  // Permisos de cliente
  public getClientPermissions() {
    return this.clientPermissions;
  }

  public getClientAccessPermission(): number {
    return (this.clientPermissions ? this.clientPermissions.acceso : null);
  }

  public getClientEditPermission(): number {
    return (this.clientPermissions ? this.clientPermissions.editar : null);
  }

  public getClientCreatePermission(): number {
    return (this.clientPermissions ? this.clientPermissions.crear : null);
  }

  public getClientDeletePermission(): number {
    return (this.clientPermissions ? this.clientPermissions.eliminar : null);
  }

  // Permisos de Propiedad
  public getPropertyAccessPermission(): number {
    return (this.propertyPermissions ? this.propertyPermissions.acceso : null);
  }

  public getPropertyEditPermission(): number {
    return (this.propertyPermissions ? this.propertyPermissions.editar : null);
  }

  public getPropertyCreatePermission(): number {
    return (this.propertyPermissions ? this.propertyPermissions.crear : null);
  }

  public getPropertyDeletePermission(): number {
    return (this.propertyPermissions ? this.propertyPermissions.eliminar : null);
  }

  // Permisos de usuario
  public getuserAccessPermission(): number {
    return (this.userPermissions ? this.userPermissions.acceso : null);
  }

  public getuserEditPermission(): number {
    return (this.userPermissions ? this.userPermissions.editar : null);
  }

  public getuserCreatePermission(): number {
    return (this.userPermissions ? this.userPermissions.crear : null);
  }

  public getuserDeletePermission(): number {
    return (this.userPermissions ? this.userPermissions.eliminar : null);
  }

  //Permisos de ofertas
  public getOfferPermission() {
    return this.offersPermissionObj;
  }

  // Permisos de reportes
  public getReportsPermission() {
    return this.reportsPermission;
  }

  public canAccessClients() {
    //return (this.clientPermissions && this.clientPermissions.acceso !== this.permissionDictionary.NOTHING);
  }

  public canAccessProperties() {
    //return (this.propertyPermissions && this.propertyPermissions.acceso !== this.permissionDictionary.NOTHING);
  }

  public canAccessOffers() {
    //return this.offersPermissionObj.acceso !== this.permissionDictionary.NOTHING;
  }

  public canCreateOffers() {
    //return this.offersPermissionObj.crear !== this.permissionDictionary.NOTHING;
  }

  public canAccessUsers() {
    //return (this.userPermissions && this.userPermissions.acceso !== this.permissionDictionary.NOTHING);
  }

  // Permisos de campo
  public getClientFieldPermission() {
    return this.clientFieldsPermissionsArray;
  }

  public getPropertyFieldPermission() {
    return this.propertyFieldsPermissionsArray;
  }

  // Permisos de pestaña (tabs)
  public getClientTabsPermission() {
    return this.clientTabsPermissionsArray;
  }

  public getPropertyTabsPermission() {
    return this.propertyTabsPermissionsArray;
  }

  public getInmoTabsPermission() {
    return this.inmoTabsPermissionsArray;
  }

  // Permiso de ofertas
  public getOfferTabsPermission() {
    return this.offerTabsPermissionsArray;
  }

  public getMaintenanceTabsPermission() {
    return this.maintenanceTabsPermissionsArray;
  }

  //Permisos de inmobiliaria

  public getInmoPermissions() {
    return 0;
  }

  private maintenanceTabsPrivilegies() {

    if (this.maintenanceTabsPermissionsArray) {
      for (let i = 0; i < this.maintenanceTabsPermissionsArray.length; i++) {
        if (this.maintenanceTabsPermissionsArray[i].id_tab == 1) {//Privilegios
          if (this.maintenanceTabsPermissionsArray[i].acceso == 0) {//oculto
            this.tabPrivilegiesShow = false;
          }
          else {//visible (acceso = 1)
            this.tabPrivilegiesShow = true;
          }
        }
        else if (this.maintenanceTabsPermissionsArray[i].id_tab == 2) {//Usuarios
          if (this.maintenanceTabsPermissionsArray[i].acceso == 0) {//oculto
            this.tabUsersShow = false;
          }
          else {//visible (acceso = 1)
            this.tabUsersShow = true;
          }
        }
        else if (this.maintenanceTabsPermissionsArray[i].id_tab == 3) {//Ubicaciones
          if (this.maintenanceTabsPermissionsArray[i].acceso == 0) {//oculto
            this.tabLocationShow = false;
          }
          else {//visible (acceso = 1)
            this.tabLocationShow = true;
          }
        }
        else if (this.maintenanceTabsPermissionsArray[i].id_tab == 4) {//Configuración
          if (this.maintenanceTabsPermissionsArray[i].acceso == 0) {//oculto
            this.tabSettingsShow = false;
          }
          else {//visible (acceso = 1)
            this.tabSettingsShow = true;
          }
        }
        else if (this.maintenanceTabsPermissionsArray[i].id_tab == 5) {//Workflow
          if (this.maintenanceTabsPermissionsArray[i].acceso == 0) {//oculto
            this.tabWorkflowShow = false;
          }
          else {//visible (acceso = 1)
            this.tabWorkflowShow = true;
          }
        }
        else if (this.maintenanceTabsPermissionsArray[i].id_tab == 6) {//Workflow
          if (this.maintenanceTabsPermissionsArray[i].acceso == 0) {//oculto
            this.tabEmailTemplatesShow = false;
          }
          else {//visible (acceso = 1)
            this.tabEmailTemplatesShow = true;
          }
        }
        else if (this.maintenanceTabsPermissionsArray[i].id_tab == 7) {//Workflow
          if (this.maintenanceTabsPermissionsArray[i].acceso == 0) {//oculto
            this.tabPdfTemplatesShow = false;
          }
          else {//visible (acceso = 1)
            this.tabPdfTemplatesShow = true;
          }
        }
        else if (this.maintenanceTabsPermissionsArray[i].id_tab == 8) {//Workflow
          if (this.maintenanceTabsPermissionsArray[i].acceso == 0) {//oculto
            this.tabEventsShow = false;
          }
          else {//visible (acceso = 1)
            this.tabEventsShow = true;
          }
        }
        else if (this.maintenanceTabsPermissionsArray[i].id_tab == 9) {//Workflow
          if (this.maintenanceTabsPermissionsArray[i].acceso == 0) {//oculto
            this.tabCampaignsShow = false;
          }
          else {//visible (acceso = 1)
            this.tabCampaignsShow = true;
          }
        }
        else if (this.maintenanceTabsPermissionsArray[i].id_tab == 10) {//Workflow
          if (this.maintenanceTabsPermissionsArray[i].acceso == 0) {//oculto
            this.tabReportsShow = false;
          }
          else {//visible (acceso = 1)
            this.tabReportsShow = true;
          }
        }
        else if (this.maintenanceTabsPermissionsArray[i].id_tab == 11) {//Workflow
          if (this.maintenanceTabsPermissionsArray[i].acceso == 0) {//oculto
            this.tabWorkflowAdminShow = false;
          }
          else {//visible (acceso = 1)
            this.tabWorkflowAdminShow = true;
          }
        }
      }
    }
  }

  /* esto era para obtener el valor y ponerlo disabled.
  public getTabPermission(module, tab) {
    // van por id
    switch (module) {
      case 1: // cliente
          switch (tab) {
            case 1: // Datos generales
              return this.clientTabClientInfoEdit;
              break;
            case 2: // Intereses
              return this.clientTabInterestsEdit;
              break;
            case 3: // Matching
              return this.clientTabMatchingEdit;
              break;
            case 5: // Docs
              return this.clientTabDocsEdit;
              break;
            case 6: // Actividades
              return this.clientTabActivitiesEdit;
              break;
          }
        break;

      case 2: // vivienda
        break;

      case 3: // ofertas
        break;
    }
  }
  */

  public setTabPermission(module, tab, value) {
    switch (module) {
      case 1: // cliente
        switch (tab) {
          case 1: // Datos generales
            this.clientTabClientInfoEdit = value;
            break;
          case 2: // Viviendas
            this.clientTabPropertiesEdit = value;
            break;
          case 3: // Matching
            this.clientTabMatchingEdit = value;
            break;
          case 4: // Actividades
            this.clientTabActivitiesEdit = value;
            break;
          case 4: // Ofertas
            this.clientTabOffersEdit = value;
            break;
          case 6: // Docs
            this.clientTabDocsEdit = value;
            break;
        }
        break;

      case 2: // vivienda
        break;

      case 3: // ofertas
        break;
    }
  }
  /* Joan: Encara no va
  public setTabPermission(module, tab, permission, value) {
console.log(module, tab, permission, value);
    //this.tabPermissions[module][tab][permission].push(value);
    this.tabPermissions[module][tab][permission].push(value);
  }
  */


  // Obenter los permisos de las pestañas.
  public getTabPermission(idCreador, idCaptadoPor1, idCaptadoPor2, idUser, idProfile, accessLevel) {
    let result = true;

    // si tengo acceso a todo puedo editar
    /*if (accessLevel === Values.PERMISSION_LEVELS.ALL) {
      result = true;
    } else if (accessLevel === Values.PERMISSION_LEVELS.OWNER) {
      if (idUser === idCreador ||
        idUser === idCaptadoPor1 ||
        idUser === idCaptadoPor2) {
        result = true;
      } else {
        result = false;
      }
    } else if (accessLevel === Values.PERMISSION_LEVELS.NOTHING) {
      result = false;
    }*/

    return result;
  }
}

export class PermissionObject {
  private _acceso;
  private _crear;
  private _editar;
  private _exportar;
  private _eliminar;

  constructor() {}

  get acceso() {
    return this._acceso;
  }

  set acceso(value) {
    this._acceso = value;
  }

  get crear() {
    return this._crear;
  }

  set crear(value) {
    this._crear = value;
  }

  get editar() {
    return this._editar;
  }

  set editar(value) {
    this._editar = value;
  }

  get exportar() {
    return this._exportar;
  }

  set exportar(value) {
    this._exportar = value;
  }

  get eliminar() {
    return this._eliminar;
  }

  set eliminar(value) {
    this._eliminar = value;
  }
}
