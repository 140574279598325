import { Injectable } from '@angular/core';

// Serivce
import { RoutingService } from "../routing/routing.service";
import { FunctionsService } from './functions.service';
import { GenericService } from '../generic/generic.service';
import { EndpointService} from "../endpoint/endpoint.service";

// Material
import {MatDialog} from "@angular/material/dialog";

// Component
import {DialogComponent} from "../../generic/components/dialog/dialog.component";
//import { MessageDialogComponent } from 'app/utils/message-dialog/message-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
//import { MatTableDataSource} from '@angular/material/table';

//import { InmoPortalDialogComponent } from "../../config-portals-inmo/subcomponents/inmo-portal-dialog/inmo-portal-dialog.component";


//import { map, startWith } from 'rxjs/operators';
//import { throwMatDuplicatedDrawerError } from '@angular/material/sidenav';

//import {Values} from "../../../values/values";


//import {catchError, map} from "rxjs/operators";
//import {HttpErrorResponse, HttpEventType, HttpEvent} from "@angular/common/http";
//import {of} from "rxjs";
//import { PathLocationStrategy } from '@angular/common';



@Injectable({
  providedIn: 'root'
})
export class MarketplaceFunctions {

  public dialogRef = null;

  constructor(
    private routingService : RoutingService,
    public dialog : MatDialog,
    public functionsService: FunctionsService,
    private endpointService: EndpointService,
    public snackBar: MatSnackBar) { }

  getFunction(structure, param = null) {

    console.log(structure);
    console.log(param);

    let id = structure['id_function'];
    let route = structure['internal_routing'];
    /*
    if (param != null) {
      if ("id" in param) {
        this.saveIdInLocalStorage(param["id"]);
      }
    }
     */

    switch (id) {
        case 1:
          var params = {'internal_routing': param.internal_routing, 'id_function': param.id_function};
          this.functionsService.getFunction(params,1);
          break;
        //initialize
        case 600:
            param.that.loadedModulosOferta = false;
            param.that.loadedMisModulos = false;
        
            param.that.endpointService.getModulosOfertas(param.that.authService.inmoId).subscribe(data => {
              console.log(data);
              if (data['errorCode'] === 0) {
                param.that.modulosOferta = data['response'];
                console.log('Modulos oferta: ');
                console.log(param.that.modulosOferta);
                param.that.loadedModulosOferta = true;
              }
            });
        
            param.that.endpointService.getModulosInmo(param.that.authService.inmoId).subscribe(data => {
              if (data['errorCode'] === 0) {
                param.that.misModulos = data['response'];
                console.log('Mis modulos: ');
                console.log(param.that.misModulos);
                param.that.loadedMisModulos = true;
              }
            });
            break;

        case 601:
            //testFunctions
            param.that.endpointService.getModulosOfertas(param.that.authService.inmoId).subscribe(data => {
                console.log('getModulosOfertas');
                if (data['errorCode'] === 0) { console.log(data['response']); }
              });
          
              /*this.endpointService.getModulosPacks().subscribe(data => {
                console.log('getModulosPacks');
                if (data['errorCode'] === 0) { console.log(data['response']); }
              });*/
          
              param.that.endpointService.getModulosInmo(param.that.authService.inmoId).subscribe(data => {
                console.log('getModulosInmo');
                if (data['errorCode'] === 0) { console.log(data['response']); }
              });
            break;

        case 602:
            //insertModuloInmo
            param.that.endpointService.insertModuloInmo(param.that.authService.inmoId, param.that.data.modulo['id'], param.that.data.modulo['precio'],
            param.that.authService.userId).subscribe(data => {
              if (data['errorCode'] === 0) {
                console.log('Modulo introducido correctamente');
                param.that.dialogRef.close();
              }
          });
          break;

        case 603:
          //addProduct
          param.that.genericService.openInEditMode = true;
          localStorage.setItem("product", '0');
          localStorage.setItem("mode", "config");
          param.that.router.navigate([param.that.routes.MARKETPLACE_ADMIN_INFO]);
          break;

        case 604:
          //editProduct
          param.that.genericService.openInEditMode = true;
          localStorage.setItem("product", param.product.id);
          localStorage.setItem("mode", "pro");
          param.that.router.navigate([param.that.routes.MARKETPLACE_ADMIN_INFO]);
          break;

        case 605:
          //config
          param.that.genericService.openInEditMode = true;
          localStorage.setItem("product", param.product.id);
          localStorage.setItem("mode", "config");
          param.that.router.navigate([param.that.routes.MARKETPLACE_ADMIN_INFO]);
          break;
      
        case 606:
          break;

        case 607:
          //selectProduct
          param.that.genericService.openInEditMode = false;
          localStorage.setItem("product", param.product.id);
      
          if(param.that.selectedTab.value == 0){
            localStorage.setItem("mode", "pro");
          }else if (param.that.selectedTab.value == 1){
            localStorage.setItem("mode", "config");
          }
          param.that.router.navigate([param.that.routes.MARKETPLACE_ADMIN_INFO]);
          break;
        
        case 618:
          //eliminarModulo
          param.that.endpointService.desactivarModuloInmo(param.that.authService.inmoId, param.that.moduloReceived['id']).subscribe(data => {
            if (data['errorCode'] === 0) {
              console.log('Modulo desactivado correctamente');
            }
          });
          break;

        case 619:
          //onCancel - NO BD
          param.that.dialogRef.close(false);
          break;

        case 620:
          //onAceptar - NO BD
          param.that.dialogRef.close(true);
          break;

        case 625:
          //addImage
          const fileUpload = param.that.fileUpload.nativeElement;
          fileUpload.onchange = () => {
            for (let index = 0; index < fileUpload.files.length; index++)
            {
              const file = fileUpload.files[index];
              param.that.files.push({ data: file, inProgress: false, progress: 0});
            }
      
            //console.log(this.files[this.files.length - 1].data.name);
            
            //this.uploadFiles();
            this.getFunction(626, {that: param.that});
          };
          fileUpload.click();
          break;

        case 626:
          //uploadFiles
          param.that.fileUpload.nativeElement.value = '';

          //this.uploadFile(param.that.files[param.that.files.length - 1]);
          //this.getFunction();
          break;

    }
  }
}