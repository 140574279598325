import { Component, OnInit, Inject } from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import { FunctionsService } from 'app/services/functions/functions.service';

@Component({
  selector: 'app-bottomsheet-menu',
  templateUrl: './bottomsheet-menu.component.html',
  styleUrls: ['./bottomsheet-menu.component.scss']
})
export class BottomsheetMenuComponent implements OnInit {

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: {},
    private functionsService: FunctionsService
  ) { }

  public structure = null;
  public param = null;
  public loaded = false;

  ngOnInit(): void {
    this.structure = this.data['structure'];
    this.param = this.data['param'];
    this.cleanChilds(this.structure);
    this.loaded = true;
  }

  public cleanChilds(element) {
    if(element['child'] && element['child'][0] !== undefined) {
      for(let i in element['child']) {
        if(this.checkIfHasGroupFA(element['child'])) {
          if(element['id_functional_type'] == 10) {
            element['child'][i]['id_functional_type'] = 7; // Creamos expansion
            element['child'][i]['precreated_menu_mobile'] = 1;
          } else {
            element['child'][i]['id_functional_type'] = 10; // Creamos expansion group
          }
          element['child'][i] = this.cleanChilds(element['child'][i]);
        } else {
          element['id_functional_type'] = 99; // Creamos list group
          element['type'] = "action";
          element['child'][i]['id_functional_type'] = 105; // Creamos list item
        }
      }
    } else {
      if(element['id_functional_type'] == 7) {
        element['child'] = [];

        element['child'][0] = this.functionsService.cloneVariable(element); // Creamos expansion
        element['child'][0]['id_functional_type'] = 99;
        element['child'][0]['type'] = "action";

        element['child'][0]['child'][0] = this.functionsService.cloneVariable(element['child'][0]); // Creamos list group

        element['child'][0]['child'][0]['id_functional_type'] = 105;
      }
    }
    return element;
  }

  private checkIfHasGroupFA(childs) {
    for(let i in childs) {
      if(childs[i]['child'] && childs[i]['child'][0] !== undefined) {
        return true;
      }
    }
    return false;
  }
}
