import {startOfDay, endOfDay} from 'date-fns';
import {CalendarEvent} from 'angular-calendar';
import {UserBody} from 'app/users/objects/user-body';
import {EventType} from './category'
import {SimpleUser} from "./simple-user";


export interface CustomEventAction {
  id?: string | number;
  label: string;
  cssClass?: string;
  a11yLabel?: string;
  onClick({ event, sourceEvent }: {
    event: CustomEvent;
    sourceEvent: MouseEvent | KeyboardEvent;
  }): any;
}

export class CustomEvent implements CalendarEvent {
	meta?: any;
	draggable = true;
	resizable  = {
			beforeStart: true,
			afterEnd: true
		};
	cssClass?: string;
	allDay?: boolean;
	idSerie?: number;
	idAviso1?: number;
	idAviso2?: number;
	idAviso3?: number;
	estado?: number;
	can_motivo?: string;
	actions?: CustomEventAction[] = [];
	color: {primary: string, secondary: string};
	title: string;
	start: Date;
	end: Date;
	id: number;
	idCreator: number;
	nombreCreador: string;
	change: boolean = false;
	idCalendarioGoogle: string;
	idCalendarioMicrosoft: string;

	signLike: number;
	signNotify: boolean;
	signDNI: string;
	signImgURL: string;
	signObservation: string;

	type: EventType;
	comment: string;
	location: string;
	zone: string;
	town: string;

	member: UserBody;
	user: SimpleUser;
	users: SimpleUser[];
	id_oferta: number;
	creatorName: string;
	logoInmo: string;
	myActivity: number;
	accountType: number;
	accountId: number;
	idDemandaRelated: string;
	account: any;
	idParentPeriodicity: number;

	constructor(title?: string, comment?: string, id?: number, idCreator?: number, start?: Date, end?: Date, idCalendarioGoogle?: string, type?: EventType, signLike?: number,
					signNotify?: boolean, signDNI?: string, signImgURL?: string, signObservation?: string, allDay?: boolean, idSerie?: number, idAviso1?: number,
              idAviso2?: number, idAviso3?: number, estado?: number, can_motivo?: string, usuarios?: SimpleUser[], architects?: [],
              idOferta?: number, creatorName?: string, logoInmo?: string, myActivity?: number, accountId?: number, accountType?: number, idParentPeriodicity?:number, location?: string, zone?: string, town?: string) {
		this.title = title || 'Nueva actividad';
		this.creatorName = creatorName;
		this.logoInmo = logoInmo;
		this.myActivity = myActivity;
		this.comment = comment || '';
		this.location = location || '';
		this.zone = zone || '';
		this.town = town || '';

		this.idCalendarioGoogle = idCalendarioGoogle || '';
		this.id = id || -1;
		this.idCreator = idCreator || -1;
		this.accountId = accountId || -1;
		this.accountType = accountType || -1;
		// this.nombreCreador = nombreCreador || '';
		this.start = (start === null || end === undefined) ? null : new Date(start) || startOfDay(new Date());
		this.end = (end === null || end === undefined) ? null : new Date(end) || endOfDay(new Date());
		this.type = type || null;
		if(type !== undefined && type !== null ) this.color = {primary: (type.color), secondary: type.color};
		else this.color = {primary: '#000000', secondary: '#000000'};

		this.signLike = signLike || null;
		this.signNotify = signNotify || false;
		this.signDNI = signDNI || null;
		this.signImgURL = signImgURL || null;
		this.signObservation = signObservation || null;

		this.member = null;
		this.user = null;
		this.users = usuarios || [];
		this.allDay = allDay || false;
		this.idSerie = idSerie || null;
		this.idAviso1 = idAviso1 || null;
		this.idAviso2 = idAviso2 || null;
		this.idAviso3 = idAviso3 || null;
		this.estado = estado || 1;
		this.can_motivo = can_motivo || null;
		this.id_oferta = idOferta || null;
		this.idParentPeriodicity = idParentPeriodicity || null;

		let action = {
		label: '<i class="fa fa-fw fa-pencil"></i>',
		onClick: ({ event }: { event: CustomEvent }): void => {
		}
		};
		// (this.actions !== undefined ? this.actions.push(action) : false);

		if (this.estado !== 1) { this.cssClass = 'customEventClass'; }
		// else if (this.idCreator !== 4) { this.cssClass = 'foreignEventClass'; }
		else { this.cssClass = ''; }
		// this.cssClass = (this.estado !== 1 ? 'customEventClass' : '');
		// this.cssClass = (this.idCreator !== 4 ? 'foreignEventClass' : '');
	}

	setMember(member: UserBody) {
		if (member !== null) {
			this.member = member;
		}
	}

	setUser(user: SimpleUser) {
		if (user !== null) {
			this.user = user;
		}
	}

  addUser(user: SimpleUser) {
    if (user !== null) {
      this.users.push(user);
    }
  }

  deleteUser(user: SimpleUser) {
    if(user !== null) {
      this.users = this.users.filter(e => e.id !== user.id);
    }
  }

	removeMember() {
		this.member = null;
	}


	removeUser() {
		this.user = null;
	}

	setType(type: EventType) {
		this.type = type;
		this.color.primary = type.color;
		this.color.secondary = type.color;
	}

	setAccount(acc: any) {
	  if (acc) {
		this.account = acc;
		this.accountId = acc.id;
		this.accountType = acc.type_account;
    }

  }

}
