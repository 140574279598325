<ng-container *ngIf="structure[index]['type'] == 'p'; else elseBlock">
  <p class="traffic-light-option" *ngIf="structure[index]['trafficLightValue'] >= 1" [matTooltip]="structure[index]['description']" > 
    <i class="{{structure[index]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
      structure[index]['icon_type']=='Filled' ? 'material-icons' :
      structure[index]['icon_type'] =='Round' ? 'material-icons-round' :
      structure[index]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
      structure[index]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}"
      [ngClass]="'generic-icon-' + this.structure[this.index]['icon'] "
      style="color: #17c33e;" > {{ this.structure[this.index]['icon'] }} </i> 
      <span *ngIf="structure[index]['text']" [innerHTML]="structure[index]['text'] | safeHtml"> </span> 
  </p>
  <p class="traffic-light-option" *ngIf="structure[index]['trafficLightValue'] == 0" [matTooltip]="structure[index]['label']"> 
    <i class="{{structure[index]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
    structure[index]['icon_type']=='Filled' ? 'material-icons' :
    structure[index]['icon_type'] =='Round' ? 'material-icons-round' :
    structure[index]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
    structure[index]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}"
    [ngClass]="'generic-icon-' + this.structure[this.index]['icon'] "
    style="color: #dc3544;;" > {{ this.structure[this.index]['icon'] }} </i> 
    <span *ngIf="structure[index]['text']" [innerHTML]="structure[index]['text'] | safeHtml"> </span> 
  </p>
  <p class="traffic-light-option" *ngIf="structure[index]['trafficLightValue'] === null" [matTooltip]="structure[index]['hint']"> 
    <i class="{{structure[index]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
    structure[index]['icon_type']=='Filled' ? 'material-icons' :
    structure[index]['icon_type'] =='Round' ? 'material-icons-round' :
    structure[index]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
    structure[index]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}"
    [ngClass]="'generic-icon-' + this.structure[this.index]['icon'] "
    style="color: #e0e0e0;" > {{ this.structure[this.index]['icon'] }} </i> 
    <span *ngIf="structure[index]['text']" [innerHTML]="structure[index]['text'] | safeHtml"> </span> 
  </p>
</ng-container>
<ng-template #elseBlock>
  <span class="traffic-light-option" *ngIf="structure[index]['trafficLightValue'] >= 1" [matTooltip]="structure[index]['description']" > 
    <i class="{{structure[index]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
    structure[index]['icon_type']=='Filled' ? 'material-icons' :
    structure[index]['icon_type'] =='Round' ? 'material-icons-round' :
    structure[index]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
    structure[index]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}"
    [ngClass]="'generic-icon-' + this.structure[this.index]['icon'] "
    style="color: #17c33e;" > {{ this.structure[this.index]['icon'] }} </i> 
    <span *ngIf="structure[index]['text']" [innerHTML]="structure[index]['text'] | safeHtml"> </span> 
  </span>
  <span class="traffic-light-option" *ngIf="structure[index]['trafficLightValue'] == 0" [matTooltip]="structure[index]['label']"> 
    <i class="{{structure[index]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
    structure[index]['icon_type']=='Filled' ? 'material-icons' :
    structure[index]['icon_type'] =='Round' ? 'material-icons-round' :
    structure[index]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
    structure[index]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}"
    [ngClass]="'generic-icon-' + this.structure[this.index]['icon'] "
    style="color: #dc3544;;" > {{ this.structure[this.index]['icon'] }} </i> 
    <span *ngIf="structure[index]['text']" [innerHTML]="structure[index]['text'] | safeHtml"> </span> 
  </span>
  <span class="traffic-light-option" *ngIf="structure[index]['trafficLightValue'] === null" [matTooltip]="structure[index]['hint']"> 
    <i class="{{structure[index]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
    structure[index]['icon_type']=='Filled' ? 'material-icons' :
    structure[index]['icon_type'] =='Round' ? 'material-icons-round' :
    structure[index]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
    structure[index]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}"
    [ngClass]="'generic-icon-' + this.structure[this.index]['icon'] "
    style="color: #e0e0e0;" > {{ this.structure[this.index]['icon'] }} </i> 
    <span *ngIf="structure[index]['text']" [innerHTML]="structure[index]['text'] | safeHtml"> </span> 
  </span>
</ng-template>
