import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
//import {FormGroup, FormControl} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.css']
})
export class DateRangePickerComponent implements OnInit, OnDestroy {
/*
  range = new FormGroup({
      start: new FormControl(null),
      end: new FormControl(null),
  });
*/
  //  optionCtrl = new FormControl();
  options: string[] = [];

  @Input() filterInitial: any;
  @Input() filterIndex: any;
  @Input() filterFields: any;
  private eventsSubscription: Subscription;
  @Input() resetFilter: Observable<void>;
  @Output() filterChanged = new EventEmitter<any[]>();

  constructor() { 
  }

  ngOnInit(): void {
    this.eventsSubscription = this.resetFilter.subscribe(() => this.clearFilter());
    this.options = this.filterInitial;
  }

  ngOnDestroy(): void {
    this.eventsSubscription.unsubscribe();
  }

  newFilterValues(): void {
    let filter: any[] = [];
    filter.push(this.filterIndex);
    filter.push(this.options);
    this.filterChanged.emit(filter);
  }

  clearFilter(): void {
    this.options = [];
    //this.optionInput.nativeElement.value = ''; --> No funciona i fa que no funcioni el codi de darrera
//    this.optionCtrl.setValue(null);
  
    this.newFilterValues();
  }
}
