<span>
  <div fxLayout="row wrap" fxLayoutAlign="center space-around" style="height:5em; padding: 1em 2em 0em 2em;">
    <span fxFlex="33.33" fxLayout="row wrap">
      <span *ngIf="this.selection.selected.length" class="generic-buttons-blue" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start baseline">
        <mat-form-field appearance="outline" fxFlex="65">
          <mat-select [(value)]="selectedAction" placeholder="Selecciona una acción">
            <mat-option>Ninguna</mat-option>
            <mat-option value="publicar">Publicar</mat-option>
            <mat-option value="no_publicar">No publicar</mat-option>
            <mat-option *ngIf="this.selectedPortal.allow_mostrar_destacados" value="destacar">Destacar</mat-option>
            <mat-option *ngIf="this.selectedPortal.allow_mostrar_destacados" value="no_destacar">No destacar</mat-option>
            <mat-option *ngIf="this.selectedPortal.allow_mostrar_precio" value="precio">Mostrar precio</mat-option>
            <mat-option *ngIf="this.selectedPortal.allow_mostrar_precio" value="no_precio">No mostrar precio</mat-option>
            <mat-option *ngIf="this.selectedPortal.allow_mostrar_ubicacion" value="ubicacion">Mostrar ubicación</mat-option>
            <mat-option *ngIf="this.selectedPortal.allow_mostrar_ubicacion" value="no_ubicacion">No mostrar ubicación</mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-button (click)="prepareMassiveAction()" *ngIf="!selection.isEmpty() && selectedAction" class="ml-3">Aplicar</button>
      </span>
    </span>
    <span fxFlex="33.33" fxLayout="row wrap">
      <span *ngIf="portalHasBeenSelected && selectedPortal.icono" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
        <img src="{{selectedPortal.icono}}" alt="{{selectedPortal.nombre}}" style="max-width:100%; width: 10em; max-height: 3em; object-fit: contain;">
      </span>
    </span>
    <span fxFlex="33.33" fxLayoutAlign="end center" fxLayout="row wrap">
      <mat-form-field appearance="outline" fxFlex="65">
      <mat-select id="selection" placeholder="Selecciona un portal" [(ngModel)]="selectedPortal" (ngModelChange)="onChangePortal()">
        <mat-option *ngFor="let portal of portales;" [value]="portal">{{portal.nombre}}</mat-option>
      </mat-select>
    </mat-form-field>
    </span>
  </div>
  <span *ngIf="portalHasBeenSelected && loaded">
    <div class="bottom-table">
      <div class="bottom-table2">
        <mat-form-field class="input-filter">
          <input matInput [(ngModel)]="inputFilter" (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
        </mat-form-field>
      </div>
      <mat-paginator style="width: 50%;" [pageSizeOptions]="[50,100,200]"></mat-paginator>
    </div>
    <div class="example-container taulesgrans report-portales" style="overflow: auto; height: calc(100vh - 7.6em - 56px);">
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows>

        <!-- Checkbox -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                          [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)"
                          [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Logos -->
        <ng-container matColumnDef="logos">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element; let index = index" class="buttons-body">
            <div>
              <img class="imagen-logo-inmo" [src]="element.logoInmo" matTooltip="{{element.inmoName}}">
            </div>
          </td>
        </ng-container>

        <!-- Collaboration -->
        <ng-container matColumnDef="colaboration">
          <th mat-header-cell *matHeaderCellDef><i class="material-icons">public</i></th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.tipo_colaboracion !== 1 && element.tipo_colaboracion !== 2"><i class="material-icons rounded" matTooltip="Inmueble no compartido">lock</i></span>
            <span *ngIf="element.tipo_colaboracion === 1"><i class="material-icons rounded" matTooltip="Inmueble compartido por Flat Sharing">policy</i></span>
            <span *ngIf="element.tipo_colaboracion === 2"><i class="material-icons rounded" matTooltip="Inmueble compartido por Flat Lending">swap_calls</i></span>
          </td>
        </ng-container>

        <!-- Referencia -->
        <ng-container matColumnDef="referencia_inmo">
          <th mat-header-cell *matHeaderCellDef>Referencia</th>
          <td (click)="expandedElement = expandedElement === element ? null : element" mat-cell *matCellDef="let element; let index = index">{{element.referencia_inmo}} <i class="material-icons hovering">info</i>
          </td>
        </ng-container>

        <!-- Publicado -->
        <ng-container matColumnDef="Publicado">
          <th mat-header-cell *matHeaderCellDef>Publicado ({{numPublicados}}<span *ngIf="maxPublicados>0">/{{maxPublicados}}<mat-icon *ngIf="numPublicados>maxPublicados" matTooltip="Se ha superado el límite de inmuebles publicados" aria-hidden="false" aria-label="danger icon" [ngStyle]="{'color':'red'}" style="height: 20px; width: 20px;">warning</mat-icon></span>)</th>
          <td mat-cell *matCellDef="let element">
            <mat-slide-toggle [(ngModel)]="element.publicado" (ngModelChange)="updateElement(element, 'publicar')"></mat-slide-toggle>
          </td>
        </ng-container>

        <!-- Destacado -->
        <ng-container matColumnDef="Destacado">
          <th mat-header-cell *matHeaderCellDef>Destacado {{numDestacados}}/?
          <!-- <mat-icon *ngIf="numDestacados>maxDestacados" matTooltip="Se ha superado el límite de inmuebles destacados" aria-hidden="false" aria-label="danger icon" [ngStyle]="{'color':'red'}" style="height: 20px; width: 20px;">warning</mat-icon>-->
          </th>

          <!--  DEIXAR AQUEST HEADER QUAN ES FAGIN BÉ ELS DESTACADOS
          <th mat-header-cell *matHeaderCellDef>Destacado ({{numDestacados}}<span *ngIf="maxDestacados>0">/{{maxDestacados}}<mat-icon *ngIf="numDestacados>maxDestacados" matTooltip="Se ha superado el límite de inmuebles destacados" aria-hidden="false" aria-label="danger icon" [ngStyle]="{'color':'red'}" style="height: 20px; width: 20px;">warning</mat-icon></span>)</th>
          -->

          <td mat-cell *matCellDef="let element">
            <mat-slide-toggle [disabled]="!element.publicado" [(ngModel)]="element.destacado" (ngModelChange)="updateElement(element, 'destacar')"></mat-slide-toggle>
          </td>
        </ng-container>

        <!-- Mostrar precio -->
        <ng-container matColumnDef="Mostrar precio">
          <th mat-header-cell *matHeaderCellDef>Mostrar precio ({{numPrecios}}<span *ngIf="maxPrecios>0">/{{maxPrecios}}<mat-icon *ngIf="numPrecios>maxPrecios" matTooltip="Se ha superado el límite de precios mostrados" aria-hidden="false" aria-label="danger icon" [ngStyle]="{'color':'red'}" style="height: 20px; width: 20px;">warning</mat-icon></span>)</th>
          <td mat-cell *matCellDef="let element">
            <mat-slide-toggle [disabled]="!element.publicado" [(ngModel)]="element.precio_mostrado" (ngModelChange)="updateElement(element, 'precio')"></mat-slide-toggle>
          </td>
        </ng-container>

        <!-- Mostrar ubicacion -->
        <ng-container matColumnDef="Mostrar ubicacion">
          <th mat-header-cell *matHeaderCellDef>Mostrar ubicación ({{numUbicaciones}}<span *ngIf="maxUbicaciones>0">/{{maxUbicaciones}}<mat-icon *ngIf="numUbicaciones>maxUbicaciones" matTooltip="Se ha superado el límite de ubicaciones mostradas" aria-hidden="false" aria-label="danger icon" [ngStyle]="{'color':'red'}" style="height: 20px; width: 20px;">warning</mat-icon></span>)</th>
          <td mat-cell *matCellDef="let element">
            <mat-slide-toggle [disabled]="!element.publicado" [(ngModel)]="element.mostrar_ubicacion" (ngModelChange)="updateElement(element, 'ubicacion')"></mat-slide-toggle>
          </td>
        </ng-container>

        <!-- Expandir más info inmueble -->
        <!--
        <ng-container matColumnDef="expand">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
          </th>
          <td (click)="expandedElement = expandedElement === element ? null : element" mat-cell *matCellDef="let element; let index = index" mat-cell>
            <i class="material-icons">info</i>
          </td>
        </ng-container>
        -->

        <!-- Expansion more info property -->
        <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div class="element-detail" style="display: flex; flex-flow: wrap" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <div style="display: flex; width: 100%;">
              <div *ngIf="element.image" style="width: 13%;">
              <img style="width: 100%; padding: 3%; border-radius: 9px; height: auto; min-height: 170px; object-fit: cover;" [src]="element.image"/>
            </div>
              <div *ngIf="!element.image" style="width: 13%;">
              <img style="width: 100%; padding: 3%; border-radius: 9px; height: auto; min-height: 170px; object-fit: cover;" [src]="defaultImg"/>
            </div>
              <div style="width: 87%;">
                <div style="display: flex; width: 100%;">
                  <div style="font-weight: bold; width: 25%;" *ngIf="element.houseSubTypeName"><div><i class="material-icons">home</i> {{element.houseSubTypeName}}</div><div style="padding-left: 0.3em;" *ngIf="element.qualification"> ({{element.qualification}})</div></div>
                  <div style="font-weight: bold; width: 25%;" *ngIf="!element.houseSubTypeName" matTooltip="No hay datos del tipo de inmueble" ><div><i class="material-icons">home</i> No hay datos del tipo de inmueble</div></div>
                  <div style="font-weight: bold; width: 25%;" *ngIf="element.operationTypeName"><div><i class="material-icons">home_repair_service</i> {{element.operationTypeName}}</div></div>
                  <div style="font-weight: bold; width: 25%;" *ngIf="!element.operationTypeName" matTooltip="No hay datos del estado del inmueble"><div><i class="material-icons">home_repair_service</i> No hay datos del tipo de operación del inmueble</div></div>
                  <div style="font-weight: bold; width: 25%;" *ngIf="element.statusName"><div><i class="material-icons">stacked_line_chart</i> {{element.statusName}}<div *ngIf="element.substatusName"> ({{element.substatusName}})</div></div></div>
                  <div style="font-weight: bold; width: 25%;" *ngIf="!element.statusName" matTooltip="No hay datos del estado del inmueble"><div><i class="material-icons">stacked_line_chart</i> No hay datos del estado del inmueble</div></div>
                  <div style="font-weight: bold; width: 25%;" *ngIf="element.inmoPropertyName"> {{element.inmoPropertyName}}</div>
                </div>
                <div style="display: flex; width: 100%;">
                  <div *ngIf="element.address || element.locationName" style="width: 90%;" matTooltip="Dirección"><i class="material-icons">room</i>
                    <span *ngIf="element.address">{{element.address}}</span><span *ngIf="!element.address">(Dirección no definida)</span> - <span *ngIf="element.locationName">{{element.locationName}}</span><span *ngIf="!element.locationName">(Población no definida)</span>
                  </div>
                  <div *ngIf="!element.address && !element.locationName" style="width: 90%;" matTooltip="No hay datos de la dirección ni de la población"><i class="material-icons">room</i> No hay datos de la dirección ni de la población</div>
                  <div *ngIf="element.commercialManager" style="width: 10%; text-align: right">{{element.commercialManager}} <i matTooltip="Gestor" class="material-icons">work</i></div>
                  <div *ngIf="!element.commercialManager" style="width: 10%; text-align: right"><p><i class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> <i matTooltip="No hay datos del gestor" class="material-icons">work</i></p></div>
                </div>
                <div style="display: flex; width: 100%;">
                  <div class="detail-contents1">
                    <div>
                      <p *ngIf="element.m2" matTooltip="Superfície">{{element.m2}} m2</p>
                      <p *ngIf="!element.m2" matTooltip="No hay datos de la superfície"><i class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> m2</p>
                    </div>
                    <div>
                      <p *ngIf="element.price" matTooltip="Precio">{{element.price}} €</p>
                      <p *ngIf="!element.price" matTooltip="No hay datos del precio"><i class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> €</p>
                    </div>
                    <div>
                      <p *ngIf="element.rooms" matTooltip="Número de habitaciones">{{element.rooms}} <i class="material-icons">hotel</i></p>
                      <p *ngIf="!element.rooms" matTooltip="No hay datos de las habitaciones"><i class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> <i class="material-icons">hotel</i></p>
                    </div>
                    <div>
                      <p *ngIf="element.bathrooms" matTooltip="Número de baños">{{element.bathrooms}} <i class="material-icons">bathtub</i></p>
                      <p *ngIf="!element.bathrooms" matTooltip="No hay datos de los baños"><i class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> <i class="material-icons">bathtub</i></p>
                    </div>
                  </div>

                  <div class="detail-contents1">
                    <div>
                      <p *ngIf="element.terraces" matTooltip="Nº terrazas y metros quadrados">{{element.terraces}} Terrazas - {{element.m2Terraces}} m2</p>
                      <p *ngIf="element.terraces == false" matTooltip="Hay 0 terrazas">{{element.terraces}} Terrazas</p>
                      <p *ngIf="element.terraces == null" matTooltip="No hay datos sobre las terrazas"><i class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> Terrazas</p>
                    </div>
                    <div>
                      <p *ngIf="element.balconies" matTooltip="Nº balcones y metros quadrados">{{element.balconies}} Balcones - {{element.m2Balconies}} m2</p>
                      <p *ngIf="element.balconies == false" matTooltip="Hay 0 balcones">{{element.balconies}} Balcones</p>
                      <p *ngIf="element.balconies == null" matTooltip="No hay datos sobre los balcones"><i class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> Balcones</p>
                    </div>
                    <div>
                      <p *ngIf="element.m2LivingRoom" matTooltip="Metros quadrados del salón">{{element.m2LivingRoom}} m2 Salón</p>
                      <p *ngIf="!element.m2LivingRoom" matTooltip="No hay datos sobre los metros quadrados del salón"><i class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> m2 Salón</p>
                    </div>
                    <div>
                      <p *ngIf="element.hasOfficeKitchen" matTooltip="La cocina es tipo office"><i class="material-icons" style="color: #17c33e;">fiber_manual_record</i> Cocina office</p>
                      <p *ngIf="element.hasOfficeKitchen == false" matTooltip="La cocina no es tipo office"><i class="material-icons" style="color: #dc3544;">fiber_manual_record</i> Cocina office</p>
                      <p *ngIf="element.hasOfficeKitchen == null" matTooltip="No se ha indicado si la cocina es tipo office"><i class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> Cocina office</p>
                    </div>
                  </div>

                  <div class="detail-contents1">
                    <div>
                      <p *ngIf="element.hasHeating" matTooltip="Hay calefacción"><i class="material-icons" style="color: #17c33e;">fiber_manual_record</i> <i class="material-icons">fireplace</i></p>
                      <p *ngIf="element.hasHeating == false" matTooltip="No hay calefacción"><i class="material-icons" style="color: #dc3544;">fiber_manual_record</i> <i class="material-icons">fireplace</i></p>
                      <p *ngIf="element.hasHeating == null" matTooltip="No se ha indicado si hay calefacción"><i class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> <i class="material-icons">fireplace</i></p>
                    </div>
                    <div>
                      <p *ngIf="element.hasAirConditioning" matTooltip="Hay aire acondicionado"><i class="material-icons" style="color: #17c33e;">fiber_manual_record</i> <i class="material-icons">toys</i></p>
                      <p *ngIf="element.hasAirConditioning == false" matTooltip="No hay aire acondicionado"><i class="material-icons" style="color: #dc3544;">fiber_manual_record</i> <i class="material-icons">toys</i></p>
                      <p *ngIf="element.hasAirConditioning == null" matTooltip="No se ha indicado si hay aire acondicionado"><i class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> <i class="material-icons">toys</i></p>
                    </div>
                    <div>
                      <p *ngIf="element.hasElevator" matTooltip="Hay ascensor"><i class="material-icons" style="color: #17c33e;">fiber_manual_record</i> <i class="material-icons">unfold_more</i></p>
                      <p *ngIf="element.hasElevator == false" matTooltip="No hay ascensor"><i class="material-icons" style="color: #dc3544;">fiber_manual_record</i> <i class="material-icons">unfold_more</i></p>
                      <p *ngIf="element.hasElevator == null" matTooltip="No se ha indicado si hay ascensor"><i class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> <i class="material-icons">unfold_more</i></p>
                    </div>
                    <div>
                      <p *ngIf="element.hasFurniture" matTooltip="Esta amueblado"><i class="material-icons" style="color: #17c33e;">fiber_manual_record</i> <i class="material-icons">kitchen</i></p>
                      <p *ngIf="element.hasFurniture == false" matTooltip="No esta amueblado"><i class="material-icons" style="color: #dc3544;">fiber_manual_record</i> <i class="material-icons">kitchen</i></p>
                      <p *ngIf="element.hasFurniture == null" matTooltip="No se ha indicado si esta amueblado"><i class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> <i class="material-icons">kitchen</i></p>
                    </div>
                  </div>

                  <div class="detail-contents1">
                    <div>
                      <p *ngIf="element.orientation" matTooltip="Información sobre la orientación del inmueble">{{element.orientation}} <i class="material-icons">explore</i></p>
                      <p *ngIf="!element.orientation" matTooltip="No hay datos sobre la orientación"><i class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> <i class="material-icons">explore</i></p>
                    </div>
                    <div>
                      <p *ngIf="element.hasAirSwimmingPol" matTooltip="Hay piscina"><i class="material-icons" style="color: #17c33e;">fiber_manual_record</i> <i class="material-icons">pool</i></p>
                      <p *ngIf="element.hasAirSwimmingPol == false" matTooltip="No hay piscina"><i class="material-icons" style="color: #dc3544;">fiber_manual_record</i> <i class="material-icons">pool</i></p>
                      <p *ngIf="element.hasAirSwimmingPol == null" matTooltip="No se ha indicado si hay piscina"><i class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> <i class="material-icons">pool</i></p>
                    </div>
                    <div>
                      <p *ngIf="element.hasCommonArea" matTooltip="Hay zona comunitaria"><i class="material-icons" style="color: #17c33e;">fiber_manual_record</i> <i class="material-icons">sports_tennis</i></p>
                      <p *ngIf="element.hasCommonArea == false" matTooltip="No hay zona comunitaria"><i class="material-icons" style="color: #dc3544;">fiber_manual_record</i> <i class="material-icons">sports_tennis</i></p>
                      <p *ngIf="element.hasCommonArea == null" matTooltip="No se ha indicado si hay zona comunitaria"><i class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> <i class="material-icons">sports_tennis</i></p>
                    </div>
                    <div>
                      <p *ngIf="element.parkings" matTooltip="Hay parking"><i class="material-icons" style="color: #17c33e;">fiber_manual_record</i> <i class="material-icons">directions_car</i></p>
                      <p *ngIf="element.parkings == false" matTooltip="No hay parking"><i class="material-icons" style="color: #dc3544;">fiber_manual_record</i> <i class="material-icons">directions_car</i></p>
                      <p *ngIf="element.parkings == null" matTooltip="No se ha indicado si hay parking"><i class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> <i class="material-icons">directions_car</i></p>
                    </div>
                  </div>

                  <div class="detail-contents1">
                    <div>
                      <p *ngIf="element.ownerName && element.inmoPropia === 1" matTooltip="Nombre del propietario">{{element.ownerName}} <i class="material-icons">person</i></p>
                      <p *ngIf="!element.ownerName && element.inmoPropia === 1" matTooltip="No hay datos sobre el nombre del propietario"><i class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> <i class="material-icons">person</i></p>
                      <p *ngIf="element.inmoPropia === 0" matTooltip="No puedes ver el nombre del propietario"><i class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> <i class="material-icons">person</i></p>
                    </div>
                    <div>
                      <p *ngIf="element.ownerPhone && element.inmoPropia === 1" matTooltip="Teléfono del propietario">{{element.ownerPhone}} <i class="material-icons">phone</i></p>
                      <p *ngIf="!element.ownerPhone && element.inmoPropia === 1" matTooltip="No hay datos sobre el teléfono del propietario"><i class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> <i class="material-icons">phone</i></p>
                      <p *ngIf="element.inmoPropia === 0" matTooltip="No puedes ver el teléfono del propietario"><i class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> <i class="material-icons">phone</i></p>
                    </div>
                    <div>
                      <p *ngIf="element.ownerEmail && element.inmoPropia === 1" matTooltip="Email del propietario">{{element.ownerEmail}} <i class="material-icons">email</i></p>
                      <p *ngIf="!element.ownerEmail && element.inmoPropia === 1" matTooltip="No hay datos sobre el email del propietario"><i class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> <i class="material-icons">email</i></p>
                      <p *ngIf="element.inmoPropia === 0" matTooltip="No puedes ver el email del propietario"><i class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> <i class="material-icons">email</i></p>
                    </div>
                    <div>
                      <p *ngIf="element.hasExclusive" matTooltip="Esta en exclusiva"><i class="material-icons" style="color: #17c33e;">fiber_manual_record</i> <i class="material-icons">vpn_key</i></p>
                      <p *ngIf="element.hasExclusive == false" matTooltip="No esta en exclusiva"><i class="material-icons" style="color: #dc3544;">fiber_manual_record</i> <i class="material-icons">vpn_key</i></p>
                      <p *ngIf="element.hasExclusive == null" matTooltip="No se ha indicado si esta en exclusiva"><i class="material-icons" style="color: #e0e0e0;">fiber_manual_record</i> <i class="material-icons">vpn_key</i></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let element; let row; columns: displayedColumns;" class="element-row"
          [class.example-expanded-row]="expandedElement === element"
          style="cursor: pointer;"></tr>
        <!--<tr mat-row *matRowDef="let element; columns: displayedColumns;"
          class="element-row"
          [class.example-expanded-row]="expandedElement === element"
          style="cursor: pointer;">
        </tr>-->
        <tr mat-row *matRowDef="let element; columns: ['expandedDetail']" class="detail-row"></tr>
      </table>
    </div>
  </span>
  <span *ngIf="!portalHasBeenSelected">
    <div class="red-info-div" style="text-align:center;">
      <div class="margin-left-right"><label>Debes seleccionar el portal que quieres ver.</label></div>
    </div>
  </span>
</span>

<span [ngClass]="{'loader-spinner0':true, 'loader-spinner0-out':loadedPublish && loaded}">
  <mat-progress-spinner
    class="loader-spinner"
    [color]="'primary'"
    [value]="75"
    [mode]="'indeterminate'"
    [diameter]="120"
    [strokeWidth]="15">
  </mat-progress-spinner>
</span>
