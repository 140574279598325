import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GenericCreatorService } from 'app/services/generic/custom/generic-creator.service';
import { GenericService } from 'app/services/generic/generic.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-generic-custom-creator-generaldetails',
  templateUrl: './generic-custom-creator-generaldetails.component.html',
  styleUrls: ['./generic-custom-creator-generaldetails.component.css']
})
export class GenericCustomCreatorGeneraldetailsComponent implements OnInit {
  
  idQueryOptions: Observable<string[]>;

  constructor(public genericCreatorService: GenericCreatorService,
              public formBuilder: FormBuilder,
              public genericService:GenericService) { }

  ngOnInit(): void {
    this.idQueryOptions = this.genericCreatorService.containerForm.controls['id_query'].valueChanges.pipe(
      startWith(''),
      map((value: any) => this.genericCreatorService._filterAutocomplete(value || '', this.genericCreatorService.queries, 'id_query')),
    );
  }

  closeAllFloatings() {
    this.genericCreatorService.expandedFloatingIndex = null;
    this.genericCreatorService.expandedFloatingElement = null;
    this.genericCreatorService.resetOptions();
    
  }

  expansionOpened(element: any, index: any){
    this.genericCreatorService.resetOptions();
    this.genericCreatorService.expandedFloatingIndex = index;
    this.genericCreatorService.expandedFloatingElement = element;
    this.genericCreatorService.actualSelectedElementDSB = element;
    this.genericCreatorService.filterStatusOptions(element['id_functional_type']);
    console.log('current floating ele', this.genericCreatorService.expandedFloatingElement);
  }

  displayFn(item: any){
    return item && item.internal_name ? item.internal_name : '';
  }

  addDsb(type: any){
    let id_functional_type: any;
    if(type === 'dialog'){
      id_functional_type = 85;
    } else {
      id_functional_type = 98;
    }
    console.log('id_functional_type', id_functional_type);
    let newObj = this.genericService.cloneVariable(this.genericCreatorService.defaultArray);
    newObj['id_functional_type'] = id_functional_type;
    newObj['internal_name'] = "NEW" + type.toUpperCase();
    newObj['id_functional_status_general'] = 1;
    newObj['creatingMode'] = true;
    this.genericCreatorService.prepareNewEleToAdd(newObj);
    newObj['child'] = [];
    
    /**
     * Pushing Stucture for child of the dialog
     */
    if(id_functional_type == 85){
      let headerChild = this.genericService.cloneVariable(this.genericCreatorService.defaultArray);
      headerChild['id_functional_type'] = 86;
      headerChild['internal_name'] = "Tittle";
      headerChild['id_functional_status_general'] = 1;
      headerChild['styleParsed'] = {};
      this.genericCreatorService.prepareNewEleToAdd(headerChild);
      newObj['child'].push(headerChild)

      let contentChild = this.genericService.cloneVariable(this.genericCreatorService.defaultArray);
      contentChild['id_functional_type'] = 87;
      contentChild['internal_name'] = "Content";
      contentChild['id_functional_status_general'] = 1;
      contentChild['styleParsed'] = {};
      contentChild['child'] = [];
      this.genericCreatorService.prepareNewEleToAdd(contentChild);
      newObj['child'].push(contentChild)

      let footerChild = this.genericService.cloneVariable(this.genericCreatorService.defaultArray);
      footerChild['id_functional_type'] = 88;
      footerChild['internal_name'] = "Footer";
      footerChild['id_functional_status_general'] = 1;
      footerChild['styleParsed'] = {};
      contentChild['child'] = [];
      this.genericCreatorService.prepareNewEleToAdd(footerChild);
      newObj['child'].push(footerChild)
    }
    console.log('newObj', newObj);
    this.genericCreatorService.arrayFloatingElements.push(newObj)
    this.genericCreatorService.pushNewElementInParentChilds(this.genericCreatorService.arrayElementsGenericCreator[0], newObj);
  }

}
