<h1 mat-dialog-title>Se han encontrado dependencias</h1>
<div mat-dialog-content>
  <p>¿Desea eliminar el producto <b>{{data.producto.name}}</b> y sus dependencias?</p>
  <p *ngIf="data.productos.length">Se eliminará del carrito:</p>
  <ul style="margin-left: 30px;" *ngFor="let producto of data.productos">
    <li>{{producto.name}}</li>
  </ul>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancelar</button>
  <button mat-button (click)="onAceptar()">Aceptar</button>
</div>
