import { Injectable } from '@angular/core';
//import {isType} from '@angular/core/src/type';

@Injectable()
export class TableSortService {

  getSortedData(criteria: SearchCriteria, data, hash, array = false, hash2 = null) {
    return data.sort((a, b) => {
      let aux1, aux2;
      if (hash == null) {
        (a[criteria.sortColumn] != null) ? aux1 = a[criteria.sortColumn] : ((typeof a[criteria.sortColumn] === 'string') ? aux1 = null : aux1 = 0);
        (b[criteria.sortColumn] != null) ? aux2 = b[criteria.sortColumn] : ((typeof b[criteria.sortColumn] === 'string') ? aux2 = null : aux2 = 0);
      } else {
        aux1 = '';
        aux2 = '';
        if (array) {
          if (a[criteria.sortColumn] != null) {
            a[criteria.sortColumn].forEach(elem => {
              if (hash[elem] !== undefined) aux1 += hash[elem].name;
            });
          }
          if (b[criteria.sortColumn] != null) {
            b[criteria.sortColumn].forEach(elem => {
              if (hash[elem] !== undefined) aux2 += hash[elem].name;
            });
          }
        } else {
          if (hash2 != null) {
            if (criteria.sortColumn === 'Category') {
              if (a['buyer' + criteria.sortColumn] != null && hash[a['buyer' + criteria.sortColumn]] !== undefined) {
                aux1 += hash[a['buyer' + criteria.sortColumn]].name;
              }
              if (a['tenant' + criteria.sortColumn] != null && hash2[a['tenant' + criteria.sortColumn]] !== undefined) {
                aux1 += hash2[a['tenant' + criteria.sortColumn]].name;
              }
              if (b['buyer' + criteria.sortColumn] != null && hash[b['buyer' + criteria.sortColumn]] !== undefined) {
                aux2 += hash[b['buyer' + criteria.sortColumn]].name;
              }
              if (b['tenant' + criteria.sortColumn] != null && hash2[b['tenant' + criteria.sortColumn]] !== undefined) {
                aux2 += hash2[b['tenant' + criteria.sortColumn]].name;
              }

            }
            else if (criteria.sortColumn === 'status') {
              if (a[criteria.sortColumn] != null && hash[a[criteria.sortColumn]] !== undefined) {
                aux1 += hash[a[criteria.sortColumn]].name;
              }
              if (a['sub' + criteria.sortColumn] != null && hash2[a['sub' + criteria.sortColumn]] !== undefined) {
                aux1 += hash2[a['sub' + criteria.sortColumn]].name;
              }
              if (b[criteria.sortColumn] != null && hash[b[criteria.sortColumn]] !== undefined) {
                aux2 += hash[b[criteria.sortColumn]].name;
              }
              if (b['sub' + criteria.sortColumn] != null && hash2[b['sub' + criteria.sortColumn]] !== undefined) {
                aux2 += hash2[b['sub' + criteria.sortColumn]].name;
              }
            }

          } else {
            if (a[criteria.sortColumn] != null && hash[a[criteria.sortColumn]] !== undefined) {
              if (criteria.sortColumn === 'id_tipo' || criteria.sortColumn === 'id_creador') aux1 += hash[a[criteria.sortColumn]];
              else aux1 += hash[a[criteria.sortColumn]].name;
            }
            if (b[criteria.sortColumn] != null && hash[b[criteria.sortColumn]] !== undefined) {
              if (criteria.sortColumn === 'id_tipo' || criteria.sortColumn === 'id_creador') aux2 += hash[b[criteria.sortColumn]];
              else aux2 += hash[b[criteria.sortColumn]].name;
            }
            if (criteria.sortColumn === 'captor') {
              if (a[criteria.sortColumn + '2'] != null && hash[a[criteria.sortColumn + '2']] !== undefined) {
                aux1 += ' ' + hash[a[criteria.sortColumn + '2']].name;
              }
              if (b[criteria.sortColumn + '2'] != null && hash[b[criteria.sortColumn + '2']] !== undefined) {
                aux2 += ' ' + hash[b[criteria.sortColumn + '2']].name;
              }
            }
            if (criteria.sortColumn === 'captador_1' || criteria.sortColumn === 'vendedor_1') {
              const auxCriteria = criteria.sortColumn.substring(0, criteria.sortColumn.length - 1);
              if (a[auxCriteria + '2'] != null && hash[a[auxCriteria + '2']] !== undefined) {
                aux1 += ' ' + hash[a[auxCriteria + '2']].name;
              }
              if (b[auxCriteria + '2'] != null && hash[b[auxCriteria + '2']] !== undefined) {
                aux2 += ' ' + hash[b[auxCriteria + '2']].name;
              }
            }
          }
        }
      }

      if (typeof aux1 === 'string') aux1 = aux1.toLowerCase();
      if (typeof aux2 === 'string') aux2 = aux2.toLowerCase();

      if (criteria.sortDirection === 'desc') {
        if (aux1 == '' || aux1 == null) return 1;
        if (aux2 == '' || aux2 == null) return -1;
        if (aux1 < aux2) return 1;
        if (aux1 > aux2) return -1;
        return 0;
      } else {
        if (aux1 == '' || aux1 == null) return -1;
        if (aux2 == '' || aux2 == null) return 1;
        if (aux1 < aux2) return -1;
        if (aux1 > aux2) return 1;
        return 0;
      }
    });
  }
}

export class SearchCriteria {
  sortColumn: string;
  sortDirection: string;
}
