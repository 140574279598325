export class UserBody {

  public id:number;
  public name:string;
  public surname:string;
  public telephone:string;
  public email:string;
  public rentalSale:boolean;
  public profile:number;
  public designatedAgent:number;
  public profilePictureURL:string;
  public user:string;
  public password:string;
  public emailMatching: string;
  public standBy: number;
  public inmoId: number;
  public inmoName: string;
  public userToken: string;

  constructor(){
    this.clearBody();
  }


  public handleGetUserResponse(user:any){
    //console.log(user);
    this.id = user.id;
    this.name = user.nombre;
    this.surname = user.apellidos;
    this.telephone = user.telefono;
    this.email = user.email;
    this.rentalSale = user.alquiler_venta;
    this.profile = user.perfil;
    this.designatedAgent = user.gestor_asignado;
    this.profilePictureURL = user.foto;
    this.user = user.usuario;
    this.password = user.password;
    this.standBy = user.stand_by;
    this.emailMatching = user.mail_matching;
    this.inmoId = (user.inmoId) ? user.inmoId : null;
    this.inmoName = (user.inmoName) ? user.inmoName : null;
    this.userToken = (user.usuario_token) ? user.usuario_token : null;
  }

  public clearBody(){
    this.name = null;
    this.surname = null;
    this.telephone = null;
    this.rentalSale = null;
    this.profile = null;
    this.designatedAgent = null;
    this.profilePictureURL = null;
    this.password = null;
    this.emailMatching = null;
    this.standBy = 0;
    this.inmoId = null;
    this.inmoName = null;
    this.userToken = null;
  }

}
