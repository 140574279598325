<div fxLayout="row wrap" class="notis0">
  <span style="font-weight: bolder; font-size: 18px; text-transform: uppercase; margin-bottom: 1em;" fxFlex="100">Hazlo facil, compra MOVINS <i class="material-icons" matTooltip="Para que disfrutéis de las ventajas que ofrece 4DMOVIN, hemos creado una nueva forma mucho más cómoda y simplificada de adquirir nuestros productos. Los MOVINS son la moneda que las empresas usarán para adquirir productos o servicios en la plataforma 4DMOVIN. Están basados en un modelo de suscripción, así pues cada inmobiliaria podrá decidir cuantos MOVINS adquirir y éstos se renovarán con la misma cantidad cuando se terminen. Esta suscripción se podrá modificar y adquirir más MOVINS o menos, según las necesidades de cada empresa." style="cursor: help;">help_outlined</i></span>
  <div fxLayout="row wrap" fxFlex="100">
    <!--<div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" class="taulesgrans">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z2">

        <ng-container matColumnDef="nmovin">
          <th mat-header-cell *matHeaderCellDef> Nº movins </th>
          <td mat-cell *matCellDef="let element; let i = index"><span *ngIf="planesArray.length - 1 == i">> {{element.num_min}}</span><span *ngIf="planesArray.length - 1 != i">{{element.num_min + " - " + element.num_max}}</span> </td>
        </ng-container>

        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef> Precio por Movin </th>
          <td mat-cell *matCellDef="let element"> {{element.precio + " €"}} </td>
        </ng-container>

        <ng-container matColumnDef="iva">
          <th mat-header-cell *matHeaderCellDef> Precio con IVA </th>
          <td mat-cell *matCellDef="let element"><span>{{(element.precio*1.21) | number : '1.2-2'}}</span><span>{{" €"}}</span> </td>
        </ng-container>

        <ng-container matColumnDef="dto">
          <th mat-header-cell *matHeaderCellDef> Descuento aplicado </th>
          <td mat-cell *matCellDef="let element"> {{element.dto_aplicado + " %"}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="headMovins"></tr>
        <tr mat-row *matRowDef="let row; columns: headMovins;"></tr>

      </table>
    </div>-->
    <div fxFlex="25" fxHide.sm="true" fxHide.xs="true"></div>
    <div *ngIf="n && !payOnce" fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap" style="padding: 0 4vw;">
      <div fxFlex="100" fxLayout="row wrap">
        <p fxFlex="100" class="no-movins">Tu suscripción actual es de <b>{{n}} MOVINS </b> <i class="material-icons" matTooltip="Recuerda que se renovarán solamente cuando se agoten :)" style="cursor: help;">help_outlined</i></p>
      </div>
      <div fxFlex="100" fxLayout="row wrap" style="justify-content: space-between;">
        <button mat-button *ngIf="n" (click)="cancelarPlan()" >Cancelar suscripción</button>
        <button mat-button *ngIf="!n" (click)="changeValue()">Escoger suscripción</button>
        <button mat-button *ngIf="n" (click)="changeValue()">Cambiar suscripción</button>
      </div>
    </div>
    <div *ngIf="!n || payOnce" fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" fxLayout="row wrap" style="padding: 0 4vw;">
      <div fxFlex="100" fxLayout="row wrap" style="background: #fff; padding: 3vw 5vw; border-radius: 1em; border: 4px solid #42678f;">
        <span fxFlex="100" class="tittle-buy-movins">Indica el número de MOVINS que quieres para tu suscripción (1MOV = 1€)<i class="material-icons" matTooltip="Recuerda que se renovarán solamente cuando se agoten :)" style="cursor: help;">help_outlined</i></span>
        <mat-form-field fxFlex="100">
          <span matPrefix>Nº</span>
          <input style="color: #000 !important;" matInput type="number" placeholder="Numero Movins"
                 [(ngModel)]="inmoNumber" (ngModelChange)="calcular($event)"> <!--(keyup)="preventInput($event)"-->
          <span matSuffix class="sub-tittle-buy-movins">Recomendación <i class="material-icons" matTooltip="Recuerda que solo se renovarán cuando se terminen." style="cursor: help;">star</i></span>
        </mat-form-field>
        <mat-form-field fxFlex="100">
          <input matInput placeholder="Precio estandard"
                 [(ngModel)]="price" disabled>
          <span matSuffix>€</span>
        </mat-form-field>
        <mat-form-field fxFlex="100">
          <input matInput placeholder="Precio IVA"
                 [(ngModel)]="iva" disabled>
          <span matSuffix>€</span>
        </mat-form-field>
        <!--<mat-form-field fxFlex="100">
          <input matInput placeholder="Descuento"
                 [(ngModel)]="dto" disabled>
          <span matSuffix>%</span>
        </mat-form-field>
        <mat-form-field fxFlex="100">
          <input matInput placeholder="Precio final"
                 [(ngModel)]="finalPrice" disabled>
          <span matSuffix>€</span>
        </mat-form-field>
        <mat-form-field fxFlex="100">
          <input matInput placeholder="Precio final IVA"
                 [(ngModel)]="finalPriceIVA" disabled>
          <span matSuffix>€</span>
        </mat-form-field>-->
        <span *ngIf="inmoNumber < minInmoNumber" class="text-buy-movins" matTooltip="Te recomendamos ajustar el precio ya que si no fuera suficiente para pagar los módulos se podrían hacer varias renovaciones al momento" style="cursor: help;">Actualmente, requieres mínimo de {{totalMinSubscription}} MOVINS para poder pagar los módulos que has adquirido por una suscripción <i class="material-icons">help_outlined</i></span>
      </div>
      <div fxFlex="100" fxLayout="row wrap" style="justify-content: space-between; margin-top: 1em;">
        <!--<button mat-button (click)="changeValue()">Volver</button>-->
        <button mat-button (click)="buy()" [disabled]="!inmoNumber || inmoNumber < 0 || inmoNumber < minInmoNumber">
          <ng-container *ngIf="!n && minMovins == 0">Suscribirme</ng-container>
          <ng-container *ngIf="n && minMovins == 0">Actualizar suscripción</ng-container>
          <ng-container *ngIf="minMovins > 0">Comprar</ng-container>
        </button>
      </div>
    </div>
  </div>
  <div fxFlex="100" style="justify-content: space-between; display: flex; margin-top: 1em;">
    <button mat-button (click)="cancelar()"> Cerrar </button>
  </div>
</div>
