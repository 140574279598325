import {Observable, of} from "rxjs";

export class DynamicReportBody {

  public id: number;

  public activePlantilla: boolean;
  public active: boolean; //Esta variable activa y desactiva el cron de los eventos
  public activeFrontend: boolean;
  public interactive: number;

  public name: string;
  public idType: number;
  public snackTime: number;
  public myScreen;
  public title: string;
  public titleColor: string;
  public subtitle: string;
  public subtitleColor: string;
  public icon: string;
  public icon_color: string;
  public icon_type: number;
  public icon_tooltipe: string;
  public icon_active: boolean;
  public cerrar: boolean;
  public colorCerrar: string;
  public backgroundColor: string;
  public num_veces: number;

  public useMode: number;

  public buttonActionXAsignar;
  public buttonActionAsignats;

  public horaEjecucion: Date;
  public horaFinal: Date;

  public fechaEjecucion: Date;

  public mensaje: string;
  public personalizado: boolean;
  public mensaje_id_modulo: number;

  public closeOutsideWindow: boolean;
  public hideStepHeader: boolean;
  public soloStepActual: boolean;

  public journey: number;

  constructor () {
    this.clearBody();
  }

  public clearBody() {

    this.id = 0;

    this.activePlantilla = true;
    this.active = true;
    this.activeFrontend = true;
    this.interactive = 0;

    this.name = null;
    this.idType = 1;
    this.snackTime = null;
    this.myScreen = new Array();
    this.title = null;
    this.titleColor = "#000000";
    this.subtitle = null;
    this.subtitleColor = "#000000";
    this.icon = "event";
    this.icon_color = "#FF0000";
    this.icon_type = 1;
    this.icon_tooltipe = null;
    this.icon_active = true;
    this.cerrar = true;
    this.colorCerrar = "#000000";
    this.backgroundColor = "#000000";
    this.useMode = 0;
    this.buttonActionXAsignar = new Array();
    this.buttonActionAsignats = new Array();
    this.num_veces = 0;
    this.mensaje = null;
    this.personalizado = false;
    this.mensaje_id_modulo = null;

    this.closeOutsideWindow = true;
    this.hideStepHeader = false;
    this.soloStepActual = false;

    this.journey = null;

  }

  public handlePlantillaInfoResponse(body: any, screen: any, icon: any): Observable<DynamicReportBody> {

    this.id = body.id;

    this.active = body.actiu;
    this.activeFrontend = body.actiu_frontend;
    this.interactive = body.interactuacion;

    this.name = body.nombre;
    this.idType = body.id_tipo;
    this.snackTime = body.snackbar_tiempo;
    for (let i = 0; i < screen['length']; i++) this.myScreen.push(screen[i].id_pantalla);
    this.title = body.titulo;
    this.titleColor = body.titulo_color;
    this.subtitle = body.subtitulo;
    this.subtitleColor = body.subtitulo_color;
    if(icon['length'] > 0){
      this.icon = icon[0].icon_nombre;
      this.icon_color = icon[0].icon_color;
      this.icon_type = icon[0].id_icon_clase;
      this.icon_tooltipe = icon[0].icon_tootltip;
    }else{
      this.icon_active = false;
    }
    this.cerrar = body.button_cerrar;
    this.colorCerrar = body.cerrar_color;
    this.backgroundColor = body.fondo_color;
    this.useMode = body.calendario;
    this.num_veces = body.num_veces;
    this.mensaje = body.mensaje;
    this.personalizado = body.personalizado === 1;
    this.mensaje_id_modulo = body.mensaje_id_modulo;
    this.closeOutsideWindow = body.cerrar_fuera_pantalla;
    this.hideStepHeader = body.ocultar_header_step;
    this.soloStepActual = body.solo_tit_step_actual;

    this.journey = body.id_customer_journey;

    return of(this);

  }

}
