import { Component, Input, OnInit } from '@angular/core';
import { GenericService } from 'app/services/generic/generic.service';
import { GenericCreatorService } from 'app/services/generic/custom/generic-creator.service';
import {SelectionModel} from '@angular/cdk/collections';

@Component({
  selector: 'app-table-generic-creator',
  templateUrl: './table-generic-creator.component.html',
  styleUrls: ['./table-generic-creator.component.css']
})
export class TableGenericCreatorComponent implements OnInit {
  
  @Input() structure = null;
  @Input() index: number;

  public loaded = false;
  displayedColumns = [];
  dataSource = [];
  aux = [];

  public sticky;
  public search;
  public paginatorOptions;
  public selection = new SelectionModel<any>(true, []);

  constructor(public genericService: GenericService,
    public genericCreatorService: GenericCreatorService) { }

  ngOnInit(): void {
    this.sticky = this.structure[this.index]['sticky'] == 1;
    this.search = this.structure[this.index]['search'] == 1;
    this.paginatorOptions = this.structure[this.index]['paginator'] === null ? null : this.structure[this.index]['paginator'] != 0 ? this.structure[this.index]['paginator'].split(',') : null;
    
    this.displayedColumns = [];
    if(this.structure[this.index]['type'] == "checkbox") {
      this.aux.push({"Name":"0000-selectorCheckbox", "Order": -999999999999});
      this.displayedColumns.push("0000-selectorCheckbox");
    }
    let i = 0;
    for(let ch in this.structure[this.index]['child']) {
      this.displayedColumns.push(this.structure[this.index]['child'][ch]['id_functional_area'] + '-' + this.structure[this.index]['child'][ch]['bd_field']);
      if(this.structure[this.index]['child'][ch]['bd_field'] !== "checkbox") {
        let bd_field
        this.structure[this.index]['child'][ch]['bd_field'] = this.structure[this.index]['child'][ch]['bd_field'] !== null ? this.structure[this.index]['child'][ch]['bd_field'] : ""
        if (!this.structure[this.index]['child'][ch]['bd_field'].includes(',')) bd_field = this.structure[this.index]['child'][ch]['id_functional_area'] + '-' + this.structure[this.index]['child'][ch]['bd_field'].toLowerCase()
        else bd_field = this.structure[this.index]['child'][ch]['id_functional_area'] + '-' + this.structure[this.index]['child'][ch]['bd_field'].split(',')[0].toLowerCase()
        this.aux.push({
          Name: bd_field,
          Order: this.structure[this.index]['child'][ch]['order_auto_cron'],
          Sort: !(this.structure[this.index]['child'][ch]['sort'] == 0),
          Id: i,
          icon: this.structure[this.index]['child'][ch]['icon'],
          class: this.structure[this.index]['child'][ch]['icon_type'] == 'Outlined' ? "material-icons-outlined" :
            this.structure[this.index]['child'][ch]['icon_type'] == 'Filled' ? "material-icons" :
              this.structure[this.index]['child'][ch]['icon_type'] == 'Round' ? "material-icons-round" :
                this.structure[this.index]['child'][ch]['icon_type'] == 'Two-tone' ? "material-icons-two-tone" :
                  this.structure[this.index]['child'][ch]['icon_type'] == 'Sharp' ? "material-icons-sharp" : "material-icons",
          Tooltipp: this.structure[this.index]['child'][ch]['tooltip']
        })
      }
      ++i;
    }
    let obj = {};
    for(let i in this.displayedColumns) {
      if(this.displayedColumns[i] == "0000-selectorCheckbox") continue;
      obj[this.displayedColumns[i]] = 'Hello world';
    }
    this.dataSource = [
      obj, obj, obj
    ];
    this.loaded = true;
  }

  checkArray(element: any) {
    return Array.isArray(element) && element.length > 1;
  }

  
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  checkSelectionMultiple(i, row) {
    row['rowID'] = i;
    this.selection.toggle(row);
    return true;
  }

}
