import { Component, OnInit, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import {FormControl} from '@angular/forms';
import {GenericService} from "../../../../services/generic/generic.service";
import 'moment/locale/fr';
import { ComponentResourceCollector } from '@angular/cdk/schematics';
import {Subject} from 'rxjs';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

@Component({
  selector: 'app-bottom-sheet-filter-table',
  templateUrl: './bottom-sheet-filter-table.component.html',
  styleUrls: ['./bottom-sheet-filter-table.component.css'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class BottomSheetFilterTableComponent implements OnInit {

  public arrayFiltersFields = [];

  //public filteredOptions = [];
  public eventResetFilter: Subject<void> = new Subject<void>();
  
  public loaded = false;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
              public _bottomSheetRef: MatBottomSheetRef<BottomSheetFilterTableComponent>,
              public genericService: GenericService,
              private adapter: DateAdapter<any>,
              @Inject(MAT_DATE_LOCALE) private _locale: string) {
    this.arrayFiltersFields = data;
  }

  ngOnInit(): void {
    for(let i in this.arrayFiltersFields) {
      if(this.arrayFiltersFields[i]['type'] == 191) {
        if(this.arrayFiltersFields[i]['filter'] === undefined || this.arrayFiltersFields[i]['filter']['min'] === undefined || this.arrayFiltersFields[i]['filter']['max'] === undefined) {
          this.arrayFiltersFields[i]['filter'] = {min: null, max: null};
        }
      } else if(this.arrayFiltersFields[i]['type'] == 1999999) {
        if(this.arrayFiltersFields[i]['filter'] === undefined || this.arrayFiltersFields[i]['filter']['fechaInicio'] === undefined || this.arrayFiltersFields[i]['filter']['fechaFin'] === undefined) {
          this.arrayFiltersFields[i]['filter'] = {fechaInicio: null, fechaFin: null};
        }
      }
    }
    this.loaded = true;
  }

  filterChanged(filter: any[]) {
    this.arrayFiltersFields[filter[0]]['filter'] = filter[1];
  }

  public closeBottomSheet() {
    this._bottomSheetRef.dismiss(3);
  }

  public filterTableValues() {
    this._bottomSheetRef.dismiss(2);
  }

  public resetFilterTableValues() {
    this.eventResetFilter.next(); // Emit event to childs

    for(let i in this.arrayFiltersFields){
      this.arrayFiltersFields[i]['filter'] = [];
    }
    this._bottomSheetRef.dismiss(1);
  }

  public clearFieldFilter(key, type) {
    if(type == 1) this.arrayFiltersFields[key]['filter'].min = null;
    else if(type == 2) this.arrayFiltersFields[key]['filter'].max = null;
    else if(type == 3) this.arrayFiltersFields[key]['filter'].fechaInicio = null;
    else if(type == 4) this.arrayFiltersFields[key]['filter'].fechaFin = null;
    else this.arrayFiltersFields[key]['filter'] = [];
  }

  public addMinMax(index) {
    let filter = this.arrayFiltersFields[index]['filter'];
    if(filter.min != null && filter.min != undefined && filter.min != '' && filter.min != null && filter.max != undefined && filter.max != '' && filter.min > filter.max) {
      // filter.max = this.genericService.cloneVariable(filter.min);
      if(!this.genericService.snackbarOpened) this.genericService.openSnackBar('El valor Mínimo debe ser menor al valor Máximo', 7000,["blue-snackbar"]);
    }
    return true;
  }

  public checkIsArray(array) {
    return Array.isArray(array);
  }
}
