import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthService } from "./services/auth/auth.service";

import { Observable, Subscribable, SubscriptionLike, Subscription } from 'rxjs' // Daniel
//import { StripeModule, StripeScriptTag } from "stripe-angular"; // Daniel



import { AppComponent } from "./app.component";
import { HomeComponent } from "./generic/components-custom/agenda-custom/agenda-custom.component";
import { DbSchemaCustomComponent } from "./generic/components-custom/db-schema-custom/db-schema-custom.component";

import { Values } from "../values/values";
import { EndpointService } from "./services/endpoint/endpoint.service";
import { RedirectionService } from "./services/redirection/redirection.service";
import { ActivitiesService } from "./services/activities/activities.service";
import {
  NgbModalModule,
  NgbModule,
  NgbPopoverModule,
} from "@ng-bootstrap/ng-bootstrap";


import { LoginComponent, PrivacyDialog } from "./login/login.component";
import { PrivilegiesControlService } from "./services/privilegies-control/privilegies-control.service";
import { MatButtonModule } from "@angular/material/button";
import {
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  MatRippleModule,
} from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatInputModule } from "@angular/material/input";
import { TextFieldModule } from "@angular/cdk/text-field";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatCardModule } from "@angular/material/card";
import { ContainerDeferComponent } from "./generic/components/container-defer/container-defer.component";
import { AgmCoreModule } from "@agm/core";
import { CarouselModule } from "primeng/carousel";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MultiselectDropdownModule } from "angular-2-dropdown-multiselect";
import { ImageUploadModule } from "angular2-image-upload";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { MomentModule } from "angular2-moment";
import {
  AngularEditorModule,
  AngularEditorService,
} from "@kolkov/angular-editor";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { NgDompurifyModule } from "@tinkoff/ng-dompurify";
import { EgoComponent } from "./ego/ego.component";
import { JourneyEgoComponent } from "./ego/subcomponents/journey_ego/journey-ego.component";
import { CampanyaEgoComponent } from "./ego/subcomponents/campaña_ego/campanya-ego.component";
import { JourneyEgoPopComponent } from "./ego/popup/journey_ego_pop/journey-ego-pop.component";
import { CampanyaEgoPopComponent } from "./ego/popup/campaña_ego_pop/campanya-ego-pop.component";
// Translations
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

// validators
import { CheckSpecialCharacterDirective } from "./validators/check-special-character.directive";
import { CheckNumberDirective } from "./validators/check-number.directive";
import { MatchPasswordDirective } from "./validators/match-password.directive";
import { CheckLowerCaseDirective } from "./validators/check-lower-case.directive";
import { CheckUpperCaseDirective } from "./validators/check-upper-case.directive";

import { ImageUploaderService } from "./services/image-uploader/image-uploader.service";
import { ClickStopPropagation } from "./services/directives/stop-propagation.directive";

// Utils
import { ImageSelectorModalComponent } from "./utils/imageSelector/imageSelector.component";
import { TargetListManagerComponent } from "app/utils/targetListManager/targetListManager.component";
import { SortableColumnComponent } from "./utils/sortable-column/sortable-column.component";
import { PredefinedValuesLoaderService } from "./services/LoadPredefinedValuesServices";
import { LoadExternValuesService } from "./services/LoadExternValuesService";
import { UploadFileModalComponent } from "./utils/uploadFIleModal/UploadFileModalComponent";
import { TableSortService } from "./services/sort-service/table-sort.service";
import { SortService } from "./services/sort-service/sort-service.service";
import { SortableTableDirective } from "./services/sort-service/sortable-table.directive";
import { TwoOptionsModalComponent } from "./utils/TwoOptionsModal/twoOptionsModal.component";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { CommonModule, DatePipe, registerLocaleData } from "@angular/common";
import { FlatpickrModule } from "angularx-flatpickr";
import { MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { MatTableExporterModule } from "mat-table-exporter";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatIconModule } from "@angular/material/icon";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatMenuModule } from "@angular/material/menu";
import { MatChipsModule } from "@angular/material/chips";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatListModule } from "@angular/material/list";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatStepperModule } from "@angular/material/stepper";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { FlexLayoutModule } from "@angular/flex-layout";
import { CDK_DRAG_CONFIG, DragDropModule } from "@angular/cdk/drag-drop";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { InputDialogComponent } from "./input-dialog/input-dialog.component";

//dialogs
import { DeleteConfirmDialog } from "./utils/delete-confirm-dialog/delete-confirm-dialog";

// bottom sheets
import { BottomSheetUploadImage } from "./utils/bottom-sheet-upload-image/bottom-sheet-upload-image";
import { BottomSheetImageSelector } from "./utils/bottom-sheet-image-selector/bottom-sheet-image-selector";
import { BottomSheetUploadFile } from "./utils/bottom-sheet-upload-file/bottom-sheet-upload-file";
import { BottomSheetTrackingLink } from "./utils/bottom-sheet-tracking-link/bottom-sheet-tracking-link";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { SideNavService } from "./utils/SideNavService";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MessageDialogComponent } from "./utils/message-dialog/message-dialog.component";
import { ResetPasswordComponent } from "./login/reset-password/reset-password.component";
import { SetPasswordComponent } from "./login/set-password/set-password.component";
import { DisableControlDirective } from "./validators/disable-control.directive";
import localeEs from "@angular/common/locales/es";
import { DragAndDropModule } from "angular-draggable-droppable";

import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatToolbarModule } from "@angular/material/toolbar";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import {
  NotificationsComponent,
  SafeStylePipe,
} from "./generic/components-custom/agenda-custom/subcomponents/notifications/notifications.component";

import { MatGoogleMapsAutocompleteModule } from "@angular-material-extensions/google-maps-autocomplete";
import { SafePipe } from "./utils/Pipe/SafePipe";

// Joan: Error en la versió 14 - Ho comento perquè sembla que no es fa servir
//import {MatVideoModule} from 'mat-video';
import { SocketIoConfig, SocketIoModule } from "ngx-socket-io";
import { DynamicReportComponent } from "./workflow/subcomponents/dynamic-report/dynamic-report.component";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { DynamicReportDetailsComponent } from "./workflow/subcomponents/dynamic-reportdetails/dynami-reportdetails.component";
import { BillComponent } from "./bill/bill.component";
import { AddMovinCoinComponent } from "./bill/subcomponent/add-movin-coin.component";
import { IconScreenComponent } from "./workflow/subcomponents/dynamic-reportdetails/iconscreen/icon-screen.component";
import { AutoPlantillaComponent } from "./auto-plantilla/auto-plantilla.component";
import { AutoAdviceHomeComponent } from "./auto-plantilla/auto-advice-home/auto-advice-home.component";
import { AutoAdviceSuperiorComponent } from "./auto-plantilla/auto-advice-superior/auto-advice-superior.component";
import { AutoBottomSheetComponent } from "./auto-plantilla/auto-bottom-sheet/auto-bottom-sheet.component";
import { AutoPopupComponent } from "./auto-plantilla/auto-popup/auto-popup.component";
import { AutoSnackbarComponent } from "./auto-plantilla/auto-snackbar/auto-snackbar.component";
import { StripePaymentComponent } from "./stripe/stripe-payment/stripe-payment.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { AdviceReportService } from "./services/advice-report/advice-report.service";
import { AutoComponentsComponent } from "./auto-plantilla/auto-components/auto-components.component";
import { MarketplaceComponent } from "./marketplace/marketplace/marketplace.component";
import { MarketplaceCardComponent } from "./marketplace/marketplace-card/marketplace-card.component";
import { ConfigDialogComponent } from "./marketplace/config-dialog/config-dialog.component";
import { ScoresComponent } from "./landing/scores/scores.component";
import {
  DemoNumber,
  KnowledgeComponent,
} from "./knowledge/knowledge/knowledge.component";
import { CommunityComponent } from "./community/community/community.component";
import { YouTubePlayerModule } from "@angular/youtube-player";
import { KnowledgeVideoComponent } from "./knowledge/knowledge-video/knowledge-video.component";
import { AutoSidenavComponent } from "./auto-plantilla/auto-sidenav/auto-sidenav.component";
import { KnowledgePlaceComponent } from "./places/knowledge-place/knowledge-place.component";
import { ContainerComponent } from "./places/container/container.component";
import { CarouselComponent } from "./places/carousel/carousel.component";
import { ConfigurationComponent } from "./places/checkout-components/configuration/configuration.component";
import { DependenciesComponent } from "./places/checkout-components/dependencies/dependencies.component";
import { CheckoutComponent } from "./places/checkout-components/checkout/checkout.component";
import { BuyMovinsComponent } from "./places/checkout-components/buy-movins/buy-movins.component";
import { GeneralComponent } from "./places/checkout-components/general/general.component";
import { MovinsHistoryComponent } from "./places/movins-history/movins-history.component";
import { ModuleConfigComponent } from "./places/module-config/module-config.component";
import { LocalStorageService } from "./services/local-storage.service";
import { ChatComponent } from "./header/chat/chat.component";
import { PerfilInmoComponent } from "./community/perfil-inmo/perfil-inmo.component";
import { InmoCardComponent } from "./community/inmo-card/inmo-card.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommunityAdministratorComponent } from "./community/community-administrator/community-administrator.component";

import { SwapAccountComponent } from "./swap-account/swap-account.component";

import { SelectGroupUsersComponent } from "./header/chat/select-group-users/select-group-users.component";
import { ConfirmationDialogComponent } from "./community/confirmation-dialog/confirmation-dialog.component";
import { SeoService } from "./services/seo/seo.service";
import { CommunityAdministratorTabComponent } from "./community/community-administrator/community-administrator-tab/community-administrator-tab.component";
import { ChatGroupInfoComponent } from "./header/chat/chat-group-info/chat-group-info.component";
import { BottomSheetEditUserGroupComponent } from "./header/chat/bottom-sheet-edit-user-group/bottom-sheet-edit-user-group.component";
import { BottomSheetNewChatContactComponent } from "./header/chat/bottom-sheet-new-chat-contact/bottom-sheet-new-chat-contact.component";
import { FilterPipe } from "./header/chat/filter.pipe";
import { BottomSheetAddUserToGroupComponent } from "./header/chat/bottom-sheet-add-user-to-group/bottom-sheet-add-user-to-group.component";
import { FilterUsersPipe } from "./generic/components-custom/agenda-custom/filter-users.pipe";
import { AgendaSettingsComponent } from "./generic/components-custom/agenda-custom/subcomponents/agenda-settings/agenda-settings.component";
import { MarketplaceGeneralComponent } from "./places/marketplace-stepper/marketplace-general/marketplace-general.component";
import { GenericComponent } from "./generic/generic.component";
import { ButtonComponent } from "./generic/components/button/button.component";
import { BottomsheetMenuComponent } from "./generic/components/button/bottomsheet-menu/bottomsheet-menu.component";
import { GraphsComponent } from "./generic/components/graphs/graphs.component";
import { FormComponent } from "./generic/components/form/form.component";
import { CheckboxComponent } from "./generic/components/checkbox/checkbox.component";
import { MenuComponent } from "./generic/components/menu/menu.component";
import { InputComponent } from "./generic/components/input/input.component";
import { SignComponent } from "./generic/components/sign/sign.component";
import { TabComponent } from "./generic/components/tab/tab.component";
import { TableComponent } from "./generic/components/table/table.component";
import { BottomSheetFilterTableComponent } from "./generic/components/table/bottom-sheet-filter-table/bottom-sheet-filter-table.component";
import { ChipAutocompleteComponent } from "./generic/components/table/bottom-sheet-filter-table/chip-autocomplete/chip-autocomplete.component";
import { DateRangePickerComponent } from "./generic/components/table/bottom-sheet-filter-table/date-range-picker/date-range-picker.component";
import { SelectionComponent } from "./generic/components/selection/selection.component";
import { ExpansionComponent } from "./generic/components/expansion/expansion.component";
import { StepperComponent } from "./generic/components/stepper/stepper.component";
import { CardComponent } from "./generic/components/card/card.component";
import { ListComponent } from "./generic/components/list/list.component";
import { BottomSheetComponent } from "./generic/components/bottom-sheet/bottom-sheet.component";
import { TextComponent } from "./generic/components/text/text.component";
import { TrafficLightComponent } from "./generic/components/traffic-light/traffic-light.component";
import { YoutubeComponent } from "./generic/components/youtube/youtube.component";
import { SearchPortalsComponent } from "./search-portals/search-portals.component";
import { PortalWarningDialogComponent } from "./search-portals/subcomponents/portal-warning-dialog/portal-warning-dialog.component";
import { BajaDialogComponent } from "./marketplace/baja-dialog/baja-dialog.component";
import { ShoppingCartComponent } from "./places/shopping-cart/shopping-cart.component";
import { DemoDialogComponent } from "./demo-dialog/demo-dialog.component";
import { NgxFreshChatModule } from "ngx-freshchat";

import { LoginScreenComponent } from "./login/login-screen/login-screen.component";
import { ShoppingCartDeleteDialogComponent } from "./places/shopping-cart/shopping-cart-delete-dialog/shopping-cart-delete-dialog.component";
import { GenericContainerComponent } from "./generic-container/generic-container.component";
import { DialogComponent } from "./generic/components/dialog/dialog.component";
import { BarRatingModule } from "ngx-bar-rating";
import { TerminosLegalesComponent } from "./web/subcomponents/web-dialog/terminos-legales/terminos-legales.component";
import { CookiesDialogComponent } from "./web/subcomponents/web-dialog/cookies-dialog/cookies-dialog.component";
import { DividerComponent } from "./generic/components/divider/divider.component";
import { BoxComponent } from "./generic/components/box/box.component";
import { SidenavComponent } from "./generic/components/sidenav/sidenav.component";
import { DialogUserProfileComponent } from "./generic/components-custom/agenda-custom/subcomponents/notifications/dialog-user-profile/dialog-user-profile.component";
import { PostponeEvent } from "./generic/components-custom/agenda-custom/subcomponents/postpone-event/postpone-event";
import { MotivoCancelacion } from "./generic/components-custom/agenda-custom/subcomponents/motivo-cancelacion/motivo-cancelacion";
import { BottomSheetFinishedEvents } from "./generic/components-custom/agenda-custom/subcomponents/bottom-sheet-finished-events/bottom-sheet-finished-events";
import { CreateGroupDialog } from "./generic/components-custom/agenda-custom/subcomponents/bottom-sheet-shared-calendar/subcomponents/create-group-dialog/create-group-dialog";
import { BottomSheetSharedCalendar } from "./generic/components-custom/agenda-custom/subcomponents/bottom-sheet-shared-calendar/bottom-sheet-shared-calendar";
import { NotificacionSuperiorComponent } from "./notificacion-superior/notificacion-superior.component";
import { BottomSheetActions } from "./property/bottom-sheet-actions/bottom-sheet-actions";
import { BottomSheetDuplicate } from "./property/Bottom-sheet-duplicate/bottom-sheet-duplicate";
import { ToolsCustomComponent } from "./generic/components-custom/tools-custom/tools-custom.component";

import { Dispatcher } from "./services/dispatcher";
import { PushNotificationsService } from "./services/pushNotifications.service";

import { ManageCategoriesDialog } from "./generic/components-custom/agenda-custom/subcomponents/manage-categories-dialog/manage-categories-dialog";
import { ColorPickerDialog } from "./activities/subcomponents/color-picker-dialog/color-picker-dialog";
import { AllEventsListDialog } from "./generic/components-custom/agenda-custom/subcomponents/all-events-list-dialog/all-events-list-dialog";
import { DeviceDetectorModule } from "ngx-device-detector";
import { FlatImagesCustomComponent } from "./generic/components-custom/flat-images-custom/flat-images-custom.component";
import { EventsConditionsCustomComponent } from "./generic/components-custom/events-conditions-custom/events-conditions-custom.component";
import { EventsConditionsCustomLoopComponent } from "./generic/components-custom/events-conditions-custom/events-conditions-custom-loop/events-conditions-custom-loop.component";
import { EventsConditionsCustomElementComponent } from "./generic/components-custom/events-conditions-custom/events-conditions-custom-element/events-conditions-custom-element.component";
import { EventsTemplateCustomComponent } from "./generic/components-custom/events-template-custom/events-template-custom.component";
import { EventsTemplateCustomLoopComponent } from "./generic/components-custom/events-template-custom/events-template-custom-loop/events-template-custom-loop.component";
import { EventsTemplateCustomElementComponent } from "./generic/components-custom/events-template-custom/events-template-custom-element/events-template-custom-element.component";
import { MessagesTemplateCustomComponent } from "./generic/components-custom/messages-template-custom/messages-template-custom.component";
import { NotificationsCustomComponent } from "./generic/components-custom/notifications-custom/notifications-custom.component";
import { SocialMediaCustomComponent } from "./generic/components-custom/rrss-home-custom/rrss-home-custom.component";
import { BuyMovinsCustomComponent } from "./generic/components-custom/buy-movins-custom/buy-movins-custom.component";
import { FileUploadComponent } from "./generic/components/file-upload/file-upload.component";
import { DragAndDropDirective } from "./generic/components/file-upload/drag-and-drop.directive";
import { LocationComponent } from "./generic/components/location/location.component";
import { CanvaComponent } from "./generic/components/canva/canva.component";
import { CarouselGenericComponent } from "./generic/components/carousel-generic/carousel-generic.component";
import { ChooseAccountCalendarComponent } from "./generic/components-custom/agenda-custom/subcomponents/choose-account-calendar/choose-account-calendar.component";
import { TwoDecimalDirective } from "./validators/two-decimal.directive";
import { SafeHtmlPipe } from "./safe-html.pipe";
import { LandingSocialMediaComponent } from "./generic/components-custom/social-media-custom/landing-social-media/landing-social-media.component";
import * as CanvasJSAngularChart from "../assets/canvasjs.angular.component";
import { GoogleMapsComponent } from "./generic/components/google-maps/google-maps.component";
import { ChipCreatorCustomComponent } from "./generic/components-custom/chip-creator-custom/chip-creator-custom.component";
import { GenericCreatorCustomComponent } from './generic/components-custom/generic-creator-custom/generic-creator-custom.component';
import { AddGenericElementDialogComponent } from './generic/components-custom/generic-creator-custom/add-generic-element-dialog/add-generic-element-dialog.component';
import { GenericCustomCreatorOptionsComponent } from './generic/components-custom/generic-creator-custom/generic-custom-creator-options/generic-custom-creator-options.component';
import { GenericCustomCreatorGeneraldetailsComponent } from './generic/components-custom/generic-creator-custom/generic-custom-creator-generaldetails/generic-custom-creator-generaldetails.component';
import { JourneyCreatorCustomComponent } from './generic/components-custom/journey-creator-custom/journey-creator-custom.component';
import { AddJourneyElementDialogComponent } from './generic/components-custom/journey-creator-custom/add-journey-element-dialog/add-journey-element-dialog.component';
import { NodeService } from "./services/generic/node.service";
import { NodesContainerComponent } from "./generic/components-custom/nodes-container/nodes-container.component";
import { NodeComponent } from "./generic/components-custom/node/node.component";
import { RestaurantPedidoActualCartaCustomComponent } from "./generic/components-custom/restaurant-pedido-actual-carta-custom/restaurant-pedido-actual-carta-custom.component";
import { RestaurantPedidosMesasCustomComponent } from "./generic/components-custom/restaurant-pedidos-mesas-custom/restaurant-pedidos-mesas-custom.component";
import { RedsysCustomComponent } from "./generic/components-custom/redsys-custom/redsys-custom.component"
import { PaycometCustomComponent } from "./generic/components-custom/paycomet-custom/paycomet-custom.component";
import { PaypalCustomComponent } from "./generic/components-custom/paypal-custom/paypal-custom.component";
import { StripeCustomComponent } from "./generic/components-custom/stripe-custom/stripe-custom.component";
import { TableGenericCreatorComponent } from "./generic/components/table-generic/table-generic-creator.component";
import { NgxEmojiPickerModule } from 'ngx-emoji-picker';
import { NgxPayPalModule } from 'ngx-paypal';
import { CalendarComponent } from "./generic/components/calendar/calendar.component";
import { CdkStepperModule } from "@angular/cdk/stepper";

import { NgxGraphModule } from '@swimlane/ngx-graph';


const config: SocketIoConfig = {
  url: "wss://server.4dmovin.com",
  options: {
    reconnectionDelay: 1000,
    reconnection: true,
    reconnectionAttempts: 2,
    transports: ["websocket"],
    // default is ['polling', 'websocket']
    // rejectUnauthorized: false,
    // enabledTransports: ['ws', 'wss']
  },
};

const DragConfig = {
  zIndex: 999999999999,
};

var CanvasJSChart = CanvasJSAngularChart.CanvasJSChart;

registerLocaleData(localeEs);

const appRoutes: Routes = [
  /* PATHS NO GENERIC */
  { path: "joinGroup/:id", component: AppComponent },

  { path: "**", component: GenericContainerComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DbSchemaCustomComponent,
    SwapAccountComponent,
    LoginComponent,
    CheckSpecialCharacterDirective,
    CheckNumberDirective,
    MatchPasswordDirective,
    CheckLowerCaseDirective,
    CheckUpperCaseDirective,
    TwoDecimalDirective,
    ClickStopPropagation,

    // Utils
    TwoOptionsModalComponent,
    ImageSelectorModalComponent,
    TargetListManagerComponent,
    SortableColumnComponent,
    UploadFileModalComponent,
    SortableTableDirective,
    ToolsCustomComponent,
    DeleteConfirmDialog,
    ManageCategoriesDialog,
    ColorPickerDialog,
    AllEventsListDialog,
    MessageDialogComponent,
    ResetPasswordComponent,
    SetPasswordComponent,
    DisableControlDirective,
    BottomSheetDuplicate,
    BottomSheetTrackingLink,
    BottomSheetUploadImage,
    BottomSheetImageSelector,
    BottomSheetUploadFile,
    DialogUserProfileComponent,
    BottomSheetFinishedEvents,
    PrivacyDialog,
    PostponeEvent,
    BottomSheetSharedCalendar,
    ChooseAccountCalendarComponent,
    BottomSheetActions,
    BottomsheetMenuComponent,
    CreateGroupDialog,
    MotivoCancelacion,
    NotificationsComponent,
    SafePipe,
    DynamicReportComponent,
    DynamicReportDetailsComponent,
    BillComponent,
    AddMovinCoinComponent,
    IconScreenComponent,
    AutoPlantillaComponent,
    AutoAdviceHomeComponent,
    AutoAdviceSuperiorComponent,
    AutoBottomSheetComponent,
    AutoPopupComponent,
    AutoSnackbarComponent,
    StripePaymentComponent,
    SafeStylePipe, AutoComponentsComponent,
    MarketplaceComponent, MarketplaceCardComponent,
    NotificacionSuperiorComponent,
    ConfigDialogComponent,
    ScoresComponent,
    KnowledgeComponent,
    CommunityComponent,
    KnowledgeVideoComponent,
    DemoNumber,
    AutoSidenavComponent,
    KnowledgePlaceComponent,
    ContainerComponent,
    CarouselComponent,
    ConfigurationComponent,
    DependenciesComponent,
    CheckoutComponent,
    BuyMovinsComponent,
    GeneralComponent,
    MovinsHistoryComponent,
    ModuleConfigComponent,
    ChatComponent,
    PerfilInmoComponent,
    InmoCardComponent,
    CommunityAdministratorComponent,
    SwapAccountComponent,
    SelectGroupUsersComponent,
    ConfirmationDialogComponent,
    CommunityAdministratorTabComponent,
    ChatGroupInfoComponent,
    BottomSheetEditUserGroupComponent,
    BottomSheetNewChatContactComponent,
    FilterPipe,
    BottomSheetAddUserToGroupComponent,
    FilterUsersPipe,
    AgendaSettingsComponent,
    MarketplaceGeneralComponent,
    SearchPortalsComponent,
    PortalWarningDialogComponent,
    InputDialogComponent,
    EgoComponent,
    JourneyEgoComponent,
    CampanyaEgoComponent,
    JourneyEgoPopComponent,
    CampanyaEgoPopComponent,
    BajaDialogComponent,
    ShoppingCartComponent,
    DemoDialogComponent,
    ShoppingCartComponent,
    GenericComponent,
    ButtonComponent,
    CanvasJSChart,
    GraphsComponent,
    FormComponent,
    CheckboxComponent,
    MenuComponent,
    InputComponent,
    SignComponent,
    FileUploadComponent,
    DragAndDropDirective,
    LocationComponent,
    TabComponent,
    TableComponent,
    BottomSheetFilterTableComponent,
    ChipAutocompleteComponent,
    DateRangePickerComponent,
    SelectionComponent,
    ExpansionComponent,
    StepperComponent,
    CardComponent,
    CarouselGenericComponent,
    ListComponent,
    TextComponent,
    TrafficLightComponent,
    BottomSheetComponent,
    YoutubeComponent,
    PortalWarningDialogComponent,
    DemoDialogComponent,
    LoginScreenComponent,
    ShoppingCartDeleteDialogComponent,
    GenericContainerComponent,
    GenericContainerComponent,
    TerminosLegalesComponent,
    CookiesDialogComponent,
    DialogComponent,
    DividerComponent,
    BoxComponent,
    SidenavComponent,
    ColorPickerDialog,
    ManageCategoriesDialog,
    AllEventsListDialog,
    ContainerDeferComponent,
    FlatImagesCustomComponent,
    EventsConditionsCustomComponent,
    EventsConditionsCustomLoopComponent,
    EventsConditionsCustomElementComponent,
    EventsTemplateCustomComponent,
    EventsTemplateCustomLoopComponent,
    EventsTemplateCustomElementComponent,
    MessagesTemplateCustomComponent,
    GenericCreatorCustomComponent,
    AddGenericElementDialogComponent,
    GenericCustomCreatorOptionsComponent,
    GenericCustomCreatorGeneraldetailsComponent, NotificationsCustomComponent,
    SocialMediaCustomComponent,
    BuyMovinsCustomComponent,
    CanvaComponent,
    CarouselGenericComponent,
    ChooseAccountCalendarComponent,
    SafeHtmlPipe,
    LandingSocialMediaComponent,
    GoogleMapsComponent,
    ChipCreatorCustomComponent,
    JourneyCreatorCustomComponent,
    AddJourneyElementDialogComponent,
    NodesContainerComponent,
    TableGenericCreatorComponent,
    NodeComponent,
    RestaurantPedidoActualCartaCustomComponent,
    RestaurantPedidosMesasCustomComponent,
    RedsysCustomComponent,
    PaycometCustomComponent,
    PaypalCustomComponent,
    StripeCustomComponent,
    CalendarComponent
  ],

  imports: [
    //[StripeModule.forRoot("pk_test_51NBDXeF0nPp3VXRASsgNRGwmoi6AUodzj5TSnmP21y5rXr8oXSx9LqWw7y4Ex9K5bEXhUIcb4eAMffhZZPKCfY9500urthblRr")], // Daniel
    BarRatingModule,
    CdkStepperModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: "never" }),
    MatSnackBarModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    CommonModule,
    NgbModalModule,
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "legacy",
    }),
    MultiselectDropdownModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    ImageUploadModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    NgxFreshChatModule,
    NgbModule.forRoot(),
    AngularEditorModule,
    MatButtonToggleModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDDD_E7LB2dKMu2qJniasF0fMatAyfmaXE",
    }),
    FlatpickrModule.forRoot(),
    GooglePlaceModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),

    DragAndDropModule,
    NgbPopoverModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatDialogModule,
    MatGridListModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatIconModule,
    MatExpansionModule,
    MatStepperModule,
    MatMenuModule,
    MatChipsModule,
    MatTooltipModule,
    MatListModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatRadioModule,
    MatTabsModule,
    MatTableModule,
    MatTableExporterModule,
    MatSortModule,
    MatCardModule,
    MatProgressSpinnerModule,
    DeviceDetectorModule.forRoot(),
    MatBottomSheetModule,
    NgDompurifyModule,
    MatProgressBarModule,
    MatToolbarModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxMaterialTimepickerModule,
    MatGoogleMapsAutocompleteModule,
    AgmCoreModule.forRoot(),
    FlexLayoutModule,
    DragDropModule,
    TextFieldModule,
    NgxEmojiPickerModule,
    NgxPayPalModule,
    NgxGraphModule,

    // Joan: error en la versió 14 - Ho comento perquè sembla que no es fa servir
    //MatVideoModule,
    SocketIoModule.forRoot(config),
    ClipboardModule,
    CarouselModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    YouTubePlayerModule,
    ScrollingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],

  providers: [
    Dispatcher,
    AngularEditorService,
    PushNotificationsService,
    EndpointService,
    AuthService,
    RedirectionService,
    ActivitiesService,
    PrivilegiesControlService,
    ImageUploaderService,
    PredefinedValuesLoaderService,
    TableSortService,
    BottomSheetFilterTableComponent,
    ChipAutocompleteComponent,
    DateRangePickerComponent,
    SortService,
    LoadExternValuesService,
    DatePipe,
    SideNavService,
    LocalStorageService,
    AdviceReportService,
    SeoService,
    FilterUsersPipe,
    DialogComponent,
    BottomSheetComponent,
    MarketplaceGeneralComponent,
    { provide: CDK_DRAG_CONFIG, useValue: DragConfig },
    { provide: MAT_DATE_LOCALE, useValue: "es-ES" },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    NodeService,
    CalendarComponent
  ],

  exports: [
    TranslateModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DialogComponent,
    ToolsCustomComponent,
    CdkStepperModule,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
