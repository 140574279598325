import {Observable, of} from "rxjs";

export class BodyTime{

  public hours : Array<string>;
  public minuts : Array<string>;
  public tiempo : {idTime: number, time: string} [] = new Array();

  public ini1;
  public fin1;

  public ini2;
  public fin2;

  constructor() {
    this.clearBodyInmmo();
  }

  public clearBodyInmmo() {

    this.hours = ['08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22'];
    this.minuts = ['00', '30'];

    let x = 0;

    for(let i = 0; i < this.hours.length ; i++){

      if(i != this.hours.length-1) {
        for (let j = 0; j < this.minuts.length; j++) {
          this.tiempo.push({idTime: x, time: this.hours[i] + ":" + this.minuts[j]});
          x++;
        }
      }else{
        this.tiempo.push({idTime: x, time: this.hours[i] + ":" + this.minuts[0]});
      }
    }

    this.ini1 = this.tiempo[0];
    this.ini2 = this.tiempo[0];
    this.fin1 = this.tiempo[1];
    this.fin2 = this.tiempo[1];

  }

  public buscaHora(startp: any, endp: any, starts: any, ends: any): Observable<BodyTime>{

    const initDateSP = startp.split(':');
    startp = initDateSP[0] + ":" + initDateSP[1];

    const initDateEP = endp.split(':');
    endp = initDateEP[0] + ":" + initDateEP[1];

    const initDateSS = starts.split(':');
    starts = initDateSS[0] + ":" + initDateSS[1];

    const initDateES = ends.split(':');
    ends = initDateES[0] + ":" + initDateES[1];

    //console.log(startp);
    //console.log(endp);
    //console.log(starts);
    //console.log(ends);

    let trobat1  = false;
    let trobat2 = false;
    let trobat3 = false;
    let trobat4 = false;
    let x = 0;
    let y = 0;
    let z = 0;
    let t = 0;

    while (x < this.tiempo.length && !trobat1){

      if(startp === this.tiempo[x]['time']){
        trobat1 = true;
        this.ini1 = this.tiempo[x];
        while (y < this.tiempo.length && !trobat2){

          if(endp === this.tiempo[y]['time']){
            trobat2 = true;
            this.fin1 = this.tiempo[y];
          }else y++;

        }
      }else x++;

    }

    while (z < this.tiempo.length && !trobat3){

      if(starts === this.tiempo[z]['time']){
        trobat3 = true;
        this.ini2 = this.tiempo[z];

        while (t < this.tiempo.length && !trobat4){

          if(ends === this.tiempo[t]['time']){
            trobat4 = true;
            this.fin2 = this.tiempo[t];
          }else t++;

        }

      }else z++;

    }

    return of(this);
  }

}
