import { isPlatformBrowser, Location } from '@angular/common';
import { AfterViewInit, Component, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from "@angular/material/sidenav";
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { TranslateService } from "@ngx-translate/core";
import { DeviceDetectorService } from "ngx-device-detector";
import { NgxFreshChatService } from "ngx-freshchat";
import { Subscription } from "rxjs";
import { Strings } from '../strings/strings';
import { Values } from '../values/values';
import { AutoPlantillaComponent } from "./auto-plantilla/auto-plantilla.component";
import { SocialMediaCustomComponent } from './generic/components-custom/rrss-home-custom/rrss-home-custom.component';
import { GenericComponent } from "./generic/generic.component";
import { LoginScreenComponent } from "./login/login-screen/login-screen.component";
import { LoginComponent } from "./login/login.component";
import { NotificacionSuperiorComponent } from "./notificacion-superior/notificacion-superior.component";
import { AdviceReportService } from "./services/advice-report/advice-report.service";
import { ApiChatService } from './services/api-chat/api-chat.service';
import { AuthService } from './services/auth/auth.service';
import { ChatService } from "./services/chat.service";
import { NotificationsCustomComponent } from "./generic/components-custom/notifications-custom/notifications-custom.component";
import { FunctionsService } from './services/functions/functions.service';
import { GenericCreatorService } from "./services/generic/custom/generic-creator.service";
import { GenericService } from "./services/generic/generic.service";
import { EndpointService } from './services/endpoint/endpoint.service';
//import { PrivilegiesControlService } from './services/privilegies-control/privilegies-control.service';
import { PushNotificationsService } from './services/pushNotifications.service';
import { RedirectionService } from './services/redirection/redirection.service';
import { RoutingService } from './services/routing/routing.service';
//import { StaticTablesService } from './services/static-tables.service';
import { SideNavService } from './utils/SideNavService';
//import { param } from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {

  readonly VAPID_PUBLIC_KEY = "BIwKVBxu5QfUclJbgJTmWnqp8RagohmcKYZfONJb5r2tuHWgccTHsnWNk53S8h5iEqC86PcL_rpXjdGVKIfuTwE";
  public Version = Values.Version;
  public strings = Strings.APP_C_STRINGS;
  public routes = Values.ROUTES;
  //loaded: Observable<any>;

  public headerStructure = null;
  public structure = null;
  public clearAllActivated = {};

  //Suscripciones al observable emmitter para captar los clicks de los botones de generic (pone que no se usan pero sí)
  private openButtonAutoPlantilla: Subscription = null;
  public notificacionSuperiorNotLoaded = false;

  public freshchat: any;

  private toolsCache = {};
  public showInstallAppIos = false;

  public searchResults = [];
  public isSearcing = false;

  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('LoginScreenComponent') public LoginScreenComponent: LoginScreenComponent;
  @ViewChild('LoginComponent') public LoginComponent: LoginComponent;
  @ViewChild('NotificacionSuperiorComponent') public NotificacionSuperiorComponent: NotificacionSuperiorComponent;
  @ViewChild('AutoPlantillaComponent') public AutoPlantillaComponent: AutoPlantillaComponent;
  @ViewChild('generic') public generic: GenericComponent;
  @ViewChild('SocialMediaCustomComponent') public SociaSocialMediaCustomComponent: SocialMediaCustomComponent;
  @ViewChild('warningDialog') warningDialog: any;

  @ViewChild('notisCustom2') public notificationsCustom2: NotificationsCustomComponent;

  private nots: any[];
  public prueba: any;
  value = "";
  lastValue = "";

  public infoFADevWindowValues = false;
  public infoFADevParams = false;

  @HostListener('window:beforeunload', ['$event'])
  async unloadNotification($event: any) {
    if (this.genericService.getNumChanges() > 0) {
      $event.returnValue = 'Parece que hay cambios sin guardar. ¿Quieres continuar con esta acción?';
    }
  }

  constructor(
    private adviceReportService: AdviceReportService,
    public sideNavService: SideNavService,
    public authService: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    private redirectionService: RedirectionService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    //public staticTablesService: StaticTablesService,
    public deviceService: DeviceDetectorService,
    public chat: NgxFreshChatService,
    public translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId,
    public genericService: GenericService,
    public genericCreatorService: GenericCreatorService,
    public chatService: ChatService,
    private apiChatService: ApiChatService,
    public routingService: RoutingService,
    private swPush: SwPush,
    private pushNotificationsService: PushNotificationsService,
    private functionsService: FunctionsService,
    private location: Location,
    private endpointService: EndpointService
  ) {
    this.routingService.shouldOpenHelp = false;
    this.routingService.shouldOpenChat = false;
    this.routingService.shouldOpenExternalWindow = false;
    this.routingService.shouldOpenSearch = false;

    /*router.events.filter(event => event instanceof NavigationStart)
      .subscribe((event:NavigationStart) => {
        // You only receive NavigationStart events
      });*/

    // Idiomas para traducir
    translate.addLangs(['es', 'en', 'ca']);
    // Usar idioma del navegador
    translate.use(this.translate.getBrowserLang());
    // Traducción de backup
    translate.setDefaultLang('es');
  }

  ngOnInit() {
    this.genericService.getCompanyColors();
    this.genericService.staticHTML = window.location.href.includes('sth');
    this.route.paramMap.subscribe(params => {
      const id = params['id'];
      if (this.router.url.indexOf('/joinGroup') > -1) {
        if (this.authService.isUserSessionActive()) {
          this.apiChatService.joinGroupByInvitationLink(id, this.authService.user_token).subscribe(data => {
            /* COMENTADO FALLA --> SOUFIANE
            if(data.error == -1){
              this.snackBar.open('¡Vaya, parece que este grupo no existe!', 'X', {
                duration: 6000,
                panelClass: ['lightred-snackbar']
              });

            } else if(data.error == -2){
              this.snackBar.open('¡Vaya, parece que este enlace ha expirado!', 'X', {
                duration: 6000,
                panelClass: ['lightred-snackbar']
              });
            } else{
              this.snackBar.open('TODO OK!', 'X', {
                duration: 6000,
                panelClass: ['lightgreen-snackbar']
              });

              this.name = {key: data._id, groupType: data.groupType, isPublic: data.isPublic};
            }
            */

            this.routingService.shouldOpenChat = true;
            this.sidenav.toggle();
            //this.router.navigateByUrl('/');

          });
        } else {
          this.snackBar.open('¡Vaya, parece que necesitas iniciar sesión!', 'X', {
            duration: 6000,
            panelClass: ['lightred-snackbar']
          });
          this.redirectionService.redirectionJoinGroup = true;
          this.redirectionService.redirectionURL = 'joinGroup/' + id; // TODO: SOUFIANE: Cambiar URL
          this.router.navigate([Values.ROUTES.LOGIN]);
        }
      } else {
        this.redirectionService.redirectionURL = this.router.url;
      }
    });

    if (!this.genericService.staticHTML) {
      // Checks if should display install popup notification:
      if (this.isIos() && this.isRunningStandalone()) {
        this.showInstallAppIos = true;
      }

      this.openButtonAutoPlantilla = this.routingService.openButton$.subscribe(() => {
        this.openButtonAutoPlantillaCaller(1);
      });

      if (this.authService.isUserSessionActive()) {
        this.chatService.getNonReadMessagesCount();
        this.swPush.requestSubscription({ serverPublicKey: this.VAPID_PUBLIC_KEY })
          .then(sub => this.pushNotificationsService.addPushSubscriber(this.authService.userId, sub).subscribe(data => {
          }))
          .catch(err => console.error("Could not subscribe to notifications", err));
      } else {
        //'NO ESTAS LOGEADO NO PUEDES WEBPUSH'
      }

      // this.router.onSameUrlNavigation = 'reload';
      this.router.onSameUrlNavigation = 'ignore';
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;

      // Notis Superior
      this.adviceReportService.open.subscribe(i => {
        this.openOtherPlantilla(i.id, i.button);
      });

      this.functionsService.openNoti.subscribe(i => {
        this.openOtherPlantilla(i.id, i.button);
      });

      this.adviceReportService.superior.subscribe(i => {
        this.previewPlantillaSuperior();
      });

      this.adviceReportService.openSuperior.subscribe(i => {
        this.openOtherPlantillaSuperior(i.id, i.noti);
      });

      this.adviceReportService.notiEGO.subscribe(i => {
        this.previewPlantilla();
      });

      if (isPlatformBrowser(this.platformId)) {
        this.checkWebp().then((res) => {
          this.genericService.webpSupported = res;
        });
      }

      this.authService.getTokenFromLS();

      if (this.authService.isUserSessionActive()) {
        this.genericService.getUserInfo(0);
        // this.privilegiesControlService.getUserPermissionsAndNavigate(this.redirectionService.redirectionURL); //TODO JOAN: Crec que ja no cal
      } else {
        this.genericService.loadHeaderStructure();
        this.genericService.loadFooterStructure();
      }
    } else {
      if (window.self !== window.top) {
        // La página está incrustada en un iframe
        // Aplica una clase CSS a tu elemento
        var element = document.getElementById("body");
        element.classList.add("body-in-iframe");
      }
    }


    let prodCounter = localStorage.getItem("productsCounter");
    let prodPriceCounter = localStorage.getItem("productsPriceCounter");
    let prodPriceCounterLabel = localStorage.getItem("productsPriceCounterLabel");
    let productos = localStorage.getItem("arrayProductos");

    if (typeof prodCounter !== "undefined" && prodCounter !== null) {
      this.authService.productsCounter = JSON.parse(prodCounter);
    }
    if (typeof prodPriceCounter !== "undefined" && prodPriceCounter !== null) {
      this.authService.productsPriceCounter = JSON.parse(prodPriceCounter);
    }
    if (typeof prodPriceCounterLabel !== "undefined" && prodPriceCounterLabel !== null) {
      this.authService.productsPriceCounterLabel = JSON.parse(prodPriceCounterLabel);
    }
    if (typeof productos !== "undefined" && productos !== null) {
      this.authService.arrayProductos = JSON.parse(productos);
    }

    let numPersonas = localStorage.getItem("numPersonas");
    if (typeof numPersonas !== "undefined" && numPersonas !== null) {
      this.authService.numPersonas = JSON.parse(numPersonas);
    }
    let numAdultos = localStorage.getItem("numAdultos");
    if (typeof numAdultos !== "undefined" && numAdultos !== null) {
      this.authService.numAdultos = JSON.parse(numAdultos);
    }
    let numNinos = localStorage.getItem("numNinos");
    if (typeof numNinos !== "undefined" && numNinos !== null) {
      this.authService.numNinos = JSON.parse(numNinos);
    }
    let numBebes = localStorage.getItem("numBebes");
    if (typeof numBebes !== "undefined" && numBebes !== null) {
      this.authService.numBebes = JSON.parse(numBebes);
    }
    let daysCounter = localStorage.getItem("daysCounter");
    if (typeof daysCounter !== "undefined" && daysCounter !== null) {
      this.authService.daysCounter = JSON.parse(daysCounter);
    }


  }

  ngAfterViewInit(): void {
    if (!this.genericService.staticHTML) {
      this.adviceReportService.loadNotis();
      this.genericService.warningDialog = this.warningDialog;
      this.sideNavService.setSidenav(this.sidenav, 0);
      if (this.NotificacionSuperiorComponent !== null && this.NotificacionSuperiorComponent !== undefined) {
        this.NotificacionSuperiorComponent.reset();
      }

      //this.previewPlantilla();
      //this.previewPlantillaSuperior();
      //this.adviceReportService.refresh = false;

      this.freshchat = document.querySelectorAll('.d-hotline.h-btn');
    }

    if (document.getElementById('notificacion-superior-class')) {
      this.notificacionSuperiorNotLoaded = true;
    }
  }
  
  onOpenSidebar(open) {
    if (open == 'chat') {
      this.routingService.shouldOpenChat = !this.routingService.shouldOpenChat;
      this.routingService.shouldOpenHelp = false;
      this.routingService.shouldOpenExternalWindow = false;
      this.routingService.shouldOpenSearch = false;
    } else if (open == 'help') {
      this.routingService.shouldOpenHelp = !this.routingService.shouldOpenHelp;
      this.routingService.shouldOpenChat = false;
      this.routingService.shouldOpenExternalWindow = false;
      this.routingService.shouldOpenSearch = false;
    } else if ('search') {
      this.routingService.shouldOpenSearch = !this.routingService.shouldOpenSearch;
      this.routingService.shouldOpenChat = false;
      this.routingService.shouldOpenHelp = false;
      this.routingService.shouldOpenExternalWindow = false;
    } else {
      this.routingService.shouldOpenExternalWindow = !this.routingService.shouldOpenExternalWindow;
      this.routingService.shouldOpenChat = false;
      this.routingService.shouldOpenHelp = false;
      this.routingService.shouldOpenSearch = false;
    }

    if (!this.sidenav.opened && (this.routingService.shouldOpenChat || this.routingService.shouldOpenHelp || this.routingService.shouldOpenExternalWindow || this.routingService.shouldOpenSearch)) this.sidenav.toggle();
    else if (this.sidenav.opened && this.routingService.shouldOpenChat == false && this.routingService.shouldOpenHelp == false && this.routingService.shouldOpenExternalWindow == false && this.routingService.shouldOpenSearch == false) this.sidenav.toggle();

    if (this.routingService.shouldOpenChat || this.routingService.shouldOpenHelp || this.routingService.shouldOpenExternalWindow || this.routingService.shouldOpenSearch) {
      this.chat.destroy();
    }
  }

  public closeSidenav() {
    if (this.genericService.pendingRevertPrevExternalWindow) {
      if (this.genericService.getNumChanges() > 0) {
        this.closeSidenavChanges();
      } else {
        this.closeSidenavExternalWindow();
      }
    } else {
      this.closeSidenavExternalWindow();
    }
  }

  public async closeSidenavChanges() {
    const status = await this.genericService.openWarningDialog(1, 1);
    if (status) {
      this.closeSidenavExternalWindow();
    }
  }

  closeSidenavExternalWindow() {
    //this.genericService.initFreshChat();
    if (this.genericService.pendingRevertPrevExternalWindow) {
      this.genericService.pendingRevertPrevExternalWindow = false;
      this.authService.externalWindowStructure = null;
      let currentInitialAreaExternal = this.genericService.cloneVariable(this.genericService.currentInitialArea);
      this.genericService.formsChanged[currentInitialAreaExternal][this.genericService.paramControlVariables[currentInitialAreaExternal]['indexParam']] = new Map();
      this.genericService.elementsThatAffectOthers = this.routingService.elementsThatAffectOthersPrevExternalWindow;
      this.genericService.affectedElements = this.routingService.affectedElementsPrevExternalWindow;
      this.genericService.selectOptions = this.routingService.selectOptionsPrevExternalWindow;
      this.genericService.tableData = this.routingService.tableDataPrevExternalWindow;
      this.genericService.currentInitialArea = this.routingService.currentInitialAreaPrevExternalWindow;
      this.genericService.readMode = this.routingService.readModePrevExternalWindow;
      this.genericService.parentStructure = this.routingService.parentStructurePrevExternalWindow;
      //this.genericService.elementsArray = this.genericService.elementsArrayPrevExternalWindow;
      this.genericService.containerFunctionArea = this.genericService.containerFunctionAreaPrevExternalWindow;
      this.routingService.externalWindowStructure = [];
      this.genericService.firstLoad = true;
    }
    this.routingService.shouldOpenHelp = false;
    this.routingService.shouldOpenChat = false;
    this.routingService.shouldOpenExternalWindow = false;
    this.routingService.shouldOpenSearch = false;
    this.genericService.emptyStructuresArrays(1);
  }

  isRunningStandalone() {
    return (window.matchMedia('(display-mode: standalone)').matches);
  }

  isIos() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }

  @HostListener("scroll", ['$event'])
  onScroll($event: Event) {
    // @ts-ignore
    let scrollOffset = $event.srcElement.scrollTop;
    this.genericService.writeScrollPosition(scrollOffset);
  }

  private checkWebp(): Promise<boolean> {
    return new Promise(res => {
      const webP = new Image();
      webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
      webP.onload = webP.onerror = () => {
        res(webP.height === 2);
      };
    })
  }

  public previewPlantilla() {
    for (let i = 0; i < this.adviceReportService.notifications.length; i++) {
      this.AutoPlantillaComponent.openScreen(this.adviceReportService.notifications[i].id_plantilla_ego, this.adviceReportService.notifications[i].id_not);
    }
  }

  public previewPlantillaSuperior() {
    this.NotificacionSuperiorComponent.reset();
    for (let i = 0; i < this.adviceReportService.notificationSuperior.length; i++) {
      this.NotificacionSuperiorComponent.openSuperior(this.adviceReportService.notificationSuperior[i].id_plantilla_ego, this.adviceReportService.notificationSuperior[i].id_not);
    }
  }

  public openOtherPlantillaSuperior(id: number, noti: number) {
    this.NotificacionSuperiorComponent.openSuperior(id, noti);
  }

  public openOtherPlantilla(id: number, button?: boolean) {
    this.AutoPlantillaComponent.openScreen(id, null);
  }

  public openButtonAutoPlantillaCaller(id: number) {
    this.AutoPlantillaComponent.staticButton(id);
  }

  public getType(key) {
    const type = key.split('_')[0];
    if (type == 'password') return 'input'
    return type;
  }

  public getId(key) {
    const id = key.split('_')[1];
    return id;
  }

  public getInternalName(key) {
    if (this.genericService.currentInitialArea['key'] == key) {
      return this.genericService.currentInitialArea['value'];
    }
  }


  public getLabel(key) {
    // Cas de que es un canvi normal
    if (!this.toolsCache[key]) {
      const elem = this.genericService.findElementWithId(this.getId(key), false, false, true);
      if (elem) {
        this.toolsCache[key] = elem['label'];
        return elem['label'];
      }
    } else {
      return this.toolsCache[key];
    }
  }

  public async go(url) {
    if (this.genericService.getNumChanges() > 0) {
      const status = await this.genericService.openWarningDialog(1, 1);
      if (status) {
        this.genericService.formsChanged[this.genericService.currentInitialArea['key']][this.genericService.paramControlVariables[this.genericService.currentInitialArea['key']]['indexParam']] = new Map();
        this.genericService.go(url, true);
      }
    }
    else {
      this.genericService.go(url, true);
    }
  }

  public getHistory() {
    return this.genericService.history.slice(this.genericService.getCurrentIndex() - 10 >= 0 ? this.genericService.getCurrentIndex() - 10 : 0).reverse().slice();
  }

  public async revertFormValue(parent, input) {
    const id = this.getId(input['value']['name']);
    const elem = this.genericService.findElementWithId(id, false, false, true);
    if (elem) {
      const status = await this.genericService.openWarningDialog(2, 0);
      if (status) {
        elem['tmp_value'] = this.genericService.formsChanged[parent][this.genericService.paramControlVariables[parent]['indexParam']][input.value.name]['internal_value']['old'];
        if (elem['type'] == '5-stars' || elem['type'] == '10-stars' || elem['type'] == '3-faces' || elem['type'] == '5-faces') {
          elem['starsOnHoverValue'] = this.genericService.formsChanged[parent][this.genericService.paramControlVariables[parent]['indexParam']][input.value.name]['internal_value']['old'] - 1;
          if (elem['type'] == '5-stars' || elem['type'] == '10-stars') {
            for (let star in elem['starsArray']) {
              if (+star <= elem['starsOnHoverValue']) elem['starsArray'][star] = true;
              else elem['starsArray'][star] = false;
            }
          }
          else if (elem['type'] == '3-faces' || elem['type'] == '5-faces') {
            for (let star in elem['starsArray']) {
              if (+star == elem['starsOnHoverValue']) elem['starsArray'][star] = true;
              else elem['starsArray'][star] = false;
            }
          }
        }
        if (elem["form"]["controls"][elem['id_functional_area'] + '-' + elem["bd_field"]]) {
          elem["form"]["controls"][elem['id_functional_area'] + '-' + elem["bd_field"]].setValue(this.genericService.formsChanged[parent][this.genericService.paramControlVariables[parent]['indexParam']][input.value.name]['internal_value']['old']);
          this.genericService.deleteFormHistory(parent, input.value.name);
          if (elem['type'] == "slide" || elem['type'] == "checkbox") this.genericService.updateValueCheckBox(elem, 'child', elem['tmp_value']);
          if (elem['type'] == "password" && elem.hasTmpValuePw !== undefined) {
            elem['form_field'] = 0;
            elem.hasTmpValuePw = true;
          }
        }
      }
    }
  }

  public revertDuplicateFromValue(parent, input) {
    this.genericService.revertDuplicateFromValue(parent, input);
  }

  public navigateBreadcrumbs(b) {
    if (b['url'] != this.routingService.urlWithoutHashtag.split('/')[1]) this.go('/' + b['url'])
    else if (b != this.genericService.breadcrumbs[this.genericService.breadcrumbs.length - 1] && this.genericService.breadcrumbs[this.genericService.breadcrumbs.length - 2] && this.genericService.breadcrumbs[this.genericService.breadcrumbs.length - 2]['defer']) this.closeAllDefers(this.genericService.breadcrumbs[this.genericService.breadcrumbs.length - 2]['defer'])
    else this.functionsService.openSnackBar("Ya te encuentras en esta pantalla.", 1000)
  }

  public navigateHistory(h, i) {
    if (h['url'] != this.routingService.urlWithoutHashtag) {
      this.genericService.redirectByTools = true;
      this.genericService.updateHistoryIndex(-(i - (this.genericService.history.length - 1 - this.genericService.getCurrentIndex())))
      this.go(h['url'])
    }
    else this.functionsService.openSnackBar("Ya te encuentras en esta pantalla.", 1000)
  }

  public closeAllDefers(structure) {
    for (let i in structure) {
      if (structure[i]['id_functional_type'] == 106) structure[i]['id_functional_status_general'] = 2;
    }
    this.genericService.breadcrumbs.pop()
  }

  public getHeight() {
    let box = document.getElementsByClassName('notificacion-superior-class')[0].getBoundingClientRect();
    return box.height;
  }

  public downloadFunction() {
    let x = { id_function: 60 };
    this.functionsService.getFunction(x);
  }

  public printFunction() {
    let x = { id_function: 59 };
    this.functionsService.getFunction(x);
  }

  public showNewVersionBar() {
    // console.log(this.Version != this.genericService.applicationVersion, this.Version , this.genericService.applicationVersion, "VersionVersionVersion", this.genericService.finishedLoadStructure);
    if (this.Version != this.genericService.applicationVersion) return true;
    return false;
  }

  public previewModeGeneric() {
    this.genericService.checkPreviewModeGeneric();
  }

  public previewModeUserChange() {
    this.genericService.previewModeUser = !this.genericService.previewModeUser;
  }

  public closeConsoleLogFA() {
    this.routingService.showFAInfo = false;
    this.genericService.showAllInfo = false;
  }

  getObjectKeys(value: any): string[] {
    if (value === null || value === undefined) {
      return [];
    }
    return Object.keys(value);
  }

  isArray(value: any): boolean {
    return typeof value === 'object' && Array.isArray(value);
  }

  isObject(value: any): boolean {
    return typeof value === 'object' && !Array.isArray(value);
  }

  toggle(fa: any) {
    console.log(fa, this.isObject(fa), this.isArray(fa));
    if (fa && fa.expandedInfoCustom) fa.expandedInfoCustom = !fa.expandedInfoCustom;
    else fa.expandedInfoCustom = true;
  }

  loadInfoFADevParams() {
    let infoFADevParams = this.genericService.paramControlVariables[this.routingService.moduleId]['params'][this.genericService.paramControlVariables[this.routingService.moduleId]['indexParam']];
    if (!infoFADevParams || !(infoFADevParams.length > 0)) this.functionsService.openSnackBar("No se han encontrado parámetros en esta pantalla", 7000, ["red-snackbar"]);
    else this.infoFADevParams = infoFADevParams;
  }

  loadInfoFADevWindowValues() {
    let validReq = this.functionsService.formsArray(this.routingService.showFAInfo, null);
    let infoFADevWindowValues = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
    if (!infoFADevWindowValues || !(infoFADevWindowValues.length > 0)) this.functionsService.openSnackBar("No se han encontrado valores en esta pantalla", 7000, ["red-snackbar"]);
    else this.infoFADevWindowValues = infoFADevWindowValues;
  }

  editFunctionalArea(data) {
    let x = { id_function: 20, internal_routing_id_functional_area: 325, refresh_params: 1 };
    if (data['id_functional_area']) {
      x['refresh_params'] = 2;
      x['customParams'] = [{ bd_table: "functional_area", bd_field: "id_functional_area", id_db: 3, value: data['id_functional_area'] }];
    }
    this.functionsService.getFunction(x, null);
    this.routingService.showFAInfo = false;
  }

  public closeTabGeneric(key, isActive) {
    if (isActive) this.genericService.go(this.routingService.initialRoute, true);
    this.genericService.paramControlVariables[key]['active'] = 0;
    this.genericService.paramControlVariables[key]['params'] = [];
    this.genericService.paramControlVariables[key]['indexParam'] = 0;
    this.genericService.arrayFunctionalParentsLoaded[key] = [];
    this.genericService.paramControlVariablesFAs[key] = [];
    this.genericService.updateParamControl();
  }

  public newTabGeneric() {
    if (!this.genericService.finishedLoadStructure) {
      this.functionsService.openSnackBar("La pantalla aún no se ha cargado completamente", 7000, ["red-snackbar"]);
      return;
    }
    this.genericService.finishedLoadStructure = false;
    let x = { id_function: 1, internal_routing: "", id_functional_parent_initial: this.routingService.moduleId };
    this.functionsService.getFunction(x, null);
  }

  swipeTabIndex(index, id_pantalla, route) {
    let response = this.genericService.swipeTabIndex(index, id_pantalla, route);
    if (response == 2) this.functionsService.openSnackBar("La pantalla aún no se ha cargado completamente", 7000, ["red-snackbar"]);
    else if (response == 1) {
      let x = { id_function: 1, internal_routing: route, id_functional_parent_initial: this.routingService.moduleId };
      this.functionsService.getFunction(x, null);
    }
  }

  openTabGeneric(key, route) {
    let response = this.genericService.openTabGeneric(key, route);
    if (response == 2) this.functionsService.openSnackBar("La pantalla aún no se ha cargado completamente", 7000, ["red-snackbar"]);
    else if (response == 1) {
      let x = { id_function: 1, internal_routing: route, id_functional_parent_initial: this.routingService.moduleId };
      this.functionsService.getFunction(x, null);
    }
  }

  deleteIndexTabGeneric(id_pantalla, index) {
    this.genericService.paramControlVariables[id_pantalla]['deletedIndex'] = this.functionsService.cloneVariable(index)
    this.genericService.paramControlVariables[id_pantalla]['params'].splice(index, 1);
    if (+this.genericService.paramControlVariables[id_pantalla]['indexParam'] > +index) this.genericService.paramControlVariables[id_pantalla]['indexParam'] = this.genericService.paramControlVariables[id_pantalla]['indexParam'] - 1;
    this.genericService.arrayFunctionalParentsLoaded[id_pantalla].splice(index, 1);
    this.genericService.paramControlVariablesFAs[id_pantalla].splice(index, 1);
    this.genericService.updateParamControl();
  }

  isOldLoaded(date: string) {
    // Construye la cadena de fecha en un formato reconocido por Date
    const formattedDateString = date['date'];

    // Crea el objeto Date con la cadena formateada
    const lastReloadDate = new Date(formattedDateString);

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = new Date().getTime() - lastReloadDate.getTime();

    // Convert the difference to minutes
    const differenceInMinutes = differenceInMilliseconds / (1000 * 60);

    return differenceInMinutes > 2;
  }

  clearAllTabs(window) {
    if (this.clearAllActivated && this.clearAllActivated[window]) {
      this.genericService.paramControlVariables[window]['active'] = 0;
      this.genericService.paramControlVariables[window]['indexParam'] = 0;
      this.genericService.paramControlVariables[window]['params'] = [];
      this.genericService.arrayFunctionalParentsLoaded[window] = [];
      this.genericService.paramControlVariablesFAs[window] = [];
      if (window == this.routingService.moduleId) this.genericService.go(this.routingService.initialRoute, true);
      this.clearAllActivated[window] = false;
      this.genericService.updateParamControl();
    } else {
      this.clearAllActivated[window] = true;
    }
  }

  getAllTabIndex(key) {
    return this.genericService.cloneVariable(this.genericService.paramControlVariables[key]['params']).reverse();
  }

  searchValue(value) {
    this.lastValue = this.genericService.cloneVariable(this.value);
    this.value = value;
    this.searchResults = [];
    this.isSearcing = true;
    this.search();
  }

  cleanFilter() {
    this.value = "";
  }

  deleteFilter() {
    this.value = "";
    this.lastValue = "";
    this.searchResults = [];
  }

  search() {
    if (!this.value || this.value == "" || this.value.length < 3) {
      this.functionsService.openSnackBar("La búsqueda debe tener al menos 3 caracteres", 7000, ["red-snackbar"]);
      this.isSearcing = false;
    } else {
      this.endpointService.searchGeneric(this.value, this.authService.getIdCompany(true), this.authService.labelLanguage).subscribe((data) => {
        this.searchResults = data["response"];
        this.isSearcing = false;
      });
    }
  }

  navigateSearch(result) {
    let lastModule = this.genericService.footerId;
    this.functionsService.addWindowParam(result['value'], result['id_db'], result['bd_table'], result['bd_field'], lastModule, true, "output");
    this.functionsService.route = result['route'];
    this.functionsService.id_route = this.functionsService.cloneVariable(null);
    this.routingService.previousModule = lastModule;
    this.functionsService.goRouting(result['route'], true);
  }
}