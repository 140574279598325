import { Injectable } from '@angular/core';

declare let jQuery: any;

@Injectable()
export class ImageUploaderService {


  constructor() { }

  private uploadedImage: string = null;

  private modalName: string = null;


  public openModalImageUploader(modalName: string) {

    //jQuery('#imageUploaderModal').modal('show');
    this.modalName = modalName;

    jQuery(this.modalName).modal('show');

    let imageModalOpened = true;

    return imageModalOpened;

  }

  public onImageUploadFinished(data: any) {
    this.uploadedImage = data.response.fileName;
  }

  public getImageNameAndClearService() {
    let imageFileName = this.uploadedImage;
    this.clearService();

    return imageFileName;
  }

  public clearService() {
    this.uploadedImage = null;
    this.modalName = null;
  }


  public onClickAccept() {
    jQuery(this.modalName).modal('hide');
    let imageModalOpened = true;
    return imageModalOpened;
  }


  public onClickCancel() {

    jQuery(this.modalName).modal('hide');
    let imageModalOpened = false;
    return imageModalOpened;


    //    TODO devolver false this.modalOpened = false;

  }

}
