<div *ngIf="loaded" style="position: relative;">
    <canvasjs-chart *ngIf="this.dataQuery.length > 0" [options]="chartOptions"></canvasjs-chart>
    <div style="padding: 0.5em;" *ngIf="this.dataQuery.length == 0">
        <ng-container *ngIf="structure[this.index]['label'] !== undefined && structure[this.index]['label'] !== '' && structure[this.index]['label'] !== null">
            <p style="font-weight: bold; margin: 1.5em 0.5em 0.2em; font-size: 35px; text-align: center; letter-spacing: -1px; font-family: system-ui; line-height: 40px;" [innerHTML]="structure[this.index]['label'] | safeHtml"></p>
        </ng-container>
        <div style="width: 100%; background-color: aliceblue; height: 300px; justify-content: center; display: flex; align-items: center; font-weight: 500; border-radius: 5px; padding: 0px;">
            <p>NO HAY DATOS PARA ESTE GRÁFICO</p>
        </div>
    </div>
    <div class="close-tittle-copyright close-tittle-copyright-right close-tittle-copyright-top"></div>
    <div class="close-tittle-copyright close-tittle-copyright-left close-tittle-copyright-top"></div>
    <div class="close-tittle-copyright close-tittle-copyright-right close-tittle-copyright-bottom"></div>
    <div class="close-tittle-copyright close-tittle-copyright-left close-tittle-copyright-bottom"></div>
</div>