<ng-container *ngIf="structure[this.index]['type'] === 'h1' && this.structure[this.index]['text']">
  <h1 [innerHTML]="this.structure[this.index]['text'] | safeHtml" (click)="onClickEvent(this); this.structure[this.index]['id_function'] ? $event.stopPropagation() : ''"></h1>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] ==='h2' && this.structure[this.index]['text']">
  <h2 [innerHTML]="this.structure[this.index]['text'] | safeHtml" (click)="onClickEvent(this); this.structure[this.index]['id_function'] ? $event.stopPropagation() : ''"></h2>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === 'h3' && this.structure[this.index]['text']">
  <h3 [innerHTML]="this.structure[this.index]['text'] | safeHtml" (click)="onClickEvent(this); this.structure[this.index]['id_function'] ? $event.stopPropagation() : ''"></h3>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === 'h4' && this.structure[this.index]['text']">
  <h4 [innerHTML]="this.structure[this.index]['text'] | safeHtml" (click)="onClickEvent(this); this.structure[this.index]['id_function'] ? $event.stopPropagation() : ''"></h4>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === 'h5' && this.structure[this.index]['text']">
  <h5 [innerHTML]="this.structure[this.index]['text'] | safeHtml" (click)="onClickEvent(this); this.structure[this.index]['id_function'] ? $event.stopPropagation() : ''"></h5>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === 'h6' && this.structure[this.index]['text']">
  <h6 [innerHTML]="this.structure[this.index]['text'] | safeHtml" (click)="onClickEvent(this); this.structure[this.index]['id_function'] ? $event.stopPropagation() : ''"></h6>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === 'p' && this.structure[this.index]['text']">
  <p [innerHTML]="this.structure[this.index]['text'] | safeHtml" (click)="onClickEvent(this); this.structure[this.index]['id_function'] ? $event.stopPropagation() : ''"></p>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === 'b' && this.structure[this.index]['text']">
  <b [innerHTML]="this.structure[this.index]['text'] | safeHtml" (click)="onClickEvent(this); this.structure[this.index]['id_function'] ? $event.stopPropagation() : ''"></b>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === 'strong' && this.structure[this.index]['text']">
  <strong [innerHTML]="this.structure[this.index]['text'] | safeHtml" (click)="onClickEvent(this); this.structure[this.index]['id_function'] ? $event.stopPropagation() : ''"></strong>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === 'i' && this.structure[this.index]['text']">
  <i [innerHTML]="this.structure[this.index]['text'] | safeHtml" (click)="onClickEvent(this); this.structure[this.index]['id_function'] ? $event.stopPropagation() : ''"></i>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === 'em' && this.structure[this.index]['text']">
  <em [innerHTML]="this.structure[this.index]['text'] | safeHtml" (click)="onClickEvent(this); this.structure[this.index]['id_function'] ? $event.stopPropagation() : ''"></em>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === 'mark' && this.structure[this.index]['text']">
  <mark [innerHTML]="this.structure[this.index]['text'] | safeHtml" (click)="onClickEvent(this); this.structure[this.index]['id_function'] ? $event.stopPropagation() : ''"></mark>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === 'small' && this.structure[this.index]['text']">
  <small [innerHTML]="this.structure[this.index]['text'] | safeHtml" (click)="onClickEvent(this); this.structure[this.index]['id_function'] ? $event.stopPropagation() : ''"></small>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === 'del' && this.structure[this.index]['text']">
  <del [innerHTML]="this.structure[this.index]['text'] | safeHtml" (click)="onClickEvent(this); this.structure[this.index]['id_function'] ? $event.stopPropagation() : ''"></del>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === 'ins' && this.structure[this.index]['text']">
  <ins [innerHTML]="this.structure[this.index]['text'] | safeHtml" (click)="onClickEvent(this); this.structure[this.index]['id_function'] ? $event.stopPropagation() : ''"></ins>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === 'sub' && this.structure[this.index]['text']">
  <sub [innerHTML]="this.structure[this.index]['text'] | safeHtml" (click)="onClickEvent(this); this.structure[this.index]['id_function'] ? $event.stopPropagation() : ''"></sub>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === 'sup' && this.structure[this.index]['text']">
  <sup [innerHTML]="this.structure[this.index]['text'] | safeHtml" (click)="onClickEvent(this); this.structure[this.index]['id_function'] ? $event.stopPropagation() : ''"></sup>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === 'span' && this.structure[this.index]['text']">
  <span [innerHTML]="this.structure[this.index]['text'] | safeHtml" (click)="onClickEvent(this); this.structure[this.index]['id_function'] ? $event.stopPropagation() : ''"></span>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === 'a' && this.structure[this.index]['text'] && this.structure[this.index]['url']">
  <a *ngIf="!((structure[index]['url'] | slice:0:1) == '#')" 
  href="{{ this.structure[this.index]['url'] }}" target="_blank" [innerHTML]="this.structure[this.index]['text'] | safeHtml"></a>
  <a *ngIf="((structure[index]['url'] | slice:0:1) == '#')"
  (click)="scrollTo(structure[index]['url'])" target="_blank" [innerHTML]="this.structure[this.index]['text'] | safeHtml"></a>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === 'iframe' && this.structure[this.index]['url']">
  <ng-container *ngIf="structure[index]['iframe_fullscreen'] != 1">
    <iframe [src]="structure[index]['url']" width="100%" height="100%" frameBorder="0"></iframe>
  </ng-container>
  <ng-container *ngIf="structure[index]['iframe_fullscreen'] == 1">
    <iframe [src]="structure[index]['url']" allowfullscreen width="100%" height="100%" frameBorder="0"></iframe>
  </ng-container>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === 'img' && this.structure[this.index]['url']">
  <img *ngIf="!this.structure[this.index]['creatingMode'] || !structure[index]['url'].includes('${*')" [ngClass]="{'no-click-img': !structure[index]['id_function']}" (click)="onClickEvent(this)" [src]="structure[index]['url']" (error)="structure[index]['url'] = this.genericService.defaultNoImage">
  <img *ngIf="this.structure[this.index]['creatingMode'] && structure[index]['url'].includes('${*')" [ngClass]="{'no-click-img': !structure[index]['id_function']}" [src]="this.genericService.defaultNoImage">
</ng-container>