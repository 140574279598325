import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class Dispatcher
{
    private cmps = [];
    constructor() {

    }

    public subscribe(cmp){
        this.cmps.push(cmp);
    }

    public notify(data?){
        for(let cmp of this.cmps){
            cmp.notify(data);
        }
    }
}