import {MessageChat} from "./message-chat";
import {UserChat} from "./user-chat";


export class InmoGroupRoom {
  id: string;
  roomTypeId: number;
  date: any;
  users: UserChat[];
  messages: MessageChat[];
  newmessages;
  auxMessages: MessageChat[];
  usersMap: Map<string, UserChat>;
  messagesMap: Map<string, MessageChat>;
  infoCargada: boolean;
  snapshot: number;
  nonReadMessages: number;
  myInfo: UserChat;
  allMessagesLoaded: boolean;
  groupName: string;
  groupPic: string;
  startPagination: string;
  lastReadMessage: string;
  inmoId: number;
  lastMessage: any;
  belongs = true;
  constructor(id: string, roomTypeId: number, inmoId: number, date?: any, groupName?: string, groupPic?: string, users?: UserChat[],
              messages?: MessageChat[], usersMap?: Map<string, UserChat>,messagesMap?: Map<string, MessageChat>, lastMessage?: any ) {
    this.id = id || '';
    this.roomTypeId = roomTypeId || -1;
    this.date = date || null;
    this.groupName = groupName || '';
    this.groupPic = groupPic || '';
    this.users = users || [];
    this.messages = messages || [];
    this.newmessages = [];
    this.usersMap = usersMap || new Map<string, UserChat>();
    this.messagesMap = messagesMap || new Map<string, MessageChat>();
    this.infoCargada = false;
    this.snapshot = 0;
    this.nonReadMessages = 0;
    this.allMessagesLoaded = false;
    this.startPagination = undefined;
    this.lastReadMessage = undefined;
    this.inmoId = inmoId || -1;
    this.lastMessage = lastMessage || null;
  }
}
