import {split} from 'ts-node/dist';

export class EventConditionBody {

  public id: number;
  public field: number;
  public operator: number;
  public type: number;
  public value: any;
  public dateOffsetOperation: number;
  public dateOffsetType: number;
  public dateOffsetValue: number;
  public valueList: Array<number>;

  constructor() {
    this.clearBody();
  }

  public clearBody() {
    this.id = null;
    this.field = 1;
    this.operator = 1;
    this.type = 1;
    this.value = '';
    this.dateOffsetOperation = null;
    this.dateOffsetType = null;
    this.dateOffsetValue = null;
    this.valueList = [];
  }

  public handleResponse(data: any) {
/*    if (Array.isArray(data.valor)) {
      // data.valor.forEach(condition => {
        this.id = data?.id;
        this.field = data?.id_campo;
        this.operator = data?.id_operador;
        this.type = data?.id_tipo;
        this.value = data.valor[0];
        try {
          if (this.value[0]?.split('-').length === 3) {
            this.value = {
              year: parseInt(splited[0], 10),
              month: parseInt(splited[1], 10),
              day: parseInt(splited[2], 10)
            };
          } else {
            this.value = parseInt(data.valor[0], 10);
            if (isNaN(this.value)) {
              this.value = data.valor[0];
            }
          }
          this.dateOffsetOperation = data?.id_tipo_operacion;
          this.dateOffsetType = data?.id_magnitud;
          this.dateOffsetValue = data?.valor_date;
          this.valueList = [];
        }
        catch (e) {
        }
      // });
      // data.valor = data.valor[0];
    }*/

    // else {
      this.id = data?.id;
      this.field = data?.id_campo;
      this.operator = data?.id_operador;
      this.type = data?.id_tipo;
      this.value = data?.valor;

      try {
        if (this.value?.split('-').length === 3) {
          const splited = this.value.split('-');
          this.value = {
            year: parseInt(splited[0], 10),
            month: parseInt(splited[1], 10),
            day: parseInt(splited[2], 10)
          };
        } else {
          this.value = parseInt(data?.valor, 10);
          if (isNaN(this.value)) {
            this.value = data?.valor;
          }
        }
        this.dateOffsetOperation = data?.id_tipo_operacion;
        this.dateOffsetType = data?.id_magnitud;
        this.dateOffsetValue = data?.valor_date;
        this.valueList = [];
      }
      catch (e) {
      }
    // }

  }

  public handleResponseMultipleValues(data) {
    this.id = data.id;
    this.field = data.id_campo;
    this.operator = data.id_operador;
    this.type = data.id_tipo;
    this.value = " ";
    this.dateOffsetOperation = data.id_tipo_operacion;
    this.dateOffsetType = data.id_magnitud;
    this.dateOffsetValue = data.valor_date;
    this.valueList = data.valor;
  }
}
