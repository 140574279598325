import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Values} from "../../../values/values";
import {map} from "rxjs/internal/operators";
import {RequestOptions} from "@angular/http";

@Injectable({
  providedIn: 'root'
})
export class ApiChatService {

  private URL = Values.CHAT_SERVER_URL + '/api/';
  private observable = null;

  constructor(private http: HttpClient) { }
  public getPublicGroups(userId: string){
    let requestURL = this.URL + 'group/getPublicGroups';
    requestURL += '?userId=' + userId;
    return this.http.get<any[]>(requestURL).pipe(map(response => response));
  }
  public getMessages(userId: string, groupId: string, page?: number, itemsPerPage?: number, anchor?: string) {
    let requestURL = this.URL + 'msg/getMsgs';
    requestURL += '?userId=' + userId;
    requestURL += '&groupId=' + groupId;
    requestURL += '&page=' + (page | 0);
    requestURL += '&itemsPerPage=' + (itemsPerPage | 15);
    if (anchor) requestURL += '&anchor=' + anchor;
    return this.http.get<any[]>(requestURL).pipe(map(response => response));
  }

  public getLastMessages(userId: string, groupId: string, page?: number, itemsPerPage?: number, anchor?: string) {
    let requestURL = this.URL + 'msg/getLastMsgs';
    requestURL += '?userId=' + userId;
    requestURL += '&groupId=' + groupId;
    requestURL += '&page=' + (page | 0);
    requestURL += '&itemsPerPage=' + (itemsPerPage | 1);
    if (anchor) requestURL += '&anchor=' + anchor;
    return this.http.get<any[]>(requestURL).pipe(map(response => response));
  }

  public getNonReadMessages(userId: string, groupId?: string, page?: number, itemsPerPage?: number, anchor?: string) {
    let requestURL = this.URL + 'msg/getNonReadMsgs';
    requestURL += '?userId=' + userId;
    if (anchor) requestURL += '&anchor=' + anchor;
    return this.http.get<any[]>(requestURL).pipe(map(response => response));
  }

  public sendMessage(userId: string, msgType: number, msg: string, groupId: string) {
    let requestURL = this.URL + 'msg/sendMsg';
    let body = JSON.stringify({userId, msgType, msg, groupId });
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers }
    return this.http.post(requestURL, body, options).pipe(map(response => response));
  }

  public getAllGroups(userId: string) {
    let requestURL = this.URL + 'group/getAllGroups';
    requestURL += '?userId=' + userId;
    return this.http.get<any[]>(requestURL).pipe(map(response => response));
  }

  public getGroupInfo(groupId: string, userId) {
    let requestURL = this.URL + 'group/getGroupInfo';
    requestURL += '?groupId=' + groupId;
    requestURL += '&userId=' + userId;

    return this.http.get<any[]>(requestURL).pipe(map(response => response));
  }

  public userBelongsToGroup(groupId, userId){
    let requestURL = this.URL + 'group/doUserBelongsToGroup';
    requestURL += '?groupId=' + groupId;
    requestURL += '&userId=' + userId;

    return this.http.get<any[]>(requestURL).pipe(map(response => response));
  }

  public joinPublicGroup(groupId, userId){
    let requestURL = this.URL + 'group/joinPublicGroup';
    requestURL += '?groupId=' + groupId;
    requestURL += '&userId=' + userId;

    return this.http.get<any[]>(requestURL).pipe(map(response => response));
  }

  public leaveGroup(groupId, userId){
    let requestURL = this.URL + 'group/leaveGroup';
    requestURL += '?groupId=' + groupId;
    requestURL += '&userId=' + userId;

    return this.http.get<any[]>(requestURL).pipe(map(response => response));
  }

  public acceptGroupInvitation(groupId: string, userId) {
    let requestURL = this.URL + 'group/acceptGroupInvitation';
    requestURL += '?groupId=' + groupId;
    requestURL += '&userId=' + userId;

    return this.http.get<any[]>(requestURL).pipe(map(response => response));
  }

  public rejectGroupInvitation(groupId: string, userId) {
    let requestURL = this.URL + 'group/rejectGroupInvitation';
    requestURL += '?groupId=' + groupId;
    requestURL += '&userId=' + userId;

    return this.http.get<any[]>(requestURL).pipe(map(response => response));
  }

  public generateInvitationLink(groupId: string, userId) {
    let requestURL = this.URL + 'group/generateInvitationLink';
    requestURL += '?groupId=' + groupId;
    requestURL += '&userId=' + userId;

    return this.http.get<any[]>(requestURL).pipe(map(response => response));
  }
  
  public getInvitationLink(groupId: string, userId) {
    let requestURL = this.URL + 'group/getInvitationLink';
    requestURL += '?groupId=' + groupId;
    requestURL += '&userId=' + userId;

    return this.http.get<any[]>(requestURL).pipe(map(response => response));
  }
  
  public joinGroupByInvitationLink(id: string, userId) {
    let requestURL = this.URL + 'group/joinGroupByInvitationLink';
    requestURL += '?id=' + id;
    requestURL += '&userId=' + userId;

    return this.http.get<any[]>(requestURL).pipe(map(response => response));
  }

  public createGroup(groupType: number, groupName: string, groupDesc: string, groupImg: string, adminTalk: boolean,
                     adminAdd: boolean, userId: string, users: {userId: string, isAdmin: boolean, canTalk: boolean}[], agency?: number, isPublic?) {
    let requestURL = this.URL + 'group/createGroup';
    let body = JSON.stringify({groupType, groupName, groupDesc, groupImg, adminTalk, adminAdd, userId, users, agency, isPublic});
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers }
    return this.http.post(requestURL, body, options).pipe(map(response => response));
  }

  public updateGroup(groupId: string, groupType?: number, groupName?: string, groupDesc?: string, groupImg?: string, adminTalk?: boolean,
                     adminAdd?: boolean, userId?: string, creationDate?: Date, isPublic?) {
    let requestURL = this.URL + 'group/updateGroup';
    let body = JSON.stringify({groupId, groupType, groupName, groupDesc, groupImg, adminTalk, adminAdd, userId, creationDate, isPublic});
    console.log(body);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers }
    return this.http.post(requestURL, body, options).pipe(map(response => response));
  }

  public addUser(groupId: string, userId: string, userToAdd: { userId: string }) {
    let requestURL = this.URL + 'group/addUser';
    let body = JSON.stringify({groupId,userId, userToAdd});
    console.log(body);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers }
    return this.http.post(requestURL, body, options).pipe(map(response => response));
  }

  public removeUser(groupId: string, userId: string, userToRemove: { userId: string }) {
    let requestURL = this.URL + 'group/removeUser';
    let body = JSON.stringify({groupId,userId, userToRemove});
    console.log(body);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers }
    return this.http.post(requestURL, body, options).pipe(map(response => response));
  }

  public removeUserFromAllGroups(userId: string) {
    this.http.post(Values.SERVER_URL + Values.API_RESOURCES.INMO + Values.API_REQ_PATTERN + 'get-user-token', userId).subscribe((res) => {
      let requestURL = this.URL + 'group/removeUserFromAllGroups';
      const user_token = res['usuario_token'];
      let body = JSON.stringify({user_token});
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers }
      return this.http.post(requestURL, body, options).pipe(map(response => response));   
    }) 
  }

  public setPrivilegies(groupId: string, userId: string, userToSet: { userId: string, isAdmin: boolean }) {
    let requestURL = this.URL + 'group/setPrivilegies';
    let body = JSON.stringify({groupId,userId, userToSet});
    console.log(body);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers }
    return this.http.post(requestURL, body, options).pipe(map(response => response));
  }

  public banUser(groupId: string, userId: string, userToBan: { userId: string }) {
    let requestURL = this.URL + 'group/banUser';
    let body = JSON.stringify({groupId,userId, userToBan});
    console.log(body);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers }
    return this.http.post(requestURL, body, options).pipe(map(response => response));
  }

  public unbanUser(groupId: string, userId: string, userToUnban: { userId: string }) {
    let requestURL = this.URL + 'group/unbanUser';
    let body = JSON.stringify({groupId,userId, userToUnban});
    console.log(body);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers }
    return this.http.post(requestURL, body, options).pipe(map(response => response));
  }

  public isUserOnline(userId: string) {
    let requestURL = this.URL + 'msg/isUserOnline ';
    requestURL += '?userId=' + userId;
    return this.http.get<any[]>(requestURL).pipe(map(response => response));
  }
}
