import { Component, OnInit, Input } from '@angular/core';
import { FunctionsService } from "app/services/functions/functions.service";
import { EndpointService } from 'app/services/endpoint/endpoint.service';
import { GenericService } from 'app/services/generic/generic.service';

@Component({
  selector: 'app-events-conditions-custom',
  templateUrl: './events-conditions-custom.component.html',
  styleUrls: ['./events-conditions-custom.component.css']
})

export class EventsConditionsCustomComponent implements OnInit {
  
  @Input() structure = null;
  @Input() index: number;
  @Input() param: any = null;

  constructor(
    public functionsService: FunctionsService,
    public endpointService: EndpointService,
    public genericService: GenericService) 
  { 
  }

  public newIdEvento;
  public newIdReporte;

  ngOnInit(): void {
    let intern_data = this.genericService.getInternParam(this.structure[this.index], this.param);
    // Get conditions from event
    for (let i in intern_data) {
      if (intern_data[i]['id_db'] == this.structure[this.index]['id_db'] && intern_data[i]['bd_table'] == this.structure[this.index]['bd_table'] && intern_data[i]['bd_field'] == this.structure[this.index]['bd_field']) {
        this.newIdReporte = intern_data[i]['value'];
        this.endpointService.getConditionsFromEvento(intern_data[i]['value'], this.structure[this.index]['type']).subscribe(data => {
          this.functionsService.arrayConditions = data['response'];
          console.log(this.functionsService.arrayConditions, "arrayConditions")
        });
        break;
      }
    }

    // Get possible tables
    this.endpointService.getEventosTables().subscribe(data => {
      this.functionsService.arrayTables = data['response']
      // Get field names for each table
      for (let table of this.functionsService.arrayTables) {
        this.endpointService.getFieldsNameTable(table.bd_table).subscribe(data => {
          this.functionsService.fieldNames[table.bd_table] = data['response']
        });
      }
    });

    // Get operators
    this.endpointService.getOperatorsConditions().subscribe(data => {
      this.functionsService.arrayOperators = data['response']
    });
  }

  // newSimpleCondition(arrayConditions, idParent, idEvento) {
  //   arrayConditions.push({id_parent: idParent, id_evento: idEvento, id_reporte: idReporte,
  //     values: [{valor: ''}]})
  //   }
    
  // newGroupCondition(arrayConditions, idParent, idEvento) {
  //   arrayConditions.push({id_subtipo: 3, id_parent: idParent, id_evento: idEvento, id_reporte: idReporte, bd_table: 0, bd_field: 0, id_operador: 0, bd_table2: 0, bd_field2: 0, 
  //   children: [{id_subtipo: null, id_parent: null, id_evento: idEvento, values: [{valor: ''}]}, 
  //             {id_subtipo: null, id_parent: null, id_evento: idEvento, values: [{valor: ''}]}], 
  //   values: [{valor: ''}]})
  // }

  public filterArrayCondition() {
    return this.functionsService.arrayConditions.filter(function(element) {
      return element.borrado != 1;
    });
  }
}
