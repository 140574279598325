export class MessageChat {
  id: string;
  userId: string;
  //roomId: number;
  message: string;
  date: any;
  messageType: number;
  url: string;
  new: boolean;
  isRead: boolean;
  lastSeen: string;
  constructor(id?: string, userId?: string, message?: string, date?: any, messageType?: number, url?: string, read? : boolean, isRead? : boolean, lastSeen?: any) {
    this.id = id || '';
    this.userId = userId || '';
    this.message = message || '';
    this.date = date || null;
    this.messageType = messageType || -1;
    this.url = url || '';
    this.new = read;
    this.isRead = isRead;
    this.lastSeen = lastSeen;
  }
}

