
<div id="container" [ngClass]="{'is-pedidos': this.from == 0, 'is-reservas': this.from == 1, 'outer_container': true}" style="width: 100%; padding: 0 5px;">
    <ng-container *ngIf="maxWidthPX != null && maxHeightPX != null && loaded && loadedW">
        <!--<div style="text-align: right; margin-bottom: 20px; margin-right: 50px;" *ngIf="previewMode == 0">
            <span class="buttons-table-filter">
                <span *ngIf="statusMesa == 'defaultMesa'" class="generic-buttons-blue" style="margin-right: 40px;" (click)="CanviarStatus('infoMesa')"><button mat-button>Ver información extra</button></span>
                <span *ngIf="statusMesa != 'defaultMesa'" class="generic-buttons-blue" style="margin-right: 40px;" (click)="CanviarStatus('defaultMesa')"><button mat-button>Ocultar información</button></span>
            </span>
        </div>-->
        <div style="max-width: 100%; overflow: auto;" class="not-previewMode" *ngIf="previewMode != 1">
    
            <!-- <h5 *ngIf="statusMesa == 'dividirMesa'"  style="margin-top: 10px;"> Selecciona la mesa que deseas dividir </h5> -->
            <svg [attr.width]="maxWidthPX" [attr.height]="maxHeightPX" style="background: #5c5c5c; border-radius: 7px;">
                <g *ngFor="let line of lines">
                    <line [attr.x1]="line.startX" [attr.y1]="line.startY" [attr.x2]="line.endX" [attr.y2]="line.endY"
                    stroke="#e9e9e9" stroke-width="2" />
                </g>
                
                <g *ngFor="let mesa of mesas" [ngClass]="{'has-reserva': this.from == 1 && mesa.num_pedidos && mesa.num_pedidos > 0, 'box-table': true}">
                    <g *ngIf="mesa.coordenada_x !== null && mesa.coordenada_y !== null" >
                        <!-- MESA CON IMAGEN -->
                        <foreignObject id="img" *ngIf="mesa.imagen_url && statusMesa != 'infoMesa'"
                        [attr.x]="(mesa.coordenada_x-1/2)*lineSize + this.paddingAll" [attr.y]="(mesa.coordenada_y-1/2)*lineSize + this.paddingAll"
                        [attr.width]="mesa.widthCalculated" [attr.height]="mesa.heightCalculated"
                        (click)="pedidoMesa(mesa)" [ngStyle]="{'background': mesa.color + '8c'}" id="ImageMesa{{mesa.id_mesa}}0"  class="mesa-img">
                            <xhtml:div style="width: 100%; height: 100%;">
                                <xhtml:img id="ImageMesa{{mesa.id_mesa}}" [ngClass]="'cursor-pointer'" src="https://movin.cloud/empresas/restaurantes/{{mesa.id_empresa}}/imagenes/tipo_mesas/{{mesa.imagen_url}}" width="100%" height="100%" />
                            </xhtml:div>
                        </foreignObject>
    
                        <!-- MESA SIN IMAGEN -->
                        <foreignObject id="img" *ngIf="mesa.imagen_url == null && statusMesa != 'infoMesa'"
                        [attr.x]="(mesa.coordenada_x-1/2)*lineSize + this.paddingAll" [attr.y]="(mesa.coordenada_y-1/2)*lineSize + this.paddingAll"
                        [attr.width]="mesa.widthCalculated" [attr.height]="mesa.heightCalculated"
                        (click)="pedidoMesa(mesa)" [ngStyle]="{'background': mesa.color + '8c'}" id="ImageMesa{{mesa.id_mesa}}0"  class="mesa-img">
                            <xhtml:div style="width: 100%; height: 100%;">
                                <xhtml:img id="ImageMesa{{mesa.id_mesa}}" [ngClass]="'cursor-pointer'" src="https://movin.cloud/empresas/restaurantes/{{mesa.id_empresa}}/imagenes/tipo_mesas/16879578651225522650.jpg" width="100%" height="100%" />
                            </xhtml:div>
                        </foreignObject>
    
                        <!-- INFO MESA -->
                        <g *ngIf="statusMesa == 'infoMesa' || this.form == 1">
                            <foreignObject 
                                [attr.x]="(mesa.coordenada_x-1/2)*lineSize + this.paddingAll" [attr.y]="(mesa.coordenada_y-1/2)*lineSize + this.paddingAll"
                                [attr.width]="mesa.widthCalculated" [attr.height]="mesa.heightCalculated">
                                <xhtml:div style="width: 100%; height: 100%;">
                                    <xhtml:img background-color: #ccc; width="100%" height="100%" />
                                </xhtml:div>
                            </foreignObject>
                        </g>
    
                        <foreignObject *ngIf="statusMesa == 'defaultMesa'" [attr.x]="(mesa.coordenada_x-1/2)*lineSize + this.paddingAll" [attr.y]="(mesa.coordenada_y-1/2)*lineSize + this.paddingAll"
                            [attr.width]="mesa.widthCalculated" [attr.height]="mesa.heightCalculated" class="obj-name-label">
                            <xhtml:div [ngClass]="'cursor-pointer'" (click)="pedidoMesa(mesa)" style="width: 100%; height: 100%;">
                                <xhtml:p class="name-label" [ngStyle]="{'height': mesa.heightCalculated}">{{ mesa.nombre }}
                                </xhtml:p>
                            </xhtml:div>
                        </foreignObject>
                          

                        <foreignObject *ngIf="mesa.nombre_cliente" [attr.x]="(mesa.coordenada_x-1/2)*lineSize + this.paddingAll" [attr.y]="(mesa.coordenada_y-1/2+mesa.height)*lineSize - this.paddingAll + 1"
                            [attr.width]="mesa.widthCalculated" [attr.height]="15" class="obj-name-label">
                            <xhtml:div [ngClass]="'cursor-pointer'" (click)="pedidoMesa(mesa)" style="width: 100%; height: 100%;">
                                <xhtml:p class="name-label-client">{{ mesa.nombre_cliente }}
                                </xhtml:p>
                            </xhtml:div>
                        </foreignObject>
                        <foreignObject *ngIf="mesa.telefono_cliente" [attr.x]="(mesa.coordenada_x-1/2)*lineSize + this.paddingAll" [attr.y]="(mesa.coordenada_y-1/2+mesa.height)*lineSize - this.paddingAll + 16"
                            [attr.width]="mesa.widthCalculated" [attr.height]="15" class="obj-name-label">
                            <xhtml:div [ngClass]="'cursor-pointer'" (click)="pedidoMesa(mesa)" style="width: 100%; height: 100%;">
                                <xhtml:p class="name-label-client">{{ mesa.telefono_cliente }}
                                </xhtml:p>
                            </xhtml:div>
                        </foreignObject>

                        <foreignObject *ngIf="this.from == 1" [attr.x]="(mesa.coordenada_x-1/2)*lineSize + this.paddingAll" [attr.y]="(mesa.coordenada_y-1/2+mesa.height)*lineSize - this.paddingAll + 1"
                            [attr.width]="mesa.widthCalculated" [attr.height]="15" class="obj-name-label">
                            <xhtml:div [ngClass]="'cursor-pointer'" (click)="pedidoMesa(mesa)" style="width: 100%; height: 100%;">
                                <xhtml:p class="name-label-client">Nº Reservas: {{ mesa.num_reservas }}
                                </xhtml:p>
                            </xhtml:div>
                        </foreignObject>
                        
                        <g *ngIf="mesa.id_pedido == null && this.from != 1" class="status-table">
                            <!-- MESA: LIBRE -->
                            <g *ngIf="statusMesa == 'defaultMesa'">
                                <text class="material-icons status-table-2"
                                    [attr.x]="mesa.coordenada_x*lineSize - lineSize*0.45 + this.paddingAll" [attr.y]="mesa.coordenada_y*lineSize - lineSize*0.5 + this.paddingAll"
                                    [attr.width]="30" [attr.height]="30" style="height: 60px"
                                    fill="red"
                                    text-anchor="middle" dominant-baseline="central">group_off
                                </text>
                            </g>
                            <g *ngIf="statusMesa == 'infoMesa'">
                                <text class="material-icons status-table-2"
                                    [attr.x]="(mesa.coordenada_x+(mesa.width-1)/2)*lineSize + this.paddingAll" [attr.y]="mesa.coordenada_y*lineSize + this.paddingAll"
                                    [attr.width]="200" [attr.height]="200" style="height: 500px"
                                    fill="red"
                                    text-anchor="middle" dominant-baseline="central">group_off
                                </text>
                            </g>
                        </g>
                        <g *ngIf="mesa.id_pedido != null" class="status-table">
                            <!-- EN CASO DE QUE HAYA PEDIDO -->
                            <!--ICONO MESA: OCUPADA -->
                            <text class="material-icons status-table-2"
                                [attr.x]="mesa.coordenada_x*lineSize - lineSize*0.45 + this.paddingAll" [attr.y]="mesa.coordenada_y*lineSize - lineSize*0.5 + this.paddingAll"
                                [attr.width]="30" [attr.height]="30" 
                                [ngStyle]="{ 'height': '60px', 'fill': mesa.color, 'text-anchor': 'middle', 'dominant-baseline': 'central' }"
                                >group
                            </text>
                            
                            <g *ngIf="statusMesa == 'infoMesa'">
                                <foreignObject [attr.x]="((mesa.coordenada_x-'0.5')*lineSize) + this.paddingAll" [attr.y]="(mesa.coordenada_y-'0.5')*lineSize + this.paddingAll"
                                    [attr.width]="mesa.widthCalculated" [attr.height]="mesa.height*lineSize" class="obj-name-label" style="padding: 5px;">
                                    <xhtml:div *ngIf="mesa.num_pedidos == 1" style="align-items: center; width: 100%; height: 100%;">
                                        <xhtml:b *ngIf="mesa.num_comensales != null" style="font-size: 13px;">{{ mesa.num_comensales }} comensales 
                                        </xhtml:b>
                                        <xhtml:div></xhtml:div>
                                        <xhtml:b *ngIf="mesa.importe_restante != null" style="font-size: 13px;"> {{ mesa.importe_restante }}€ <span *ngIf="mesa.importe_total != null && mesa.importe_total != mesa.importe_restante"> / {{ mesa.importe_total }}€</span>
                                        </xhtml:b>
                                        <xhtml:div></xhtml:div>
                                        <xhtml:b *ngIf="mesa.passedTime != null" style="font-size: 13px;"> {{ mesa.passedTime }}
                                        </xhtml:b>
                                        <xhtml:div></xhtml:div>
                                        <xhtml:b *ngIf="mesa.estado != null" style="font-size: 13px;"> {{ mesa.estado }}
                                        </xhtml:b>                                    
                                    </xhtml:div>
                                    <xhtml:div *ngIf="mesa.num_pedidos != 1" style="align-items: center; width: 100%; height: 100%;">
                                        <xhtml:b style="font-size: 13px;">Nº Pedidos: {{mesa.num_pedidos}}
                                        </xhtml:b>
                                    </xhtml:div>
                                </foreignObject>                                                      
                            </g>
    
                            <foreignObject *ngIf="mesa.num_pedidos > 1 && statusMesa == 'defaultMesa'" [attr.x]="((mesa.coordenada_x - 0.6)*lineSize) + 33 + this.paddingAll" [attr.y]="mesa.coordenada_y*lineSize - lineSize*0.5 - 12 + this.paddingAll" width="24" height="24">
                                <xhtml:div style="width: 100%; height: 100%;">
                                    <svg width="100%" height="100%">
                                    <circle cx="12px" cy="12px" r="14" fill="rgba(var(--primary-color), 0.9)" />
                                    <text x="50%" y="50%" text-anchor="middle" alignment-baseline="middle" fill="white" font-size="12px">{{ mesa.num_pedidos }}</text>
                                    </svg>
                                </xhtml:div>
                            </foreignObject>
    
                            <!--<foreignObject *ngIf="statusMesa == 'defaultMesa'" [attr.x]="(mesa.coordenada_x - 0.50)*lineSize" [attr.y]="(mesa.coordenada_y+0.2)*lineSize" width="30" height="30" (click)= "dividirMesa(mesa.id_mesa, mesa.num_pedidos)">
                                <xhtml:div style="width: 100%; height: 100%;">
                                    <svg width="100%" height="100%">
                                    <circle cx="50%" cy="50%" r="15" fill="rgba(var(--primary-color), 0.9)" />
                                    <text x="50%" y="75%" text-anchor="middle" class="material-icons" alignment-baseline="middle" fill="white" font-size="18px" style="font-size: 18px;">post_add</text>
                                    </svg>
                                </xhtml:div>
                            </foreignObject>-->
    
                            <!--<foreignObject 
                              [attr.x]="(mesa.coordenada_x-1/2)*lineSize + this.paddingAll" [attr.y]="(mesa.coordenada_y-1/2)*lineSize + this.paddingAll"
                              [attr.width]="mesa.widthCalculated" [attr.height]="mesa.heightCalculated"
                              (click)="pedidoMesa(mesa.id_mesa, mesa.id_pedido, mesa.num_pedidos)">
                            </foreignObject>-->
    
                            <!-- FOTO CAMARERO -->
                            <foreignObject *ngIf="mesa.foto"
                                [attr.x]="((mesa.coordenada_x-1)*lineSize)+(mesa.width*lineSize)+(fotoSize*'0.65') - this.paddingAll" [attr.y]="(mesa.coordenada_y-1/2)*lineSize - this.paddingAll"
                                [attr.width]="fotoSizePX" [attr.height]="fotoSizePX">
                                <xhtml:div style="width: 100%; height: 100%;">
                                    <xhtml:img [ngClass]="'border-radius'" src={{mesa.foto}} width="100%" height="100%" /> </xhtml:div>
                            </foreignObject>
                            
                        </g>                                       
                    </g>  
                </g>
    
                <g *ngFor="let objeto of objetos" class="box-table box-objetos">
                    <g *ngIf="objeto.coordenada_x !== null && objeto.coordenada_y !== null" >
                        <!-- OBJETO CON IMAGEN -->
                        <foreignObject id="img" *ngIf="objeto.imagen_url"
                        [attr.x]="(objeto.coordenada_x-1/2)*lineSize" [attr.y]="(objeto.coordenada_y-1/2)*lineSize"
                        [attr.width]="objeto.widthCalculated" [attr.height]="objeto.heightCalculated"
                        class="mesa-img">
                            <xhtml:div style="width: 100%; height: 100%;">
                                <xhtml:img src="https://movin.cloud/empresas/restaurantes/{{objeto.id_empresa_tipo}}/imagenes/tipo_mesas_objetos/{{objeto.imagen_url}}" width="100%" height="100%" />
                            </xhtml:div>
                        </foreignObject>
    
                        <!-- OBJETO SIN IMAGEN -->
                        <foreignObject id="img" *ngIf="objeto.imagen_url == null"
                        [attr.x]="(objeto.coordenada_x-1/2)*lineSize" [attr.y]="(objeto.coordenada_y-1/2)*lineSize"
                        [attr.width]="objeto.widthCalculated" [attr.height]="objeto.heightCalculated"
                        class="mesa-img">
                            <xhtml:div style="width: 100%; height: 100%;">
                                <xhtml:img src="https://movin.cloud/empresas/restaurantes/{{objeto.id_empresa}}/imagenes/tipo_mesas_objetos/16879578651225522650.jpg" width="100%" height="100%" />
                            </xhtml:div>
                        </foreignObject>
                    </g>  
                </g>
            </svg>
        </div>
    
        <div style="max-width: 100%; overflow: auto;" class="previewMode" *ngIf="previewMode == 1">
            <svg [attr.width]="maxWidthPX" [attr.height]="maxHeightPX">
                <g *ngFor="let line of lines">
                    <line [attr.x1]="line.startX" [attr.y1]="line.startY" [attr.x2]="line.endX" [attr.y2]="line.endY"
                    stroke="#cdcdcd" stroke-width="2" />
                </g>
    
                <g *ngFor="let x of coordXs">
                    <g *ngFor="let y of coordYs">
                        <!-- BOTTON SITUAR MESA-->
                        <text class="material-icons-outlined edit-button-text"
                            [attr.x]="x*lineSize - 15" [attr.y]="y*lineSize" 
                            [attr.width]="20" [attr.height]="20"
                            (click)="addElement(x,y)"
                            text-anchor="middle" dominant-baseline="central" fill="green">add_box
                        </text>
                    </g>
                </g>
                <g *ngFor="let x of coordXs">
                    <g *ngFor="let y of coordYs">
                        <!-- BOTTON SITUAR OBJETO-->
                        <text class="material-icons-outlined edit-button-text"
                            [attr.x]="x*lineSize + 15" [attr.y]="y*lineSize" 
                            [attr.width]="20" [attr.height]="20"
                            (click)="addElement(x,y,1)"
                            text-anchor="middle" dominant-baseline="central" fill="green">add_road
                        </text>
                    </g>
                </g>
                
                <g *ngFor="let mesa of mesas" class="box-table">
                    <rect [attr.x]="(mesa.coordenada_x-1/2)*lineSize" [attr.y]="(mesa.coordenada_y-1/2)*lineSize" [attr.width]="mesa.widthCalculated" [attr.height]="mesa.heightCalculated" fill="#e3e3e3" />
    
                    <g *ngIf="mesa.coordenada_x !== null && mesa.coordenada_y !== null" >
                        <!-- MESA CON IMAGEN -->
                        <foreignObject *ngIf="mesa.imagen_url != null"
                        [attr.x]="(mesa.coordenada_x-1/2)*lineSize" [attr.y]="(mesa.coordenada_y-1/2)*lineSize"
                        [attr.width]="mesa.widthCalculated" [attr.height]="mesa.heightCalculated">
                            <xhtml:div style="width: 100%; height: 100%;">
                                <xhtml:img id="ImageMesa{{mesa.id}}" [ngClass]="previewMode == 0 ? 'cursor-pointer' : 'none-image'" src="https://movin.cloud/empresas/restaurantes/{{mesa.id_empresa}}/imagenes/tipo_mesas/{{mesa.imagen_url}}" width="100%" height="100%" />
                            </xhtml:div>
                        </foreignObject>
        
                        <!-- MESA SIN IMAGEN -->
                        <foreignObject *ngIf="mesa.imagen_url == null"
                        [attr.x]="(mesa.coordenada_x-1/2)*lineSize" [attr.y]="(mesa.coordenada_y-1/2)*lineSize"
                        [attr.width]="mesa.widthCalculated" [attr.height]="mesa.heightCalculated">
                            <xhtml:div style="width: 100%; height: 100%;">
                                <xhtml:img [ngClass]="previewMode == 0 ? 'cursor-pointer' : 'none-image'" src="https://movin.cloud/empresas/restaurantes/{{mesa.id_empresa}}/imagenes/tipo_mesas/16879578651225522650.jpg" width="100%" height="100%" />
                            </xhtml:div>
                        </foreignObject>
                    </g>
    
                     
                    <foreignObject [attr.x]="(mesa.coordenada_x-1/2)*lineSize" [attr.y]="(mesa.coordenada_y-1/2)*lineSize"
                    [attr.width]="mesa.widthCalculated" [attr.height]="mesa.heightCalculated" class="obj-name-label">
                        <xhtml:div style="width: 100%; height: 100%;" class="name-label2">
                            <xhtml:p class="name-label">{{ mesa.nombre }}
                            </xhtml:p>
                        </xhtml:div>
                    </foreignObject>
    
                    <g class="remove-table">
                        <!-- BOTTON DESCOLOCAR MESA -->
                        <text class="material-icons-outlined delete-button-text"
                        [attr.x]="(mesa.coordenada_x + mesa.width - 1/2) * lineSize - 20" [attr.y]="(mesa.coordenada_y - 1/2)*lineSize"
                        [attr.width]="30" [attr.height]="30" style="height: 48px"
                        text-anchor="middle" dominant-baseline="central" (click)="deleteElement(mesa.id_mesa)">cancel
                        </text>
                    </g>
                </g>
    
                <g *ngFor="let objeto of objetos" class="box-table box-objetos">
                    <g *ngIf="objeto.coordenada_x !== null && objeto.coordenada_y !== null" >
                        <rect [attr.x]="(objeto.coordenada_x-1/2)*lineSize" [attr.y]="(objeto.coordenada_y-1/2)*lineSize" [attr.width]="objeto.widthCalculated" [attr.height]="objeto.heightCalculated" fill="#e3e3e3" />
                        <!-- OBJETO CON IMAGEN -->
                        <foreignObject id="img" *ngIf="objeto.imagen_url"
                        [attr.x]="(objeto.coordenada_x-1/2)*lineSize" [attr.y]="(objeto.coordenada_y-1/2)*lineSize"
                        [attr.width]="objeto.widthCalculated" [attr.height]="objeto.heightCalculated"
                        class="mesa-img">
                            <xhtml:div style="width: 100%; height: 100%;">
                                <xhtml:img src="https://movin.cloud/empresas/restaurantes/{{objeto.id_empresa}}/imagenes/tipo_mesas_objetos/{{objeto.imagen_url}}" width="100%" height="100%" />
                            </xhtml:div>
                        </foreignObject>
    
                        <!-- OBJETO SIN IMAGEN -->
                        <foreignObject id="img" *ngIf="objeto.imagen_url == null"
                        [attr.x]="(objeto.coordenada_x-1/2)*lineSize" [attr.y]="(objeto.coordenada_y-1/2)*lineSize"
                        [attr.width]="objeto.widthCalculated" [attr.height]="objeto.heightCalculated"
                        class="mesa-img">
                            <xhtml:div style="width: 100%; height: 100%;">
                                <xhtml:img src="https://movin.cloud/empresas/restaurantes/{{objeto.id_empresa_tipo}}/imagenes/tipo_mesas_objetos/16879578651225522650.jpg" width="100%" height="100%" />
                            </xhtml:div>
                        </foreignObject>
    
                        <g class="remove-table">
                            <!-- BOTTON DESCOLOCAR OBJETO -->
                            <text class="material-icons-outlined delete-button-text"
                            [attr.x]="(objeto.coordenada_x + objeto.width) * lineSize - lineSize*0.5 - 12" [attr.y]="objeto.coordenada_y*lineSize - lineSize*0.5 + 12"
                            [attr.width]="30" [attr.height]="30" style="height: 48px"
                            text-anchor="middle" dominant-baseline="central" (click)="deleteElement(objeto.id_objeto, 1)">cancel
                            </text>
                        </g>
                    </g>  
                </g>
            </svg>
        </div>
    </ng-container>
</div>
