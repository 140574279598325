export class ExecuteFunctionAction {

  public id: number;
  public id_funcion: number;
  public id_enviar_email: number;
  // public emailInfo: {
  public id_email_remitente: number;
  public plantilla: number;
  public id_email_tipo: number;
  public valor_email_campo: string;
  public id_lista_destinatarios: number;
  public email_from: string;
  public email_from_name: string;
  // } = null;

  constructor() {
    this.clearBody();
  }

  public clearBody() {
    this.id = null;
    this.id_funcion = null;
    this.id_enviar_email = null;
    this.id_email_remitente = null;
    this.plantilla = null;
    this.id_email_tipo = null;
    this.valor_email_campo = null;
    this.id_lista_destinatarios = null;
    this.email_from = null;
    this.email_from_name = null;
  }

  public handleResponse(data: any) {
    this.id = data.core.id;
    this.id_funcion = data.core.id_funcion;
    this.id_enviar_email = data.core.id_enviar_email;
    this.id_email_remitente = data.core.id_email_remitente;
    this.plantilla = data.core.plantilla;
    this.id_email_tipo = data.core.id_email_tipo;
    this.valor_email_campo = data.core.valor_email_campo;
    this.id_lista_destinatarios = data.core.id_lista_destinatarios;
    this.email_from = data.core.email_from;
    this.email_from_name = data.core.email_from_name;
  }
}
